import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { SelectInputType } from '@modules/wizard-sales/store/sales.state';
import { ModalComponent } from '@shared/modal/modal.component';
import { map } from 'rxjs';
import { Modal, PermissionFE } from './interfaces';

export enum SalesRoutes {
  PREVENDITA = 'pre-vendita',
  INITIALCHECK = 'initialCheck',
  PRECHECK = 'pre-check',
  ESITO = 'esito',
  CONTRATTUALIZZAZIONE = 'contrattualizzazione',
  COMMODITY = 'commodity',
  VAS = 'vas',
  RACCOLTA_DATI = 'raccolta-dati',
  RIEPILOGO = 'riepilogo',
  INVIO = 'invio'
}

export enum SalesID {
  INITIALCHECK = 'initialCheck',
  PRECHECK = 'pre-check',
  ESITO = 'esito',
  CONTRATTUALIZZAZIONE = 'contrattualizzazione',
  COMMODITY = 'commodity',
  VAS = 'vas',
  RACCOLTA_DATI = 'raccoltaDati',
  RIEPILOGO = 'riepilogo',
  INVIO = 'invioo'
}

export enum SalesCommercialActionsBE {
  VOLTURA_III = 'VOLTURA_VOLTURANTE',
  VOLTURA_IV = 'VOLTURA_4',
  CAMBIO_OFFERTA = 'CAMBIO_OFFERTA',
  CAMBIO_MERCATO = 'CAMBIO_MERCATO',
  PREWIN_BACK = 'PREWIN_BACK',
  VOLTURA_SWITCH = 'VOLTURA_SWITCH',
  SWITCHIN = 'SWITCHIN',
  VOLTURA = 'Voltura',
  SWITCHINDOC = 'SWITCH IN',
  SUBENTRO = 'SUBENTRO',
  ALLACCIO_ATTIVAZIONE = 'ALLACCIO_ATTIVAZIONE',
  ATTIVAZIONE = 'ATTIVAZIONE'
}

export enum PertinenzeOffers {
  IEAUBE2S = 'IEAUBE2S',
  IEAUBE2V = 'IEAUBE2V'
}

export enum DualMono {
  DUAL = 'DUAL',
  MONO = 'MONO'
}

export enum SalesCommercialActions {
  ALLACCIO_CON_ATTIVAZIONE = 'Allaccio con ATT',
  ATTIVAZIONE_DUAL = 'Attivazione DUAL',
  ATTIVAZIONE_ELE = 'Attivazione Ele',
  ATTIVAZIONE_GAS = 'Attivazione Gas',
  ATTIVAZIONE_GAS_DELIBERA = 'Attivazione Gas delibera 40',
  CAMBIO_MERCATO = 'Cambio Mercato',
  CAMBIO_OFFERTA = 'Cambio Offerta',
  PREWIN_BACK = 'Pre-Win Back',
  SUBENTRO_DUAL = 'Subentro DUAL',
  SUBENTRO_ELE = 'Subentro Ele',
  SUBENTRO_GAS = 'Subentro Gas',
  SWITCH_IN = 'Switch In',
  SWITCH_IN_DUAL = 'Switch IN Dual',
  SWITCH_IN_SCHEDULATO = 'Switch In Schedulato',
  VOLTURA = 'Voltura', // corrisponde sia a voltura III che voltura IV
  VOLTURA_SWITCHING_ELE = 'Voltura con Switching Ele',
  VOLTURA_DUAL = 'Voltura DUAL' // corrisponde sia a voltura III che voltura IV
}

export const CaricaDocVisibilityStates = ['in attesa caricamento verbal order', 'in attesa call', 'in attesa verifica dati', 'attesa conferma plico digitale iren', 'attesa stampa proposta', 'attesa consegna plico a cliente', 'in attesa firma'];
export const CaricaDocLumineaVisibilityStates = ['in attesa caricamento verbal order'];

export enum TrackingSalesKeys {
  SELLING_TYPE = 'Tipo di Vendita',
  COMMODITY = 'Commodity',
  NAME = 'Nome',
  SURNAME = 'Cognome',
  POD = 'Pod',
  PDR = 'Pdr',
  SOCIAL_REASON = 'Ragione Sociale',
  CF = 'Codice Fiscale',
  PIVA = 'P.Iva',
  COD_ELE = 'Codice Ele',
  COD_ENEL_TEL_GAS = 'Codice Enel Gas',
  ADDRESS_FORN = 'Indirizzo Fornitura',
  RESIDENCE_ADDRESS = 'Indirizzo di Residenza',
  DELIVERY_ADDRESS = 'Indirizzo di Consegna',
  FORNITURE_ADDRESS = 'Indirizzo Fornitura',
  INSTALLATION_ADDRESS = 'Indirizzo Installazione',
  OPERATION = 'Operazione',
  STIPULATION_METHOD = 'Metodo di stipula',
  SIGN_TYPE = 'Tipologia di firma',
  MOTIVAZIONE_KO_QUOTE = 'Motivazione Ko Quote',
  NOTE = 'Note',
  MOBILE_PHONE = 'Cellulare',
  EMAIL = 'Email',
  ESITO_CAUSALE = 'Esito Causale',
  COMMODITY_OFFER = 'Offerta Commodity',
  VAS_OFFER = 'Offerta Vas',
  CONTRACTUALIZED_POWER = 'Potere contrattuale',
  PROFILE_WITHDRAWAL = 'Profilo di Prelievo',
  YEARLY_CONSUMPTION_ELE = 'Consumo Annuo Ele',
  YEARLY_CONSUMPTION_GAS = 'Consumo annuo Gas',
  PAYMENT_METHOD = 'Metodo di Pagamento',
  BILLING_FORMAT = 'Formato di Fatturazione',
  BANK_NAME = 'Nome Banca',
  IBAN = 'Iban',
  BIC_SWIFT = 'Codice Bic/Swift',
  BILLING_TYPE = 'Tipo di Fatturazione',
  FILE_TYPE = 'Tipo File',
  OPERATOR_CODE = 'Codice operatore',
  AGENCY_CODE = 'Codice agenzia',
  SPORTELLO = 'Sportello',
  WORKING_GROUP = 'Gruppo di lavoro',
  RANDOM_CODE = 'Random Code',
  SUPPLY_POINT = 'Punto di fornitura',
  OWNER = 'Titolare',
  DAYS_SPENT = 'Giorni Trascorsi',
  ONGOING_PRACTICE_STATE = 'Stato avanzamento SFDC',
  CANCELLATION_CAUSE = 'Causale annullamento',
  OWNER_CF_PIVA = 'CF/P.IVA Titolare',
  ACCEPTANCE_QUOTE_DATE = 'Data di accettazione',
  CANCELLATION_DATE = 'Data di annullamento',
  REQUEST_DATE = 'Data di sottoscrizione',
  OPPORTUNITY_ID = 'ID Opportunity',
  QUOTE_ID = 'ID Quote',
  SUPPLY_ADDRESS = 'Indirizzo di fornitura',
  SIGNED_PROPOSAL_FILE_NAME = 'Nome file di proposta firmata',
  OFFER_NUMBER = 'Nome offerta',
  USER_NUMBER = 'Numero utente',
  QUOTE = 'Quote',
  SKU_CODE = 'Sku code',
  PROCESS_IN_PROGRESS = 'Tipo lavorazione',
  CALL_TYPE = 'Tipologia call',
  DOCUMENTATION_RESEND = 'Reinvio documentazione',
  MARKET_TYPE = 'Tipo Mercato',
  VOCAL_ORDER_ACCEPTANCE = 'Accettazione vocal order',
  VOCAL_ORDER_SUBSCRIPTION = 'Sottoscrizione vocal order',
  PRACTICE_STATE = 'Stato avanzamento IF',
  ACCEPTANCE_DATE = 'Data accettazione cliente',
  ACCEPTANCE_PHONE = 'Numero di telefono accettazione',
  PRODUCT_VAS_NAME = 'Nome prodotto',
  FATT_CONG = 'Fatturazione congiunta',
  INSTALLMENTS_NUMBER = 'Numero rate',
  DETAIL_COMMERCIAL_VARIATION = 'Dettaglio variazione commerciale',
  TYPE_COMMERCIAL_VARIATION = 'Tipologia variazione commerciale',
  VULNERABILITY = 'Vulnerabilità'
}

export enum OngoingPracticeState {
  ACTIVE = 'attivo',
  VOID = 'Annullato',
  ACCEPTED = 'accettato',
  WAITING_FOR_DOCUMENTATION = 'In attesa di documentazione',
  MODULES_COMPLETION = 'Completamento modulistica',
  PLICO_ACCEPTANCE = 'Accettazione Plico digitale IREN',
  DIGITAL_SIGNATURE_KO = 'KO firma digitale IREN',
  EXPIRED_DIGITAL_SIGNATURE = 'Tempi di firma digitale IREN scaduti',
  DRAFT = 'Draft'
}

export enum PostSalesWorkingTypes {
  ACTIVATED = 'Attivati',
  CANCELLED = 'Annullati',
  DIGITAL_SIGNATURE_ACCEPTANCE = 'Accettazione firma digitale',
  DIGITAL_SIGNATURE_KO = 'KO Firma digitale IREN',
  DIGITAL_SIGNATURE_TIMING = 'Tempi di firma digitale IREN scaduti',
  MODULES_COMPLETION = 'Completamento modulistica'
}
export enum PostSalesWorkingTypesTwo {
  REGISTRY_VARIATION = 'Variazione anagrafica',
  COMMERCIAL_VARIATION = 'Variazione commerciale',
  PAYMENT_VARIATION = 'Variazione metodo di pagamento',
  CONSENT_VARIATION = 'Variazione consensi'
}

export enum ModalStatus {
  WARNING = 'Warning', // usato nei servizi non bloccanti
  ERROR = 'Error', // errore api
  SUCCESS = 'Success', // successo api
  GENERIC = 'Generic'
}

export enum ModalCtaTypes {
  PROCEED_OR_CANCEL = 'proceedOrCancel',
  BACK_OR_CANCEL = 'backOrCancel',
  CONFIRM_OR_CANCEL = 'confirmOrCancel',
  CLOSE = 'close',
  YES_NO = 'yesOrNo',
  GO_TO_HOMEPAGE = 'goToHomepage'
}

export enum StatoAvanzamentoValue {
  COMPLETE = 'In Attesa Completamento Luminea',
  DRAFT = 'Attesa assegnazione Luminea'
}
export enum StatoAvanzamentoViewValue {
  COMPLETE = 'In Attesa Completamento Luminea',
  DRAFT = 'Attesa assegnazione Luminea'
}
export enum StatoAvanzamentoDescription {
  COMPLETE = 'Pratiche Luminea  da assegnare ad agente abilitato a Luminea ',
  DRAFT = 'Pratiche già assegnate da completare con la parte VAS'
}

export enum RCVasSubtype {
  AUTO = 'AUTO',
  MOTO = 'MOTO',
  FURGONE = 'FURGONE'
}

export enum RCVasSubtypeVertiId {
  AUTO = 'AUTO',
  MOTO = 'MOTO',
  FURGONE = 'FURGONE'
}

export enum StatoAvanzamentoRcValue {
  COMPLETE = 'In Attesa Completamento Luminea RC',
  DRAFT = 'Attesa assegnazione Luminea RC',
  WASTED = 'In attesa risoluzione scarto RC Auto'
}
export enum StatoAvanzamentoRcViewValue {
  COMPLETE = 'In Attesa Completamento Luminea Rc',
  DRAFT = 'Attesa assegnazione Luminea Rc',
  WASTED = 'In attesa risoluzione scarto RC Auto'
}
export enum StatoAvanzamentoRcDescription {
  COMPLETE = 'Pratiche Luminea Rc da assegnare ad agente abilitato a Luminea Rc',
  DRAFT = 'Pratiche già assegnate da completare con la parte VAS',
  WASTED = 'In attesa risoluzione scarto RC Auto da parte di Verti'
}

export enum PermissionList {
  CATALOG_BUNDLE = 1,
  UTILITY_COMMODITY_BUNDLE = 2,
  UTILITY_VAS = 3,
  VENDITA_COMMODITY_BUNDLE = 4,
  VENDITA_VAS = 5,
  POST_SALES = 6,
  TRACKING_AGENZIA = 7,
  TRACKING_AGENTE = 8,
  CARICAMENTO_MODULISTICA = 9,
  KPI_MONITORING_AGENZIA = 10,
  KPI_MONITORING_AGENTE = 11,
  CREAZIONE_CREDENZIALI = 12,
  MODIFICA_CREDENZIALI = 13,
  GESTIONE_VISIBILITA_PERMESSI = 14,
  BLACKLIST = 15,
  VISUALIZZAZIONE_FORMAZIONE = 16,
  GESTIONE_CONTENUTI_FORMATIVI = 17,
  PROFILO = 18,
  SCARTI_FUNZIONALI = 19,
  SCARTI_TECNICI = 20,
  GESTIONE_LEAD = 21,
  GESTIONE_REGOLE_DISPATCHING = 22,
  CAMPAGNE_ASSEGNATE = 23,
  CATALOG_COMMODITY = 24,
  CATALOG_VAS = 25,
  CARICAMENTO_PRATICHE_MASSIVE = 26,
  SCRIPT_TAB = 27,
  SCRIPT_EDIT = 28,
  DAILY_TASK = 29,
  DAILY_TASK_ASSIGN = 30,
  CREAZIONE_CAMPAGNE = 31,
  CONFIGURAZIONE_GARE = 34,
  UPLOAD_CSV_LEAD_FREDDI = 36,
  CARICAMENTO_ESITI_RPO = 37,
  INSTANT_CALL = 38,
  ASSEGNAZIONE_LEAD_AGENZIE = 39,
  DOWNLOAD_LEAD_CALDI = 41,
  UPLOAD_ESITI_LEAD_CALDI = 42,
  KPI_LEAD_FREDDI = 43,
  DOWNLOAD_LEAD_FREDDI = 44,
  UPLOAD_ESITI_LEAD_FREDDI = 45,
  DISPONIBILITA_LEAD_FREDDI_AGENZIA = 46,
  DISPONIBILITA_LEAD_FREDDI_IREN = 47,
  KPI_MONITORING_DASHBOARD_BOE = 48,
  KPI_MONITORING_DASHBOARD_SALES = 49,
  KPI_MONITORING_DASHBOARD_AZ_COMM = 50,
  KPI_MONITORING_DASHBOARD_FOCUS = 51,
  KPI_MONITORING_DASHBOARD_GEO = 52,
  KPI_MONITORING_DASHBOARD_REPORT = 53,
  AGENCY_SELL = 55,
  FUB_RPO = 56,
  PREWINBACK = 57,
  DETTAGLIO_LAVORAZIONE_LEAD = 58,
  DETTAGLIO_CONTRATTI = 59,
  SOCIAL_ADS_P = 60,
  GOOGLE_ADS_P = 61,
  WEB_SERVICE_P = 62,
  CRM_P = 63,
  ESTRAZIONE_ESITI = 64,
  KPI_HOT_LEAD_AGENCY = 65,
  KPI_HOT_LEAD_AGENT = 66,
  VENDITA_LUMINEA = 67,
  ASSEGNAZIONE_LUMINEA = 68,
  VISUALIZZAZIONE_PRATICHE_LUMINEA = 69,
  KPI_LEAD_TARGHE = 70,
  ENRICHMENT_LEAD_TARGHE = 71,
  DOWNLOAD_LEAD_TARGHE = 72,
  UPLOAD_ESITI_LEAD_TARGHE = 73,
  BOE_DISPATCHING = 74,
  BOE_REDISPATCHING = 75,
  GESTIONE_VISIBILITA_PERMESSI_OPERATORE = 76,
  REDISPATCHING_LEAD_TARGHE = 77,
  ESTRAZIONE_ESITI_LEAD_TARGHE = 78,
  TRUSTPILOT_SEND_INVITATION = 79,
  TRUSTPILOT_SET_TEMPLATE = 80,
  TRUSTPILOT_KPI = 81,
  TRUSTPILOT_REPORT = 82,
  TRUSTPILOT_SET_GOALS = 83,
  TRUSTPILOT_SET_AGENCY_PERMISSION = 84,
  WHITELIST_OVER_85 = 85
}

export const permissionsGroup: PermissionFE = {
  massivePractices: [PermissionList.CARICAMENTO_PRATICHE_MASSIVE, PermissionList.SCARTI_FUNZIONALI, PermissionList.SCARTI_TECNICI],
  catalog: [PermissionList.CATALOG_COMMODITY, PermissionList.CATALOG_BUNDLE, PermissionList.CATALOG_VAS],
  script: [PermissionList.SCRIPT_TAB, PermissionList.SCRIPT_EDIT],
  salesUtility: [PermissionList.UTILITY_COMMODITY_BUNDLE, PermissionList.UTILITY_VAS],
  salesVendita: [PermissionList.VENDITA_COMMODITY_BUNDLE, PermissionList.VENDITA_VAS],
  agentLanding: [PermissionList.CREAZIONE_CREDENZIALI, PermissionList.BLACKLIST, PermissionList.MODIFICA_CREDENZIALI, PermissionList.GESTIONE_VISIBILITA_PERMESSI],
  agentActiveInactive: [PermissionList.CREAZIONE_CREDENZIALI, PermissionList.MODIFICA_CREDENZIALI],
  kpiMonitoring: [PermissionList.KPI_MONITORING_AGENTE, PermissionList.KPI_MONITORING_AGENZIA],
  kpiMonitoringTab: [
    PermissionList.KPI_MONITORING_DASHBOARD_BOE,
    PermissionList.KPI_MONITORING_DASHBOARD_SALES,
    PermissionList.KPI_MONITORING_DASHBOARD_AZ_COMM,
    PermissionList.KPI_MONITORING_DASHBOARD_FOCUS,
    PermissionList.KPI_MONITORING_DASHBOARD_GEO,
    PermissionList.KPI_MONITORING_DASHBOARD_REPORT
  ],
  availability: [PermissionList.DISPONIBILITA_LEAD_FREDDI_AGENZIA, PermissionList.DISPONIBILITA_LEAD_FREDDI_IREN],
  tracking: [PermissionList.TRACKING_AGENTE, PermissionList.TRACKING_AGENZIA],
  leadDispatching: [
    PermissionList.ASSEGNAZIONE_LEAD_AGENZIE,
    PermissionList.GESTIONE_REGOLE_DISPATCHING,
    PermissionList.DOWNLOAD_LEAD_CALDI,
    PermissionList.UPLOAD_ESITI_LEAD_CALDI,
    PermissionList.DETTAGLIO_LAVORAZIONE_LEAD,
    PermissionList.DETTAGLIO_CONTRATTI,
    PermissionList.SOCIAL_ADS_P,
    PermissionList.GOOGLE_ADS_P,
    PermissionList.WEB_SERVICE_P,
    PermissionList.CRM_P,
    PermissionList.ESTRAZIONE_ESITI,
    PermissionList.KPI_HOT_LEAD_AGENCY,
    PermissionList.KPI_HOT_LEAD_AGENT,
    PermissionList.DOWNLOAD_LEAD_FREDDI,
    PermissionList.UPLOAD_ESITI_LEAD_FREDDI,
    PermissionList.KPI_LEAD_FREDDI,
    PermissionList.CARICAMENTO_ESITI_RPO,
    PermissionList.KPI_LEAD_TARGHE,
    PermissionList.ENRICHMENT_LEAD_TARGHE,
    PermissionList.DOWNLOAD_LEAD_TARGHE,
    PermissionList.UPLOAD_ESITI_LEAD_TARGHE,
    PermissionList.REDISPATCHING_LEAD_TARGHE,
    PermissionList.ESTRAZIONE_ESITI_LEAD_TARGHE
  ],
  hotKpiLead: [
    PermissionList.DETTAGLIO_LAVORAZIONE_LEAD,
    PermissionList.DETTAGLIO_CONTRATTI,
    PermissionList.SOCIAL_ADS_P,
    PermissionList.GOOGLE_ADS_P,
    PermissionList.WEB_SERVICE_P,
    PermissionList.CRM_P,
    PermissionList.ESTRAZIONE_ESITI,
    PermissionList.KPI_HOT_LEAD_AGENCY,
    PermissionList.KPI_HOT_LEAD_AGENT
  ],
  dispatchingCall: [PermissionList.BOE_DISPATCHING, PermissionList.BOE_REDISPATCHING],
  recensioni: [PermissionList.TRUSTPILOT_SEND_INVITATION, PermissionList.TRUSTPILOT_SET_TEMPLATE]
};

export const commercialOperationXPermission = [
  {
    opComm: 'VOLTURA_VOLTURANTE',
    copy: 'Voltura'
  },
  {
    opComm: 'VOLTURA_4',
    copy: 'Voltura Titolo IV'
  },
  {
    opComm: 'CAMBIO_OFFERTA',
    copy: 'Cambio offerta'
  },
  {
    opComm: 'CAMBIO_MERCATO',
    copy: 'Cambio mercato'
  },
  {
    opComm: 'PREWIN_BACK',
    copy: 'Prewin Back'
  },
  {
    opComm: 'VOLTURA_SWITCH',
    copy: 'Voltura con switch'
  },
  {
    opComm: 'SWITCHIN',
    copy: 'Switch In'
  },
  {
    opComm: 'SUBENTRO',
    copy: 'Subentro'
  },
  {
    opComm: 'ALLACCIO_ATTIVAZIONE',
    copy: 'Allaccio con attivazione'
  },
  {
    opComm: 'ATTIVAZIONE',
    copy: 'Attivazione'
  }
];

export const PERSONA_FISICA_ACCOUNT_TYPE = 'Persona fisica';
export const CONDOMINI_ACCOUNT_TYPE = 'Condominio';
export const PERSONA_GIURIDICA_ACCOUNT_TYPE = 'Persona giuridica';
export const DITTA_INDIVIDUALE_ACCOUNT_TYPE = 'Ditta individuale';
export const PUBBLICA_AMMINISTRAZIONE_ACCOUNT_TYPE = 'Pubblica Amministrazione';

export const DocType = ["CARTA IDENTITA'", 'PATENTE DI GUIDA', 'PASSAPORTO', 'LIBRETTO PENSIONE', "PORTO D'ARMI", 'TESSERA MINISTERI', 'PERMESSO DI SOGGIORNO'];

export const AuthGroup = ['1920 - Atena', '9915 - SEV', '9000 - Iren Mercato', '9940 - Alegas'];

export const ClusterWeb = ['Affiliation', 'Aiuto Self', 'Aiuto_Self_Sem', 'Altro', 'Chat', 'Cluster web', 'Google Ads', 'Portale Comparatore', 'Progetti Speciali', 'Recupero Self', 'Self', 'Sem', 'Social Media', 'Web'];
export const ClusterOffer = ['Standard', 'OLD', 'PFIX2'];
export const ClusterAction = ['Cambio Mercato', 'Cambio Offerta', 'Sviluppo portafoglio', 'Voltura'];

export enum ChannelPA {
  STORE_CORNER = 'Store e Corner',
  SPORTELLO = 'Sportello',
  AGENZIA_MID = 'Agenzia MID',
  AGENZIA_D2D = 'Agenzia D2D',
  DOOR_TO_DOOR = 'Door-to-door',
  TELESELLER = 'Teleseller',
  WEB_SALES = 'WebSales',
  TELESELLER_INBOUND = 'Teleseller Inbound',
  TELESELLER_OUTBOUND = 'Teleseller Outbound'
}

export const ChannelKPI: SelectInputType<string>[] = [
  {
    value: 'AGENZIA MID',
    viewValue: 'Agenzia MID'
  },
  {
    value: 'AGENZIA D2D',
    viewValue: 'Door-to-door'
  },
  {
    value: 'SPORTELLO',
    viewValue: 'Sportello'
  },
  {
    value: 'STORE E CORNER',
    viewValue: 'Store e Corner'
  },
  {
    value: 'TELESELLER INBOUND',
    viewValue: 'Teleseller Inbound'
  },
  {
    value: 'TELESELLER OUTBOUND',
    viewValue: 'Teleseller Outbound'
  },
  {
    value: 'WEBSALES',
    viewValue: 'WebSales'
  }
];

export const ChannelReportKPI: SelectInputType<string>[] = [
  {
    value: 'Store e Corner',
    viewValue: 'Store e Corner'
  },
  {
    value: 'SPORTELLO',
    viewValue: 'Sportello'
  },
  {
    value: 'Agenzia MID',
    viewValue: 'Agenzia MID'
  },
  {
    value: 'WebSales',
    viewValue: 'WebSales'
  },
  {
    value: 'Teleseller Inbound',
    viewValue: 'Teleseller Inbound'
  },
  {
    value: 'Teleseller Outbound',
    viewValue: 'Teleseller Outbound'
  }
];

export const CommercialSalesKPI: SelectInputType<string>[] = [
  {
    value: 'ALLACCIO CON ATT',
    viewValue: 'Allaccio con attivazione'
  },
  {
    value: 'ATTIVAZIONE',
    viewValue: 'Attivazione'
  },
  {
    value: 'CAMBIO MERCATO',
    viewValue: 'Cambio Mercato'
  },
  {
    value: 'CAMBIO OFFERTA',
    viewValue: 'Cambio Offerta'
  },
  {
    value: 'PRE-WIN BACK',
    viewValue: 'Pre-Win Back'
  },
  {
    value: 'SUBENTRO',
    viewValue: 'Subentro'
  },
  {
    value: 'SWITCH IN',
    viewValue: 'Switch In'
  },
  {
    value: 'VOLTURA',
    viewValue: 'Voltura'
  },
  {
    value: 'VOLTURA CON SWITCHING ELE',
    viewValue: 'Voltura con Switching Ele'
  }
];

export enum ProfileType {
  EXTERNAL_SALES = 'EXTERNAL_SALES',
  SALES = 'SALES',
  IT_IREN = 'IT_IREN',
  BOE = 'BOE',
  EDITOR = 'EDITOR'
}

export const uploadDocRequired = [
  {
    name: SalesCommercialActionsBE.ATTIVAZIONE,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.SUBENTRO,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.VOLTURA_III,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.VOLTURA_IV,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.ALLACCIO_ATTIVAZIONE,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.SWITCHIN,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.PREWIN_BACK,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.CAMBIO_MERCATO,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.VOLTURA_SWITCH,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.CAMBIO_OFFERTA,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.SWITCHINDOC,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.VOLTURA,
    identityDoc: false
  }
];

export enum CommodityTypesValues {
  ELE = 'ele',
  GAS = 'gas'
}

export enum CommodityTypesViewValues {
  ELE = 'Elettricità',
  GAS = 'Gas'
}

export enum SalesAccountTypes {
  BUSINESS = 'Business',
  RESIDENZIALI = 'Residenziali',
  PERTINENZE = 'Pertinenze',
  CONDOMINI = 'Condomini'
}

export const BusinessSegm = ['pmi', 'soho', 'microbusiness'];

export enum StipulationMethods {
  IREN_DIGITAL_SIGNATURE = 'Firma digitale IREN',
  PRECOMPILED_PLICO = 'Plico Precompilato',
  PREPRINTED_PLICO = 'Plico prestampato',
  EXT_PARTNER_SIGNATURE = 'Firma digitale Partner esterni',
  VOCAL_ORDER = 'Vocal Order'
}

export enum StipulationMethodValue {
  IREN_DIGITAL_SIGNATURE = 'Firma Digitale Iren',
  PRECOMPILED_PLICO = 'Plico Precompilato',
  VOCAL_ORDER = 'Vocal Order',
  PREPRINTED_PLICO = 'Plico Prestampato',
  EXT_PARTNER_SIGNATURE = 'Firma Digitale Partner Esterni'
}

export enum MassiveProcessesValue {
  SWITCH_IN = 'SWITCHIN',
  OFFER_CHANGE = 'CAMBIO-OFFERTA',
  ACTIVATION = 'ATTIVAZIONE-SUBENTRO',
  CONNECTION_WITH_ACTIVATION = 'ATTIVAZIONE-SUBENTRO',
  SWITCHED_ACCOUNT_CHANGE = 'VOLTURA-SWITCHIN',
  TAKE_OVER = 'ATTIVAZIONE-SUBENTRO'
}

export enum MassiveProcessesViewValue {
  SWITCH_IN = 'Switch-In',
  OFFER_CHANGE = 'Cambio Offerta',
  ACTIVATION = 'Attivazione',
  CONNECTION_WITH_ACTIVATION = 'Allaccio con Attivazione',
  SWITCHED_ACCOUNT_CHANGE = 'Voltura con switch',
  TAKE_OVER = 'Subentro'
}

export enum MassiveProcessesBundleValue {
  ACTIVATION_TAKEOVER = 'ATTIVAZIONE-SUBENTRO',
  CONNECTION_WITH_ACTIVATION = 'ATTIVAZIONE-SUBENTRO',
  TAKEOVER = 'ATTIVAZIONE-SUBENTRO',
  SWITCH_IN = 'SWITCHIN',
  SWITCH_IN_NDS = 'SWITCHIN-NDS',
  OFFER_CHANGE = 'CAMBIO-OFFERTA',
  OFFER_CHANGE_NDS = 'CAMBIO-OFFERTA-NDS',
  TRANSFER_SWITCH_IN = 'VOLTURA-SWITCHIN',
  TRANSFER_SWITCH_IN_NDS = 'VOLTURA-SWITCHIN-NDS'
}

export enum MassiveProcessesBundleViewValue {
  ACTIVATION_TAKEOVER = 'Attivazione',
  CONNECTION_WITH_ACTIVATION = 'Allaccio con attivazione',
  TAKEOVER = 'Subentro',
  SWITCH_IN = 'Switch-in',
  SWITCH_IN_NDS = 'Switch-in NDS',
  OFFER_CHANGE = 'Cambio Offerta',
  OFFER_CHANGE_NDS = 'Cambio Offerta NDS',
  TRANSFER_SWITCH_IN = 'Voltura con Switch',
  STAND_ALONE = 'Stand Alone (VAS)'
}

export const profiles = [
  {
    id: 1,
    name: 'Operatore di vendita',
    type: ProfileType.EXTERNAL_SALES
  },
  {
    id: 2,
    name: 'Team Leader',
    type: ProfileType.EXTERNAL_SALES
  },
  {
    id: 3,
    name: 'Supervisor BO di processo',
    type: ProfileType.EXTERNAL_SALES
  },
  {
    id: 4,
    name: 'Channel Account',
    type: ProfileType.SALES
  },
  {
    id: 5,
    name: 'Supervisor di processo IREN',
    type: ProfileType.SALES
  },
  {
    id: 6,
    name: 'IT IREN',
    type: ProfileType.IT_IREN
  },
  {
    id: 7,
    name: 'Supervisor BOE',
    type: ProfileType.BOE
  },
  {
    id: 8,
    name: 'Operatore BOE',
    type: ProfileType.BOE
  },
  {
    id: 9,
    name: 'Editor',
    type: ProfileType.EDITOR
  }
];

export const Manutenzione = 'Manutenzione';

export const contrState = [
  'In Attesa Completamento Luminea RC',
  'In Attesa Completamento Luminea',
  'Attesa assegnazione Luminea',
  'In attesa risoluzione scarto RC Auto',
  'Completamento Modulistica',
  'Attesa assegnazione Luminea RC',
  'DRAFT',
  'Attesa stampa proposta',
  'Attesa conferma plico digitale IREN',
  'In attesa call',
  'In attesa verifica dati',
  'KO firma digitale IREN',
  'Accettato',
  'Annullato'
];

export const CancelPracticeBoeNotesCallKO = ['KO: Ripensamento / annullamento richiesto dal cliente', 'KO: Disconoscimento firma', 'KO: Rifiuta Registrazione', 'KO: Vendita non conforme / non prevista da contratto / canale', 'KO: SMS/MAIL: disconoscimento MAIL o SMS', 'KO: Delega non autorizzata'];
export const CancelPracticeBoeNotesCallNR = ['NR: Numero errato /inesistente', 'NR: Squilla ma mai risposto'];
export const CancelPracticeBoeNotesCheck = ['KO: Dati errati', 'KO: Delega non autorizzata', 'KO: Fuori perimetro', 'KO: Mancanza di documenti necessari per tipologia di acquisizione', 'KO: Plico contrattuale non conforme con i dati inseriti'];

export const CancelPracticeNotes = [
  'KO: Altra prestazione in corso',
  'KO: Disconoscimento/comportamento scorretto agente/agenzia',
  'KO: Credit check ko',
  'KO: Dati errati',
  'KO: Delega non autorizzata',
  'KO: Disconoscimento firma',
  'KO: Flusso TIMG/TIMOE morosità',
  'KO: Fuori perimetro',
  'KO: Punto già attivo con società del gruppo',
  'KO: Punto già presente su SFDC',
  'KO: Ripensamento / annullamento richiesto dal cliente',
  'KO: SMS/MAIL: disconoscimento MAIL o SMS',
  'KO: Vendibilità ko cabina remi non aperta',
  'KO: Numero errato /inesistente',
  'KO: Pending da troppo tempo',
  'KO: Precheck ko - dati incongruenti',
  'KO: Rifiuta registrazione',
  'KO: Salvaguardia / Fui',
  'KO: Turista energetico',
  'KO: Vendita non conforme / non prevista da contratto / canale',
  'KO: Documentazione non completa',
  'KO: Errata offerta / commodity',
  'KO: Errata prestazione',
  'KO: Lavoro non eseguibile'
];

export const CancelPracticeReasons = [
  { viewValue: 'Annullamento manuale', value: ' Annullamento manuale', info: 'Viene inviata la bye bye letter' },
  { viewValue: 'Test tecnico', value: 'Test Tecnico', info: 'Non viene inviata la bye bye letter' }
];

export enum InstallmentsRcAuto {
  EVERY_YEAR_LABEL = 'Annuale',
  EVERY_YEAR_VALUE = 'everyyear',
  MONTHLY_LABEL = 'Mensile',
  MONTHLY_VALUE = 'monthly'
}

export const mapServicesRc: any = {
  'Pagamento Premio': {
    protectionDescription: 'Scegli se frazionare il pagamento in unica soluzione o 12 rate mensili',
    functionDescription: 'Ricordati che il pagamento unica soluzione può essere effettuato con carta di credito o bonifico bancario mentre il pagamento a rate può essere effettuato con carta di credito, bonifico bancario e SDD. La prima rata sarà sempre doppia.'
  },
  'Carrozzerie convenzionate': {
    protectionDescription: 'Scegli se convenzionarti con le nostre carrozzerie',
    functionDescription: 'Se scegli Sì, ricevi uno sconto in polizza e, in caso di sinistro, dovrai far riparare il tuo veicolo in una delle carrozzerie convenzionate Verti.'
  },
  'Tipologia di guida': {
    protectionDescription: 'Scegli la tipologia di guida',
    functionDescription:
      'Guida esperta: sono autorizzati alla guida il contraente e il proprietario, se in possesso di patente, il conducente abituale del veicolo e tutti i patentati con almeno 23 anni di età. Se selezioni, invece, Guida Libera sono autorizzati alla guida tutti i conducenti in possesso di regolare patente.'
  }
};

export const mapCoverageRc: any = {
  'RCA': {
    typecurrency: 'eur',
    protectionDescription: 'RCA',
    functionDescription: 'RCA'
  },
  'ARDVAND': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danneggiamenti volontari al veicolo parcheggiato in strada.',
    functionDescription: 'Copertura per i danni provocati da atti vandalici, tumulti, scioperi, sommosse e altre azioni di danneggiamento volontario.'
  },
  'ARDCRST': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni a vetri e cristalli del veicolo.',
    functionDescription: 'Copertura dei danni a vetri, lunotti e parabrezza del veicolo.'
  },
  'FUR_INC': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni al veicolo in caso di incendio, rapine e furti.',
    functionDescription:
      'Copertura in caso di danni materiali e diretti subiti dal veicolo assicurato a seguito di furto (consumato o tentato) e rapina.\n' +
      "La garanzia incendio copre i danni subiti dal veicolo assicurato a causa di incendio non doloso, azione di fulmini, scoppio o esplosione dell'impianto di alimentazione.\n" +
      'Per i veicoli ibridi ed elettrici è coperto anche il furto del cavo di ricarica e lo smaltimento della batteria.'
  },
  'ARDCOLL': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni al veicolo in caso di incidente con colpa.',
    functionDescription: "Copertura dei danni al veicolo causati dall'urto contro un altro veicolo targato, in sosta o in marcia, in caso di incidente con colpa."
  },
  'INFPD': {
    typecurrency: 'eur',
    protectionDescription: 'Copre gli infortuni subiti da chi è alla guida in caso di incidente anche in caso di torto, negligenza o malore (sia in marcia che ad auto ferma)',
    functionDescription: "Copertura per gli infortuni che determinino la morte o l'invalidità permanente, occorso a chiunque sia alla guida del veicolo assicurato."
  },
  'ARDKASK': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni che il veicolo potrebbe subire in caso di urti contro ostacoli fissi, collisioni, uscite di strada, ribaltamenti e incidenti con colpa.',
    functionDescription: 'Copertura per i danni subiti dal veicolo in qualsiasi tipologia di sinistro, come urti contro ostacoli fissi, collisioni, uscite di strada e ribaltamenti.'
  },
  'PNCD': {
    typecurrency: 'eur',
    protectionDescription: 'Mantiene invariata la classe di merito interna in caso di incidente con colpa ed evita che la compagnia eserciti la rivalsa in alcuni casi specifici',
    functionDescription:
      'La Classe di merito interna rimane invariata e viene utilizzata per calcolare il preventivo di rinnovo con la stessa compagnia. La garanzia è utilizzabile per un solo sinistro con colpa. Se il conducente è in stato di ebbrezza e provoca un incidente, Verti rinuncia al diritto di rivalsa. Valida solo per il primo sinistro e se il tasso alcolemico rilevato è sotto a 1,5 grammi per litro.'
  },
  'SOS': {
    typecurrency: 'pack',
    protectionDescription: 'Permette di ricevere assistenza per il veicolo in caso di guasto o incidente.',
    functionDescription:
      'Assistenza stradale per il veicolo in caso di guasto, foratura o incidente. La garanzia prevede due offerte:\n' +
      "- Base (disponibile per auto, moto e furgone): offre la riparazione sul posto o il traino del veicolo; il rientro dei passeggeri e l'eventuale recupero del veicolo fuori strada;\n" +
      "- Deluxe (disponibile per auto, moto e furgone) aggiunge l'invio dei pezzi di ricambio all'estero; il rientro sanitario e il veicolo sostitutivo. Per le auto e moto elettriche è previsto anche il trasporto del veicolo fermo alla colonnina di ricarica più vicina o, solo per alcune zone, la ricarica sul posto."
  },
  'TUTG': {
    typecurrency: 'pack',
    protectionDescription: 'Copre le spese legali in caso di coinvolgimenti giudiziari.',
    functionDescription:
      'Copertura delle spese legali per la difesa dei propri interessi, al conducente autorizzato, ai trasportati, al proprietario e al locatario (per i contratti di leasing) con un massimale pari a 7.000 euro. La versione Deluxe (disponibile solo per auto) prevede un massimale pari a 15.000 euro.'
  },
  'ARDATMO': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni subiti dal veicolo in caso di eventi atmosferici.',
    functionDescription:
      'Copertura dei danni materiali e diretti causati al veicolo assicurato da: inondazioni, esondazioni, alluvioni, trombe d’aria, uragani, mareggiate, cicloni, tifoni, tempeste, nubifragi, frane, smottamenti e cedimenti del terreno, valanghe, slavine, caduta di neve, ghiaccio e grandine. Sono compresi i danni da allagamenti, da cose trasportate dal vento e da caduta di alberi quando conseguenti agli eventi sopra indicati. Sono compresi i danni agli accessori di serie e anche gli optional, se il loro valore è riportato sulla scheda di polizza oppure fino ad un indennizzo massimo pari a € 1.500,00, a condizione che la loro presenza sia documentabile dalla fattura di acquisto.'
  }
};

//COMMON FUNCTION
export function getPositionByField(propertyName: string, value: any, array: any) {
  for (let i = 0; i < array.length; i++) if (array[i][propertyName] === value) return i;
  return -1;
}

export function clearEmptyKeyFromObject(obj: any) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === '' || obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
}

export const checkAgencyPick = map((el: any) => el.profile?.name === 'IT IREN' || el.profile?.name === 'Channel Account' || el.profile?.name === 'Supervisor di processo IREN');
export const checkPermissionGroup = map((el: any) => el.profile?.name === 'IT IREN');
export const checkEditor = map((el: any) => el.profile?.name !== 'Team Leader' && el.profile?.name !== 'Supervisor BO di processo');
export const checkAgencyPickBOE = map((el: any) => el.profile?.type === 'BOE');
export const checkDownloadMassiveCatalog = map((el: any) => el.profile?.name === 'Supervisor BO di processo' || el.profile?.name === 'Supervisor di processo IREN');

//REGEX
export const nameSurnameRegex = /^[a-zA-Z][a-zA-Z\s]+$/;
export const emailRegex = /^[A-Za-z0-9][A-Za-z0-9%+_-]*(\.[A-Za-z0-9%+_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;
export const cfRegex = /^([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$/;
export const generalCfRegex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$|^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$/;
export const pIvaRegex = /^[0-9]{11}$/;
export const onlyNumberRegex = /^[0-9]+$/;
export const regexContractualizedPower = /^(3[1-9]|[4-9]\d|\d{3,})$/;
export const pIvaCndRegex = /^([89])(\d{10})$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
export const cfPivaRegex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$|^(8|9)\d{10}$/i;
export const cfPivaRegexPostSales = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$|^\d{11}$/i;
export const ibanRegex = /^[A-Za-z]{2}[0-9]{2}[A-Za-z]{1}[0-9]{10}[A-Za-z0-9]{12}$/;
export const podRegex = /^IT\d{3}E(?!0{8})[A-Z0-9]{8}$/;
export const pdrRegex = /^\d{14}$/;
export const combinedPodPdrRegex = /^(IT\d{3}E(?!0{8})[A-Z0-9]{8}|\d{14})$/;
export const mobilePhoneRegexPostSales = /^(?:\+39|39)?\d{10}$/;
export const mobilePhoneRegex = /^[1-9](\d{8,10})$/;
export const phoneRegex = /^(0[0-9])(\d{6,13})$/;
export const combinedPhoneRegex = /^(?:[1-9]\d{8,10}|0[0-9]{8}|0[0-9]{9})$/;
export const comuneRegex = /^[A-Z]\d\d\d$/i;
export const onlyNumber = /^[0-9]+$/;
export const codiceMigrazioneRegex = /^[a-zA-Z0-9]{1,19}$/;
export const numerazioneTelefonicaRegex = /^(\d{8,10})(;\d{8,10})*$/;
export const capRegex = /^[0-9]{5}$/;
export const buildingNumberRegex = /^[ a-zA-Z0-9,'-_\\']{1,9}$/;
export const SDIRegex = /^[0-9A-Za-z]{7}$/;

//VALIDATORS
export function agentListNoAgencyValidator(control: AbstractControl): { [key: string]: any } | null {
  const agency = control.get('cod_agen')?.value;
  const operator = control.get('cod_oper')?.value;
  const codiceFiscale: string = control.get('cf')?.value;
  const agencyT = control.get('cod_agen')?.touched;
  const operatorT = control.get('cod_oper')?.touched;
  const codiceFiscaleT = control.get('cf')?.touched;

  if (!agency?.sfid && !operator && !codiceFiscale) {
    control.get('cod_agen')?.setErrors({ oneFieldRequired: true });
    control.get('cod_oper')?.setErrors({ oneFieldRequired: true });
    control.get('cf')?.setErrors({ oneFieldRequired: true });
    if (agencyT || operatorT || codiceFiscaleT) {
      control.get('cod_agen')?.markAsTouched();
      control.get('cod_oper')?.markAsTouched();
      control.get('cf')?.markAsTouched();
    }
  } else {
    control.get('cod_agen')?.setErrors(null);
    control.get('cod_oper')?.setErrors(null);
    control.get('cf')?.setErrors(null);
  }
  return null;
}

export const passValidator = function (control: AbstractControl): ValidationErrors | null {
  const hasLowercase = /[a-z]/.test(control.value);
  const hasUppercase = /[A-Z]/.test(control.value);
  const hasNumber = /\d/.test(control.value);
  const hasSpecial = /[!@#$%&*]/.test(control.value);
  const notHasSpecial = /[^!@#$%&*\w\s]/.test(control.value);
  const double = /(.)\1/.test(control.value);
  const isLongEnough = control.value.length >= 14;
  const errors: any = {};
  if (!hasLowercase) {
    errors['lowercase'] = true;
  }
  if (double) {
    errors['double'] = true;
  }
  if (!hasUppercase) {
    errors['uppercase'] = true;
  }
  if (!hasNumber) {
    errors['number'] = true;
  }
  if (!hasSpecial) {
    errors['special'] = true;
  }
  if (!isLongEnough) {
    errors['length'] = true;
  }
  if (notHasSpecial) {
    errors['noSpecial'] = true;
  }
  return Object.keys(errors).length > 0 ? errors : null;
};

export function isOmocode(cf: string): boolean {
  // Regex per verificare se ci sono lettere nelle posizioni numeriche che indicano omocodia
  const omocodiaCheck = /[LMNPQRSTUV]/;

  // Controlla le sezioni numeriche che possono subire omocodia
  const dayPart = cf.slice(9, 11);
  const yearPart = cf.slice(6, 8);
  const cadastralCodePart = cf.slice(12, 15);

  return (
    omocodiaCheck.test(dayPart) ||
    omocodiaCheck.test(yearPart) ||
    omocodiaCheck.test(cadastralCodePart)
  );
}

export function nameSurnameCfValidators(control: AbstractControl): { [key: string]: any } | null {
  const nome = control.get('name')?.value;
  const cognome = control.get('surname')?.value;
  const codiceFiscale: string = control.get('cf')?.value;

  if ((nome && cognome && codiceFiscale) || pIvaRegex.test(codiceFiscale)) {
    const regex = cfRegex;
    if (regex.test(codiceFiscale)) {
      const expectedCodeName = calcola_codice_nome(nome);
      const expectedCodeSurName = calcola_codice_cognome(cognome);
      const expectedControlCodeCF = calcola_carattere_di_controllo(codiceFiscale);
      if (codiceFiscale.slice(0, 3).toUpperCase() !== expectedCodeSurName || !codiceFiscale.slice(3, 6).toUpperCase().includes(expectedCodeName) || !codiceFiscale.toUpperCase().includes(expectedCodeSurName) || !codiceFiscale.charAt(15).toUpperCase().includes(expectedControlCodeCF)) {
        control.get('cf')?.setErrors({ nameSurnameCfError: true });
        return null;
      } else if(isOmocode(control.get('cf')?.value) && ((control.get('cfSpecial')?.value != null && !control.get('cfSpecial')?.value) || (control.get('codiceFiscaleStraniero')?.value != null && !control.get('codiceFiscaleStraniero')?.value))){
        control.get('cf')?.setErrors({ nameSurnameCfOmocodiaError: true });
        return null;
      } else {
        control.get('cf')?.setErrors(null);
        return null;
      }
    } else if (control.get('accountType')?.value === 'Condomini' && pIvaRegex.test(codiceFiscale)) {
      control.get('cf')?.setErrors(null);
      return null;
    } else {
      control.get('cf')?.setErrors({ required: true });
      control.get('cf')?.setErrors({ nameSurnameCfError: true });
      return null;
    }
  } else {
    control.get('cf')?.setErrors({ required: true });
    control.get('cf')?.setErrors({ nameSurnameCfError: true });
    return null;
  }
}

export function cfOrPartitaIvaValidator(control: AbstractControl): { [key: string]: any } | null {
  const cfBus = control.value.cfBus;

  if (control.get('cfBus')?.hasValidator(Validators.required)) {
    if (!cfBus?.match(generalCfRegex)) {
      control.get('cfBus')?.setErrors({ cfLength: true });
      return null;
    } else if (cfBus.match(pIvaRegex)) {
      for (let i = 0; i < 11; i++) {
        if (isNaN(parseInt(cfBus.charAt(i), 10)) || !cfBus.match(generalCfRegex)) {
          control.get('cfBus')?.setErrors({ partitaIvaNumeric: true });
          return null;
        } else {
          control.get('cfBus')?.setErrors(null);
        }
      }

      let s = 0;
      for (let i = 0; i <= 9; i += 2) {
        s += parseInt(cfBus.charAt(i), 10);
      }

      for (let i = 1; i <= 9; i += 2) {
        let c = 2 * parseInt(cfBus.charAt(i), 10);
        if (c > 9) {
          c = c - 9;
        }
        s += c;
      }

      if ((10 - (s % 10)) % 10 !== parseInt(cfBus.charAt(10), 10) || !cfBus.match(generalCfRegex)) {
        control.get('cfBus')?.setErrors({ partitaIvaInvalid: true });
        return null;
      } else {
        control.get('cfBus')?.setErrors(null);
      }
    } else if (cfBus?.match(generalCfRegex)) {
      control.get('cfBus')?.setErrors(null);
    } else {
      control.get('cfBus')?.setErrors({ partitaIvaInvalid: true });
    }
  }
  return null;
}

export function partitaIvaValidator(control: AbstractControl): { [key: string]: any } | null {
  const partitaIva = control.value.piva;

  if (!(control.get('accountType')?.value === 'Condomini' && control.get('soggetto_non_rilevante_fini_iva')?.value === true)) {
    if (!partitaIva || partitaIva.length !== 11) {
      control.get('piva')?.setErrors({ partitaIvaLength: true });
      return null;
    }

    for (let i = 0; i < 11; i++) {
      if (isNaN(parseInt(partitaIva.charAt(i), 10))) {
        control.get('piva')?.setErrors({ partitaIvaNumeric: true });
        return null;
      }
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
      s += parseInt(partitaIva.charAt(i), 10);
    }

    for (let i = 1; i <= 9; i += 2) {
      let c = 2 * parseInt(partitaIva.charAt(i), 10);
      if (c > 9) {
        c = c - 9;
      }
      s += c;
    }

    if ((10 - (s % 10)) % 10 !== parseInt(partitaIva.charAt(10), 10)) {
      control.get('piva')?.setErrors({ partitaIvaInvalid: true });
      return null;
    }
  } else {
    control.get('piva')?.setErrors(null);
  }
  return null;
}

export function checkPercetage(control: AbstractControl): { [key: string]: any } | null {
  const percentages = [...control.value.map((el: any) => el.percentage)];
  if (percentages) {
    let total: number = 0;
    percentages.forEach((el: number) => {
      total += el;
    });
    if (total !== 100) {
      return { percentageMax: true };
    }
  }
  return null;
}

export function calcola_carattere_di_controllo(codice_fiscale: string) {
  let val: number = 0;
  for (let i = 0; i < 15; i++) {
    let c = codice_fiscale[i];
    if (i % 2) val += tavola_carattere_di_controllo_valore_caratteri_pari[c as keyof typeof tavola_carattere_di_controllo_valore_caratteri_pari];
    else val += tavola_carattere_di_controllo_valore_caratteri_dispari[c as keyof typeof tavola_carattere_di_controllo_valore_caratteri_dispari];
  }
  val = val % 26;
  return tavola_carattere_di_controllo.charAt(val);
}

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl?.split(','),
    mime = arr[0]?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function calcola_codice_cognome(cognome: string) {
  let codice_cognome = ottieni_consonanti(cognome);
  codice_cognome += ottieni_vocali(cognome);
  codice_cognome += 'XXX';
  codice_cognome = codice_cognome.substring(0, 3);
  return codice_cognome.toUpperCase();
}

export function calcola_codice_nome(nome: string) {
  let codice_nome = ottieni_consonanti(nome);
  if (codice_nome.length >= 4) {
    codice_nome = codice_nome.charAt(0) + codice_nome.charAt(2) + codice_nome.charAt(3);
  } else {
    codice_nome += ottieni_vocali(nome);
    codice_nome += 'XXX';
    codice_nome = codice_nome.substr(0, 3);
  }
  return codice_nome.toUpperCase();
}

export function ottieni_consonanti(str: string) {
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
}

export function ottieni_vocali(str: string) {
  return str.replace(/[^AEIOU]/gi, '');
}

//MODAL CONFIG
export function openDialog(modal: Partial<Modal>) {
  const dialogRef = modal.dialog!.open(ModalComponent, {
    panelClass: 'custom-dialog',
    disableClose: true,
    width: modal.width,
    height: modal.height,
    data: {
      errorCode: modal.errorCode,
      title: modal.title,
      message: modal.message,
      status: modal.status,
      ctaType: modal.ctaType,
      noCopy: modal.noCopy,
      optionalMessage: modal.optionalMessage,
      copyOptionalMessage: modal.copyOptionalMessage,
      typeOptionalMessage: modal.typeOptionalMessage,
      funnelCommodity: modal.funnelCommodity,
      titleStyle: modal.titleStyle,
      messageStyle: modal.messageStyle,
      pointBreak: modal.pointBreak
    }
  });
  return dialogRef;
}

export enum ModalTitles {
  HOMEPAGE = 'Homepage',
  CATALOG = 'Catalogo',
  SCRIPT = 'Script',
  SALES = 'Pre-Check e Vendita',
  INVIO_PREVENTIVO = 'Invio Preventivo',
  IBAN_CHECK = 'Iban check',
  MASSIVE_PRACTICES = 'Gestione Pratiche Massive', //
  PREWIN_BACK_CONF = 'Configuratore prewinback',
  TRACKING_SALES = 'Tracking Pratiche - Sales',
  TRACKING_LUMINEA = 'Tracking Pratiche - Luminea',
  TRACKING_POSTSALES = 'Tracking Pratiche - PostSales',
  POST_SALES = 'Post-Sales',
  KPI_MONITORING = 'Kpi Monitoring',
  TRUSTPILOT_KPI_MONITORING = 'Trustpilot Kpi Monitoring',
  AGENT_PROFILE = 'Anagrafica Agenti',
  TRAINING = 'Formazione',
  LEAD_MANAGEMENT = 'Gestione Lead',
  CUSTOMER_MANAGEMENT = 'Gestione Clienti',
  LEAD_ASSIGN = 'Assegnazione Lead',
  ASSIGNED_CAMPAIGN = 'Campagne Assegnate',
  INSTANT_CALL = 'Instant Call',
  LOGIN = 'Login',
  BOE = 'Boe',
  TENDER = 'Gare',
  AGENCY_MANAGER = 'Configurazione agenzie',
  EDITOR = 'Editor',
  AVAILABILITY = 'Indicazione Disponibilità',
  RPO = 'Esiti RPO',
  FLAT_OFFER = 'Preventivatore FLAT',
  VECCHIA_BOLLETTA = 'SCARICA VECCHIA BOLLETTA',
  DOWNLOAD_LEAD_RC = 'DOWNLOAD LEAD TARGHE',
  ARRICCHIMENTI_LEAD_RC = 'ARRICCHIMENTI LEAD RC',
  REDISPATCH_LEAD = 'RIDISPACCIAMENTO LEAD',
  TRUSTPILOT = 'TRUSTPILOT',
  OVER85 = 'WHITELIST OVER 85'
}

export const ALLACCIO_CODE = '00000000000000';

//GET ERROR MESSAGE
export function getErrorMessage(errorID: string): string {
  switch (errorID) {
    case '400':
      return 'Bad Request';
    case '403':
      return 'Forbidden';
    case '404':
      return 'Page Not Found';
    case '500':
      return 'Internal Server Error';
    case '503':
      return 'Service Unavailable';
    case '999':
      return 'Errore Generico';
    default:
      return 'Error';
  }
}

export enum rcaError {
  FAST_QUOTE_ERROR = '000',
  PLATE_ERROR = '001',
  BIRTHDAY_ERROR = '002',
  AFFINITY_ERROR = '003',
  AFFINITY_EMPTY_ERROR = '004',
  DATA_NON_VALID = '005',
  GENERIC_ERROR = '999'
}

export const consumoAnnuoEleMapping = [
  { potenza: '0.5', domestico: '335', altriUsi: '400' },
  { potenza: '1', domestico: '670', altriUsi: '800' },
  { potenza: '1.5', domestico: '1005', altriUsi: '1200' },
  { potenza: '2', domestico: '1340', altriUsi: '1600' },
  { potenza: '2.5', domestico: '1675', altriUsi: '2000' },
  { potenza: '3', domestico: '2010', altriUsi: '2400' },
  { potenza: '3.5', domestico: '2345', altriUsi: '2800' },
  { potenza: '4', domestico: '2680', altriUsi: '3200' },
  { potenza: '4.5', domestico: '3015', altriUsi: '3600' },
  { potenza: '5', domestico: '3350', altriUsi: '4000' },
  { potenza: '5.5', domestico: '3685', altriUsi: '4400' },
  { potenza: '6', domestico: '4020', altriUsi: '4800' },
  { potenza: '7', domestico: '4690', altriUsi: '5600' },
  { potenza: '8', domestico: '5360', altriUsi: '6400' },
  { potenza: '9', domestico: '6030', altriUsi: '7200' },
  { potenza: '10', domestico: '6700', altriUsi: '8000' },
  { potenza: '15', domestico: '10050', altriUsi: '12000' },
  { potenza: '20', domestico: '13400', altriUsi: '16000' },
  { potenza: '25', domestico: '16750', altriUsi: '20000' },
  { potenza: '30', domestico: '20100', altriUsi: '24000' },
  { potenza: '> 30', domestico: '670', altriUsi: '800' }
];

export const consumoAnnuoGasMapping = [
  { profilo: 'C1 - riscaldamento', consumo: '5001', consumoMax: '' },
  { profilo: 'C2 - cottura cibi e/o produzione acqua calda sanitaria', consumo: '500', consumoMax: '' },
  { profilo: 'C3 - riscaldamento + cottura cibi e/o produzione acqua calda sanitaria', consumo: '1550', consumoMax: '' },
  { profilo: 'C4 - condizionamento', consumo: '1550', consumoMax: '' },
  { profilo: 'C5 - condizionamento + riscaldamento', consumo: '2500', consumoMax: '' },
  { profilo: 'T1 - tecnologico (artigianale - industriale)', consumo: '5000', consumoMax: '' },
  { profilo: 'T2 - tecnologico + riscaldamento', consumo: '5000', consumoMax: '' }
];

export const REFERENT_QUALIFICATION = ['Amministratore Pro Tempore', 'Titolare del contratto', 'Legale Rappresentante Società'];

export const MAGGIOR_TUTELA = 'maggior tutela';

export const TUTELA_GRADUALE = 'tutela graduale';
export const LIBERO = 'libero';

export const TUTELA_GRADUALE_MICROIMPRESE = 'Tutela Graduale Microimprese';

export const ATECO_CODES = [
  '01.11 - Coltivazione di cereali (escluso il riso), legumi da granella e semi oleosi',
  '01.12 - Coltivazione di riso',
  '01.13 - Coltivazione di ortaggi e meloni, radici e tuberi',
  '01.14 - Coltivazione di canna da zucchero',
  '01.15 - Coltivazione di tabacco',
  '01.16 - Coltivazione di piante tessili',
  '01.19 - Floricoltura e coltivazione di altre colture non permanenti',
  '01.21 - Coltivazione di uva',
  '01.22 - Coltivazione di frutta di origine tropicale e subtropicale',
  '01.23 - Coltivazione di agrumi',
  '01.24 - Coltivazione di pomacee e frutta a nocciolo',
  '01.25 - Coltivazione di altri alberi da frutta, frutti di bosco e in guscio',
  '01.26 - Coltivazione di frutti oleosi',
  '01.27 - Coltivazione di piante per la produzione di bevande',
  '01.28 - Coltivazione di spezie, piante aromatiche e farmaceutiche',
  '01.29 - Coltivazione di altre colture permanenti (inclusi alberi di Natale)',
  '01.30 - Riproduzione delle piante',
  '01.41 - Allevamento di bovini da latte',
  '01.42 - Allevamento di altri bovini e di bufalini',
  '01.43 - Allevamento di cavalli e altri equini',
  '01.44 - Allevamento di cammelli e camelidi',
  '01.45 - Allevamento di ovini e caprini',
  '01.46 - Allevamento di suini',
  '01.47 - Allevamento di pollame',
  '01.49 - Allevamento di altri animali',
  "01.50 - Coltivazioni agricole associate all'allevamento di animali: attività mista",
  '01.61 - Attività di supporto alla produzione vegetale',
  '01.62 - Attività di supporto alla produzione animale',
  '01.63 - Attività successive alla raccolta',
  '01.64 - Lavorazione delle sementi per la semina',
  '01.70 - Caccia, cattura di animali e servizi connessi',
  '02.10 - Silvicoltura ed altre attività forestali',
  '02.20 - Utilizzo di aree forestali',
  '02.30 - Raccolta di prodotti selvatici non legnosi',
  '02.40 - Servizi di supporto per la silvicoltura',
  '03.11 - Pesca marina',
  '03.12 - Pesca in acque dolci',
  '03.21 - Acquacoltura marina',
  '03.22 - Acquacoltura in acque dolci',
  '05.10 - Estrazione di antracite',
  '05.20 - Estrazione di lignite',
  '06.10 - Estrazione di petrolio greggio',
  '06.20 - Estrazione di gas naturale',
  '07.10 - Estrazione di minerali metalliferi ferrosi',
  '07.21 - Estrazione di minerali di uranio e di torio',
  '07.29 - Estrazione di altri minerali metalliferi non ferrosi',
  '08.11 - Estrazione di pietre ornamentali e da costruzione, calcare, pietra da gesso, creta e ardesia',
  '08.12 - Estrazione di ghiaia e sabbia; estrazione di argille e caolino',
  "08.91 - Estrazione di minerali per l'industria chimica e per la produzione di fertilizzanti",
  '08.92 - Estrazione di torba',
  '08.93 - Estrazione di sale',
  '08.99 - Estrazione di altri minerali da cave e miniere nca',
  "09.10 - Attività di supporto all'estrazione di petrolio e di gas naturale",
  "09.90 - Attività di supporto per l'estrazione da cave e miniere di altri minerali",
  '10.11 - Lavorazione e conservazione di carne (escluso volatili)',
  '10.12 - Lavorazione e conservazione di carne di volatili',
  '10.13 - Produzione di prodotti a base di carne (inclusa la carne di volatili)',
  '10.20 - Lavorazione e conservazione di pesce, crostacei e molluschi',
  '10.31 - Lavorazione e conservazione delle patate',
  '10.32 - Produzione di succhi di frutta e di ortaggi',
  '10.39 - Altra Lavorazione e conservazione di frutta e di ortaggi',
  '10.41 - Produzione di oli e grassi',
  '10.42 - Produzione di margarina e di grassi commestibili simili',
  '10.51 - Industria lattiero-casearia, trattamento igienico, conservazione del latte',
  '10.52 - Produzione di gelati',
  '10.61 - Lavorazione delle granaglie',
  '10.62 - Produzione di amidi e di prodotti amidacei',
  '10.71 - Produzione di pane; prodotti di pasticceria freschi',
  '10.72 - Produzione di fette biscottate e di biscotti; produzione di prodotti di pasticceria conservati',
  '10.73 - Produzione di paste alimentari, di cuscus e di prodotti farinacei simili',
  '10.81 - Produzione di zucchero',
  '10.82 - Produzione di cacao, cioccolato, caramelle e confetterie',
  '10.83 - Lavorazione del tè e del caffè',
  '10.84 - Produzione di condimenti e spezie',
  '10.85 - Produzione di pasti e piatti preparati',
  '10.86 - Produzione di preparati omogeneizzati e di alimenti dietetici',
  '10.89 - Produzione di prodotti alimentari nca',
  "10.91 - Produzione di mangimi per l'alimentazione degli animali da allevamento",
  "10.92 - Produzione di prodotti per l'alimentazione degli animali da compagnia",
  '11.01 - Distillazione, rettifica e miscelatura degli alcolici',
  '11.02 - Produzione di vini da uve',
  '11.03 - Produzione di sidro e di altri vini a base di frutta',
  '11.04 - Produzione di altre bevande fermentate non distillate',
  '11.05 - Produzione di birra',
  '11.06 - Produzione di malto',
  '11.07 - Industria delle bibite analcoliche, delle acque minerali e di altre acque in bottiglia',
  '12.00 - Industria del tabacco',
  '13.10 - Preparazione e filatura di fibre tessili',
  '13.20 - Tessitura',
  '13.30 - Finissaggio dei tessili',
  '13.91 - Fabbricazione di tessuti a maglia',
  '13.92 - Confezionamento di articoli tessili (esclusi gli articoli di abbigliamento)',
  '13.93 - Fabbricazione di tappeti e moquette',
  '13.94 - Fabbricazione di spago, corde, funi e reti',
  '13.95 - Fabbricazione di tessuti non tessuti e di articoli in tali materie (esclusi gli articoli di abbigliamento)',
  '13.96 - Fabbricazione di articoli tessili tecnici ed industriali',
  '13.99 - Fabbricazione di altri prodotti tessili nca',
  '14.11 - Confezione di abbigliamento in pelle',
  '14.12 - Confezione di indumenti da lavoro',
  '14.13 - Confezione di altro abbigliamento esterno',
  '14.14 - Confezione di biancheria intima',
  '14.19 - Confezione di altri articoli di abbigliamento ed accessori',
  '14.20 - Confezione di articoli in pelliccia',
  '14.31 - Fabbricazione di articoli di calzetteria in maglia',
  '14.39 - Fabbricazione di altri articoli di maglieria',
  '15.11 - Preparazione e concia del cuoio; preparazione e tintura di pellicce',
  '15.12 - Fabbricazione di articoli da viaggio, borse e simili, pelletteria e selleria',
  '15.20 - Fabbricazione di calzature',
  '16.10 - Taglio e piallatura del legno',
  '16.21 - Fabbricazione di fogli da impiallacciatura e di pannelli a base di legno',
  '16.22 - Fabbricazione di pavimenti in parquet assemblato',
  "16.23 - Fabbricazione di altri prodotti di carpenteria in legno e falegnameria per l'edilizia",
  '16.24 - Fabbricazione di imballaggi in legno',
  '16.29 - Fabbricazione di altri prodotti in legno, sughero, paglia e materiali da intreccio',
  '17.11 - Fabbricazione di pasta-carta',
  '17.12 - Fabbricazione di carta e cartone',
  '17.21 - Fabbricazione di carta e cartone ondulato e di imballaggi di carta e cartone',
  '17.22 - Fabbricazione di prodotti igienico-sanitari e per uso domestico in carta e ovatta di cellulosa',
  '17.23 - Fabbricazione di prodotti cartotecnici',
  '17.24 - Fabbricazione di carta da parati',
  '17.29 - Fabbricazione di altri articoli di carta e cartone',
  '18.11 - Stampa di giornali',
  '18.12 - Altra stampa',
  '18.13 - Lavorazioni preliminari alla stampa e ai media',
  '18.14 - Legatoria e servizi connessi',
  '18.20 - Riproduzione di supporti registrati',
  '19.10 - Fabbricazione di prodotti di cokeria',
  '19.20 - Fabbricazione di prodotti derivanti dalla raffinazione del petrolio',
  '20.11 - Fabbricazione di gas industriali',
  '20.12 - Fabbricazione di coloranti e pigmenti',
  '20.13 - Fabbricazione di altri prodotti chimici di base inorganici',
  '20.14 - Fabbricazione di altri prodotti chimici di base organici',
  '20.15 - Fabbricazione di fertilizzanti e composti azotati',
  '20.16 - Fabbricazione di materie plastiche in forme primarie',
  '20.17 - Fabbricazione di gomma sintetica in forme primarie',
  "20.20 - Fabbricazione di agrofarmaci e di altri prodotti chimici per l'agricoltura",
  '20.30 - Fabbricazione di pitture, vernici e smalti, inchiostri da stampa e adesivi sintetici (mastici)',
  '20.41 - Fabbricazione di saponi e detergenti, di prodotti per la pulizia e la lucidatura',
  '20.42 - Fabbricazione di profumi e cosmetici',
  '20.51 - Fabbricazione di esplosivi',
  '20.52 - Fabbricazione di colle',
  '20.53 - Fabbricazione di oli essenziali',
  '20.59 - Fabbricazione di prodotti chimici nca',
  '20.60 - Fabbricazione di fibre sintetiche e artificiali',
  '21.10 - Fabbricazione di prodotti farmaceutici di base',
  '21.20 - Fabbricazione di medicinali e preparati farmaceutici',
  "22.11 - Fabbricazione di pneumatici e camere d'aria; rigenerazione e ricostruzione di pneumatici",
  '22.19 - Fabbricazione di altri prodotti in gomma',
  '22.21 - Fabbricazione di lastre, fogli, tubi e profilati in materie plastiche',
  '22.22 - Fabbricazione di imballaggi in materie plastiche',
  "22.23 - Fabbricazione di articoli in plastica per l'edilizia",
  '22.29 - Fabbricazione di altri articoli in materie plastiche',
  '23.11 - Fabbricazione di vetro piano',
  '23.12 - Lavorazione e trasformazione del vetro piano',
  '23.13 - Fabbricazione di vetro cavo',
  '23.14 - Fabbricazione di fibre di vetro',
  '23.19 - Fabbricazione e lavorazione di altro vetro (incluso vetro per usi tecnici), lavorazione di vetro cavo',
  '23.20 - Fabbricazione di prodotti refrattari',
  '23.31 - Fabbricazione di piastrelle in ceramica per pavimenti e rivestimenti',
  "23.32 - Fabbricazione di mattoni, tegole ed altri prodotti per l'edilizia in terracotta",
  '23.41 - Fabbricazione di prodotti in ceramica per usi domestici e ornamentali',
  '23.42 - Fabbricazione di articoli sanitari in ceramica',
  '23.43 - Fabbricazione di isolatori e di pezzi isolanti in ceramica',
  '23.44 - Fabbricazione di altri prodotti in ceramica per uso tecnico e industriale',
  '23.49 - Fabbricazione di altri prodotti in ceramica',
  '23.51 - Produzione di cemento',
  '23.52 - Produzione di calce e gesso',
  "23.61 - Fabbricazione di prodotti in calcestruzzo per l'edilizia",
  "23.62 - Fabbricazione di prodotti in gesso per l'edilizia",
  "23.63 - Produzione di calcestruzzo pronto per l'uso",
  '23.64 - Produzione di malta',
  '23.65 - Fabbricazione di prodotti in fibrocemento',
  '23.69 - Fabbricazione di altri prodotti in calcestruzzo, gesso e cemento',
  '23.70 - Taglio, modellatura e finitura di pietre',
  '23.91 - Produzione di prodotti abrasivi',
  '23.99 - Fabbricazione di altri prodotti in minerali non metalliferi nca',
  '24.10 - Siderurgia',
  '24.20 - Fabbricazione di tubi, condotti, profilati cavi e relativi accessori in acciaio (esclusi quelli in acciaio colato)',
  '24.31 - Stiratura a freddo di barre',
  '24.32 - Laminazione a freddo di nastri',
  '24.33 - Profilatura mediante formatura o piegatura a freddo; fabbricazione di pannelli stratificati in acciaio',
  '24.34 - Trafilatura a freddo',
  '24.41 - Produzione di metalli preziosi',
  '24.42 - Produzione di alluminio',
  '24.43 - Produzione di piombo, zinco e stagno e semilavorati',
  '24.44 - Produzione di rame',
  '24.45 - Produzione di altri metalli non ferrosi',
  '24.46 - Trattamento dei combustibili nucleari',
  '24.51 - Fusione di ghisa',
  '24.52 - Fusione di acciaio',
  '24.53 - Fusione di metalli leggeri',
  '24.54 - Fusione di altri metalli non ferrosi',
  '25.11 - Fabbricazione di strutture metalliche e di parti di strutture',
  '25.12 - Fabbricazione di porte e finestre in metallo',
  '25.21 - Fabbricazione di radiatori e contenitori in metallo per caldaie per il riscaldamento centrale',
  '25.29 - Fabbricazione di altre cisterne, serbatoi e contenitori in metallo',
  '25.30 - Fabbricazione di generatori di vapore (esclusi i contenitori in metallo per caldaie per il riscaldamento centrale ad acqua calda)',
  '25.40 - Fabbricazione di armi e munizioni',
  '25.50 - Fucinatura, imbutitura, stampaggio e profilatura dei metalli; metallurgia delle polveri',
  '25.61 - Trattamento e rivestimento dei metalli',
  '25.62 - Lavori di meccanica generale',
  '25.71 - Fabbricazione di articoli di coltelleria e posateria',
  '25.72 - Fabbricazione di serrature e cerniere',
  '25.73 - Fabbricazione di utensileria',
  '25.91 - Fabbricazione di bidoni in acciaio e di contenitori analoghi',
  '25.92 - Fabbricazione di imballaggi leggeri in metallo',
  '25.93 - Fabbricazione di prodotti fabbricati con fili metallici, catene e molle',
  '25.94 - Fabbricazione di articoli di bulloneria',
  '25.99 - Fabbricazione di altri prodotti in metallo nca',
  '26.11 - Fabbricazione di componenti elettronici',
  '26.12 - Fabbricazione di schede elettroniche assemblate',
  '26.20 - Fabbricazione di computer e unità periferiche',
  '26.30 - Fabbricazione di apparecchiature per le telecomunicazioni',
  '26.40 - Fabbricazione di prodotti di elettronica di consumo audio e video',
  '26.51 - Fabbricazione di strumenti e apparecchi di misurazione, prova e navigazione (esclusi quelli ottici)',
  '26.52 - Fabbricazione di orologi',
  '26.60 - Fabbricazione di strumenti per irradiazione, apparecchiature elettromedicali ed elettroterapeutiche',
  '26.70 - Fabbricazione di strumenti ottici e attrezzature fotografiche',
  '26.80 - Fabbricazione di supporti magnetici ed ottici',
  '27.11 - Fabbricazione di motori, generatori e trasformatori elettrici',
  "27.12 - Fabbricazione di apparecchiature per le reti di distribuzione e il controllo dell'elettricità",
  '27.20 - Fabbricazione di batterie di pile ed accumulatori elettrici',
  '27.31 - Fabbricazione di cavi a fibra ottica',
  '27.32 - Fabbricazione di altri fili e cavi elettrici ed elettronici',
  '27.33 - Fabbricazione di attrezzature per cablaggio',
  '27.40 - Fabbricazione di apparecchiature per illuminazione',
  '27.51 - Fabbricazione di elettrodomestici',
  '27.52 - Fabbricazione di apparecchi per uso domestico non elettrici',
  '27.90 - Fabbricazione di altre apparecchiature elettriche',
  '28.11 - Fabbricazione di motori e turbine (esclusi i motori per aeromobili, veicoli e motocicli)',
  '28.12 - Fabbricazione di apparecchiature fluidodinamiche',
  '28.13 - Fabbricazione di altre pompe e compressori',
  '28.14 - Fabbricazione di altri rubinetti e valvole',
  '28.15 - Fabbricazione di cuscinetti, ingranaggi e organi di trasmissione (esclusi quelli idraulici)',
  '28.21 - Fabbricazione di forni, bruciatori e sistemi di riscaldamento',
  '28.22 - Fabbricazione di macchine e apparecchi di sollevamento e movimentazione',
  '28.23 - Fabbricazione di macchine ed attrezzature per ufficio (esclusi computer e unità periferiche)',
  '28.24 - Fabbricazione di utensili portatili a motore',
  '28.25 - Fabbricazione di attrezzature di uso non domestico per la refrigerazione e la ventilazione',
  '28.29 - Fabbricazione di altre macchine di impiego generale nca',
  "28.30 - Fabbricazione di macchine per l'agricoltura e la silvicoltura",
  '28.41 - Fabbricazione di macchine utensili per la formatura dei metalli',
  '28.49 - Fabbricazione di altre macchine utensili',
  '28.91 - Fabbricazione di macchine per la metallurgia',
  '28.92 - Fabbricazione di macchine da miniera, cava e cantiere',
  "28.93 - Fabbricazione di macchine per l'industria alimentare, delle bevande e del tabacco",
  "28.94 - Fabbricazione di macchine per le industrie tessili, dell'abbigliamento e del cuoio(incluse parti e accessori)",
  "28.95 - Fabbricazione di macchine per l'industria della carta e del cartone(incluse parti e accessori)",
  "28.96 - Fabbricazione di macchine per l'industria delle materie plastiche e della gomma(incluse parti e accessori)",
  '28.99 - Fabbricazione di macchine per impieghi speciali nca (incluse parti e accessori)',
  '29.10 - Fabbricazione di autoveicoli',
  '29.20 - Fabbricazione di carrozzerie per autoveicoli, rimorchi e semirimorchi',
  '29.31 - Fabbricazione di apparecchiature elettriche ed elettroniche per autoveicoli e loro motori',
  '29.32 - Fabbricazione di altre parti ed accessori per autoveicoli',
  '30.11 - Costruzione di navi e di strutture galleggianti',
  '30.12 - Costruzione di imbarcazioni da diporto e sportive',
  '30.20 - Costruzione di locomotive e di materiale rotabile ferro-tranviario',
  '30.30 - Fabbricazione di aeromobili, di veicoli spaziali e dei relativi dispositivi',
  '30.40 - Fabbricazione di veicoli militari da combattimento',
  '30.91 - Fabbricazione di motocicli (inclusi i motori)',
  '30.92 - Fabbricazione di biciclette e veicoli per invalidi',
  '30.99 - Fabbricazione di altri mezzi di trasporto nca',
  '31.01 - Fabbricazione di mobili per ufficio e negozi',
  '31.02 - Fabbricazione di mobili per cucina',
  '31.03 - Fabbricazione di materassi',
  '31.09 - Fabbricazione di altri mobili',
  '32.11 - Coniazione di monete',
  '32.12 - Fabbricazione di oggetti di gioielleria e oreficeria e articoli connessi',
  '32.13 - Fabbricazione di bigiotteria e articoli simili',
  '32.20 - Fabbricazione di strumenti musicali',
  '32.30 - Fabbricazione di articoli sportivi',
  '32.40 - Fabbricazione di giochi e giocattoli',
  '32.50 - Fabbricazione di strumenti e forniture mediche e dentistiche',
  '32.91 - Fabbricazione di scope e spazzole',
  '32.99 - Altre industrie manifatturiere nca',
  '33.11 - Riparazione e manutenzione di prodotti in metallo',
  '33.12 - Riparazione e manutenzione di macchinari',
  '33.13 - Riparazione e manutenzione di apparecchiature elettroniche ed ottiche',
  '33.14 - Riparazione e manutenzione di apparecchiature elettriche',
  '33.15 - Riparazione e manutenzione di navi e imbarcazioni (esclusi i loro motori)',
  '33.16 - Riparazione e manutenzione di aeromobili e di veicoli spaziali',
  '33.17 - Riparazione e manutenzione di locomotive e di materiale rotabile ferro-tranviario (esclusi i loro motori)',
  '33.19 - Riparazione di altre apparecchiature',
  '33.20 - Installazione di macchine ed apparecchiature industriali',
  '35.11 - Produzione di energia elettrica',
  '35.12 - Trasmissione di energia elettrica',
  '35.13 - Distribuzione di energia elettrica',
  '35.14 - Commercio di energia elettrica',
  '35.21 - Produzione di gas',
  '35.22 - Distribuzione di combustibili gassosi mediante condotte',
  '35.23 - Commercio di gas distribuito mediante condotte',
  '35.30 - Fornitura di vapore e aria condizionata',
  '36.00 - Raccolta, trattamento e fornitura di acqua',
  '37.00 - Gestione delle reti fognarie',
  '38.11 - Raccolta di rifiuti non pericolosi',
  '38.12 - Raccolta di rifiuti pericolosi',
  '38.21 - Trattamento e smaltimento di rifiuti non pericolosi',
  '38.22 - Trattamento e smaltimento di rifiuti pericolosi',
  '38.31 - Demolizione di carcasse e cantieri di demolizione navali',
  '38.32 - Recupero e cernita di materiali',
  '39.00 - Attività di risanamento e altri servizi di gestione dei rifiuti',
  '41.10 - Sviluppo di progetti immobiliari',
  '41.20 - Costruzione di edifici residenziali e non residenziali',
  '42.11 - Costruzione di strade e autostrade',
  '42.12 - Costruzione di linee ferroviarie e metropolitane',
  '42.13 - Costruzione di ponti e gallerie',
  '42.21 - Costruzione di opere di pubblica utilità per il trasporto di fluidi',
  "42.22 - Costruzione di opere di pubblica utilità per l'energia elettrica e le telecomunicazioni",
  '42.91 - Costruzione di opere idrauliche',
  '42.99 - Costruzione di altre opere di ingegneria civile nca',
  '43.11 - Demolizione',
  '43.12 - Preparazione del cantiere edile',
  '43.13 - Trivellazioni e perforazioni',
  '43.21 - Installazione di impianti elettrici',
  "43.22 - Installazione di impianti idraulici, di riscaldamento e di condizionamento dell'aria",
  '43.29 - Altri lavori di costruzione e installazione',
  '43.31 - Intonacatura',
  '43.32 - Posa in opera di infissi',
  '43.33 - Rivestimento di pavimenti e di muri',
  '43.34 - Tinteggiatura e posa in opera di vetri',
  '43.39 - Altri lavori di completamento e di finitura degli edifici',
  '43.91 - Realizzazione di coperture',
  '43.99 - Altri lavori specializzati di costruzione nca',
  '45.11 - Commercio di autovetture e di autoveicoli leggeri',
  '45.19 - Commercio di altri autoveicoli',
  '45.20 - Manutenzione e riparazione di autoveicoli',
  "45.31 - Commercio all'ingrosso ed intermediazione di parti e accessori di autoveicoli",
  '45.32 - Commercio al dettaglio di parti e accessori di autoveicoli',
  '45.40 - Commercio, manutenzione e riparazione di motocicli e relative parti ed accessori',
  '46.11 - Intermediari del commercio di materie prime agricole, di animali vivi, di materie prime tessili e di semilavorati',
  '46.12 - Intermediari del commercio di combustibili, minerali, metalli e prodotti chimici',
  '46.13 - Intermediari del commercio di legname e materiali da costruzione',
  '46.14 - Intermediari del commercio di macchinari, impianti industriali, navi e aeromobili',
  '46.15 - Intermediari del commercio di mobili, articoli per la casa e ferramenta',
  '46.16 - Intermediari del commercio di prodotti tessili, abbigliamento, pellicce, calzature e articoli in pelle',
  '46.17 - Intermediari del commercio di prodotti alimentari, bevande e tabacco',
  '46.18 - Intermediari del commercio specializzato in altri prodotti',
  '46.19 - Intermediari del commercio di vari prodotti senza prevalenza di alcuno',
  "46.21 - Commercio all'ingrosso di cereali, tabacco grezzo, sementi e alimenti per il bestiame(mangimi)",
  "46.22 - Commercio all'ingrosso di fiori e piante",
  "46.23 - Commercio all'ingrosso di animali vivi",
  "46.24 - Commercio all'ingrosso di pelli e cuoio",
  "46.31 - Commercio all'ingrosso di frutta e ortaggi freschi o conservati",
  "46.32 - Commercio all'ingrosso di carne e di prodotti a base di carne",
  "46.33 - Commercio all'ingrosso di prodotti lattiero-caseari, uova, oli e grassi commestibili",
  "46.34 - Commercio all'ingrosso di bevande",
  "46.35 - Commercio all'ingrosso di prodotti del tabacco",
  "46.36 - Commercio all'ingrosso di zucchero, cioccolato, dolciumi e prodotti da forno",
  "46.37 - Commercio all'ingrosso di caffè, tè, cacao e spezie",
  "46.38 - Commercio all'ingrosso di altri prodotti alimentari, inclusi pesci, crostacei e molluschi",
  "46.39 - Commercio all'ingrosso non specializzato di prodotti alimentari, bevande e tabacco",
  "46.41 - Commercio all'ingrosso di prodotti tessili",
  "46.42 - Commercio all'ingrosso di abbigliamento e di calzature",
  "46.43 - Commercio all'ingrosso di elettrodomestici, elettronica di consumo audio e video; articoli per fotografia, cinematografia e ottica",
  "46.44 - Commercio all'ingrosso di articoli di porcellana, di vetro e di prodotti per la pulizia",
  "46.45 - Commercio all'ingrosso di profumi e cosmetici",
  "46.46 - Commercio all'ingrosso di prodotti farmaceutici",
  "46.47 - Commercio all'ingrosso di mobili, tappeti e articoli per l'illuminazione",
  "46.48 - Commercio all'ingrosso di orologi e di gioielleria",
  "46.49 - Commercio all'ingrosso di altri beni di consumo",
  "46.51 - Commercio all'ingrosso di computer, apparecchiature informatiche periferiche e di software",
  "46.52 - Commercio all'ingrosso di apparecchiature elettroniche per telecomunicazioni e componenti elettronici",
  "46.61 - Commercio all'ingrosso di macchinari, attrezzature e forniture agricole",
  "46.62 - Commercio all'ingrosso di macchine utensili",
  "46.63 - Commercio all'ingrosso di macchinari per l'estrazione, l'edilizia e l'ingegneria civile",
  "46.64 - Commercio all'ingrosso di macchinari per l'industria tessile, di macchine per cucire e per maglieria",
  "46.65 - Commercio all'ingrosso di mobili per ufficio e negozi",
  "46.66 - Commercio all'ingrosso di altre macchine e attrezzature per ufficio",
  "46.69 - Commercio all'ingrosso di altri macchinari e attrezzature",
  "46.71 - Commercio all'ingrosso di combustibili solidi, liquidi, gassosi e di prodotti derivati",
  "46.72 - Commercio all'ingrosso di metalli e di minerali metalliferi",
  "46.73 - Commercio all'ingrosso di legname e di materiali da costruzione, apparecchi igienico-sanitari, vetro piano, vernici e colori",
  "46.74 - Commercio all'ingrosso di ferramenta, di apparecchi e accessori per impianti idraulici e di riscaldamento",
  "46.75 - Commercio all'ingrosso di prodotti chimici",
  "46.76 - Commercio all'ingrosso di altri prodotti intermedi",
  "46.77 - Commercio all'ingrosso di rottami e cascami",
  "46.90 - Commercio all'ingrosso non specializzato",
  '47.11 - Commercio al dettaglio in esercizi non specializzati con prevalenza di prodotti alimentari e bevande',
  '47.19 - Commercio al dettaglio in altri esercizi non specializzati',
  '47.21 - Commercio al dettaglio di frutta e verdura in esercizi specializzati',
  '47.22 - Commercio al dettaglio di carni e di prodotti a base di carne in esercizi specializzati',
  '47.23 - Commercio al dettaglio di pesci, crostacei e molluschi in esercizi specializzati',
  '47.24 - Commercio al dettaglio di pane, torte, dolciumi e confetteria in esercizi specializzati',
  '47.25 - Commercio al dettaglio di bevande in esercizi specializzati',
  '47.26 - Commercio al dettaglio di prodotti del tabacco in esercizi specializzati',
  '47.29 - Commercio al dettaglio di altri prodotti alimentari in esercizi specializzati',
  '47.30 - Commercio al dettaglio di carburante per autotrazione in esercizi specializzati',
  '47.41 - Commercio al dettaglio di computer, unità periferiche, software e attrezzature per ufficio in esercizi specializzati',
  '47.42 - Commercio al dettaglio di apparecchiature per le telecomunicazioni e la telefonia in esercizi specializzati',
  '47.43 - Commercio al dettaglio di apparecchiature audio e video in esercizi specializzati',
  '47.51 - Commercio al dettaglio di prodotti tessili in esercizi specializzati',
  '47.52 - Commercio al dettaglio di ferramenta, vernici, vetro piano e materiali da costruzione in esercizi specializzati',
  '47.53 - Commercio al dettaglio di tappeti, scendiletto e rivestimenti per pavimenti e pareti (moquette, linoleum) in esercizi specializzati',
  '47.54 - Commercio al dettaglio di elettrodomestici in esercizi specializzati',
  "47.59 - Commercio al dettaglio di mobili, di articoli per l'illuminazione e altri articoli per la casa in esercizi specializzati",
  '47.61 - Commercio al dettaglio di libri in esercizi specializzati',
  '47.62 - Commercio al dettaglio di giornali e articoli di cartoleria in esercizi specializzati',
  '47.63 - Commercio al dettaglio di registrazioni musicali e video in esercizi specializzati',
  '47.64 - Commercio al dettaglio di articoli sportivi in esercizi specializzati',
  '47.65 - Commercio al dettaglio di giochi e giocattoli in esercizi specializzati',
  '47.71 - Commercio al dettaglio di articoli di abbigliamento in esercizi specializzati',
  '47.72 - Commercio al dettaglio di calzature e articoli in pelle in esercizi specializzati',
  '47.73 - Commercio al dettaglio di medicinali in esercizi specializzati',
  '47.74 - Commercio al dettaglio di articoli medicali e ortopedici in esercizi specializzati',
  '47.75 - Commercio al dettaglio di cosmetici, di articoli di profumeria e di erboristeria in esercizi specializzati',
  '47.76 - Commercio al dettaglio di fiori, piante, semi, fertilizzanti, animali domestici e alimenti per animali domestici in esercizi specializzati',
  '47.77 - Commercio al dettaglio di orologi e articoli di gioielleria in esercizi specializzati',
  '47.78 - Commercio al dettaglio di altri prodotti (esclusi quelli di seconda mano) in esercizi specializzati',
  '47.79 - Commercio al dettaglio di articoli di seconda mano in negozi',
  '47.81 - Commercio al dettaglio ambulante di prodotti alimentari e bevande',
  '47.82 - Commercio al dettaglio ambulante di prodotti tessili, abbigliamento e calzature',
  '47.89 - Commercio al dettaglio ambulante di altri prodotti',
  '47.91 - Commercio al dettaglio per corrispondenza o attraverso internet',
  '47.99 - Altro commercio al dettaglio al di fuori di negozi, banchi o mercati',
  '49.10 - Trasporto ferroviario di passeggeri (interurbano)',
  '49.20 - Trasporto ferroviario di merci',
  '49.31 - Trasporto terrestre di passeggeri in aree urbane e suburbane',
  '49.32 - Trasporto con taxi, noleggio di autovetture con conducente',
  '49.39 - Altri trasporti terrestri di passeggeri nca',
  '49.41 - Trasporto di merci su strada',
  '49.42 - Servizi di trasloco',
  '49.50 - Trasporto mediante condotte',
  '50.10 - Trasporto marittimo e costiero di passeggeri',
  '50.20 - Trasporto marittimo e costiero di merci',
  "50.30 - Trasporto di passeggeri per vie d'acqua interne",
  "50.40 - Trasporto di merci per vie d'acqua interne",
  '51.10 - Trasporto aereo di passeggeri',
  '51.21 - Trasporto aereo di merci',
  '51.22 - Trasporto spaziale',
  '52.10 - Magazzinaggio e custodia',
  '52.21 - Attività dei servizi connessi ai trasporti terrestri',
  "52.22 - Attività dei servizi connessi al trasporto marittimo e per vie d'acqua",
  '52.23 - Attività dei servizi connessi al trasporto aereo',
  '52.24 - Movimentazione merci',
  '52.29 - Altre attività di supporto connesse ai trasporti',
  '53.10 - Attività postali con obbligo di servizio universale',
  '53.20 - Altre attività postali e di corriere senza obbligo di servizio universale',
  '55.10 - Alberghi e strutture simili',
  '55.20 - Alloggi per vacanze e altre strutture per brevi soggiorni',
  '55.30 - Aree di campeggio e aree attrezzate per camper e roulotte',
  '55.90 - Altri alloggi',
  '56.10 - Ristoranti e attività di ristorazione mobile',
  '56.21 - Fornitura di pasti preparati (catering per eventi)',
  '56.29 - Mense e catering continuativo su base contrattuale',
  '56.30 - Bar e altri esercizi simili senza cucina',
  '58.11 - Edizione di libri',
  '58.12 - Pubblicazione di elenchi e mailing list',
  '58.13 - Edizione di quotidiani',
  '58.14 - Edizione di riviste e periodici',
  '58.19 - Altre attività editoriali',
  '58.21 - Edizione di giochi per computer',
  '58.29 - Edizione di altri software',
  '59.11 - Attività di produzione cinematografica, di video e di programmi televisivi',
  '59.12 - Attività di post-produzione cinematografica, di video e di programmi televisivi',
  '59.13 - Attività di distribuzione cinematografica, di video e di programmi televisivi',
  '59.14 - Attività di proiezione cinematografica',
  '59.20 - Attività di registrazione sonora e di editoria musicale',
  '60.10 - Trasmissioni radiofoniche',
  '60.20 - Attività di programmazione e trasmissioni televisive',
  '61.10 - Telecomunicazioni fisse',
  '61.20 - Telecomunicazioni mobili',
  '61.30 - Telecomunicazioni satellitari',
  '61.90 - Altre attività di telecomunicazione',
  "62.01 - Produzione di software non connesso all'edizione",
  "62.02 - Consulenza nel settore delle tecnologie dell'informatica",
  '62.03 - Gestione di strutture informatizzate',
  "62.09 - Altre attività dei servizi connessi alle tecnologie dell'informatica",
  '63.11 - Elaborazione dei dati, hosting e attività connesse',
  '63.12 - Portali web',
  '63.91 - Attività delle agenzie di stampa',
  '63.99 - Altre attività dei servizi di informazione nca',
  '64.11 - Attività delle banche centrali',
  '64.19 - Altre intermediazioni monetarie',
  '64.20 - Attività delle società di partecipazione (holding)',
  '64.30 - Società fiduciarie, fondi e altre società simili',
  '64.91 - Leasing finanziario',
  '64.92 - Altre attività creditizie',
  '64.99 - Altre attività di servizi finanziari nca (escluse le assicurazioni e i fondi pensione)',
  '65.11 - Assicurazioni sulla vita',
  '65.12 - Assicurazioni diverse da quelle sulla vita',
  '65.20 - Riassicurazioni',
  '65.30 - Fondi pensione',
  '66.11 - Amministrazione di mercati finanziari',
  '66.12 - Attività di negoziazione di contratti relativi a titoli e merci',
  '66.19 - Altre attività ausiliarie dei servizi finanziari (escluse le assicurazioni e i fondi pensione)',
  '66.21 - Valutazione dei rischi e dei danni',
  '66.22 - Attività di agenti e mediatori di assicurazioni',
  '66.29 - Altre attività ausiliarie delle assicurazioni e dei fondi pensione',
  '66.30 - Attività di gestione dei fondi',
  '68.10 - Compravendita di beni immobili effettuata su beni propri',
  '68.20 - Affitto e gestione di immobili di proprietà o in leasing',
  '68.31 - Attività di mediazione immobiliare',
  '68.32 - Gestione di immobili per conto terzi',
  '69.10 - Attività degli studi legali e notarili',
  '69.20 - Contabilità, controllo e revisione contabile, consulenza in materia fiscale e del lavoro',
  '70.10 - Attività delle holding impegnate nelle attività gestionali (holding operative)',
  '70.21 - Pubbliche relazioni e comunicazione',
  '70.22 - Consulenza imprenditoriale e altra consulenza amministrativo-gestionale',
  '71.11 - Attività degli studi di architettura',
  "71.12 - Attività degli studi d'ingegneria ed altri studi tecnici",
  '71.20 - Collaudi ed analisi tecniche',
  '72.11 - Ricerca e sviluppo sperimentale nel campo delle biotecnologie',
  "72.19 - Altre attività di ricerca e sviluppo sperimentale nel campo delle scienze naturali e dell'ingegneria",
  '72.20 - Ricerca e sviluppo sperimentale nel campo delle scienze sociali e umanistiche',
  '73.11 - Agenzie pubblicitarie',
  '73.12 - Attività delle concessionarie e degli altri intermediari di servizi pubblicitari',
  '73.20 - Ricerche di mercato e sondaggi di opinione',
  '74.10 - Attività di design specializzate',
  '74.20 - Attività fotografiche',
  '74.30 - Traduzione e interpretariato',
  '74.90 - Altre attività professionali, scientifiche e tecniche nca',
  '75.00 - Servizi veterinari',
  '77.11 - Noleggio di autovetture ed autoveicoli leggeri',
  '77.12 - Noleggio di autocarri e di altri veicoli pesanti',
  '77.21 - Noleggio di attrezzature sportive e ricreative',
  '77.22 - Noleggio di videocassette e dischi',
  '77.29 - Noleggio di altri beni per uso personale e domestico (escluse le attrezzature sportive e ricreative)',
  '77.31 - Noleggio di macchine e attrezzature agricole',
  '77.32 - Noleggio di macchine e attrezzature per lavori edili e di genio civile',
  '77.33 - Noleggio di macchine e attrezzature per ufficio (inclusi i computer)',
  '77.34 - Noleggio di mezzi di trasporto marittimo e fluviale',
  '77.35 - Noleggio di mezzi di trasporto aereo',
  '77.39 - Noleggio di altre macchine, attrezzature e beni materiali nca',
  '77.40 - Concessione dei diritti di sfruttamento di proprietà intellettuale e prodotti simili (escluse le opere protette dal copyright)',
  '78.10 - Attività di agenzie di collocamento',
  '78.20 - Attività delle agenzie di lavoro temporaneo (interinale)',
  '78.30 - Altre attività di fornitura e gestione di risorse umane',
  '79.11 - Attività delle agenzie di viaggio',
  '79.12 - Attività dei tour operator',
  '79.90 - Altri servizi di prenotazione e altre attività di assistenza turistica non svolte dalle agenzie di viaggio',
  '80.10 - Servizi di vigilanza privata',
  '80.20 - Servizi connessi ai sistemi di vigilanza',
  '80.30 - Servizi investigativi privati',
  '81.10 - Servizi integrati di gestione agli edifici',
  '81.21 - Pulizia generale (non specializzata) di edifici',
  '81.22 - Attività di pulizia specializzata di edifici e di impianti e macchinari industriali',
  '81.29 - Altre attività di pulizia',
  '81.30 - Cura e manutenzione del paesaggio',
  "82.11 - Servizi integrati di supporto per le funzioni d'ufficio",
  "82.19 - Servizi di fotocopiatura, preparazione di documenti e altre attività di supporto specializzate per le funzioni d'ufficio",
  '82.20 - Attività dei call center',
  '82.30 - Organizzazione di convegni e fiere',
  '82.91 - Attività di agenzie di recupero crediti; agenzie di informazioni commerciali',
  '82.92 - Attività di imballaggio e confezionamento per conto terzi',
  '82.99 - Altri servizi di supporto alle imprese nca',
  '84.11 - Attività generali di amministrazione pubblica',
  "84.12 - Regolamentazione delle attività relative alla fornitura di servizi di assistenza sanitaria, dell'istruzione, di servizi culturali e ad altri servizi sociali(esclusa l'assicurazione sociale obbligatoria)",
  '84.13 - Regolamentazione delle attività che contribuiscono ad una più efficiente gestione delle attività economiche',
  '84.21 - Affari esteri',
  '84.22 - Difesa nazionale',
  '84.23 - Giustizia ed attività giudiziarie',
  '84.24 - Ordine pubblico e sicurezza nazionale',
  '84.25 - Attività dei vigili del fuoco e della protezione civile',
  '84.30 - Assicurazione sociale obbligatoria',
  '85.10 - Istruzione prescolastica',
  '85.20 - Istruzione primaria',
  '85.31 - Istruzione secondaria di formazione generale',
  '85.32 - Istruzione secondaria tecnica e professionale',
  '85.41 - Istruzione post-secondaria non universitaria',
  '85.42 - Istruzione universitaria e post-universitaria; accademie e conservatori',
  '85.51 - Corsi sportivi e ricreativi',
  '85.52 - Formazione culturale',
  '85.53 - Attività delle scuole guida',
  '85.59 - Servizi di istruzione nca',
  "85.60 - Attività di supporto all'istruzione",
  '86.10 - Servizi ospedalieri',
  '86.21 - Servizi degli studi medici di medicina generale',
  '86.22 - Servizi degli studi medici specialistici',
  '86.23 - Attività degli studi odontoiatrici',
  '86.90 - Altri servizi di assistenza sanitaria',
  '87.10 - Strutture di assistenza infermieristica residenziale',
  '87.20 - Strutture di assistenza residenziale per persone affette da ritardi mentali, disturbi mentali o che abusano di sostanze stupefacenti',
  '87.30 - Strutture di assistenza residenziale per anziani e disabili',
  '87.90 - Altre strutture di assistenza sociale residenziale',
  '88.10 - Assistenza sociale non residenziale per anziani e disabili',
  '88.91 - Servizi di asili nido; assistenza diurna per minori disabili',
  '88.99 - Altre attività di assistenza sociale non residenziale nca',
  '90.01 - Rappresentazioni artistiche',
  '90.02 - Attività di supporto alle rappresentazioni artistiche',
  '90.03 - Creazioni artistiche e letterarie',
  '90.04 - Gestione di strutture artistiche',
  '91.01 - Attività di biblioteche ed archivi',
  '91.02 - Attività di musei',
  '91.03 - Gestione di luoghi e monumenti storici e attrazioni simili',
  '91.04 - Attività degli orti botanici, dei giardini zoologici e delle riserve naturali',
  '92.00 - Attività riguardanti le lotterie, le scommesse, le case da gioco',
  '93.11 - Gestione di impianti sportivi',
  '93.12 - Attività di club sportivi',
  '93.13 - Palestre',
  '93.19 - Altre attività sportive',
  '93.21 - Parchi di divertimento e parchi tematici',
  '93.29 - Altre attività ricreative e di divertimento',
  '94.11 - Attività di organizzazioni economiche e di datori di lavoro',
  '94.12 - Attività di organizzazioni associative professionali',
  '94.20 - Attività dei sindacati di lavoratori dipendenti',
  "94.91 - Attività delle organizzazioni religiose nell'esercizio del culto",
  '94.92 - Attività dei partiti e delle associazioni politiche',
  '94.99 - Attività di altre organizzazioni associative nca',
  '95.11 - Riparazione di computer e periferiche',
  '95.12 - Riparazione di apparecchiature per le comunicazioni',
  '95.21 - Riparazione di prodotti elettronici di consumo audio e video',
  '95.22 - Riparazione di elettrodomestici e di articoli per la casa e il giardinaggio',
  '95.23 - Riparazione di calzature e articoli da viaggio',
  '95.24 - Riparazione di mobili e di oggetti di arredamento; laboratori di tappezzeria',
  '95.25 - Riparazione di orologi e di gioielli',
  '95.29 - Riparazione di altri beni per uso personale e per la casa',
  '96.01 - Lavanderia e pulitura di articoli tessili e pelliccia',
  '96.02 - Servizi dei parrucchieri e di altri trattamenti estetici',
  '96.03 - Servizi di pompe funebri e attività connesse',
  '96.04 - Servizi dei centri per il benessere fisico',
  '96.09 - Attività di servizi per la persona nca',
  '97.00 - Attività di famiglie e convivenze come datori di lavoro per personale domestico',
  '98.10 - Produzione di beni indifferenziati per uso proprio da parte di famiglie e convivenze',
  '98.20 - Produzione di servizi indifferenziati per uso proprio da parte di famiglie e convivenze',
  '99.00 - Organizzazioni ed organismi extraterritoriali'
];

export enum corporateFormValues {
  CONDOMINIUM = 'C. - Condominio',
  SPA = 'S.P.A. - Società per azioni',
  USUFRUCTUARY = 'I.I. - Impresa individuale',
  SRL = 'S.R.L. - Società a responsabilità limitata',
  SAS = 'S.A.S. - Società in accomandita semplice',
  SCPA = 'S.C.P.A. - Società cooperativa per azioni',
  SCRL = 'S.C.R.L. - Società cooperativa a responsabilità limitata',
  SNC = 'S.N.C. - Società in nome collettivo',
  SRLS = 'S.R.L.S. - Società a responsabilità limitata semplificata',
  SAPA = 'S.A.P.A. - Società in accomandita per azioni',
  EP = 'E.P. - Ente pubblico',
  LP = 'L.P. - Libero professionista',
  SS = 'S.S. - Società semplice',
  ONLUS = 'O.N.L.U.S. - Organizzazione Non Lucrativa di Utilità Sociale',
  OTHER = 'ALTRO - Consorzio, Associazione, Fondazione, Enti privati'
}
export enum corporateFormViewValues {
  CONDOMINIUM = 'Condominio',
  SPA = 'Società per azioni',
  USUFRUCTUARY = 'Impresa individuale',
  SRL = 'Società a responsabilità limitata',
  SAS = 'Società in accomandita semplice',
  SCPA = 'Società cooperativa per azioni',
  SCRL = 'Società cooperativa a responsabilità limitata',
  SNC = 'Società in nome collettivo',
  SRLS = 'Società a responsabilità limitata semplificata',
  SAPA = 'Società in accomandita per azioni',
  EP = 'Ente pubblico',
  LP = 'Libero professionista',
  SS = 'Società semplice',
  ONLUS = 'Organizzazione Non Lucrativa di Utilità Sociale',
  OTHER = 'Consorzio, Associazione, Fondazione, Enti privati'
}

export enum ProfileRoles {
  OPERATORE_DI_VENDITA = 1,
  TEAM_LEADER = 2,
  IT_IREN = 6,
  SUPERVISOR_BOE_PROCESSI = 7,
  OPERATORE_BOE = 8,
  SUPERVISOR_PROCESSO_IREN = 5,
  CHANNEL_ACCOUNT = 4,
  EDITOR = 9,
  SUPERVISOR_BO_PROCESSO = 3
}

export const SetInfomrativoLumineaMapping = [
  { path: 'assets/pdf/4.Set Informativo Luminea Family.pdf', uatSku: 'SKU-A-00000043', prodSku: 'SKU-A-00000542' },
  { path: 'assets/pdf/4.Set Informativo Luminea Family Plus.pdf', uatSku: 'SKU-A-00000044', prodSku: 'SKU-A-00000543' },
  { path: 'assets/pdf/4.Set Informativo Luminea Infortuni.pdf', uatSku: 'SKU-A-00000042', prodSku: 'SKU-A-00000541' },
  { path: 'assets/pdf/4.Set Informativo Luminea Universo Casa Plus 2609.pdf', uatSku: 'SKU-A-10000556', prodSku: 'SKU-A-00000532' },
  { path: 'assets/pdf/4.Set Informativo Luminea Universo Casa 2609.pdf', uatSku: 'SKU-A-10000555', prodSku: 'SKU-A-00000531' },
  { path: 'assets/pdf/4.Set Informativo Luminea Pronto Casa 2609.pdf', uatSku: 'SKU-A-00005554', prodSku: 'SKU-A-00000530' }
];

export enum SpecialKeys {
  CSVUTF8 = '77u/'
}

export const DateFormatBe = 'yyyy-MM-dd';
export const DateFormatFe = 'dd-MM-yyyy';

export const FormatReferenceDateVerti = 'yyyyMMdd';

export const FormatMatriculationDateVerti = 'yyyyMM';

export const formatDateForVertiUTC = function (dataISO: string) {
  // Creare un oggetto Date dalla stringa ISO
  var data = new Date(dataISO);

  // Estrai i componenti della data
  var anno = data.getUTCFullYear();
  var mese = ('0' + (data.getUTCMonth() + 1)).slice(-2);
  var giorno = ('0' + data.getUTCDate()).slice(-2);
  var ore = ('0' + data.getUTCHours()).slice(-2);
  var minuti = ('0' + data.getUTCMinutes()).slice(-2);
  var secondi = ('0' + data.getUTCSeconds()).slice(-2);
  var millisecondi = ('00' + data.getUTCMilliseconds()).slice(-3);
  var offset = data.getTimezoneOffset();
  var offsetString = (offset < 0 ? '+' : '-') + ('0' + Math.abs(offset / 60)).slice(-2) + ('0' + (offset % 60)).slice(-2);

  // Costruisci la stringa nel formato desiderato
  var dataFormattata = `${anno}-${mese}-${giorno}T${ore}:${minuti}:${secondi}.${millisecondi}${offsetString}`;

  return dataFormattata;
};

export const formatDateFromVerti = function (notCompletedDate: string | number) {
  if (typeof notCompletedDate === 'string') {
    const anno = notCompletedDate.slice(0, 4);
    const mese = notCompletedDate.slice(4);

    const data = `${anno}-${mese}-01T00:00:00.000Z`;

    return data;
  } else if (typeof notCompletedDate === 'number' && notCompletedDate.toString().length > 3) {
    const data = `${notCompletedDate}-01-01T00:00:00.000Z`;
    return data;
  } else {
    const data = `20${notCompletedDate}-01-01T00:00:00.000Z`;
    return data;
  }
};

export const notificationType = [
  {
    key: 'PRESENCE_RESIDUE',
    value: '',
    route: '/my-area/assigned-campaign'
  },
  {
    key: 'TRAINING',
    value: '',
    route: '/my-area/training'
  },
  {
    key: 'BROKEN_INJECTION',
    value: '',
    route: '/my-area/assigned-campaign'
  },
  {
    key: 'ROTTURA_INJECTION_HOT_LEAD',
    value: '',
    route: '/my-area/assigned-campaign'
  },
  {
    key: 'LEAD_DISPATCH',
    value: '',
    route: '/my-area/assigned-campaign'
  },
  {
    key: 'LEAD_OUTCOME',
    value: '',
    route: '/my-area/assigned-campaign'
  },
  {
    key: 'HOT_LEAD_DISPATCH',
    value: '',
    route: '/my-area/lead-management/download-hot-lead'
  },
  {
    key: 'COLD_LEAD_DISPATCH',
    value: '',
    route: '/my-area/lead-management/download-cold-lead'
  },
  {
    key: 'INDICAZIONE_DISPONIBILITA',
    value: '',
    route: '/my-area/lead-management/availability/management/edit'
  },
  {
    key: 'ASSEGNAZIONE_PRATICA_LUMINEA',
    value: '',
    route: '/my-area/tracking-contracts-luminea/sales/draft'
  },
  {
    key: 'LEAD_TO_ENRICH',
    value: '',
    route: '/my-area/lead-management/plate-lead-enrichment-upload'
  },
  {
    key: 'RCA_LEAD_DISPATCH',
    value: '',
    route: '/my-area/lead-management/download-plate-lead'
  },
  {
    key: 'REPORT_RCA_LEAD_AVAILABLE',
    value: '',
    route: '/my-area/lead-management/plate-lead-kpi/estrazione-esiti-targhe'
  },
  {
    key: 'REPORT_AVAILABLE',
    value: 'download-report',
    route: '/my-area/kpi-monitoring/download-report'
  },
  {
    key: 'REPORT_HOT_LEAD_AVAILABLE',
    value: 'estrazioni-esiti-caldi',
    route: '/my-area/lead-management/hot-lead-kpi/estrazione-esiti-caldi'
  },
  {
    key: 'REPORT_COLD_LEAD_AVAILABLE',
    value: 'estrazioni-esiti-freddi',
    route: '/my-area/lead-management/cold-lead-kpi/results-extraction'
  }
];

export enum ClientType {
  BUSINESS = 'Business',
  RESIDENZIALE = 'Residenziale',
  CONDOMINIO = 'Condominio'
}

const serializeArr = (arr: any[]) => {
  return arr.map(obj => {
    return JSON.stringify(obj);
  });
};
export const arrayUnique = (arr: any[]) => {
  let objects = serializeArr(arr);
  let unique = [...new Set(objects)];
  return unique.map(str => {
    return JSON.parse(str);
  });
};

export function mergeArraysUniqueByKey_OLD(array1: any[], array2: any[], key: string): any[] {
  const commercialActionsInArray1 = new Set(array1.map(obj => obj[key]));
  const mergedArray: any[] = array2.filter(obj => commercialActionsInArray1.has(obj[key]));

  return mergedArray;
}

export function mergeArraysUniqueByKey(array1: any[], array2: any[], key: string): any[] {
  const map = new Map();

  // Prima, aggiungiamo tutti gli elementi di array1
  array1.forEach(item => map.set(item[key], item));

  // Poi, aggiungiamo gli elementi di array2 solo se non esistono già in array1
  array2.forEach(item => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });

  return Array.from(map.values());
}

export function getDatesOfTheWeek() {
  const today = new Date(); // crea un oggetto Date rappresentante la data corrente
  const dayOfWeek = today.getDay(); // ottiene il giorno della settimana corrente (0 = Domenica, 1 = Lunedì, ecc.)
  const datesOfWeek = []; // inizializza un array vuoto
  for (let i = 0; i < 7; i++) {
    const date = new Date(today); // crea un nuovo oggetto Date dalla data corrente
    const diff = i - dayOfWeek; // calcola la differenza tra il giorno della settimana corrente e il giorno che si vuole creare
    date.setDate(today.getDate() + diff); // imposta la data come la data corrente più la differenza calcolata
    datesOfWeek.push(date); // aggiunge la data all'array
  }
  return datesOfWeek;
}

//CF CALCULATOR CONSTANTS

export const tavola_mesi = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];
export const alternative_tavola_mesi = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'I', 'T'];

export const tavola_omocodie = ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'];

export const tavola_carattere_di_controllo_valore_caratteri_dispari = {
  0: 1,
  1: 0,
  2: 5,
  3: 7,
  4: 9,
  5: 13,
  6: 15,
  7: 17,
  8: 19,
  9: 21,
  A: 1,
  B: 0,
  C: 5,
  D: 7,
  E: 9,
  F: 13,
  G: 15,
  H: 17,
  I: 19,
  J: 21,
  K: 2,
  L: 4,
  M: 18,
  N: 20,
  O: 11,
  P: 3,
  Q: 6,
  R: 8,
  S: 12,
  T: 14,
  U: 16,
  V: 10,
  W: 22,
  X: 25,
  Y: 24,
  Z: 23
};

export const arrCodiciCatastali = [
  {
    'value': 'A001',
    'viewValue': 'ABANO TERME (PD)'
  },
  {
    'value': 'A004',
    'viewValue': 'ABBADIA CERRETO (LO)'
  },
  {
    'value': 'A005',
    'viewValue': 'ABBADIA LARIANA (LC)'
  },
  {
    'value': 'A006',
    'viewValue': 'ABBADIA SAN SALVATORE (SI)'
  },
  {
    'value': 'A007',
    'viewValue': 'ABBASANTA (OR)'
  },
  {
    'value': 'A008',
    'viewValue': 'ABBATEGGIO (PE)'
  },
  {
    'value': 'A010',
    'viewValue': 'ABBIATEGRASSO (MI)'
  },
  {
    'value': 'A012',
    'viewValue': 'ABETONE (PT)'
  },
  {
    'value': 'A013',
    'viewValue': 'ABRIOLA (PZ)'
  },
  {
    'value': 'A014',
    'viewValue': 'ACATE (RG)'
  },
  {
    'value': 'A015',
    'viewValue': 'ACCADIA (FG)'
  },
  {
    'value': 'A016',
    'viewValue': 'ACCEGLIO (CN)'
  },
  {
    'value': 'A017',
    'viewValue': 'ACCETTURA (MT)'
  },
  {
    'value': 'A018',
    'viewValue': 'ACCIANO (AQ)'
  },
  {
    'value': 'A019',
    'viewValue': 'ACCUMOLI (RI)'
  },
  {
    'value': 'A020',
    'viewValue': 'ACERENZA (PZ)'
  },
  {
    'value': 'A023',
    'viewValue': 'ACERNO (SA)'
  },
  {
    'value': 'A024',
    'viewValue': 'ACERRA (NA)'
  },
  {
    'value': 'A025',
    'viewValue': 'ACI BONACCORSI (CT)'
  },
  {
    'value': 'A026',
    'viewValue': 'ACI CASTELLO (CT)'
  },
  {
    'value': 'A027',
    'viewValue': 'ACI CATENA (CT)'
  },
  {
    'value': 'A029',
    'viewValue': "ACI SANT'ANTONIO (CT)"
  },
  {
    'value': 'A028',
    'viewValue': 'ACIREALE (CT)'
  },
  {
    'value': 'A031',
    'viewValue': 'ACQUACANINA (MC)'
  },
  {
    'value': 'A032',
    'viewValue': 'ACQUAFONDATA (FR)'
  },
  {
    'value': 'A033',
    'viewValue': 'ACQUAFORMOSA (CS)'
  },
  {
    'value': 'A034',
    'viewValue': 'ACQUAFREDDA (BS)'
  },
  {
    'value': 'A035',
    'viewValue': 'ACQUALAGNA (PU)'
  },
  {
    'value': 'A039',
    'viewValue': 'ACQUANEGRA CREMONESE (CR)'
  },
  {
    'value': 'A038',
    'viewValue': 'ACQUANEGRA SUL CHIESE (MN)'
  },
  {
    'value': 'A040',
    'viewValue': 'ACQUAPENDENTE (VT)'
  },
  {
    'value': 'A041',
    'viewValue': 'ACQUAPPESA (CS)'
  },
  {
    'value': 'A042',
    'viewValue': 'ACQUARICA DEL CAPO (LE)'
  },
  {
    'value': 'A043',
    'viewValue': 'ACQUARO (VV)'
  },
  {
    'value': 'A044',
    'viewValue': 'ACQUASANTA TERME (AP)'
  },
  {
    'value': 'A045',
    'viewValue': 'ACQUASPARTA (TR)'
  },
  {
    'value': 'A050',
    'viewValue': 'ACQUAVIVA COLLECROCE (CB)'
  },
  {
    'value': 'A048',
    'viewValue': 'ACQUAVIVA DELLE FONTI (BA)'
  },
  {
    'value': 'A051',
    'viewValue': "ACQUAVIVA D'ISERNIA (IS)"
  },
  {
    'value': 'A047',
    'viewValue': 'ACQUAVIVA PICENA (AP)'
  },
  {
    'value': 'A049',
    'viewValue': 'ACQUAVIVA PLATANI (CL)'
  },
  {
    'value': 'M211',
    'viewValue': 'ACQUEDOLCI (ME)'
  },
  {
    'value': 'A052',
    'viewValue': 'ACQUI TERME (AL)'
  },
  {
    'value': 'A053',
    'viewValue': 'ACRI (CS)'
  },
  {
    'value': 'A054',
    'viewValue': 'ACUTO (FR)'
  },
  {
    'value': 'A055',
    'viewValue': 'ADELFIA (BA)'
  },
  {
    'value': 'A056',
    'viewValue': 'ADRANO (CT)'
  },
  {
    'value': 'A057',
    'viewValue': 'ADRARA SAN MARTINO (BG)'
  },
  {
    'value': 'A058',
    'viewValue': 'ADRARA SAN ROCCO (BG)'
  },
  {
    'value': 'A059',
    'viewValue': 'ADRIA (RO)'
  },
  {
    'value': 'A060',
    'viewValue': 'ADRO (BS)'
  },
  {
    'value': 'A061',
    'viewValue': 'AFFI (VR)'
  },
  {
    'value': 'A062',
    'viewValue': 'AFFILE (RM)'
  },
  {
    'value': 'A064',
    'viewValue': 'AFRAGOLA (NA)'
  },
  {
    'value': 'A065',
    'viewValue': 'AFRICO (RC)'
  },
  {
    'value': 'A067',
    'viewValue': 'AGAZZANO (PC)'
  },
  {
    'value': 'A068',
    'viewValue': 'AGEROLA (NA)'
  },
  {
    'value': 'A069',
    'viewValue': 'AGGIUS (SS)'
  },
  {
    'value': 'A070',
    'viewValue': 'AGIRA (EN)'
  },
  {
    'value': 'A071',
    'viewValue': 'AGLIANA (PT)'
  },
  {
    'value': 'A072',
    'viewValue': 'AGLIANO TERME (AT)'
  },
  {
    'value': 'A074',
    'viewValue': "AGLIE' (TO)"
  },
  {
    'value': 'H848',
    'viewValue': 'AGLIENTU (SS)'
  },
  {
    'value': 'A075',
    'viewValue': 'AGNA (PD)'
  },
  {
    'value': 'A076',
    'viewValue': 'AGNADELLO (CR)'
  },
  {
    'value': 'A077',
    'viewValue': 'AGNANA CALABRA (RC)'
  },
  {
    'value': 'A080',
    'viewValue': 'AGNONE (IS)'
  },
  {
    'value': 'A082',
    'viewValue': 'AGNOSINE (BS)'
  },
  {
    'value': 'A083',
    'viewValue': 'AGORDO (BL)'
  },
  {
    'value': 'A084',
    'viewValue': 'AGOSTA (RM)'
  },
  {
    'value': 'A085',
    'viewValue': 'AGRA (VA)'
  },
  {
    'value': 'A087',
    'viewValue': 'AGRATE BRIANZA (MI)'
  },
  {
    'value': 'A088',
    'viewValue': 'AGRATE CONTURBIA (NO)'
  },
  {
    'value': 'A089',
    'viewValue': 'AGRIGENTO (AG)'
  },
  {
    'value': 'A091',
    'viewValue': 'AGROPOLI (SA)'
  },
  {
    'value': 'A092',
    'viewValue': 'AGUGLIANO (AN)'
  },
  {
    'value': 'A093',
    'viewValue': 'AGUGLIARO (VI)'
  },
  {
    'value': 'A096',
    'viewValue': 'AICURZIO (MI)'
  },
  {
    'value': 'A097',
    'viewValue': 'AIDOMAGGIORE (OR)'
  },
  {
    'value': 'A098',
    'viewValue': 'AIDONE (EN)'
  },
  {
    'value': 'A100',
    'viewValue': 'AIELLI (AQ)'
  },
  {
    'value': 'A102',
    'viewValue': 'AIELLO CALABRO (CS)'
  },
  {
    'value': 'A103',
    'viewValue': 'AIELLO DEL FRIULI (UD)'
  },
  {
    'value': 'A101',
    'viewValue': 'AIELLO DEL SABATO (AV)'
  },
  {
    'value': 'A105',
    'viewValue': 'AIETA (CS)'
  },
  {
    'value': 'A106',
    'viewValue': 'AILANO (CE)'
  },
  {
    'value': 'A107',
    'viewValue': 'AILOCHE (BI)'
  },
  {
    'value': 'A109',
    'viewValue': 'AIRASCA (TO)'
  },
  {
    'value': 'A110',
    'viewValue': 'AIROLA (BN)'
  },
  {
    'value': 'A111',
    'viewValue': 'AIROLE (IM)'
  },
  {
    'value': 'A112',
    'viewValue': 'AIRUNO (LC)'
  },
  {
    'value': 'A113',
    'viewValue': 'AISONE (CN)'
  },
  {
    'value': 'A116',
    'viewValue': 'ALA (TN)'
  },
  {
    'value': 'A115',
    'viewValue': "ALA' DEI SARDI (SS)"
  },
  {
    'value': 'A117',
    'viewValue': 'ALA DI STURA (TO)'
  },
  {
    'value': 'A118',
    'viewValue': 'ALAGNA (PV)'
  },
  {
    'value': 'A119',
    'viewValue': 'ALAGNA VALSESIA (VC)'
  },
  {
    'value': 'A120',
    'viewValue': 'ALANNO (PE)'
  },
  {
    'value': 'A121',
    'viewValue': 'ALANO DI PIAVE (BL)'
  },
  {
    'value': 'A122',
    'viewValue': 'ALASSIO (SV)'
  },
  {
    'value': 'A123',
    'viewValue': 'ALATRI (FR)'
  },
  {
    'value': 'A124',
    'viewValue': 'ALBA (CN)'
  },
  {
    'value': 'A125',
    'viewValue': 'ALBA ADRIATICA (TE)'
  },
  {
    'value': 'A126',
    'viewValue': 'ALBAGIARA (OR)'
  },
  {
    'value': 'A127',
    'viewValue': 'ALBAIRATE (MI)'
  },
  {
    'value': 'A128',
    'viewValue': 'ALBANELLA (SA)'
  },
  {
    'value': 'A131',
    'viewValue': 'ALBANO DI LUCANIA (PZ)'
  },
  {
    'value': 'A132',
    'viewValue': 'ALBANO LAZIALE (RM)'
  },
  {
    'value': 'A129',
    'viewValue': "ALBANO SANT'ALESSANDRO (BG)"
  },
  {
    'value': 'A130',
    'viewValue': 'ALBANO VERCELLESE (VC)'
  },
  {
    'value': 'A134',
    'viewValue': 'ALBAREDO ARNABOLDI (PV)'
  },
  {
    'value': 'A137',
    'viewValue': "ALBAREDO D'ADIGE (VR)"
  },
  {
    'value': 'A135',
    'viewValue': 'ALBAREDO PER SAN MARCO (SO)'
  },
  {
    'value': 'A138',
    'viewValue': 'ALBARETO (PR)'
  },
  {
    'value': 'A139',
    'viewValue': 'ALBARETTO DELLA TORRE (CN)'
  },
  {
    'value': 'A143',
    'viewValue': 'ALBAVILLA (CO)'
  },
  {
    'value': 'A145',
    'viewValue': 'ALBENGA (SV)'
  },
  {
    'value': 'A146',
    'viewValue': 'ALBERA LIGURE (AL)'
  },
  {
    'value': 'A149',
    'viewValue': 'ALBEROBELLO (BA)'
  },
  {
    'value': 'A150',
    'viewValue': 'ALBERONA (FG)'
  },
  {
    'value': 'A153',
    'viewValue': 'ALBESE CON CASSANO (CO)'
  },
  {
    'value': 'A154',
    'viewValue': 'ALBETTONE (VI)'
  },
  {
    'value': 'A155',
    'viewValue': 'ALBI (CZ)'
  },
  {
    'value': 'A158',
    'viewValue': 'ALBIANO (TN)'
  },
  {
    'value': 'A157',
    'viewValue': "ALBIANO D'IVREA (TO)"
  },
  {
    'value': 'A159',
    'viewValue': 'ALBIATE (MI)'
  },
  {
    'value': 'A160',
    'viewValue': 'ALBIDONA (CS)'
  },
  {
    'value': 'A161',
    'viewValue': 'ALBIGNASEGO (PD)'
  },
  {
    'value': 'A162',
    'viewValue': 'ALBINEA (RE)'
  },
  {
    'value': 'A163',
    'viewValue': 'ALBINO (BG)'
  },
  {
    'value': 'A164',
    'viewValue': 'ALBIOLO (CO)'
  },
  {
    'value': 'A166',
    'viewValue': 'ALBISOLA SUPERIORE (SV)'
  },
  {
    'value': 'A165',
    'viewValue': 'ALBISSOLA MARINA (SV)'
  },
  {
    'value': 'A167',
    'viewValue': 'ALBIZZATE (VA)'
  },
  {
    'value': 'A171',
    'viewValue': 'ALBONESE (PV)'
  },
  {
    'value': 'A172',
    'viewValue': 'ALBOSAGGIA (SO)'
  },
  {
    'value': 'A173',
    'viewValue': 'ALBUGNANO (AT)'
  },
  {
    'value': 'A175',
    'viewValue': 'ALBUZZANO (PV)'
  },
  {
    'value': 'A176',
    'viewValue': 'ALCAMO (TP)'
  },
  {
    'value': 'A177',
    'viewValue': 'ALCARA LI FUSI (ME)'
  },
  {
    'value': 'A178',
    'viewValue': 'ALDENO (TN)'
  },
  {
    'value': 'A179',
    'viewValue': 'ALDINO .ALDEIN. (BZ)'
  },
  {
    'value': 'A180',
    'viewValue': 'ALES (OR)'
  },
  {
    'value': 'A182',
    'viewValue': 'ALESSANDRIA (AL)'
  },
  {
    'value': 'A183',
    'viewValue': 'ALESSANDRIA DEL CARRETTO (CS)'
  },
  {
    'value': 'A181',
    'viewValue': 'ALESSANDRIA DELLA ROCCA (AG)'
  },
  {
    'value': 'A184',
    'viewValue': 'ALESSANO (LE)'
  },
  {
    'value': 'A185',
    'viewValue': 'ALEZIO (LE)'
  },
  {
    'value': 'A186',
    'viewValue': 'ALFANO (SA)'
  },
  {
    'value': 'A187',
    'viewValue': 'ALFEDENA (AQ)'
  },
  {
    'value': 'A188',
    'viewValue': 'ALFIANELLO (BS)'
  },
  {
    'value': 'A189',
    'viewValue': 'ALFIANO NATTA (AL)'
  },
  {
    'value': 'A191',
    'viewValue': 'ALFONSINE (RA)'
  },
  {
    'value': 'A192',
    'viewValue': 'ALGHERO (SS)'
  },
  {
    'value': 'A193',
    'viewValue': 'ALGUA (BG)'
  },
  {
    'value': 'A194',
    'viewValue': "ALI' (ME)"
  },
  {
    'value': 'A201',
    'viewValue': "ALI' TERME (ME)"
  },
  {
    'value': 'A195',
    'viewValue': 'ALIA (PA)'
  },
  {
    'value': 'A196',
    'viewValue': 'ALIANO (MT)'
  },
  {
    'value': 'A197',
    'viewValue': 'ALICE BEL COLLE (AL)'
  },
  {
    'value': 'A198',
    'viewValue': 'ALICE CASTELLO (VC)'
  },
  {
    'value': 'A199',
    'viewValue': 'ALICE SUPERIORE (TO)'
  },
  {
    'value': 'A200',
    'viewValue': 'ALIFE (CE)'
  },
  {
    'value': 'A202',
    'viewValue': 'ALIMENA (PA)'
  },
  {
    'value': 'A203',
    'viewValue': 'ALIMINUSA (PA)'
  },
  {
    'value': 'A204',
    'viewValue': 'ALLAI (OR)'
  },
  {
    'value': 'A206',
    'viewValue': 'ALLEGHE (BL)'
  },
  {
    'value': 'A205',
    'viewValue': 'ALLEIN (AO)'
  },
  {
    'value': 'A207',
    'viewValue': 'ALLERONA (TR)'
  },
  {
    'value': 'A208',
    'viewValue': 'ALLISTE (LE)'
  },
  {
    'value': 'A210',
    'viewValue': 'ALLUMIERE (RM)'
  },
  {
    'value': 'A211',
    'viewValue': "ALLUVIONI CAMBIO' (AL)"
  },
  {
    'value': 'A214',
    'viewValue': "ALME' (BG)"
  },
  {
    'value': 'A216',
    'viewValue': 'ALMENNO SAN BARTOLOMEO (BG)'
  },
  {
    'value': 'A217',
    'viewValue': 'ALMENNO SAN SALVATORE (BG)'
  },
  {
    'value': 'A218',
    'viewValue': 'ALMESE (TO)'
  },
  {
    'value': 'A220',
    'viewValue': 'ALONTE (VI)'
  },
  {
    'value': 'A221',
    'viewValue': 'ALPETTE (TO)'
  },
  {
    'value': 'A222',
    'viewValue': 'ALPIGNANO (TO)'
  },
  {
    'value': 'A223',
    'viewValue': 'ALSENO (PC)'
  },
  {
    'value': 'A224',
    'viewValue': 'ALSERIO (CO)'
  },
  {
    'value': 'A225',
    'viewValue': 'ALTAMURA (BA)'
  },
  {
    'value': 'A226',
    'viewValue': 'ALTARE (SV)'
  },
  {
    'value': 'A228',
    'viewValue': 'ALTAVILLA IRPINA (AV)'
  },
  {
    'value': 'A229',
    'viewValue': 'ALTAVILLA MILICIA (PA)'
  },
  {
    'value': 'A227',
    'viewValue': 'ALTAVILLA MONFERRATO (AL)'
  },
  {
    'value': 'A230',
    'viewValue': 'ALTAVILLA SILENTINA (SA)'
  },
  {
    'value': 'A231',
    'viewValue': 'ALTAVILLA VICENTINA (VI)'
  },
  {
    'value': 'A233',
    'viewValue': 'ALTIDONA (AP)'
  },
  {
    'value': 'A234',
    'viewValue': 'ALTILIA (CS)'
  },
  {
    'value': 'A235',
    'viewValue': 'ALTINO (CH)'
  },
  {
    'value': 'A236',
    'viewValue': 'ALTISSIMO (VI)'
  },
  {
    'value': 'A237',
    'viewValue': 'ALTIVOLE (TV)'
  },
  {
    'value': 'A238',
    'viewValue': 'ALTO (CN)'
  },
  {
    'value': 'A239',
    'viewValue': 'ALTOFONTE (PA)'
  },
  {
    'value': 'A240',
    'viewValue': 'ALTOMONTE (CS)'
  },
  {
    'value': 'A241',
    'viewValue': 'ALTOPASCIO (LU)'
  },
  {
    'value': 'A242',
    'viewValue': 'ALVIANO (TR)'
  },
  {
    'value': 'A243',
    'viewValue': 'ALVIGNANO (CE)'
  },
  {
    'value': 'A244',
    'viewValue': 'ALVITO (FR)'
  },
  {
    'value': 'A246',
    'viewValue': 'ALZANO LOMBARDO (BG)'
  },
  {
    'value': 'A245',
    'viewValue': 'ALZANO SCRIVIA (AL)'
  },
  {
    'value': 'A249',
    'viewValue': 'ALZATE BRIANZA (CO)'
  },
  {
    'value': 'A251',
    'viewValue': 'AMALFI (SA)'
  },
  {
    'value': 'A252',
    'viewValue': 'AMANDOLA (AP)'
  },
  {
    'value': 'A253',
    'viewValue': 'AMANTEA (CS)'
  },
  {
    'value': 'A254',
    'viewValue': 'AMARO (UD)'
  },
  {
    'value': 'A255',
    'viewValue': 'AMARONI (CZ)'
  },
  {
    'value': 'A256',
    'viewValue': 'AMASENO (FR)'
  },
  {
    'value': 'A257',
    'viewValue': 'AMATO (CZ)'
  },
  {
    'value': 'A258',
    'viewValue': 'AMATRICE (RI)'
  },
  {
    'value': 'A259',
    'viewValue': 'AMBIVERE (BG)'
  },
  {
    'value': 'A260',
    'viewValue': 'AMBLAR (TN)'
  },
  {
    'value': 'A261',
    'viewValue': 'AMEGLIA (SP)'
  },
  {
    'value': 'A262',
    'viewValue': 'AMELIA (TR)'
  },
  {
    'value': 'A263',
    'viewValue': 'AMENDOLARA (CS)'
  },
  {
    'value': 'A264',
    'viewValue': 'AMENO (NO)'
  },
  {
    'value': 'A265',
    'viewValue': 'AMOROSI (BN)'
  },
  {
    'value': 'A267',
    'viewValue': 'AMPEZZO (UD)'
  },
  {
    'value': 'A268',
    'viewValue': 'ANACAPRI (NA)'
  },
  {
    'value': 'A269',
    'viewValue': 'ANAGNI (FR)'
  },
  {
    'value': 'A270',
    'viewValue': 'ANCARANO (TE)'
  },
  {
    'value': 'A271',
    'viewValue': 'ANCONA (AN)'
  },
  {
    'value': 'A272',
    'viewValue': 'ANDALI (CZ)'
  },
  {
    'value': 'A274',
    'viewValue': 'ANDALO (TN)'
  },
  {
    'value': 'A273',
    'viewValue': 'ANDALO VALTELLINO (SO)'
  },
  {
    'value': 'A275',
    'viewValue': 'ANDEZENO (TO)'
  },
  {
    'value': 'A278',
    'viewValue': 'ANDORA (SV)'
  },
  {
    'value': 'A280',
    'viewValue': 'ANDORNO MICCA (BI)'
  },
  {
    'value': 'A281',
    'viewValue': 'ANDRANO (LE)'
  },
  {
    'value': 'A282',
    'viewValue': 'ANDRATE (TO)'
  },
  {
    'value': 'A283',
    'viewValue': 'ANDREIS (PN)'
  },
  {
    'value': 'A284',
    'viewValue': 'ANDRETTA (AV)'
  },
  {
    'value': 'A285',
    'viewValue': 'ANDRIA (BA)'
  },
  {
    'value': 'A286',
    'viewValue': 'ANDRIANO .ANDRIAN. (BZ)'
  },
  {
    'value': 'A287',
    'viewValue': 'ANELA (SS)'
  },
  {
    'value': 'A288',
    'viewValue': 'ANFO (BS)'
  },
  {
    'value': 'A290',
    'viewValue': 'ANGERA (VA)'
  },
  {
    'value': 'A291',
    'viewValue': 'ANGHIARI (AR)'
  },
  {
    'value': 'A292',
    'viewValue': 'ANGIARI (VR)'
  },
  {
    'value': 'A293',
    'viewValue': 'ANGOLO TERME (BS)'
  },
  {
    'value': 'A294',
    'viewValue': 'ANGRI (SA)'
  },
  {
    'value': 'A295',
    'viewValue': 'ANGROGNA (TO)'
  },
  {
    'value': 'A297',
    'viewValue': 'ANGUILLARA SABAZIA (RM)'
  },
  {
    'value': 'A296',
    'viewValue': 'ANGUILLARA VENETA (PD)'
  },
  {
    'value': 'A299',
    'viewValue': 'ANNICCO (CR)'
  },
  {
    'value': 'A301',
    'viewValue': 'ANNONE DI BRIANZA (LC)'
  },
  {
    'value': 'A302',
    'viewValue': 'ANNONE VENETO (VE)'
  },
  {
    'value': 'A303',
    'viewValue': 'ANOIA (RC)'
  },
  {
    'value': 'A304',
    'viewValue': 'ANTEGNATE (BG)'
  },
  {
    'value': 'A306',
    'viewValue': 'ANTERIVO .ALTREI. (BZ)'
  },
  {
    'value': 'A305',
    'viewValue': "ANTEY-SAINT-ANDRE' (AO)"
  },
  {
    'value': 'A309',
    'viewValue': 'ANTICOLI CORRADO (RM)'
  },
  {
    'value': 'A312',
    'viewValue': 'ANTIGNANO (AT)'
  },
  {
    'value': 'A313',
    'viewValue': 'ANTILLO (ME)'
  },
  {
    'value': 'A314',
    'viewValue': 'ANTONIMINA (RC)'
  },
  {
    'value': 'A315',
    'viewValue': 'ANTRODOCO (RI)'
  },
  {
    'value': 'A317',
    'viewValue': 'ANTRONA SCHIERANCO (VB)'
  },
  {
    'value': 'A318',
    'viewValue': 'ANVERSA DEGLI ABRUZZI (AQ)'
  },
  {
    'value': 'A319',
    'viewValue': 'ANZANO DEL PARCO (CO)'
  },
  {
    'value': 'A320',
    'viewValue': 'ANZANO DI PUGLIA (FG)'
  },
  {
    'value': 'A321',
    'viewValue': 'ANZI (PZ)'
  },
  {
    'value': 'A323',
    'viewValue': 'ANZIO (RM)'
  },
  {
    'value': 'A324',
    'viewValue': "ANZOLA DELL'EMILIA (BO)"
  },
  {
    'value': 'A325',
    'viewValue': "ANZOLA D'OSSOLA (VB)"
  },
  {
    'value': 'A326',
    'viewValue': 'AOSTA (AO)'
  },
  {
    'value': 'A327',
    'viewValue': 'APECCHIO (PU)'
  },
  {
    'value': 'A328',
    'viewValue': 'APICE (BN)'
  },
  {
    'value': 'A329',
    'viewValue': 'APIRO (MC)'
  },
  {
    'value': 'A330',
    'viewValue': 'APOLLOSA (BN)'
  },
  {
    'value': 'A333',
    'viewValue': 'APPIANO GENTILE (CO)'
  },
  {
    'value': 'A332',
    'viewValue': 'APPIANO SULLA STRADA DEL VINO .EPPAN AN DER. (BZ)'
  },
  {
    'value': 'A334',
    'viewValue': 'APPIGNANO (MC)'
  },
  {
    'value': 'A335',
    'viewValue': 'APPIGNANO DEL TRONTO (AP)'
  },
  {
    'value': 'A337',
    'viewValue': 'APRICA (SO)'
  },
  {
    'value': 'A338',
    'viewValue': 'APRICALE (IM)'
  },
  {
    'value': 'A339',
    'viewValue': 'APRICENA (FG)'
  },
  {
    'value': 'A340',
    'viewValue': 'APRIGLIANO (CS)'
  },
  {
    'value': 'A341',
    'viewValue': 'APRILIA (LT)'
  },
  {
    'value': 'A343',
    'viewValue': 'AQUARA (SA)'
  },
  {
    'value': 'A344',
    'viewValue': "AQUILA D'ARROSCIA (IM)"
  },
  {
    'value': 'A346',
    'viewValue': 'AQUILEIA (UD)'
  },
  {
    'value': 'A347',
    'viewValue': 'AQUILONIA (AV)'
  },
  {
    'value': 'A348',
    'viewValue': 'AQUINO (FR)'
  },
  {
    'value': 'A350',
    'viewValue': 'ARADEO (LE)'
  },
  {
    'value': 'A351',
    'viewValue': 'ARAGONA (AG)'
  },
  {
    'value': 'A352',
    'viewValue': 'ARAMENGO (AT)'
  },
  {
    'value': 'A354',
    'viewValue': 'ARBA (PN)'
  },
  {
    'value': 'A357',
    'viewValue': 'ARBOREA (OR)'
  },
  {
    'value': 'A358',
    'viewValue': 'ARBORIO (VC)'
  },
  {
    'value': 'A359',
    'viewValue': 'ARBUS (CA)'
  },
  {
    'value': 'A360',
    'viewValue': 'ARCADE (TV)'
  },
  {
    'value': 'A363',
    'viewValue': 'ARCE (FR)'
  },
  {
    'value': 'A365',
    'viewValue': 'ARCENE (BG)'
  },
  {
    'value': 'A366',
    'viewValue': 'ARCEVIA (AN)'
  },
  {
    'value': 'A367',
    'viewValue': 'ARCHI (CH)'
  },
  {
    'value': 'A369',
    'viewValue': 'ARCIDOSSO (GR)'
  },
  {
    'value': 'A370',
    'viewValue': 'ARCINAZZO ROMANO (RM)'
  },
  {
    'value': 'A371',
    'viewValue': 'ARCISATE (VA)'
  },
  {
    'value': 'A372',
    'viewValue': 'ARCO (TN)'
  },
  {
    'value': 'A373',
    'viewValue': 'ARCOLA (SP)'
  },
  {
    'value': 'A374',
    'viewValue': 'ARCOLE (VR)'
  },
  {
    'value': 'A375',
    'viewValue': 'ARCONATE (MI)'
  },
  {
    'value': 'A376',
    'viewValue': 'ARCORE (MI)'
  },
  {
    'value': 'A377',
    'viewValue': 'ARCUGNANO (VI)'
  },
  {
    'value': 'A379',
    'viewValue': 'ARDARA (SS)'
  },
  {
    'value': 'A380',
    'viewValue': 'ARDAULI (OR)'
  },
  {
    'value': 'M213',
    'viewValue': 'ARDEA (RM)'
  },
  {
    'value': 'A382',
    'viewValue': 'ARDENNO (SO)'
  },
  {
    'value': 'A383',
    'viewValue': 'ARDESIO (BG)'
  },
  {
    'value': 'A385',
    'viewValue': 'ARDORE (RC)'
  },
  {
    'value': 'A386',
    'viewValue': 'ARENA (VV)'
  },
  {
    'value': 'A387',
    'viewValue': 'ARENA PO (PV)'
  },
  {
    'value': 'A388',
    'viewValue': 'ARENZANO (GE)'
  },
  {
    'value': 'A389',
    'viewValue': 'ARESE (MI)'
  },
  {
    'value': 'A390',
    'viewValue': 'AREZZO (AR)'
  },
  {
    'value': 'A391',
    'viewValue': 'ARGEGNO (CO)'
  },
  {
    'value': 'A392',
    'viewValue': 'ARGELATO (BO)'
  },
  {
    'value': 'A393',
    'viewValue': 'ARGENTA (FE)'
  },
  {
    'value': 'A394',
    'viewValue': 'ARGENTERA (CN)'
  },
  {
    'value': 'A396',
    'viewValue': 'ARGUELLO (CN)'
  },
  {
    'value': 'A397',
    'viewValue': 'ARGUSTO (CZ)'
  },
  {
    'value': 'A398',
    'viewValue': 'ARI (CH)'
  },
  {
    'value': 'A399',
    'viewValue': 'ARIANO IRPINO (AV)'
  },
  {
    'value': 'A400',
    'viewValue': 'ARIANO NEL POLESINE (RO)'
  },
  {
    'value': 'A401',
    'viewValue': 'ARICCIA (RM)'
  },
  {
    'value': 'A402',
    'viewValue': 'ARIELLI (CH)'
  },
  {
    'value': 'A403',
    'viewValue': 'ARIENZO (CE)'
  },
  {
    'value': 'A405',
    'viewValue': 'ARIGNANO (TO)'
  },
  {
    'value': 'A407',
    'viewValue': 'ARITZO (NU)'
  },
  {
    'value': 'A409',
    'viewValue': 'ARIZZANO (VB)'
  },
  {
    'value': 'A412',
    'viewValue': 'ARLENA DI CASTRO (VT)'
  },
  {
    'value': 'A413',
    'viewValue': 'ARLUNO (MI)'
  },
  {
    'value': 'A414',
    'viewValue': 'ARMENO (NO)'
  },
  {
    'value': 'A415',
    'viewValue': 'ARMENTO (PZ)'
  },
  {
    'value': 'A418',
    'viewValue': 'ARMO (IM)'
  },
  {
    'value': 'A419',
    'viewValue': 'ARMUNGIA (CA)'
  },
  {
    'value': 'A424',
    'viewValue': 'ARNAD (AO)'
  },
  {
    'value': 'A421',
    'viewValue': 'ARNARA (FR)'
  },
  {
    'value': 'A422',
    'viewValue': 'ARNASCO (SV)'
  },
  {
    'value': 'A425',
    'viewValue': 'ARNESANO (LE)'
  },
  {
    'value': 'A427',
    'viewValue': 'AROLA (VB)'
  },
  {
    'value': 'A429',
    'viewValue': 'ARONA (NO)'
  },
  {
    'value': 'A430',
    'viewValue': 'AROSIO (CO)'
  },
  {
    'value': 'A431',
    'viewValue': 'ARPAIA (BN)'
  },
  {
    'value': 'A432',
    'viewValue': 'ARPAISE (BN)'
  },
  {
    'value': 'A433',
    'viewValue': 'ARPINO (FR)'
  },
  {
    'value': 'A434',
    'viewValue': "ARQUA' PETRARCA (PD)"
  },
  {
    'value': 'A435',
    'viewValue': "ARQUA' POLESINE (RO)"
  },
  {
    'value': 'A437',
    'viewValue': 'ARQUATA DEL TRONTO (AP)'
  },
  {
    'value': 'A436',
    'viewValue': 'ARQUATA SCRIVIA (AL)'
  },
  {
    'value': 'A438',
    'viewValue': 'ARRE (PD)'
  },
  {
    'value': 'A439',
    'viewValue': 'ARRONE (TR)'
  },
  {
    'value': 'A441',
    'viewValue': 'ARSAGO SEPRIO (VA)'
  },
  {
    'value': 'A443',
    'viewValue': "ARSIE' (BL)"
  },
  {
    'value': 'A444',
    'viewValue': 'ARSIERO (VI)'
  },
  {
    'value': 'A445',
    'viewValue': 'ARSITA (TE)'
  },
  {
    'value': 'A446',
    'viewValue': 'ARSOLI (RM)'
  },
  {
    'value': 'A447',
    'viewValue': 'ARTA TERME (UD)'
  },
  {
    'value': 'A448',
    'viewValue': 'ARTEGNA (UD)'
  },
  {
    'value': 'A449',
    'viewValue': 'ARTENA (RM)'
  },
  {
    'value': 'A451',
    'viewValue': 'ARTOGNE (BS)'
  },
  {
    'value': 'A452',
    'viewValue': 'ARVIER (AO)'
  },
  {
    'value': 'A453',
    'viewValue': 'ARZACHENA (SS)'
  },
  {
    'value': 'A440',
    'viewValue': "ARZAGO D'ADDA (BG)"
  },
  {
    'value': 'A454',
    'viewValue': 'ARZANA (NU)'
  },
  {
    'value': 'A455',
    'viewValue': 'ARZANO (NA)'
  },
  {
    'value': 'A456',
    'viewValue': 'ARZENE (PN)'
  },
  {
    'value': 'A458',
    'viewValue': 'ARZERGRANDE (PD)'
  },
  {
    'value': 'A459',
    'viewValue': 'ARZIGNANO (VI)'
  },
  {
    'value': 'A460',
    'viewValue': 'ASCEA (SA)'
  },
  {
    'value': 'A461',
    'viewValue': 'ASCIANO (SI)'
  },
  {
    'value': 'A462',
    'viewValue': 'ASCOLI PICENO (AP)'
  },
  {
    'value': 'A463',
    'viewValue': 'ASCOLI SATRIANO (FG)'
  },
  {
    'value': 'A464',
    'viewValue': 'ASCREA (RI)'
  },
  {
    'value': 'A465',
    'viewValue': 'ASIAGO (VI)'
  },
  {
    'value': 'A467',
    'viewValue': 'ASIGLIANO VENETO (VI)'
  },
  {
    'value': 'A466',
    'viewValue': 'ASIGLIANO VERCELLESE (VC)'
  },
  {
    'value': 'A470',
    'viewValue': 'ASOLA (MN)'
  },
  {
    'value': 'A471',
    'viewValue': 'ASOLO (TV)'
  },
  {
    'value': 'A473',
    'viewValue': 'ASSAGO (MI)'
  },
  {
    'value': 'A474',
    'viewValue': 'ASSEMINI (CA)'
  },
  {
    'value': 'A475',
    'viewValue': 'ASSISI (PG)'
  },
  {
    'value': 'A476',
    'viewValue': 'ASSO (CO)'
  },
  {
    'value': 'A477',
    'viewValue': 'ASSOLO (OR)'
  },
  {
    'value': 'A478',
    'viewValue': 'ASSORO (EN)'
  },
  {
    'value': 'A479',
    'viewValue': 'ASTI (AT)'
  },
  {
    'value': 'A480',
    'viewValue': 'ASUNI (OR)'
  },
  {
    'value': 'A481',
    'viewValue': 'ATELETA (AQ)'
  },
  {
    'value': 'A482',
    'viewValue': 'ATELLA (PZ)'
  },
  {
    'value': 'A484',
    'viewValue': 'ATENA LUCANA (SA)'
  },
  {
    'value': 'A485',
    'viewValue': 'ATESSA (CH)'
  },
  {
    'value': 'A486',
    'viewValue': 'ATINA (FR)'
  },
  {
    'value': 'A487',
    'viewValue': 'ATRANI (SA)'
  },
  {
    'value': 'A488',
    'viewValue': 'ATRI (TE)'
  },
  {
    'value': 'A489',
    'viewValue': 'ATRIPALDA (AV)'
  },
  {
    'value': 'A490',
    'viewValue': 'ATTIGLIANO (TR)'
  },
  {
    'value': 'A491',
    'viewValue': 'ATTIMIS (UD)'
  },
  {
    'value': 'A492',
    'viewValue': 'ATZARA (NU)'
  },
  {
    'value': 'A493',
    'viewValue': 'AUDITORE (PU)'
  },
  {
    'value': 'A494',
    'viewValue': 'AUGUSTA (SR)'
  },
  {
    'value': 'A495',
    'viewValue': 'AULETTA (SA)'
  },
  {
    'value': 'A496',
    'viewValue': 'AULLA (MS)'
  },
  {
    'value': 'A497',
    'viewValue': 'AURANO (VB)'
  },
  {
    'value': 'A499',
    'viewValue': 'AURIGO (IM)'
  },
  {
    'value': 'A501',
    'viewValue': 'AURONZO DI CADORE (BL)'
  },
  {
    'value': 'A502',
    'viewValue': 'AUSONIA (FR)'
  },
  {
    'value': 'A503',
    'viewValue': 'AUSTIS (NU)'
  },
  {
    'value': 'A506',
    'viewValue': 'AVEGNO (GE)'
  },
  {
    'value': 'A507',
    'viewValue': 'AVELENGO .HAFLING. (BZ)'
  },
  {
    'value': 'A508',
    'viewValue': 'AVELLA (AV)'
  },
  {
    'value': 'A509',
    'viewValue': 'AVELLINO (AV)'
  },
  {
    'value': 'A511',
    'viewValue': 'AVERARA (BG)'
  },
  {
    'value': 'A512',
    'viewValue': 'AVERSA (CE)'
  },
  {
    'value': 'A514',
    'viewValue': 'AVETRANA (TA)'
  },
  {
    'value': 'A515',
    'viewValue': 'AVEZZANO (AQ)'
  },
  {
    'value': 'A516',
    'viewValue': 'AVIANO (PN)'
  },
  {
    'value': 'A517',
    'viewValue': 'AVIATICO (BG)'
  },
  {
    'value': 'A518',
    'viewValue': 'AVIGLIANA (TO)'
  },
  {
    'value': 'A519',
    'viewValue': 'AVIGLIANO (PZ)'
  },
  {
    'value': 'M258',
    'viewValue': 'AVIGLIANO UMBRO (TR)'
  },
  {
    'value': 'A520',
    'viewValue': 'AVIO (TN)'
  },
  {
    'value': 'A521',
    'viewValue': 'AVISE (AO)'
  },
  {
    'value': 'A522',
    'viewValue': 'AVOLA (SR)'
  },
  {
    'value': 'A523',
    'viewValue': 'AVOLASCA (AL)'
  },
  {
    'value': 'A094',
    'viewValue': 'AYAS (AO)'
  },
  {
    'value': 'A108',
    'viewValue': 'AYMAVILLES (AO)'
  },
  {
    'value': 'A525',
    'viewValue': 'AZEGLIO (TO)'
  },
  {
    'value': 'A526',
    'viewValue': 'AZZANELLO (CR)'
  },
  {
    'value': 'A527',
    'viewValue': "AZZANO D'ASTI (AT)"
  },
  {
    'value': 'A530',
    'viewValue': 'AZZANO DECIMO (PN)'
  },
  {
    'value': 'A529',
    'viewValue': 'AZZANO MELLA (BS)'
  },
  {
    'value': 'A528',
    'viewValue': 'AZZANO SAN PAOLO (BG)'
  },
  {
    'value': 'A531',
    'viewValue': 'AZZATE (VA)'
  },
  {
    'value': 'A532',
    'viewValue': 'AZZIO (VA)'
  },
  {
    'value': 'A533',
    'viewValue': 'AZZONE (BG)'
  },
  {
    'value': 'A534',
    'viewValue': 'BACENO (VB)'
  },
  {
    'value': 'A535',
    'viewValue': 'BACOLI (NA)'
  },
  {
    'value': 'A536',
    'viewValue': 'BADALUCCO (IM)'
  },
  {
    'value': 'M214',
    'viewValue': 'BADESI (SS)'
  },
  {
    'value': 'A537',
    'viewValue': 'BADIA .ABTEI. (BZ)'
  },
  {
    'value': 'A540',
    'viewValue': 'BADIA CALAVENA (VR)'
  },
  {
    'value': 'A538',
    'viewValue': 'BADIA PAVESE (PV)'
  },
  {
    'value': 'A539',
    'viewValue': 'BADIA POLESINE (RO)'
  },
  {
    'value': 'A541',
    'viewValue': 'BADIA TEDALDA (AR)'
  },
  {
    'value': 'A542',
    'viewValue': 'BADOLATO (CZ)'
  },
  {
    'value': 'A544',
    'viewValue': 'BAGALADI (RC)'
  },
  {
    'value': 'A546',
    'viewValue': 'BAGHERIA (PA)'
  },
  {
    'value': 'A547',
    'viewValue': 'BAGNACAVALLO (RA)'
  },
  {
    'value': 'A552',
    'viewValue': 'BAGNARA CALABRA (RC)'
  },
  {
    'value': 'A551',
    'viewValue': 'BAGNARA DI ROMAGNA (RA)'
  },
  {
    'value': 'A550',
    'viewValue': 'BAGNARIA (PV)'
  },
  {
    'value': 'A553',
    'viewValue': 'BAGNARIA ARSA (UD)'
  },
  {
    'value': 'A555',
    'viewValue': 'BAGNASCO (CN)'
  },
  {
    'value': 'A557',
    'viewValue': 'BAGNATICA (BG)'
  },
  {
    'value': 'A560',
    'viewValue': 'BAGNI DI LUCCA (LU)'
  },
  {
    'value': 'A564',
    'viewValue': 'BAGNO A RIPOLI (FI)'
  },
  {
    'value': 'A565',
    'viewValue': 'BAGNO DI ROMAGNA (FC)'
  },
  {
    'value': 'A567',
    'viewValue': 'BAGNOLI DEL TRIGNO (IS)'
  },
  {
    'value': 'A568',
    'viewValue': 'BAGNOLI DI SOPRA (PD)'
  },
  {
    'value': 'A566',
    'viewValue': 'BAGNOLI IRPINO (AV)'
  },
  {
    'value': 'A570',
    'viewValue': 'BAGNOLO CREMASCO (CR)'
  },
  {
    'value': 'A572',
    'viewValue': 'BAGNOLO DEL SALENTO (LE)'
  },
  {
    'value': 'A574',
    'viewValue': 'BAGNOLO DI PO (RO)'
  },
  {
    'value': 'A573',
    'viewValue': 'BAGNOLO IN PIANO (RE)'
  },
  {
    'value': 'A569',
    'viewValue': 'BAGNOLO MELLA (BS)'
  },
  {
    'value': 'A571',
    'viewValue': 'BAGNOLO PIEMONTE (CN)'
  },
  {
    'value': 'A575',
    'viewValue': 'BAGNOLO SAN VITO (MN)'
  },
  {
    'value': 'A576',
    'viewValue': 'BAGNONE (MS)'
  },
  {
    'value': 'A577',
    'viewValue': 'BAGNOREGIO (VT)'
  },
  {
    'value': 'A578',
    'viewValue': 'BAGOLINO (BS)'
  },
  {
    'value': 'A579',
    'viewValue': 'BAIA E LATINA (CE)'
  },
  {
    'value': 'A580',
    'viewValue': 'BAIANO (AV)'
  },
  {
    'value': 'A581',
    'viewValue': 'BAIARDO (IM)'
  },
  {
    'value': 'A584',
    'viewValue': 'BAIRO (TO)'
  },
  {
    'value': 'A586',
    'viewValue': 'BAISO (RE)'
  },
  {
    'value': 'A587',
    'viewValue': 'BALANGERO (TO)'
  },
  {
    'value': 'A588',
    'viewValue': "BALDICHIERI D'ASTI (AT)"
  },
  {
    'value': 'A590',
    'viewValue': 'BALDISSERO CANAVESE (TO)'
  },
  {
    'value': 'A589',
    'viewValue': "BALDISSERO D'ALBA (CN)"
  },
  {
    'value': 'A591',
    'viewValue': 'BALDISSERO TORINESE (TO)'
  },
  {
    'value': 'A592',
    'viewValue': 'BALESTRATE (PA)'
  },
  {
    'value': 'A593',
    'viewValue': 'BALESTRINO (SV)'
  },
  {
    'value': 'A594',
    'viewValue': 'BALLABIO (LC)'
  },
  {
    'value': 'A597',
    'viewValue': 'BALLAO (CA)'
  },
  {
    'value': 'A599',
    'viewValue': 'BALME (TO)'
  },
  {
    'value': 'A600',
    'viewValue': 'BALMUCCIA (VC)'
  },
  {
    'value': 'A601',
    'viewValue': 'BALOCCO (VC)'
  },
  {
    'value': 'A603',
    'viewValue': 'BALSORANO (AQ)'
  },
  {
    'value': 'A604',
    'viewValue': 'BALVANO (PZ)'
  },
  {
    'value': 'A605',
    'viewValue': 'BALZOLA (AL)'
  },
  {
    'value': 'A606',
    'viewValue': 'BANARI (SS)'
  },
  {
    'value': 'A607',
    'viewValue': 'BANCHETTE (TO)'
  },
  {
    'value': 'A610',
    'viewValue': 'BANNIO ANZINO (VB)'
  },
  {
    'value': 'A612',
    'viewValue': 'BANZI (PZ)'
  },
  {
    'value': 'A613',
    'viewValue': 'BAONE (PD)'
  },
  {
    'value': 'A614',
    'viewValue': 'BARADILI (OR)'
  },
  {
    'value': 'A615',
    'viewValue': 'BARAGIANO (PZ)'
  },
  {
    'value': 'A616',
    'viewValue': 'BARANELLO (CB)'
  },
  {
    'value': 'A617',
    'viewValue': "BARANO D'ISCHIA (NA)"
  },
  {
    'value': 'A618',
    'viewValue': 'BARANZATE (MI)'
  },
  {
    'value': 'A619',
    'viewValue': 'BARASSO (VA)'
  },
  {
    'value': 'A621',
    'viewValue': 'BARATILI SAN PIETRO (OR)'
  },
  {
    'value': 'A625',
    'viewValue': 'BARBANIA (TO)'
  },
  {
    'value': 'A626',
    'viewValue': 'BARBARA (AN)'
  },
  {
    'value': 'A628',
    'viewValue': 'BARBARANO ROMANO (VT)'
  },
  {
    'value': 'A627',
    'viewValue': 'BARBARANO VICENTINO (VI)'
  },
  {
    'value': 'A629',
    'viewValue': 'BARBARESCO (CN)'
  },
  {
    'value': 'A630',
    'viewValue': 'BARBARIGA (BS)'
  },
  {
    'value': 'A631',
    'viewValue': 'BARBATA (BG)'
  },
  {
    'value': 'A632',
    'viewValue': 'BARBERINO DI MUGELLO (FI)'
  },
  {
    'value': 'A633',
    'viewValue': "BARBERINO VAL D'ELSA (FI)"
  },
  {
    'value': 'A634',
    'viewValue': 'BARBIANELLO (PV)'
  },
  {
    'value': 'A635',
    'viewValue': 'BARBIANO .BARBIAN. (BZ)'
  },
  {
    'value': 'A637',
    'viewValue': 'BARBONA (PD)'
  },
  {
    'value': 'A638',
    'viewValue': 'BARCELLONA POZZO DI GOTTO (ME)'
  },
  {
    'value': 'A639',
    'viewValue': 'BARCHI (PU)'
  },
  {
    'value': 'A640',
    'viewValue': 'BARCIS (PN)'
  },
  {
    'value': 'A643',
    'viewValue': 'BARD (AO)'
  },
  {
    'value': 'A645',
    'viewValue': 'BARDELLO (VA)'
  },
  {
    'value': 'A646',
    'viewValue': 'BARDI (PR)'
  },
  {
    'value': 'A647',
    'viewValue': 'BARDINETO (SV)'
  },
  {
    'value': 'A650',
    'viewValue': 'BARDOLINO (VR)'
  },
  {
    'value': 'A651',
    'viewValue': 'BARDONECCHIA (TO)'
  },
  {
    'value': 'A652',
    'viewValue': 'BAREGGIO (MI)'
  },
  {
    'value': 'A653',
    'viewValue': 'BARENGO (NO)'
  },
  {
    'value': 'A655',
    'viewValue': 'BARESSA (OR)'
  },
  {
    'value': 'A656',
    'viewValue': 'BARETE (AQ)'
  },
  {
    'value': 'A657',
    'viewValue': 'BARGA (LU)'
  },
  {
    'value': 'A658',
    'viewValue': 'BARGAGLI (GE)'
  },
  {
    'value': 'A660',
    'viewValue': 'BARGE (CN)'
  },
  {
    'value': 'A661',
    'viewValue': 'BARGHE (BS)'
  },
  {
    'value': 'A662',
    'viewValue': 'BARI (BA)'
  },
  {
    'value': 'A663',
    'viewValue': 'BARI SARDO (NU)'
  },
  {
    'value': 'A664',
    'viewValue': 'BARIANO (BG)'
  },
  {
    'value': 'A665',
    'viewValue': 'BARICELLA (BO)'
  },
  {
    'value': 'A666',
    'viewValue': 'BARILE (PZ)'
  },
  {
    'value': 'A667',
    'viewValue': 'BARISCIANO (AQ)'
  },
  {
    'value': 'A668',
    'viewValue': 'BARLASSINA (MI)'
  },
  {
    'value': 'A669',
    'viewValue': 'BARLETTA (BA)'
  },
  {
    'value': 'A670',
    'viewValue': 'BARNI (CO)'
  },
  {
    'value': 'A671',
    'viewValue': 'BAROLO (CN)'
  },
  {
    'value': 'A673',
    'viewValue': 'BARONE CANAVESE (TO)'
  },
  {
    'value': 'A674',
    'viewValue': 'BARONISSI (SA)'
  },
  {
    'value': 'A676',
    'viewValue': 'BARRAFRANCA (EN)'
  },
  {
    'value': 'A677',
    'viewValue': 'BARRALI (CA)'
  },
  {
    'value': 'A678',
    'viewValue': 'BARREA (AQ)'
  },
  {
    'value': 'A681',
    'viewValue': 'BARUMINI (CA)'
  },
  {
    'value': 'A683',
    'viewValue': 'BARZAGO (LC)'
  },
  {
    'value': 'A684',
    'viewValue': 'BARZANA (BG)'
  },
  {
    'value': 'A686',
    'viewValue': "BARZANO' (LC)"
  },
  {
    'value': 'A687',
    'viewValue': 'BARZIO (LC)'
  },
  {
    'value': 'A689',
    'viewValue': 'BASALUZZO (AL)'
  },
  {
    'value': 'A690',
    'viewValue': "BASCAPE' (PV)"
  },
  {
    'value': 'A691',
    'viewValue': 'BASCHI (TR)'
  },
  {
    'value': 'A692',
    'viewValue': 'BASCIANO (TE)'
  },
  {
    'value': 'A694',
    'viewValue': "BASELGA DI PINE' (TN)"
  },
  {
    'value': 'A696',
    'viewValue': 'BASELICE (BN)'
  },
  {
    'value': 'A697',
    'viewValue': 'BASIANO (MI)'
  },
  {
    'value': 'A698',
    'viewValue': "BASICO' (ME)"
  },
  {
    'value': 'A699',
    'viewValue': 'BASIGLIO (MI)'
  },
  {
    'value': 'A700',
    'viewValue': 'BASILIANO (UD)'
  },
  {
    'value': 'A702',
    'viewValue': 'BASSANO BRESCIANO (BS)'
  },
  {
    'value': 'A703',
    'viewValue': 'BASSANO DEL GRAPPA (VI)'
  },
  {
    'value': 'A706',
    'viewValue': 'BASSANO IN TEVERINA (VT)'
  },
  {
    'value': 'A704',
    'viewValue': 'BASSANO ROMANO (VT)'
  },
  {
    'value': 'A707',
    'viewValue': 'BASSIANO (LT)'
  },
  {
    'value': 'A708',
    'viewValue': 'BASSIGNANA (AL)'
  },
  {
    'value': 'A709',
    'viewValue': "BASTIA MONDOVI' (CN)"
  },
  {
    'value': 'A710',
    'viewValue': 'BASTIA UMBRA (PG)'
  },
  {
    'value': 'A711',
    'viewValue': "BASTIDA DE' DOSSI (PV)"
  },
  {
    'value': 'A712',
    'viewValue': 'BASTIDA PANCARANA (PV)'
  },
  {
    'value': 'A713',
    'viewValue': 'BASTIGLIA (MO)'
  },
  {
    'value': 'A714',
    'viewValue': 'BATTAGLIA TERME (PD)'
  },
  {
    'value': 'A716',
    'viewValue': 'BATTIFOLLO (CN)'
  },
  {
    'value': 'A717',
    'viewValue': 'BATTIPAGLIA (SA)'
  },
  {
    'value': 'A718',
    'viewValue': 'BATTUDA (PV)'
  },
  {
    'value': 'A719',
    'viewValue': 'BAUCINA (PA)'
  },
  {
    'value': 'A721',
    'viewValue': 'BAULADU (OR)'
  },
  {
    'value': 'A722',
    'viewValue': 'BAUNEI (NU)'
  },
  {
    'value': 'A725',
    'viewValue': 'BAVENO (VB)'
  },
  {
    'value': 'A726',
    'viewValue': 'BAZZANO (BO)'
  },
  {
    'value': 'A728',
    'viewValue': 'BEDERO VALCUVIA (VA)'
  },
  {
    'value': 'A729',
    'viewValue': 'BEDIZZOLE (BS)'
  },
  {
    'value': 'A730',
    'viewValue': 'BEDOLLO (TN)'
  },
  {
    'value': 'A731',
    'viewValue': 'BEDONIA (PR)'
  },
  {
    'value': 'A732',
    'viewValue': 'BEDULITA (BG)'
  },
  {
    'value': 'A733',
    'viewValue': 'BEE (VB)'
  },
  {
    'value': 'A734',
    'viewValue': 'BEINASCO (TO)'
  },
  {
    'value': 'A735',
    'viewValue': 'BEINETTE (CN)'
  },
  {
    'value': 'A736',
    'viewValue': 'BELCASTRO (CZ)'
  },
  {
    'value': 'A737',
    'viewValue': 'BELFIORE (VR)'
  },
  {
    'value': 'A740',
    'viewValue': "BELFORTE ALL'ISAURO (PU)"
  },
  {
    'value': 'A739',
    'viewValue': 'BELFORTE DEL CHIENTI (MC)'
  },
  {
    'value': 'A738',
    'viewValue': 'BELFORTE MONFERRATO (AL)'
  },
  {
    'value': 'A741',
    'viewValue': 'BELGIOIOSO (PV)'
  },
  {
    'value': 'A742',
    'viewValue': 'BELGIRATE (VB)'
  },
  {
    'value': 'A743',
    'viewValue': 'BELLA (PZ)'
  },
  {
    'value': 'A744',
    'viewValue': 'BELLAGIO (CO)'
  },
  {
    'value': 'A745',
    'viewValue': 'BELLANO (LC)'
  },
  {
    'value': 'A746',
    'viewValue': 'BELLANTE (TE)'
  },
  {
    'value': 'A747',
    'viewValue': 'BELLARIA-IGEA MARINA (RN)'
  },
  {
    'value': 'A749',
    'viewValue': 'BELLEGRA (RM)'
  },
  {
    'value': 'A750',
    'viewValue': 'BELLINO (CN)'
  },
  {
    'value': 'A751',
    'viewValue': 'BELLINZAGO LOMBARDO (MI)'
  },
  {
    'value': 'A752',
    'viewValue': 'BELLINZAGO NOVARESE (NO)'
  },
  {
    'value': 'M294',
    'viewValue': 'BELLIZZI (SA)'
  },
  {
    'value': 'A755',
    'viewValue': 'BELLONA (CE)'
  },
  {
    'value': 'A756',
    'viewValue': 'BELLOSGUARDO (SA)'
  },
  {
    'value': 'A757',
    'viewValue': 'BELLUNO (BL)'
  },
  {
    'value': 'A759',
    'viewValue': 'BELLUSCO (MI)'
  },
  {
    'value': 'A762',
    'viewValue': 'BELMONTE CALABRO (CS)'
  },
  {
    'value': 'A763',
    'viewValue': 'BELMONTE CASTELLO (FR)'
  },
  {
    'value': 'A761',
    'viewValue': 'BELMONTE DEL SANNIO (IS)'
  },
  {
    'value': 'A765',
    'viewValue': 'BELMONTE IN SABINA (RI)'
  },
  {
    'value': 'A764',
    'viewValue': 'BELMONTE MEZZAGNO (PA)'
  },
  {
    'value': 'A760',
    'viewValue': 'BELMONTE PICENO (AP)'
  },
  {
    'value': 'A766',
    'viewValue': 'BELPASSO (CT)'
  },
  {
    'value': 'A768',
    'viewValue': 'BELSITO (CS)'
  },
  {
    'value': 'A772',
    'viewValue': 'BELVEDERE DI SPINELLO (KR)'
  },
  {
    'value': 'A774',
    'viewValue': 'BELVEDERE LANGHE (CN)'
  },
  {
    'value': 'A773',
    'viewValue': 'BELVEDERE MARITTIMO (CS)'
  },
  {
    'value': 'A769',
    'viewValue': 'BELVEDERE OSTRENSE (AN)'
  },
  {
    'value': 'A770',
    'viewValue': 'BELVEGLIO (AT)'
  },
  {
    'value': 'A776',
    'viewValue': 'BELVI (NU)'
  },
  {
    'value': 'A777',
    'viewValue': 'BEMA (SO)'
  },
  {
    'value': 'A778',
    'viewValue': 'BENE LARIO (CO)'
  },
  {
    'value': 'A779',
    'viewValue': 'BENE VAGIENNA (CN)'
  },
  {
    'value': 'A780',
    'viewValue': 'BENESTARE (RC)'
  },
  {
    'value': 'A781',
    'viewValue': 'BENETUTTI (SS)'
  },
  {
    'value': 'A782',
    'viewValue': 'BENEVELLO (CN)'
  },
  {
    'value': 'A783',
    'viewValue': 'BENEVENTO (BN)'
  },
  {
    'value': 'A784',
    'viewValue': 'BENNA (BI)'
  },
  {
    'value': 'A785',
    'viewValue': 'BENTIVOGLIO (BO)'
  },
  {
    'value': 'A786',
    'viewValue': 'BERBENNO (BG)'
  },
  {
    'value': 'A787',
    'viewValue': 'BERBENNO DI VALTELLINA (SO)'
  },
  {
    'value': 'A788',
    'viewValue': 'BERCETO (PR)'
  },
  {
    'value': 'A789',
    'viewValue': 'BERCHIDDA (SS)'
  },
  {
    'value': 'A791',
    'viewValue': 'BEREGAZZO CON FIGLIARO (CO)'
  },
  {
    'value': 'A792',
    'viewValue': 'BEREGUARDO (PV)'
  },
  {
    'value': 'A793',
    'viewValue': 'BERGAMASCO (AL)'
  },
  {
    'value': 'A794',
    'viewValue': 'BERGAMO (BG)'
  },
  {
    'value': 'A795',
    'viewValue': 'BERGANTINO (RO)'
  },
  {
    'value': 'A796',
    'viewValue': 'BERGEGGI (SV)'
  },
  {
    'value': 'A798',
    'viewValue': 'BERGOLO (CN)'
  },
  {
    'value': 'A799',
    'viewValue': 'BERLINGO (BS)'
  },
  {
    'value': 'A801',
    'viewValue': 'BERNALDA (MT)'
  },
  {
    'value': 'A802',
    'viewValue': 'BERNAREGGIO (MI)'
  },
  {
    'value': 'A804',
    'viewValue': 'BERNATE TICINO (MI)'
  },
  {
    'value': 'A805',
    'viewValue': 'BERNEZZO (CN)'
  },
  {
    'value': 'A806',
    'viewValue': 'BERRA (FE)'
  },
  {
    'value': 'A808',
    'viewValue': 'BERSONE (TN)'
  },
  {
    'value': 'A809',
    'viewValue': 'BERTINORO (FC)'
  },
  {
    'value': 'A810',
    'viewValue': 'BERTIOLO (UD)'
  },
  {
    'value': 'A811',
    'viewValue': 'BERTONICO (LO)'
  },
  {
    'value': 'A812',
    'viewValue': 'BERZANO DI SAN PIETRO (AT)'
  },
  {
    'value': 'A813',
    'viewValue': 'BERZANO DI TORTONA (AL)'
  },
  {
    'value': 'A816',
    'viewValue': 'BERZO DEMO (BS)'
  },
  {
    'value': 'A817',
    'viewValue': 'BERZO INFERIORE (BS)'
  },
  {
    'value': 'A815',
    'viewValue': 'BERZO SAN FERMO (BG)'
  },
  {
    'value': 'A818',
    'viewValue': 'BESANA IN BRIANZA (MI)'
  },
  {
    'value': 'A819',
    'viewValue': 'BESANO (VA)'
  },
  {
    'value': 'A820',
    'viewValue': 'BESATE (MI)'
  },
  {
    'value': 'A821',
    'viewValue': 'BESENELLO (TN)'
  },
  {
    'value': 'A823',
    'viewValue': 'BESENZONE (PC)'
  },
  {
    'value': 'A825',
    'viewValue': 'BESNATE (VA)'
  },
  {
    'value': 'A826',
    'viewValue': 'BESOZZO (VA)'
  },
  {
    'value': 'A827',
    'viewValue': 'BESSUDE (SS)'
  },
  {
    'value': 'A831',
    'viewValue': 'BETTOLA (PC)'
  },
  {
    'value': 'A832',
    'viewValue': 'BETTONA (PG)'
  },
  {
    'value': 'A834',
    'viewValue': 'BEURA-CARDEZZA (VB)'
  },
  {
    'value': 'A835',
    'viewValue': 'BEVAGNA (PG)'
  },
  {
    'value': 'A836',
    'viewValue': 'BEVERINO (SP)'
  },
  {
    'value': 'A837',
    'viewValue': 'BEVILACQUA (VR)'
  },
  {
    'value': 'A839',
    'viewValue': 'BEZZECCA (TN)'
  },
  {
    'value': 'A841',
    'viewValue': 'BIANCAVILLA (CT)'
  },
  {
    'value': 'A842',
    'viewValue': 'BIANCHI (CS)'
  },
  {
    'value': 'A843',
    'viewValue': 'BIANCO (RC)'
  },
  {
    'value': 'A844',
    'viewValue': 'BIANDRATE (NO)'
  },
  {
    'value': 'A845',
    'viewValue': 'BIANDRONNO (VA)'
  },
  {
    'value': 'A846',
    'viewValue': 'BIANZANO (BG)'
  },
  {
    'value': 'A847',
    'viewValue': "BIANZE' (VC)"
  },
  {
    'value': 'A848',
    'viewValue': 'BIANZONE (SO)'
  },
  {
    'value': 'A849',
    'viewValue': 'BIASSONO (MI)'
  },
  {
    'value': 'A850',
    'viewValue': 'BIBBIANO (RE)'
  },
  {
    'value': 'A851',
    'viewValue': 'BIBBIENA (AR)'
  },
  {
    'value': 'A852',
    'viewValue': 'BIBBONA (LI)'
  },
  {
    'value': 'A853',
    'viewValue': 'BIBIANA (TO)'
  },
  {
    'value': 'A854',
    'viewValue': 'BICCARI (FG)'
  },
  {
    'value': 'A855',
    'viewValue': 'BICINICCO (UD)'
  },
  {
    'value': 'A856',
    'viewValue': "BIDONI' (OR)"
  },
  {
    'value': 'A859',
    'viewValue': 'BIELLA (BI)'
  },
  {
    'value': 'A861',
    'viewValue': 'BIENNO (BS)'
  },
  {
    'value': 'A863',
    'viewValue': 'BIENO (TN)'
  },
  {
    'value': 'A864',
    'viewValue': 'BIENTINA (PI)'
  },
  {
    'value': 'A866',
    'viewValue': 'BIGARELLO (MN)'
  },
  {
    'value': 'A870',
    'viewValue': 'BINAGO (CO)'
  },
  {
    'value': 'A872',
    'viewValue': 'BINASCO (MI)'
  },
  {
    'value': 'A874',
    'viewValue': 'BINETTO (BA)'
  },
  {
    'value': 'A876',
    'viewValue': 'BIOGLIO (BI)'
  },
  {
    'value': 'A877',
    'viewValue': 'BIONAZ (AO)'
  },
  {
    'value': 'A878',
    'viewValue': 'BIONE (BS)'
  },
  {
    'value': 'A880',
    'viewValue': 'BIRORI (NU)'
  },
  {
    'value': 'A881',
    'viewValue': 'BISACCIA (AV)'
  },
  {
    'value': 'A882',
    'viewValue': 'BISACQUINO (PA)'
  },
  {
    'value': 'A883',
    'viewValue': 'BISCEGLIE (BA)'
  },
  {
    'value': 'A884',
    'viewValue': 'BISEGNA (AQ)'
  },
  {
    'value': 'A885',
    'viewValue': 'BISENTI (TE)'
  },
  {
    'value': 'A887',
    'viewValue': 'BISIGNANO (CS)'
  },
  {
    'value': 'A889',
    'viewValue': 'BISTAGNO (AL)'
  },
  {
    'value': 'A891',
    'viewValue': 'BISUSCHIO (VA)'
  },
  {
    'value': 'A892',
    'viewValue': 'BITETTO (BA)'
  },
  {
    'value': 'A893',
    'viewValue': 'BITONTO (BA)'
  },
  {
    'value': 'A894',
    'viewValue': 'BITRITTO (BA)'
  },
  {
    'value': 'A895',
    'viewValue': 'BITTI (NU)'
  },
  {
    'value': 'A896',
    'viewValue': 'BIVONA (AG)'
  },
  {
    'value': 'A897',
    'viewValue': 'BIVONGI (RC)'
  },
  {
    'value': 'A898',
    'viewValue': 'BIZZARONE (CO)'
  },
  {
    'value': 'A901',
    'viewValue': 'BLEGGIO INFERIORE (TN)'
  },
  {
    'value': 'A902',
    'viewValue': 'BLEGGIO SUPERIORE (TN)'
  },
  {
    'value': 'A903',
    'viewValue': 'BLELLO (BG)'
  },
  {
    'value': 'A857',
    'viewValue': 'BLERA (VT)'
  },
  {
    'value': 'A904',
    'viewValue': 'BLESSAGNO (CO)'
  },
  {
    'value': 'A905',
    'viewValue': 'BLEVIO (CO)'
  },
  {
    'value': 'M268',
    'viewValue': 'BLUFI (PA)'
  },
  {
    'value': 'A906',
    'viewValue': 'BOARA PISANI (PD)'
  },
  {
    'value': 'A909',
    'viewValue': 'BOBBIO (PC)'
  },
  {
    'value': 'A910',
    'viewValue': 'BOBBIO PELLICE (TO)'
  },
  {
    'value': 'A911',
    'viewValue': 'BOCA (NO)'
  },
  {
    'value': 'A912',
    'viewValue': 'BOCCHIGLIERO (CS)'
  },
  {
    'value': 'A914',
    'viewValue': 'BOCCIOLETO (VC)'
  },
  {
    'value': 'A916',
    'viewValue': 'BOCENAGO (TN)'
  },
  {
    'value': 'A918',
    'viewValue': 'BODIO LOMNAGO (VA)'
  },
  {
    'value': 'A919',
    'viewValue': "BOFFALORA D'ADDA (LO)"
  },
  {
    'value': 'A920',
    'viewValue': 'BOFFALORA SOPRA TICINO (MI)'
  },
  {
    'value': 'A922',
    'viewValue': 'BOGLIASCO (GE)'
  },
  {
    'value': 'A925',
    'viewValue': 'BOGNANCO (VB)'
  },
  {
    'value': 'A929',
    'viewValue': 'BOGOGNO (NO)'
  },
  {
    'value': 'A930',
    'viewValue': 'BOIANO (CB)'
  },
  {
    'value': 'A931',
    'viewValue': 'BOISSANO (SV)'
  },
  {
    'value': 'A932',
    'viewValue': 'BOLANO (SP)'
  },
  {
    'value': 'A933',
    'viewValue': 'BOLBENO (TN)'
  },
  {
    'value': 'A937',
    'viewValue': 'BOLGARE (BG)'
  },
  {
    'value': 'A940',
    'viewValue': 'BOLLATE (MI)'
  },
  {
    'value': 'A941',
    'viewValue': 'BOLLENGO (TO)'
  },
  {
    'value': 'A944',
    'viewValue': 'BOLOGNA (BO)'
  },
  {
    'value': 'A945',
    'viewValue': 'BOLOGNANO (PE)'
  },
  {
    'value': 'A946',
    'viewValue': 'BOLOGNETTA (PA)'
  },
  {
    'value': 'A947',
    'viewValue': 'BOLOGNOLA (MC)'
  },
  {
    'value': 'A948',
    'viewValue': 'BOLOTANA (NU)'
  },
  {
    'value': 'A949',
    'viewValue': 'BOLSENA (VT)'
  },
  {
    'value': 'A950',
    'viewValue': 'BOLTIERE (BG)'
  },
  {
    'value': 'A952',
    'viewValue': 'BOLZANO .BOZEN. (BZ)'
  },
  {
    'value': 'A953',
    'viewValue': 'BOLZANO NOVARESE (NO)'
  },
  {
    'value': 'A954',
    'viewValue': 'BOLZANO VICENTINO (VI)'
  },
  {
    'value': 'A955',
    'viewValue': 'BOMARZO (VT)'
  },
  {
    'value': 'A956',
    'viewValue': 'BOMBA (CH)'
  },
  {
    'value': 'A957',
    'viewValue': 'BOMPENSIERE (CL)'
  },
  {
    'value': 'A958',
    'viewValue': 'BOMPIETRO (PA)'
  },
  {
    'value': 'A959',
    'viewValue': 'BOMPORTO (MO)'
  },
  {
    'value': 'A960',
    'viewValue': 'BONARCADO (OR)'
  },
  {
    'value': 'A961',
    'viewValue': 'BONASSOLA (SP)'
  },
  {
    'value': 'A963',
    'viewValue': 'BONATE SOPRA (BG)'
  },
  {
    'value': 'A962',
    'viewValue': 'BONATE SOTTO (BG)'
  },
  {
    'value': 'A964',
    'viewValue': 'BONAVIGO (VR)'
  },
  {
    'value': 'A965',
    'viewValue': 'BONDENO (FE)'
  },
  {
    'value': 'A967',
    'viewValue': 'BONDO (TN)'
  },
  {
    'value': 'A968',
    'viewValue': 'BONDONE (TN)'
  },
  {
    'value': 'A970',
    'viewValue': 'BONEA (BN)'
  },
  {
    'value': 'A971',
    'viewValue': 'BONEFRO (CB)'
  },
  {
    'value': 'A972',
    'viewValue': 'BONEMERSE (CR)'
  },
  {
    'value': 'A973',
    'viewValue': 'BONIFATI (CS)'
  },
  {
    'value': 'A975',
    'viewValue': 'BONITO (AV)'
  },
  {
    'value': 'A976',
    'viewValue': 'BONNANARO (SS)'
  },
  {
    'value': 'A977',
    'viewValue': 'BONO (SS)'
  },
  {
    'value': 'A978',
    'viewValue': 'BONORVA (SS)'
  },
  {
    'value': 'A979',
    'viewValue': 'BONVICINO (CN)'
  },
  {
    'value': 'A981',
    'viewValue': 'BORBONA (RI)'
  },
  {
    'value': 'A982',
    'viewValue': 'BORCA DI CADORE (BL)'
  },
  {
    'value': 'A983',
    'viewValue': 'BORDANO (UD)'
  },
  {
    'value': 'A984',
    'viewValue': 'BORDIGHERA (IM)'
  },
  {
    'value': 'A986',
    'viewValue': 'BORDOLANO (CR)'
  },
  {
    'value': 'A987',
    'viewValue': 'BORE (PR)'
  },
  {
    'value': 'A988',
    'viewValue': 'BORETTO (RE)'
  },
  {
    'value': 'A989',
    'viewValue': 'BORGARELLO (PV)'
  },
  {
    'value': 'A990',
    'viewValue': 'BORGARO TORINESE (TO)'
  },
  {
    'value': 'A991',
    'viewValue': 'BORGETTO (PA)'
  },
  {
    'value': 'A993',
    'viewValue': "BORGHETTO D'ARROSCIA (IM)"
  },
  {
    'value': 'A998',
    'viewValue': 'BORGHETTO DI BORBERA (AL)'
  },
  {
    'value': 'A992',
    'viewValue': 'BORGHETTO DI VARA (SP)'
  },
  {
    'value': 'A995',
    'viewValue': 'BORGHETTO LODIGIANO (LO)'
  },
  {
    'value': 'A999',
    'viewValue': 'BORGHETTO SANTO SPIRITO (SV)'
  },
  {
    'value': 'B001',
    'viewValue': 'BORGHI (FC)'
  },
  {
    'value': 'B002',
    'viewValue': 'BORGIA (CZ)'
  },
  {
    'value': 'B003',
    'viewValue': 'BORGIALLO (TO)'
  },
  {
    'value': 'B005',
    'viewValue': 'BORGIO VEREZZI (SV)'
  },
  {
    'value': 'B007',
    'viewValue': 'BORGO A MOZZANO (LU)'
  },
  {
    'value': 'B009',
    'viewValue': "BORGO D'ALE (VC)"
  },
  {
    'value': 'B010',
    'viewValue': 'BORGO DI TERZO (BG)'
  },
  {
    'value': 'B026',
    'viewValue': 'BORGO PACE (PU)'
  },
  {
    'value': 'B028',
    'viewValue': 'BORGO PRIOLO (PV)'
  },
  {
    'value': 'B033',
    'viewValue': 'BORGO SAN DALMAZZO (CN)'
  },
  {
    'value': 'B035',
    'viewValue': 'BORGO SAN GIACOMO (BS)'
  },
  {
    'value': 'B017',
    'viewValue': 'BORGO SAN GIOVANNI (LO)'
  },
  {
    'value': 'B036',
    'viewValue': 'BORGO SAN LORENZO (FI)'
  },
  {
    'value': 'B037',
    'viewValue': 'BORGO SAN MARTINO (AL)'
  },
  {
    'value': 'B038',
    'viewValue': 'BORGO SAN SIRO (PV)'
  },
  {
    'value': 'B043',
    'viewValue': 'BORGO TICINO (NO)'
  },
  {
    'value': 'B044',
    'viewValue': 'BORGO TOSSIGNANO (BO)'
  },
  {
    'value': 'B042',
    'viewValue': 'BORGO VAL DI TARO (PR)'
  },
  {
    'value': 'B006',
    'viewValue': 'BORGO VALSUGANA (TN)'
  },
  {
    'value': 'A996',
    'viewValue': 'BORGO VELINO (RI)'
  },
  {
    'value': 'B046',
    'viewValue': 'BORGO VERCELLI (VC)'
  },
  {
    'value': 'M406',
    'viewValue': 'BORGOCARBONARA (MN)'
  },
  {
    'value': 'B011',
    'viewValue': 'BORGOFORTE (MN)'
  },
  {
    'value': 'B015',
    'viewValue': "BORGOFRANCO D'IVREA (TO)"
  },
  {
    'value': 'B013',
    'viewValue': 'BORGOFRANCO SUL PO (MN)'
  },
  {
    'value': 'B016',
    'viewValue': 'BORGOLAVEZZARO (NO)'
  },
  {
    'value': 'B018',
    'viewValue': 'BORGOMALE (CN)'
  },
  {
    'value': 'B019',
    'viewValue': 'BORGOMANERO (NO)'
  },
  {
    'value': 'B020',
    'viewValue': 'BORGOMARO (IM)'
  },
  {
    'value': 'B021',
    'viewValue': 'BORGOMASINO (TO)'
  },
  {
    'value': 'B024',
    'viewValue': 'BORGONE SUSA (TO)'
  },
  {
    'value': 'B025',
    'viewValue': 'BORGONOVO VAL TIDONE (PC)'
  },
  {
    'value': 'B029',
    'viewValue': 'BORGORATTO ALESSANDRINO (AL)'
  },
  {
    'value': 'B030',
    'viewValue': 'BORGORATTO MORMOROLO (PV)'
  },
  {
    'value': 'B031',
    'viewValue': 'BORGORICCO (PD)'
  },
  {
    'value': 'B008',
    'viewValue': 'BORGOROSE (RI)'
  },
  {
    'value': 'B040',
    'viewValue': 'BORGOSATOLLO (BS)'
  },
  {
    'value': 'B041',
    'viewValue': 'BORGOSESIA (VC)'
  },
  {
    'value': 'B048',
    'viewValue': 'BORMIDA (SV)'
  },
  {
    'value': 'B049',
    'viewValue': 'BORMIO (SO)'
  },
  {
    'value': 'B051',
    'viewValue': 'BORNASCO (PV)'
  },
  {
    'value': 'B054',
    'viewValue': 'BORNO (BS)'
  },
  {
    'value': 'B055',
    'viewValue': 'BORONEDDU (OR)'
  },
  {
    'value': 'B056',
    'viewValue': 'BORORE (NU)'
  },
  {
    'value': 'B057',
    'viewValue': 'BORRELLO (CH)'
  },
  {
    'value': 'B058',
    'viewValue': 'BORRIANA (BI)'
  },
  {
    'value': 'B061',
    'viewValue': 'BORSO DEL GRAPPA (TV)'
  },
  {
    'value': 'B062',
    'viewValue': 'BORTIGALI (NU)'
  },
  {
    'value': 'B063',
    'viewValue': 'BORTIGIADAS (SS)'
  },
  {
    'value': 'B064',
    'viewValue': 'BORUTTA (SS)'
  },
  {
    'value': 'B067',
    'viewValue': 'BORZONASCA (GE)'
  },
  {
    'value': 'B068',
    'viewValue': 'BOSA (NU)'
  },
  {
    'value': 'B069',
    'viewValue': 'BOSARO (RO)'
  },
  {
    'value': 'B070',
    'viewValue': "BOSCHI SANT'ANNA (VR)"
  },
  {
    'value': 'B073',
    'viewValue': 'BOSCO CHIESANUOVA (VR)'
  },
  {
    'value': 'B071',
    'viewValue': 'BOSCO MARENGO (AL)'
  },
  {
    'value': 'B075',
    'viewValue': 'BOSCONERO (TO)'
  },
  {
    'value': 'B076',
    'viewValue': 'BOSCOREALE (NA)'
  },
  {
    'value': 'B077',
    'viewValue': 'BOSCOTRECASE (NA)'
  },
  {
    'value': 'B078',
    'viewValue': 'BOSENTINO (TN)'
  },
  {
    'value': 'B079',
    'viewValue': 'BOSIA (CN)'
  },
  {
    'value': 'B080',
    'viewValue': 'BOSIO (AL)'
  },
  {
    'value': 'B081',
    'viewValue': 'BOSISIO PARINI (LC)'
  },
  {
    'value': 'B082',
    'viewValue': 'BOSNASCO (PV)'
  },
  {
    'value': 'B083',
    'viewValue': 'BOSSICO (BG)'
  },
  {
    'value': 'B084',
    'viewValue': 'BOSSOLASCO (CN)'
  },
  {
    'value': 'B085',
    'viewValue': 'BOTRICELLO (CZ)'
  },
  {
    'value': 'B086',
    'viewValue': 'BOTRUGNO (LE)'
  },
  {
    'value': 'B088',
    'viewValue': 'BOTTANUCO (BG)'
  },
  {
    'value': 'B091',
    'viewValue': 'BOTTICINO (BS)'
  },
  {
    'value': 'B094',
    'viewValue': 'BOTTIDDA (SS)'
  },
  {
    'value': 'B097',
    'viewValue': 'BOVA (RC)'
  },
  {
    'value': 'B099',
    'viewValue': 'BOVA MARINA (RC)'
  },
  {
    'value': 'B098',
    'viewValue': 'BOVALINO (RC)'
  },
  {
    'value': 'B100',
    'viewValue': 'BOVEGNO (BS)'
  },
  {
    'value': 'B101',
    'viewValue': 'BOVES (CN)'
  },
  {
    'value': 'B102',
    'viewValue': 'BOVEZZO (BS)'
  },
  {
    'value': 'A720',
    'viewValue': 'BOVILLE ERNICA (FR)'
  },
  {
    'value': 'B104',
    'viewValue': 'BOVINO (FG)'
  },
  {
    'value': 'B105',
    'viewValue': 'BOVISIO-MASCIAGO (MI)'
  },
  {
    'value': 'B106',
    'viewValue': 'BOVOLENTA (PD)'
  },
  {
    'value': 'B107',
    'viewValue': 'BOVOLONE (VR)'
  },
  {
    'value': 'B109',
    'viewValue': 'BOZZOLE (AL)'
  },
  {
    'value': 'B110',
    'viewValue': 'BOZZOLO (MN)'
  },
  {
    'value': 'B111',
    'viewValue': 'BRA (CN)'
  },
  {
    'value': 'B112',
    'viewValue': 'BRACCA (BG)'
  },
  {
    'value': 'B114',
    'viewValue': 'BRACCIANO (RM)'
  },
  {
    'value': 'B115',
    'viewValue': 'BRACIGLIANO (SA)'
  },
  {
    'value': 'B116',
    'viewValue': 'BRAIES .PRAGS. (BZ)'
  },
  {
    'value': 'B117',
    'viewValue': 'BRALLO DI PREGOLA (PV)'
  },
  {
    'value': 'B118',
    'viewValue': 'BRANCALEONE (RC)'
  },
  {
    'value': 'B120',
    'viewValue': 'BRANDICO (BS)'
  },
  {
    'value': 'B121',
    'viewValue': 'BRANDIZZO (TO)'
  },
  {
    'value': 'B123',
    'viewValue': 'BRANZI (BG)'
  },
  {
    'value': 'B124',
    'viewValue': 'BRAONE (BS)'
  },
  {
    'value': 'B126',
    'viewValue': 'BREBBIA (VA)'
  },
  {
    'value': 'B128',
    'viewValue': 'BREDA DI PIAVE (TV)'
  },
  {
    'value': 'B131',
    'viewValue': 'BREGANO (VA)'
  },
  {
    'value': 'B132',
    'viewValue': 'BREGANZE (VI)'
  },
  {
    'value': 'B134',
    'viewValue': 'BREGNANO (CO)'
  },
  {
    'value': 'B135',
    'viewValue': 'BREGUZZO (TN)'
  },
  {
    'value': 'B136',
    'viewValue': 'BREIA (VC)'
  },
  {
    'value': 'B137',
    'viewValue': 'BREMBATE (BG)'
  },
  {
    'value': 'B138',
    'viewValue': 'BREMBATE DI SOPRA (BG)'
  },
  {
    'value': 'B140',
    'viewValue': 'BREMBILLA (BG)'
  },
  {
    'value': 'B141',
    'viewValue': 'BREMBIO (LO)'
  },
  {
    'value': 'B142',
    'viewValue': 'BREME (PV)'
  },
  {
    'value': 'B143',
    'viewValue': 'BRENDOLA (VI)'
  },
  {
    'value': 'B144',
    'viewValue': 'BRENNA (CO)'
  },
  {
    'value': 'B145',
    'viewValue': 'BRENNERO .BRENNER. (BZ)'
  },
  {
    'value': 'B149',
    'viewValue': 'BRENO (BS)'
  },
  {
    'value': 'B150',
    'viewValue': 'BRENTA (VA)'
  },
  {
    'value': 'B152',
    'viewValue': 'BRENTINO BELLUNO (VR)'
  },
  {
    'value': 'B153',
    'viewValue': 'BRENTONICO (TN)'
  },
  {
    'value': 'B154',
    'viewValue': 'BRENZONE (VR)'
  },
  {
    'value': 'B156',
    'viewValue': 'BRESCELLO (RE)'
  },
  {
    'value': 'B157',
    'viewValue': 'BRESCIA (BS)'
  },
  {
    'value': 'B158',
    'viewValue': 'BRESIMO (TN)'
  },
  {
    'value': 'B159',
    'viewValue': 'BRESSANA BOTTARONE (PV)'
  },
  {
    'value': 'B160',
    'viewValue': 'BRESSANONE .BRIXEN. (BZ)'
  },
  {
    'value': 'B161',
    'viewValue': 'BRESSANVIDO (VI)'
  },
  {
    'value': 'B162',
    'viewValue': 'BRESSO (MI)'
  },
  {
    'value': 'B165',
    'viewValue': 'BREZ (TN)'
  },
  {
    'value': 'B166',
    'viewValue': 'BREZZO DI BEDERO (VA)'
  },
  {
    'value': 'B167',
    'viewValue': 'BRIAGLIA (CN)'
  },
  {
    'value': 'B169',
    'viewValue': 'BRIATICO (VV)'
  },
  {
    'value': 'B171',
    'viewValue': 'BRICHERASIO (TO)'
  },
  {
    'value': 'B172',
    'viewValue': 'BRIENNO (CO)'
  },
  {
    'value': 'B173',
    'viewValue': 'BRIENZA (PZ)'
  },
  {
    'value': 'B175',
    'viewValue': 'BRIGA ALTA (CN)'
  },
  {
    'value': 'B176',
    'viewValue': 'BRIGA NOVARESE (NO)'
  },
  {
    'value': 'B178',
    'viewValue': "BRIGNANO GERA D'ADDA (BG)"
  },
  {
    'value': 'B179',
    'viewValue': 'BRIGNANO-FRASCATA (AL)'
  },
  {
    'value': 'B180',
    'viewValue': 'BRINDISI (BR)'
  },
  {
    'value': 'B181',
    'viewValue': 'BRINDISI MONTAGNA (PZ)'
  },
  {
    'value': 'B182',
    'viewValue': 'BRINZIO (VA)'
  },
  {
    'value': 'B183',
    'viewValue': 'BRIONA (NO)'
  },
  {
    'value': 'B184',
    'viewValue': 'BRIONE (BS)'
  },
  {
    'value': 'B185',
    'viewValue': 'BRIONE (TN)'
  },
  {
    'value': 'B187',
    'viewValue': 'BRIOSCO (MI)'
  },
  {
    'value': 'B188',
    'viewValue': 'BRISIGHELLA (RA)'
  },
  {
    'value': 'B191',
    'viewValue': 'BRISSAGO-VALTRAVAGLIA (VA)'
  },
  {
    'value': 'B192',
    'viewValue': 'BRISSOGNE (AO)'
  },
  {
    'value': 'B193',
    'viewValue': 'BRITTOLI (PE)'
  },
  {
    'value': 'B194',
    'viewValue': 'BRIVIO (LC)'
  },
  {
    'value': 'B195',
    'viewValue': 'BROCCOSTELLA (FR)'
  },
  {
    'value': 'B196',
    'viewValue': 'BROGLIANO (VI)'
  },
  {
    'value': 'B197',
    'viewValue': 'BROGNATURO (VV)'
  },
  {
    'value': 'B198',
    'viewValue': 'BROLO (ME)'
  },
  {
    'value': 'B200',
    'viewValue': 'BRONDELLO (CN)'
  },
  {
    'value': 'B201',
    'viewValue': 'BRONI (PV)'
  },
  {
    'value': 'B202',
    'viewValue': 'BRONTE (CT)'
  },
  {
    'value': 'B203',
    'viewValue': 'BRONZOLO .BRANZOLL. (BZ)'
  },
  {
    'value': 'B204',
    'viewValue': 'BROSSASCO (CN)'
  },
  {
    'value': 'B205',
    'viewValue': 'BROSSO (TO)'
  },
  {
    'value': 'B207',
    'viewValue': 'BROVELLO-CARPUGNINO (VB)'
  },
  {
    'value': 'B209',
    'viewValue': 'BROZOLO (TO)'
  },
  {
    'value': 'B212',
    'viewValue': 'BRUGHERIO (MI)'
  },
  {
    'value': 'B213',
    'viewValue': 'BRUGINE (PD)'
  },
  {
    'value': 'B214',
    'viewValue': 'BRUGNATO (SP)'
  },
  {
    'value': 'B215',
    'viewValue': 'BRUGNERA (PN)'
  },
  {
    'value': 'B216',
    'viewValue': 'BRUINO (TO)'
  },
  {
    'value': 'B217',
    'viewValue': 'BRUMANO (BG)'
  },
  {
    'value': 'B218',
    'viewValue': 'BRUNATE (CO)'
  },
  {
    'value': 'B219',
    'viewValue': 'BRUNELLO (VA)'
  },
  {
    'value': 'B220',
    'viewValue': 'BRUNICO .BRUNECK. (BZ)'
  },
  {
    'value': 'B221',
    'viewValue': 'BRUNO (AT)'
  },
  {
    'value': 'B223',
    'viewValue': 'BRUSAPORTO (BG)'
  },
  {
    'value': 'B225',
    'viewValue': 'BRUSASCO (TO)'
  },
  {
    'value': 'B227',
    'viewValue': 'BRUSCIANO (NA)'
  },
  {
    'value': 'B228',
    'viewValue': 'BRUSIMPIANO (VA)'
  },
  {
    'value': 'B229',
    'viewValue': 'BRUSNENGO (BI)'
  },
  {
    'value': 'B230',
    'viewValue': 'BRUSSON (AO)'
  },
  {
    'value': 'B232',
    'viewValue': 'BRUZOLO (TO)'
  },
  {
    'value': 'B234',
    'viewValue': 'BRUZZANO ZEFFIRIO (RC)'
  },
  {
    'value': 'B235',
    'viewValue': 'BUBBIANO (MI)'
  },
  {
    'value': 'B236',
    'viewValue': 'BUBBIO (AT)'
  },
  {
    'value': 'B237',
    'viewValue': 'BUCCHERI (SR)'
  },
  {
    'value': 'B238',
    'viewValue': 'BUCCHIANICO (CH)'
  },
  {
    'value': 'B239',
    'viewValue': 'BUCCIANO (BN)'
  },
  {
    'value': 'B240',
    'viewValue': 'BUCCINASCO (MI)'
  },
  {
    'value': 'B242',
    'viewValue': 'BUCCINO (SA)'
  },
  {
    'value': 'B243',
    'viewValue': 'BUCINE (AR)'
  },
  {
    'value': 'B246',
    'viewValue': "BUDDUSO' (SS)"
  },
  {
    'value': 'B247',
    'viewValue': 'BUDOIA (PN)'
  },
  {
    'value': 'B248',
    'viewValue': 'BUDONI (NU)'
  },
  {
    'value': 'B249',
    'viewValue': 'BUDRIO (BO)'
  },
  {
    'value': 'B250',
    'viewValue': 'BUGGERRU (CA)'
  },
  {
    'value': 'B251',
    'viewValue': 'BUGGIANO (PT)'
  },
  {
    'value': 'B255',
    'viewValue': 'BUGLIO IN MONTE (SO)'
  },
  {
    'value': 'B256',
    'viewValue': 'BUGNARA (AQ)'
  },
  {
    'value': 'B258',
    'viewValue': 'BUGUGGIATE (VA)'
  },
  {
    'value': 'B259',
    'viewValue': 'BUJA (UD)'
  },
  {
    'value': 'B261',
    'viewValue': 'BULCIAGO (LC)'
  },
  {
    'value': 'B262',
    'viewValue': 'BULGAROGRASSO (CO)'
  },
  {
    'value': 'B264',
    'viewValue': 'BULTEI (SS)'
  },
  {
    'value': 'B265',
    'viewValue': 'BULZI (SS)'
  },
  {
    'value': 'B266',
    'viewValue': 'BUONABITACOLO (SA)'
  },
  {
    'value': 'B267',
    'viewValue': 'BUONALBERGO (BN)'
  },
  {
    'value': 'B269',
    'viewValue': 'BUONCONVENTO (SI)'
  },
  {
    'value': 'B270',
    'viewValue': 'BUONVICINO (CS)'
  },
  {
    'value': 'B272',
    'viewValue': 'BURAGO DI MOLGORA (MI)'
  },
  {
    'value': 'B274',
    'viewValue': 'BURCEI (CA)'
  },
  {
    'value': 'B275',
    'viewValue': 'BURGIO (AG)'
  },
  {
    'value': 'B276',
    'viewValue': 'BURGOS (SS)'
  },
  {
    'value': 'B278',
    'viewValue': 'BURIASCO (TO)'
  },
  {
    'value': 'B279',
    'viewValue': 'BUROLO (TO)'
  },
  {
    'value': 'B280',
    'viewValue': 'BURONZO (VC)'
  },
  {
    'value': 'B281',
    'viewValue': 'BUSACHI (OR)'
  },
  {
    'value': 'B282',
    'viewValue': 'BUSALLA (GE)'
  },
  {
    'value': 'B283',
    'viewValue': 'BUSANA (RE)'
  },
  {
    'value': 'B284',
    'viewValue': 'BUSANO (TO)'
  },
  {
    'value': 'B285',
    'viewValue': 'BUSCA (CN)'
  },
  {
    'value': 'B286',
    'viewValue': 'BUSCATE (MI)'
  },
  {
    'value': 'B287',
    'viewValue': 'BUSCEMI (SR)'
  },
  {
    'value': 'B288',
    'viewValue': 'BUSETO PALIZZOLO (TP)'
  },
  {
    'value': 'B289',
    'viewValue': 'BUSNAGO (MI)'
  },
  {
    'value': 'B292',
    'viewValue': 'BUSSERO (MI)'
  },
  {
    'value': 'B293',
    'viewValue': 'BUSSETO (PR)'
  },
  {
    'value': 'B294',
    'viewValue': 'BUSSI SUL TIRINO (PE)'
  },
  {
    'value': 'B295',
    'viewValue': 'BUSSO (CB)'
  },
  {
    'value': 'B296',
    'viewValue': 'BUSSOLENGO (VR)'
  },
  {
    'value': 'B297',
    'viewValue': 'BUSSOLENO (TO)'
  },
  {
    'value': 'B300',
    'viewValue': 'BUSTO ARSIZIO (VA)'
  },
  {
    'value': 'B301',
    'viewValue': 'BUSTO GAROLFO (MI)'
  },
  {
    'value': 'B302',
    'viewValue': 'BUTERA (CL)'
  },
  {
    'value': 'B303',
    'viewValue': 'BUTI (PI)'
  },
  {
    'value': 'B304',
    'viewValue': 'BUTTAPIETRA (VR)'
  },
  {
    'value': 'B305',
    'viewValue': 'BUTTIGLIERA ALTA (TO)'
  },
  {
    'value': 'B306',
    'viewValue': "BUTTIGLIERA D'ASTI (AT)"
  },
  {
    'value': 'B309',
    'viewValue': 'BUTTRIO (UD)'
  },
  {
    'value': 'B320',
    'viewValue': "CA' D'ANDREA (CR)"
  },
  {
    'value': 'B311',
    'viewValue': 'CABELLA LIGURE (AL)'
  },
  {
    'value': 'B313',
    'viewValue': 'CABIATE (CO)'
  },
  {
    'value': 'B314',
    'viewValue': 'CABRAS (OR)'
  },
  {
    'value': 'B315',
    'viewValue': 'CACCAMO (PA)'
  },
  {
    'value': 'B319',
    'viewValue': 'CACCURI (KR)'
  },
  {
    'value': 'B326',
    'viewValue': 'CADEGLIANO-VICONAGO (VA)'
  },
  {
    'value': 'B328',
    'viewValue': 'CADELBOSCO DI SOPRA (RE)'
  },
  {
    'value': 'B332',
    'viewValue': 'CADEO (PC)'
  },
  {
    'value': 'B335',
    'viewValue': 'CADERZONE (TN)'
  },
  {
    'value': 'B345',
    'viewValue': 'CADONEGHE (PD)'
  },
  {
    'value': 'B346',
    'viewValue': 'CADORAGO (CO)'
  },
  {
    'value': 'B347',
    'viewValue': 'CADREZZATE (VA)'
  },
  {
    'value': 'B349',
    'viewValue': 'CAERANO DI SAN MARCO (TV)'
  },
  {
    'value': 'B350',
    'viewValue': 'CAFASSE (TO)'
  },
  {
    'value': 'B351',
    'viewValue': 'CAGGIANO (SA)'
  },
  {
    'value': 'B352',
    'viewValue': 'CAGLI (PU)'
  },
  {
    'value': 'B354',
    'viewValue': 'CAGLIARI (CA)'
  },
  {
    'value': 'B355',
    'viewValue': 'CAGLIO (CO)'
  },
  {
    'value': 'B358',
    'viewValue': 'CAGNANO AMITERNO (AQ)'
  },
  {
    'value': 'B357',
    'viewValue': 'CAGNANO VARANO (FG)'
  },
  {
    'value': 'B359',
    'viewValue': 'CAGNO (CO)'
  },
  {
    'value': 'B360',
    'viewValue': "CAGNO' (TN)"
  },
  {
    'value': 'B361',
    'viewValue': 'CAIANELLO (CE)'
  },
  {
    'value': 'B362',
    'viewValue': 'CAIAZZO (CE)'
  },
  {
    'value': 'B364',
    'viewValue': 'CAINES .KUENS. (BZ)'
  },
  {
    'value': 'B365',
    'viewValue': 'CAINO (BS)'
  },
  {
    'value': 'B366',
    'viewValue': 'CAIOLO (SO)'
  },
  {
    'value': 'B367',
    'viewValue': 'CAIRANO (AV)'
  },
  {
    'value': 'B368',
    'viewValue': 'CAIRATE (VA)'
  },
  {
    'value': 'B369',
    'viewValue': 'CAIRO MONTENOTTE (SV)'
  },
  {
    'value': 'B371',
    'viewValue': 'CAIVANO (NA)'
  },
  {
    'value': 'B374',
    'viewValue': 'CALABRITTO (AV)'
  },
  {
    'value': 'B375',
    'viewValue': 'CALALZO DI CADORE (BL)'
  },
  {
    'value': 'B376',
    'viewValue': 'CALAMANDRANA (AT)'
  },
  {
    'value': 'B377',
    'viewValue': 'CALAMONACI (AG)'
  },
  {
    'value': 'B378',
    'viewValue': 'CALANGIANUS (SS)'
  },
  {
    'value': 'B379',
    'viewValue': 'CALANNA (RC)'
  },
  {
    'value': 'B380',
    'viewValue': 'CALASCA-CASTIGLIONE (VB)'
  },
  {
    'value': 'B381',
    'viewValue': 'CALASCIBETTA (EN)'
  },
  {
    'value': 'B382',
    'viewValue': 'CALASCIO (AQ)'
  },
  {
    'value': 'B383',
    'viewValue': 'CALASETTA (CA)'
  },
  {
    'value': 'B384',
    'viewValue': 'CALATABIANO (CT)'
  },
  {
    'value': 'B385',
    'viewValue': 'CALATAFIMI (TP)'
  },
  {
    'value': 'B386',
    'viewValue': 'CALAVINO (TN)'
  },
  {
    'value': 'B388',
    'viewValue': 'CALCATA (VT)'
  },
  {
    'value': 'B389',
    'viewValue': 'CALCERANICA AL LAGO (TN)'
  },
  {
    'value': 'B390',
    'viewValue': 'CALCI (PI)'
  },
  {
    'value': 'B391',
    'viewValue': 'CALCIANO (MT)'
  },
  {
    'value': 'B392',
    'viewValue': 'CALCINAIA (PI)'
  },
  {
    'value': 'B393',
    'viewValue': 'CALCINATE (BG)'
  },
  {
    'value': 'B394',
    'viewValue': 'CALCINATO (BS)'
  },
  {
    'value': 'B395',
    'viewValue': 'CALCIO (BG)'
  },
  {
    'value': 'B396',
    'viewValue': 'CALCO (LC)'
  },
  {
    'value': 'B397',
    'viewValue': 'CALDARO SULLA STRADA DEL VINO .KALTERN AN DE. (BZ)'
  },
  {
    'value': 'B398',
    'viewValue': 'CALDAROLA (MC)'
  },
  {
    'value': 'B399',
    'viewValue': 'CALDERARA DI RENO (BO)'
  },
  {
    'value': 'B400',
    'viewValue': 'CALDES (TN)'
  },
  {
    'value': 'B402',
    'viewValue': 'CALDIERO (VR)'
  },
  {
    'value': 'B403',
    'viewValue': 'CALDOGNO (VI)'
  },
  {
    'value': 'B404',
    'viewValue': 'CALDONAZZO (TN)'
  },
  {
    'value': 'B405',
    'viewValue': 'CALENDASCO (PC)'
  },
  {
    'value': 'B406',
    'viewValue': 'CALENZANO (FI)'
  },
  {
    'value': 'B408',
    'viewValue': 'CALESTANO (PR)'
  },
  {
    'value': 'B410',
    'viewValue': 'CALICE AL CORNOVIGLIO (SP)'
  },
  {
    'value': 'B409',
    'viewValue': 'CALICE LIGURE (SV)'
  },
  {
    'value': 'B413',
    'viewValue': 'CALIMERA (LE)'
  },
  {
    'value': 'B415',
    'viewValue': 'CALITRI (AV)'
  },
  {
    'value': 'B416',
    'viewValue': 'CALIZZANO (SV)'
  },
  {
    'value': 'B417',
    'viewValue': 'CALLABIANA (BI)'
  },
  {
    'value': 'B418',
    'viewValue': 'CALLIANO (AT)'
  },
  {
    'value': 'B419',
    'viewValue': 'CALLIANO (TN)'
  },
  {
    'value': 'B423',
    'viewValue': 'CALOLZIOCORTE (LC)'
  },
  {
    'value': 'B424',
    'viewValue': 'CALOPEZZATI (CS)'
  },
  {
    'value': 'B425',
    'viewValue': 'CALOSSO (AT)'
  },
  {
    'value': 'B426',
    'viewValue': 'CALOVETO (CS)'
  },
  {
    'value': 'B427',
    'viewValue': 'CALTABELLOTTA (AG)'
  },
  {
    'value': 'B428',
    'viewValue': 'CALTAGIRONE (CT)'
  },
  {
    'value': 'B429',
    'viewValue': 'CALTANISSETTA (CL)'
  },
  {
    'value': 'B430',
    'viewValue': 'CALTAVUTURO (PA)'
  },
  {
    'value': 'B431',
    'viewValue': 'CALTIGNAGA (NO)'
  },
  {
    'value': 'B432',
    'viewValue': 'CALTO (RO)'
  },
  {
    'value': 'B433',
    'viewValue': 'CALTRANO (VI)'
  },
  {
    'value': 'B434',
    'viewValue': "CALUSCO D'ADDA (BG)"
  },
  {
    'value': 'B435',
    'viewValue': 'CALUSO (TO)'
  },
  {
    'value': 'B436',
    'viewValue': 'CALVAGESE DELLA RIVIERA (BS)'
  },
  {
    'value': 'B437',
    'viewValue': 'CALVANICO (SA)'
  },
  {
    'value': 'B439',
    'viewValue': 'CALVATONE (CR)'
  },
  {
    'value': 'B440',
    'viewValue': 'CALVELLO (PZ)'
  },
  {
    'value': 'B441',
    'viewValue': 'CALVENE (VI)'
  },
  {
    'value': 'B442',
    'viewValue': 'CALVENZANO (BG)'
  },
  {
    'value': 'B443',
    'viewValue': 'CALVERA (PZ)'
  },
  {
    'value': 'B444',
    'viewValue': 'CALVI (BN)'
  },
  {
    'value': 'B446',
    'viewValue': "CALVI DELL'UMBRIA (TR)"
  },
  {
    'value': 'B445',
    'viewValue': 'CALVI RISORTA (CE)'
  },
  {
    'value': 'B449',
    'viewValue': 'CALVI SAN NAZZARO (BN)'
  },
  {
    'value': 'B447',
    'viewValue': 'CALVIGNANO (PV)'
  },
  {
    'value': 'B448',
    'viewValue': 'CALVIGNASCO (MI)'
  },
  {
    'value': 'B450',
    'viewValue': 'CALVISANO (BS)'
  },
  {
    'value': 'B452',
    'viewValue': 'CALVIZZANO (NA)'
  },
  {
    'value': 'B453',
    'viewValue': 'CAMAGNA MONFERRATO (AL)'
  },
  {
    'value': 'B455',
    'viewValue': 'CAMAIORE (LU)'
  },
  {
    'value': 'B456',
    'viewValue': 'CAMAIRAGO (LO)'
  },
  {
    'value': 'B457',
    'viewValue': 'CAMANDONA (BI)'
  },
  {
    'value': 'B460',
    'viewValue': 'CAMASTRA (AG)'
  },
  {
    'value': 'B461',
    'viewValue': 'CAMBIAGO (MI)'
  },
  {
    'value': 'B462',
    'viewValue': 'CAMBIANO (TO)'
  },
  {
    'value': 'B463',
    'viewValue': 'CAMBIASCA (VB)'
  },
  {
    'value': 'B465',
    'viewValue': 'CAMBURZANO (BI)'
  },
  {
    'value': 'B467',
    'viewValue': 'CAMERANA (CN)'
  },
  {
    'value': 'B468',
    'viewValue': 'CAMERANO (AN)'
  },
  {
    'value': 'B469',
    'viewValue': 'CAMERANO CASASCO (AT)'
  },
  {
    'value': 'B471',
    'viewValue': 'CAMERATA CORNELLO (BG)'
  },
  {
    'value': 'B472',
    'viewValue': 'CAMERATA NUOVA (RM)'
  },
  {
    'value': 'B470',
    'viewValue': 'CAMERATA PICENA (AN)'
  },
  {
    'value': 'B473',
    'viewValue': 'CAMERI (NO)'
  },
  {
    'value': 'B474',
    'viewValue': 'CAMERINO (MC)'
  },
  {
    'value': 'B476',
    'viewValue': 'CAMEROTA (SA)'
  },
  {
    'value': 'B477',
    'viewValue': 'CAMIGLIANO (CE)'
  },
  {
    'value': 'B479',
    'viewValue': 'CAMINATA (PC)'
  },
  {
    'value': 'B481',
    'viewValue': 'CAMINI (RC)'
  },
  {
    'value': 'B482',
    'viewValue': 'CAMINO (AL)'
  },
  {
    'value': 'B483',
    'viewValue': 'CAMINO AL TAGLIAMENTO (UD)'
  },
  {
    'value': 'B484',
    'viewValue': 'CAMISANO (CR)'
  },
  {
    'value': 'B485',
    'viewValue': 'CAMISANO VICENTINO (VI)'
  },
  {
    'value': 'B486',
    'viewValue': 'CAMMARATA (AG)'
  },
  {
    'value': 'B489',
    'viewValue': 'CAMO (CN)'
  },
  {
    'value': 'B490',
    'viewValue': 'CAMOGLI (GE)'
  },
  {
    'value': 'B492',
    'viewValue': 'CAMPAGNA (SA)'
  },
  {
    'value': 'B493',
    'viewValue': 'CAMPAGNA LUPIA (VE)'
  },
  {
    'value': 'B496',
    'viewValue': 'CAMPAGNANO DI ROMA (RM)'
  },
  {
    'value': 'B497',
    'viewValue': 'CAMPAGNATICO (GR)'
  },
  {
    'value': 'B498',
    'viewValue': 'CAMPAGNOLA CREMASCA (CR)'
  },
  {
    'value': 'B499',
    'viewValue': 'CAMPAGNOLA EMILIA (RE)'
  },
  {
    'value': 'B500',
    'viewValue': 'CAMPANA (CS)'
  },
  {
    'value': 'B501',
    'viewValue': 'CAMPARADA (MI)'
  },
  {
    'value': 'B502',
    'viewValue': 'CAMPEGINE (RE)'
  },
  {
    'value': 'B504',
    'viewValue': 'CAMPELLO SUL CLITUNNO (PG)'
  },
  {
    'value': 'B505',
    'viewValue': 'CAMPERTOGNO (VC)'
  },
  {
    'value': 'B507',
    'viewValue': 'CAMPI BISENZIO (FI)'
  },
  {
    'value': 'B506',
    'viewValue': 'CAMPI SALENTINA (LE)'
  },
  {
    'value': 'B508',
    'viewValue': 'CAMPIGLIA CERVO (BI)'
  },
  {
    'value': 'B511',
    'viewValue': 'CAMPIGLIA DEI BERICI (VI)'
  },
  {
    'value': 'B509',
    'viewValue': 'CAMPIGLIA MARITTIMA (LI)'
  },
  {
    'value': 'B512',
    'viewValue': 'CAMPIGLIONE FENILE (TO)'
  },
  {
    'value': 'B513',
    'viewValue': "CAMPIONE D'ITALIA (CO)"
  },
  {
    'value': 'B514',
    'viewValue': 'CAMPITELLO DI FASSA (TN)'
  },
  {
    'value': 'B515',
    'viewValue': 'CAMPLI (TE)'
  },
  {
    'value': 'B516',
    'viewValue': 'CAMPO CALABRO (RC)'
  },
  {
    'value': 'B526',
    'viewValue': 'CAMPO DI GIOVE (AQ)'
  },
  {
    'value': 'B529',
    'viewValue': 'CAMPO DI TRENS .FREIENFELD. (BZ)'
  },
  {
    'value': 'B538',
    'viewValue': 'CAMPO LIGURE (GE)'
  },
  {
    'value': 'B553',
    'viewValue': "CAMPO NELL'ELBA (LI)"
  },
  {
    'value': 'B564',
    'viewValue': 'CAMPO SAN MARTINO (PD)'
  },
  {
    'value': 'B570',
    'viewValue': 'CAMPO TURES .SAND IN TAUFERS. (BZ)'
  },
  {
    'value': 'B519',
    'viewValue': 'CAMPOBASSO (CB)'
  },
  {
    'value': 'B520',
    'viewValue': 'CAMPOBELLO DI LICATA (AG)'
  },
  {
    'value': 'B521',
    'viewValue': 'CAMPOBELLO DI MAZARA (TP)'
  },
  {
    'value': 'B522',
    'viewValue': 'CAMPOCHIARO (CB)'
  },
  {
    'value': 'B524',
    'viewValue': 'CAMPODARSEGO (PD)'
  },
  {
    'value': 'B525',
    'viewValue': 'CAMPODENNO (TN)'
  },
  {
    'value': 'B527',
    'viewValue': 'CAMPODIMELE (LT)'
  },
  {
    'value': 'B528',
    'viewValue': 'CAMPODIPIETRA (CB)'
  },
  {
    'value': 'B530',
    'viewValue': 'CAMPODOLCINO (SO)'
  },
  {
    'value': 'B531',
    'viewValue': 'CAMPODORO (PD)'
  },
  {
    'value': 'B533',
    'viewValue': 'CAMPOFELICE DI FITALIA (PA)'
  },
  {
    'value': 'B532',
    'viewValue': 'CAMPOFELICE DI ROCCELLA (PA)'
  },
  {
    'value': 'B534',
    'viewValue': 'CAMPOFILONE (AP)'
  },
  {
    'value': 'B535',
    'viewValue': 'CAMPOFIORITO (PA)'
  },
  {
    'value': 'B536',
    'viewValue': 'CAMPOFORMIDO (UD)'
  },
  {
    'value': 'B537',
    'viewValue': 'CAMPOFRANCO (CL)'
  },
  {
    'value': 'B539',
    'viewValue': 'CAMPOGALLIANO (MO)'
  },
  {
    'value': 'B541',
    'viewValue': 'CAMPOLATTARO (BN)'
  },
  {
    'value': 'B543',
    'viewValue': 'CAMPOLI APPENNINO (FR)'
  },
  {
    'value': 'B542',
    'viewValue': 'CAMPOLI DEL MONTE TABURNO (BN)'
  },
  {
    'value': 'B544',
    'viewValue': 'CAMPOLIETO (CB)'
  },
  {
    'value': 'B545',
    'viewValue': 'CAMPOLONGO AL TORRE (UD)'
  },
  {
    'value': 'B546',
    'viewValue': 'CAMPOLONGO MAGGIORE (VE)'
  },
  {
    'value': 'B547',
    'viewValue': 'CAMPOLONGO SUL BRENTA (VI)'
  },
  {
    'value': 'B549',
    'viewValue': 'CAMPOMAGGIORE (PZ)'
  },
  {
    'value': 'B550',
    'viewValue': 'CAMPOMARINO (CB)'
  },
  {
    'value': 'B551',
    'viewValue': 'CAMPOMORONE (GE)'
  },
  {
    'value': 'B554',
    'viewValue': 'CAMPONOGARA (VE)'
  },
  {
    'value': 'B555',
    'viewValue': 'CAMPORA (SA)'
  },
  {
    'value': 'B556',
    'viewValue': 'CAMPOREALE (PA)'
  },
  {
    'value': 'B557',
    'viewValue': 'CAMPORGIANO (LU)'
  },
  {
    'value': 'B559',
    'viewValue': 'CAMPOROSSO (IM)'
  },
  {
    'value': 'B562',
    'viewValue': 'CAMPOROTONDO DI FIASTRONE (MC)'
  },
  {
    'value': 'B561',
    'viewValue': 'CAMPOROTONDO ETNEO (CT)'
  },
  {
    'value': 'B563',
    'viewValue': 'CAMPOSAMPIERO (PD)'
  },
  {
    'value': 'B565',
    'viewValue': 'CAMPOSANO (NA)'
  },
  {
    'value': 'B566',
    'viewValue': 'CAMPOSANTO (MO)'
  },
  {
    'value': 'B567',
    'viewValue': 'CAMPOSPINOSO (PV)'
  },
  {
    'value': 'B569',
    'viewValue': 'CAMPOTOSTO (AQ)'
  },
  {
    'value': 'B572',
    'viewValue': 'CAMUGNANO (BO)'
  },
  {
    'value': 'B577',
    'viewValue': 'CANAL SAN BOVO (TN)'
  },
  {
    'value': 'B573',
    'viewValue': 'CANALE (CN)'
  },
  {
    'value': 'B574',
    'viewValue': "CANALE D'AGORDO (BL)"
  },
  {
    'value': 'B576',
    'viewValue': 'CANALE MONTERANO (RM)'
  },
  {
    'value': 'B578',
    'viewValue': 'CANARO (RO)'
  },
  {
    'value': 'B579',
    'viewValue': 'CANAZEI (TN)'
  },
  {
    'value': 'B580',
    'viewValue': 'CANCELLARA (PZ)'
  },
  {
    'value': 'B581',
    'viewValue': 'CANCELLO ED ARNONE (CE)'
  },
  {
    'value': 'B582',
    'viewValue': 'CANDA (RO)'
  },
  {
    'value': 'B584',
    'viewValue': 'CANDELA (FG)'
  },
  {
    'value': 'B586',
    'viewValue': 'CANDELO (BI)'
  },
  {
    'value': 'B588',
    'viewValue': 'CANDIA CANAVESE (TO)'
  },
  {
    'value': 'B587',
    'viewValue': 'CANDIA LOMELLINA (PV)'
  },
  {
    'value': 'B589',
    'viewValue': 'CANDIANA (PD)'
  },
  {
    'value': 'B590',
    'viewValue': 'CANDIDA (AV)'
  },
  {
    'value': 'B591',
    'viewValue': 'CANDIDONI (RC)'
  },
  {
    'value': 'B592',
    'viewValue': 'CANDIOLO (TO)'
  },
  {
    'value': 'B593',
    'viewValue': 'CANEGRATE (MI)'
  },
  {
    'value': 'B594',
    'viewValue': 'CANELLI (AT)'
  },
  {
    'value': 'B597',
    'viewValue': 'CANEPINA (VT)'
  },
  {
    'value': 'B598',
    'viewValue': 'CANEVA (PN)'
  },
  {
    'value': 'B599',
    'viewValue': 'CANEVINO (PV)'
  },
  {
    'value': 'B602',
    'viewValue': "CANICATTI' (AG)"
  },
  {
    'value': 'B603',
    'viewValue': 'CANICATTINI BAGNI (SR)'
  },
  {
    'value': 'B604',
    'viewValue': 'CANINO (VT)'
  },
  {
    'value': 'B605',
    'viewValue': 'CANISCHIO (TO)'
  },
  {
    'value': 'B606',
    'viewValue': 'CANISTRO (AQ)'
  },
  {
    'value': 'B607',
    'viewValue': 'CANNA (CS)'
  },
  {
    'value': 'B608',
    'viewValue': 'CANNALONGA (SA)'
  },
  {
    'value': 'B609',
    'viewValue': 'CANNARA (PG)'
  },
  {
    'value': 'B610',
    'viewValue': 'CANNERO RIVIERA (VB)'
  },
  {
    'value': 'B613',
    'viewValue': 'CANNETO PAVESE (PV)'
  },
  {
    'value': 'B612',
    'viewValue': "CANNETO SULL'OGLIO (MN)"
  },
  {
    'value': 'B615',
    'viewValue': 'CANNOBIO (VB)'
  },
  {
    'value': 'B616',
    'viewValue': 'CANNOLE (LE)'
  },
  {
    'value': 'B617',
    'viewValue': 'CANOLO (RC)'
  },
  {
    'value': 'B618',
    'viewValue': "CANONICA D'ADDA (BG)"
  },
  {
    'value': 'B619',
    'viewValue': 'CANOSA DI PUGLIA (BA)'
  },
  {
    'value': 'B620',
    'viewValue': 'CANOSA SANNITA (CH)'
  },
  {
    'value': 'B621',
    'viewValue': 'CANOSIO (CN)'
  },
  {
    'value': 'C669',
    'viewValue': 'CANOSSA (RE)'
  },
  {
    'value': 'B624',
    'viewValue': 'CANSANO (AQ)'
  },
  {
    'value': 'B626',
    'viewValue': 'CANTAGALLO (PO)'
  },
  {
    'value': 'B627',
    'viewValue': 'CANTALICE (RI)'
  },
  {
    'value': 'B628',
    'viewValue': 'CANTALUPA (TO)'
  },
  {
    'value': 'B631',
    'viewValue': 'CANTALUPO IN SABINA (RI)'
  },
  {
    'value': 'B629',
    'viewValue': 'CANTALUPO LIGURE (AL)'
  },
  {
    'value': 'B630',
    'viewValue': 'CANTALUPO NEL SANNIO (IS)'
  },
  {
    'value': 'B633',
    'viewValue': 'CANTARANA (AT)'
  },
  {
    'value': 'B634',
    'viewValue': 'CANTELLO (VA)'
  },
  {
    'value': 'B635',
    'viewValue': 'CANTERANO (RM)'
  },
  {
    'value': 'B636',
    'viewValue': 'CANTIANO (PU)'
  },
  {
    'value': 'B637',
    'viewValue': 'CANTOIRA (TO)'
  },
  {
    'value': 'B639',
    'viewValue': "CANTU' (CO)"
  },
  {
    'value': 'B640',
    'viewValue': 'CANZANO (TE)'
  },
  {
    'value': 'B641',
    'viewValue': 'CANZO (CO)'
  },
  {
    'value': 'B642',
    'viewValue': 'CAORLE (VE)'
  },
  {
    'value': 'B643',
    'viewValue': 'CAORSO (PC)'
  },
  {
    'value': 'B644',
    'viewValue': 'CAPACCIO (SA)'
  },
  {
    'value': 'B645',
    'viewValue': 'CAPACI (PA)'
  },
  {
    'value': 'B646',
    'viewValue': 'CAPALBIO (GR)'
  },
  {
    'value': 'B647',
    'viewValue': 'CAPANNOLI (PI)'
  },
  {
    'value': 'B648',
    'viewValue': 'CAPANNORI (LU)'
  },
  {
    'value': 'B649',
    'viewValue': 'CAPENA (RM)'
  },
  {
    'value': 'B650',
    'viewValue': 'CAPERGNANICA (CR)'
  },
  {
    'value': 'B651',
    'viewValue': 'CAPESTRANO (AQ)'
  },
  {
    'value': 'B653',
    'viewValue': 'CAPIAGO INTIMIANO (CO)'
  },
  {
    'value': 'B655',
    'viewValue': 'CAPISTRANO (VV)'
  },
  {
    'value': 'B656',
    'viewValue': 'CAPISTRELLO (AQ)'
  },
  {
    'value': 'B658',
    'viewValue': 'CAPITIGNANO (AQ)'
  },
  {
    'value': 'B660',
    'viewValue': 'CAPIZZI (ME)'
  },
  {
    'value': 'B661',
    'viewValue': 'CAPIZZONE (BG)'
  },
  {
    'value': 'B664',
    'viewValue': 'CAPO DI PONTE (BS)'
  },
  {
    'value': 'B666',
    'viewValue': "CAPO D'ORLANDO (ME)"
  },
  {
    'value': 'B663',
    'viewValue': 'CAPODIMONTE (VT)'
  },
  {
    'value': 'B667',
    'viewValue': 'CAPODRISE (CE)'
  },
  {
    'value': 'B669',
    'viewValue': 'CAPOLIVERI (LI)'
  },
  {
    'value': 'B670',
    'viewValue': 'CAPOLONA (AR)'
  },
  {
    'value': 'B671',
    'viewValue': 'CAPONAGO (MI)'
  },
  {
    'value': 'B672',
    'viewValue': 'CAPORCIANO (AQ)'
  },
  {
    'value': 'B674',
    'viewValue': 'CAPOSELE (AV)'
  },
  {
    'value': 'B675',
    'viewValue': 'CAPOTERRA (CA)'
  },
  {
    'value': 'B676',
    'viewValue': 'CAPOVALLE (BS)'
  },
  {
    'value': 'B677',
    'viewValue': 'CAPPADOCIA (AQ)'
  },
  {
    'value': 'B679',
    'viewValue': 'CAPPELLA CANTONE (CR)'
  },
  {
    'value': 'B680',
    'viewValue': "CAPPELLA DE' PICENARDI (CR)"
  },
  {
    'value': 'B678',
    'viewValue': 'CAPPELLA MAGGIORE (TV)'
  },
  {
    'value': 'B681',
    'viewValue': 'CAPPELLE SUL TAVO (PE)'
  },
  {
    'value': 'B682',
    'viewValue': 'CAPRACOTTA (IS)'
  },
  {
    'value': 'B684',
    'viewValue': 'CAPRAIA E LIMITE (FI)'
  },
  {
    'value': 'B685',
    'viewValue': 'CAPRAIA ISOLA (LI)'
  },
  {
    'value': 'B686',
    'viewValue': 'CAPRALBA (CR)'
  },
  {
    'value': 'B688',
    'viewValue': 'CAPRANICA (VT)'
  },
  {
    'value': 'B687',
    'viewValue': 'CAPRANICA PRENESTINA (RM)'
  },
  {
    'value': 'B690',
    'viewValue': 'CAPRARICA DI LECCE (LE)'
  },
  {
    'value': 'B691',
    'viewValue': 'CAPRAROLA (VT)'
  },
  {
    'value': 'B692',
    'viewValue': 'CAPRAUNA (CN)'
  },
  {
    'value': 'B693',
    'viewValue': 'CAPRESE MICHELANGELO (AR)'
  },
  {
    'value': 'B694',
    'viewValue': 'CAPREZZO (VB)'
  },
  {
    'value': 'B696',
    'viewValue': 'CAPRI (NA)'
  },
  {
    'value': 'B695',
    'viewValue': 'CAPRI LEONE (ME)'
  },
  {
    'value': 'B697',
    'viewValue': 'CAPRIANA (TN)'
  },
  {
    'value': 'B698',
    'viewValue': 'CAPRIANO DEL COLLE (BS)'
  },
  {
    'value': 'B701',
    'viewValue': "CAPRIATA D'ORBA (AL)"
  },
  {
    'value': 'B703',
    'viewValue': 'CAPRIATE SAN GERVASIO (BG)'
  },
  {
    'value': 'B704',
    'viewValue': 'CAPRIATI A VOLTURNO (CE)'
  },
  {
    'value': 'B705',
    'viewValue': 'CAPRIE (TO)'
  },
  {
    'value': 'B706',
    'viewValue': 'CAPRIGLIA IRPINA (AV)'
  },
  {
    'value': 'B707',
    'viewValue': 'CAPRIGLIO (AT)'
  },
  {
    'value': 'B708',
    'viewValue': 'CAPRILE (BI)'
  },
  {
    'value': 'B710',
    'viewValue': 'CAPRINO BERGAMASCO (BG)'
  },
  {
    'value': 'B709',
    'viewValue': 'CAPRINO VERONESE (VR)'
  },
  {
    'value': 'B711',
    'viewValue': 'CAPRIOLO (BS)'
  },
  {
    'value': 'B712',
    'viewValue': 'CAPRIVA DEL FRIULI (GO)'
  },
  {
    'value': 'B715',
    'viewValue': 'CAPUA (CE)'
  },
  {
    'value': 'B716',
    'viewValue': 'CAPURSO (BA)'
  },
  {
    'value': 'B718',
    'viewValue': 'CARAFFA DEL BIANCO (RC)'
  },
  {
    'value': 'B717',
    'viewValue': 'CARAFFA DI CATANZARO (CZ)'
  },
  {
    'value': 'B719',
    'viewValue': 'CARAGLIO (CN)'
  },
  {
    'value': 'B720',
    'viewValue': 'CARAMAGNA PIEMONTE (CN)'
  },
  {
    'value': 'B722',
    'viewValue': 'CARAMANICO TERME (PE)'
  },
  {
    'value': 'B723',
    'viewValue': 'CARANO (TN)'
  },
  {
    'value': 'B724',
    'viewValue': 'CARAPELLE (FG)'
  },
  {
    'value': 'B725',
    'viewValue': 'CARAPELLE CALVISIO (AQ)'
  },
  {
    'value': 'B726',
    'viewValue': 'CARASCO (GE)'
  },
  {
    'value': 'B727',
    'viewValue': 'CARASSAI (AP)'
  },
  {
    'value': 'B729',
    'viewValue': 'CARATE BRIANZA (MI)'
  },
  {
    'value': 'B730',
    'viewValue': 'CARATE URIO (CO)'
  },
  {
    'value': 'B731',
    'viewValue': 'CARAVAGGIO (BG)'
  },
  {
    'value': 'B732',
    'viewValue': 'CARAVATE (VA)'
  },
  {
    'value': 'B733',
    'viewValue': 'CARAVINO (TO)'
  },
  {
    'value': 'B734',
    'viewValue': 'CARAVONICA (IM)'
  },
  {
    'value': 'B735',
    'viewValue': 'CARBOGNANO (VT)'
  },
  {
    'value': 'B741',
    'viewValue': 'CARBONARA AL TICINO (PV)'
  },
  {
    'value': 'B740',
    'viewValue': 'CARBONARA DI NOLA (NA)'
  },
  {
    'value': 'B739',
    'viewValue': 'CARBONARA DI PO (MN)'
  },
  {
    'value': 'B736',
    'viewValue': 'CARBONARA SCRIVIA (AL)'
  },
  {
    'value': 'B742',
    'viewValue': 'CARBONATE (CO)'
  },
  {
    'value': 'B743',
    'viewValue': 'CARBONE (PZ)'
  },
  {
    'value': 'B744',
    'viewValue': 'CARBONERA (TV)'
  },
  {
    'value': 'B745',
    'viewValue': 'CARBONIA (CA)'
  },
  {
    'value': 'B748',
    'viewValue': 'CARCARE (SV)'
  },
  {
    'value': 'B749',
    'viewValue': 'CARCERI (PD)'
  },
  {
    'value': 'B752',
    'viewValue': 'CARCOFORO (VC)'
  },
  {
    'value': 'B754',
    'viewValue': 'CARDANO AL CAMPO (VA)'
  },
  {
    'value': 'B755',
    'viewValue': "CARDE' (CN)"
  },
  {
    'value': 'M285',
    'viewValue': 'CARDEDU (NU)'
  },
  {
    'value': 'B756',
    'viewValue': 'CARDETO (RC)'
  },
  {
    'value': 'B758',
    'viewValue': 'CARDINALE (CZ)'
  },
  {
    'value': 'B759',
    'viewValue': 'CARDITO (NA)'
  },
  {
    'value': 'B760',
    'viewValue': 'CAREGGINE (LU)'
  },
  {
    'value': 'B762',
    'viewValue': 'CAREMA (TO)'
  },
  {
    'value': 'B763',
    'viewValue': 'CARENNO (LC)'
  },
  {
    'value': 'B765',
    'viewValue': 'CARENTINO (AL)'
  },
  {
    'value': 'B766',
    'viewValue': 'CARERI (RC)'
  },
  {
    'value': 'B767',
    'viewValue': 'CARESANA (VC)'
  },
  {
    'value': 'B768',
    'viewValue': 'CARESANABLOT (VC)'
  },
  {
    'value': 'B769',
    'viewValue': 'CAREZZANO (AL)'
  },
  {
    'value': 'B771',
    'viewValue': 'CARFIZZI (KR)'
  },
  {
    'value': 'B772',
    'viewValue': 'CARGEGHE (SS)'
  },
  {
    'value': 'B774',
    'viewValue': 'CARIATI (CS)'
  },
  {
    'value': 'B776',
    'viewValue': 'CARIFE (AV)'
  },
  {
    'value': 'B777',
    'viewValue': 'CARIGNANO (TO)'
  },
  {
    'value': 'B778',
    'viewValue': 'CARIMATE (CO)'
  },
  {
    'value': 'B779',
    'viewValue': 'CARINARO (CE)'
  },
  {
    'value': 'B780',
    'viewValue': 'CARINI (PA)'
  },
  {
    'value': 'B781',
    'viewValue': 'CARINOLA (CE)'
  },
  {
    'value': 'B782',
    'viewValue': 'CARISIO (VC)'
  },
  {
    'value': 'B783',
    'viewValue': 'CARISOLO (TN)'
  },
  {
    'value': 'B784',
    'viewValue': 'CARLANTINO (FG)'
  },
  {
    'value': 'B785',
    'viewValue': 'CARLAZZO (CO)'
  },
  {
    'value': 'B787',
    'viewValue': 'CARLENTINI (SR)'
  },
  {
    'value': 'B788',
    'viewValue': 'CARLINO (UD)'
  },
  {
    'value': 'B789',
    'viewValue': 'CARLOFORTE (CA)'
  },
  {
    'value': 'B790',
    'viewValue': 'CARLOPOLI (CZ)'
  },
  {
    'value': 'B791',
    'viewValue': 'CARMAGNOLA (TO)'
  },
  {
    'value': 'B792',
    'viewValue': 'CARMIANO (LE)'
  },
  {
    'value': 'B794',
    'viewValue': 'CARMIGNANO (PO)'
  },
  {
    'value': 'B795',
    'viewValue': 'CARMIGNANO DI BRENTA (PD)'
  },
  {
    'value': 'B796',
    'viewValue': 'CARNAGO (VA)'
  },
  {
    'value': 'B798',
    'viewValue': 'CARNATE (MI)'
  },
  {
    'value': 'B801',
    'viewValue': 'CAROBBIO DEGLI ANGELI (BG)'
  },
  {
    'value': 'B802',
    'viewValue': 'CAROLEI (CS)'
  },
  {
    'value': 'B803',
    'viewValue': 'CARONA (BG)'
  },
  {
    'value': 'B804',
    'viewValue': 'CARONIA (ME)'
  },
  {
    'value': 'B805',
    'viewValue': 'CARONNO PERTUSELLA (VA)'
  },
  {
    'value': 'B807',
    'viewValue': 'CARONNO VARESINO (VA)'
  },
  {
    'value': 'B808',
    'viewValue': 'CAROSINO (TA)'
  },
  {
    'value': 'B809',
    'viewValue': 'CAROVIGNO (BR)'
  },
  {
    'value': 'B810',
    'viewValue': 'CAROVILLI (IS)'
  },
  {
    'value': 'B812',
    'viewValue': 'CARPANETO PIACENTINO (PC)'
  },
  {
    'value': 'B813',
    'viewValue': 'CARPANZANO (CS)'
  },
  {
    'value': 'B814',
    'viewValue': 'CARPASIO (IM)'
  },
  {
    'value': 'B816',
    'viewValue': 'CARPEGNA (PU)'
  },
  {
    'value': 'B817',
    'viewValue': 'CARPENEDOLO (BS)'
  },
  {
    'value': 'B818',
    'viewValue': 'CARPENETO (AL)'
  },
  {
    'value': 'B819',
    'viewValue': 'CARPI (MO)'
  },
  {
    'value': 'B820',
    'viewValue': 'CARPIANO (MI)'
  },
  {
    'value': 'B822',
    'viewValue': 'CARPIGNANO SALENTINO (LE)'
  },
  {
    'value': 'B823',
    'viewValue': 'CARPIGNANO SESIA (NO)'
  },
  {
    'value': 'B825',
    'viewValue': 'CARPINETI (RE)'
  },
  {
    'value': 'B827',
    'viewValue': 'CARPINETO DELLA NORA (PE)'
  },
  {
    'value': 'B828',
    'viewValue': 'CARPINETO ROMANO (RM)'
  },
  {
    'value': 'B826',
    'viewValue': 'CARPINETO SINELLO (CH)'
  },
  {
    'value': 'B829',
    'viewValue': 'CARPINO (FG)'
  },
  {
    'value': 'B830',
    'viewValue': 'CARPINONE (IS)'
  },
  {
    'value': 'B832',
    'viewValue': 'CARRARA (MS)'
  },
  {
    'value': 'B835',
    'viewValue': "CARRE' (VI)"
  },
  {
    'value': 'B836',
    'viewValue': 'CARREGA LIGURE (AL)'
  },
  {
    'value': 'B838',
    'viewValue': 'CARRO (SP)'
  },
  {
    'value': 'B839',
    'viewValue': 'CARRODANO (SP)'
  },
  {
    'value': 'B840',
    'viewValue': 'CARROSIO (AL)'
  },
  {
    'value': 'B841',
    'viewValue': "CARRU' (CN)"
  },
  {
    'value': 'B842',
    'viewValue': 'CARSOLI (AQ)'
  },
  {
    'value': 'B844',
    'viewValue': 'CARTIGLIANO (VI)'
  },
  {
    'value': 'B845',
    'viewValue': 'CARTIGNANO (CN)'
  },
  {
    'value': 'B846',
    'viewValue': 'CARTOCETO (PU)'
  },
  {
    'value': 'B847',
    'viewValue': 'CARTOSIO (AL)'
  },
  {
    'value': 'B848',
    'viewValue': 'CARTURA (PD)'
  },
  {
    'value': 'B850',
    'viewValue': 'CARUGATE (MI)'
  },
  {
    'value': 'B851',
    'viewValue': 'CARUGO (CO)'
  },
  {
    'value': 'B853',
    'viewValue': 'CARUNCHIO (CH)'
  },
  {
    'value': 'B854',
    'viewValue': 'CARVICO (BG)'
  },
  {
    'value': 'B856',
    'viewValue': 'CARZANO (TN)'
  },
  {
    'value': 'B857',
    'viewValue': 'CASABONA (KR)'
  },
  {
    'value': 'B858',
    'viewValue': 'CASACALENDA (CB)'
  },
  {
    'value': 'B859',
    'viewValue': 'CASACANDITELLA (CH)'
  },
  {
    'value': 'B860',
    'viewValue': 'CASAGIOVE (CE)'
  },
  {
    'value': 'B870',
    'viewValue': 'CASAL CERMELLI (AL)'
  },
  {
    'value': 'B872',
    'viewValue': 'CASAL DI PRINCIPE (CE)'
  },
  {
    'value': 'B895',
    'viewValue': 'CASAL VELINO (SA)'
  },
  {
    'value': 'B861',
    'viewValue': 'CASALANGUIDA (CH)'
  },
  {
    'value': 'B862',
    'viewValue': 'CASALATTICO (FR)'
  },
  {
    'value': 'B864',
    'viewValue': 'CASALBELTRAME (NO)'
  },
  {
    'value': 'B865',
    'viewValue': 'CASALBORDINO (CH)'
  },
  {
    'value': 'B866',
    'viewValue': 'CASALBORE (AV)'
  },
  {
    'value': 'B867',
    'viewValue': 'CASALBORGONE (TO)'
  },
  {
    'value': 'B868',
    'viewValue': 'CASALBUONO (SA)'
  },
  {
    'value': 'B869',
    'viewValue': 'CASALBUTTANO ED UNITI (CR)'
  },
  {
    'value': 'B871',
    'viewValue': 'CASALCIPRANO (CB)'
  },
  {
    'value': 'B873',
    'viewValue': 'CASALDUNI (BN)'
  },
  {
    'value': 'B876',
    'viewValue': 'CASALE CORTE CERRO (VB)'
  },
  {
    'value': 'B881',
    'viewValue': 'CASALE CREMASCO-VIDOLASCO (CR)'
  },
  {
    'value': 'B877',
    'viewValue': 'CASALE DI SCODOSIA (PD)'
  },
  {
    'value': 'B875',
    'viewValue': 'CASALE LITTA (VA)'
  },
  {
    'value': 'B878',
    'viewValue': 'CASALE MARITTIMO (PI)'
  },
  {
    'value': 'B885',
    'viewValue': 'CASALE MONFERRATO (AL)'
  },
  {
    'value': 'B879',
    'viewValue': 'CASALE SUL SILE (TV)'
  },
  {
    'value': 'B880',
    'viewValue': 'CASALECCHIO DI RENO (BO)'
  },
  {
    'value': 'B882',
    'viewValue': 'CASALEGGIO BOIRO (AL)'
  },
  {
    'value': 'B883',
    'viewValue': 'CASALEGGIO NOVARA (NO)'
  },
  {
    'value': 'B886',
    'viewValue': 'CASALEONE (VR)'
  },
  {
    'value': 'B889',
    'viewValue': 'CASALETTO CEREDANO (CR)'
  },
  {
    'value': 'B890',
    'viewValue': 'CASALETTO DI SOPRA (CR)'
  },
  {
    'value': 'B887',
    'viewValue': 'CASALETTO LODIGIANO (LO)'
  },
  {
    'value': 'B888',
    'viewValue': 'CASALETTO SPARTANO (SA)'
  },
  {
    'value': 'B891',
    'viewValue': 'CASALETTO VAPRIO (CR)'
  },
  {
    'value': 'B892',
    'viewValue': 'CASALFIUMANESE (BO)'
  },
  {
    'value': 'B893',
    'viewValue': 'CASALGRANDE (RE)'
  },
  {
    'value': 'B894',
    'viewValue': 'CASALGRASSO (CN)'
  },
  {
    'value': 'B896',
    'viewValue': 'CASALINCONTRADA (CH)'
  },
  {
    'value': 'B897',
    'viewValue': 'CASALINO (NO)'
  },
  {
    'value': 'B898',
    'viewValue': 'CASALMAGGIORE (CR)'
  },
  {
    'value': 'B899',
    'viewValue': 'CASALMAIOCCO (LO)'
  },
  {
    'value': 'B900',
    'viewValue': 'CASALMORANO (CR)'
  },
  {
    'value': 'B901',
    'viewValue': 'CASALMORO (MN)'
  },
  {
    'value': 'B902',
    'viewValue': 'CASALNOCETO (AL)'
  },
  {
    'value': 'B905',
    'viewValue': 'CASALNUOVO DI NAPOLI (NA)'
  },
  {
    'value': 'B904',
    'viewValue': 'CASALNUOVO MONTEROTARO (FG)'
  },
  {
    'value': 'B907',
    'viewValue': 'CASALOLDO (MN)'
  },
  {
    'value': 'B910',
    'viewValue': 'CASALPUSTERLENGO (LO)'
  },
  {
    'value': 'B911',
    'viewValue': 'CASALROMANO (MN)'
  },
  {
    'value': 'B912',
    'viewValue': 'CASALSERUGO (PD)'
  },
  {
    'value': 'B916',
    'viewValue': 'CASALUCE (CE)'
  },
  {
    'value': 'B917',
    'viewValue': 'CASALVECCHIO DI PUGLIA (FG)'
  },
  {
    'value': 'B918',
    'viewValue': 'CASALVECCHIO SICULO (ME)'
  },
  {
    'value': 'B919',
    'viewValue': 'CASALVIERI (FR)'
  },
  {
    'value': 'B920',
    'viewValue': 'CASALVOLONE (NO)'
  },
  {
    'value': 'B921',
    'viewValue': 'CASALZUIGNO (VA)'
  },
  {
    'value': 'B922',
    'viewValue': 'CASAMARCIANO (NA)'
  },
  {
    'value': 'B923',
    'viewValue': 'CASAMASSIMA (BA)'
  },
  {
    'value': 'B924',
    'viewValue': 'CASAMICCIOLA TERME (NA)'
  },
  {
    'value': 'B925',
    'viewValue': 'CASANDRINO (NA)'
  },
  {
    'value': 'B928',
    'viewValue': 'CASANOVA ELVO (VC)'
  },
  {
    'value': 'B927',
    'viewValue': 'CASANOVA LERRONE (SV)'
  },
  {
    'value': 'B929',
    'viewValue': 'CASANOVA LONATI (PV)'
  },
  {
    'value': 'B932',
    'viewValue': 'CASAPE (RM)'
  },
  {
    'value': 'M260',
    'viewValue': 'CASAPESENNA (CE)'
  },
  {
    'value': 'B933',
    'viewValue': 'CASAPINTA (BI)'
  },
  {
    'value': 'B934',
    'viewValue': 'CASAPROTA (RI)'
  },
  {
    'value': 'B935',
    'viewValue': 'CASAPULLA (CE)'
  },
  {
    'value': 'B936',
    'viewValue': 'CASARANO (LE)'
  },
  {
    'value': 'B937',
    'viewValue': 'CASARGO (LC)'
  },
  {
    'value': 'B938',
    'viewValue': 'CASARILE (MI)'
  },
  {
    'value': 'B940',
    'viewValue': 'CASARSA DELLA DELIZIA (PN)'
  },
  {
    'value': 'B939',
    'viewValue': 'CASARZA LIGURE (GE)'
  },
  {
    'value': 'B941',
    'viewValue': 'CASASCO (AL)'
  },
  {
    'value': 'B942',
    'viewValue': "CASASCO D'INTELVI (CO)"
  },
  {
    'value': 'B943',
    'viewValue': 'CASATENOVO (LC)'
  },
  {
    'value': 'B945',
    'viewValue': 'CASATISMA (PV)'
  },
  {
    'value': 'B946',
    'viewValue': 'CASAVATORE (NA)'
  },
  {
    'value': 'B947',
    'viewValue': 'CASAZZA (BG)'
  },
  {
    'value': 'B948',
    'viewValue': 'CASCIA (PG)'
  },
  {
    'value': 'B949',
    'viewValue': 'CASCIAGO (VA)'
  },
  {
    'value': 'A559',
    'viewValue': 'CASCIANA TERME (PI)'
  },
  {
    'value': 'B950',
    'viewValue': 'CASCINA (PI)'
  },
  {
    'value': 'B953',
    'viewValue': "CASCINETTE D'IVREA (TO)"
  },
  {
    'value': 'B954',
    'viewValue': 'CASEI GEROLA (PV)'
  },
  {
    'value': 'B955',
    'viewValue': 'CASELETTE (TO)'
  },
  {
    'value': 'B956',
    'viewValue': 'CASELLA (GE)'
  },
  {
    'value': 'B959',
    'viewValue': 'CASELLE IN PITTARI (SA)'
  },
  {
    'value': 'B961',
    'viewValue': 'CASELLE LANDI (LO)'
  },
  {
    'value': 'B958',
    'viewValue': 'CASELLE LURANI (LO)'
  },
  {
    'value': 'B960',
    'viewValue': 'CASELLE TORINESE (TO)'
  },
  {
    'value': 'B963',
    'viewValue': 'CASERTA (CE)'
  },
  {
    'value': 'B965',
    'viewValue': 'CASIER (TV)'
  },
  {
    'value': 'B966',
    'viewValue': 'CASIGNANA (RC)'
  },
  {
    'value': 'B967',
    'viewValue': 'CASINA (RE)'
  },
  {
    'value': 'B971',
    'viewValue': "CASIRATE D'ADDA (BG)"
  },
  {
    'value': 'B974',
    'viewValue': "CASLINO D'ERBA (CO)"
  },
  {
    'value': 'B977',
    'viewValue': 'CASNATE CON BERNATE (CO)'
  },
  {
    'value': 'B978',
    'viewValue': 'CASNIGO (BG)'
  },
  {
    'value': 'B980',
    'viewValue': 'CASOLA DI NAPOLI (NA)'
  },
  {
    'value': 'B979',
    'viewValue': 'CASOLA IN LUNIGIANA (MS)'
  },
  {
    'value': 'B982',
    'viewValue': 'CASOLA VALSENIO (RA)'
  },
  {
    'value': 'B983',
    'viewValue': 'CASOLE BRUZIO (CS)'
  },
  {
    'value': 'B984',
    'viewValue': "CASOLE D'ELSA (SI)"
  },
  {
    'value': 'B985',
    'viewValue': 'CASOLI (CH)'
  },
  {
    'value': 'B988',
    'viewValue': 'CASORATE PRIMO (PV)'
  },
  {
    'value': 'B987',
    'viewValue': 'CASORATE SEMPIONE (VA)'
  },
  {
    'value': 'B989',
    'viewValue': 'CASOREZZO (MI)'
  },
  {
    'value': 'B990',
    'viewValue': 'CASORIA (NA)'
  },
  {
    'value': 'B991',
    'viewValue': 'CASORZO (AT)'
  },
  {
    'value': 'A472',
    'viewValue': 'CASPERIA (RI)'
  },
  {
    'value': 'B993',
    'viewValue': 'CASPOGGIO (SO)'
  },
  {
    'value': 'B994',
    'viewValue': 'CASSACCO (UD)'
  },
  {
    'value': 'B996',
    'viewValue': 'CASSAGO BRIANZA (LC)'
  },
  {
    'value': 'C002',
    'viewValue': 'CASSANO ALLO IONIO (CS)'
  },
  {
    'value': 'C003',
    'viewValue': "CASSANO D'ADDA (MI)"
  },
  {
    'value': 'B998',
    'viewValue': 'CASSANO DELLE MURGE (BA)'
  },
  {
    'value': 'B997',
    'viewValue': 'CASSANO IRPINO (AV)'
  },
  {
    'value': 'C004',
    'viewValue': 'CASSANO MAGNAGO (VA)'
  },
  {
    'value': 'C005',
    'viewValue': 'CASSANO SPINOLA (AL)'
  },
  {
    'value': 'B999',
    'viewValue': 'CASSANO VALCUVIA (VA)'
  },
  {
    'value': 'C006',
    'viewValue': 'CASSARO (SR)'
  },
  {
    'value': 'C007',
    'viewValue': 'CASSIGLIO (BG)'
  },
  {
    'value': 'C014',
    'viewValue': "CASSINA DE' PECCHI (MI)"
  },
  {
    'value': 'C020',
    'viewValue': 'CASSINA RIZZARDI (CO)'
  },
  {
    'value': 'C024',
    'viewValue': 'CASSINA VALSASSINA (LC)'
  },
  {
    'value': 'C022',
    'viewValue': 'CASSINASCO (AT)'
  },
  {
    'value': 'C027',
    'viewValue': 'CASSINE (AL)'
  },
  {
    'value': 'C030',
    'viewValue': 'CASSINELLE (AL)'
  },
  {
    'value': 'C033',
    'viewValue': 'CASSINETTA DI LUGAGNANO (MI)'
  },
  {
    'value': 'C034',
    'viewValue': 'CASSINO (FR)'
  },
  {
    'value': 'C037',
    'viewValue': 'CASSOLA (VI)'
  },
  {
    'value': 'C038',
    'viewValue': 'CASSOLNOVO (PV)'
  },
  {
    'value': 'C041',
    'viewValue': 'CASTAGNARO (VR)'
  },
  {
    'value': 'C044',
    'viewValue': 'CASTAGNETO CARDUCCI (LI)'
  },
  {
    'value': 'C045',
    'viewValue': 'CASTAGNETO PO (TO)'
  },
  {
    'value': 'C046',
    'viewValue': 'CASTAGNITO (CN)'
  },
  {
    'value': 'C049',
    'viewValue': 'CASTAGNOLE DELLE LANZE (AT)'
  },
  {
    'value': 'C047',
    'viewValue': 'CASTAGNOLE MONFERRATO (AT)'
  },
  {
    'value': 'C048',
    'viewValue': 'CASTAGNOLE PIEMONTE (TO)'
  },
  {
    'value': 'C050',
    'viewValue': 'CASTANA (PV)'
  },
  {
    'value': 'C052',
    'viewValue': 'CASTANO PRIMO (MI)'
  },
  {
    'value': 'C053',
    'viewValue': 'CASTEGGIO (PV)'
  },
  {
    'value': 'C055',
    'viewValue': 'CASTEGNATO (BS)'
  },
  {
    'value': 'C056',
    'viewValue': 'CASTEGNERO (VI)'
  },
  {
    'value': 'C058',
    'viewValue': 'CASTEL BARONIA (AV)'
  },
  {
    'value': 'C064',
    'viewValue': 'CASTEL BOGLIONE (AT)'
  },
  {
    'value': 'C065',
    'viewValue': 'CASTEL BOLOGNESE (RA)'
  },
  {
    'value': 'B494',
    'viewValue': 'CASTEL CAMPAGNANO (CE)'
  },
  {
    'value': 'C040',
    'viewValue': 'CASTEL CASTAGNA (TE)'
  },
  {
    'value': 'C071',
    'viewValue': 'CASTEL COLONNA (AN)'
  },
  {
    'value': 'C183',
    'viewValue': 'CASTEL CONDINO (TN)'
  },
  {
    'value': 'C075',
    'viewValue': "CASTEL D'AIANO (BO)"
  },
  {
    'value': 'C076',
    'viewValue': "CASTEL D'ARIO (MN)"
  },
  {
    'value': 'C078',
    'viewValue': "CASTEL D'AZZANO (VR)"
  },
  {
    'value': 'C082',
    'viewValue': 'CASTEL DEL GIUDICE (IS)'
  },
  {
    'value': 'C083',
    'viewValue': 'CASTEL DEL MONTE (AQ)'
  },
  {
    'value': 'C085',
    'viewValue': 'CASTEL DEL PIANO (GR)'
  },
  {
    'value': 'C086',
    'viewValue': 'CASTEL DEL RIO (BO)'
  },
  {
    'value': 'B969',
    'viewValue': 'CASTEL DI CASIO (BO)'
  },
  {
    'value': 'C090',
    'viewValue': 'CASTEL DI IERI (AQ)'
  },
  {
    'value': 'C091',
    'viewValue': 'CASTEL DI IUDICA (CT)'
  },
  {
    'value': 'C093',
    'viewValue': 'CASTEL DI LAMA (AP)'
  },
  {
    'value': 'C094',
    'viewValue': 'CASTEL DI LUCIO (ME)'
  },
  {
    'value': 'C096',
    'viewValue': 'CASTEL DI SANGRO (AQ)'
  },
  {
    'value': 'C097',
    'viewValue': 'CASTEL DI SASSO (CE)'
  },
  {
    'value': 'C098',
    'viewValue': 'CASTEL DI TORA (RI)'
  },
  {
    'value': 'C102',
    'viewValue': 'CASTEL FOCOGNANO (AR)'
  },
  {
    'value': 'C114',
    'viewValue': 'CASTEL FRENTANO (CH)'
  },
  {
    'value': 'C115',
    'viewValue': 'CASTEL GABBIANO (CR)'
  },
  {
    'value': 'C116',
    'viewValue': 'CASTEL GANDOLFO (RM)'
  },
  {
    'value': 'C117',
    'viewValue': 'CASTEL GIORGIO (TR)'
  },
  {
    'value': 'C118',
    'viewValue': 'CASTEL GOFFREDO (MN)'
  },
  {
    'value': 'C121',
    'viewValue': 'CASTEL GUELFO DI BOLOGNA (BO)'
  },
  {
    'value': 'C203',
    'viewValue': 'CASTEL MADAMA (RM)'
  },
  {
    'value': 'C204',
    'viewValue': 'CASTEL MAGGIORE (BO)'
  },
  {
    'value': 'C208',
    'viewValue': 'CASTEL MELLA (BS)'
  },
  {
    'value': 'C211',
    'viewValue': 'CASTEL MORRONE (CE)'
  },
  {
    'value': 'C252',
    'viewValue': 'CASTEL RITALDI (PG)'
  },
  {
    'value': 'C253',
    'viewValue': 'CASTEL ROCCHERO (AT)'
  },
  {
    'value': 'C255',
    'viewValue': 'CASTEL ROZZONE (BG)'
  },
  {
    'value': 'C259',
    'viewValue': 'CASTEL SAN GIORGIO (SA)'
  },
  {
    'value': 'C261',
    'viewValue': 'CASTEL SAN GIOVANNI (PC)'
  },
  {
    'value': 'C262',
    'viewValue': 'CASTEL SAN LORENZO (SA)'
  },
  {
    'value': 'C263',
    'viewValue': "CASTEL SAN NICCOLO' (AR)"
  },
  {
    'value': 'C266',
    'viewValue': 'CASTEL SAN PIETRO ROMANO (RM)'
  },
  {
    'value': 'C265',
    'viewValue': 'CASTEL SAN PIETRO TERME (BO)'
  },
  {
    'value': 'C270',
    'viewValue': 'CASTEL SAN VINCENZO (IS)'
  },
  {
    'value': 'C268',
    'viewValue': "CASTEL SANT'ANGELO (RI)"
  },
  {
    'value': 'C269',
    'viewValue': "CASTEL SANT'ELIA (VT)"
  },
  {
    'value': 'C289',
    'viewValue': 'CASTEL VISCARDO (TR)'
  },
  {
    'value': 'C110',
    'viewValue': 'CASTEL VITTORIO (IM)'
  },
  {
    'value': 'C291',
    'viewValue': 'CASTEL VOLTURNO (CE)'
  },
  {
    'value': 'C057',
    'viewValue': 'CASTELBALDO (PD)'
  },
  {
    'value': 'C059',
    'viewValue': 'CASTELBELFORTE (MN)'
  },
  {
    'value': 'C060',
    'viewValue': 'CASTELBELLINO (AN)'
  },
  {
    'value': 'C062',
    'viewValue': 'CASTELBELLO CIARDES .KASTELBELL TSCHARS. (BZ)'
  },
  {
    'value': 'C063',
    'viewValue': 'CASTELBIANCO (SV)'
  },
  {
    'value': 'C066',
    'viewValue': 'CASTELBOTTACCIO (CB)'
  },
  {
    'value': 'C067',
    'viewValue': 'CASTELBUONO (PA)'
  },
  {
    'value': 'C069',
    'viewValue': 'CASTELCIVITA (SA)'
  },
  {
    'value': 'C072',
    'viewValue': 'CASTELCOVATI (BS)'
  },
  {
    'value': 'C073',
    'viewValue': 'CASTELCUCCO (TV)'
  },
  {
    'value': 'C074',
    'viewValue': 'CASTELDACCIA (PA)'
  },
  {
    'value': 'C080',
    'viewValue': 'CASTELDELCI (PU)'
  },
  {
    'value': 'C081',
    'viewValue': 'CASTELDELFINO (CN)'
  },
  {
    'value': 'C089',
    'viewValue': 'CASTELDIDONE (CR)'
  },
  {
    'value': 'C100',
    'viewValue': 'CASTELFIDARDO (AN)'
  },
  {
    'value': 'C101',
    'viewValue': 'CASTELFIORENTINO (FI)'
  },
  {
    'value': 'C103',
    'viewValue': 'CASTELFONDO (TN)'
  },
  {
    'value': 'C104',
    'viewValue': 'CASTELFORTE (LT)'
  },
  {
    'value': 'C105',
    'viewValue': 'CASTELFRANCI (AV)'
  },
  {
    'value': 'C112',
    'viewValue': 'CASTELFRANCO DI SOPRA (AR)'
  },
  {
    'value': 'C113',
    'viewValue': 'CASTELFRANCO DI SOTTO (PI)'
  },
  {
    'value': 'C107',
    'viewValue': 'CASTELFRANCO EMILIA (MO)'
  },
  {
    'value': 'C106',
    'viewValue': 'CASTELFRANCO IN MISCANO (BN)'
  },
  {
    'value': 'C111',
    'viewValue': 'CASTELFRANCO VENETO (TV)'
  },
  {
    'value': 'C119',
    'viewValue': 'CASTELGOMBERTO (VI)'
  },
  {
    'value': 'C120',
    'viewValue': 'CASTELGRANDE (PZ)'
  },
  {
    'value': 'C122',
    'viewValue': 'CASTELGUGLIELMO (RO)'
  },
  {
    'value': 'C123',
    'viewValue': 'CASTELGUIDONE (CH)'
  },
  {
    'value': 'C125',
    'viewValue': 'CASTELLABATE (SA)'
  },
  {
    'value': 'C126',
    'viewValue': 'CASTELLAFIUME (AQ)'
  },
  {
    'value': 'C127',
    'viewValue': "CASTELL'ALFERO (AT)"
  },
  {
    'value': 'C128',
    'viewValue': 'CASTELLALTO (TE)'
  },
  {
    'value': 'C130',
    'viewValue': 'CASTELLAMMARE DEL GOLFO (TP)'
  },
  {
    'value': 'C129',
    'viewValue': 'CASTELLAMMARE DI STABIA (NA)'
  },
  {
    'value': 'C133',
    'viewValue': 'CASTELLAMONTE (TO)'
  },
  {
    'value': 'C134',
    'viewValue': 'CASTELLANA GROTTE (BA)'
  },
  {
    'value': 'C135',
    'viewValue': 'CASTELLANA SICULA (PA)'
  },
  {
    'value': 'C136',
    'viewValue': 'CASTELLANETA (TA)'
  },
  {
    'value': 'C137',
    'viewValue': 'CASTELLANIA (AL)'
  },
  {
    'value': 'C139',
    'viewValue': 'CASTELLANZA (VA)'
  },
  {
    'value': 'C140',
    'viewValue': 'CASTELLAR (CN)'
  },
  {
    'value': 'C142',
    'viewValue': 'CASTELLAR GUIDOBONO (AL)'
  },
  {
    'value': 'C141',
    'viewValue': 'CASTELLARANO (RE)'
  },
  {
    'value': 'C143',
    'viewValue': 'CASTELLARO (IM)'
  },
  {
    'value': 'C145',
    'viewValue': "CASTELL'ARQUATO (PC)"
  },
  {
    'value': 'C146',
    'viewValue': 'CASTELLAVAZZO (BL)'
  },
  {
    'value': 'C147',
    'viewValue': "CASTELL'AZZARA (GR)"
  },
  {
    'value': 'C148',
    'viewValue': 'CASTELLAZZO BORMIDA (AL)'
  },
  {
    'value': 'C149',
    'viewValue': 'CASTELLAZZO NOVARESE (NO)'
  },
  {
    'value': 'C153',
    'viewValue': 'CASTELLEONE (CR)'
  },
  {
    'value': 'C152',
    'viewValue': 'CASTELLEONE DI SUASA (AN)'
  },
  {
    'value': 'C154',
    'viewValue': 'CASTELLERO (AT)'
  },
  {
    'value': 'C155',
    'viewValue': 'CASTELLETTO CERVO (BI)'
  },
  {
    'value': 'C156',
    'viewValue': "CASTELLETTO D'ERRO (AL)"
  },
  {
    'value': 'C157',
    'viewValue': 'CASTELLETTO DI BRANDUZZO (PV)'
  },
  {
    'value': 'C158',
    'viewValue': "CASTELLETTO D'ORBA (AL)"
  },
  {
    'value': 'C160',
    'viewValue': 'CASTELLETTO MERLI (AL)'
  },
  {
    'value': 'C161',
    'viewValue': 'CASTELLETTO MOLINA (AT)'
  },
  {
    'value': 'C162',
    'viewValue': 'CASTELLETTO MONFERRATO (AL)'
  },
  {
    'value': 'C166',
    'viewValue': 'CASTELLETTO SOPRA TICINO (NO)'
  },
  {
    'value': 'C165',
    'viewValue': 'CASTELLETTO STURA (CN)'
  },
  {
    'value': 'C167',
    'viewValue': 'CASTELLETTO UZZONE (CN)'
  },
  {
    'value': 'C169',
    'viewValue': 'CASTELLI (TE)'
  },
  {
    'value': 'C079',
    'viewValue': 'CASTELLI CALEPIO (BG)'
  },
  {
    'value': 'C172',
    'viewValue': 'CASTELLINA IN CHIANTI (SI)'
  },
  {
    'value': 'C174',
    'viewValue': 'CASTELLINA MARITTIMA (PI)'
  },
  {
    'value': 'C173',
    'viewValue': 'CASTELLINALDO (CN)'
  },
  {
    'value': 'C175',
    'viewValue': 'CASTELLINO DEL BIFERNO (CB)'
  },
  {
    'value': 'C176',
    'viewValue': 'CASTELLINO TANARO (CN)'
  },
  {
    'value': 'C177',
    'viewValue': 'CASTELLIRI (FR)'
  },
  {
    'value': 'B312',
    'viewValue': 'CASTELLO CABIAGLIO (VA)'
  },
  {
    'value': 'C184',
    'viewValue': "CASTELLO D'AGOGNA (PV)"
  },
  {
    'value': 'C185',
    'viewValue': "CASTELLO D'ARGILE (BO)"
  },
  {
    'value': 'C178',
    'viewValue': 'CASTELLO DEL MATESE (CE)'
  },
  {
    'value': 'C186',
    'viewValue': "CASTELLO DELL'ACQUA (SO)"
  },
  {
    'value': 'A300',
    'viewValue': 'CASTELLO DI ANNONE (AT)'
  },
  {
    'value': 'C187',
    'viewValue': 'CASTELLO DI BRIANZA (LC)'
  },
  {
    'value': 'C188',
    'viewValue': 'CASTELLO DI CISTERNA (NA)'
  },
  {
    'value': 'C190',
    'viewValue': 'CASTELLO DI GODEGO (TV)'
  },
  {
    'value': 'C191',
    'viewValue': 'CASTELLO DI SERRAVALLE (BO)'
  },
  {
    'value': 'C194',
    'viewValue': 'CASTELLO TESINO (TN)'
  },
  {
    'value': 'C189',
    'viewValue': 'CASTELLO-MOLINA DI FIEMME (TN)'
  },
  {
    'value': 'C195',
    'viewValue': 'CASTELLUCCHIO (MN)'
  },
  {
    'value': 'C198',
    'viewValue': 'CASTELLUCCIO DEI SAURI (FG)'
  },
  {
    'value': 'C199',
    'viewValue': 'CASTELLUCCIO INFERIORE (PZ)'
  },
  {
    'value': 'C201',
    'viewValue': 'CASTELLUCCIO SUPERIORE (PZ)'
  },
  {
    'value': 'C202',
    'viewValue': 'CASTELLUCCIO VALMAGGIORE (FG)'
  },
  {
    'value': 'C051',
    'viewValue': "CASTELL'UMBERTO (ME)"
  },
  {
    'value': 'C205',
    'viewValue': 'CASTELMAGNO (CN)'
  },
  {
    'value': 'C206',
    'viewValue': 'CASTELMARTE (CO)'
  },
  {
    'value': 'C207',
    'viewValue': 'CASTELMASSA (RO)'
  },
  {
    'value': 'C197',
    'viewValue': 'CASTELMAURO (CB)'
  },
  {
    'value': 'C209',
    'viewValue': 'CASTELMEZZANO (PZ)'
  },
  {
    'value': 'C210',
    'viewValue': 'CASTELMOLA (ME)'
  },
  {
    'value': 'C213',
    'viewValue': 'CASTELNOVETTO (PV)'
  },
  {
    'value': 'C215',
    'viewValue': 'CASTELNOVO BARIANO (RO)'
  },
  {
    'value': 'C217',
    'viewValue': 'CASTELNOVO DEL FRIULI (PN)'
  },
  {
    'value': 'C218',
    'viewValue': 'CASTELNOVO DI SOTTO (RE)'
  },
  {
    'value': 'C219',
    'viewValue': "CASTELNOVO NE' MONTI (RE)"
  },
  {
    'value': 'C216',
    'viewValue': 'CASTELNUOVO (TN)'
  },
  {
    'value': 'C226',
    'viewValue': 'CASTELNUOVO BELBO (AT)'
  },
  {
    'value': 'C227',
    'viewValue': 'CASTELNUOVO BERARDENGA (SI)'
  },
  {
    'value': 'C228',
    'viewValue': "CASTELNUOVO BOCCA D'ADDA (LO)"
  },
  {
    'value': 'C229',
    'viewValue': 'CASTELNUOVO BORMIDA (AL)'
  },
  {
    'value': 'C220',
    'viewValue': 'CASTELNUOVO BOZZENTE (CO)'
  },
  {
    'value': 'C230',
    'viewValue': 'CASTELNUOVO CALCEA (AT)'
  },
  {
    'value': 'C231',
    'viewValue': 'CASTELNUOVO CILENTO (SA)'
  },
  {
    'value': 'C225',
    'viewValue': 'CASTELNUOVO DEL GARDA (VR)'
  },
  {
    'value': 'C222',
    'viewValue': 'CASTELNUOVO DELLA DAUNIA (FG)'
  },
  {
    'value': 'C214',
    'viewValue': 'CASTELNUOVO DI CEVA (CN)'
  },
  {
    'value': 'C235',
    'viewValue': 'CASTELNUOVO DI CONZA (SA)'
  },
  {
    'value': 'C224',
    'viewValue': 'CASTELNUOVO DI FARFA (RI)'
  },
  {
    'value': 'C236',
    'viewValue': 'CASTELNUOVO DI GARFAGNANA (LU)'
  },
  {
    'value': 'C237',
    'viewValue': 'CASTELNUOVO DI PORTO (RM)'
  },
  {
    'value': 'C232',
    'viewValue': 'CASTELNUOVO DON BOSCO (AT)'
  },
  {
    'value': 'C240',
    'viewValue': 'CASTELNUOVO MAGRA (SP)'
  },
  {
    'value': 'C241',
    'viewValue': 'CASTELNUOVO NIGRA (TO)'
  },
  {
    'value': 'C223',
    'viewValue': 'CASTELNUOVO PARANO (FR)'
  },
  {
    'value': 'C242',
    'viewValue': 'CASTELNUOVO RANGONE (MO)'
  },
  {
    'value': 'C243',
    'viewValue': 'CASTELNUOVO SCRIVIA (AL)'
  },
  {
    'value': 'C244',
    'viewValue': 'CASTELNUOVO VAL DI CECINA (PI)'
  },
  {
    'value': 'C245',
    'viewValue': 'CASTELPAGANO (BN)'
  },
  {
    'value': 'C246',
    'viewValue': 'CASTELPETROSO (IS)'
  },
  {
    'value': 'C247',
    'viewValue': 'CASTELPIZZUTO (IS)'
  },
  {
    'value': 'C248',
    'viewValue': 'CASTELPLANIO (AN)'
  },
  {
    'value': 'C250',
    'viewValue': 'CASTELPOTO (BN)'
  },
  {
    'value': 'C251',
    'viewValue': 'CASTELRAIMONDO (MC)'
  },
  {
    'value': 'C254',
    'viewValue': 'CASTELROTTO .KASTELRUTH. (BZ)'
  },
  {
    'value': 'C267',
    'viewValue': 'CASTELSANTANGELO SUL NERA (MC)'
  },
  {
    'value': 'C271',
    'viewValue': 'CASTELSARACENO (PZ)'
  },
  {
    'value': 'C272',
    'viewValue': 'CASTELSARDO (SS)'
  },
  {
    'value': 'C273',
    'viewValue': 'CASTELSEPRIO (VA)'
  },
  {
    'value': 'B968',
    'viewValue': 'CASTELSILANO (KR)'
  },
  {
    'value': 'C274',
    'viewValue': 'CASTELSPINA (AL)'
  },
  {
    'value': 'C275',
    'viewValue': 'CASTELTERMINI (AG)'
  },
  {
    'value': 'C181',
    'viewValue': 'CASTELVECCANA (VA)'
  },
  {
    'value': 'C278',
    'viewValue': 'CASTELVECCHIO CALVISIO (AQ)'
  },
  {
    'value': 'C276',
    'viewValue': 'CASTELVECCHIO DI ROCCA BARBENA (SV)'
  },
  {
    'value': 'C279',
    'viewValue': 'CASTELVECCHIO SUBEQUO (AQ)'
  },
  {
    'value': 'C280',
    'viewValue': 'CASTELVENERE (BN)'
  },
  {
    'value': 'B129',
    'viewValue': 'CASTELVERDE (CR)'
  },
  {
    'value': 'C200',
    'viewValue': 'CASTELVERRINO (IS)'
  },
  {
    'value': 'C284',
    'viewValue': 'CASTELVETERE IN VAL FORTORE (BN)'
  },
  {
    'value': 'C283',
    'viewValue': 'CASTELVETERE SUL CALORE (AV)'
  },
  {
    'value': 'C286',
    'viewValue': 'CASTELVETRANO (TP)'
  },
  {
    'value': 'C287',
    'viewValue': 'CASTELVETRO DI MODENA (MO)'
  },
  {
    'value': 'C288',
    'viewValue': 'CASTELVETRO PIACENTINO (PC)'
  },
  {
    'value': 'C290',
    'viewValue': 'CASTELVISCONTI (CR)'
  },
  {
    'value': 'C292',
    'viewValue': 'CASTENASO (BO)'
  },
  {
    'value': 'C293',
    'viewValue': 'CASTENEDOLO (BS)'
  },
  {
    'value': 'M288',
    'viewValue': 'CASTIADAS (CA)'
  },
  {
    'value': 'C318',
    'viewValue': 'CASTIGLION FIBOCCHI (AR)'
  },
  {
    'value': 'C319',
    'viewValue': 'CASTIGLION FIORENTINO (AR)'
  },
  {
    'value': 'C308',
    'viewValue': 'CASTIGLIONE A CASAURIA (PE)'
  },
  {
    'value': 'C302',
    'viewValue': 'CASTIGLIONE CHIAVARESE (GE)'
  },
  {
    'value': 'C301',
    'viewValue': 'CASTIGLIONE COSENTINO (CS)'
  },
  {
    'value': 'C304',
    'viewValue': "CASTIGLIONE D'ADDA (LO)"
  },
  {
    'value': 'C296',
    'viewValue': 'CASTIGLIONE DEI PEPOLI (BO)'
  },
  {
    'value': 'C306',
    'viewValue': 'CASTIGLIONE DEL GENOVESI (SA)'
  },
  {
    'value': 'C309',
    'viewValue': 'CASTIGLIONE DEL LAGO (PG)'
  },
  {
    'value': 'C310',
    'viewValue': 'CASTIGLIONE DELLA PESCAIA (GR)'
  },
  {
    'value': 'C312',
    'viewValue': 'CASTIGLIONE DELLE STIVIERE (MN)'
  },
  {
    'value': 'C303',
    'viewValue': 'CASTIGLIONE DI GARFAGNANA (LU)'
  },
  {
    'value': 'C297',
    'viewValue': 'CASTIGLIONE DI SICILIA (CT)'
  },
  {
    'value': 'C299',
    'viewValue': "CASTIGLIONE D'INTELVI (CO)"
  },
  {
    'value': 'C313',
    'viewValue': "CASTIGLIONE D'ORCIA (SI)"
  },
  {
    'value': 'C314',
    'viewValue': 'CASTIGLIONE FALLETTO (CN)'
  },
  {
    'value': 'C315',
    'viewValue': 'CASTIGLIONE IN TEVERINA (VT)'
  },
  {
    'value': 'C298',
    'viewValue': 'CASTIGLIONE MESSER MARINO (CH)'
  },
  {
    'value': 'C316',
    'viewValue': 'CASTIGLIONE MESSER RAIMONDO (TE)'
  },
  {
    'value': 'C300',
    'viewValue': 'CASTIGLIONE OLONA (VA)'
  },
  {
    'value': 'C317',
    'viewValue': 'CASTIGLIONE TINELLA (CN)'
  },
  {
    'value': 'C307',
    'viewValue': 'CASTIGLIONE TORINESE (TO)'
  },
  {
    'value': 'C321',
    'viewValue': 'CASTIGNANO (AP)'
  },
  {
    'value': 'C322',
    'viewValue': 'CASTILENTI (TE)'
  },
  {
    'value': 'C323',
    'viewValue': 'CASTINO (CN)'
  },
  {
    'value': 'C325',
    'viewValue': 'CASTIONE ANDEVENNO (SO)'
  },
  {
    'value': 'C324',
    'viewValue': 'CASTIONE DELLA PRESOLANA (BG)'
  },
  {
    'value': 'C327',
    'viewValue': 'CASTIONS DI STRADA (UD)'
  },
  {
    'value': 'C329',
    'viewValue': 'CASTIRAGA VIDARDO (LO)'
  },
  {
    'value': 'C330',
    'viewValue': 'CASTO (BS)'
  },
  {
    'value': 'C331',
    'viewValue': 'CASTORANO (AP)'
  },
  {
    'value': 'C332',
    'viewValue': 'CASTREZZATO (BS)'
  },
  {
    'value': 'C334',
    'viewValue': 'CASTRI DI LECCE (LE)'
  },
  {
    'value': 'C335',
    'viewValue': "CASTRIGNANO DE' GRECI (LE)"
  },
  {
    'value': 'C336',
    'viewValue': 'CASTRIGNANO DEL CAPO (LE)'
  },
  {
    'value': 'C337',
    'viewValue': 'CASTRO (BG)'
  },
  {
    'value': 'M261',
    'viewValue': 'CASTRO (LE)'
  },
  {
    'value': 'C338',
    'viewValue': 'CASTRO DEI VOLSCI (FR)'
  },
  {
    'value': 'C339',
    'viewValue': 'CASTROCARO TERME E TERRA DEL SOLE (FC)'
  },
  {
    'value': 'C340',
    'viewValue': 'CASTROCIELO (FR)'
  },
  {
    'value': 'C341',
    'viewValue': 'CASTROFILIPPO (AG)'
  },
  {
    'value': 'C108',
    'viewValue': 'CASTROLIBERO (CS)'
  },
  {
    'value': 'C343',
    'viewValue': 'CASTRONNO (VA)'
  },
  {
    'value': 'C344',
    'viewValue': 'CASTRONOVO DI SICILIA (PA)'
  },
  {
    'value': 'C345',
    'viewValue': "CASTRONUOVO DI SANT'ANDREA (PZ)"
  },
  {
    'value': 'C346',
    'viewValue': 'CASTROPIGNANO (CB)'
  },
  {
    'value': 'C347',
    'viewValue': 'CASTROREALE (ME)'
  },
  {
    'value': 'C348',
    'viewValue': 'CASTROREGIO (CS)'
  },
  {
    'value': 'C349',
    'viewValue': 'CASTROVILLARI (CS)'
  },
  {
    'value': 'C351',
    'viewValue': 'CATANIA (CT)'
  },
  {
    'value': 'C352',
    'viewValue': 'CATANZARO (CZ)'
  },
  {
    'value': 'C353',
    'viewValue': 'CATENANUOVA (EN)'
  },
  {
    'value': 'C354',
    'viewValue': 'CATIGNANO (PE)'
  },
  {
    'value': 'C357',
    'viewValue': 'CATTOLICA (RN)'
  },
  {
    'value': 'C356',
    'viewValue': 'CATTOLICA ERACLEA (AG)'
  },
  {
    'value': 'C285',
    'viewValue': 'CAULONIA (RC)'
  },
  {
    'value': 'C359',
    'viewValue': 'CAUTANO (BN)'
  },
  {
    'value': 'C361',
    'viewValue': "CAVA DE' TIRRENI (SA)"
  },
  {
    'value': 'C360',
    'viewValue': 'CAVA MANARA (PV)'
  },
  {
    'value': 'C362',
    'viewValue': 'CAVACURTA (LO)'
  },
  {
    'value': 'C363',
    'viewValue': "CAVAGLIA' (BI)"
  },
  {
    'value': 'C364',
    'viewValue': 'CAVAGLIETTO (NO)'
  },
  {
    'value': 'C365',
    'viewValue': "CAVAGLIO D'AGOGNA (NO)"
  },
  {
    'value': 'C367',
    'viewValue': 'CAVAGLIO-SPOCCIA (VB)'
  },
  {
    'value': 'C369',
    'viewValue': 'CAVAGNOLO (TO)'
  },
  {
    'value': 'C370',
    'viewValue': 'CAVAION VERONESE (VR)'
  },
  {
    'value': 'C372',
    'viewValue': 'CAVALESE (TN)'
  },
  {
    'value': 'C374',
    'viewValue': 'CAVALLASCA (CO)'
  },
  {
    'value': 'C375',
    'viewValue': 'CAVALLERLEONE (CN)'
  },
  {
    'value': 'C376',
    'viewValue': 'CAVALLERMAGGIORE (CN)'
  },
  {
    'value': 'C377',
    'viewValue': 'CAVALLINO (LE)'
  },
  {
    'value': 'M308',
    'viewValue': 'CAVALLINO-TREPORTI (VE)'
  },
  {
    'value': 'C378',
    'viewValue': 'CAVALLIRIO (NO)'
  },
  {
    'value': 'C380',
    'viewValue': 'CAVARENO (TN)'
  },
  {
    'value': 'C381',
    'viewValue': 'CAVARGNA (CO)'
  },
  {
    'value': 'C382',
    'viewValue': 'CAVARIA CON PREMEZZO (VA)'
  },
  {
    'value': 'C383',
    'viewValue': 'CAVARZERE (VE)'
  },
  {
    'value': 'C384',
    'viewValue': 'CAVASO DEL TOMBA (TV)'
  },
  {
    'value': 'C385',
    'viewValue': 'CAVASSO NUOVO (PN)'
  },
  {
    'value': 'C387',
    'viewValue': 'CAVATORE (AL)'
  },
  {
    'value': 'C389',
    'viewValue': 'CAVAZZO CARNICO (UD)'
  },
  {
    'value': 'C390',
    'viewValue': 'CAVE (RM)'
  },
  {
    'value': 'C392',
    'viewValue': 'CAVEDAGO (TN)'
  },
  {
    'value': 'C393',
    'viewValue': 'CAVEDINE (TN)'
  },
  {
    'value': 'C394',
    'viewValue': "CAVENAGO D'ADDA (LO)"
  },
  {
    'value': 'C395',
    'viewValue': 'CAVENAGO DI BRIANZA (MI)'
  },
  {
    'value': 'C396',
    'viewValue': 'CAVERNAGO (BG)'
  },
  {
    'value': 'C398',
    'viewValue': 'CAVEZZO (MO)'
  },
  {
    'value': 'C400',
    'viewValue': 'CAVIZZANA (TN)'
  },
  {
    'value': 'C404',
    'viewValue': 'CAVOUR (TO)'
  },
  {
    'value': 'C405',
    'viewValue': 'CAVRIAGO (RE)'
  },
  {
    'value': 'C406',
    'viewValue': 'CAVRIANA (MN)'
  },
  {
    'value': 'C407',
    'viewValue': 'CAVRIGLIA (AR)'
  },
  {
    'value': 'C409',
    'viewValue': 'CAZZAGO BRABBIA (VA)'
  },
  {
    'value': 'C408',
    'viewValue': 'CAZZAGO SAN MARTINO (BS)'
  },
  {
    'value': 'C412',
    'viewValue': 'CAZZANO DI TRAMIGNA (VR)'
  },
  {
    'value': 'C410',
    'viewValue': "CAZZANO SANT'ANDREA (BG)"
  },
  {
    'value': 'C413',
    'viewValue': 'CECCANO (FR)'
  },
  {
    'value': 'C414',
    'viewValue': 'CECIMA (PV)'
  },
  {
    'value': 'C415',
    'viewValue': 'CECINA (LI)'
  },
  {
    'value': 'C417',
    'viewValue': 'CEDEGOLO (BS)'
  },
  {
    'value': 'C418',
    'viewValue': 'CEDRASCO (SO)'
  },
  {
    'value': 'C420',
    'viewValue': "CEFALA' DIANA (PA)"
  },
  {
    'value': 'C421',
    'viewValue': "CEFALU' (PA)"
  },
  {
    'value': 'C422',
    'viewValue': 'CEGGIA (VE)'
  },
  {
    'value': 'C424',
    'viewValue': 'CEGLIE MESSAPICO (BR)'
  },
  {
    'value': 'C426',
    'viewValue': 'CELANO (AQ)'
  },
  {
    'value': 'C428',
    'viewValue': 'CELENZA SUL TRIGNO (CH)'
  },
  {
    'value': 'C429',
    'viewValue': 'CELENZA VALFORTORE (FG)'
  },
  {
    'value': 'C430',
    'viewValue': 'CELICO (CS)'
  },
  {
    'value': 'C435',
    'viewValue': 'CELLA DATI (CR)'
  },
  {
    'value': 'C432',
    'viewValue': 'CELLA MONTE (AL)'
  },
  {
    'value': 'C436',
    'viewValue': 'CELLAMARE (BA)'
  },
  {
    'value': 'C437',
    'viewValue': 'CELLARA (CS)'
  },
  {
    'value': 'C438',
    'viewValue': 'CELLARENGO (AT)'
  },
  {
    'value': 'C439',
    'viewValue': 'CELLATICA (BS)'
  },
  {
    'value': 'C444',
    'viewValue': 'CELLE DI BULGHERIA (SA)'
  },
  {
    'value': 'C441',
    'viewValue': 'CELLE DI MACRA (CN)'
  },
  {
    'value': 'C440',
    'viewValue': 'CELLE ENOMONDO (AT)'
  },
  {
    'value': 'C443',
    'viewValue': 'CELLE LIGURE (SV)'
  },
  {
    'value': 'C442',
    'viewValue': 'CELLE SAN VITO (FG)'
  },
  {
    'value': 'C446',
    'viewValue': 'CELLENO (VT)'
  },
  {
    'value': 'C447',
    'viewValue': 'CELLERE (VT)'
  },
  {
    'value': 'C449',
    'viewValue': 'CELLINO ATTANASIO (TE)'
  },
  {
    'value': 'C448',
    'viewValue': 'CELLINO SAN MARCO (BR)'
  },
  {
    'value': 'C450',
    'viewValue': 'CELLIO (VC)'
  },
  {
    'value': 'M262',
    'viewValue': 'CELLOLE (CE)'
  },
  {
    'value': 'C452',
    'viewValue': 'CEMBRA (TN)'
  },
  {
    'value': 'C453',
    'viewValue': 'CENADI (CZ)'
  },
  {
    'value': 'C456',
    'viewValue': 'CENATE SOPRA (BG)'
  },
  {
    'value': 'C457',
    'viewValue': 'CENATE SOTTO (BG)'
  },
  {
    'value': 'C458',
    'viewValue': 'CENCENIGHE AGORDINO (BL)'
  },
  {
    'value': 'C459',
    'viewValue': 'CENE (BG)'
  },
  {
    'value': 'C461',
    'viewValue': 'CENESELLI (RO)'
  },
  {
    'value': 'C463',
    'viewValue': 'CENGIO (SV)'
  },
  {
    'value': 'C467',
    'viewValue': "CENTA SAN NICOLO' (TN)"
  },
  {
    'value': 'C466',
    'viewValue': 'CENTALLO (CN)'
  },
  {
    'value': 'C469',
    'viewValue': 'CENTO (FE)'
  },
  {
    'value': 'C470',
    'viewValue': 'CENTOLA (SA)'
  },
  {
    'value': 'C472',
    'viewValue': 'CENTRACHE (CZ)'
  },
  {
    'value': 'C471',
    'viewValue': 'CENTURIPE (EN)'
  },
  {
    'value': 'C474',
    'viewValue': 'CEPAGATTI (PE)'
  },
  {
    'value': 'C476',
    'viewValue': 'CEPPALONI (BN)'
  },
  {
    'value': 'C478',
    'viewValue': 'CEPPO MORELLI (VB)'
  },
  {
    'value': 'C479',
    'viewValue': 'CEPRANO (FR)'
  },
  {
    'value': 'C480',
    'viewValue': 'CERAMI (EN)'
  },
  {
    'value': 'C481',
    'viewValue': 'CERANESI (GE)'
  },
  {
    'value': 'C483',
    'viewValue': 'CERANO (NO)'
  },
  {
    'value': 'C482',
    'viewValue': "CERANO D'INTELVI (CO)"
  },
  {
    'value': 'C484',
    'viewValue': 'CERANOVA (PV)'
  },
  {
    'value': 'C485',
    'viewValue': 'CERASO (SA)'
  },
  {
    'value': 'C486',
    'viewValue': 'CERCEMAGGIORE (CB)'
  },
  {
    'value': 'C487',
    'viewValue': 'CERCENASCO (TO)'
  },
  {
    'value': 'C488',
    'viewValue': 'CERCEPICCOLA (CB)'
  },
  {
    'value': 'C489',
    'viewValue': 'CERCHIARA DI CALABRIA (CS)'
  },
  {
    'value': 'C492',
    'viewValue': 'CERCHIO (AQ)'
  },
  {
    'value': 'C493',
    'viewValue': 'CERCINO (SO)'
  },
  {
    'value': 'C494',
    'viewValue': 'CERCIVENTO (UD)'
  },
  {
    'value': 'C495',
    'viewValue': 'CERCOLA (NA)'
  },
  {
    'value': 'C496',
    'viewValue': 'CERDA (PA)'
  },
  {
    'value': 'C498',
    'viewValue': 'CEREA (VR)'
  },
  {
    'value': 'C500',
    'viewValue': 'CEREGNANO (RO)'
  },
  {
    'value': 'C501',
    'viewValue': 'CERENZIA (KR)'
  },
  {
    'value': 'C497',
    'viewValue': 'CERES (TO)'
  },
  {
    'value': 'C502',
    'viewValue': 'CERESARA (MN)'
  },
  {
    'value': 'C503',
    'viewValue': 'CERESETO (AL)'
  },
  {
    'value': 'C504',
    'viewValue': 'CERESOLE ALBA (CN)'
  },
  {
    'value': 'C505',
    'viewValue': 'CERESOLE REALE (TO)'
  },
  {
    'value': 'C506',
    'viewValue': 'CERETE (BG)'
  },
  {
    'value': 'C508',
    'viewValue': 'CERETTO LOMELLINA (PV)'
  },
  {
    'value': 'C509',
    'viewValue': 'CERGNAGO (PV)'
  },
  {
    'value': 'C510',
    'viewValue': 'CERIALE (SV)'
  },
  {
    'value': 'C511',
    'viewValue': 'CERIANA (IM)'
  },
  {
    'value': 'C512',
    'viewValue': 'CERIANO LAGHETTO (MI)'
  },
  {
    'value': 'C513',
    'viewValue': 'CERIGNALE (PC)'
  },
  {
    'value': 'C514',
    'viewValue': 'CERIGNOLA (FG)'
  },
  {
    'value': 'C515',
    'viewValue': 'CERISANO (CS)'
  },
  {
    'value': 'C516',
    'viewValue': 'CERMENATE (CO)'
  },
  {
    'value': 'A022',
    'viewValue': 'CERMES .TSCHERMS. (BZ)'
  },
  {
    'value': 'C517',
    'viewValue': 'CERMIGNANO (TE)'
  },
  {
    'value': 'C520',
    'viewValue': 'CERNOBBIO (CO)'
  },
  {
    'value': 'C521',
    'viewValue': 'CERNUSCO LOMBARDONE (LC)'
  },
  {
    'value': 'C523',
    'viewValue': 'CERNUSCO SUL NAVIGLIO (MI)'
  },
  {
    'value': 'C526',
    'viewValue': 'CERRETO CASTELLO (BI)'
  },
  {
    'value': 'C528',
    'viewValue': "CERRETO D'ASTI (AT)"
  },
  {
    'value': 'C524',
    'viewValue': "CERRETO D'ESI (AN)"
  },
  {
    'value': 'C527',
    'viewValue': 'CERRETO DI SPOLETO (PG)'
  },
  {
    'value': 'C507',
    'viewValue': 'CERRETO GRUE (AL)'
  },
  {
    'value': 'C529',
    'viewValue': 'CERRETO GUIDI (FI)'
  },
  {
    'value': 'C518',
    'viewValue': 'CERRETO LAZIALE (RM)'
  },
  {
    'value': 'C525',
    'viewValue': 'CERRETO SANNITA (BN)'
  },
  {
    'value': 'C530',
    'viewValue': 'CERRETTO DELLE LANGHE (CN)'
  },
  {
    'value': 'C531',
    'viewValue': 'CERRINA (AL)'
  },
  {
    'value': 'C532',
    'viewValue': 'CERRIONE (BI)'
  },
  {
    'value': 'C536',
    'viewValue': 'CERRO AL LAMBRO (MI)'
  },
  {
    'value': 'C534',
    'viewValue': 'CERRO AL VOLTURNO (IS)'
  },
  {
    'value': 'C537',
    'viewValue': 'CERRO MAGGIORE (MI)'
  },
  {
    'value': 'C533',
    'viewValue': 'CERRO TANARO (AT)'
  },
  {
    'value': 'C538',
    'viewValue': 'CERRO VERONESE (VR)'
  },
  {
    'value': 'C539',
    'viewValue': 'CERSOSIMO (PZ)'
  },
  {
    'value': 'C540',
    'viewValue': 'CERTALDO (FI)'
  },
  {
    'value': 'C541',
    'viewValue': 'CERTOSA DI PAVIA (PV)'
  },
  {
    'value': 'C542',
    'viewValue': 'CERVA (CZ)'
  },
  {
    'value': 'C543',
    'viewValue': 'CERVARA DI ROMA (RM)'
  },
  {
    'value': 'C544',
    'viewValue': 'CERVARESE SANTA CROCE (PD)'
  },
  {
    'value': 'C545',
    'viewValue': 'CERVARO (FR)'
  },
  {
    'value': 'C547',
    'viewValue': 'CERVASCA (CN)'
  },
  {
    'value': 'C548',
    'viewValue': 'CERVATTO (VC)'
  },
  {
    'value': 'C549',
    'viewValue': 'CERVENO (BS)'
  },
  {
    'value': 'C550',
    'viewValue': 'CERVERE (CN)'
  },
  {
    'value': 'C551',
    'viewValue': 'CERVESINA (PV)'
  },
  {
    'value': 'C552',
    'viewValue': 'CERVETERI (RM)'
  },
  {
    'value': 'C553',
    'viewValue': 'CERVIA (RA)'
  },
  {
    'value': 'C554',
    'viewValue': 'CERVICATI (CS)'
  },
  {
    'value': 'C555',
    'viewValue': "CERVIGNANO D'ADDA (LO)"
  },
  {
    'value': 'C556',
    'viewValue': 'CERVIGNANO DEL FRIULI (UD)'
  },
  {
    'value': 'C557',
    'viewValue': 'CERVINARA (AV)'
  },
  {
    'value': 'C558',
    'viewValue': 'CERVINO (CE)'
  },
  {
    'value': 'C559',
    'viewValue': 'CERVO (IM)'
  },
  {
    'value': 'C560',
    'viewValue': 'CERZETO (CS)'
  },
  {
    'value': 'C561',
    'viewValue': 'CESA (CE)'
  },
  {
    'value': 'C563',
    'viewValue': 'CESANA BRIANZA (LC)'
  },
  {
    'value': 'C564',
    'viewValue': 'CESANA TORINESE (TO)'
  },
  {
    'value': 'C565',
    'viewValue': 'CESANO BOSCONE (MI)'
  },
  {
    'value': 'C566',
    'viewValue': 'CESANO MADERNO (MI)'
  },
  {
    'value': 'C567',
    'viewValue': 'CESARA (VB)'
  },
  {
    'value': 'C568',
    'viewValue': "CESARO' (ME)"
  },
  {
    'value': 'C569',
    'viewValue': 'CESATE (MI)'
  },
  {
    'value': 'C573',
    'viewValue': 'CESENA (FC)'
  },
  {
    'value': 'C574',
    'viewValue': 'CESENATICO (FC)'
  },
  {
    'value': 'C576',
    'viewValue': 'CESINALI (AV)'
  },
  {
    'value': 'C578',
    'viewValue': 'CESIO (IM)'
  },
  {
    'value': 'C577',
    'viewValue': 'CESIOMAGGIORE (BL)'
  },
  {
    'value': 'C580',
    'viewValue': 'CESSALTO (TV)'
  },
  {
    'value': 'C581',
    'viewValue': 'CESSANITI (VV)'
  },
  {
    'value': 'C582',
    'viewValue': 'CESSAPALOMBO (MC)'
  },
  {
    'value': 'C583',
    'viewValue': 'CESSOLE (AT)'
  },
  {
    'value': 'C584',
    'viewValue': 'CETARA (SA)'
  },
  {
    'value': 'C585',
    'viewValue': 'CETO (BS)'
  },
  {
    'value': 'C587',
    'viewValue': 'CETONA (SI)'
  },
  {
    'value': 'C588',
    'viewValue': 'CETRARO (CS)'
  },
  {
    'value': 'C589',
    'viewValue': 'CEVA (CN)'
  },
  {
    'value': 'C591',
    'viewValue': 'CEVO (BS)'
  },
  {
    'value': 'C593',
    'viewValue': 'CHALLAND-SAINT-ANSELME (AO)'
  },
  {
    'value': 'C594',
    'viewValue': 'CHALLAND-SAINT-VICTOR (AO)'
  },
  {
    'value': 'C595',
    'viewValue': 'CHAMBAVE (AO)'
  },
  {
    'value': 'B491',
    'viewValue': 'CHAMOIS (AO)'
  },
  {
    'value': 'C596',
    'viewValue': 'CHAMPDEPRAZ (AO)'
  },
  {
    'value': 'B540',
    'viewValue': 'CHAMPORCHER (AO)'
  },
  {
    'value': 'C598',
    'viewValue': 'CHARVENSOD (AO)'
  },
  {
    'value': 'C294',
    'viewValue': 'CHATILLON (AO)'
  },
  {
    'value': 'C599',
    'viewValue': 'CHERASCO (CN)'
  },
  {
    'value': 'C600',
    'viewValue': 'CHEREMULE (SS)'
  },
  {
    'value': 'C603',
    'viewValue': 'CHIAIANO ED UNITI (NA)'
  },
  {
    'value': 'C604',
    'viewValue': 'CHIALAMBERTO (TO)'
  },
  {
    'value': 'C605',
    'viewValue': 'CHIAMPO (VI)'
  },
  {
    'value': 'C606',
    'viewValue': 'CHIANCHE (AV)'
  },
  {
    'value': 'C608',
    'viewValue': 'CHIANCIANO TERME (SI)'
  },
  {
    'value': 'C609',
    'viewValue': 'CHIANNI (PI)'
  },
  {
    'value': 'C610',
    'viewValue': 'CHIANOCCO (TO)'
  },
  {
    'value': 'C612',
    'viewValue': 'CHIARAMONTE GULFI (RG)'
  },
  {
    'value': 'C613',
    'viewValue': 'CHIARAMONTI (SS)'
  },
  {
    'value': 'C614',
    'viewValue': 'CHIARANO (TV)'
  },
  {
    'value': 'C615',
    'viewValue': 'CHIARAVALLE (AN)'
  },
  {
    'value': 'C616',
    'viewValue': 'CHIARAVALLE CENTRALE (CZ)'
  },
  {
    'value': 'C618',
    'viewValue': 'CHIARI (BS)'
  },
  {
    'value': 'C619',
    'viewValue': 'CHIAROMONTE (PZ)'
  },
  {
    'value': 'C620',
    'viewValue': 'CHIAUCI (IS)'
  },
  {
    'value': 'C621',
    'viewValue': 'CHIAVARI (GE)'
  },
  {
    'value': 'C623',
    'viewValue': 'CHIAVENNA (SO)'
  },
  {
    'value': 'C624',
    'viewValue': 'CHIAVERANO (TO)'
  },
  {
    'value': 'C625',
    'viewValue': 'CHIENES .KIENS. (BZ)'
  },
  {
    'value': 'C627',
    'viewValue': 'CHIERI (TO)'
  },
  {
    'value': 'C630',
    'viewValue': "CHIES D'ALPAGO (BL)"
  },
  {
    'value': 'C628',
    'viewValue': 'CHIESA IN VALMALENCO (SO)'
  },
  {
    'value': 'C629',
    'viewValue': 'CHIESANUOVA (TO)'
  },
  {
    'value': 'C631',
    'viewValue': 'CHIESINA UZZANESE (PT)'
  },
  {
    'value': 'C632',
    'viewValue': 'CHIETI (CH)'
  },
  {
    'value': 'C633',
    'viewValue': 'CHIEUTI (FG)'
  },
  {
    'value': 'C634',
    'viewValue': 'CHIEVE (CR)'
  },
  {
    'value': 'C635',
    'viewValue': "CHIGNOLO D'ISOLA (BG)"
  },
  {
    'value': 'C637',
    'viewValue': 'CHIGNOLO PO (PV)'
  },
  {
    'value': 'C638',
    'viewValue': 'CHIOGGIA (VE)'
  },
  {
    'value': 'C639',
    'viewValue': 'CHIOMONTE (TO)'
  },
  {
    'value': 'C640',
    'viewValue': 'CHIONS (PN)'
  },
  {
    'value': 'C641',
    'viewValue': 'CHIOPRIS VISCONE (UD)'
  },
  {
    'value': 'C648',
    'viewValue': 'CHITIGNANO (AR)'
  },
  {
    'value': 'C649',
    'viewValue': 'CHIUDUNO (BG)'
  },
  {
    'value': 'C650',
    'viewValue': 'CHIUPPANO (VI)'
  },
  {
    'value': 'C651',
    'viewValue': 'CHIURO (SO)'
  },
  {
    'value': 'C652',
    'viewValue': 'CHIUSA .KLAUSEN. (BZ)'
  },
  {
    'value': 'C653',
    'viewValue': 'CHIUSA DI PESIO (CN)'
  },
  {
    'value': 'C655',
    'viewValue': 'CHIUSA DI SAN MICHELE (TO)'
  },
  {
    'value': 'C654',
    'viewValue': 'CHIUSA SCLAFANI (PA)'
  },
  {
    'value': 'C656',
    'viewValue': 'CHIUSAFORTE (UD)'
  },
  {
    'value': 'C657',
    'viewValue': 'CHIUSANICO (IM)'
  },
  {
    'value': 'C658',
    'viewValue': "CHIUSANO D'ASTI (AT)"
  },
  {
    'value': 'C659',
    'viewValue': 'CHIUSANO DI SAN DOMENICO (AV)'
  },
  {
    'value': 'C660',
    'viewValue': 'CHIUSAVECCHIA (IM)'
  },
  {
    'value': 'C661',
    'viewValue': 'CHIUSDINO (SI)'
  },
  {
    'value': 'C662',
    'viewValue': 'CHIUSI (SI)'
  },
  {
    'value': 'C663',
    'viewValue': 'CHIUSI DELLA VERNA (AR)'
  },
  {
    'value': 'C665',
    'viewValue': 'CHIVASSO (TO)'
  },
  {
    'value': 'M272',
    'viewValue': 'CIAMPINO (RM)'
  },
  {
    'value': 'C668',
    'viewValue': 'CIANCIANA (AG)'
  },
  {
    'value': 'C672',
    'viewValue': 'CIBIANA DI CADORE (BL)'
  },
  {
    'value': 'C673',
    'viewValue': 'CICAGNA (GE)'
  },
  {
    'value': 'C674',
    'viewValue': 'CICALA (CZ)'
  },
  {
    'value': 'C675',
    'viewValue': 'CICCIANO (NA)'
  },
  {
    'value': 'C676',
    'viewValue': 'CICERALE (SA)'
  },
  {
    'value': 'C677',
    'viewValue': 'CICILIANO (RM)'
  },
  {
    'value': 'C678',
    'viewValue': 'CICOGNOLO (CR)'
  },
  {
    'value': 'C679',
    'viewValue': 'CICONIO (TO)'
  },
  {
    'value': 'C680',
    'viewValue': 'CIGLIANO (VC)'
  },
  {
    'value': 'C681',
    'viewValue': "CIGLIE' (CN)"
  },
  {
    'value': 'C684',
    'viewValue': 'CIGOGNOLA (PV)'
  },
  {
    'value': 'C685',
    'viewValue': 'CIGOLE (BS)'
  },
  {
    'value': 'C686',
    'viewValue': 'CILAVEGNA (PV)'
  },
  {
    'value': 'C689',
    'viewValue': 'CIMADOLMO (TV)'
  },
  {
    'value': 'C691',
    'viewValue': 'CIMBERGO (BS)'
  },
  {
    'value': 'C694',
    'viewValue': 'CIMEGO (TN)'
  },
  {
    'value': 'C695',
    'viewValue': "CIMINA' (RC)"
  },
  {
    'value': 'C696',
    'viewValue': 'CIMINNA (PA)'
  },
  {
    'value': 'C697',
    'viewValue': 'CIMITILE (NA)'
  },
  {
    'value': 'C699',
    'viewValue': 'CIMOLAIS (PN)'
  },
  {
    'value': 'C700',
    'viewValue': 'CIMONE (TN)'
  },
  {
    'value': 'C701',
    'viewValue': 'CINAGLIO (AT)'
  },
  {
    'value': 'C702',
    'viewValue': 'CINETO ROMANO (RM)'
  },
  {
    'value': 'C703',
    'viewValue': "CINGIA DE' BOTTI (CR)"
  },
  {
    'value': 'C704',
    'viewValue': 'CINGOLI (MC)'
  },
  {
    'value': 'C705',
    'viewValue': 'CINIGIANO (GR)'
  },
  {
    'value': 'C707',
    'viewValue': 'CINISELLO BALSAMO (MI)'
  },
  {
    'value': 'C708',
    'viewValue': 'CINISI (PA)'
  },
  {
    'value': 'C709',
    'viewValue': 'CINO (SO)'
  },
  {
    'value': 'C710',
    'viewValue': 'CINQUEFRONDI (RC)'
  },
  {
    'value': 'C711',
    'viewValue': 'CINTANO (TO)'
  },
  {
    'value': 'C712',
    'viewValue': 'CINTE TESINO (TN)'
  },
  {
    'value': 'C714',
    'viewValue': 'CINTO CAOMAGGIORE (VE)'
  },
  {
    'value': 'C713',
    'viewValue': 'CINTO EUGANEO (PD)'
  },
  {
    'value': 'C715',
    'viewValue': 'CINZANO (TO)'
  },
  {
    'value': 'C716',
    'viewValue': 'CIORLANO (CE)'
  },
  {
    'value': 'C718',
    'viewValue': 'CIPRESSA (IM)'
  },
  {
    'value': 'C719',
    'viewValue': 'CIRCELLO (BN)'
  },
  {
    'value': 'C722',
    'viewValue': "CIRIE' (TO)"
  },
  {
    'value': 'C723',
    'viewValue': 'CIRIGLIANO (MT)'
  },
  {
    'value': 'C724',
    'viewValue': 'CIRIMIDO (CO)'
  },
  {
    'value': 'C725',
    'viewValue': "CIRO' (KR)"
  },
  {
    'value': 'C726',
    'viewValue': "CIRO' MARINA (KR)"
  },
  {
    'value': 'C727',
    'viewValue': 'CIS (TN)'
  },
  {
    'value': 'C728',
    'viewValue': 'CISANO BERGAMASCO (BG)'
  },
  {
    'value': 'C729',
    'viewValue': 'CISANO SUL NEVA (SV)'
  },
  {
    'value': 'C730',
    'viewValue': 'CISERANO (BG)'
  },
  {
    'value': 'C732',
    'viewValue': 'CISLAGO (VA)'
  },
  {
    'value': 'C733',
    'viewValue': 'CISLIANO (MI)'
  },
  {
    'value': 'C734',
    'viewValue': 'CISMON DEL GRAPPA (VI)'
  },
  {
    'value': 'C735',
    'viewValue': 'CISON DI VALMARINO (TV)'
  },
  {
    'value': 'C738',
    'viewValue': 'CISSONE (CN)'
  },
  {
    'value': 'C739',
    'viewValue': "CISTERNA D'ASTI (AT)"
  },
  {
    'value': 'C740',
    'viewValue': 'CISTERNA DI LATINA (LT)'
  },
  {
    'value': 'C741',
    'viewValue': 'CISTERNINO (BR)'
  },
  {
    'value': 'C742',
    'viewValue': 'CITERNA (PG)'
  },
  {
    'value': 'C744',
    'viewValue': "CITTA' DELLA PIEVE (PG)"
  },
  {
    'value': 'C745',
    'viewValue': "CITTA' DI CASTELLO (PG)"
  },
  {
    'value': 'C750',
    'viewValue': "CITTA' SANT'ANGELO (PE)"
  },
  {
    'value': 'C743',
    'viewValue': 'CITTADELLA (PD)'
  },
  {
    'value': 'C746',
    'viewValue': 'CITTADUCALE (RI)'
  },
  {
    'value': 'C747',
    'viewValue': 'CITTANOVA (RC)'
  },
  {
    'value': 'C749',
    'viewValue': 'CITTAREALE (RI)'
  },
  {
    'value': 'C751',
    'viewValue': 'CITTIGLIO (VA)'
  },
  {
    'value': 'C752',
    'viewValue': 'CIVATE (LC)'
  },
  {
    'value': 'C754',
    'viewValue': 'CIVENNA (CO)'
  },
  {
    'value': 'C755',
    'viewValue': 'CIVEZZA (IM)'
  },
  {
    'value': 'C756',
    'viewValue': 'CIVEZZANO (TN)'
  },
  {
    'value': 'C757',
    'viewValue': 'CIVIASCO (VC)'
  },
  {
    'value': 'C758',
    'viewValue': 'CIVIDALE DEL FRIULI (UD)'
  },
  {
    'value': 'C759',
    'viewValue': 'CIVIDATE AL PIANO (BG)'
  },
  {
    'value': 'C760',
    'viewValue': 'CIVIDATE CAMUNO (BS)'
  },
  {
    'value': 'C763',
    'viewValue': 'CIVITA (CS)'
  },
  {
    'value': 'C765',
    'viewValue': 'CIVITA CASTELLANA (VT)'
  },
  {
    'value': 'C766',
    'viewValue': "CIVITA D'ANTINO (AQ)"
  },
  {
    'value': 'C764',
    'viewValue': 'CIVITACAMPOMARANO (CB)'
  },
  {
    'value': 'C768',
    'viewValue': 'CIVITALUPARELLA (CH)'
  },
  {
    'value': 'C769',
    'viewValue': 'CIVITANOVA DEL SANNIO (IS)'
  },
  {
    'value': 'C770',
    'viewValue': 'CIVITANOVA MARCHE (MC)'
  },
  {
    'value': 'C771',
    'viewValue': 'CIVITAQUANA (PE)'
  },
  {
    'value': 'C773',
    'viewValue': 'CIVITAVECCHIA (RM)'
  },
  {
    'value': 'C778',
    'viewValue': 'CIVITELLA ALFEDENA (AQ)'
  },
  {
    'value': 'C779',
    'viewValue': 'CIVITELLA CASANOVA (PE)'
  },
  {
    'value': 'C780',
    'viewValue': "CIVITELLA D'AGLIANO (VT)"
  },
  {
    'value': 'C781',
    'viewValue': 'CIVITELLA DEL TRONTO (TE)'
  },
  {
    'value': 'C777',
    'viewValue': 'CIVITELLA DI ROMAGNA (FC)'
  },
  {
    'value': 'C774',
    'viewValue': 'CIVITELLA IN VAL DI CHIANA (AR)'
  },
  {
    'value': 'C776',
    'viewValue': 'CIVITELLA MESSER RAIMONDO (CH)'
  },
  {
    'value': 'C782',
    'viewValue': 'CIVITELLA PAGANICO (GR)'
  },
  {
    'value': 'C783',
    'viewValue': 'CIVITELLA ROVETO (AQ)'
  },
  {
    'value': 'C784',
    'viewValue': 'CIVITELLA SAN PAOLO (RM)'
  },
  {
    'value': 'C785',
    'viewValue': 'CIVO (SO)'
  },
  {
    'value': 'C787',
    'viewValue': 'CLAINO CON OSTENO (CO)'
  },
  {
    'value': 'C790',
    'viewValue': 'CLAUT (PN)'
  },
  {
    'value': 'C791',
    'viewValue': 'CLAUZETTO (PN)'
  },
  {
    'value': 'C792',
    'viewValue': 'CLAVESANA (CN)'
  },
  {
    'value': 'C793',
    'viewValue': 'CLAVIERE (TO)'
  },
  {
    'value': 'C794',
    'viewValue': 'CLES (TN)'
  },
  {
    'value': 'C795',
    'viewValue': 'CLETO (CS)'
  },
  {
    'value': 'C796',
    'viewValue': 'CLIVIO (VA)'
  },
  {
    'value': 'C797',
    'viewValue': 'CLOZ (TN)'
  },
  {
    'value': 'C800',
    'viewValue': 'CLUSONE (BG)'
  },
  {
    'value': 'C801',
    'viewValue': 'COASSOLO TORINESE (TO)'
  },
  {
    'value': 'C803',
    'viewValue': 'COAZZE (TO)'
  },
  {
    'value': 'C804',
    'viewValue': 'COAZZOLO (AT)'
  },
  {
    'value': 'C806',
    'viewValue': 'COCCAGLIO (BS)'
  },
  {
    'value': 'C807',
    'viewValue': 'COCCONATO (AT)'
  },
  {
    'value': 'C810',
    'viewValue': 'COCQUIO-TREVISAGO (VA)'
  },
  {
    'value': 'C811',
    'viewValue': 'COCULLO (AQ)'
  },
  {
    'value': 'C812',
    'viewValue': 'CODEVIGO (PD)'
  },
  {
    'value': 'C813',
    'viewValue': 'CODEVILLA (PV)'
  },
  {
    'value': 'C814',
    'viewValue': 'CODIGORO (FE)'
  },
  {
    'value': 'C815',
    'viewValue': "CODOGNE' (TV)"
  },
  {
    'value': 'C816',
    'viewValue': 'CODOGNO (LO)'
  },
  {
    'value': 'C817',
    'viewValue': 'CODROIPO (UD)'
  },
  {
    'value': 'C818',
    'viewValue': 'CODRONGIANOS (SS)'
  },
  {
    'value': 'C819',
    'viewValue': 'COGGIOLA (BI)'
  },
  {
    'value': 'C820',
    'viewValue': 'COGLIATE (MI)'
  },
  {
    'value': 'C821',
    'viewValue': 'COGNE (AO)'
  },
  {
    'value': 'C823',
    'viewValue': 'COGOLETO (GE)'
  },
  {
    'value': 'C824',
    'viewValue': 'COGOLLO DEL CENGIO (VI)'
  },
  {
    'value': 'C826',
    'viewValue': 'COGORNO (GE)'
  },
  {
    'value': 'C829',
    'viewValue': 'COLAZZA (NO)'
  },
  {
    'value': 'C830',
    'viewValue': 'COLBORDOLO (PU)'
  },
  {
    'value': 'C835',
    'viewValue': 'COLERE (BG)'
  },
  {
    'value': 'C836',
    'viewValue': 'COLFELICE (FR)'
  },
  {
    'value': 'C838',
    'viewValue': 'COLI (PC)'
  },
  {
    'value': 'C839',
    'viewValue': 'COLICO (LC)'
  },
  {
    'value': 'C840',
    'viewValue': 'COLLAGNA (RE)'
  },
  {
    'value': 'C841',
    'viewValue': 'COLLALTO SABINO (RI)'
  },
  {
    'value': 'C844',
    'viewValue': 'COLLARMELE (AQ)'
  },
  {
    'value': 'C845',
    'viewValue': 'COLLAZZONE (PG)'
  },
  {
    'value': 'C851',
    'viewValue': 'COLLE BRIANZA (LC)'
  },
  {
    'value': 'C854',
    'viewValue': "COLLE D'ANCHISE (CB)"
  },
  {
    'value': 'C857',
    'viewValue': 'COLLE DI TORA (RI)'
  },
  {
    'value': 'C847',
    'viewValue': "COLLE DI VAL D'ELSA (SI)"
  },
  {
    'value': 'C870',
    'viewValue': 'COLLE SAN MAGNO (FR)'
  },
  {
    'value': 'C846',
    'viewValue': 'COLLE SANNITA (BN)'
  },
  {
    'value': 'C872',
    'viewValue': 'COLLE SANTA LUCIA (BL)'
  },
  {
    'value': 'C848',
    'viewValue': 'COLLE UMBERTO (TV)'
  },
  {
    'value': 'C850',
    'viewValue': 'COLLEBEATO (BS)'
  },
  {
    'value': 'C852',
    'viewValue': 'COLLECCHIO (PR)'
  },
  {
    'value': 'C853',
    'viewValue': 'COLLECORVINO (PE)'
  },
  {
    'value': 'C311',
    'viewValue': 'COLLEDARA (TE)'
  },
  {
    'value': 'C855',
    'viewValue': 'COLLEDIMACINE (CH)'
  },
  {
    'value': 'C856',
    'viewValue': 'COLLEDIMEZZO (CH)'
  },
  {
    'value': 'C858',
    'viewValue': 'COLLEFERRO (RM)'
  },
  {
    'value': 'C859',
    'viewValue': 'COLLEGIOVE (RI)'
  },
  {
    'value': 'C860',
    'viewValue': 'COLLEGNO (TO)'
  },
  {
    'value': 'C862',
    'viewValue': 'COLLELONGO (AQ)'
  },
  {
    'value': 'C864',
    'viewValue': 'COLLEPARDO (FR)'
  },
  {
    'value': 'C865',
    'viewValue': 'COLLEPASSO (LE)'
  },
  {
    'value': 'C866',
    'viewValue': 'COLLEPIETRO (AQ)'
  },
  {
    'value': 'C867',
    'viewValue': 'COLLERETTO CASTELNUOVO (TO)'
  },
  {
    'value': 'C868',
    'viewValue': 'COLLERETTO GIACOSA (TO)'
  },
  {
    'value': 'C869',
    'viewValue': 'COLLESALVETTI (LI)'
  },
  {
    'value': 'C871',
    'viewValue': 'COLLESANO (PA)'
  },
  {
    'value': 'C875',
    'viewValue': 'COLLETORTO (CB)'
  },
  {
    'value': 'C876',
    'viewValue': 'COLLEVECCHIO (RI)'
  },
  {
    'value': 'C878',
    'viewValue': 'COLLI A VOLTURNO (IS)'
  },
  {
    'value': 'C877',
    'viewValue': 'COLLI DEL TRONTO (AP)'
  },
  {
    'value': 'C880',
    'viewValue': 'COLLI SUL VELINO (RI)'
  },
  {
    'value': 'C879',
    'viewValue': 'COLLIANO (SA)'
  },
  {
    'value': 'C882',
    'viewValue': 'COLLINAS (CA)'
  },
  {
    'value': 'C883',
    'viewValue': 'COLLIO (BS)'
  },
  {
    'value': 'C884',
    'viewValue': 'COLLOBIANO (VC)'
  },
  {
    'value': 'C885',
    'viewValue': 'COLLOREDO DI MONTE ALBANO (UD)'
  },
  {
    'value': 'C886',
    'viewValue': 'COLMURANO (MC)'
  },
  {
    'value': 'C888',
    'viewValue': 'COLOBRARO (MT)'
  },
  {
    'value': 'C890',
    'viewValue': 'COLOGNA VENETA (VR)'
  },
  {
    'value': 'C893',
    'viewValue': 'COLOGNE (BS)'
  },
  {
    'value': 'C894',
    'viewValue': 'COLOGNO AL SERIO (BG)'
  },
  {
    'value': 'C895',
    'viewValue': 'COLOGNO MONZESE (MI)'
  },
  {
    'value': 'C897',
    'viewValue': 'COLOGNOLA AI COLLI (VR)'
  },
  {
    'value': 'C900',
    'viewValue': 'COLONNA (RM)'
  },
  {
    'value': 'C901',
    'viewValue': 'COLONNELLA (TE)'
  },
  {
    'value': 'C902',
    'viewValue': 'COLONNO (CO)'
  },
  {
    'value': 'C903',
    'viewValue': 'COLORINA (SO)'
  },
  {
    'value': 'C904',
    'viewValue': 'COLORNO (PR)'
  },
  {
    'value': 'C905',
    'viewValue': 'COLOSIMI (CS)'
  },
  {
    'value': 'C908',
    'viewValue': 'COLTURANO (MI)'
  },
  {
    'value': 'C910',
    'viewValue': 'COLZATE (BG)'
  },
  {
    'value': 'C911',
    'viewValue': 'COMABBIO (VA)'
  },
  {
    'value': 'C912',
    'viewValue': 'COMACCHIO (FE)'
  },
  {
    'value': 'C914',
    'viewValue': 'COMANO (MS)'
  },
  {
    'value': 'C917',
    'viewValue': 'COMAZZO (LO)'
  },
  {
    'value': 'C918',
    'viewValue': 'COMEGLIANS (UD)'
  },
  {
    'value': 'C920',
    'viewValue': 'COMELICO SUPERIORE (BL)'
  },
  {
    'value': 'C922',
    'viewValue': 'COMERIO (VA)'
  },
  {
    'value': 'C925',
    'viewValue': 'COMEZZANO-CIZZAGO (BS)'
  },
  {
    'value': 'C926',
    'viewValue': 'COMIGNAGO (NO)'
  },
  {
    'value': 'C927',
    'viewValue': 'COMISO (RG)'
  },
  {
    'value': 'C928',
    'viewValue': 'COMITINI (AG)'
  },
  {
    'value': 'C929',
    'viewValue': 'COMIZIANO (NA)'
  },
  {
    'value': 'C930',
    'viewValue': 'COMMESSAGGIO (MN)'
  },
  {
    'value': 'C931',
    'viewValue': 'COMMEZZADURA (TN)'
  },
  {
    'value': 'C933',
    'viewValue': 'COMO (CO)'
  },
  {
    'value': 'C934',
    'viewValue': 'COMPIANO (PR)'
  },
  {
    'value': 'C937',
    'viewValue': 'COMUN NUOVO (BG)'
  },
  {
    'value': 'C935',
    'viewValue': 'COMUNANZA (AP)'
  },
  {
    'value': 'C938',
    'viewValue': 'CONA (VE)'
  },
  {
    'value': 'C941',
    'viewValue': 'CONCA CASALE (IS)'
  },
  {
    'value': 'C940',
    'viewValue': 'CONCA DEI MARINI (SA)'
  },
  {
    'value': 'C939',
    'viewValue': 'CONCA DELLA CAMPANIA (CE)'
  },
  {
    'value': 'C943',
    'viewValue': 'CONCAMARISE (VR)'
  },
  {
    'value': 'C944',
    'viewValue': 'CONCEI (TN)'
  },
  {
    'value': 'C946',
    'viewValue': 'CONCERVIANO (RI)'
  },
  {
    'value': 'C948',
    'viewValue': 'CONCESIO (BS)'
  },
  {
    'value': 'C949',
    'viewValue': 'CONCO (VI)'
  },
  {
    'value': 'C950',
    'viewValue': 'CONCORDIA SAGITTARIA (VE)'
  },
  {
    'value': 'C951',
    'viewValue': 'CONCORDIA SULLA SECCHIA (MO)'
  },
  {
    'value': 'C952',
    'viewValue': 'CONCOREZZO (MI)'
  },
  {
    'value': 'C953',
    'viewValue': 'CONDINO (TN)'
  },
  {
    'value': 'C954',
    'viewValue': 'CONDOFURI (RC)'
  },
  {
    'value': 'C955',
    'viewValue': 'CONDOVE (TO)'
  },
  {
    'value': 'C956',
    'viewValue': "CONDRO' (ME)"
  },
  {
    'value': 'C957',
    'viewValue': 'CONEGLIANO (TV)'
  },
  {
    'value': 'C958',
    'viewValue': 'CONFIENZA (PV)'
  },
  {
    'value': 'C959',
    'viewValue': 'CONFIGNI (RI)'
  },
  {
    'value': 'C960',
    'viewValue': 'CONFLENTI (CZ)'
  },
  {
    'value': 'C962',
    'viewValue': 'CONIOLO (AL)'
  },
  {
    'value': 'C963',
    'viewValue': 'CONSELICE (RA)'
  },
  {
    'value': 'C964',
    'viewValue': 'CONSELVE (PD)'
  },
  {
    'value': 'C965',
    'viewValue': 'CONSIGLIO DI RUMO (CO)'
  },
  {
    'value': 'C967',
    'viewValue': 'CONTARINA (RO)'
  },
  {
    'value': 'C968',
    'viewValue': 'CONTESSA ENTELLINA (PA)'
  },
  {
    'value': 'C969',
    'viewValue': 'CONTIGLIANO (RI)'
  },
  {
    'value': 'C971',
    'viewValue': 'CONTRADA (AV)'
  },
  {
    'value': 'C972',
    'viewValue': 'CONTROGUERRA (TE)'
  },
  {
    'value': 'C973',
    'viewValue': 'CONTRONE (SA)'
  },
  {
    'value': 'C974',
    'viewValue': 'CONTURSI TERME (SA)'
  },
  {
    'value': 'C975',
    'viewValue': 'CONVERSANO (BA)'
  },
  {
    'value': 'C976',
    'viewValue': 'CONZA DELLA CAMPANIA (AV)'
  },
  {
    'value': 'C977',
    'viewValue': 'CONZANO (AL)'
  },
  {
    'value': 'C978',
    'viewValue': 'COPERTINO (LE)'
  },
  {
    'value': 'C979',
    'viewValue': 'COPIANO (PV)'
  },
  {
    'value': 'C980',
    'viewValue': 'COPPARO (FE)'
  },
  {
    'value': 'C982',
    'viewValue': 'CORANA (PV)'
  },
  {
    'value': 'C983',
    'viewValue': 'CORATO (BA)'
  },
  {
    'value': 'C984',
    'viewValue': 'CORBARA (SA)'
  },
  {
    'value': 'C986',
    'viewValue': 'CORBETTA (MI)'
  },
  {
    'value': 'C987',
    'viewValue': 'CORBOLA (RO)'
  },
  {
    'value': 'C988',
    'viewValue': 'CORCHIANO (VT)'
  },
  {
    'value': 'C990',
    'viewValue': 'CORCIANO (PG)'
  },
  {
    'value': 'C991',
    'viewValue': 'CORDENONS (PN)'
  },
  {
    'value': 'C992',
    'viewValue': 'CORDIGNANO (TV)'
  },
  {
    'value': 'C993',
    'viewValue': 'CORDOVADO (PN)'
  },
  {
    'value': 'C994',
    'viewValue': 'COREDO (TN)'
  },
  {
    'value': 'C996',
    'viewValue': 'COREGLIA ANTELMINELLI (LU)'
  },
  {
    'value': 'C995',
    'viewValue': 'COREGLIA LIGURE (GE)'
  },
  {
    'value': 'C998',
    'viewValue': 'CORENO AUSONIO (FR)'
  },
  {
    'value': 'C999',
    'viewValue': 'CORFINIO (AQ)'
  },
  {
    'value': 'D003',
    'viewValue': 'CORI (LT)'
  },
  {
    'value': 'D004',
    'viewValue': 'CORIANO (RN)'
  },
  {
    'value': 'D005',
    'viewValue': 'CORIGLIANO CALABRO (CS)'
  },
  {
    'value': 'D006',
    'viewValue': "CORIGLIANO D'OTRANTO (LE)"
  },
  {
    'value': 'D007',
    'viewValue': 'CORINALDO (AN)'
  },
  {
    'value': 'D008',
    'viewValue': 'CORIO (TO)'
  },
  {
    'value': 'D009',
    'viewValue': 'CORLEONE (PA)'
  },
  {
    'value': 'D011',
    'viewValue': 'CORLETO MONFORTE (SA)'
  },
  {
    'value': 'D010',
    'viewValue': 'CORLETO PERTICARA (PZ)'
  },
  {
    'value': 'D013',
    'viewValue': 'CORMANO (MI)'
  },
  {
    'value': 'D014',
    'viewValue': 'CORMONS (GO)'
  },
  {
    'value': 'D015',
    'viewValue': 'CORNA IMAGNA (BG)'
  },
  {
    'value': 'D016',
    'viewValue': 'CORNALBA (BG)'
  },
  {
    'value': 'D017',
    'viewValue': 'CORNALE (PV)'
  },
  {
    'value': 'D018',
    'viewValue': 'CORNAREDO (MI)'
  },
  {
    'value': 'D019',
    'viewValue': "CORNATE D'ADDA (MI)"
  },
  {
    'value': 'B799',
    'viewValue': "CORNEDO ALL'ISARCO .KARNEID. (BZ)"
  },
  {
    'value': 'D020',
    'viewValue': 'CORNEDO VICENTINO (VI)'
  },
  {
    'value': 'D021',
    'viewValue': 'CORNEGLIANO LAUDENSE (LO)'
  },
  {
    'value': 'D022',
    'viewValue': "CORNELIANO D'ALBA (CN)"
  },
  {
    'value': 'D026',
    'viewValue': 'CORNIGLIO (PR)'
  },
  {
    'value': 'D027',
    'viewValue': 'CORNO DI ROSAZZO (UD)'
  },
  {
    'value': 'D028',
    'viewValue': 'CORNO GIOVINE (LO)'
  },
  {
    'value': 'D029',
    'viewValue': 'CORNOVECCHIO (LO)'
  },
  {
    'value': 'D030',
    'viewValue': 'CORNUDA (TV)'
  },
  {
    'value': 'D037',
    'viewValue': 'CORREGGIO (RE)'
  },
  {
    'value': 'D038',
    'viewValue': 'CORREZZANA (MI)'
  },
  {
    'value': 'D040',
    'viewValue': 'CORREZZOLA (PD)'
  },
  {
    'value': 'D041',
    'viewValue': 'CORRIDO (CO)'
  },
  {
    'value': 'D042',
    'viewValue': 'CORRIDONIA (MC)'
  },
  {
    'value': 'D043',
    'viewValue': 'CORROPOLI (TE)'
  },
  {
    'value': 'D044',
    'viewValue': 'CORSANO (LE)'
  },
  {
    'value': 'D045',
    'viewValue': 'CORSICO (MI)'
  },
  {
    'value': 'D046',
    'viewValue': 'CORSIONE (AT)'
  },
  {
    'value': 'D048',
    'viewValue': 'CORTACCIA SULLA STRADA DEL VINO .KURTATSCH A. (BZ)'
  },
  {
    'value': 'D049',
    'viewValue': 'CORTALE (CZ)'
  },
  {
    'value': 'D050',
    'viewValue': 'CORTANDONE (AT)'
  },
  {
    'value': 'D051',
    'viewValue': 'CORTANZE (AT)'
  },
  {
    'value': 'D052',
    'viewValue': 'CORTAZZONE (AT)'
  },
  {
    'value': 'D054',
    'viewValue': 'CORTE BRUGNATELLA (PC)'
  },
  {
    'value': 'D056',
    'viewValue': "CORTE DE' CORTESI CON CIGNONE (CR)"
  },
  {
    'value': 'D057',
    'viewValue': "CORTE DE' FRATI (CR)"
  },
  {
    'value': 'D058',
    'viewValue': 'CORTE FRANCA (BS)'
  },
  {
    'value': 'D068',
    'viewValue': 'CORTE PALASIO (LO)'
  },
  {
    'value': 'D061',
    'viewValue': 'CORTEMAGGIORE (PC)'
  },
  {
    'value': 'D062',
    'viewValue': 'CORTEMILIA (CN)'
  },
  {
    'value': 'D064',
    'viewValue': 'CORTENO GOLGI (BS)'
  },
  {
    'value': 'D065',
    'viewValue': 'CORTENOVA (LC)'
  },
  {
    'value': 'D066',
    'viewValue': 'CORTENUOVA (BG)'
  },
  {
    'value': 'D067',
    'viewValue': 'CORTEOLONA (PV)'
  },
  {
    'value': 'D072',
    'viewValue': 'CORTIGLIONE (AT)'
  },
  {
    'value': 'A266',
    'viewValue': "CORTINA D'AMPEZZO (BL)"
  },
  {
    'value': 'D075',
    'viewValue': 'CORTINA SULLA STRADA DEL VINO .KURTINIG AN D. (BZ)'
  },
  {
    'value': 'D076',
    'viewValue': 'CORTINO (TE)'
  },
  {
    'value': 'D077',
    'viewValue': 'CORTONA (AR)'
  },
  {
    'value': 'D078',
    'viewValue': 'CORVARA (PE)'
  },
  {
    'value': 'D079',
    'viewValue': 'CORVARA IN BADIA .CORVARA. (BZ)'
  },
  {
    'value': 'D081',
    'viewValue': 'CORVINO SAN QUIRICO (PV)'
  },
  {
    'value': 'D082',
    'viewValue': 'CORZANO (BS)'
  },
  {
    'value': 'D085',
    'viewValue': 'COSEANO (UD)'
  },
  {
    'value': 'D086',
    'viewValue': 'COSENZA (CS)'
  },
  {
    'value': 'D087',
    'viewValue': "COSIO D'ARROSCIA (IM)"
  },
  {
    'value': 'D088',
    'viewValue': 'COSIO VALTELLINO (SO)'
  },
  {
    'value': 'D089',
    'viewValue': 'COSOLETO (RC)'
  },
  {
    'value': 'D093',
    'viewValue': 'COSSANO BELBO (CN)'
  },
  {
    'value': 'D092',
    'viewValue': 'COSSANO CANAVESE (TO)'
  },
  {
    'value': 'D094',
    'viewValue': 'COSSATO (BI)'
  },
  {
    'value': 'D095',
    'viewValue': 'COSSERIA (SV)'
  },
  {
    'value': 'D096',
    'viewValue': 'COSSIGNANO (AP)'
  },
  {
    'value': 'D099',
    'viewValue': 'COSSOGNO (VB)'
  },
  {
    'value': 'D100',
    'viewValue': 'COSSOINE (SS)'
  },
  {
    'value': 'D101',
    'viewValue': 'COSSOMBRATO (AT)'
  },
  {
    'value': 'D109',
    'viewValue': "COSTA DE' NOBILI (PV)"
  },
  {
    'value': 'D110',
    'viewValue': 'COSTA DI MEZZATE (BG)'
  },
  {
    'value': 'D105',
    'viewValue': 'COSTA DI ROVIGO (RO)'
  },
  {
    'value': 'D111',
    'viewValue': 'COSTA DI SERINA (BG)'
  },
  {
    'value': 'D112',
    'viewValue': 'COSTA MASNAGA (LC)'
  },
  {
    'value': 'D103',
    'viewValue': 'COSTA VALLE IMAGNA (BG)'
  },
  {
    'value': 'D102',
    'viewValue': 'COSTA VESCOVATO (AL)'
  },
  {
    'value': 'D117',
    'viewValue': 'COSTA VOLPINO (BG)'
  },
  {
    'value': 'D107',
    'viewValue': 'COSTABISSARA (VI)'
  },
  {
    'value': 'D108',
    'viewValue': 'COSTACCIARO (PG)'
  },
  {
    'value': 'D113',
    'viewValue': 'COSTANZANA (VC)'
  },
  {
    'value': 'D114',
    'viewValue': 'COSTARAINERA (IM)'
  },
  {
    'value': 'D118',
    'viewValue': 'COSTERMANO (VR)'
  },
  {
    'value': 'D119',
    'viewValue': "COSTIGLIOLE D'ASTI (AT)"
  },
  {
    'value': 'D120',
    'viewValue': 'COSTIGLIOLE SALUZZO (CN)'
  },
  {
    'value': 'D121',
    'viewValue': 'COTIGNOLA (RA)'
  },
  {
    'value': 'D123',
    'viewValue': 'COTRONEI (KR)'
  },
  {
    'value': 'D124',
    'viewValue': 'COTTANELLO (RI)'
  },
  {
    'value': 'D012',
    'viewValue': 'COURMAYEUR (AO)'
  },
  {
    'value': 'D126',
    'viewValue': 'COVO (BG)'
  },
  {
    'value': 'D127',
    'viewValue': 'COZZO (PV)'
  },
  {
    'value': 'D128',
    'viewValue': 'CRACO (MT)'
  },
  {
    'value': 'D131',
    'viewValue': 'CRANDOLA VALSASSINA (LC)'
  },
  {
    'value': 'D132',
    'viewValue': 'CRAVAGLIANA (VC)'
  },
  {
    'value': 'D133',
    'viewValue': 'CRAVANZANA (CN)'
  },
  {
    'value': 'D134',
    'viewValue': 'CRAVEGGIA (VB)'
  },
  {
    'value': 'D136',
    'viewValue': 'CREAZZO (VI)'
  },
  {
    'value': 'D137',
    'viewValue': 'CRECCHIO (CH)'
  },
  {
    'value': 'D139',
    'viewValue': 'CREDARO (BG)'
  },
  {
    'value': 'D141',
    'viewValue': 'CREDERA RUBBIANO (CR)'
  },
  {
    'value': 'D142',
    'viewValue': 'CREMA (CR)'
  },
  {
    'value': 'D143',
    'viewValue': 'CREMELLA (LC)'
  },
  {
    'value': 'D144',
    'viewValue': 'CREMENAGA (VA)'
  },
  {
    'value': 'D145',
    'viewValue': 'CREMENO (LC)'
  },
  {
    'value': 'D147',
    'viewValue': 'CREMIA (CO)'
  },
  {
    'value': 'D149',
    'viewValue': 'CREMOLINO (AL)'
  },
  {
    'value': 'D150',
    'viewValue': 'CREMONA (CR)'
  },
  {
    'value': 'D151',
    'viewValue': 'CREMOSANO (CR)'
  },
  {
    'value': 'D154',
    'viewValue': 'CRESCENTINO (VC)'
  },
  {
    'value': 'D156',
    'viewValue': 'CRESPADORO (VI)'
  },
  {
    'value': 'D157',
    'viewValue': 'CRESPANO DEL GRAPPA (TV)'
  },
  {
    'value': 'D158',
    'viewValue': 'CRESPELLANO (BO)'
  },
  {
    'value': 'D159',
    'viewValue': 'CRESPIATICA (LO)'
  },
  {
    'value': 'D160',
    'viewValue': 'CRESPINA (PI)'
  },
  {
    'value': 'D161',
    'viewValue': 'CRESPINO (RO)'
  },
  {
    'value': 'D162',
    'viewValue': 'CRESSA (NO)'
  },
  {
    'value': 'D165',
    'viewValue': 'CREVACUORE (BI)'
  },
  {
    'value': 'D166',
    'viewValue': 'CREVALCORE (BO)'
  },
  {
    'value': 'D168',
    'viewValue': 'CREVOLADOSSOLA (VB)'
  },
  {
    'value': 'D170',
    'viewValue': 'CRISPANO (NA)'
  },
  {
    'value': 'D171',
    'viewValue': 'CRISPIANO (TA)'
  },
  {
    'value': 'D172',
    'viewValue': 'CRISSOLO (CN)'
  },
  {
    'value': 'D175',
    'viewValue': 'CROCEFIESCHI (GE)'
  },
  {
    'value': 'C670',
    'viewValue': 'CROCETTA DEL MONTELLO (TV)'
  },
  {
    'value': 'D177',
    'viewValue': 'CRODO (VB)'
  },
  {
    'value': 'D179',
    'viewValue': 'CROGNALETO (TE)'
  },
  {
    'value': 'D180',
    'viewValue': 'CROPALATI (CS)'
  },
  {
    'value': 'D181',
    'viewValue': 'CROPANI (CZ)'
  },
  {
    'value': 'D182',
    'viewValue': 'CROSA (BI)'
  },
  {
    'value': 'D184',
    'viewValue': 'CROSIA (CS)'
  },
  {
    'value': 'D185',
    'viewValue': 'CROSIO DELLA VALLE (VA)'
  },
  {
    'value': 'D122',
    'viewValue': 'CROTONE (KR)'
  },
  {
    'value': 'D186',
    'viewValue': "CROTTA D'ADDA (CR)"
  },
  {
    'value': 'D187',
    'viewValue': 'CROVA (VC)'
  },
  {
    'value': 'D188',
    'viewValue': 'CROVIANA (TN)'
  },
  {
    'value': 'D189',
    'viewValue': 'CRUCOLI (KR)'
  },
  {
    'value': 'D192',
    'viewValue': 'CUASSO AL MONTE (VA)'
  },
  {
    'value': 'D194',
    'viewValue': 'CUCCARO MONFERRATO (AL)'
  },
  {
    'value': 'D195',
    'viewValue': 'CUCCARO VETERE (SA)'
  },
  {
    'value': 'D196',
    'viewValue': 'CUCCIAGO (CO)'
  },
  {
    'value': 'D197',
    'viewValue': 'CUCEGLIO (TO)'
  },
  {
    'value': 'D198',
    'viewValue': 'CUGGIONO (MI)'
  },
  {
    'value': 'D199',
    'viewValue': 'CUGLIATE-FABIASCO (VA)'
  },
  {
    'value': 'D200',
    'viewValue': 'CUGLIERI (OR)'
  },
  {
    'value': 'D201',
    'viewValue': 'CUGNOLI (PE)'
  },
  {
    'value': 'D202',
    'viewValue': 'CUMIANA (TO)'
  },
  {
    'value': 'D203',
    'viewValue': 'CUMIGNANO SUL NAVIGLIO (CR)'
  },
  {
    'value': 'D204',
    'viewValue': 'CUNARDO (VA)'
  },
  {
    'value': 'D205',
    'viewValue': 'CUNEO (CN)'
  },
  {
    'value': 'D206',
    'viewValue': 'CUNEVO (TN)'
  },
  {
    'value': 'D207',
    'viewValue': 'CUNICO (AT)'
  },
  {
    'value': 'D208',
    'viewValue': "CUORGNE' (TO)"
  },
  {
    'value': 'D209',
    'viewValue': 'CUPELLO (CH)'
  },
  {
    'value': 'D210',
    'viewValue': 'CUPRA MARITTIMA (AP)'
  },
  {
    'value': 'D211',
    'viewValue': 'CUPRAMONTANA (AN)'
  },
  {
    'value': 'B824',
    'viewValue': 'CURA CARPIGNANO (PV)'
  },
  {
    'value': 'D214',
    'viewValue': 'CURCURIS (OR)'
  },
  {
    'value': 'D216',
    'viewValue': 'CUREGGIO (NO)'
  },
  {
    'value': 'D217',
    'viewValue': 'CURIGLIA CON MONTEVIASCO (VA)'
  },
  {
    'value': 'D218',
    'viewValue': 'CURINGA (CZ)'
  },
  {
    'value': 'D219',
    'viewValue': 'CURINO (BI)'
  },
  {
    'value': 'D221',
    'viewValue': 'CURNO (BG)'
  },
  {
    'value': 'D222',
    'viewValue': 'CURON VENOSTA .GRAUN IN VINSCHGAU. (BZ)'
  },
  {
    'value': 'D223',
    'viewValue': 'CURSI (LE)'
  },
  {
    'value': 'D225',
    'viewValue': 'CURSOLO-ORASSO (VB)'
  },
  {
    'value': 'D226',
    'viewValue': 'CURTAROLO (PD)'
  },
  {
    'value': 'D227',
    'viewValue': 'CURTATONE (MN)'
  },
  {
    'value': 'D228',
    'viewValue': 'CURTI (CE)'
  },
  {
    'value': 'D229',
    'viewValue': 'CUSAGO (MI)'
  },
  {
    'value': 'D231',
    'viewValue': 'CUSANO MILANINO (MI)'
  },
  {
    'value': 'D230',
    'viewValue': 'CUSANO MUTRI (BN)'
  },
  {
    'value': 'D232',
    'viewValue': 'CUSINO (CO)'
  },
  {
    'value': 'D233',
    'viewValue': 'CUSIO (BG)'
  },
  {
    'value': 'D234',
    'viewValue': 'CUSTONACI (TP)'
  },
  {
    'value': 'D235',
    'viewValue': 'CUTIGLIANO (PT)'
  },
  {
    'value': 'D236',
    'viewValue': 'CUTRO (KR)'
  },
  {
    'value': 'D237',
    'viewValue': 'CUTROFIANO (LE)'
  },
  {
    'value': 'D238',
    'viewValue': 'CUVEGLIO (VA)'
  },
  {
    'value': 'D239',
    'viewValue': 'CUVIO (VA)'
  },
  {
    'value': 'D243',
    'viewValue': 'DAIANO (TN)'
  },
  {
    'value': 'D244',
    'viewValue': 'DAIRAGO (MI)'
  },
  {
    'value': 'D245',
    'viewValue': 'DALMINE (BG)'
  },
  {
    'value': 'D246',
    'viewValue': 'DAMBEL (TN)'
  },
  {
    'value': 'D247',
    'viewValue': 'DANTA DI CADORE (BL)'
  },
  {
    'value': 'D248',
    'viewValue': 'DAONE (TN)'
  },
  {
    'value': 'D250',
    'viewValue': "DARE' (TN)"
  },
  {
    'value': 'D251',
    'viewValue': 'DARFO BOARIO TERME (BS)'
  },
  {
    'value': 'D253',
    'viewValue': "DASA' (VV)"
  },
  {
    'value': 'D255',
    'viewValue': 'DAVAGNA (GE)'
  },
  {
    'value': 'D256',
    'viewValue': 'DAVERIO (VA)'
  },
  {
    'value': 'D257',
    'viewValue': 'DAVOLI (CZ)'
  },
  {
    'value': 'D258',
    'viewValue': 'DAZIO (SO)'
  },
  {
    'value': 'D259',
    'viewValue': 'DECIMOMANNU (CA)'
  },
  {
    'value': 'D260',
    'viewValue': 'DECIMOPUTZU (CA)'
  },
  {
    'value': 'D261',
    'viewValue': 'DECOLLATURA (CZ)'
  },
  {
    'value': 'D264',
    'viewValue': 'DEGO (SV)'
  },
  {
    'value': 'D265',
    'viewValue': 'DEIVA MARINA (SP)'
  },
  {
    'value': 'D266',
    'viewValue': 'DELEBIO (SO)'
  },
  {
    'value': 'D267',
    'viewValue': 'DELIA (CL)'
  },
  {
    'value': 'D268',
    'viewValue': 'DELIANUOVA (RC)'
  },
  {
    'value': 'D269',
    'viewValue': 'DELICETO (FG)'
  },
  {
    'value': 'D270',
    'viewValue': 'DELLO (BS)'
  },
  {
    'value': 'D271',
    'viewValue': 'DEMONTE (CN)'
  },
  {
    'value': 'D272',
    'viewValue': 'DENICE (AL)'
  },
  {
    'value': 'D273',
    'viewValue': 'DENNO (TN)'
  },
  {
    'value': 'D277',
    'viewValue': 'DERNICE (AL)'
  },
  {
    'value': 'D278',
    'viewValue': 'DEROVERE (CR)'
  },
  {
    'value': 'D279',
    'viewValue': 'DERUTA (PG)'
  },
  {
    'value': 'D280',
    'viewValue': 'DERVIO (LC)'
  },
  {
    'value': 'D281',
    'viewValue': 'DESANA (VC)'
  },
  {
    'value': 'D284',
    'viewValue': 'DESENZANO DEL GARDA (BS)'
  },
  {
    'value': 'D286',
    'viewValue': 'DESIO (MI)'
  },
  {
    'value': 'D287',
    'viewValue': 'DESULO (NU)'
  },
  {
    'value': 'D289',
    'viewValue': 'DIAMANTE (CS)'
  },
  {
    'value': 'D293',
    'viewValue': 'DIANO ARENTINO (IM)'
  },
  {
    'value': 'D296',
    'viewValue': 'DIANO CASTELLO (IM)'
  },
  {
    'value': 'D291',
    'viewValue': "DIANO D'ALBA (CN)"
  },
  {
    'value': 'D297',
    'viewValue': 'DIANO MARINA (IM)'
  },
  {
    'value': 'D298',
    'viewValue': 'DIANO SAN PIETRO (IM)'
  },
  {
    'value': 'D299',
    'viewValue': 'DICOMANO (FI)'
  },
  {
    'value': 'D300',
    'viewValue': 'DIGNANO (UD)'
  },
  {
    'value': 'D302',
    'viewValue': 'DIMARO (TN)'
  },
  {
    'value': 'D303',
    'viewValue': 'DINAMI (VV)'
  },
  {
    'value': 'D304',
    'viewValue': 'DIPIGNANO (CS)'
  },
  {
    'value': 'D305',
    'viewValue': 'DISO (LE)'
  },
  {
    'value': 'D309',
    'viewValue': 'DIVIGNANO (NO)'
  },
  {
    'value': 'D310',
    'viewValue': 'DIZZASCO (CO)'
  },
  {
    'value': 'D311',
    'viewValue': 'DOBBIACO .TOBLACH. (BZ)'
  },
  {
    'value': 'D312',
    'viewValue': "DOBERDO' DEL LAGO (GO)"
  },
  {
    'value': 'D314',
    'viewValue': 'DOGLIANI (CN)'
  },
  {
    'value': 'D315',
    'viewValue': 'DOGLIOLA (CH)'
  },
  {
    'value': 'D316',
    'viewValue': 'DOGNA (UD)'
  },
  {
    'value': 'D317',
    'viewValue': "DOLCE' (VR)"
  },
  {
    'value': 'D318',
    'viewValue': 'DOLCEACQUA (IM)'
  },
  {
    'value': 'D319',
    'viewValue': 'DOLCEDO (IM)'
  },
  {
    'value': 'D321',
    'viewValue': 'DOLEGNA DEL COLLIO (GO)'
  },
  {
    'value': 'D323',
    'viewValue': 'DOLIANOVA (CA)'
  },
  {
    'value': 'D325',
    'viewValue': 'DOLO (VE)'
  },
  {
    'value': 'D327',
    'viewValue': 'DOLZAGO (LC)'
  },
  {
    'value': 'D328',
    'viewValue': 'DOMANICO (CS)'
  },
  {
    'value': 'D329',
    'viewValue': 'DOMASO (CO)'
  },
  {
    'value': 'D330',
    'viewValue': 'DOMEGGE DI CADORE (BL)'
  },
  {
    'value': 'D331',
    'viewValue': 'DOMICELLA (AV)'
  },
  {
    'value': 'D332',
    'viewValue': 'DOMODOSSOLA (VB)'
  },
  {
    'value': 'D333',
    'viewValue': 'DOMUS DE MARIA (CA)'
  },
  {
    'value': 'D334',
    'viewValue': 'DOMUSNOVAS (CA)'
  },
  {
    'value': 'D336',
    'viewValue': 'DON (TN)'
  },
  {
    'value': 'D337',
    'viewValue': 'DONADA (RO)'
  },
  {
    'value': 'D339',
    'viewValue': 'DONATO (BI)'
  },
  {
    'value': 'D341',
    'viewValue': 'DONGO (CO)'
  },
  {
    'value': 'D338',
    'viewValue': 'DONNAS (AO)'
  },
  {
    'value': 'D344',
    'viewValue': 'DONORI (CA)'
  },
  {
    'value': 'D345',
    'viewValue': 'DORGALI (NU)'
  },
  {
    'value': 'D346',
    'viewValue': 'DORIO (LC)'
  },
  {
    'value': 'D347',
    'viewValue': 'DORMELLETTO (NO)'
  },
  {
    'value': 'D348',
    'viewValue': 'DORNO (PV)'
  },
  {
    'value': 'D349',
    'viewValue': 'DORSINO (TN)'
  },
  {
    'value': 'D350',
    'viewValue': 'DORZANO (BI)'
  },
  {
    'value': 'D351',
    'viewValue': 'DOSOLO (MN)'
  },
  {
    'value': 'D352',
    'viewValue': 'DOSSENA (BG)'
  },
  {
    'value': 'D355',
    'viewValue': 'DOSSO DEL LIRO (CO)'
  },
  {
    'value': 'D356',
    'viewValue': 'DOUES (AO)'
  },
  {
    'value': 'D357',
    'viewValue': 'DOVADOLA (FC)'
  },
  {
    'value': 'D358',
    'viewValue': 'DOVERA (CR)'
  },
  {
    'value': 'D360',
    'viewValue': 'DOZZA (BO)'
  },
  {
    'value': 'D361',
    'viewValue': 'DRAGONI (CE)'
  },
  {
    'value': 'D364',
    'viewValue': 'DRAPIA (VV)'
  },
  {
    'value': 'D365',
    'viewValue': 'DRENA (TN)'
  },
  {
    'value': 'D366',
    'viewValue': 'DRENCHIA (UD)'
  },
  {
    'value': 'D367',
    'viewValue': 'DRESANO (MI)'
  },
  {
    'value': 'D369',
    'viewValue': 'DREZZO (CO)'
  },
  {
    'value': 'D370',
    'viewValue': 'DRIZZONA (CR)'
  },
  {
    'value': 'D371',
    'viewValue': 'DRO (TN)'
  },
  {
    'value': 'D372',
    'viewValue': 'DRONERO (CN)'
  },
  {
    'value': 'D373',
    'viewValue': 'DRUENTO (TO)'
  },
  {
    'value': 'D374',
    'viewValue': 'DRUOGNO (VB)'
  },
  {
    'value': 'D376',
    'viewValue': 'DUALCHI (NU)'
  },
  {
    'value': 'D377',
    'viewValue': 'DUBINO (SO)'
  },
  {
    'value': 'M300',
    'viewValue': 'DUE CARRARE (PD)'
  },
  {
    'value': 'D379',
    'viewValue': 'DUEVILLE (VI)'
  },
  {
    'value': 'D380',
    'viewValue': 'DUGENTA (BN)'
  },
  {
    'value': 'D383',
    'viewValue': 'DUINO-AURISINA (TS)'
  },
  {
    'value': 'D384',
    'viewValue': 'DUMENZA (VA)'
  },
  {
    'value': 'D385',
    'viewValue': 'DUNO (VA)'
  },
  {
    'value': 'D386',
    'viewValue': 'DURAZZANO (BN)'
  },
  {
    'value': 'C772',
    'viewValue': 'DURONIA (CB)'
  },
  {
    'value': 'D388',
    'viewValue': 'DUSINO SAN MICHELE (AT)'
  },
  {
    'value': 'D390',
    'viewValue': 'EBOLI (SA)'
  },
  {
    'value': 'D391',
    'viewValue': 'EDOLO (BS)'
  },
  {
    'value': 'D392',
    'viewValue': 'EGNA .NEUMARKT. (BZ)'
  },
  {
    'value': 'D394',
    'viewValue': 'ELICE (PE)'
  },
  {
    'value': 'D395',
    'viewValue': 'ELINI (NU)'
  },
  {
    'value': 'D398',
    'viewValue': 'ELLO (LC)'
  },
  {
    'value': 'D399',
    'viewValue': 'ELMAS (CA)'
  },
  {
    'value': 'D401',
    'viewValue': 'ELVA (CN)'
  },
  {
    'value': 'D402',
    'viewValue': 'EMARESE (AO)'
  },
  {
    'value': 'D403',
    'viewValue': 'EMPOLI (FI)'
  },
  {
    'value': 'D406',
    'viewValue': 'ENDINE GAIANO (BG)'
  },
  {
    'value': 'D407',
    'viewValue': 'ENEGO (VI)'
  },
  {
    'value': 'D408',
    'viewValue': 'ENEMONZO (UD)'
  },
  {
    'value': 'C342',
    'viewValue': 'ENNA (EN)'
  },
  {
    'value': 'D410',
    'viewValue': 'ENTRACQUE (CN)'
  },
  {
    'value': 'D411',
    'viewValue': 'ENTRATICO (BG)'
  },
  {
    'value': 'D412',
    'viewValue': 'ENVIE (CN)'
  },
  {
    'value': 'D414',
    'viewValue': 'EPISCOPIA (PZ)'
  },
  {
    'value': 'D415',
    'viewValue': 'ERACLEA (VE)'
  },
  {
    'value': 'D416',
    'viewValue': 'ERBA (CO)'
  },
  {
    'value': 'D419',
    'viewValue': "ERBE' (VR)"
  },
  {
    'value': 'D420',
    'viewValue': 'ERBEZZO (VR)'
  },
  {
    'value': 'D421',
    'viewValue': 'ERBUSCO (BS)'
  },
  {
    'value': 'D422',
    'viewValue': 'ERCHIE (BR)'
  },
  {
    'value': 'H243',
    'viewValue': 'ERCOLANO (NA)'
  },
  {
    'value': 'D423',
    'viewValue': 'ERICE (TP)'
  },
  {
    'value': 'D424',
    'viewValue': 'ERLI (SV)'
  },
  {
    'value': 'D426',
    'viewValue': 'ERTO E CASSO (PN)'
  },
  {
    'value': 'M292',
    'viewValue': 'ERULA (SS)'
  },
  {
    'value': 'D428',
    'viewValue': 'ERVE (LC)'
  },
  {
    'value': 'D429',
    'viewValue': 'ESANATOGLIA (MC)'
  },
  {
    'value': 'D430',
    'viewValue': 'ESCALAPLANO (NU)'
  },
  {
    'value': 'D431',
    'viewValue': 'ESCOLCA (NU)'
  },
  {
    'value': 'D434',
    'viewValue': 'ESINE (BS)'
  },
  {
    'value': 'D436',
    'viewValue': 'ESINO LARIO (LC)'
  },
  {
    'value': 'D440',
    'viewValue': 'ESPERIA (FR)'
  },
  {
    'value': 'D441',
    'viewValue': 'ESPORLATU (SS)'
  },
  {
    'value': 'D442',
    'viewValue': 'ESTE (PD)'
  },
  {
    'value': 'D443',
    'viewValue': 'ESTERZILI (NU)'
  },
  {
    'value': 'D444',
    'viewValue': 'ETROUBLES (AO)'
  },
  {
    'value': 'D445',
    'viewValue': 'EUPILIO (CO)'
  },
  {
    'value': 'D433',
    'viewValue': 'EXILLES (TO)'
  },
  {
    'value': 'D447',
    'viewValue': 'FABBRICA CURONE (AL)'
  },
  {
    'value': 'D449',
    'viewValue': 'FABBRICHE DI VALLICO (LU)'
  },
  {
    'value': 'D450',
    'viewValue': 'FABBRICO (RE)'
  },
  {
    'value': 'D451',
    'viewValue': 'FABRIANO (AN)'
  },
  {
    'value': 'D452',
    'viewValue': 'FABRICA DI ROMA (VT)'
  },
  {
    'value': 'D453',
    'viewValue': 'FABRIZIA (VV)'
  },
  {
    'value': 'D454',
    'viewValue': 'FABRO (TR)'
  },
  {
    'value': 'D455',
    'viewValue': 'FAEDIS (UD)'
  },
  {
    'value': 'D457',
    'viewValue': 'FAEDO (TN)'
  },
  {
    'value': 'D456',
    'viewValue': 'FAEDO VALTELLINO (SO)'
  },
  {
    'value': 'D458',
    'viewValue': 'FAENZA (RA)'
  },
  {
    'value': 'D459',
    'viewValue': 'FAETO (FG)'
  },
  {
    'value': 'D461',
    'viewValue': 'FAGAGNA (UD)'
  },
  {
    'value': 'D462',
    'viewValue': 'FAGGETO LARIO (CO)'
  },
  {
    'value': 'D463',
    'viewValue': 'FAGGIANO (TA)'
  },
  {
    'value': 'D465',
    'viewValue': 'FAGNANO ALTO (AQ)'
  },
  {
    'value': 'D464',
    'viewValue': 'FAGNANO CASTELLO (CS)'
  },
  {
    'value': 'D467',
    'viewValue': 'FAGNANO OLONA (VA)'
  },
  {
    'value': 'D468',
    'viewValue': 'FAI DELLA PAGANELLA (TN)'
  },
  {
    'value': 'D469',
    'viewValue': 'FAICCHIO (BN)'
  },
  {
    'value': 'D470',
    'viewValue': 'FALCADE (BL)'
  },
  {
    'value': 'D471',
    'viewValue': 'FALCIANO DEL MASSICO (CE)'
  },
  {
    'value': 'D473',
    'viewValue': 'FALCONARA ALBANESE (CS)'
  },
  {
    'value': 'D472',
    'viewValue': 'FALCONARA MARITTIMA (AN)'
  },
  {
    'value': 'D474',
    'viewValue': 'FALCONE (ME)'
  },
  {
    'value': 'D475',
    'viewValue': 'FALERIA (VT)'
  },
  {
    'value': 'D476',
    'viewValue': 'FALERNA (CZ)'
  },
  {
    'value': 'D477',
    'viewValue': 'FALERONE (AP)'
  },
  {
    'value': 'D480',
    'viewValue': 'FALLO (CH)'
  },
  {
    'value': 'D481',
    'viewValue': 'FALMENTA (VB)'
  },
  {
    'value': 'D482',
    'viewValue': 'FALOPPIO (CO)'
  },
  {
    'value': 'D483',
    'viewValue': 'FALVATERRA (FR)'
  },
  {
    'value': 'D484',
    'viewValue': 'FALZES .PFALZEN. (BZ)'
  },
  {
    'value': 'D486',
    'viewValue': 'FANANO (MO)'
  },
  {
    'value': 'D487',
    'viewValue': 'FANNA (PN)'
  },
  {
    'value': 'D488',
    'viewValue': 'FANO (PU)'
  },
  {
    'value': 'D489',
    'viewValue': 'FANO ADRIANO (TE)'
  },
  {
    'value': 'D494',
    'viewValue': 'FARA FILIORUM PETRI (CH)'
  },
  {
    'value': 'D490',
    'viewValue': "FARA GERA D'ADDA (BG)"
  },
  {
    'value': 'D493',
    'viewValue': 'FARA IN SABINA (RI)'
  },
  {
    'value': 'D492',
    'viewValue': 'FARA NOVARESE (NO)'
  },
  {
    'value': 'D491',
    'viewValue': 'FARA OLIVANA CON SOLA (BG)'
  },
  {
    'value': 'D495',
    'viewValue': 'FARA SAN MARTINO (CH)'
  },
  {
    'value': 'D496',
    'viewValue': 'FARA VICENTINO (VI)'
  },
  {
    'value': 'D497',
    'viewValue': 'FARDELLA (PZ)'
  },
  {
    'value': 'D499',
    'viewValue': 'FARIGLIANO (CN)'
  },
  {
    'value': 'D501',
    'viewValue': 'FARINDOLA (PE)'
  },
  {
    'value': 'D502',
    'viewValue': 'FARINI (PC)'
  },
  {
    'value': 'D503',
    'viewValue': 'FARNESE (VT)'
  },
  {
    'value': 'D506',
    'viewValue': "FARRA D'ALPAGO (BL)"
  },
  {
    'value': 'D505',
    'viewValue': 'FARRA DI SOLIGO (TV)'
  },
  {
    'value': 'D504',
    'viewValue': "FARRA D'ISONZO (GO)"
  },
  {
    'value': 'D508',
    'viewValue': 'FASANO (BR)'
  },
  {
    'value': 'D509',
    'viewValue': 'FASCIA (GE)'
  },
  {
    'value': 'D510',
    'viewValue': 'FAUGLIA (PI)'
  },
  {
    'value': 'D511',
    'viewValue': 'FAULE (CN)'
  },
  {
    'value': 'D512',
    'viewValue': 'FAVALE DI MALVARO (GE)'
  },
  {
    'value': 'D514',
    'viewValue': 'FAVARA (AG)'
  },
  {
    'value': 'D516',
    'viewValue': 'FAVER (TN)'
  },
  {
    'value': 'D518',
    'viewValue': 'FAVIGNANA (TP)'
  },
  {
    'value': 'D520',
    'viewValue': 'FAVRIA (TO)'
  },
  {
    'value': 'D523',
    'viewValue': 'FEISOGLIO (CN)'
  },
  {
    'value': 'D524',
    'viewValue': 'FELETTO (TO)'
  },
  {
    'value': 'D526',
    'viewValue': 'FELINO (PR)'
  },
  {
    'value': 'D527',
    'viewValue': 'FELITTO (SA)'
  },
  {
    'value': 'D528',
    'viewValue': 'FELIZZANO (AL)'
  },
  {
    'value': 'D529',
    'viewValue': 'FELONICA (MN)'
  },
  {
    'value': 'D530',
    'viewValue': 'FELTRE (BL)'
  },
  {
    'value': 'D531',
    'viewValue': "FENEGRO' (CO)"
  },
  {
    'value': 'D532',
    'viewValue': 'FENESTRELLE (TO)'
  },
  {
    'value': 'D537',
    'viewValue': 'FENIS (AO)'
  },
  {
    'value': 'D538',
    'viewValue': 'FERENTILLO (TR)'
  },
  {
    'value': 'D539',
    'viewValue': 'FERENTINO (FR)'
  },
  {
    'value': 'D540',
    'viewValue': 'FERLA (SR)'
  },
  {
    'value': 'D541',
    'viewValue': 'FERMIGNANO (PU)'
  },
  {
    'value': 'D542',
    'viewValue': 'FERMO (AP)'
  },
  {
    'value': 'D543',
    'viewValue': 'FERNO (VA)'
  },
  {
    'value': 'D544',
    'viewValue': 'FEROLETO ANTICO (CZ)'
  },
  {
    'value': 'D545',
    'viewValue': 'FEROLETO DELLA CHIESA (RC)'
  },
  {
    'value': 'D547',
    'viewValue': 'FERRANDINA (MT)'
  },
  {
    'value': 'D548',
    'viewValue': 'FERRARA (FE)'
  },
  {
    'value': 'D549',
    'viewValue': 'FERRARA DI MONTE BALDO (VR)'
  },
  {
    'value': 'D550',
    'viewValue': 'FERRAZZANO (CB)'
  },
  {
    'value': 'D551',
    'viewValue': 'FERRERA DI VARESE (VA)'
  },
  {
    'value': 'D552',
    'viewValue': 'FERRERA ERBOGNONE (PV)'
  },
  {
    'value': 'D554',
    'viewValue': 'FERRERE (AT)'
  },
  {
    'value': 'D555',
    'viewValue': 'FERRIERE (PC)'
  },
  {
    'value': 'D557',
    'viewValue': 'FERRUZZANO (RC)'
  },
  {
    'value': 'D560',
    'viewValue': 'FIAMIGNANO (RI)'
  },
  {
    'value': 'D562',
    'viewValue': 'FIANO (TO)'
  },
  {
    'value': 'D561',
    'viewValue': 'FIANO ROMANO (RM)'
  },
  {
    'value': 'D564',
    'viewValue': 'FIASTRA (MC)'
  },
  {
    'value': 'D565',
    'viewValue': "FIAVE' (TN)"
  },
  {
    'value': 'D567',
    'viewValue': 'FICARAZZI (PA)'
  },
  {
    'value': 'D568',
    'viewValue': 'FICAROLO (RO)'
  },
  {
    'value': 'D569',
    'viewValue': 'FICARRA (ME)'
  },
  {
    'value': 'D570',
    'viewValue': 'FICULLE (TR)'
  },
  {
    'value': 'B034',
    'viewValue': 'FIDENZA (PR)'
  },
  {
    'value': 'D571',
    'viewValue': "FIE' ALLO SCILIAR .VOLS AM SCHLERN. (BZ)"
  },
  {
    'value': 'D572',
    'viewValue': 'FIERA DI PRIMIERO (TN)'
  },
  {
    'value': 'D573',
    'viewValue': 'FIEROZZO (TN)'
  },
  {
    'value': 'D574',
    'viewValue': 'FIESCO (CR)'
  },
  {
    'value': 'D575',
    'viewValue': 'FIESOLE (FI)'
  },
  {
    'value': 'D576',
    'viewValue': 'FIESSE (BS)'
  },
  {
    'value': 'D578',
    'viewValue': "FIESSO D'ARTICO (VE)"
  },
  {
    'value': 'D577',
    'viewValue': 'FIESSO UMBERTIANO (RO)'
  },
  {
    'value': 'D579',
    'viewValue': 'FIGINO SERENZA (CO)'
  },
  {
    'value': 'D583',
    'viewValue': 'FIGLINE VALDARNO (FI)'
  },
  {
    'value': 'D582',
    'viewValue': 'FIGLINE VEGLIATURO (CS)'
  },
  {
    'value': 'D586',
    'viewValue': 'FILACCIANO (RM)'
  },
  {
    'value': 'D587',
    'viewValue': 'FILADELFIA (VV)'
  },
  {
    'value': 'D588',
    'viewValue': 'FILAGO (BG)'
  },
  {
    'value': 'D589',
    'viewValue': 'FILANDARI (VV)'
  },
  {
    'value': 'D590',
    'viewValue': 'FILATTIERA (MS)'
  },
  {
    'value': 'D591',
    'viewValue': 'FILETTINO (FR)'
  },
  {
    'value': 'D592',
    'viewValue': 'FILETTO (CH)'
  },
  {
    'value': 'D593',
    'viewValue': 'FILIANO (PZ)'
  },
  {
    'value': 'D594',
    'viewValue': 'FILIGHERA (PV)'
  },
  {
    'value': 'D595',
    'viewValue': 'FILIGNANO (IS)'
  },
  {
    'value': 'D596',
    'viewValue': 'FILOGASO (VV)'
  },
  {
    'value': 'D597',
    'viewValue': 'FILOTTRANO (AN)'
  },
  {
    'value': 'D599',
    'viewValue': 'FINALE EMILIA (MO)'
  },
  {
    'value': 'D600',
    'viewValue': 'FINALE LIGURE (SV)'
  },
  {
    'value': 'D604',
    'viewValue': 'FINO DEL MONTE (BG)'
  },
  {
    'value': 'D605',
    'viewValue': 'FINO MORNASCO (CO)'
  },
  {
    'value': 'D606',
    'viewValue': 'FIORANO AL SERIO (BG)'
  },
  {
    'value': 'D608',
    'viewValue': 'FIORANO CANAVESE (TO)'
  },
  {
    'value': 'D607',
    'viewValue': 'FIORANO MODENESE (MO)'
  },
  {
    'value': 'D609',
    'viewValue': 'FIORDIMONTE (MC)'
  },
  {
    'value': 'D611',
    'viewValue': "FIORENZUOLA D'ARDA (PC)"
  },
  {
    'value': 'D612',
    'viewValue': 'FIRENZE (FI)'
  },
  {
    'value': 'D613',
    'viewValue': 'FIRENZUOLA (FI)'
  },
  {
    'value': 'D614',
    'viewValue': 'FIRMO (CS)'
  },
  {
    'value': 'D615',
    'viewValue': 'FISCIANO (SA)'
  },
  {
    'value': 'A310',
    'viewValue': 'FIUGGI (FR)'
  },
  {
    'value': 'D617',
    'viewValue': 'FIUMALBO (MO)'
  },
  {
    'value': 'D619',
    'viewValue': 'FIUMARA (RC)'
  },
  {
    'value': 'D621',
    'viewValue': 'FIUME VENETO (PN)'
  },
  {
    'value': 'D622',
    'viewValue': 'FIUMEDINISI (ME)'
  },
  {
    'value': 'D624',
    'viewValue': 'FIUMEFREDDO BRUZIO (CS)'
  },
  {
    'value': 'D623',
    'viewValue': 'FIUMEFREDDO DI SICILIA (CT)'
  },
  {
    'value': 'D627',
    'viewValue': 'FIUMICELLO (UD)'
  },
  {
    'value': 'M297',
    'viewValue': 'FIUMICINO (RM)'
  },
  {
    'value': 'D628',
    'viewValue': 'FIUMINATA (MC)'
  },
  {
    'value': 'D629',
    'viewValue': 'FIVIZZANO (MS)'
  },
  {
    'value': 'D630',
    'viewValue': 'FLAIBANO (UD)'
  },
  {
    'value': 'D631',
    'viewValue': 'FLAVON (TN)'
  },
  {
    'value': 'D634',
    'viewValue': 'FLERO (BS)'
  },
  {
    'value': 'D635',
    'viewValue': 'FLORESTA (ME)'
  },
  {
    'value': 'D636',
    'viewValue': 'FLORIDIA (SR)'
  },
  {
    'value': 'D637',
    'viewValue': 'FLORINAS (SS)'
  },
  {
    'value': 'D638',
    'viewValue': 'FLUMERI (AV)'
  },
  {
    'value': 'D639',
    'viewValue': 'FLUMINIMAGGIORE (CA)'
  },
  {
    'value': 'D640',
    'viewValue': 'FLUSSIO (NU)'
  },
  {
    'value': 'D641',
    'viewValue': 'FOBELLO (VC)'
  },
  {
    'value': 'D643',
    'viewValue': 'FOGGIA (FG)'
  },
  {
    'value': 'D644',
    'viewValue': 'FOGLIANISE (BN)'
  },
  {
    'value': 'D645',
    'viewValue': 'FOGLIANO REDIPUGLIA (GO)'
  },
  {
    'value': 'D646',
    'viewValue': 'FOGLIZZO (TO)'
  },
  {
    'value': 'D649',
    'viewValue': 'FOIANO DELLA CHIANA (AR)'
  },
  {
    'value': 'D650',
    'viewValue': 'FOIANO DI VAL FORTORE (BN)'
  },
  {
    'value': 'D651',
    'viewValue': 'FOLGARIA (TN)'
  },
  {
    'value': 'D652',
    'viewValue': 'FOLIGNANO (AP)'
  },
  {
    'value': 'D653',
    'viewValue': 'FOLIGNO (PG)'
  },
  {
    'value': 'D654',
    'viewValue': 'FOLLINA (TV)'
  },
  {
    'value': 'D655',
    'viewValue': 'FOLLO (SP)'
  },
  {
    'value': 'D656',
    'viewValue': 'FOLLONICA (GR)'
  },
  {
    'value': 'D660',
    'viewValue': 'FOMBIO (LO)'
  },
  {
    'value': 'D661',
    'viewValue': 'FONDACHELLI-FANTINA (ME)'
  },
  {
    'value': 'D662',
    'viewValue': 'FONDI (LT)'
  },
  {
    'value': 'D663',
    'viewValue': 'FONDO (TN)'
  },
  {
    'value': 'D665',
    'viewValue': 'FONNI (NU)'
  },
  {
    'value': 'D666',
    'viewValue': 'FONTAINEMORE (AO)'
  },
  {
    'value': 'D667',
    'viewValue': 'FONTANA LIRI (FR)'
  },
  {
    'value': 'D670',
    'viewValue': 'FONTANAFREDDA (PN)'
  },
  {
    'value': 'D671',
    'viewValue': 'FONTANAROSA (AV)'
  },
  {
    'value': 'D668',
    'viewValue': 'FONTANELICE (BO)'
  },
  {
    'value': 'D672',
    'viewValue': 'FONTANELLA (BG)'
  },
  {
    'value': 'D673',
    'viewValue': 'FONTANELLATO (PR)'
  },
  {
    'value': 'D674',
    'viewValue': 'FONTANELLE (TV)'
  },
  {
    'value': 'D675',
    'viewValue': "FONTANETO D'AGOGNA (NO)"
  },
  {
    'value': 'D676',
    'viewValue': 'FONTANETTO PO (VC)'
  },
  {
    'value': 'D677',
    'viewValue': 'FONTANIGORDA (GE)'
  },
  {
    'value': 'D678',
    'viewValue': 'FONTANILE (AT)'
  },
  {
    'value': 'D679',
    'viewValue': 'FONTANIVA (PD)'
  },
  {
    'value': 'D680',
    'viewValue': 'FONTE (TV)'
  },
  {
    'value': 'M309',
    'viewValue': 'FONTE NUOVA (RM)'
  },
  {
    'value': 'D681',
    'viewValue': 'FONTECCHIO (AQ)'
  },
  {
    'value': 'D682',
    'viewValue': 'FONTECHIARI (FR)'
  },
  {
    'value': 'D683',
    'viewValue': 'FONTEGRECA (CE)'
  },
  {
    'value': 'D684',
    'viewValue': 'FONTENO (BG)'
  },
  {
    'value': 'D685',
    'viewValue': 'FONTEVIVO (PR)'
  },
  {
    'value': 'D686',
    'viewValue': 'FONZASO (BL)'
  },
  {
    'value': 'D688',
    'viewValue': 'FOPPOLO (BG)'
  },
  {
    'value': 'D689',
    'viewValue': 'FORANO (RI)'
  },
  {
    'value': 'D691',
    'viewValue': 'FORCE (AP)'
  },
  {
    'value': 'D693',
    'viewValue': 'FORCHIA (BN)'
  },
  {
    'value': 'D694',
    'viewValue': 'FORCOLA (SO)'
  },
  {
    'value': 'D695',
    'viewValue': 'FORDONGIANUS (OR)'
  },
  {
    'value': 'D696',
    'viewValue': 'FORENZA (PZ)'
  },
  {
    'value': 'D697',
    'viewValue': 'FORESTO SPARSO (BG)'
  },
  {
    'value': 'D700',
    'viewValue': 'FORGARIA NEL FRIULI (UD)'
  },
  {
    'value': 'D701',
    'viewValue': 'FORINO (AV)'
  },
  {
    'value': 'D702',
    'viewValue': 'FORIO (NA)'
  },
  {
    'value': 'D704',
    'viewValue': "FORLI' (FC)"
  },
  {
    'value': 'D703',
    'viewValue': "FORLI' DEL SANNIO (IS)"
  },
  {
    'value': 'D705',
    'viewValue': 'FORLIMPOPOLI (FC)'
  },
  {
    'value': 'D706',
    'viewValue': 'FORMAZZA (VB)'
  },
  {
    'value': 'D707',
    'viewValue': 'FORMELLO (RM)'
  },
  {
    'value': 'D708',
    'viewValue': 'FORMIA (LT)'
  },
  {
    'value': 'D709',
    'viewValue': 'FORMICOLA (CE)'
  },
  {
    'value': 'D710',
    'viewValue': 'FORMIGARA (CR)'
  },
  {
    'value': 'D711',
    'viewValue': 'FORMIGINE (MO)'
  },
  {
    'value': 'D712',
    'viewValue': 'FORMIGLIANA (VC)'
  },
  {
    'value': 'D713',
    'viewValue': 'FORMIGNANA (FE)'
  },
  {
    'value': 'D714',
    'viewValue': 'FORNACE (TN)'
  },
  {
    'value': 'D715',
    'viewValue': 'FORNELLI (IS)'
  },
  {
    'value': 'D718',
    'viewValue': 'FORNI AVOLTRI (UD)'
  },
  {
    'value': 'D719',
    'viewValue': 'FORNI DI SOPRA (UD)'
  },
  {
    'value': 'D720',
    'viewValue': 'FORNI DI SOTTO (UD)'
  },
  {
    'value': 'D725',
    'viewValue': 'FORNO CANAVESE (TO)'
  },
  {
    'value': 'D726',
    'viewValue': 'FORNO DI ZOLDO (BL)'
  },
  {
    'value': 'D728',
    'viewValue': 'FORNOVO DI TARO (PR)'
  },
  {
    'value': 'D727',
    'viewValue': 'FORNOVO SAN GIOVANNI (BG)'
  },
  {
    'value': 'D730',
    'viewValue': 'FORTE DEI MARMI (LU)'
  },
  {
    'value': 'D731',
    'viewValue': 'FORTEZZA .FRANZENSFESTEN. (BZ)'
  },
  {
    'value': 'D732',
    'viewValue': 'FORTUNAGO (PV)'
  },
  {
    'value': 'D733',
    'viewValue': "FORZA D'AGRO' (ME)"
  },
  {
    'value': 'D734',
    'viewValue': 'FOSCIANDORA (LU)'
  },
  {
    'value': 'D735',
    'viewValue': 'FOSDINOVO (MS)'
  },
  {
    'value': 'D736',
    'viewValue': 'FOSSA (AQ)'
  },
  {
    'value': 'D738',
    'viewValue': 'FOSSACESIA (CH)'
  },
  {
    'value': 'D740',
    'viewValue': 'FOSSALTA DI PIAVE (VE)'
  },
  {
    'value': 'D741',
    'viewValue': 'FOSSALTA DI PORTOGRUARO (VE)'
  },
  {
    'value': 'D737',
    'viewValue': 'FOSSALTO (CB)'
  },
  {
    'value': 'D742',
    'viewValue': 'FOSSANO (CN)'
  },
  {
    'value': 'D745',
    'viewValue': 'FOSSATO DI VICO (PG)'
  },
  {
    'value': 'D744',
    'viewValue': 'FOSSATO SERRALTA (CZ)'
  },
  {
    'value': 'D748',
    'viewValue': "FOSSO' (VE)"
  },
  {
    'value': 'D749',
    'viewValue': 'FOSSOMBRONE (PU)'
  },
  {
    'value': 'D750',
    'viewValue': 'FOZA (VI)'
  },
  {
    'value': 'D751',
    'viewValue': 'FRABOSA SOPRANA (CN)'
  },
  {
    'value': 'D752',
    'viewValue': 'FRABOSA SOTTANA (CN)'
  },
  {
    'value': 'D559',
    'viewValue': 'FRACONALTO (AL)'
  },
  {
    'value': 'D754',
    'viewValue': 'FRAGAGNANO (TA)'
  },
  {
    'value': 'D755',
    'viewValue': "FRAGNETO L'ABATE (BN)"
  },
  {
    'value': 'D756',
    'viewValue': 'FRAGNETO MONFORTE (BN)'
  },
  {
    'value': 'D757',
    'viewValue': 'FRAINE (CH)'
  },
  {
    'value': 'D758',
    'viewValue': 'FRAMURA (SP)'
  },
  {
    'value': 'D763',
    'viewValue': 'FRANCAVILLA AL MARE (CH)'
  },
  {
    'value': 'D762',
    'viewValue': 'FRANCAVILLA ANGITOLA (VV)'
  },
  {
    'value': 'D759',
    'viewValue': 'FRANCAVILLA BISIO (AL)'
  },
  {
    'value': 'D760',
    'viewValue': "FRANCAVILLA D'ETE (AP)"
  },
  {
    'value': 'D765',
    'viewValue': 'FRANCAVILLA DI SICILIA (ME)'
  },
  {
    'value': 'D761',
    'viewValue': 'FRANCAVILLA FONTANA (BR)'
  },
  {
    'value': 'D766',
    'viewValue': 'FRANCAVILLA IN SINNI (PZ)'
  },
  {
    'value': 'D764',
    'viewValue': 'FRANCAVILLA MARITTIMA (CS)'
  },
  {
    'value': 'D767',
    'viewValue': 'FRANCICA (VV)'
  },
  {
    'value': 'D768',
    'viewValue': 'FRANCOFONTE (SR)'
  },
  {
    'value': 'D769',
    'viewValue': 'FRANCOLISE (CE)'
  },
  {
    'value': 'D770',
    'viewValue': 'FRASCARO (AL)'
  },
  {
    'value': 'D771',
    'viewValue': 'FRASCAROLO (PV)'
  },
  {
    'value': 'D773',
    'viewValue': 'FRASCATI (RM)'
  },
  {
    'value': 'D774',
    'viewValue': 'FRASCINETO (CS)'
  },
  {
    'value': 'D775',
    'viewValue': 'FRASSILONGO (TN)'
  },
  {
    'value': 'D776',
    'viewValue': 'FRASSINELLE POLESINE (RO)'
  },
  {
    'value': 'D777',
    'viewValue': 'FRASSINELLO MONFERRATO (AL)'
  },
  {
    'value': 'D780',
    'viewValue': 'FRASSINETO PO (AL)'
  },
  {
    'value': 'D781',
    'viewValue': 'FRASSINETTO (TO)'
  },
  {
    'value': 'D782',
    'viewValue': 'FRASSINO (CN)'
  },
  {
    'value': 'D783',
    'viewValue': 'FRASSINORO (MO)'
  },
  {
    'value': 'D785',
    'viewValue': 'FRASSO SABINO (RI)'
  },
  {
    'value': 'D784',
    'viewValue': 'FRASSO TELESINO (BN)'
  },
  {
    'value': 'D788',
    'viewValue': 'FRATTA POLESINE (RO)'
  },
  {
    'value': 'D787',
    'viewValue': 'FRATTA TODINA (PG)'
  },
  {
    'value': 'D789',
    'viewValue': 'FRATTAMAGGIORE (NA)'
  },
  {
    'value': 'D790',
    'viewValue': 'FRATTAMINORE (NA)'
  },
  {
    'value': 'D791',
    'viewValue': 'FRATTE ROSA (PU)'
  },
  {
    'value': 'D793',
    'viewValue': "FRAZZANO' (ME)"
  },
  {
    'value': 'D794',
    'viewValue': 'FREGONA (TV)'
  },
  {
    'value': 'D796',
    'viewValue': 'FRESAGRANDINARIA (CH)'
  },
  {
    'value': 'D797',
    'viewValue': 'FRESONARA (AL)'
  },
  {
    'value': 'D798',
    'viewValue': 'FRIGENTO (AV)'
  },
  {
    'value': 'D799',
    'viewValue': 'FRIGNANO (CE)'
  },
  {
    'value': 'D800',
    'viewValue': 'FRIGNANO MAGGIORE (CE)'
  },
  {
    'value': 'D802',
    'viewValue': 'FRINCO (AT)'
  },
  {
    'value': 'D803',
    'viewValue': 'FRISA (CH)'
  },
  {
    'value': 'D804',
    'viewValue': 'FRISANCO (PN)'
  },
  {
    'value': 'D805',
    'viewValue': 'FRONT (TO)'
  },
  {
    'value': 'D807',
    'viewValue': 'FRONTINO (PU)'
  },
  {
    'value': 'D808',
    'viewValue': 'FRONTONE (PU)'
  },
  {
    'value': 'D810',
    'viewValue': 'FROSINONE (FR)'
  },
  {
    'value': 'D811',
    'viewValue': 'FROSOLONE (IS)'
  },
  {
    'value': 'D812',
    'viewValue': 'FROSSASCO (TO)'
  },
  {
    'value': 'D813',
    'viewValue': 'FRUGAROLO (AL)'
  },
  {
    'value': 'D814',
    'viewValue': 'FUBINE (AL)'
  },
  {
    'value': 'D815',
    'viewValue': 'FUCECCHIO (FI)'
  },
  {
    'value': 'D817',
    'viewValue': 'FUIPIANO VALLE IMAGNA (BG)'
  },
  {
    'value': 'D818',
    'viewValue': 'FUMANE (VR)'
  },
  {
    'value': 'D819',
    'viewValue': 'FUMONE (FR)'
  },
  {
    'value': 'D821',
    'viewValue': 'FUNES .VILLNOSS. (BZ)'
  },
  {
    'value': 'D823',
    'viewValue': 'FURCI (CH)'
  },
  {
    'value': 'D824',
    'viewValue': 'FURCI SICULO (ME)'
  },
  {
    'value': 'D825',
    'viewValue': 'FURNARI (ME)'
  },
  {
    'value': 'D826',
    'viewValue': 'FURORE (SA)'
  },
  {
    'value': 'D827',
    'viewValue': 'FURTEI (CA)'
  },
  {
    'value': 'D828',
    'viewValue': 'FUSCALDO (CS)'
  },
  {
    'value': 'D829',
    'viewValue': 'FUSIGNANO (RA)'
  },
  {
    'value': 'D830',
    'viewValue': 'FUSINE (SO)'
  },
  {
    'value': 'D832',
    'viewValue': 'FUTANI (SA)'
  },
  {
    'value': 'D834',
    'viewValue': 'GABBIONETA BINANUOVA (CR)'
  },
  {
    'value': 'D835',
    'viewValue': 'GABIANO (AL)'
  },
  {
    'value': 'D836',
    'viewValue': 'GABICCE MARE (PU)'
  },
  {
    'value': 'D839',
    'viewValue': 'GABY (AO)'
  },
  {
    'value': 'D841',
    'viewValue': 'GADESCO PIEVE DELMONA (CR)'
  },
  {
    'value': 'D842',
    'viewValue': 'GADONI (NU)'
  },
  {
    'value': 'D843',
    'viewValue': 'GAETA (LT)'
  },
  {
    'value': 'D844',
    'viewValue': 'GAGGI (ME)'
  },
  {
    'value': 'D845',
    'viewValue': 'GAGGIANO (MI)'
  },
  {
    'value': 'D847',
    'viewValue': 'GAGGIO MONTANO (BO)'
  },
  {
    'value': 'D848',
    'viewValue': 'GAGLIANICO (BI)'
  },
  {
    'value': 'D850',
    'viewValue': 'GAGLIANO ATERNO (AQ)'
  },
  {
    'value': 'D849',
    'viewValue': 'GAGLIANO CASTELFERRATO (EN)'
  },
  {
    'value': 'D851',
    'viewValue': 'GAGLIANO DEL CAPO (LE)'
  },
  {
    'value': 'D852',
    'viewValue': 'GAGLIATO (CZ)'
  },
  {
    'value': 'D853',
    'viewValue': 'GAGLIOLE (MC)'
  },
  {
    'value': 'D854',
    'viewValue': 'GAIARINE (TV)'
  },
  {
    'value': 'D855',
    'viewValue': 'GAIBA (RO)'
  },
  {
    'value': 'D856',
    'viewValue': 'GAIOLA (CN)'
  },
  {
    'value': 'D858',
    'viewValue': 'GAIOLE IN CHIANTI (SI)'
  },
  {
    'value': 'D859',
    'viewValue': 'GAIRO (NU)'
  },
  {
    'value': 'D860',
    'viewValue': 'GAIS .GAIS. (BZ)'
  },
  {
    'value': 'D861',
    'viewValue': 'GALATI MAMERTINO (ME)'
  },
  {
    'value': 'D862',
    'viewValue': 'GALATINA (LE)'
  },
  {
    'value': 'D863',
    'viewValue': 'GALATONE (LE)'
  },
  {
    'value': 'D864',
    'viewValue': 'GALATRO (RC)'
  },
  {
    'value': 'D865',
    'viewValue': 'GALBIATE (LC)'
  },
  {
    'value': 'D867',
    'viewValue': 'GALEATA (FC)'
  },
  {
    'value': 'D868',
    'viewValue': 'GALGAGNANO (LO)'
  },
  {
    'value': 'D869',
    'viewValue': 'GALLARATE (VA)'
  },
  {
    'value': 'D870',
    'viewValue': 'GALLESE (VT)'
  },
  {
    'value': 'D872',
    'viewValue': 'GALLIATE (NO)'
  },
  {
    'value': 'D871',
    'viewValue': 'GALLIATE LOMBARDO (VA)'
  },
  {
    'value': 'D873',
    'viewValue': 'GALLIAVOLA (PV)'
  },
  {
    'value': 'D874',
    'viewValue': 'GALLICANO (LU)'
  },
  {
    'value': 'D875',
    'viewValue': 'GALLICANO NEL LAZIO (RM)'
  },
  {
    'value': 'D876',
    'viewValue': 'GALLICCHIO (PZ)'
  },
  {
    'value': 'D878',
    'viewValue': 'GALLIERA (BO)'
  },
  {
    'value': 'D879',
    'viewValue': 'GALLIERA VENETA (PD)'
  },
  {
    'value': 'D881',
    'viewValue': 'GALLINARO (FR)'
  },
  {
    'value': 'D882',
    'viewValue': 'GALLIO (VI)'
  },
  {
    'value': 'D883',
    'viewValue': 'GALLIPOLI (LE)'
  },
  {
    'value': 'D884',
    'viewValue': 'GALLO MATESE (CE)'
  },
  {
    'value': 'D885',
    'viewValue': 'GALLODORO (ME)'
  },
  {
    'value': 'D886',
    'viewValue': 'GALLUCCIO (CE)'
  },
  {
    'value': 'D888',
    'viewValue': 'GALTELLI (NU)'
  },
  {
    'value': 'D889',
    'viewValue': 'GALZIGNANO TERME (PD)'
  },
  {
    'value': 'D890',
    'viewValue': 'GAMALERO (AL)'
  },
  {
    'value': 'D891',
    'viewValue': 'GAMBARA (BS)'
  },
  {
    'value': 'D892',
    'viewValue': 'GAMBARANA (PV)'
  },
  {
    'value': 'D894',
    'viewValue': 'GAMBASCA (CN)'
  },
  {
    'value': 'D895',
    'viewValue': 'GAMBASSI TERME (FI)'
  },
  {
    'value': 'D896',
    'viewValue': 'GAMBATESA (CB)'
  },
  {
    'value': 'D897',
    'viewValue': 'GAMBELLARA (VI)'
  },
  {
    'value': 'D898',
    'viewValue': 'GAMBERALE (CH)'
  },
  {
    'value': 'D899',
    'viewValue': 'GAMBETTOLA (FC)'
  },
  {
    'value': 'D901',
    'viewValue': "GAMBOLO' (PV)"
  },
  {
    'value': 'D902',
    'viewValue': 'GAMBUGLIANO (VI)'
  },
  {
    'value': 'D903',
    'viewValue': 'GANDELLINO (BG)'
  },
  {
    'value': 'D905',
    'viewValue': 'GANDINO (BG)'
  },
  {
    'value': 'D906',
    'viewValue': 'GANDOSSO (BG)'
  },
  {
    'value': 'D907',
    'viewValue': 'GANGI (PA)'
  },
  {
    'value': 'D909',
    'viewValue': 'GARAGUSO (MT)'
  },
  {
    'value': 'D910',
    'viewValue': 'GARBAGNA (AL)'
  },
  {
    'value': 'D911',
    'viewValue': 'GARBAGNA NOVARESE (NO)'
  },
  {
    'value': 'D912',
    'viewValue': 'GARBAGNATE MILANESE (MI)'
  },
  {
    'value': 'D913',
    'viewValue': 'GARBAGNATE MONASTERO (LC)'
  },
  {
    'value': 'D915',
    'viewValue': 'GARDA (VR)'
  },
  {
    'value': 'D917',
    'viewValue': 'GARDONE RIVIERA (BS)'
  },
  {
    'value': 'D918',
    'viewValue': 'GARDONE VAL TROMPIA (BS)'
  },
  {
    'value': 'D920',
    'viewValue': 'GARESSIO (CN)'
  },
  {
    'value': 'D921',
    'viewValue': 'GARGALLO (NO)'
  },
  {
    'value': 'D923',
    'viewValue': 'GARGAZZONE .GARGAZON. (BZ)'
  },
  {
    'value': 'D924',
    'viewValue': 'GARGNANO (BS)'
  },
  {
    'value': 'D925',
    'viewValue': 'GARLASCO (PV)'
  },
  {
    'value': 'D926',
    'viewValue': 'GARLATE (LC)'
  },
  {
    'value': 'D927',
    'viewValue': 'GARLENDA (SV)'
  },
  {
    'value': 'D928',
    'viewValue': 'GARNIGA TERME (TN)'
  },
  {
    'value': 'D930',
    'viewValue': 'GARZENO (CO)'
  },
  {
    'value': 'D931',
    'viewValue': 'GARZIGLIANA (TO)'
  },
  {
    'value': 'D932',
    'viewValue': 'GASPERINA (CZ)'
  },
  {
    'value': 'D933',
    'viewValue': 'GASSINO TORINESE (TO)'
  },
  {
    'value': 'D934',
    'viewValue': 'GATTATICO (RE)'
  },
  {
    'value': 'D935',
    'viewValue': 'GATTEO (FC)'
  },
  {
    'value': 'D937',
    'viewValue': 'GATTICO (NO)'
  },
  {
    'value': 'D938',
    'viewValue': 'GATTINARA (VC)'
  },
  {
    'value': 'D940',
    'viewValue': 'GAVARDO (BS)'
  },
  {
    'value': 'D941',
    'viewValue': 'GAVAZZANA (AL)'
  },
  {
    'value': 'D942',
    'viewValue': 'GAVELLO (RO)'
  },
  {
    'value': 'D943',
    'viewValue': 'GAVERINA TERME (BG)'
  },
  {
    'value': 'D944',
    'viewValue': 'GAVI (AL)'
  },
  {
    'value': 'D945',
    'viewValue': 'GAVIGNANO (RM)'
  },
  {
    'value': 'D946',
    'viewValue': 'GAVIRATE (VA)'
  },
  {
    'value': 'D947',
    'viewValue': 'GAVOI (NU)'
  },
  {
    'value': 'D948',
    'viewValue': 'GAVORRANO (GR)'
  },
  {
    'value': 'D949',
    'viewValue': 'GAZOLDO DEGLI IPPOLITI (MN)'
  },
  {
    'value': 'D951',
    'viewValue': 'GAZZADA SCHIANNO (VA)'
  },
  {
    'value': 'D952',
    'viewValue': 'GAZZANIGA (BG)'
  },
  {
    'value': 'D956',
    'viewValue': 'GAZZO PADOVANO (PD)'
  },
  {
    'value': 'D957',
    'viewValue': 'GAZZO VERONESE (VR)'
  },
  {
    'value': 'D958',
    'viewValue': 'GAZZOLA (PC)'
  },
  {
    'value': 'D959',
    'viewValue': 'GAZZUOLO (MN)'
  },
  {
    'value': 'D960',
    'viewValue': 'GELA (CL)'
  },
  {
    'value': 'D961',
    'viewValue': 'GEMMANO (RN)'
  },
  {
    'value': 'D962',
    'viewValue': 'GEMONA DEL FRIULI (UD)'
  },
  {
    'value': 'D963',
    'viewValue': 'GEMONIO (VA)'
  },
  {
    'value': 'D964',
    'viewValue': 'GENAZZANO (RM)'
  },
  {
    'value': 'D965',
    'viewValue': 'GENGA (AN)'
  },
  {
    'value': 'D966',
    'viewValue': 'GENIVOLTA (CR)'
  },
  {
    'value': 'D967',
    'viewValue': 'GENOLA (CN)'
  },
  {
    'value': 'D968',
    'viewValue': 'GENONI (NU)'
  },
  {
    'value': 'D969',
    'viewValue': 'GENOVA (GE)'
  },
  {
    'value': 'D970',
    'viewValue': 'GENURI (CA)'
  },
  {
    'value': 'D971',
    'viewValue': 'GENZANO DI LUCANIA (PZ)'
  },
  {
    'value': 'D972',
    'viewValue': 'GENZANO DI ROMA (RM)'
  },
  {
    'value': 'D973',
    'viewValue': 'GENZONE (PV)'
  },
  {
    'value': 'D974',
    'viewValue': 'GERA LARIO (CO)'
  },
  {
    'value': 'D975',
    'viewValue': 'GERACE (RC)'
  },
  {
    'value': 'D977',
    'viewValue': 'GERACI SICULO (PA)'
  },
  {
    'value': 'D978',
    'viewValue': 'GERANO (RM)'
  },
  {
    'value': 'D980',
    'viewValue': 'GERENZAGO (PV)'
  },
  {
    'value': 'D981',
    'viewValue': 'GERENZANO (VA)'
  },
  {
    'value': 'D982',
    'viewValue': 'GERGEI (NU)'
  },
  {
    'value': 'D983',
    'viewValue': 'GERMAGNANO (TO)'
  },
  {
    'value': 'D984',
    'viewValue': 'GERMAGNO (VB)'
  },
  {
    'value': 'D986',
    'viewValue': 'GERMASINO (CO)'
  },
  {
    'value': 'D987',
    'viewValue': 'GERMIGNAGA (VA)'
  },
  {
    'value': 'D988',
    'viewValue': 'GEROCARNE (VV)'
  },
  {
    'value': 'D990',
    'viewValue': 'GEROLA ALTA (SO)'
  },
  {
    'value': 'D991',
    'viewValue': 'GEROSA (BG)'
  },
  {
    'value': 'D993',
    'viewValue': "GERRE DE' CAPRIOLI (CR)"
  },
  {
    'value': 'D994',
    'viewValue': 'GESICO (CA)'
  },
  {
    'value': 'D995',
    'viewValue': 'GESSATE (MI)'
  },
  {
    'value': 'D996',
    'viewValue': 'GESSOPALENA (CH)'
  },
  {
    'value': 'D997',
    'viewValue': 'GESTURI (CA)'
  },
  {
    'value': 'D998',
    'viewValue': 'GESUALDO (AV)'
  },
  {
    'value': 'D999',
    'viewValue': 'GHEDI (BS)'
  },
  {
    'value': 'E001',
    'viewValue': 'GHEMME (NO)'
  },
  {
    'value': 'E003',
    'viewValue': 'GHIFFA (VB)'
  },
  {
    'value': 'E004',
    'viewValue': 'GHILARZA (OR)'
  },
  {
    'value': 'E006',
    'viewValue': 'GHISALBA (BG)'
  },
  {
    'value': 'E007',
    'viewValue': 'GHISLARENGO (VC)'
  },
  {
    'value': 'E008',
    'viewValue': 'GIACCIANO CON BARUCHELLA (RO)'
  },
  {
    'value': 'E009',
    'viewValue': 'GIAGLIONE (TO)'
  },
  {
    'value': 'E010',
    'viewValue': 'GIANICO (BS)'
  },
  {
    'value': 'E012',
    'viewValue': "GIANO DELL'UMBRIA (PG)"
  },
  {
    'value': 'E011',
    'viewValue': 'GIANO VETUSTO (CE)'
  },
  {
    'value': 'E013',
    'viewValue': 'GIARDINELLO (PA)'
  },
  {
    'value': 'E014',
    'viewValue': 'GIARDINI-NAXOS (ME)'
  },
  {
    'value': 'E015',
    'viewValue': 'GIAROLE (AL)'
  },
  {
    'value': 'E016',
    'viewValue': 'GIARRATANA (RG)'
  },
  {
    'value': 'E017',
    'viewValue': 'GIARRE (CT)'
  },
  {
    'value': 'E019',
    'viewValue': 'GIAVE (SS)'
  },
  {
    'value': 'E020',
    'viewValue': 'GIAVENO (TO)'
  },
  {
    'value': 'E021',
    'viewValue': 'GIAVERA DEL MONTELLO (TV)'
  },
  {
    'value': 'E022',
    'viewValue': 'GIBA (CA)'
  },
  {
    'value': 'E023',
    'viewValue': 'GIBELLINA (TP)'
  },
  {
    'value': 'E024',
    'viewValue': 'GIFFLENGA (BI)'
  },
  {
    'value': 'E025',
    'viewValue': 'GIFFONE (RC)'
  },
  {
    'value': 'E026',
    'viewValue': 'GIFFONI SEI CASALI (SA)'
  },
  {
    'value': 'E027',
    'viewValue': 'GIFFONI VALLE PIANA (SA)'
  },
  {
    'value': 'E028',
    'viewValue': 'GIGNESE (VB)'
  },
  {
    'value': 'E029',
    'viewValue': 'GIGNOD (AO)'
  },
  {
    'value': 'E030',
    'viewValue': 'GILDONE (CB)'
  },
  {
    'value': 'E031',
    'viewValue': 'GIMIGLIANO (CZ)'
  },
  {
    'value': 'E033',
    'viewValue': 'GINESTRA (PZ)'
  },
  {
    'value': 'E034',
    'viewValue': 'GINESTRA DEGLI SCHIAVONI (BN)'
  },
  {
    'value': 'E036',
    'viewValue': 'GINOSA (TA)'
  },
  {
    'value': 'E037',
    'viewValue': 'GIOI (SA)'
  },
  {
    'value': 'E040',
    'viewValue': 'GIOIA DEI MARSI (AQ)'
  },
  {
    'value': 'E038',
    'viewValue': 'GIOIA DEL COLLE (BA)'
  },
  {
    'value': 'E039',
    'viewValue': 'GIOIA SANNITICA (CE)'
  },
  {
    'value': 'E041',
    'viewValue': 'GIOIA TAURO (RC)'
  },
  {
    'value': 'E044',
    'viewValue': 'GIOIOSA IONICA (RC)'
  },
  {
    'value': 'E043',
    'viewValue': 'GIOIOSA MAREA (ME)'
  },
  {
    'value': 'E045',
    'viewValue': 'GIOVE (TR)'
  },
  {
    'value': 'E047',
    'viewValue': 'GIOVINAZZO (BA)'
  },
  {
    'value': 'E048',
    'viewValue': 'GIOVO (TN)'
  },
  {
    'value': 'E049',
    'viewValue': 'GIRASOLE (NU)'
  },
  {
    'value': 'E050',
    'viewValue': 'GIRIFALCO (CZ)'
  },
  {
    'value': 'E051',
    'viewValue': 'GIRONICO (CO)'
  },
  {
    'value': 'E052',
    'viewValue': 'GISSI (CH)'
  },
  {
    'value': 'E053',
    'viewValue': 'GIUGGIANELLO (LE)'
  },
  {
    'value': 'E054',
    'viewValue': 'GIUGLIANO IN CAMPANIA (NA)'
  },
  {
    'value': 'E055',
    'viewValue': 'GIULIANA (PA)'
  },
  {
    'value': 'E057',
    'viewValue': 'GIULIANO DI ROMA (FR)'
  },
  {
    'value': 'E056',
    'viewValue': 'GIULIANO TEATINO (CH)'
  },
  {
    'value': 'E058',
    'viewValue': 'GIULIANOVA (TE)'
  },
  {
    'value': 'E059',
    'viewValue': 'GIUNCUGNANO (LU)'
  },
  {
    'value': 'E060',
    'viewValue': 'GIUNGANO (SA)'
  },
  {
    'value': 'E061',
    'viewValue': 'GIURDIGNANO (LE)'
  },
  {
    'value': 'E062',
    'viewValue': 'GIUSSAGO (PV)'
  },
  {
    'value': 'E063',
    'viewValue': 'GIUSSANO (MI)'
  },
  {
    'value': 'E064',
    'viewValue': 'GIUSTENICE (SV)'
  },
  {
    'value': 'E065',
    'viewValue': 'GIUSTINO (TN)'
  },
  {
    'value': 'E066',
    'viewValue': 'GIUSVALLA (SV)'
  },
  {
    'value': 'E067',
    'viewValue': 'GIVOLETTO (TO)'
  },
  {
    'value': 'E068',
    'viewValue': 'GIZZERIA (CZ)'
  },
  {
    'value': 'E069',
    'viewValue': 'GLORENZA .GLURNS. (BZ)'
  },
  {
    'value': 'E071',
    'viewValue': "GODEGA DI SANT'URBANO (TV)"
  },
  {
    'value': 'E072',
    'viewValue': 'GODIASCO (PV)'
  },
  {
    'value': 'E074',
    'viewValue': 'GODRANO (PA)'
  },
  {
    'value': 'E078',
    'viewValue': 'GOITO (MN)'
  },
  {
    'value': 'E079',
    'viewValue': 'GOLASECCA (VA)'
  },
  {
    'value': 'E080',
    'viewValue': 'GOLESE (PR)'
  },
  {
    'value': 'E081',
    'viewValue': 'GOLFERENZO (PV)'
  },
  {
    'value': 'M274',
    'viewValue': 'GOLFO ARANCI (SS)'
  },
  {
    'value': 'E082',
    'viewValue': 'GOMBITO (CR)'
  },
  {
    'value': 'E083',
    'viewValue': 'GONARS (UD)'
  },
  {
    'value': 'E084',
    'viewValue': 'GONI (CA)'
  },
  {
    'value': 'E086',
    'viewValue': 'GONNESA (CA)'
  },
  {
    'value': 'E087',
    'viewValue': 'GONNOSCODINA (OR)'
  },
  {
    'value': 'E085',
    'viewValue': 'GONNOSFANADIGA (CA)'
  },
  {
    'value': 'D585',
    'viewValue': "GONNOSNO' (OR)"
  },
  {
    'value': 'E088',
    'viewValue': 'GONNOSTRAMATZA (OR)'
  },
  {
    'value': 'E089',
    'viewValue': 'GONZAGA (MN)'
  },
  {
    'value': 'E090',
    'viewValue': 'GORDONA (SO)'
  },
  {
    'value': 'E091',
    'viewValue': 'GORGA (RM)'
  },
  {
    'value': 'E092',
    'viewValue': 'GORGO AL MONTICANO (TV)'
  },
  {
    'value': 'E093',
    'viewValue': 'GORGOGLIONE (MT)'
  },
  {
    'value': 'E094',
    'viewValue': 'GORGONZOLA (MI)'
  },
  {
    'value': 'E096',
    'viewValue': 'GORIANO SICOLI (AQ)'
  },
  {
    'value': 'E098',
    'viewValue': 'GORIZIA (GO)'
  },
  {
    'value': 'E101',
    'viewValue': 'GORLA MAGGIORE (VA)'
  },
  {
    'value': 'E102',
    'viewValue': 'GORLA MINORE (VA)'
  },
  {
    'value': 'E100',
    'viewValue': 'GORLAGO (BG)'
  },
  {
    'value': 'E103',
    'viewValue': 'GORLE (BG)'
  },
  {
    'value': 'E104',
    'viewValue': 'GORNATE-OLONA (VA)'
  },
  {
    'value': 'E106',
    'viewValue': 'GORNO (BG)'
  },
  {
    'value': 'E107',
    'viewValue': 'GORO (FE)'
  },
  {
    'value': 'E109',
    'viewValue': 'GORRETO (GE)'
  },
  {
    'value': 'E111',
    'viewValue': 'GORZEGNO (CN)'
  },
  {
    'value': 'E113',
    'viewValue': 'GOSALDO (BL)'
  },
  {
    'value': 'E114',
    'viewValue': 'GOSSOLENGO (PC)'
  },
  {
    'value': 'E115',
    'viewValue': 'GOTTASECCA (CN)'
  },
  {
    'value': 'E116',
    'viewValue': 'GOTTOLENGO (BS)'
  },
  {
    'value': 'E118',
    'viewValue': 'GOVONE (CN)'
  },
  {
    'value': 'E120',
    'viewValue': 'GOZZANO (NO)'
  },
  {
    'value': 'E122',
    'viewValue': 'GRADARA (PU)'
  },
  {
    'value': 'E124',
    'viewValue': "GRADISCA D'ISONZO (GO)"
  },
  {
    'value': 'E125',
    'viewValue': 'GRADO (GO)'
  },
  {
    'value': 'E126',
    'viewValue': 'GRADOLI (VT)'
  },
  {
    'value': 'E127',
    'viewValue': 'GRAFFIGNANA (LO)'
  },
  {
    'value': 'E128',
    'viewValue': 'GRAFFIGNANO (VT)'
  },
  {
    'value': 'E130',
    'viewValue': 'GRAGLIA (BI)'
  },
  {
    'value': 'E131',
    'viewValue': 'GRAGNANO (NA)'
  },
  {
    'value': 'E132',
    'viewValue': 'GRAGNANO TREBBIENSE (PC)'
  },
  {
    'value': 'E133',
    'viewValue': 'GRAMMICHELE (CT)'
  },
  {
    'value': 'E134',
    'viewValue': 'GRANA (AT)'
  },
  {
    'value': 'E135',
    'viewValue': 'GRANAGLIONE (BO)'
  },
  {
    'value': 'E136',
    'viewValue': "GRANAROLO DELL'EMILIA (BO)"
  },
  {
    'value': 'E138',
    'viewValue': 'GRANCONA (VI)'
  },
  {
    'value': 'E139',
    'viewValue': 'GRANDATE (CO)'
  },
  {
    'value': 'E141',
    'viewValue': 'GRANDOLA ED UNITI (CO)'
  },
  {
    'value': 'E142',
    'viewValue': 'GRANITI (ME)'
  },
  {
    'value': 'E143',
    'viewValue': 'GRANOZZO CON MONTICELLO (NO)'
  },
  {
    'value': 'E144',
    'viewValue': 'GRANTOLA (VA)'
  },
  {
    'value': 'E145',
    'viewValue': 'GRANTORTO (PD)'
  },
  {
    'value': 'E146',
    'viewValue': 'GRANZE (PD)'
  },
  {
    'value': 'E147',
    'viewValue': 'GRASSANO (MT)'
  },
  {
    'value': 'E148',
    'viewValue': 'GRASSOBBIO (BG)'
  },
  {
    'value': 'E149',
    'viewValue': 'GRATTERI (PA)'
  },
  {
    'value': 'E150',
    'viewValue': 'GRAUNO (TN)'
  },
  {
    'value': 'E151',
    'viewValue': 'GRAVEDONA (CO)'
  },
  {
    'value': 'E152',
    'viewValue': 'GRAVELLONA LOMELLINA (PV)'
  },
  {
    'value': 'E153',
    'viewValue': 'GRAVELLONA TOCE (VB)'
  },
  {
    'value': 'E154',
    'viewValue': 'GRAVERE (TO)'
  },
  {
    'value': 'E156',
    'viewValue': 'GRAVINA DI CATANIA (CT)'
  },
  {
    'value': 'E155',
    'viewValue': 'GRAVINA IN PUGLIA (BA)'
  },
  {
    'value': 'E158',
    'viewValue': 'GRAZZANISE (CE)'
  },
  {
    'value': 'E159',
    'viewValue': 'GRAZZANO BADOGLIO (AT)'
  },
  {
    'value': 'E160',
    'viewValue': 'GRECCIO (RI)'
  },
  {
    'value': 'E161',
    'viewValue': 'GRECI (AV)'
  },
  {
    'value': 'E163',
    'viewValue': 'GREGGIO (VC)'
  },
  {
    'value': 'E164',
    'viewValue': 'GREMIASCO (AL)'
  },
  {
    'value': 'E165',
    'viewValue': 'GRESSAN (AO)'
  },
  {
    'value': 'E167',
    'viewValue': "GRESSONEY-LA-TRINITE' (AO)"
  },
  {
    'value': 'E168',
    'viewValue': 'GRESSONEY-SAINT-JEAN (AO)'
  },
  {
    'value': 'E169',
    'viewValue': 'GREVE IN CHIANTI (FI)'
  },
  {
    'value': 'E170',
    'viewValue': 'GREZZAGO (MI)'
  },
  {
    'value': 'E171',
    'viewValue': 'GREZZANA (VR)'
  },
  {
    'value': 'E172',
    'viewValue': 'GRIANTE (CO)'
  },
  {
    'value': 'E173',
    'viewValue': 'GRICIGNANO DI AVERSA (CE)'
  },
  {
    'value': 'E177',
    'viewValue': 'GRIGNASCO (NO)'
  },
  {
    'value': 'E178',
    'viewValue': 'GRIGNO (TN)'
  },
  {
    'value': 'E179',
    'viewValue': 'GRIMACCO (UD)'
  },
  {
    'value': 'E180',
    'viewValue': 'GRIMALDI (CS)'
  },
  {
    'value': 'E182',
    'viewValue': 'GRINZANE CAVOUR (CN)'
  },
  {
    'value': 'E184',
    'viewValue': 'GRISIGNANO DI ZOCCO (VI)'
  },
  {
    'value': 'E185',
    'viewValue': 'GRISOLIA (CS)'
  },
  {
    'value': 'E187',
    'viewValue': 'GRIZZANA MORANDI (BO)'
  },
  {
    'value': 'E188',
    'viewValue': 'GROGNARDO (AL)'
  },
  {
    'value': 'E189',
    'viewValue': 'GROMO (BG)'
  },
  {
    'value': 'E191',
    'viewValue': 'GRONDONA (AL)'
  },
  {
    'value': 'E192',
    'viewValue': 'GRONE (BG)'
  },
  {
    'value': 'E193',
    'viewValue': 'GRONTARDO (CR)'
  },
  {
    'value': 'E195',
    'viewValue': 'GROPELLO CAIROLI (PV)'
  },
  {
    'value': 'E196',
    'viewValue': 'GROPPARELLO (PC)'
  },
  {
    'value': 'E199',
    'viewValue': 'GROSCAVALLO (TO)'
  },
  {
    'value': 'E200',
    'viewValue': 'GROSIO (SO)'
  },
  {
    'value': 'E201',
    'viewValue': 'GROSOTTO (SO)'
  },
  {
    'value': 'E202',
    'viewValue': 'GROSSETO (GR)'
  },
  {
    'value': 'E203',
    'viewValue': 'GROSSO (TO)'
  },
  {
    'value': 'E204',
    'viewValue': 'GROTTAFERRATA (RM)'
  },
  {
    'value': 'E205',
    'viewValue': 'GROTTAGLIE (TA)'
  },
  {
    'value': 'E206',
    'viewValue': 'GROTTAMINARDA (AV)'
  },
  {
    'value': 'E207',
    'viewValue': 'GROTTAMMARE (AP)'
  },
  {
    'value': 'E208',
    'viewValue': 'GROTTAZZOLINA (AP)'
  },
  {
    'value': 'E209',
    'viewValue': 'GROTTE (AG)'
  },
  {
    'value': 'E210',
    'viewValue': 'GROTTE DI CASTRO (VT)'
  },
  {
    'value': 'E212',
    'viewValue': 'GROTTERIA (RC)'
  },
  {
    'value': 'E213',
    'viewValue': 'GROTTOLE (MT)'
  },
  {
    'value': 'E214',
    'viewValue': 'GROTTOLELLA (AV)'
  },
  {
    'value': 'E215',
    'viewValue': 'GRUARO (VE)'
  },
  {
    'value': 'E216',
    'viewValue': 'GRUGLIASCO (TO)'
  },
  {
    'value': 'E217',
    'viewValue': 'GRUMELLO CREMONESE ED UNITI (CR)'
  },
  {
    'value': 'E219',
    'viewValue': 'GRUMELLO DEL MONTE (BG)'
  },
  {
    'value': 'E221',
    'viewValue': 'GRUMENTO NOVA (PZ)'
  },
  {
    'value': 'E222',
    'viewValue': 'GRUMES (TN)'
  },
  {
    'value': 'E223',
    'viewValue': 'GRUMO APPULA (BA)'
  },
  {
    'value': 'E224',
    'viewValue': 'GRUMO NEVANO (NA)'
  },
  {
    'value': 'E226',
    'viewValue': 'GRUMOLO DELLE ABBADESSE (VI)'
  },
  {
    'value': 'E227',
    'viewValue': 'GUAGNANO (LE)'
  },
  {
    'value': 'E228',
    'viewValue': 'GUALDO (MC)'
  },
  {
    'value': 'E229',
    'viewValue': 'GUALDO CATTANEO (PG)'
  },
  {
    'value': 'E230',
    'viewValue': 'GUALDO TADINO (PG)'
  },
  {
    'value': 'E232',
    'viewValue': 'GUALTIERI (RE)'
  },
  {
    'value': 'E233',
    'viewValue': "GUALTIERI SICAMINO' (ME)"
  },
  {
    'value': 'E234',
    'viewValue': 'GUAMAGGIORE (CA)'
  },
  {
    'value': 'E235',
    'viewValue': 'GUANZATE (CO)'
  },
  {
    'value': 'E236',
    'viewValue': 'GUARCINO (FR)'
  },
  {
    'value': 'E240',
    'viewValue': 'GUARDA VENETA (RO)'
  },
  {
    'value': 'E237',
    'viewValue': 'GUARDABOSONE (VC)'
  },
  {
    'value': 'E238',
    'viewValue': 'GUARDAMIGLIO (LO)'
  },
  {
    'value': 'E239',
    'viewValue': 'GUARDAVALLE (CZ)'
  },
  {
    'value': 'E241',
    'viewValue': 'GUARDEA (TR)'
  },
  {
    'value': 'E245',
    'viewValue': 'GUARDIA LOMBARDI (AV)'
  },
  {
    'value': 'E246',
    'viewValue': 'GUARDIA PERTICARA (PZ)'
  },
  {
    'value': 'E242',
    'viewValue': 'GUARDIA PIEMONTESE (CS)'
  },
  {
    'value': 'E249',
    'viewValue': 'GUARDIA SANFRAMONDI (BN)'
  },
  {
    'value': 'E243',
    'viewValue': 'GUARDIAGRELE (CH)'
  },
  {
    'value': 'E244',
    'viewValue': 'GUARDIALFIERA (CB)'
  },
  {
    'value': 'E248',
    'viewValue': 'GUARDIAREGIA (CB)'
  },
  {
    'value': 'E250',
    'viewValue': 'GUARDISTALLO (PI)'
  },
  {
    'value': 'E251',
    'viewValue': 'GUARENE (CN)'
  },
  {
    'value': 'E252',
    'viewValue': 'GUASILA (CA)'
  },
  {
    'value': 'E253',
    'viewValue': 'GUASTALLA (RE)'
  },
  {
    'value': 'E255',
    'viewValue': 'GUAZZORA (AL)'
  },
  {
    'value': 'E256',
    'viewValue': 'GUBBIO (PG)'
  },
  {
    'value': 'E258',
    'viewValue': 'GUDO VISCONTI (MI)'
  },
  {
    'value': 'E259',
    'viewValue': 'GUGLIONESI (CB)'
  },
  {
    'value': 'E261',
    'viewValue': 'GUIDIZZOLO (MN)'
  },
  {
    'value': 'E263',
    'viewValue': 'GUIDONIA MONTECELIO (RM)'
  },
  {
    'value': 'E264',
    'viewValue': 'GUIGLIA (MO)'
  },
  {
    'value': 'E266',
    'viewValue': 'GUILMI (CH)'
  },
  {
    'value': 'E269',
    'viewValue': 'GURRO (VB)'
  },
  {
    'value': 'E270',
    'viewValue': 'GUSPINI (CA)'
  },
  {
    'value': 'E271',
    'viewValue': 'GUSSAGO (BS)'
  },
  {
    'value': 'E272',
    'viewValue': 'GUSSOLA (CR)'
  },
  {
    'value': 'E273',
    'viewValue': 'HONE (AO)'
  },
  {
    'value': 'E280',
    'viewValue': 'IDRO (BS)'
  },
  {
    'value': 'E281',
    'viewValue': 'IGLESIAS (CA)'
  },
  {
    'value': 'E282',
    'viewValue': 'IGLIANO (CN)'
  },
  {
    'value': 'E283',
    'viewValue': 'ILBONO (NU)'
  },
  {
    'value': 'E284',
    'viewValue': 'ILLASI (VR)'
  },
  {
    'value': 'E285',
    'viewValue': 'ILLORAI (SS)'
  },
  {
    'value': 'E287',
    'viewValue': 'IMBERSAGO (LC)'
  },
  {
    'value': 'E288',
    'viewValue': 'IMER (TN)'
  },
  {
    'value': 'E289',
    'viewValue': 'IMOLA (BO)'
  },
  {
    'value': 'E290',
    'viewValue': 'IMPERIA (IM)'
  },
  {
    'value': 'E291',
    'viewValue': 'IMPRUNETA (FI)'
  },
  {
    'value': 'E292',
    'viewValue': 'INARZO (VA)'
  },
  {
    'value': 'E296',
    'viewValue': "INCISA IN VAL D'ARNO (FI)"
  },
  {
    'value': 'E295',
    'viewValue': 'INCISA SCAPACCINO (AT)'
  },
  {
    'value': 'E297',
    'viewValue': 'INCUDINE (BS)'
  },
  {
    'value': 'E299',
    'viewValue': 'INDUNO OLONA (VA)'
  },
  {
    'value': 'E301',
    'viewValue': 'INGRIA (TO)'
  },
  {
    'value': 'E304',
    'viewValue': 'INTRAGNA (VB)'
  },
  {
    'value': 'E305',
    'viewValue': 'INTROBIO (LC)'
  },
  {
    'value': 'E306',
    'viewValue': 'INTROD (AO)'
  },
  {
    'value': 'E307',
    'viewValue': 'INTRODACQUA (AQ)'
  },
  {
    'value': 'E308',
    'viewValue': 'INTROZZO (LC)'
  },
  {
    'value': 'E309',
    'viewValue': 'INVERIGO (CO)'
  },
  {
    'value': 'E310',
    'viewValue': 'INVERNO E MONTELEONE (PV)'
  },
  {
    'value': 'E311',
    'viewValue': 'INVERSO PINASCA (TO)'
  },
  {
    'value': 'E313',
    'viewValue': 'INVERUNO (MI)'
  },
  {
    'value': 'E314',
    'viewValue': 'INVORIO (NO)'
  },
  {
    'value': 'E317',
    'viewValue': 'INZAGO (MI)'
  },
  {
    'value': 'E321',
    'viewValue': 'IONADI (VV)'
  },
  {
    'value': 'E323',
    'viewValue': 'IRGOLI (NU)'
  },
  {
    'value': 'E325',
    'viewValue': 'IRMA (BS)'
  },
  {
    'value': 'E326',
    'viewValue': 'IRSINA (MT)'
  },
  {
    'value': 'E327',
    'viewValue': 'ISASCA (CN)'
  },
  {
    'value': 'E328',
    'viewValue': 'ISCA SULLO IONIO (CZ)'
  },
  {
    'value': 'E329',
    'viewValue': 'ISCHIA (NA)'
  },
  {
    'value': 'E330',
    'viewValue': 'ISCHIA DI CASTRO (VT)'
  },
  {
    'value': 'E332',
    'viewValue': 'ISCHITELLA (FG)'
  },
  {
    'value': 'E333',
    'viewValue': 'ISEO (BS)'
  },
  {
    'value': 'E334',
    'viewValue': 'ISERA (TN)'
  },
  {
    'value': 'E335',
    'viewValue': 'ISERNIA (IS)'
  },
  {
    'value': 'E336',
    'viewValue': 'ISILI (NU)'
  },
  {
    'value': 'E337',
    'viewValue': 'ISNELLO (PA)'
  },
  {
    'value': 'E338',
    'viewValue': "ISOLA D'ASTI (AT)"
  },
  {
    'value': 'E341',
    'viewValue': 'ISOLA DEL CANTONE (GE)'
  },
  {
    'value': 'E348',
    'viewValue': 'ISOLA DEL GIGLIO (GR)'
  },
  {
    'value': 'E343',
    'viewValue': "ISOLA DEL GRAN SASSO D'ITALIA (TE)"
  },
  {
    'value': 'E340',
    'viewValue': 'ISOLA DEL LIRI (FR)'
  },
  {
    'value': 'E351',
    'viewValue': 'ISOLA DEL PIANO (PU)'
  },
  {
    'value': 'E349',
    'viewValue': 'ISOLA DELLA SCALA (VR)'
  },
  {
    'value': 'E350',
    'viewValue': 'ISOLA DELLE FEMMINE (PA)'
  },
  {
    'value': 'E339',
    'viewValue': 'ISOLA DI CAPO RIZZUTO (KR)'
  },
  {
    'value': 'E353',
    'viewValue': 'ISOLA DI FONDRA (BG)'
  },
  {
    'value': 'E356',
    'viewValue': 'ISOLA DOVARESE (CR)'
  },
  {
    'value': 'E358',
    'viewValue': 'ISOLA RIZZA (VR)'
  },
  {
    'value': 'E360',
    'viewValue': "ISOLA SANT'ANTONIO (AL)"
  },
  {
    'value': 'E354',
    'viewValue': 'ISOLA VICENTINA (VI)'
  },
  {
    'value': 'E345',
    'viewValue': 'ISOLABELLA (TO)'
  },
  {
    'value': 'E346',
    'viewValue': 'ISOLABONA (IM)'
  },
  {
    'value': 'E363',
    'viewValue': 'ISOLE TREMITI (FG)'
  },
  {
    'value': 'E364',
    'viewValue': 'ISORELLA (BS)'
  },
  {
    'value': 'E365',
    'viewValue': 'ISPANI (SA)'
  },
  {
    'value': 'E366',
    'viewValue': 'ISPICA (RG)'
  },
  {
    'value': 'E367',
    'viewValue': 'ISPRA (VA)'
  },
  {
    'value': 'E368',
    'viewValue': 'ISSIGLIO (TO)'
  },
  {
    'value': 'E369',
    'viewValue': 'ISSIME (AO)'
  },
  {
    'value': 'E370',
    'viewValue': 'ISSO (BG)'
  },
  {
    'value': 'E371',
    'viewValue': 'ISSOGNE (AO)'
  },
  {
    'value': 'E373',
    'viewValue': 'ISTRANA (TV)'
  },
  {
    'value': 'E374',
    'viewValue': 'ITALA (ME)'
  },
  {
    'value': 'E375',
    'viewValue': 'ITRI (LT)'
  },
  {
    'value': 'E376',
    'viewValue': 'ITTIREDDU (SS)'
  },
  {
    'value': 'E377',
    'viewValue': 'ITTIRI (SS)'
  },
  {
    'value': 'E378',
    'viewValue': 'IVANO FRACENA (TN)'
  },
  {
    'value': 'E379',
    'viewValue': 'IVREA (TO)'
  },
  {
    'value': 'E380',
    'viewValue': 'IZANO (CR)'
  },
  {
    'value': 'E274',
    'viewValue': 'JACURSO (CZ)'
  },
  {
    'value': 'E381',
    'viewValue': 'JELSI (CB)'
  },
  {
    'value': 'E382',
    'viewValue': 'JENNE (RM)'
  },
  {
    'value': 'E386',
    'viewValue': 'JERAGO CON ORAGO (VA)'
  },
  {
    'value': 'E387',
    'viewValue': 'JERZU (NU)'
  },
  {
    'value': 'E388',
    'viewValue': 'JESI (AN)'
  },
  {
    'value': 'C388',
    'viewValue': 'JESOLO (VE)'
  },
  {
    'value': 'E320',
    'viewValue': 'JOLANDA DI SAVOIA (FE)'
  },
  {
    'value': 'E389',
    'viewValue': 'JOPPOLO (VV)'
  },
  {
    'value': 'E390',
    'viewValue': 'JOPPOLO GIANCAXIO (AG)'
  },
  {
    'value': 'E391',
    'viewValue': 'JOVENCAN (AO)'
  },
  {
    'value': 'E394',
    'viewValue': 'LA CASSA (TO)'
  },
  {
    'value': 'E423',
    'viewValue': 'LA LOGGIA (TO)'
  },
  {
    'value': 'E425',
    'viewValue': 'LA MADDALENA (SS)'
  },
  {
    'value': 'A308',
    'viewValue': 'LA MAGDELEINE (AO)'
  },
  {
    'value': 'E430',
    'viewValue': 'LA MORRA (CN)'
  },
  {
    'value': 'E458',
    'viewValue': 'LA SALLE (AO)'
  },
  {
    'value': 'E463',
    'viewValue': 'LA SPEZIA (SP)'
  },
  {
    'value': 'E470',
    'viewValue': 'LA THUILE (AO)'
  },
  {
    'value': 'E491',
    'viewValue': 'LA VALLE .WENGEN. (BZ)'
  },
  {
    'value': 'E490',
    'viewValue': 'LA VALLE AGORDINA (BL)'
  },
  {
    'value': 'E392',
    'viewValue': 'LABICO (RM)'
  },
  {
    'value': 'E393',
    'viewValue': 'LABRO (RI)'
  },
  {
    'value': 'E395',
    'viewValue': 'LACCHIARELLA (MI)'
  },
  {
    'value': 'E396',
    'viewValue': 'LACCO AMENO (NA)'
  },
  {
    'value': 'E397',
    'viewValue': 'LACEDONIA (AV)'
  },
  {
    'value': 'E398',
    'viewValue': 'LACES .LATSCH. (BZ)'
  },
  {
    'value': 'E400',
    'viewValue': 'LACONI (NU)'
  },
  {
    'value': 'M212',
    'viewValue': 'LADISPOLI (RM)'
  },
  {
    'value': 'E401',
    'viewValue': 'LAERRU (SS)'
  },
  {
    'value': 'E402',
    'viewValue': 'LAGANADI (RC)'
  },
  {
    'value': 'E403',
    'viewValue': 'LAGHI (VI)'
  },
  {
    'value': 'E405',
    'viewValue': 'LAGLIO (CO)'
  },
  {
    'value': 'E406',
    'viewValue': 'LAGNASCO (CN)'
  },
  {
    'value': 'E407',
    'viewValue': 'LAGO (CS)'
  },
  {
    'value': 'E409',
    'viewValue': 'LAGONEGRO (PZ)'
  },
  {
    'value': 'E410',
    'viewValue': 'LAGOSANTO (FE)'
  },
  {
    'value': 'E412',
    'viewValue': 'LAGUNDO .ALGUND. (BZ)'
  },
  {
    'value': 'E414',
    'viewValue': 'LAIGUEGLIA (SV)'
  },
  {
    'value': 'E415',
    'viewValue': 'LAINATE (MI)'
  },
  {
    'value': 'E416',
    'viewValue': 'LAINO (CO)'
  },
  {
    'value': 'E417',
    'viewValue': 'LAINO BORGO (CS)'
  },
  {
    'value': 'E419',
    'viewValue': 'LAINO CASTELLO (CS)'
  },
  {
    'value': 'E420',
    'viewValue': 'LAION .LAJEN. (BZ)'
  },
  {
    'value': 'E421',
    'viewValue': 'LAIVES .LEIFERS. (BZ)'
  },
  {
    'value': 'E413',
    'viewValue': 'LAJATICO (PI)'
  },
  {
    'value': 'E422',
    'viewValue': 'LALLIO (BG)'
  },
  {
    'value': 'E424',
    'viewValue': 'LAMA DEI PELIGNI (CH)'
  },
  {
    'value': 'E426',
    'viewValue': 'LAMA MOCOGNO (MO)'
  },
  {
    'value': 'E428',
    'viewValue': 'LAMBRUGO (CO)'
  },
  {
    'value': 'M208',
    'viewValue': 'LAMEZIA TERME (CZ)'
  },
  {
    'value': 'E429',
    'viewValue': 'LAMON (BL)'
  },
  {
    'value': 'E431',
    'viewValue': 'LAMPEDUSA E LINOSA (AG)'
  },
  {
    'value': 'E432',
    'viewValue': 'LAMPORECCHIO (PT)'
  },
  {
    'value': 'E433',
    'viewValue': 'LAMPORO (VC)'
  },
  {
    'value': 'E434',
    'viewValue': 'LANA .LANA. (BZ)'
  },
  {
    'value': 'E435',
    'viewValue': 'LANCIANO (CH)'
  },
  {
    'value': 'E436',
    'viewValue': 'LANDIONA (NO)'
  },
  {
    'value': 'E437',
    'viewValue': 'LANDRIANO (PV)'
  },
  {
    'value': 'E438',
    'viewValue': 'LANGHIRANO (PR)'
  },
  {
    'value': 'E439',
    'viewValue': 'LANGOSCO (PV)'
  },
  {
    'value': 'E441',
    'viewValue': 'LANUSEI (NU)'
  },
  {
    'value': 'C767',
    'viewValue': 'LANUVIO (RM)'
  },
  {
    'value': 'E443',
    'viewValue': 'LANZADA (SO)'
  },
  {
    'value': 'E444',
    'viewValue': "LANZO D'INTELVI (CO)"
  },
  {
    'value': 'E445',
    'viewValue': 'LANZO TORINESE (TO)'
  },
  {
    'value': 'E447',
    'viewValue': 'LAPEDONA (AP)'
  },
  {
    'value': 'E448',
    'viewValue': 'LAPIO (AV)'
  },
  {
    'value': 'E450',
    'viewValue': 'LAPPANO (CS)'
  },
  {
    'value': 'A345',
    'viewValue': "L'AQUILA (AQ)"
  },
  {
    'value': 'E451',
    'viewValue': 'LARCIANO (PT)'
  },
  {
    'value': 'E452',
    'viewValue': 'LARDARO (TN)'
  },
  {
    'value': 'E454',
    'viewValue': 'LARDIRAGO (PV)'
  },
  {
    'value': 'E455',
    'viewValue': 'LARI (PI)'
  },
  {
    'value': 'M207',
    'viewValue': 'LARIANO (RM)'
  },
  {
    'value': 'E456',
    'viewValue': 'LARINO (CB)'
  },
  {
    'value': 'E464',
    'viewValue': 'LAS PLASSAS (CA)'
  },
  {
    'value': 'E457',
    'viewValue': 'LASA .LAAS. (BZ)'
  },
  {
    'value': 'E459',
    'viewValue': 'LASCARI (PA)'
  },
  {
    'value': 'E461',
    'viewValue': 'LASINO (TN)'
  },
  {
    'value': 'E462',
    'viewValue': 'LASNIGO (CO)'
  },
  {
    'value': 'E465',
    'viewValue': 'LASTEBASSE (VI)'
  },
  {
    'value': 'E466',
    'viewValue': 'LASTRA A SIGNA (FI)'
  },
  {
    'value': 'E467',
    'viewValue': 'LATERA (VT)'
  },
  {
    'value': 'E468',
    'viewValue': 'LATERINA (AR)'
  },
  {
    'value': 'E469',
    'viewValue': 'LATERZA (TA)'
  },
  {
    'value': 'E471',
    'viewValue': 'LATIANO (BR)'
  },
  {
    'value': 'E472',
    'viewValue': 'LATINA (LT)'
  },
  {
    'value': 'E473',
    'viewValue': 'LATISANA (UD)'
  },
  {
    'value': 'E474',
    'viewValue': 'LATRONICO (PZ)'
  },
  {
    'value': 'E475',
    'viewValue': 'LATTARICO (CS)'
  },
  {
    'value': 'E476',
    'viewValue': 'LAUCO (UD)'
  },
  {
    'value': 'E480',
    'viewValue': 'LAUREANA CILENTO (SA)'
  },
  {
    'value': 'E479',
    'viewValue': 'LAUREANA DI BORRELLO (RC)'
  },
  {
    'value': 'E481',
    'viewValue': 'LAUREGNO .LAUREIN. (BZ)'
  },
  {
    'value': 'E482',
    'viewValue': 'LAURENZANA (PZ)'
  },
  {
    'value': 'E483',
    'viewValue': 'LAURIA (PZ)'
  },
  {
    'value': 'E484',
    'viewValue': 'LAURIANO (TO)'
  },
  {
    'value': 'E485',
    'viewValue': 'LAURINO (SA)'
  },
  {
    'value': 'E486',
    'viewValue': 'LAURITO (SA)'
  },
  {
    'value': 'E487',
    'viewValue': 'LAURO (AV)'
  },
  {
    'value': 'E488',
    'viewValue': 'LAVAGNA (GE)'
  },
  {
    'value': 'E489',
    'viewValue': 'LAVAGNO (VR)'
  },
  {
    'value': 'E492',
    'viewValue': 'LAVARONE (TN)'
  },
  {
    'value': 'E493',
    'viewValue': 'LAVELLO (PZ)'
  },
  {
    'value': 'E494',
    'viewValue': 'LAVENA PONTE TRESA (VA)'
  },
  {
    'value': 'E496',
    'viewValue': 'LAVENO-MOMBELLO (VA)'
  },
  {
    'value': 'E497',
    'viewValue': 'LAVENONE (BS)'
  },
  {
    'value': 'E498',
    'viewValue': 'LAVIANO (SA)'
  },
  {
    'value': 'E500',
    'viewValue': 'LAVIS (TN)'
  },
  {
    'value': 'E502',
    'viewValue': 'LAZISE (VR)'
  },
  {
    'value': 'E504',
    'viewValue': 'LAZZATE (MI)'
  },
  {
    'value': 'E506',
    'viewValue': 'LECCE (LE)'
  },
  {
    'value': 'E505',
    'viewValue': 'LECCE NEI MARSI (AQ)'
  },
  {
    'value': 'E507',
    'viewValue': 'LECCO (LC)'
  },
  {
    'value': 'E509',
    'viewValue': 'LEFFE (BG)'
  },
  {
    'value': 'E510',
    'viewValue': 'LEGGIUNO (VA)'
  },
  {
    'value': 'E512',
    'viewValue': 'LEGNAGO (VR)'
  },
  {
    'value': 'E514',
    'viewValue': 'LEGNANO (MI)'
  },
  {
    'value': 'E515',
    'viewValue': 'LEGNARO (PD)'
  },
  {
    'value': 'E517',
    'viewValue': 'LEI (NU)'
  },
  {
    'value': 'E518',
    'viewValue': 'LEINI (TO)'
  },
  {
    'value': 'E519',
    'viewValue': 'LEIVI (GE)'
  },
  {
    'value': 'E520',
    'viewValue': 'LEMIE (TO)'
  },
  {
    'value': 'E522',
    'viewValue': 'LENDINARA (RO)'
  },
  {
    'value': 'E523',
    'viewValue': 'LENI (ME)'
  },
  {
    'value': 'E524',
    'viewValue': 'LENNA (BG)'
  },
  {
    'value': 'E525',
    'viewValue': 'LENNO (CO)'
  },
  {
    'value': 'E526',
    'viewValue': 'LENO (BS)'
  },
  {
    'value': 'E527',
    'viewValue': 'LENOLA (LT)'
  },
  {
    'value': 'E528',
    'viewValue': 'LENTA (VC)'
  },
  {
    'value': 'E530',
    'viewValue': 'LENTATE SUL SEVESO (MI)'
  },
  {
    'value': 'E531',
    'viewValue': 'LENTELLA (CH)'
  },
  {
    'value': 'C562',
    'viewValue': 'LENTIAI (BL)'
  },
  {
    'value': 'E532',
    'viewValue': 'LENTINI (SR)'
  },
  {
    'value': 'E535',
    'viewValue': 'LEONESSA (RI)'
  },
  {
    'value': 'E536',
    'viewValue': 'LEONFORTE (EN)'
  },
  {
    'value': 'E537',
    'viewValue': 'LEPORANO (TA)'
  },
  {
    'value': 'E538',
    'viewValue': 'LEQUILE (LE)'
  },
  {
    'value': 'E540',
    'viewValue': 'LEQUIO BERRIA (CN)'
  },
  {
    'value': 'E539',
    'viewValue': 'LEQUIO TANARO (CN)'
  },
  {
    'value': 'E541',
    'viewValue': 'LERCARA FRIDDI (PA)'
  },
  {
    'value': 'E542',
    'viewValue': 'LERICI (SP)'
  },
  {
    'value': 'E543',
    'viewValue': 'LERMA (AL)'
  },
  {
    'value': 'E544',
    'viewValue': 'LESA (NO)'
  },
  {
    'value': 'E546',
    'viewValue': 'LESEGNO (CN)'
  },
  {
    'value': 'E547',
    'viewValue': "LESIGNANO DE' BAGNI (PR)"
  },
  {
    'value': 'E549',
    'viewValue': 'LESINA (FG)'
  },
  {
    'value': 'E550',
    'viewValue': 'LESMO (MI)'
  },
  {
    'value': 'E551',
    'viewValue': 'LESSOLO (TO)'
  },
  {
    'value': 'E552',
    'viewValue': 'LESSONA (BI)'
  },
  {
    'value': 'E553',
    'viewValue': 'LESTIZZA (UD)'
  },
  {
    'value': 'E554',
    'viewValue': 'LETINO (CE)'
  },
  {
    'value': 'E555',
    'viewValue': 'LETOJANNI (ME)'
  },
  {
    'value': 'E557',
    'viewValue': 'LETTERE (NA)'
  },
  {
    'value': 'E558',
    'viewValue': 'LETTOMANOPPELLO (PE)'
  },
  {
    'value': 'E559',
    'viewValue': 'LETTOPALENA (CH)'
  },
  {
    'value': 'E560',
    'viewValue': 'LEVANTO (SP)'
  },
  {
    'value': 'E562',
    'viewValue': 'LEVATE (BG)'
  },
  {
    'value': 'E563',
    'viewValue': 'LEVERANO (LE)'
  },
  {
    'value': 'E564',
    'viewValue': 'LEVICE (CN)'
  },
  {
    'value': 'E565',
    'viewValue': 'LEVICO TERME (TN)'
  },
  {
    'value': 'E566',
    'viewValue': 'LEVONE (TO)'
  },
  {
    'value': 'E569',
    'viewValue': 'LEZZENO (CO)'
  },
  {
    'value': 'E570',
    'viewValue': 'LIBERI (CE)'
  },
  {
    'value': 'E571',
    'viewValue': 'LIBRIZZI (ME)'
  },
  {
    'value': 'E573',
    'viewValue': 'LICATA (AG)'
  },
  {
    'value': 'E574',
    'viewValue': 'LICCIANA NARDI (MS)'
  },
  {
    'value': 'E576',
    'viewValue': 'LICENZA (RM)'
  },
  {
    'value': 'E578',
    'viewValue': 'LICODIA EUBEA (CT)'
  },
  {
    'value': 'E581',
    'viewValue': 'LIERNA (LC)'
  },
  {
    'value': 'E583',
    'viewValue': 'LIGNANA (VC)'
  },
  {
    'value': 'E584',
    'viewValue': 'LIGNANO SABBIADORO (UD)'
  },
  {
    'value': 'E585',
    'viewValue': 'LIGONCHIO (RE)'
  },
  {
    'value': 'E586',
    'viewValue': 'LIGOSULLO (UD)'
  },
  {
    'value': 'E587',
    'viewValue': 'LILLIANES (AO)'
  },
  {
    'value': 'E588',
    'viewValue': 'LIMANA (BL)'
  },
  {
    'value': 'E589',
    'viewValue': 'LIMATOLA (BN)'
  },
  {
    'value': 'E590',
    'viewValue': 'LIMBADI (VV)'
  },
  {
    'value': 'E591',
    'viewValue': 'LIMBIATE (MI)'
  },
  {
    'value': 'E592',
    'viewValue': 'LIMENA (PD)'
  },
  {
    'value': 'E593',
    'viewValue': 'LIMIDO COMASCO (CO)'
  },
  {
    'value': 'E594',
    'viewValue': 'LIMINA (ME)'
  },
  {
    'value': 'E597',
    'viewValue': 'LIMONE PIEMONTE (CN)'
  },
  {
    'value': 'E596',
    'viewValue': 'LIMONE SUL GARDA (BS)'
  },
  {
    'value': 'E599',
    'viewValue': 'LIMOSANO (CB)'
  },
  {
    'value': 'E600',
    'viewValue': 'LINAROLO (PV)'
  },
  {
    'value': 'E602',
    'viewValue': 'LINGUAGLOSSA (CT)'
  },
  {
    'value': 'E605',
    'viewValue': 'LIONI (AV)'
  },
  {
    'value': 'E606',
    'viewValue': 'LIPARI (ME)'
  },
  {
    'value': 'E607',
    'viewValue': 'LIPOMO (CO)'
  },
  {
    'value': 'E608',
    'viewValue': 'LIRIO (PV)'
  },
  {
    'value': 'E610',
    'viewValue': 'LISCATE (MI)'
  },
  {
    'value': 'E611',
    'viewValue': 'LISCIA (CH)'
  },
  {
    'value': 'E613',
    'viewValue': 'LISCIANO NICCONE (PG)'
  },
  {
    'value': 'E614',
    'viewValue': 'LISIGNAGO (TN)'
  },
  {
    'value': 'E615',
    'viewValue': 'LISIO (CN)'
  },
  {
    'value': 'E617',
    'viewValue': 'LISSONE (MI)'
  },
  {
    'value': 'E620',
    'viewValue': 'LIVERI (NA)'
  },
  {
    'value': 'E621',
    'viewValue': 'LIVIGNO (SO)'
  },
  {
    'value': 'E622',
    'viewValue': 'LIVINALLONGO DEL COL DI LANA (BL)'
  },
  {
    'value': 'E623',
    'viewValue': 'LIVO (CO)'
  },
  {
    'value': 'E624',
    'viewValue': 'LIVO (TN)'
  },
  {
    'value': 'E625',
    'viewValue': 'LIVORNO (LI)'
  },
  {
    'value': 'E626',
    'viewValue': 'LIVORNO FERRARIS (VC)'
  },
  {
    'value': 'E627',
    'viewValue': 'LIVRAGA (LO)'
  },
  {
    'value': 'E629',
    'viewValue': 'LIZZANELLO (LE)'
  },
  {
    'value': 'E630',
    'viewValue': 'LIZZANO (TA)'
  },
  {
    'value': 'A771',
    'viewValue': 'LIZZANO IN BELVEDERE (BO)'
  },
  {
    'value': 'E632',
    'viewValue': 'LOANO (SV)'
  },
  {
    'value': 'E633',
    'viewValue': 'LOAZZOLO (AT)'
  },
  {
    'value': 'E635',
    'viewValue': 'LOCANA (TO)'
  },
  {
    'value': 'E639',
    'viewValue': 'LOCATE DI TRIULZI (MI)'
  },
  {
    'value': 'E638',
    'viewValue': 'LOCATE VARESINO (CO)'
  },
  {
    'value': 'E640',
    'viewValue': 'LOCATELLO (BG)'
  },
  {
    'value': 'E644',
    'viewValue': 'LOCERI (NU)'
  },
  {
    'value': 'E645',
    'viewValue': 'LOCOROTONDO (BA)'
  },
  {
    'value': 'D976',
    'viewValue': 'LOCRI (RC)'
  },
  {
    'value': 'E646',
    'viewValue': 'LOCULI (NU)'
  },
  {
    'value': 'E647',
    'viewValue': "LODE' (NU)"
  },
  {
    'value': 'E648',
    'viewValue': 'LODI (LO)'
  },
  {
    'value': 'E651',
    'viewValue': 'LODI VECCHIO (LO)'
  },
  {
    'value': 'E649',
    'viewValue': 'LODINE (NU)'
  },
  {
    'value': 'E652',
    'viewValue': 'LODRINO (BS)'
  },
  {
    'value': 'E654',
    'viewValue': 'LOGRATO (BS)'
  },
  {
    'value': 'E655',
    'viewValue': 'LOIANO (BO)'
  },
  {
    'value': 'M275',
    'viewValue': 'LOIRI PORTO SAN PAOLO (SS)'
  },
  {
    'value': 'E656',
    'viewValue': 'LOMAGNA (LC)'
  },
  {
    'value': 'E658',
    'viewValue': 'LOMASO (TN)'
  },
  {
    'value': 'E659',
    'viewValue': 'LOMAZZO (CO)'
  },
  {
    'value': 'E660',
    'viewValue': 'LOMBARDORE (TO)'
  },
  {
    'value': 'E661',
    'viewValue': 'LOMBRIASCO (TO)'
  },
  {
    'value': 'E662',
    'viewValue': 'LOMELLO (PV)'
  },
  {
    'value': 'E664',
    'viewValue': 'LONA LASES (TN)'
  },
  {
    'value': 'E665',
    'viewValue': 'LONATE CEPPINO (VA)'
  },
  {
    'value': 'E666',
    'viewValue': 'LONATE POZZOLO (VA)'
  },
  {
    'value': 'E667',
    'viewValue': 'LONATO (BS)'
  },
  {
    'value': 'E668',
    'viewValue': 'LONDA (FI)'
  },
  {
    'value': 'E669',
    'viewValue': 'LONGANO (IS)'
  },
  {
    'value': 'E671',
    'viewValue': 'LONGARE (VI)'
  },
  {
    'value': 'E672',
    'viewValue': 'LONGARONE (BL)'
  },
  {
    'value': 'E673',
    'viewValue': 'LONGHENA (BS)'
  },
  {
    'value': 'E674',
    'viewValue': 'LONGI (ME)'
  },
  {
    'value': 'E675',
    'viewValue': 'LONGIANO (FC)'
  },
  {
    'value': 'E677',
    'viewValue': 'LONGOBARDI (CS)'
  },
  {
    'value': 'E678',
    'viewValue': 'LONGOBUCCO (CS)'
  },
  {
    'value': 'E679',
    'viewValue': 'LONGONE AL SEGRINO (CO)'
  },
  {
    'value': 'E681',
    'viewValue': 'LONGONE SABINO (RI)'
  },
  {
    'value': 'E682',
    'viewValue': 'LONIGO (VI)'
  },
  {
    'value': 'E683',
    'viewValue': "LORANZE' (TO)"
  },
  {
    'value': 'E684',
    'viewValue': 'LOREGGIA (PD)'
  },
  {
    'value': 'E685',
    'viewValue': 'LOREGLIA (VB)'
  },
  {
    'value': 'E687',
    'viewValue': 'LORENZAGO DI CADORE (BL)'
  },
  {
    'value': 'E688',
    'viewValue': 'LORENZANA (PI)'
  },
  {
    'value': 'E689',
    'viewValue': 'LOREO (RO)'
  },
  {
    'value': 'E690',
    'viewValue': 'LORETO (AN)'
  },
  {
    'value': 'E691',
    'viewValue': 'LORETO APRUTINO (PE)'
  },
  {
    'value': 'E692',
    'viewValue': 'LORIA (TV)'
  },
  {
    'value': 'E693',
    'viewValue': 'LORO CIUFFENNA (AR)'
  },
  {
    'value': 'E694',
    'viewValue': 'LORO PICENO (MC)'
  },
  {
    'value': 'E695',
    'viewValue': 'LORSICA (GE)'
  },
  {
    'value': 'E698',
    'viewValue': 'LOSINE (BS)'
  },
  {
    'value': 'E700',
    'viewValue': 'LOTZORAI (NU)'
  },
  {
    'value': 'E704',
    'viewValue': 'LOVERE (BG)'
  },
  {
    'value': 'E705',
    'viewValue': 'LOVERO (SO)'
  },
  {
    'value': 'E706',
    'viewValue': 'LOZIO (BS)'
  },
  {
    'value': 'E707',
    'viewValue': 'LOZZA (VA)'
  },
  {
    'value': 'E709',
    'viewValue': 'LOZZO ATESTINO (PD)'
  },
  {
    'value': 'E708',
    'viewValue': 'LOZZO DI CADORE (BL)'
  },
  {
    'value': 'E711',
    'viewValue': 'LOZZOLO (VC)'
  },
  {
    'value': 'E712',
    'viewValue': 'LU (AL)'
  },
  {
    'value': 'E713',
    'viewValue': 'LUBRIANO (VT)'
  },
  {
    'value': 'E715',
    'viewValue': 'LUCCA (LU)'
  },
  {
    'value': 'E714',
    'viewValue': 'LUCCA SICULA (AG)'
  },
  {
    'value': 'E716',
    'viewValue': 'LUCERA (FG)'
  },
  {
    'value': 'E718',
    'viewValue': 'LUCIGNANO (AR)'
  },
  {
    'value': 'E719',
    'viewValue': 'LUCINASCO (IM)'
  },
  {
    'value': 'E722',
    'viewValue': 'LUCITO (CB)'
  },
  {
    'value': 'E723',
    'viewValue': 'LUCO DEI MARSI (AQ)'
  },
  {
    'value': 'E724',
    'viewValue': 'LUCOLI (AQ)'
  },
  {
    'value': 'E726',
    'viewValue': "LUGAGNANO VAL D'ARDA (PC)"
  },
  {
    'value': 'E727',
    'viewValue': 'LUGNACCO (TO)'
  },
  {
    'value': 'E729',
    'viewValue': 'LUGNANO IN TEVERINA (TR)'
  },
  {
    'value': 'E730',
    'viewValue': 'LUGO (RA)'
  },
  {
    'value': 'E731',
    'viewValue': 'LUGO DI VICENZA (VI)'
  },
  {
    'value': 'E734',
    'viewValue': 'LUINO (VA)'
  },
  {
    'value': 'E735',
    'viewValue': 'LUISAGO (CO)'
  },
  {
    'value': 'E736',
    'viewValue': 'LULA (NU)'
  },
  {
    'value': 'E737',
    'viewValue': 'LUMARZO (GE)'
  },
  {
    'value': 'E738',
    'viewValue': 'LUMEZZANE (BS)'
  },
  {
    'value': 'E742',
    'viewValue': 'LUNAMATRONA (CA)'
  },
  {
    'value': 'E743',
    'viewValue': 'LUNANO (PU)'
  },
  {
    'value': 'B387',
    'viewValue': 'LUNGAVILLA (PV)'
  },
  {
    'value': 'E745',
    'viewValue': 'LUNGRO (CS)'
  },
  {
    'value': 'E746',
    'viewValue': 'LUOGOSANO (AV)'
  },
  {
    'value': 'E747',
    'viewValue': 'LUOGOSANTO (SS)'
  },
  {
    'value': 'E748',
    'viewValue': 'LUPARA (CB)'
  },
  {
    'value': 'E749',
    'viewValue': "LURAGO D'ERBA (CO)"
  },
  {
    'value': 'E750',
    'viewValue': 'LURAGO MARINONE (CO)'
  },
  {
    'value': 'E751',
    'viewValue': 'LURANO (BG)'
  },
  {
    'value': 'E752',
    'viewValue': 'LURAS (SS)'
  },
  {
    'value': 'E753',
    'viewValue': 'LURATE CACCIVIO (CO)'
  },
  {
    'value': 'E754',
    'viewValue': 'LUSCIANO (CE)'
  },
  {
    'value': 'E757',
    'viewValue': 'LUSERNA (TN)'
  },
  {
    'value': 'E758',
    'viewValue': 'LUSERNA SAN GIOVANNI (TO)'
  },
  {
    'value': 'E759',
    'viewValue': 'LUSERNETTA (TO)'
  },
  {
    'value': 'E760',
    'viewValue': 'LUSEVERA (UD)'
  },
  {
    'value': 'E761',
    'viewValue': 'LUSIA (RO)'
  },
  {
    'value': 'E762',
    'viewValue': 'LUSIANA (VI)'
  },
  {
    'value': 'E763',
    'viewValue': "LUSIGLIE' (TO)"
  },
  {
    'value': 'E764',
    'viewValue': 'LUSON .LUSEN. (BZ)'
  },
  {
    'value': 'E767',
    'viewValue': 'LUSTRA (SA)'
  },
  {
    'value': 'E769',
    'viewValue': 'LUVINATE (VA)'
  },
  {
    'value': 'E770',
    'viewValue': 'LUZZANA (BG)'
  },
  {
    'value': 'E772',
    'viewValue': 'LUZZARA (RE)'
  },
  {
    'value': 'E773',
    'viewValue': 'LUZZI (CS)'
  },
  {
    'value': 'E775',
    'viewValue': 'MACCAGNO (VA)'
  },
  {
    'value': 'E777',
    'viewValue': 'MACCASTORNA (LO)'
  },
  {
    'value': 'E778',
    'viewValue': "MACCHIA D'ISERNIA (IS)"
  },
  {
    'value': 'E780',
    'viewValue': 'MACCHIA VALFORTORE (CB)'
  },
  {
    'value': 'E779',
    'viewValue': 'MACCHIAGODENA (IS)'
  },
  {
    'value': 'E782',
    'viewValue': 'MACELLO (TO)'
  },
  {
    'value': 'E783',
    'viewValue': 'MACERATA (MC)'
  },
  {
    'value': 'E784',
    'viewValue': 'MACERATA CAMPANIA (CE)'
  },
  {
    'value': 'E785',
    'viewValue': 'MACERATA FELTRIA (PU)'
  },
  {
    'value': 'E786',
    'viewValue': 'MACHERIO (MI)'
  },
  {
    'value': 'E787',
    'viewValue': 'MACLODIO (BS)'
  },
  {
    'value': 'E788',
    'viewValue': 'MACOMER (NU)'
  },
  {
    'value': 'E789',
    'viewValue': 'MACRA (CN)'
  },
  {
    'value': 'E790',
    'viewValue': 'MACUGNAGA (VB)'
  },
  {
    'value': 'E791',
    'viewValue': 'MADDALONI (CE)'
  },
  {
    'value': 'E342',
    'viewValue': 'MADESIMO (SO)'
  },
  {
    'value': 'E793',
    'viewValue': 'MADIGNANO (CR)'
  },
  {
    'value': 'E794',
    'viewValue': 'MADONE (BG)'
  },
  {
    'value': 'E795',
    'viewValue': 'MADONNA DEL SASSO (VB)'
  },
  {
    'value': 'E798',
    'viewValue': 'MAENZA (LT)'
  },
  {
    'value': 'E799',
    'viewValue': 'MAFALDA (CB)'
  },
  {
    'value': 'E800',
    'viewValue': 'MAGASA (BS)'
  },
  {
    'value': 'E801',
    'viewValue': 'MAGENTA (MI)'
  },
  {
    'value': 'E803',
    'viewValue': 'MAGGIORA (NO)'
  },
  {
    'value': 'E804',
    'viewValue': 'MAGHERNO (PV)'
  },
  {
    'value': 'E805',
    'viewValue': 'MAGIONE (PG)'
  },
  {
    'value': 'E806',
    'viewValue': 'MAGISANO (CZ)'
  },
  {
    'value': 'E809',
    'viewValue': 'MAGLIANO ALFIERI (CN)'
  },
  {
    'value': 'E808',
    'viewValue': 'MAGLIANO ALPI (CN)'
  },
  {
    'value': 'E811',
    'viewValue': "MAGLIANO DE' MARSI (AQ)"
  },
  {
    'value': 'E807',
    'viewValue': 'MAGLIANO DI TENNA (AP)'
  },
  {
    'value': 'E810',
    'viewValue': 'MAGLIANO IN TOSCANA (GR)'
  },
  {
    'value': 'E813',
    'viewValue': 'MAGLIANO ROMANO (RM)'
  },
  {
    'value': 'E812',
    'viewValue': 'MAGLIANO SABINA (RI)'
  },
  {
    'value': 'E814',
    'viewValue': 'MAGLIANO VETERE (SA)'
  },
  {
    'value': 'E815',
    'viewValue': 'MAGLIE (LE)'
  },
  {
    'value': 'E816',
    'viewValue': 'MAGLIOLO (SV)'
  },
  {
    'value': 'E817',
    'viewValue': 'MAGLIONE (TO)'
  },
  {
    'value': 'E818',
    'viewValue': 'MAGNACAVALLO (MN)'
  },
  {
    'value': 'E819',
    'viewValue': 'MAGNAGO (MI)'
  },
  {
    'value': 'E821',
    'viewValue': 'MAGNANO (BI)'
  },
  {
    'value': 'E820',
    'viewValue': 'MAGNANO IN RIVIERA (UD)'
  },
  {
    'value': 'E825',
    'viewValue': 'MAGOMADAS (NU)'
  },
  {
    'value': 'E829',
    'viewValue': "MAGRE' SULLA STRADA DEL VINO .MARGREID AN DE. (BZ)"
  },
  {
    'value': 'E830',
    'viewValue': 'MAGREGLIO (CO)'
  },
  {
    'value': 'E834',
    'viewValue': 'MAIDA (CZ)'
  },
  {
    'value': 'E835',
    'viewValue': "MAIERA' (CS)"
  },
  {
    'value': 'E836',
    'viewValue': 'MAIERATO (VV)'
  },
  {
    'value': 'E837',
    'viewValue': 'MAIOLATI SPONTINI (AN)'
  },
  {
    'value': 'E838',
    'viewValue': 'MAIOLO (PU)'
  },
  {
    'value': 'E839',
    'viewValue': 'MAIORI (SA)'
  },
  {
    'value': 'E840',
    'viewValue': 'MAIRAGO (LO)'
  },
  {
    'value': 'E841',
    'viewValue': 'MAIRANO (BS)'
  },
  {
    'value': 'E842',
    'viewValue': 'MAISSANA (SP)'
  },
  {
    'value': 'E833',
    'viewValue': 'MAJANO (UD)'
  },
  {
    'value': 'E843',
    'viewValue': 'MALAGNINO (CR)'
  },
  {
    'value': 'E844',
    'viewValue': 'MALALBERGO (BO)'
  },
  {
    'value': 'E847',
    'viewValue': 'MALBORGHETTO-VALBRUNA (UD)'
  },
  {
    'value': 'E848',
    'viewValue': 'MALCESINE (VR)'
  },
  {
    'value': 'E850',
    'viewValue': "MALE' (TN)"
  },
  {
    'value': 'E851',
    'viewValue': 'MALEGNO (BS)'
  },
  {
    'value': 'E852',
    'viewValue': 'MALEO (LO)'
  },
  {
    'value': 'E853',
    'viewValue': 'MALESCO (VB)'
  },
  {
    'value': 'E854',
    'viewValue': 'MALETTO (CT)'
  },
  {
    'value': 'E855',
    'viewValue': 'MALFA (ME)'
  },
  {
    'value': 'E856',
    'viewValue': 'MALGESSO (VA)'
  },
  {
    'value': 'E858',
    'viewValue': 'MALGRATE (LC)'
  },
  {
    'value': 'E859',
    'viewValue': 'MALITO (CS)'
  },
  {
    'value': 'E860',
    'viewValue': 'MALLARE (SV)'
  },
  {
    'value': 'E862',
    'viewValue': 'MALLES VENOSTA .MALS. (BZ)'
  },
  {
    'value': 'E863',
    'viewValue': 'MALNATE (VA)'
  },
  {
    'value': 'E864',
    'viewValue': 'MALO (VI)'
  },
  {
    'value': 'E865',
    'viewValue': 'MALONNO (BS)'
  },
  {
    'value': 'E866',
    'viewValue': 'MALOSCO (TN)'
  },
  {
    'value': 'E868',
    'viewValue': 'MALTIGNANO (AP)'
  },
  {
    'value': 'E869',
    'viewValue': 'MALVAGNA (ME)'
  },
  {
    'value': 'E870',
    'viewValue': 'MALVICINO (AL)'
  },
  {
    'value': 'E872',
    'viewValue': 'MALVITO (CS)'
  },
  {
    'value': 'E873',
    'viewValue': 'MAMMOLA (RC)'
  },
  {
    'value': 'E874',
    'viewValue': 'MAMOIADA (NU)'
  },
  {
    'value': 'E875',
    'viewValue': 'MANCIANO (GR)'
  },
  {
    'value': 'E876',
    'viewValue': 'MANDANICI (ME)'
  },
  {
    'value': 'E877',
    'viewValue': 'MANDAS (CA)'
  },
  {
    'value': 'E878',
    'viewValue': 'MANDATORICCIO (CS)'
  },
  {
    'value': 'B632',
    'viewValue': 'MANDELA (RM)'
  },
  {
    'value': 'E879',
    'viewValue': 'MANDELLO DEL LARIO (LC)'
  },
  {
    'value': 'E880',
    'viewValue': 'MANDELLO VITTA (NO)'
  },
  {
    'value': 'E882',
    'viewValue': 'MANDURIA (TA)'
  },
  {
    'value': 'E883',
    'viewValue': 'MANERBA DEL GARDA (BS)'
  },
  {
    'value': 'E884',
    'viewValue': 'MANERBIO (BS)'
  },
  {
    'value': 'E885',
    'viewValue': 'MANFREDONIA (FG)'
  },
  {
    'value': 'E887',
    'viewValue': 'MANGO (CN)'
  },
  {
    'value': 'E888',
    'viewValue': 'MANGONE (CS)'
  },
  {
    'value': 'M283',
    'viewValue': 'MANIACE (CT)'
  },
  {
    'value': 'E889',
    'viewValue': 'MANIAGO (PN)'
  },
  {
    'value': 'E891',
    'viewValue': 'MANOCALZATI (AV)'
  },
  {
    'value': 'E892',
    'viewValue': 'MANOPPELLO (PE)'
  },
  {
    'value': 'E893',
    'viewValue': "MANSUE' (TV)"
  },
  {
    'value': 'E894',
    'viewValue': 'MANTA (CN)'
  },
  {
    'value': 'E896',
    'viewValue': 'MANTELLO (SO)'
  },
  {
    'value': 'E897',
    'viewValue': 'MANTOVA (MN)'
  },
  {
    'value': 'E899',
    'viewValue': 'MANZANO (UD)'
  },
  {
    'value': 'E900',
    'viewValue': 'MANZIANA (RM)'
  },
  {
    'value': 'E901',
    'viewValue': 'MAPELLO (BG)'
  },
  {
    'value': 'E902',
    'viewValue': 'MARA (SS)'
  },
  {
    'value': 'E903',
    'viewValue': 'MARACALAGONIS (CA)'
  },
  {
    'value': 'E904',
    'viewValue': 'MARANELLO (MO)'
  },
  {
    'value': 'E906',
    'viewValue': 'MARANO DI NAPOLI (NA)'
  },
  {
    'value': 'E911',
    'viewValue': 'MARANO DI VALPOLICELLA (VR)'
  },
  {
    'value': 'E908',
    'viewValue': 'MARANO EQUO (RM)'
  },
  {
    'value': 'E910',
    'viewValue': 'MARANO LAGUNARE (UD)'
  },
  {
    'value': 'E914',
    'viewValue': 'MARANO MARCHESATO (CS)'
  },
  {
    'value': 'E915',
    'viewValue': 'MARANO PRINCIPATO (CS)'
  },
  {
    'value': 'E905',
    'viewValue': 'MARANO SUL PANARO (MO)'
  },
  {
    'value': 'E907',
    'viewValue': 'MARANO TICINO (NO)'
  },
  {
    'value': 'E912',
    'viewValue': 'MARANO VICENTINO (VI)'
  },
  {
    'value': 'E917',
    'viewValue': 'MARANZANA (AT)'
  },
  {
    'value': 'E919',
    'viewValue': 'MARATEA (PZ)'
  },
  {
    'value': 'E921',
    'viewValue': 'MARCALLO CON CASONE (MI)'
  },
  {
    'value': 'E922',
    'viewValue': 'MARCARIA (MN)'
  },
  {
    'value': 'E923',
    'viewValue': 'MARCEDUSA (CZ)'
  },
  {
    'value': 'E924',
    'viewValue': 'MARCELLINA (RM)'
  },
  {
    'value': 'E925',
    'viewValue': 'MARCELLINARA (CZ)'
  },
  {
    'value': 'E927',
    'viewValue': 'MARCETELLI (RI)'
  },
  {
    'value': 'E928',
    'viewValue': 'MARCHENO (BS)'
  },
  {
    'value': 'E929',
    'viewValue': 'MARCHIROLO (VA)'
  },
  {
    'value': 'E930',
    'viewValue': 'MARCIANA (LI)'
  },
  {
    'value': 'E931',
    'viewValue': 'MARCIANA MARINA (LI)'
  },
  {
    'value': 'E932',
    'viewValue': 'MARCIANISE (CE)'
  },
  {
    'value': 'E933',
    'viewValue': 'MARCIANO DELLA CHIANA (AR)'
  },
  {
    'value': 'E934',
    'viewValue': 'MARCIGNAGO (PV)'
  },
  {
    'value': 'E936',
    'viewValue': 'MARCON (VE)'
  },
  {
    'value': 'E938',
    'viewValue': 'MAREBBE .ENNEBERG. (BZ)'
  },
  {
    'value': 'E939',
    'viewValue': 'MARENE (CN)'
  },
  {
    'value': 'E940',
    'viewValue': 'MARENO DI PIAVE (TV)'
  },
  {
    'value': 'E941',
    'viewValue': 'MARENTINO (TO)'
  },
  {
    'value': 'E944',
    'viewValue': 'MARETTO (AT)'
  },
  {
    'value': 'E945',
    'viewValue': 'MARGARITA (CN)'
  },
  {
    'value': 'E946',
    'viewValue': 'MARGHERITA DI SAVOIA (FG)'
  },
  {
    'value': 'E947',
    'viewValue': 'MARGNO (LC)'
  },
  {
    'value': 'E949',
    'viewValue': 'MARIANA MANTOVANA (MN)'
  },
  {
    'value': 'E951',
    'viewValue': 'MARIANO COMENSE (CO)'
  },
  {
    'value': 'E952',
    'viewValue': 'MARIANO DEL FRIULI (GO)'
  },
  {
    'value': 'E953',
    'viewValue': 'MARIANOPOLI (CL)'
  },
  {
    'value': 'E954',
    'viewValue': 'MARIGLIANELLA (NA)'
  },
  {
    'value': 'E955',
    'viewValue': 'MARIGLIANO (NA)'
  },
  {
    'value': 'E956',
    'viewValue': 'MARINA DI GIOIOSA IONICA (RC)'
  },
  {
    'value': 'E957',
    'viewValue': 'MARINEO (PA)'
  },
  {
    'value': 'E958',
    'viewValue': 'MARINO (RM)'
  },
  {
    'value': 'E959',
    'viewValue': 'MARLENGO .MARLING. (BZ)'
  },
  {
    'value': 'E960',
    'viewValue': 'MARLIANA (PT)'
  },
  {
    'value': 'E961',
    'viewValue': 'MARMENTINO (BS)'
  },
  {
    'value': 'E962',
    'viewValue': 'MARMIROLO (MN)'
  },
  {
    'value': 'E963',
    'viewValue': 'MARMORA (CN)'
  },
  {
    'value': 'E965',
    'viewValue': 'MARNATE (VA)'
  },
  {
    'value': 'E967',
    'viewValue': 'MARONE (BS)'
  },
  {
    'value': 'E968',
    'viewValue': 'MAROPATI (RC)'
  },
  {
    'value': 'E970',
    'viewValue': 'MAROSTICA (VI)'
  },
  {
    'value': 'E971',
    'viewValue': 'MARRADI (FI)'
  },
  {
    'value': 'E972',
    'viewValue': 'MARRUBIU (OR)'
  },
  {
    'value': 'E973',
    'viewValue': 'MARSAGLIA (CN)'
  },
  {
    'value': 'E974',
    'viewValue': 'MARSALA (TP)'
  },
  {
    'value': 'E975',
    'viewValue': 'MARSCIANO (PG)'
  },
  {
    'value': 'E976',
    'viewValue': 'MARSICO NUOVO (PZ)'
  },
  {
    'value': 'E977',
    'viewValue': 'MARSICOVETERE (PZ)'
  },
  {
    'value': 'E978',
    'viewValue': 'MARTA (VT)'
  },
  {
    'value': 'E979',
    'viewValue': 'MARTANO (LE)'
  },
  {
    'value': 'E980',
    'viewValue': 'MARTELLAGO (VE)'
  },
  {
    'value': 'E981',
    'viewValue': 'MARTELLO .MARTELL. (BZ)'
  },
  {
    'value': 'E982',
    'viewValue': 'MARTIGNACCO (UD)'
  },
  {
    'value': 'E983',
    'viewValue': 'MARTIGNANA DI PO (CR)'
  },
  {
    'value': 'E984',
    'viewValue': 'MARTIGNANO (LE)'
  },
  {
    'value': 'E986',
    'viewValue': 'MARTINA FRANCA (TA)'
  },
  {
    'value': 'E987',
    'viewValue': 'MARTINENGO (BG)'
  },
  {
    'value': 'E988',
    'viewValue': 'MARTINIANA PO (CN)'
  },
  {
    'value': 'E989',
    'viewValue': 'MARTINSICURO (TE)'
  },
  {
    'value': 'E990',
    'viewValue': 'MARTIRANO (CZ)'
  },
  {
    'value': 'E991',
    'viewValue': 'MARTIRANO LOMBARDO (CZ)'
  },
  {
    'value': 'E992',
    'viewValue': 'MARTIS (SS)'
  },
  {
    'value': 'E993',
    'viewValue': 'MARTONE (RC)'
  },
  {
    'value': 'E994',
    'viewValue': 'MARUDO (LO)'
  },
  {
    'value': 'E995',
    'viewValue': 'MARUGGIO (TA)'
  },
  {
    'value': 'B689',
    'viewValue': 'MARZABOTTO (BO)'
  },
  {
    'value': 'E999',
    'viewValue': 'MARZANO (PV)'
  },
  {
    'value': 'E998',
    'viewValue': 'MARZANO APPIO (CE)'
  },
  {
    'value': 'E997',
    'viewValue': 'MARZANO DI NOLA (AV)'
  },
  {
    'value': 'F001',
    'viewValue': 'MARZI (CS)'
  },
  {
    'value': 'F002',
    'viewValue': 'MARZIO (VA)'
  },
  {
    'value': 'M270',
    'viewValue': 'MASAINAS (CA)'
  },
  {
    'value': 'F003',
    'viewValue': 'MASATE (MI)'
  },
  {
    'value': 'F004',
    'viewValue': 'MASCALI (CT)'
  },
  {
    'value': 'F005',
    'viewValue': 'MASCALUCIA (CT)'
  },
  {
    'value': 'F006',
    'viewValue': 'MASCHITO (PZ)'
  },
  {
    'value': 'F007',
    'viewValue': 'MASCIAGO PRIMO (VA)'
  },
  {
    'value': 'F009',
    'viewValue': 'MASER (TV)'
  },
  {
    'value': 'F010',
    'viewValue': 'MASERA (VB)'
  },
  {
    'value': 'F011',
    'viewValue': "MASERA' DI PADOVA (PD)"
  },
  {
    'value': 'F012',
    'viewValue': 'MASERADA SUL PIAVE (TV)'
  },
  {
    'value': 'F013',
    'viewValue': 'MASI (PD)'
  },
  {
    'value': 'F016',
    'viewValue': 'MASI TORELLO (FE)'
  },
  {
    'value': 'F015',
    'viewValue': 'MASIO (AL)'
  },
  {
    'value': 'F017',
    'viewValue': 'MASLIANICO (CO)'
  },
  {
    'value': 'F019',
    'viewValue': 'MASON VICENTINO (VI)'
  },
  {
    'value': 'F020',
    'viewValue': 'MASONE (GE)'
  },
  {
    'value': 'F023',
    'viewValue': 'MASSA (MS)'
  },
  {
    'value': 'F022',
    'viewValue': "MASSA D'ALBE (AQ)"
  },
  {
    'value': 'M289',
    'viewValue': 'MASSA DI SOMMA (NA)'
  },
  {
    'value': 'F025',
    'viewValue': 'MASSA E COZZILE (PT)'
  },
  {
    'value': 'F021',
    'viewValue': 'MASSA FERMANA (AP)'
  },
  {
    'value': 'F026',
    'viewValue': 'MASSA FISCAGLIA (FE)'
  },
  {
    'value': 'F029',
    'viewValue': 'MASSA LOMBARDA (RA)'
  },
  {
    'value': 'F030',
    'viewValue': 'MASSA LUBRENSE (NA)'
  },
  {
    'value': 'F032',
    'viewValue': 'MASSA MARITTIMA (GR)'
  },
  {
    'value': 'F024',
    'viewValue': 'MASSA MARTANA (PG)'
  },
  {
    'value': 'F027',
    'viewValue': 'MASSAFRA (TA)'
  },
  {
    'value': 'F028',
    'viewValue': 'MASSALENGO (LO)'
  },
  {
    'value': 'F033',
    'viewValue': 'MASSANZAGO (PD)'
  },
  {
    'value': 'F035',
    'viewValue': 'MASSAROSA (LU)'
  },
  {
    'value': 'F037',
    'viewValue': 'MASSAZZA (BI)'
  },
  {
    'value': 'F041',
    'viewValue': 'MASSELLO (TO)'
  },
  {
    'value': 'F042',
    'viewValue': 'MASSERANO (BI)'
  },
  {
    'value': 'F044',
    'viewValue': 'MASSIGNANO (AP)'
  },
  {
    'value': 'F045',
    'viewValue': 'MASSIMENO (TN)'
  },
  {
    'value': 'F046',
    'viewValue': 'MASSIMINO (SV)'
  },
  {
    'value': 'F047',
    'viewValue': 'MASSINO VISCONTI (NO)'
  },
  {
    'value': 'F048',
    'viewValue': 'MASSIOLA (VB)'
  },
  {
    'value': 'F050',
    'viewValue': 'MASULLAS (OR)'
  },
  {
    'value': 'F051',
    'viewValue': 'MATELICA (MC)'
  },
  {
    'value': 'F052',
    'viewValue': 'MATERA (MT)'
  },
  {
    'value': 'F053',
    'viewValue': 'MATHI (TO)'
  },
  {
    'value': 'F054',
    'viewValue': 'MATINO (LE)'
  },
  {
    'value': 'F055',
    'viewValue': 'MATRICE (CB)'
  },
  {
    'value': 'F058',
    'viewValue': 'MATTIE (TO)'
  },
  {
    'value': 'F059',
    'viewValue': 'MATTINATA (FG)'
  },
  {
    'value': 'F061',
    'viewValue': 'MAZARA DEL VALLO (TP)'
  },
  {
    'value': 'F063',
    'viewValue': 'MAZZANO (BS)'
  },
  {
    'value': 'F064',
    'viewValue': 'MAZZANO ROMANO (RM)'
  },
  {
    'value': 'F065',
    'viewValue': 'MAZZARINO (CL)'
  },
  {
    'value': 'F066',
    'viewValue': "MAZZARRA' SANT'ANDREA (ME)"
  },
  {
    'value': 'M271',
    'viewValue': 'MAZZARRONE (CT)'
  },
  {
    'value': 'F067',
    'viewValue': "MAZZE' (TO)"
  },
  {
    'value': 'F068',
    'viewValue': 'MAZZIN (TN)'
  },
  {
    'value': 'F070',
    'viewValue': 'MAZZO DI VALTELLINA (SO)'
  },
  {
    'value': 'F074',
    'viewValue': 'MEANA DI SUSA (TO)'
  },
  {
    'value': 'F073',
    'viewValue': 'MEANA SARDO (NU)'
  },
  {
    'value': 'F078',
    'viewValue': 'MEDA (MI)'
  },
  {
    'value': 'F080',
    'viewValue': 'MEDE (PV)'
  },
  {
    'value': 'F081',
    'viewValue': 'MEDEA (GO)'
  },
  {
    'value': 'F082',
    'viewValue': 'MEDESANO (PR)'
  },
  {
    'value': 'F083',
    'viewValue': 'MEDICINA (BO)'
  },
  {
    'value': 'F084',
    'viewValue': 'MEDIGLIA (MI)'
  },
  {
    'value': 'F085',
    'viewValue': 'MEDOLAGO (BG)'
  },
  {
    'value': 'F086',
    'viewValue': 'MEDOLE (MN)'
  },
  {
    'value': 'F087',
    'viewValue': 'MEDOLLA (MO)'
  },
  {
    'value': 'F088',
    'viewValue': 'MEDUNA DI LIVENZA (TV)'
  },
  {
    'value': 'F089',
    'viewValue': 'MEDUNO (PN)'
  },
  {
    'value': 'F091',
    'viewValue': 'MEGLIADINO SAN FIDENZIO (PD)'
  },
  {
    'value': 'F092',
    'viewValue': 'MEGLIADINO SAN VITALE (PD)'
  },
  {
    'value': 'F093',
    'viewValue': 'MEINA (NO)'
  },
  {
    'value': 'F094',
    'viewValue': 'MEL (BL)'
  },
  {
    'value': 'F095',
    'viewValue': 'MELARA (RO)'
  },
  {
    'value': 'F096',
    'viewValue': 'MELAZZO (AL)'
  },
  {
    'value': 'F097',
    'viewValue': 'MELDOLA (FC)'
  },
  {
    'value': 'F098',
    'viewValue': 'MELE (GE)'
  },
  {
    'value': 'F100',
    'viewValue': 'MELEGNANO (MI)'
  },
  {
    'value': 'F101',
    'viewValue': 'MELENDUGNO (LE)'
  },
  {
    'value': 'F102',
    'viewValue': 'MELETI (LO)'
  },
  {
    'value': 'F104',
    'viewValue': 'MELFI (PZ)'
  },
  {
    'value': 'F105',
    'viewValue': "MELICUCCA' (RC)"
  },
  {
    'value': 'F106',
    'viewValue': 'MELICUCCO (RC)'
  },
  {
    'value': 'F107',
    'viewValue': 'MELILLI (SR)'
  },
  {
    'value': 'F108',
    'viewValue': 'MELISSA (KR)'
  },
  {
    'value': 'F109',
    'viewValue': 'MELISSANO (LE)'
  },
  {
    'value': 'F111',
    'viewValue': 'MELITO DI NAPOLI (NA)'
  },
  {
    'value': 'F112',
    'viewValue': 'MELITO DI PORTO SALVO (RC)'
  },
  {
    'value': 'F110',
    'viewValue': 'MELITO IRPINO (AV)'
  },
  {
    'value': 'F113',
    'viewValue': 'MELIZZANO (BN)'
  },
  {
    'value': 'F114',
    'viewValue': 'MELLE (CN)'
  },
  {
    'value': 'F115',
    'viewValue': 'MELLO (SO)'
  },
  {
    'value': 'F117',
    'viewValue': 'MELPIGNANO (LE)'
  },
  {
    'value': 'F118',
    'viewValue': 'MELTINA .MOLTEN. (BZ)'
  },
  {
    'value': 'F119',
    'viewValue': 'MELZO (MI)'
  },
  {
    'value': 'F120',
    'viewValue': 'MENAGGIO (CO)'
  },
  {
    'value': 'F121',
    'viewValue': 'MENAROLA (SO)'
  },
  {
    'value': 'F122',
    'viewValue': 'MENCONICO (PV)'
  },
  {
    'value': 'F123',
    'viewValue': 'MENDATICA (IM)'
  },
  {
    'value': 'F125',
    'viewValue': 'MENDICINO (CS)'
  },
  {
    'value': 'F126',
    'viewValue': 'MENFI (AG)'
  },
  {
    'value': 'F127',
    'viewValue': 'MENTANA (RM)'
  },
  {
    'value': 'F130',
    'viewValue': 'MEOLO (VE)'
  },
  {
    'value': 'F131',
    'viewValue': 'MERANA (AL)'
  },
  {
    'value': 'F132',
    'viewValue': 'MERANO .MERAN. (BZ)'
  },
  {
    'value': 'F133',
    'viewValue': 'MERATE (LC)'
  },
  {
    'value': 'F134',
    'viewValue': 'MERCALLO (VA)'
  },
  {
    'value': 'F135',
    'viewValue': 'MERCATELLO SUL METAURO (PU)'
  },
  {
    'value': 'F136',
    'viewValue': 'MERCATINO CONCA (PU)'
  },
  {
    'value': 'F138',
    'viewValue': 'MERCATO SAN SEVERINO (SA)'
  },
  {
    'value': 'F139',
    'viewValue': 'MERCATO SARACENO (FC)'
  },
  {
    'value': 'F140',
    'viewValue': 'MERCENASCO (TO)'
  },
  {
    'value': 'F141',
    'viewValue': 'MERCOGLIANO (AV)'
  },
  {
    'value': 'F144',
    'viewValue': 'MERETO DI TOMBA (UD)'
  },
  {
    'value': 'F145',
    'viewValue': 'MERGO (AN)'
  },
  {
    'value': 'F146',
    'viewValue': 'MERGOZZO (VB)'
  },
  {
    'value': 'F147',
    'viewValue': "MERI' (ME)"
  },
  {
    'value': 'F148',
    'viewValue': 'MERLARA (PD)'
  },
  {
    'value': 'F149',
    'viewValue': 'MERLINO (LO)'
  },
  {
    'value': 'F151',
    'viewValue': 'MERONE (CO)'
  },
  {
    'value': 'F152',
    'viewValue': 'MESAGNE (BR)'
  },
  {
    'value': 'F153',
    'viewValue': 'MESE (SO)'
  },
  {
    'value': 'F154',
    'viewValue': 'MESENZANA (VA)'
  },
  {
    'value': 'F155',
    'viewValue': 'MESERO (MI)'
  },
  {
    'value': 'F156',
    'viewValue': 'MESOLA (FE)'
  },
  {
    'value': 'F157',
    'viewValue': 'MESORACA (KR)'
  },
  {
    'value': 'F158',
    'viewValue': 'MESSINA (ME)'
  },
  {
    'value': 'F161',
    'viewValue': 'MESTRINO (PD)'
  },
  {
    'value': 'F162',
    'viewValue': 'META (NA)'
  },
  {
    'value': 'F164',
    'viewValue': 'MEUGLIANO (TO)'
  },
  {
    'value': 'F165',
    'viewValue': 'MEZZAGO (MI)'
  },
  {
    'value': 'F168',
    'viewValue': 'MEZZANA (TN)'
  },
  {
    'value': 'F170',
    'viewValue': 'MEZZANA BIGLI (PV)'
  },
  {
    'value': 'F167',
    'viewValue': 'MEZZANA MORTIGLIENGO (BI)'
  },
  {
    'value': 'F171',
    'viewValue': 'MEZZANA RABATTONE (PV)'
  },
  {
    'value': 'F172',
    'viewValue': 'MEZZANE DI SOTTO (VR)'
  },
  {
    'value': 'F173',
    'viewValue': 'MEZZANEGO (GE)'
  },
  {
    'value': 'F174',
    'viewValue': 'MEZZANI (PR)'
  },
  {
    'value': 'F175',
    'viewValue': 'MEZZANINO (PV)'
  },
  {
    'value': 'F176',
    'viewValue': 'MEZZANO (TN)'
  },
  {
    'value': 'F181',
    'viewValue': 'MEZZEGRA (CO)'
  },
  {
    'value': 'F182',
    'viewValue': 'MEZZENILE (TO)'
  },
  {
    'value': 'F183',
    'viewValue': 'MEZZOCORONA (TN)'
  },
  {
    'value': 'F184',
    'viewValue': 'MEZZOJUSO (PA)'
  },
  {
    'value': 'F186',
    'viewValue': 'MEZZOLDO (BG)'
  },
  {
    'value': 'F187',
    'viewValue': 'MEZZOLOMBARDO (TN)'
  },
  {
    'value': 'F188',
    'viewValue': 'MEZZOMERICO (NO)'
  },
  {
    'value': 'F189',
    'viewValue': 'MIAGLIANO (BI)'
  },
  {
    'value': 'F190',
    'viewValue': 'MIANE (TV)'
  },
  {
    'value': 'F191',
    'viewValue': 'MIASINO (NO)'
  },
  {
    'value': 'F192',
    'viewValue': 'MIAZZINA (VB)'
  },
  {
    'value': 'F193',
    'viewValue': 'MICIGLIANO (RI)'
  },
  {
    'value': 'F194',
    'viewValue': 'MIGGIANO (LE)'
  },
  {
    'value': 'F196',
    'viewValue': 'MIGLIANICO (CH)'
  },
  {
    'value': 'F198',
    'viewValue': 'MIGLIARINO (FE)'
  },
  {
    'value': 'F199',
    'viewValue': 'MIGLIARO (FE)'
  },
  {
    'value': 'F200',
    'viewValue': 'MIGLIERINA (CZ)'
  },
  {
    'value': 'F201',
    'viewValue': 'MIGLIONICO (MT)'
  },
  {
    'value': 'F202',
    'viewValue': 'MIGNANEGO (GE)'
  },
  {
    'value': 'F203',
    'viewValue': 'MIGNANO MONTE LUNGO (CE)'
  },
  {
    'value': 'F205',
    'viewValue': 'MILANO (MI)'
  },
  {
    'value': 'F206',
    'viewValue': 'MILAZZO (ME)'
  },
  {
    'value': 'E618',
    'viewValue': 'MILENA (CL)'
  },
  {
    'value': 'F207',
    'viewValue': 'MILETO (VV)'
  },
  {
    'value': 'F208',
    'viewValue': 'MILIS (OR)'
  },
  {
    'value': 'F209',
    'viewValue': 'MILITELLO IN VAL DI CATANIA (CT)'
  },
  {
    'value': 'F210',
    'viewValue': 'MILITELLO ROSMARINO (ME)'
  },
  {
    'value': 'F213',
    'viewValue': 'MILLESIMO (SV)'
  },
  {
    'value': 'F214',
    'viewValue': 'MILO (CT)'
  },
  {
    'value': 'F216',
    'viewValue': 'MILZANO (BS)'
  },
  {
    'value': 'F217',
    'viewValue': 'MINEO (CT)'
  },
  {
    'value': 'F218',
    'viewValue': 'MINERBE (VR)'
  },
  {
    'value': 'F219',
    'viewValue': 'MINERBIO (BO)'
  },
  {
    'value': 'F221',
    'viewValue': 'MINERVINO DI LECCE (LE)'
  },
  {
    'value': 'F220',
    'viewValue': 'MINERVINO MURGE (BA)'
  },
  {
    'value': 'F223',
    'viewValue': 'MINORI (SA)'
  },
  {
    'value': 'F224',
    'viewValue': 'MINTURNO (LT)'
  },
  {
    'value': 'F225',
    'viewValue': 'MINUCCIANO (LU)'
  },
  {
    'value': 'F226',
    'viewValue': 'MIOGLIA (SV)'
  },
  {
    'value': 'F229',
    'viewValue': 'MIRA (VE)'
  },
  {
    'value': 'F230',
    'viewValue': 'MIRABELLA ECLANO (AV)'
  },
  {
    'value': 'F231',
    'viewValue': 'MIRABELLA IMBACCARI (CT)'
  },
  {
    'value': 'F235',
    'viewValue': 'MIRABELLO (FE)'
  },
  {
    'value': 'F232',
    'viewValue': 'MIRABELLO MONFERRATO (AL)'
  },
  {
    'value': 'F233',
    'viewValue': 'MIRABELLO SANNITICO (CB)'
  },
  {
    'value': 'F238',
    'viewValue': 'MIRADOLO TERME (PV)'
  },
  {
    'value': 'F239',
    'viewValue': 'MIRANDA (IS)'
  },
  {
    'value': 'F240',
    'viewValue': 'MIRANDOLA (MO)'
  },
  {
    'value': 'F241',
    'viewValue': 'MIRANO (VE)'
  },
  {
    'value': 'F242',
    'viewValue': 'MIRTO (ME)'
  },
  {
    'value': 'F244',
    'viewValue': 'MISANO ADRIATICO (RN)'
  },
  {
    'value': 'F243',
    'viewValue': "MISANO DI GERA D'ADDA (BG)"
  },
  {
    'value': 'F246',
    'viewValue': 'MISILMERI (PA)'
  },
  {
    'value': 'F247',
    'viewValue': 'MISINTO (MI)'
  },
  {
    'value': 'F248',
    'viewValue': 'MISSAGLIA (LC)'
  },
  {
    'value': 'F249',
    'viewValue': 'MISSANELLO (PZ)'
  },
  {
    'value': 'F250',
    'viewValue': 'MISTERBIANCO (CT)'
  },
  {
    'value': 'F251',
    'viewValue': 'MISTRETTA (ME)'
  },
  {
    'value': 'F254',
    'viewValue': 'MOASCA (AT)'
  },
  {
    'value': 'F256',
    'viewValue': 'MOCONESI (GE)'
  },
  {
    'value': 'F257',
    'viewValue': 'MODENA (MO)'
  },
  {
    'value': 'F258',
    'viewValue': 'MODICA (RG)'
  },
  {
    'value': 'F259',
    'viewValue': 'MODIGLIANA (FC)'
  },
  {
    'value': 'F261',
    'viewValue': 'MODOLO (NU)'
  },
  {
    'value': 'F262',
    'viewValue': 'MODUGNO (BA)'
  },
  {
    'value': 'F263',
    'viewValue': 'MOENA (TN)'
  },
  {
    'value': 'F265',
    'viewValue': 'MOGGIO (LC)'
  },
  {
    'value': 'F266',
    'viewValue': 'MOGGIO UDINESE (UD)'
  },
  {
    'value': 'F267',
    'viewValue': 'MOGLIA (MN)'
  },
  {
    'value': 'F268',
    'viewValue': 'MOGLIANO (MC)'
  },
  {
    'value': 'F269',
    'viewValue': 'MOGLIANO VENETO (TV)'
  },
  {
    'value': 'F270',
    'viewValue': 'MOGORELLA (OR)'
  },
  {
    'value': 'F272',
    'viewValue': 'MOGORO (OR)'
  },
  {
    'value': 'F274',
    'viewValue': 'MOIANO (BN)'
  },
  {
    'value': 'F275',
    'viewValue': 'MOIMACCO (UD)'
  },
  {
    'value': 'F277',
    'viewValue': 'MOIO ALCANTARA (ME)'
  },
  {
    'value': 'F276',
    'viewValue': "MOIO DE' CALVI (BG)"
  },
  {
    'value': 'F278',
    'viewValue': 'MOIO DELLA CIVITELLA (SA)'
  },
  {
    'value': 'F279',
    'viewValue': 'MOIOLA (CN)'
  },
  {
    'value': 'F280',
    'viewValue': 'MOLA DI BARI (BA)'
  },
  {
    'value': 'F281',
    'viewValue': 'MOLARE (AL)'
  },
  {
    'value': 'F283',
    'viewValue': 'MOLAZZANA (LU)'
  },
  {
    'value': 'F284',
    'viewValue': 'MOLFETTA (BA)'
  },
  {
    'value': 'M255',
    'viewValue': 'MOLINA ATERNO (AQ)'
  },
  {
    'value': 'F286',
    'viewValue': 'MOLINA DI LEDRO (TN)'
  },
  {
    'value': 'F287',
    'viewValue': 'MOLINARA (BN)'
  },
  {
    'value': 'F288',
    'viewValue': 'MOLINELLA (BO)'
  },
  {
    'value': 'F290',
    'viewValue': 'MOLINI DI TRIORA (IM)'
  },
  {
    'value': 'F293',
    'viewValue': 'MOLINO DEI TORTI (AL)'
  },
  {
    'value': 'F294',
    'viewValue': 'MOLISE (CB)'
  },
  {
    'value': 'F295',
    'viewValue': 'MOLITERNO (PZ)'
  },
  {
    'value': 'F297',
    'viewValue': 'MOLLIA (VC)'
  },
  {
    'value': 'F301',
    'viewValue': 'MOLOCHIO (RC)'
  },
  {
    'value': 'F304',
    'viewValue': 'MOLTENO (LC)'
  },
  {
    'value': 'F305',
    'viewValue': 'MOLTRASIO (CO)'
  },
  {
    'value': 'F306',
    'viewValue': 'MOLVENA (VI)'
  },
  {
    'value': 'F307',
    'viewValue': 'MOLVENO (TN)'
  },
  {
    'value': 'F308',
    'viewValue': 'MOMBALDONE (AT)'
  },
  {
    'value': 'F309',
    'viewValue': 'MOMBARCARO (CN)'
  },
  {
    'value': 'F310',
    'viewValue': 'MOMBAROCCIO (PU)'
  },
  {
    'value': 'F311',
    'viewValue': 'MOMBARUZZO (AT)'
  },
  {
    'value': 'F312',
    'viewValue': 'MOMBASIGLIO (CN)'
  },
  {
    'value': 'F315',
    'viewValue': 'MOMBELLO DI TORINO (TO)'
  },
  {
    'value': 'F313',
    'viewValue': 'MOMBELLO MONFERRATO (AL)'
  },
  {
    'value': 'F316',
    'viewValue': 'MOMBERCELLI (AT)'
  },
  {
    'value': 'F317',
    'viewValue': 'MOMO (NO)'
  },
  {
    'value': 'F318',
    'viewValue': 'MOMPANTERO (TO)'
  },
  {
    'value': 'F319',
    'viewValue': 'MOMPEO (RI)'
  },
  {
    'value': 'F320',
    'viewValue': 'MOMPERONE (AL)'
  },
  {
    'value': 'F322',
    'viewValue': 'MONACILIONI (CB)'
  },
  {
    'value': 'F323',
    'viewValue': 'MONALE (AT)'
  },
  {
    'value': 'F324',
    'viewValue': 'MONASTERACE (RC)'
  },
  {
    'value': 'F325',
    'viewValue': 'MONASTERO BORMIDA (AT)'
  },
  {
    'value': 'F327',
    'viewValue': 'MONASTERO DI LANZO (TO)'
  },
  {
    'value': 'F326',
    'viewValue': 'MONASTERO DI VASCO (CN)'
  },
  {
    'value': 'F329',
    'viewValue': 'MONASTEROLO CASOTTO (CN)'
  },
  {
    'value': 'F328',
    'viewValue': 'MONASTEROLO DEL CASTELLO (BG)'
  },
  {
    'value': 'F330',
    'viewValue': 'MONASTEROLO DI SAVIGLIANO (CN)'
  },
  {
    'value': 'F332',
    'viewValue': 'MONASTIER DI TREVISO (TV)'
  },
  {
    'value': 'F333',
    'viewValue': 'MONASTIR (CA)'
  },
  {
    'value': 'F335',
    'viewValue': 'MONCALIERI (TO)'
  },
  {
    'value': 'F336',
    'viewValue': 'MONCALVO (AT)'
  },
  {
    'value': 'D553',
    'viewValue': 'MONCENISIO (TO)'
  },
  {
    'value': 'F337',
    'viewValue': 'MONCESTINO (AL)'
  },
  {
    'value': 'F338',
    'viewValue': 'MONCHIERO (CN)'
  },
  {
    'value': 'F340',
    'viewValue': 'MONCHIO DELLE CORTI (PR)'
  },
  {
    'value': 'F341',
    'viewValue': 'MONCLASSICO (TN)'
  },
  {
    'value': 'F342',
    'viewValue': 'MONCRIVELLO (VC)'
  },
  {
    'value': 'F343',
    'viewValue': 'MONCUCCO TORINESE (AT)'
  },
  {
    'value': 'F346',
    'viewValue': 'MONDAINO (RN)'
  },
  {
    'value': 'F347',
    'viewValue': 'MONDAVIO (PU)'
  },
  {
    'value': 'F348',
    'viewValue': 'MONDOLFO (PU)'
  },
  {
    'value': 'F351',
    'viewValue': "MONDOVI' (CN)"
  },
  {
    'value': 'F352',
    'viewValue': 'MONDRAGONE (CE)'
  },
  {
    'value': 'F354',
    'viewValue': 'MONEGLIA (GE)'
  },
  {
    'value': 'F355',
    'viewValue': 'MONESIGLIO (CN)'
  },
  {
    'value': 'F356',
    'viewValue': 'MONFALCONE (GO)'
  },
  {
    'value': 'F358',
    'viewValue': "MONFORTE D'ALBA (CN)"
  },
  {
    'value': 'F359',
    'viewValue': 'MONFORTE SAN GIORGIO (ME)'
  },
  {
    'value': 'F360',
    'viewValue': 'MONFUMO (TV)'
  },
  {
    'value': 'F361',
    'viewValue': 'MONGARDINO (AT)'
  },
  {
    'value': 'F363',
    'viewValue': 'MONGHIDORO (BO)'
  },
  {
    'value': 'F364',
    'viewValue': 'MONGIANA (VV)'
  },
  {
    'value': 'F365',
    'viewValue': 'MONGIARDINO LIGURE (AL)'
  },
  {
    'value': 'F368',
    'viewValue': 'MONGIUFFI MELIA (ME)'
  },
  {
    'value': 'F369',
    'viewValue': 'MONGRANDO (BI)'
  },
  {
    'value': 'F370',
    'viewValue': 'MONGRASSANO (CS)'
  },
  {
    'value': 'F371',
    'viewValue': 'MONGUELFO .WELSBERG. (BZ)'
  },
  {
    'value': 'F372',
    'viewValue': 'MONGUZZO (CO)'
  },
  {
    'value': 'F373',
    'viewValue': 'MONIGA DEL GARDA (BS)'
  },
  {
    'value': 'F374',
    'viewValue': 'MONLEALE (AL)'
  },
  {
    'value': 'F375',
    'viewValue': 'MONNO (BS)'
  },
  {
    'value': 'F376',
    'viewValue': 'MONOPOLI (BA)'
  },
  {
    'value': 'F377',
    'viewValue': 'MONREALE (PA)'
  },
  {
    'value': 'F378',
    'viewValue': 'MONRUPINO (TS)'
  },
  {
    'value': 'F379',
    'viewValue': 'MONSAMPIETRO MORICO (AP)'
  },
  {
    'value': 'F380',
    'viewValue': 'MONSAMPOLO DEL TRONTO (AP)'
  },
  {
    'value': 'F381',
    'viewValue': 'MONSANO (AN)'
  },
  {
    'value': 'F382',
    'viewValue': 'MONSELICE (PD)'
  },
  {
    'value': 'F383',
    'viewValue': 'MONSERRATO (CA)'
  },
  {
    'value': 'F384',
    'viewValue': 'MONSUMMANO TERME (PT)'
  },
  {
    'value': 'F385',
    'viewValue': "MONTA' (CN)"
  },
  {
    'value': 'F386',
    'viewValue': 'MONTABONE (AT)'
  },
  {
    'value': 'F387',
    'viewValue': 'MONTACUTO (AL)'
  },
  {
    'value': 'F390',
    'viewValue': 'MONTAFIA (AT)'
  },
  {
    'value': 'F391',
    'viewValue': 'MONTAGANO (CB)'
  },
  {
    'value': 'F392',
    'viewValue': 'MONTAGNA .MONTAN. (BZ)'
  },
  {
    'value': 'F393',
    'viewValue': 'MONTAGNA IN VALTELLINA (SO)'
  },
  {
    'value': 'F394',
    'viewValue': 'MONTAGNANA (PD)'
  },
  {
    'value': 'F395',
    'viewValue': 'MONTAGNAREALE (ME)'
  },
  {
    'value': 'F396',
    'viewValue': 'MONTAGNE (TN)'
  },
  {
    'value': 'F397',
    'viewValue': 'MONTAGUTO (AV)'
  },
  {
    'value': 'F398',
    'viewValue': 'MONTAIONE (FI)'
  },
  {
    'value': 'F400',
    'viewValue': 'MONTALBANO ELICONA (ME)'
  },
  {
    'value': 'F399',
    'viewValue': 'MONTALBANO JONICO (MT)'
  },
  {
    'value': 'F402',
    'viewValue': 'MONTALCINO (SI)'
  },
  {
    'value': 'F403',
    'viewValue': 'MONTALDEO (AL)'
  },
  {
    'value': 'F404',
    'viewValue': 'MONTALDO BORMIDA (AL)'
  },
  {
    'value': 'F405',
    'viewValue': "MONTALDO DI MONDOVI' (CN)"
  },
  {
    'value': 'F408',
    'viewValue': 'MONTALDO ROERO (CN)'
  },
  {
    'value': 'F409',
    'viewValue': 'MONTALDO SCARAMPI (AT)'
  },
  {
    'value': 'F407',
    'viewValue': 'MONTALDO TORINESE (TO)'
  },
  {
    'value': 'F410',
    'viewValue': 'MONTALE (PT)'
  },
  {
    'value': 'F411',
    'viewValue': 'MONTALENGHE (TO)'
  },
  {
    'value': 'F414',
    'viewValue': 'MONTALLEGRO (AG)'
  },
  {
    'value': 'F415',
    'viewValue': 'MONTALTO DELLE MARCHE (AP)'
  },
  {
    'value': 'F419',
    'viewValue': 'MONTALTO DI CASTRO (VT)'
  },
  {
    'value': 'F420',
    'viewValue': 'MONTALTO DORA (TO)'
  },
  {
    'value': 'F406',
    'viewValue': 'MONTALTO LIGURE (IM)'
  },
  {
    'value': 'F417',
    'viewValue': 'MONTALTO PAVESE (PV)'
  },
  {
    'value': 'F416',
    'viewValue': 'MONTALTO UFFUGO (CS)'
  },
  {
    'value': 'F422',
    'viewValue': 'MONTANARO (TO)'
  },
  {
    'value': 'F423',
    'viewValue': 'MONTANASO LOMBARDO (LO)'
  },
  {
    'value': 'F424',
    'viewValue': 'MONTANERA (CN)'
  },
  {
    'value': 'F426',
    'viewValue': 'MONTANO ANTILIA (SA)'
  },
  {
    'value': 'F427',
    'viewValue': 'MONTANO LUCINO (CO)'
  },
  {
    'value': 'F428',
    'viewValue': 'MONTAPPONE (AP)'
  },
  {
    'value': 'F429',
    'viewValue': 'MONTAQUILA (IS)'
  },
  {
    'value': 'F430',
    'viewValue': 'MONTASOLA (RI)'
  },
  {
    'value': 'F432',
    'viewValue': 'MONTAURO (CZ)'
  },
  {
    'value': 'F433',
    'viewValue': 'MONTAZZOLI (CH)'
  },
  {
    'value': 'F437',
    'viewValue': 'MONTE ARGENTARIO (GR)'
  },
  {
    'value': 'F456',
    'viewValue': 'MONTE CASTELLO DI VIBIO (PG)'
  },
  {
    'value': 'F460',
    'viewValue': 'MONTE CAVALLO (MC)'
  },
  {
    'value': 'F467',
    'viewValue': 'MONTE CERIGNONE (PU)'
  },
  {
    'value': 'F476',
    'viewValue': 'MONTE COLOMBO (RN)'
  },
  {
    'value': 'F434',
    'viewValue': 'MONTE CREMASCO (CR)'
  },
  {
    'value': 'F486',
    'viewValue': 'MONTE DI MALO (VI)'
  },
  {
    'value': 'F488',
    'viewValue': 'MONTE DI PROCIDA (NA)'
  },
  {
    'value': 'F517',
    'viewValue': 'MONTE GIBERTO (AP)'
  },
  {
    'value': 'F524',
    'viewValue': 'MONTE GRIMANO TERME (PU)'
  },
  {
    'value': 'F532',
    'viewValue': 'MONTE ISOLA (BS)'
  },
  {
    'value': 'F561',
    'viewValue': 'MONTE MARENZO (LC)'
  },
  {
    'value': 'F589',
    'viewValue': 'MONTE PORZIO (PU)'
  },
  {
    'value': 'F590',
    'viewValue': 'MONTE PORZIO CATONE (RM)'
  },
  {
    'value': 'F599',
    'viewValue': 'MONTE RINALDO (AP)'
  },
  {
    'value': 'F600',
    'viewValue': 'MONTE ROBERTO (AN)'
  },
  {
    'value': 'F603',
    'viewValue': 'MONTE ROMANO (VT)'
  },
  {
    'value': 'F616',
    'viewValue': 'MONTE SAN BIAGIO (LT)'
  },
  {
    'value': 'F618',
    'viewValue': 'MONTE SAN GIACOMO (SA)'
  },
  {
    'value': 'F620',
    'viewValue': 'MONTE SAN GIOVANNI CAMPANO (FR)'
  },
  {
    'value': 'F619',
    'viewValue': 'MONTE SAN GIOVANNI IN SABINA (RI)'
  },
  {
    'value': 'F621',
    'viewValue': 'MONTE SAN GIUSTO (MC)'
  },
  {
    'value': 'F622',
    'viewValue': 'MONTE SAN MARTINO (MC)'
  },
  {
    'value': 'F626',
    'viewValue': 'MONTE SAN PIETRANGELI (AP)'
  },
  {
    'value': 'F627',
    'viewValue': 'MONTE SAN PIETRO (BO)'
  },
  {
    'value': 'F628',
    'viewValue': 'MONTE SAN SAVINO (AR)'
  },
  {
    'value': 'F634',
    'viewValue': 'MONTE SAN VITO (AN)'
  },
  {
    'value': 'F629',
    'viewValue': 'MONTE SANTA MARIA TIBERINA (PG)'
  },
  {
    'value': 'F631',
    'viewValue': "MONTE SANT'ANGELO (FG)"
  },
  {
    'value': 'F653',
    'viewValue': 'MONTE URANO (AP)'
  },
  {
    'value': 'F664',
    'viewValue': 'MONTE VIDON COMBATTE (AP)'
  },
  {
    'value': 'F665',
    'viewValue': 'MONTE VIDON CORRADO (AP)'
  },
  {
    'value': 'F440',
    'viewValue': 'MONTEBELLO DELLA BATTAGLIA (PV)'
  },
  {
    'value': 'F441',
    'viewValue': 'MONTEBELLO DI BERTONA (PE)'
  },
  {
    'value': 'D746',
    'viewValue': 'MONTEBELLO IONICO (RC)'
  },
  {
    'value': 'B268',
    'viewValue': 'MONTEBELLO SUL SANGRO (CH)'
  },
  {
    'value': 'F442',
    'viewValue': 'MONTEBELLO VICENTINO (VI)'
  },
  {
    'value': 'F443',
    'viewValue': 'MONTEBELLUNA (TV)'
  },
  {
    'value': 'F445',
    'viewValue': 'MONTEBRUNO (GE)'
  },
  {
    'value': 'F446',
    'viewValue': 'MONTEBUONO (RI)'
  },
  {
    'value': 'F450',
    'viewValue': 'MONTECALVO IN FOGLIA (PU)'
  },
  {
    'value': 'F448',
    'viewValue': 'MONTECALVO IRPINO (AV)'
  },
  {
    'value': 'F449',
    'viewValue': 'MONTECALVO VERSIGGIA (PV)'
  },
  {
    'value': 'F452',
    'viewValue': 'MONTECARLO (LU)'
  },
  {
    'value': 'F453',
    'viewValue': 'MONTECAROTTO (AN)'
  },
  {
    'value': 'F454',
    'viewValue': 'MONTECASSIANO (MC)'
  },
  {
    'value': 'F455',
    'viewValue': 'MONTECASTELLO (AL)'
  },
  {
    'value': 'F457',
    'viewValue': 'MONTECASTRILLI (TR)'
  },
  {
    'value': 'A561',
    'viewValue': 'MONTECATINI TERME (PT)'
  },
  {
    'value': 'F458',
    'viewValue': 'MONTECATINI VAL DI CECINA (PI)'
  },
  {
    'value': 'F461',
    'viewValue': 'MONTECCHIA DI CROSARA (VR)'
  },
  {
    'value': 'F462',
    'viewValue': 'MONTECCHIO (TR)'
  },
  {
    'value': 'F463',
    'viewValue': 'MONTECCHIO EMILIA (RE)'
  },
  {
    'value': 'F464',
    'viewValue': 'MONTECCHIO MAGGIORE (VI)'
  },
  {
    'value': 'F465',
    'viewValue': 'MONTECCHIO PRECALCINO (VI)'
  },
  {
    'value': 'F469',
    'viewValue': "MONTECHIARO D'ACQUI (AL)"
  },
  {
    'value': 'F468',
    'viewValue': "MONTECHIARO D'ASTI (AT)"
  },
  {
    'value': 'F473',
    'viewValue': 'MONTECHIARUGOLO (PR)'
  },
  {
    'value': 'F474',
    'viewValue': 'MONTECICCARDO (PU)'
  },
  {
    'value': 'F475',
    'viewValue': 'MONTECILFONE (CB)'
  },
  {
    'value': 'F477',
    'viewValue': 'MONTECOMPATRI (RM)'
  },
  {
    'value': 'F478',
    'viewValue': 'MONTECOPIOLO (PU)'
  },
  {
    'value': 'F479',
    'viewValue': 'MONTECORICE (SA)'
  },
  {
    'value': 'F480',
    'viewValue': 'MONTECORVINO PUGLIANO (SA)'
  },
  {
    'value': 'F481',
    'viewValue': 'MONTECORVINO ROVELLA (SA)'
  },
  {
    'value': 'F482',
    'viewValue': 'MONTECOSARO (MC)'
  },
  {
    'value': 'F483',
    'viewValue': 'MONTECRESTESE (VB)'
  },
  {
    'value': 'F484',
    'viewValue': 'MONTECRETO (MO)'
  },
  {
    'value': 'F487',
    'viewValue': 'MONTEDINOVE (AP)'
  },
  {
    'value': 'F489',
    'viewValue': 'MONTEDORO (CL)'
  },
  {
    'value': 'F491',
    'viewValue': 'MONTEFALCIONE (AV)'
  },
  {
    'value': 'F492',
    'viewValue': 'MONTEFALCO (PG)'
  },
  {
    'value': 'F493',
    'viewValue': 'MONTEFALCONE APPENNINO (AP)'
  },
  {
    'value': 'F494',
    'viewValue': 'MONTEFALCONE DI VAL FORTORE (BN)'
  },
  {
    'value': 'F495',
    'viewValue': 'MONTEFALCONE NEL SANNIO (CB)'
  },
  {
    'value': 'F496',
    'viewValue': 'MONTEFANO (MC)'
  },
  {
    'value': 'F497',
    'viewValue': 'MONTEFELCINO (PU)'
  },
  {
    'value': 'F498',
    'viewValue': 'MONTEFERRANTE (CH)'
  },
  {
    'value': 'F499',
    'viewValue': 'MONTEFIASCONE (VT)'
  },
  {
    'value': 'F500',
    'viewValue': 'MONTEFINO (TE)'
  },
  {
    'value': 'F502',
    'viewValue': 'MONTEFIORE CONCA (RN)'
  },
  {
    'value': 'F501',
    'viewValue': "MONTEFIORE DELL'ASO (AP)"
  },
  {
    'value': 'F503',
    'viewValue': 'MONTEFIORINO (MO)'
  },
  {
    'value': 'F504',
    'viewValue': 'MONTEFLAVIO (RM)'
  },
  {
    'value': 'F507',
    'viewValue': 'MONTEFORTE CILENTO (SA)'
  },
  {
    'value': 'F508',
    'viewValue': "MONTEFORTE D'ALPONE (VR)"
  },
  {
    'value': 'F506',
    'viewValue': 'MONTEFORTE IRPINO (AV)'
  },
  {
    'value': 'F509',
    'viewValue': 'MONTEFORTINO (AP)'
  },
  {
    'value': 'F510',
    'viewValue': 'MONTEFRANCO (TR)'
  },
  {
    'value': 'F511',
    'viewValue': 'MONTEFREDANE (AV)'
  },
  {
    'value': 'F512',
    'viewValue': 'MONTEFUSCO (AV)'
  },
  {
    'value': 'F513',
    'viewValue': 'MONTEGABBIONE (TR)'
  },
  {
    'value': 'F514',
    'viewValue': 'MONTEGALDA (VI)'
  },
  {
    'value': 'F515',
    'viewValue': 'MONTEGALDELLA (VI)'
  },
  {
    'value': 'F516',
    'viewValue': 'MONTEGALLO (AP)'
  },
  {
    'value': 'F518',
    'viewValue': 'MONTEGIOCO (AL)'
  },
  {
    'value': 'F519',
    'viewValue': 'MONTEGIORDANO (CS)'
  },
  {
    'value': 'F520',
    'viewValue': 'MONTEGIORGIO (AP)'
  },
  {
    'value': 'F522',
    'viewValue': 'MONTEGRANARO (AP)'
  },
  {
    'value': 'F523',
    'viewValue': 'MONTEGRIDOLFO (RN)'
  },
  {
    'value': 'F526',
    'viewValue': 'MONTEGRINO VALTRAVAGLIA (VA)'
  },
  {
    'value': 'F527',
    'viewValue': "MONTEGROSSO D'ASTI (AT)"
  },
  {
    'value': 'F528',
    'viewValue': 'MONTEGROSSO PIAN LATTE (IM)'
  },
  {
    'value': 'F529',
    'viewValue': 'MONTEGROTTO TERME (PD)'
  },
  {
    'value': 'F531',
    'viewValue': 'MONTEIASI (TA)'
  },
  {
    'value': 'F533',
    'viewValue': 'MONTELABBATE (PU)'
  },
  {
    'value': 'F534',
    'viewValue': 'MONTELANICO (RM)'
  },
  {
    'value': 'F535',
    'viewValue': 'MONTELAPIANO (CH)'
  },
  {
    'value': 'F536',
    'viewValue': 'MONTELEONE DI FERMO (AP)'
  },
  {
    'value': 'F538',
    'viewValue': 'MONTELEONE DI PUGLIA (FG)'
  },
  {
    'value': 'F540',
    'viewValue': 'MONTELEONE DI SPOLETO (PG)'
  },
  {
    'value': 'F543',
    'viewValue': "MONTELEONE D'ORVIETO (TR)"
  },
  {
    'value': 'F542',
    'viewValue': 'MONTELEONE ROCCA DORIA (SS)'
  },
  {
    'value': 'F541',
    'viewValue': 'MONTELEONE SABINO (RI)'
  },
  {
    'value': 'F544',
    'viewValue': 'MONTELEPRE (PA)'
  },
  {
    'value': 'F545',
    'viewValue': 'MONTELIBRETTI (RM)'
  },
  {
    'value': 'F546',
    'viewValue': 'MONTELLA (AV)'
  },
  {
    'value': 'F547',
    'viewValue': 'MONTELLO (BG)'
  },
  {
    'value': 'F548',
    'viewValue': 'MONTELONGO (CB)'
  },
  {
    'value': 'F549',
    'viewValue': 'MONTELPARO (AP)'
  },
  {
    'value': 'F550',
    'viewValue': 'MONTELUPO ALBESE (CN)'
  },
  {
    'value': 'F551',
    'viewValue': 'MONTELUPO FIORENTINO (FI)'
  },
  {
    'value': 'F552',
    'viewValue': 'MONTELUPONE (MC)'
  },
  {
    'value': 'F555',
    'viewValue': 'MONTEMAGGIORE AL METAURO (PU)'
  },
  {
    'value': 'F553',
    'viewValue': 'MONTEMAGGIORE BELSITO (PA)'
  },
  {
    'value': 'F556',
    'viewValue': 'MONTEMAGNO (AT)'
  },
  {
    'value': 'F558',
    'viewValue': 'MONTEMALE DI CUNEO (CN)'
  },
  {
    'value': 'F559',
    'viewValue': 'MONTEMARANO (AV)'
  },
  {
    'value': 'F560',
    'viewValue': 'MONTEMARCIANO (AN)'
  },
  {
    'value': 'F562',
    'viewValue': 'MONTEMARZINO (AL)'
  },
  {
    'value': 'F563',
    'viewValue': 'MONTEMESOLA (TA)'
  },
  {
    'value': 'F564',
    'viewValue': 'MONTEMEZZO (CO)'
  },
  {
    'value': 'F565',
    'viewValue': 'MONTEMIGNAIO (AR)'
  },
  {
    'value': 'F566',
    'viewValue': 'MONTEMILETTO (AV)'
  },
  {
    'value': 'F568',
    'viewValue': 'MONTEMILONE (PZ)'
  },
  {
    'value': 'F569',
    'viewValue': 'MONTEMITRO (CB)'
  },
  {
    'value': 'F570',
    'viewValue': 'MONTEMONACO (AP)'
  },
  {
    'value': 'F572',
    'viewValue': 'MONTEMURLO (PO)'
  },
  {
    'value': 'F573',
    'viewValue': 'MONTEMURRO (PZ)'
  },
  {
    'value': 'F574',
    'viewValue': 'MONTENARS (UD)'
  },
  {
    'value': 'F576',
    'viewValue': 'MONTENERO DI BISACCIA (CB)'
  },
  {
    'value': 'F579',
    'viewValue': 'MONTENERO SABINO (RI)'
  },
  {
    'value': 'F580',
    'viewValue': 'MONTENERO VAL COCCHIARA (IS)'
  },
  {
    'value': 'F578',
    'viewValue': 'MONTENERODOMO (CH)'
  },
  {
    'value': 'F582',
    'viewValue': 'MONTEODORISIO (CH)'
  },
  {
    'value': 'F586',
    'viewValue': 'MONTEPAONE (CZ)'
  },
  {
    'value': 'F587',
    'viewValue': 'MONTEPARANO (TA)'
  },
  {
    'value': 'F591',
    'viewValue': 'MONTEPRANDONE (AP)'
  },
  {
    'value': 'F592',
    'viewValue': 'MONTEPULCIANO (SI)'
  },
  {
    'value': 'F593',
    'viewValue': 'MONTERADO (AN)'
  },
  {
    'value': 'F594',
    'viewValue': 'MONTERCHI (AR)'
  },
  {
    'value': 'F595',
    'viewValue': 'MONTEREALE (AQ)'
  },
  {
    'value': 'F596',
    'viewValue': 'MONTEREALE VALCELLINA (PN)'
  },
  {
    'value': 'F597',
    'viewValue': 'MONTERENZIO (BO)'
  },
  {
    'value': 'F598',
    'viewValue': 'MONTERIGGIONI (SI)'
  },
  {
    'value': 'F601',
    'viewValue': 'MONTERODUNI (IS)'
  },
  {
    'value': 'F605',
    'viewValue': "MONTERONI D'ARBIA (SI)"
  },
  {
    'value': 'F604',
    'viewValue': 'MONTERONI DI LECCE (LE)'
  },
  {
    'value': 'F606',
    'viewValue': 'MONTEROSI (VT)'
  },
  {
    'value': 'F609',
    'viewValue': 'MONTEROSSO AL MARE (SP)'
  },
  {
    'value': 'F610',
    'viewValue': 'MONTEROSSO ALMO (RG)'
  },
  {
    'value': 'F607',
    'viewValue': 'MONTEROSSO CALABRO (VV)'
  },
  {
    'value': 'F608',
    'viewValue': 'MONTEROSSO GRANA (CN)'
  },
  {
    'value': 'F611',
    'viewValue': 'MONTEROTONDO (RM)'
  },
  {
    'value': 'F612',
    'viewValue': 'MONTEROTONDO MARITTIMO (GR)'
  },
  {
    'value': 'F614',
    'viewValue': 'MONTERUBBIANO (AP)'
  },
  {
    'value': 'F623',
    'viewValue': 'MONTESANO SALENTINO (LE)'
  },
  {
    'value': 'F625',
    'viewValue': 'MONTESANO SULLA MARCELLANA (SA)'
  },
  {
    'value': 'F636',
    'viewValue': 'MONTESARCHIO (BN)'
  },
  {
    'value': 'F637',
    'viewValue': 'MONTESCAGLIOSO (MT)'
  },
  {
    'value': 'F638',
    'viewValue': 'MONTESCANO (PV)'
  },
  {
    'value': 'F639',
    'viewValue': 'MONTESCHENO (VB)'
  },
  {
    'value': 'F640',
    'viewValue': 'MONTESCUDAIO (PI)'
  },
  {
    'value': 'F641',
    'viewValue': 'MONTESCUDO (RN)'
  },
  {
    'value': 'F642',
    'viewValue': 'MONTESE (MO)'
  },
  {
    'value': 'F644',
    'viewValue': 'MONTESEGALE (PV)'
  },
  {
    'value': 'F646',
    'viewValue': 'MONTESILVANO (PE)'
  },
  {
    'value': 'F648',
    'viewValue': 'MONTESPERTOLI (FI)'
  },
  {
    'value': 'F651',
    'viewValue': 'MONTEU DA PO (TO)'
  },
  {
    'value': 'F654',
    'viewValue': 'MONTEU ROERO (CN)'
  },
  {
    'value': 'F655',
    'viewValue': 'MONTEVAGO (AG)'
  },
  {
    'value': 'F656',
    'viewValue': 'MONTEVARCHI (AR)'
  },
  {
    'value': 'F657',
    'viewValue': 'MONTEVECCHIA (LC)'
  },
  {
    'value': 'F659',
    'viewValue': 'MONTEVEGLIO (BO)'
  },
  {
    'value': 'F660',
    'viewValue': 'MONTEVERDE (AV)'
  },
  {
    'value': 'F661',
    'viewValue': 'MONTEVERDI MARITTIMO (PI)'
  },
  {
    'value': 'F662',
    'viewValue': 'MONTEVIALE (VI)'
  },
  {
    'value': 'F666',
    'viewValue': 'MONTEZEMOLO (CN)'
  },
  {
    'value': 'F667',
    'viewValue': 'MONTI (SS)'
  },
  {
    'value': 'F668',
    'viewValue': 'MONTIANO (FC)'
  },
  {
    'value': 'F672',
    'viewValue': 'MONTICELLI BRUSATI (BS)'
  },
  {
    'value': 'F671',
    'viewValue': "MONTICELLI D'ONGINA (PC)"
  },
  {
    'value': 'F670',
    'viewValue': 'MONTICELLI PAVESE (PV)'
  },
  {
    'value': 'F674',
    'viewValue': 'MONTICELLO BRIANZA (LC)'
  },
  {
    'value': 'F675',
    'viewValue': 'MONTICELLO CONTE OTTO (VI)'
  },
  {
    'value': 'F669',
    'viewValue': "MONTICELLO D'ALBA (CN)"
  },
  {
    'value': 'F471',
    'viewValue': 'MONTICHIARI (BS)'
  },
  {
    'value': 'F676',
    'viewValue': 'MONTICIANO (SI)'
  },
  {
    'value': 'F677',
    'viewValue': 'MONTIERI (GR)'
  },
  {
    'value': 'M302',
    'viewValue': 'MONTIGLIO MONFERRATO (AT)'
  },
  {
    'value': 'F679',
    'viewValue': 'MONTIGNOSO (MS)'
  },
  {
    'value': 'F680',
    'viewValue': 'MONTIRONE (BS)'
  },
  {
    'value': 'F367',
    'viewValue': 'MONTJOVET (AO)'
  },
  {
    'value': 'F681',
    'viewValue': 'MONTODINE (CR)'
  },
  {
    'value': 'F682',
    'viewValue': 'MONTOGGIO (GE)'
  },
  {
    'value': 'F685',
    'viewValue': 'MONTONE (PG)'
  },
  {
    'value': 'F687',
    'viewValue': 'MONTOPOLI DI SABINA (RI)'
  },
  {
    'value': 'F686',
    'viewValue': "MONTOPOLI IN VAL D'ARNO (PI)"
  },
  {
    'value': 'F688',
    'viewValue': 'MONTORFANO (CO)'
  },
  {
    'value': 'F690',
    'viewValue': 'MONTORIO AL VOMANO (TE)'
  },
  {
    'value': 'F689',
    'viewValue': 'MONTORIO NEI FRENTANI (CB)'
  },
  {
    'value': 'F692',
    'viewValue': 'MONTORIO ROMANO (RM)'
  },
  {
    'value': 'F693',
    'viewValue': 'MONTORO INFERIORE (AV)'
  },
  {
    'value': 'F694',
    'viewValue': 'MONTORO SUPERIORE (AV)'
  },
  {
    'value': 'F696',
    'viewValue': 'MONTORSO VICENTINO (VI)'
  },
  {
    'value': 'F697',
    'viewValue': 'MONTOTTONE (AP)'
  },
  {
    'value': 'F698',
    'viewValue': 'MONTRESTA (NU)'
  },
  {
    'value': 'F701',
    'viewValue': "MONTU' BECCARIA (PV)"
  },
  {
    'value': 'F703',
    'viewValue': 'MONVALLE (VA)'
  },
  {
    'value': 'F704',
    'viewValue': 'MONZA (MI)'
  },
  {
    'value': 'F705',
    'viewValue': 'MONZAMBANO (MN)'
  },
  {
    'value': 'F706',
    'viewValue': 'MONZUNO (BO)'
  },
  {
    'value': 'F708',
    'viewValue': 'MORANO CALABRO (CS)'
  },
  {
    'value': 'F707',
    'viewValue': 'MORANO SUL PO (AL)'
  },
  {
    'value': 'F709',
    'viewValue': 'MORANSENGO (AT)'
  },
  {
    'value': 'F710',
    'viewValue': 'MORARO (GO)'
  },
  {
    'value': 'F711',
    'viewValue': 'MORAZZONE (VA)'
  },
  {
    'value': 'F712',
    'viewValue': 'MORBEGNO (SO)'
  },
  {
    'value': 'F713',
    'viewValue': 'MORBELLO (AL)'
  },
  {
    'value': 'F716',
    'viewValue': 'MORCIANO DI LEUCA (LE)'
  },
  {
    'value': 'F715',
    'viewValue': 'MORCIANO DI ROMAGNA (RN)'
  },
  {
    'value': 'F717',
    'viewValue': 'MORCONE (BN)'
  },
  {
    'value': 'F718',
    'viewValue': 'MORDANO (BO)'
  },
  {
    'value': 'F720',
    'viewValue': 'MORENGO (BG)'
  },
  {
    'value': 'F721',
    'viewValue': 'MORES (SS)'
  },
  {
    'value': 'F722',
    'viewValue': 'MORESCO (AP)'
  },
  {
    'value': 'F723',
    'viewValue': 'MORETTA (CN)'
  },
  {
    'value': 'F724',
    'viewValue': 'MORFASSO (PC)'
  },
  {
    'value': 'F725',
    'viewValue': 'MORGANO (TV)'
  },
  {
    'value': 'F726',
    'viewValue': 'MORGEX (AO)'
  },
  {
    'value': 'F727',
    'viewValue': 'MORGONGIORI (OR)'
  },
  {
    'value': 'F728',
    'viewValue': 'MORI (TN)'
  },
  {
    'value': 'F729',
    'viewValue': 'MORIAGO DELLA BATTAGLIA (TV)'
  },
  {
    'value': 'F730',
    'viewValue': 'MORICONE (RM)'
  },
  {
    'value': 'F731',
    'viewValue': 'MORIGERATI (SA)'
  },
  {
    'value': 'D033',
    'viewValue': 'MORIMONDO (MI)'
  },
  {
    'value': 'F732',
    'viewValue': 'MORINO (AQ)'
  },
  {
    'value': 'F733',
    'viewValue': 'MORIONDO TORINESE (TO)'
  },
  {
    'value': 'F734',
    'viewValue': 'MORLUPO (RM)'
  },
  {
    'value': 'F735',
    'viewValue': 'MORMANNO (CS)'
  },
  {
    'value': 'F736',
    'viewValue': 'MORNAGO (VA)'
  },
  {
    'value': 'F737',
    'viewValue': 'MORNESE (AL)'
  },
  {
    'value': 'F738',
    'viewValue': 'MORNICO AL SERIO (BG)'
  },
  {
    'value': 'F739',
    'viewValue': 'MORNICO LOSANA (PV)'
  },
  {
    'value': 'F740',
    'viewValue': 'MOROLO (FR)'
  },
  {
    'value': 'F743',
    'viewValue': 'MOROZZO (CN)'
  },
  {
    'value': 'F744',
    'viewValue': 'MORRA DE SANCTIS (AV)'
  },
  {
    'value': 'F745',
    'viewValue': "MORRO D'ALBA (AN)"
  },
  {
    'value': 'F747',
    'viewValue': "MORRO D'ORO (TE)"
  },
  {
    'value': 'F746',
    'viewValue': 'MORRO REATINO (RI)'
  },
  {
    'value': 'F748',
    'viewValue': 'MORRONE DEL SANNIO (CB)'
  },
  {
    'value': 'F749',
    'viewValue': 'MORROVALLE (MC)'
  },
  {
    'value': 'F750',
    'viewValue': 'MORSANO AL TAGLIAMENTO (PN)'
  },
  {
    'value': 'F751',
    'viewValue': 'MORSASCO (AL)'
  },
  {
    'value': 'F754',
    'viewValue': 'MORTARA (PV)'
  },
  {
    'value': 'F756',
    'viewValue': 'MORTEGLIANO (UD)'
  },
  {
    'value': 'F758',
    'viewValue': 'MORTERONE (LC)'
  },
  {
    'value': 'F760',
    'viewValue': 'MORUZZO (UD)'
  },
  {
    'value': 'F761',
    'viewValue': 'MOSCAZZANO (CR)'
  },
  {
    'value': 'F762',
    'viewValue': 'MOSCHIANO (AV)'
  },
  {
    'value': 'F764',
    'viewValue': "MOSCIANO SANT'ANGELO (TE)"
  },
  {
    'value': 'F765',
    'viewValue': 'MOSCUFO (PE)'
  },
  {
    'value': 'F766',
    'viewValue': 'MOSO IN PASSIRIA .MOOS IN PASSEIER. (BZ)'
  },
  {
    'value': 'F767',
    'viewValue': 'MOSSA (GO)'
  },
  {
    'value': 'F768',
    'viewValue': 'MOSSANO (VI)'
  },
  {
    'value': 'M304',
    'viewValue': 'MOSSO (BI)'
  },
  {
    'value': 'F771',
    'viewValue': 'MOTTA BALUFFI (CR)'
  },
  {
    'value': 'F772',
    'viewValue': 'MOTTA CAMASTRA (ME)'
  },
  {
    'value': 'F773',
    'viewValue': "MOTTA D'AFFERMO (ME)"
  },
  {
    'value': 'F774',
    'viewValue': "MOTTA DE' CONTI (VC)"
  },
  {
    'value': 'F770',
    'viewValue': 'MOTTA DI LIVENZA (TV)'
  },
  {
    'value': 'F777',
    'viewValue': 'MOTTA MONTECORVINO (FG)'
  },
  {
    'value': 'F779',
    'viewValue': 'MOTTA SAN GIOVANNI (RC)'
  },
  {
    'value': 'F780',
    'viewValue': 'MOTTA SANTA LUCIA (CZ)'
  },
  {
    'value': 'F781',
    'viewValue': "MOTTA SANT'ANASTASIA (CT)"
  },
  {
    'value': 'F783',
    'viewValue': 'MOTTA VISCONTI (MI)'
  },
  {
    'value': 'F775',
    'viewValue': 'MOTTAFOLLONE (CS)'
  },
  {
    'value': 'F776',
    'viewValue': 'MOTTALCIATA (BI)'
  },
  {
    'value': 'B012',
    'viewValue': 'MOTTEGGIANA (MN)'
  },
  {
    'value': 'F784',
    'viewValue': 'MOTTOLA (TA)'
  },
  {
    'value': 'F785',
    'viewValue': 'MOZZAGROGNA (CH)'
  },
  {
    'value': 'F786',
    'viewValue': 'MOZZANICA (BG)'
  },
  {
    'value': 'F788',
    'viewValue': 'MOZZATE (CO)'
  },
  {
    'value': 'F789',
    'viewValue': 'MOZZECANE (VR)'
  },
  {
    'value': 'F791',
    'viewValue': 'MOZZO (BG)'
  },
  {
    'value': 'F793',
    'viewValue': 'MUCCIA (MC)'
  },
  {
    'value': 'F795',
    'viewValue': 'MUGGIA (TS)'
  },
  {
    'value': 'F797',
    'viewValue': "MUGGIO' (MI)"
  },
  {
    'value': 'F798',
    'viewValue': 'MUGNANO DEL CARDINALE (AV)'
  },
  {
    'value': 'F799',
    'viewValue': 'MUGNANO DI NAPOLI (NA)'
  },
  {
    'value': 'F801',
    'viewValue': 'MULAZZANO (LO)'
  },
  {
    'value': 'F802',
    'viewValue': 'MULAZZO (MS)'
  },
  {
    'value': 'F806',
    'viewValue': 'MURA (BS)'
  },
  {
    'value': 'F808',
    'viewValue': 'MURAVERA (CA)'
  },
  {
    'value': 'F809',
    'viewValue': 'MURAZZANO (CN)'
  },
  {
    'value': 'F811',
    'viewValue': 'MURELLO (CN)'
  },
  {
    'value': 'F813',
    'viewValue': 'MURIALDO (SV)'
  },
  {
    'value': 'F814',
    'viewValue': 'MURISENGO (AL)'
  },
  {
    'value': 'F815',
    'viewValue': 'MURLO (SI)'
  },
  {
    'value': 'F816',
    'viewValue': 'MURO LECCESE (LE)'
  },
  {
    'value': 'F817',
    'viewValue': 'MURO LUCANO (PZ)'
  },
  {
    'value': 'F818',
    'viewValue': 'MUROS (SS)'
  },
  {
    'value': 'F820',
    'viewValue': 'MUSCOLINE (BS)'
  },
  {
    'value': 'F822',
    'viewValue': 'MUSEI (CA)'
  },
  {
    'value': 'F826',
    'viewValue': 'MUSILE DI PIAVE (VE)'
  },
  {
    'value': 'F828',
    'viewValue': 'MUSSO (CO)'
  },
  {
    'value': 'F829',
    'viewValue': 'MUSSOLENTE (VI)'
  },
  {
    'value': 'F830',
    'viewValue': 'MUSSOMELI (CL)'
  },
  {
    'value': 'F832',
    'viewValue': 'MUZZANA DEL TURGNANO (UD)'
  },
  {
    'value': 'F833',
    'viewValue': 'MUZZANO (BI)'
  },
  {
    'value': 'F835',
    'viewValue': 'NAGO-TORBOLE (TN)'
  },
  {
    'value': 'F836',
    'viewValue': 'NALLES .NALS. (BZ)'
  },
  {
    'value': 'F837',
    'viewValue': 'NANNO (TN)'
  },
  {
    'value': 'F838',
    'viewValue': 'NANTO (VI)'
  },
  {
    'value': 'F839',
    'viewValue': 'NAPOLI (NA)'
  },
  {
    'value': 'F840',
    'viewValue': 'NARBOLIA (OR)'
  },
  {
    'value': 'F841',
    'viewValue': 'NARCAO (CA)'
  },
  {
    'value': 'F842',
    'viewValue': "NARDO' (LE)"
  },
  {
    'value': 'F843',
    'viewValue': 'NARDODIPACE (VV)'
  },
  {
    'value': 'F844',
    'viewValue': 'NARNI (TR)'
  },
  {
    'value': 'F845',
    'viewValue': 'NARO (AG)'
  },
  {
    'value': 'F846',
    'viewValue': 'NARZOLE (CN)'
  },
  {
    'value': 'F847',
    'viewValue': 'NASINO (SV)'
  },
  {
    'value': 'F848',
    'viewValue': 'NASO (ME)'
  },
  {
    'value': 'F849',
    'viewValue': 'NATURNO .NATURNS. (BZ)'
  },
  {
    'value': 'F851',
    'viewValue': 'NAVE (BS)'
  },
  {
    'value': 'F853',
    'viewValue': 'NAVE SAN ROCCO (TN)'
  },
  {
    'value': 'F852',
    'viewValue': 'NAVELLI (AQ)'
  },
  {
    'value': 'F856',
    'viewValue': 'NAZ SCIAVES .NATZ SCHABS. (BZ)'
  },
  {
    'value': 'F857',
    'viewValue': 'NAZZANO (RM)'
  },
  {
    'value': 'F858',
    'viewValue': 'NE (GE)'
  },
  {
    'value': 'F859',
    'viewValue': 'NEBBIUNO (NO)'
  },
  {
    'value': 'F861',
    'viewValue': 'NEGRAR (VR)'
  },
  {
    'value': 'F862',
    'viewValue': 'NEIRONE (GE)'
  },
  {
    'value': 'F863',
    'viewValue': 'NEIVE (CN)'
  },
  {
    'value': 'F864',
    'viewValue': 'NEMBRO (BG)'
  },
  {
    'value': 'F865',
    'viewValue': 'NEMI (RM)'
  },
  {
    'value': 'F866',
    'viewValue': 'NEMOLI (PZ)'
  },
  {
    'value': 'F867',
    'viewValue': 'NEONELI (OR)'
  },
  {
    'value': 'F868',
    'viewValue': 'NEPI (VT)'
  },
  {
    'value': 'F870',
    'viewValue': 'NERETO (TE)'
  },
  {
    'value': 'F871',
    'viewValue': 'NEROLA (RM)'
  },
  {
    'value': 'F872',
    'viewValue': 'NERVESA DELLA BATTAGLIA (TV)'
  },
  {
    'value': 'F874',
    'viewValue': 'NERVIANO (MI)'
  },
  {
    'value': 'F876',
    'viewValue': 'NESPOLO (RI)'
  },
  {
    'value': 'F877',
    'viewValue': 'NESSO (CO)'
  },
  {
    'value': 'F878',
    'viewValue': 'NETRO (BI)'
  },
  {
    'value': 'F880',
    'viewValue': 'NETTUNO (RM)'
  },
  {
    'value': 'F881',
    'viewValue': 'NEVIANO (LE)'
  },
  {
    'value': 'F882',
    'viewValue': 'NEVIANO DEGLI ARDUINI (PR)'
  },
  {
    'value': 'F883',
    'viewValue': 'NEVIGLIE (CN)'
  },
  {
    'value': 'F884',
    'viewValue': 'NIARDO (BS)'
  },
  {
    'value': 'F885',
    'viewValue': 'NIBBIANO (PC)'
  },
  {
    'value': 'F886',
    'viewValue': 'NIBBIOLA (NO)'
  },
  {
    'value': 'F887',
    'viewValue': 'NIBIONNO (LC)'
  },
  {
    'value': 'F888',
    'viewValue': 'NICASTRO (CZ)'
  },
  {
    'value': 'F889',
    'viewValue': 'NICHELINO (TO)'
  },
  {
    'value': 'F890',
    'viewValue': 'NICOLOSI (CT)'
  },
  {
    'value': 'F891',
    'viewValue': 'NICORVO (PV)'
  },
  {
    'value': 'F892',
    'viewValue': 'NICOSIA (EN)'
  },
  {
    'value': 'F893',
    'viewValue': 'NICOTERA (VV)'
  },
  {
    'value': 'F894',
    'viewValue': 'NIELLA BELBO (CN)'
  },
  {
    'value': 'F895',
    'viewValue': 'NIELLA TANARO (CN)'
  },
  {
    'value': 'F898',
    'viewValue': 'NIMIS (UD)'
  },
  {
    'value': 'F899',
    'viewValue': 'NISCEMI (CL)'
  },
  {
    'value': 'F900',
    'viewValue': 'NISSORIA (EN)'
  },
  {
    'value': 'F901',
    'viewValue': 'NIZZA DI SICILIA (ME)'
  },
  {
    'value': 'F902',
    'viewValue': 'NIZZA MONFERRATO (AT)'
  },
  {
    'value': 'F904',
    'viewValue': 'NOALE (VE)'
  },
  {
    'value': 'F906',
    'viewValue': 'NOASCA (TO)'
  },
  {
    'value': 'F907',
    'viewValue': 'NOCARA (CS)'
  },
  {
    'value': 'F908',
    'viewValue': 'NOCCIANO (PE)'
  },
  {
    'value': 'F912',
    'viewValue': 'NOCERA INFERIORE (SA)'
  },
  {
    'value': 'F913',
    'viewValue': 'NOCERA SUPERIORE (SA)'
  },
  {
    'value': 'F910',
    'viewValue': 'NOCERA TERINESE (CZ)'
  },
  {
    'value': 'F911',
    'viewValue': 'NOCERA UMBRA (PG)'
  },
  {
    'value': 'F914',
    'viewValue': 'NOCETO (PR)'
  },
  {
    'value': 'F915',
    'viewValue': 'NOCI (BA)'
  },
  {
    'value': 'F916',
    'viewValue': 'NOCIGLIA (LE)'
  },
  {
    'value': 'F917',
    'viewValue': 'NOEPOLI (PZ)'
  },
  {
    'value': 'F918',
    'viewValue': 'NOGARA (VR)'
  },
  {
    'value': 'F920',
    'viewValue': 'NOGAREDO (TN)'
  },
  {
    'value': 'F921',
    'viewValue': 'NOGAROLE ROCCA (VR)'
  },
  {
    'value': 'F922',
    'viewValue': 'NOGAROLE VICENTINO (VI)'
  },
  {
    'value': 'F923',
    'viewValue': 'NOICATTARO (BA)'
  },
  {
    'value': 'F924',
    'viewValue': 'NOLA (NA)'
  },
  {
    'value': 'F925',
    'viewValue': 'NOLE (TO)'
  },
  {
    'value': 'F926',
    'viewValue': 'NOLI (SV)'
  },
  {
    'value': 'F927',
    'viewValue': 'NOMAGLIO (TO)'
  },
  {
    'value': 'F929',
    'viewValue': 'NOMI (TN)'
  },
  {
    'value': 'F930',
    'viewValue': 'NONANTOLA (MO)'
  },
  {
    'value': 'F931',
    'viewValue': 'NONE (TO)'
  },
  {
    'value': 'F932',
    'viewValue': 'NONIO (VB)'
  },
  {
    'value': 'F933',
    'viewValue': 'NORAGUGUME (NU)'
  },
  {
    'value': 'F934',
    'viewValue': 'NORBELLO (OR)'
  },
  {
    'value': 'F935',
    'viewValue': 'NORCIA (PG)'
  },
  {
    'value': 'F937',
    'viewValue': 'NORMA (LT)'
  },
  {
    'value': 'F939',
    'viewValue': 'NOSATE (MI)'
  },
  {
    'value': 'F942',
    'viewValue': 'NOTARESCO (TE)'
  },
  {
    'value': 'F943',
    'viewValue': 'NOTO (SR)'
  },
  {
    'value': 'F949',
    'viewValue': 'NOVA LEVANTE .WELSCHNOFEN. (BZ)'
  },
  {
    'value': 'F944',
    'viewValue': 'NOVA MILANESE (MI)'
  },
  {
    'value': 'F950',
    'viewValue': 'NOVA PONENTE .DEUTSCHNOFEN. (BZ)'
  },
  {
    'value': 'A942',
    'viewValue': 'NOVA SIRI (MT)'
  },
  {
    'value': 'F137',
    'viewValue': 'NOVAFELTRIA (PU)'
  },
  {
    'value': 'F947',
    'viewValue': 'NOVALEDO (TN)'
  },
  {
    'value': 'F948',
    'viewValue': 'NOVALESA (TO)'
  },
  {
    'value': 'F952',
    'viewValue': 'NOVARA (NO)'
  },
  {
    'value': 'F951',
    'viewValue': 'NOVARA DI SICILIA (ME)'
  },
  {
    'value': 'F956',
    'viewValue': 'NOVATE MEZZOLA (SO)'
  },
  {
    'value': 'F955',
    'viewValue': 'NOVATE MILANESE (MI)'
  },
  {
    'value': 'F957',
    'viewValue': 'NOVE (VI)'
  },
  {
    'value': 'F958',
    'viewValue': 'NOVEDRATE (CO)'
  },
  {
    'value': 'F960',
    'viewValue': 'NOVELLARA (RE)'
  },
  {
    'value': 'F961',
    'viewValue': 'NOVELLO (CN)'
  },
  {
    'value': 'F963',
    'viewValue': 'NOVENTA DI PIAVE (VE)'
  },
  {
    'value': 'F962',
    'viewValue': 'NOVENTA PADOVANA (PD)'
  },
  {
    'value': 'F964',
    'viewValue': 'NOVENTA VICENTINA (VI)'
  },
  {
    'value': 'F966',
    'viewValue': 'NOVI DI MODENA (MO)'
  },
  {
    'value': 'F965',
    'viewValue': 'NOVI LIGURE (AL)'
  },
  {
    'value': 'F967',
    'viewValue': 'NOVI VELIA (SA)'
  },
  {
    'value': 'F968',
    'viewValue': 'NOVIGLIO (MI)'
  },
  {
    'value': 'F970',
    'viewValue': 'NOVOLI (LE)'
  },
  {
    'value': 'F972',
    'viewValue': 'NUCETTO (CN)'
  },
  {
    'value': 'F975',
    'viewValue': "NUGHEDU SAN NICOLO' (SS)"
  },
  {
    'value': 'F974',
    'viewValue': 'NUGHEDU SANTA VITTORIA (OR)'
  },
  {
    'value': 'F976',
    'viewValue': 'NULE (SS)'
  },
  {
    'value': 'F977',
    'viewValue': 'NULVI (SS)'
  },
  {
    'value': 'F978',
    'viewValue': 'NUMANA (AN)'
  },
  {
    'value': 'F979',
    'viewValue': 'NUORO (NU)'
  },
  {
    'value': 'F980',
    'viewValue': 'NURACHI (OR)'
  },
  {
    'value': 'F981',
    'viewValue': 'NURAGUS (NU)'
  },
  {
    'value': 'F982',
    'viewValue': 'NURALLAO (NU)'
  },
  {
    'value': 'F983',
    'viewValue': 'NURAMINIS (CA)'
  },
  {
    'value': 'F985',
    'viewValue': 'NURECI (OR)'
  },
  {
    'value': 'F986',
    'viewValue': 'NURRI (NU)'
  },
  {
    'value': 'F987',
    'viewValue': 'NUS (AO)'
  },
  {
    'value': 'F988',
    'viewValue': 'NUSCO (AV)'
  },
  {
    'value': 'F989',
    'viewValue': 'NUVOLENTO (BS)'
  },
  {
    'value': 'F990',
    'viewValue': 'NUVOLERA (BS)'
  },
  {
    'value': 'F991',
    'viewValue': 'NUXIS (CA)'
  },
  {
    'value': 'F992',
    'viewValue': 'OCCHIEPPO INFERIORE (BI)'
  },
  {
    'value': 'F993',
    'viewValue': 'OCCHIEPPO SUPERIORE (BI)'
  },
  {
    'value': 'F994',
    'viewValue': 'OCCHIOBELLO (RO)'
  },
  {
    'value': 'F995',
    'viewValue': 'OCCIMIANO (AL)'
  },
  {
    'value': 'F996',
    'viewValue': 'OCRE (AQ)'
  },
  {
    'value': 'F997',
    'viewValue': 'ODALENGO GRANDE (AL)'
  },
  {
    'value': 'F998',
    'viewValue': 'ODALENGO PICCOLO (AL)'
  },
  {
    'value': 'F999',
    'viewValue': 'ODERZO (TV)'
  },
  {
    'value': 'G001',
    'viewValue': 'ODOLO (BS)'
  },
  {
    'value': 'G002',
    'viewValue': 'OFENA (AQ)'
  },
  {
    'value': 'G003',
    'viewValue': 'OFFAGNA (AN)'
  },
  {
    'value': 'G004',
    'viewValue': 'OFFANENGO (CR)'
  },
  {
    'value': 'G005',
    'viewValue': 'OFFIDA (AP)'
  },
  {
    'value': 'G006',
    'viewValue': 'OFFLAGA (BS)'
  },
  {
    'value': 'G007',
    'viewValue': 'OGGEBBIO (VB)'
  },
  {
    'value': 'G008',
    'viewValue': 'OGGIONA CON SANTO STEFANO (VA)'
  },
  {
    'value': 'G009',
    'viewValue': 'OGGIONO (LC)'
  },
  {
    'value': 'G010',
    'viewValue': 'OGLIANICO (TO)'
  },
  {
    'value': 'G011',
    'viewValue': 'OGLIASTRO CILENTO (SA)'
  },
  {
    'value': 'G015',
    'viewValue': 'OLBIA (SS)'
  },
  {
    'value': 'G016',
    'viewValue': 'OLCENENGO (VC)'
  },
  {
    'value': 'G018',
    'viewValue': 'OLDENICO (VC)'
  },
  {
    'value': 'G019',
    'viewValue': 'OLEGGIO (NO)'
  },
  {
    'value': 'G020',
    'viewValue': 'OLEGGIO CASTELLO (NO)'
  },
  {
    'value': 'G021',
    'viewValue': 'OLEVANO DI LOMELLINA (PV)'
  },
  {
    'value': 'G022',
    'viewValue': 'OLEVANO ROMANO (RM)'
  },
  {
    'value': 'G023',
    'viewValue': 'OLEVANO SUL TUSCIANO (SA)'
  },
  {
    'value': 'G025',
    'viewValue': 'OLGIATE COMASCO (CO)'
  },
  {
    'value': 'G026',
    'viewValue': 'OLGIATE MOLGORA (LC)'
  },
  {
    'value': 'G028',
    'viewValue': 'OLGIATE OLONA (VA)'
  },
  {
    'value': 'G030',
    'viewValue': 'OLGINATE (LC)'
  },
  {
    'value': 'G031',
    'viewValue': 'OLIENA (NU)'
  },
  {
    'value': 'G032',
    'viewValue': 'OLIVA GESSI (PV)'
  },
  {
    'value': 'G034',
    'viewValue': 'OLIVADI (CZ)'
  },
  {
    'value': 'G036',
    'viewValue': 'OLIVERI (ME)'
  },
  {
    'value': 'G039',
    'viewValue': 'OLIVETO CITRA (SA)'
  },
  {
    'value': 'G040',
    'viewValue': 'OLIVETO LARIO (LC)'
  },
  {
    'value': 'G037',
    'viewValue': 'OLIVETO LUCANO (MT)'
  },
  {
    'value': 'G041',
    'viewValue': 'OLIVETTA SAN MICHELE (IM)'
  },
  {
    'value': 'G042',
    'viewValue': 'OLIVOLA (AL)'
  },
  {
    'value': 'G043',
    'viewValue': 'OLLASTRA (OR)'
  },
  {
    'value': 'G044',
    'viewValue': 'OLLOLAI (NU)'
  },
  {
    'value': 'G045',
    'viewValue': 'OLLOMONT (AO)'
  },
  {
    'value': 'G046',
    'viewValue': 'OLMEDO (SS)'
  },
  {
    'value': 'G047',
    'viewValue': 'OLMENETA (CR)'
  },
  {
    'value': 'G049',
    'viewValue': 'OLMO AL BREMBO (BG)'
  },
  {
    'value': 'G048',
    'viewValue': 'OLMO GENTILE (AT)'
  },
  {
    'value': 'G050',
    'viewValue': 'OLTRE IL COLLE (BG)'
  },
  {
    'value': 'G054',
    'viewValue': 'OLTRESSENDA ALTA (BG)'
  },
  {
    'value': 'G056',
    'viewValue': 'OLTRONA DI SAN MAMETTE (CO)'
  },
  {
    'value': 'G058',
    'viewValue': 'OLZAI (NU)'
  },
  {
    'value': 'G061',
    'viewValue': 'OME (BS)'
  },
  {
    'value': 'G062',
    'viewValue': 'OMEGNA (VB)'
  },
  {
    'value': 'G063',
    'viewValue': 'OMIGNANO (SA)'
  },
  {
    'value': 'G064',
    'viewValue': 'ONANI (NU)'
  },
  {
    'value': 'G065',
    'viewValue': 'ONANO (VT)'
  },
  {
    'value': 'G066',
    'viewValue': 'ONCINO (CN)'
  },
  {
    'value': 'G068',
    'viewValue': 'ONETA (BG)'
  },
  {
    'value': 'G070',
    'viewValue': 'ONIFAI (NU)'
  },
  {
    'value': 'G071',
    'viewValue': 'ONIFERI (NU)'
  },
  {
    'value': 'G074',
    'viewValue': 'ONO SAN PIETRO (BS)'
  },
  {
    'value': 'G075',
    'viewValue': 'ONORE (BG)'
  },
  {
    'value': 'G076',
    'viewValue': 'ONZO (SV)'
  },
  {
    'value': 'G078',
    'viewValue': 'OPERA (MI)'
  },
  {
    'value': 'G079',
    'viewValue': 'OPI (AQ)'
  },
  {
    'value': 'G080',
    'viewValue': 'OPPEANO (VR)'
  },
  {
    'value': 'G081',
    'viewValue': 'OPPIDO LUCANO (PZ)'
  },
  {
    'value': 'G082',
    'viewValue': 'OPPIDO MAMERTINA (RC)'
  },
  {
    'value': 'G083',
    'viewValue': 'ORA .AUER. (BZ)'
  },
  {
    'value': 'G084',
    'viewValue': 'ORANI (NU)'
  },
  {
    'value': 'G086',
    'viewValue': 'ORATINO (CB)'
  },
  {
    'value': 'G087',
    'viewValue': 'ORBASSANO (TO)'
  },
  {
    'value': 'G088',
    'viewValue': 'ORBETELLO (GR)'
  },
  {
    'value': 'G089',
    'viewValue': 'ORCIANO DI PESARO (PU)'
  },
  {
    'value': 'G090',
    'viewValue': 'ORCIANO PISANO (PI)'
  },
  {
    'value': 'D522',
    'viewValue': 'ORCO FEGLINO (SV)'
  },
  {
    'value': 'M266',
    'viewValue': 'ORDONA (FG)'
  },
  {
    'value': 'G093',
    'viewValue': 'ORERO (GE)'
  },
  {
    'value': 'G095',
    'viewValue': 'ORGIANO (VI)'
  },
  {
    'value': 'G097',
    'viewValue': 'ORGOSOLO (NU)'
  },
  {
    'value': 'G098',
    'viewValue': 'ORIA (BR)'
  },
  {
    'value': 'G102',
    'viewValue': 'ORICOLA (AQ)'
  },
  {
    'value': 'G103',
    'viewValue': 'ORIGGIO (VA)'
  },
  {
    'value': 'G105',
    'viewValue': 'ORINO (VA)'
  },
  {
    'value': 'G108',
    'viewValue': 'ORIO AL SERIO (BG)'
  },
  {
    'value': 'G109',
    'viewValue': 'ORIO CANAVESE (TO)'
  },
  {
    'value': 'G107',
    'viewValue': 'ORIO LITTA (LO)'
  },
  {
    'value': 'G110',
    'viewValue': 'ORIOLO (CS)'
  },
  {
    'value': 'G111',
    'viewValue': 'ORIOLO ROMANO (VT)'
  },
  {
    'value': 'G113',
    'viewValue': 'ORISTANO (OR)'
  },
  {
    'value': 'G114',
    'viewValue': 'ORMEA (CN)'
  },
  {
    'value': 'G115',
    'viewValue': 'ORMELLE (TV)'
  },
  {
    'value': 'G116',
    'viewValue': 'ORNAGO (MI)'
  },
  {
    'value': 'G117',
    'viewValue': 'ORNAVASSO (VB)'
  },
  {
    'value': 'G118',
    'viewValue': 'ORNICA (BG)'
  },
  {
    'value': 'G119',
    'viewValue': 'OROSEI (NU)'
  },
  {
    'value': 'G120',
    'viewValue': 'OROTELLI (NU)'
  },
  {
    'value': 'G121',
    'viewValue': 'ORRIA (SA)'
  },
  {
    'value': 'G122',
    'viewValue': 'ORROLI (NU)'
  },
  {
    'value': 'G123',
    'viewValue': 'ORSAGO (TV)'
  },
  {
    'value': 'G124',
    'viewValue': 'ORSARA BORMIDA (AL)'
  },
  {
    'value': 'G125',
    'viewValue': 'ORSARA DI PUGLIA (FG)'
  },
  {
    'value': 'G126',
    'viewValue': 'ORSENIGO (CO)'
  },
  {
    'value': 'G128',
    'viewValue': 'ORSOGNA (CH)'
  },
  {
    'value': 'G129',
    'viewValue': 'ORSOMARSO (CS)'
  },
  {
    'value': 'G130',
    'viewValue': 'ORTA DI ATELLA (CE)'
  },
  {
    'value': 'G131',
    'viewValue': 'ORTA NOVA (FG)'
  },
  {
    'value': 'G134',
    'viewValue': 'ORTA SAN GIULIO (NO)'
  },
  {
    'value': 'G133',
    'viewValue': 'ORTACESUS (CA)'
  },
  {
    'value': 'G135',
    'viewValue': 'ORTE (VT)'
  },
  {
    'value': 'G136',
    'viewValue': 'ORTELLE (LE)'
  },
  {
    'value': 'G137',
    'viewValue': 'ORTEZZANO (AP)'
  },
  {
    'value': 'G139',
    'viewValue': 'ORTIGNANO RAGGIOLO (AR)'
  },
  {
    'value': 'G140',
    'viewValue': 'ORTISEI .ST ULRICH. (BZ)'
  },
  {
    'value': 'G141',
    'viewValue': 'ORTONA (CH)'
  },
  {
    'value': 'G142',
    'viewValue': 'ORTONA DEI MARSI (AQ)'
  },
  {
    'value': 'G143',
    'viewValue': 'ORTONOVO (SP)'
  },
  {
    'value': 'G144',
    'viewValue': 'ORTOVERO (SV)'
  },
  {
    'value': 'G145',
    'viewValue': 'ORTUCCHIO (AQ)'
  },
  {
    'value': 'G146',
    'viewValue': 'ORTUERI (NU)'
  },
  {
    'value': 'G147',
    'viewValue': 'ORUNE (NU)'
  },
  {
    'value': 'G148',
    'viewValue': 'ORVIETO (TR)'
  },
  {
    'value': 'B595',
    'viewValue': 'ORVINIO (RI)'
  },
  {
    'value': 'G149',
    'viewValue': 'ORZINUOVI (BS)'
  },
  {
    'value': 'G150',
    'viewValue': 'ORZIVECCHI (BS)'
  },
  {
    'value': 'G151',
    'viewValue': 'OSASCO (TO)'
  },
  {
    'value': 'G152',
    'viewValue': 'OSASIO (TO)'
  },
  {
    'value': 'G153',
    'viewValue': 'OSCHIRI (SS)'
  },
  {
    'value': 'G154',
    'viewValue': 'OSIDDA (NU)'
  },
  {
    'value': 'G155',
    'viewValue': 'OSIGLIA (SV)'
  },
  {
    'value': 'G156',
    'viewValue': 'OSILO (SS)'
  },
  {
    'value': 'G157',
    'viewValue': 'OSIMO (AN)'
  },
  {
    'value': 'G158',
    'viewValue': 'OSINI (NU)'
  },
  {
    'value': 'G159',
    'viewValue': 'OSIO SOPRA (BG)'
  },
  {
    'value': 'G160',
    'viewValue': 'OSIO SOTTO (BG)'
  },
  {
    'value': 'E529',
    'viewValue': 'OSMATE (VA)'
  },
  {
    'value': 'G161',
    'viewValue': 'OSNAGO (LC)'
  },
  {
    'value': 'G163',
    'viewValue': 'OSOPPO (UD)'
  },
  {
    'value': 'G164',
    'viewValue': 'OSPEDALETTI (IM)'
  },
  {
    'value': 'G168',
    'viewValue': 'OSPEDALETTO (TN)'
  },
  {
    'value': 'G165',
    'viewValue': "OSPEDALETTO D'ALPINOLO (AV)"
  },
  {
    'value': 'G167',
    'viewValue': 'OSPEDALETTO EUGANEO (PD)'
  },
  {
    'value': 'G166',
    'viewValue': 'OSPEDALETTO LODIGIANO (LO)'
  },
  {
    'value': 'G169',
    'viewValue': 'OSPITALE DI CADORE (BL)'
  },
  {
    'value': 'G170',
    'viewValue': 'OSPITALETTO (BS)'
  },
  {
    'value': 'G171',
    'viewValue': 'OSSAGO LODIGIANO (LO)'
  },
  {
    'value': 'G173',
    'viewValue': 'OSSANA (TN)'
  },
  {
    'value': 'G178',
    'viewValue': 'OSSI (SS)'
  },
  {
    'value': 'G179',
    'viewValue': 'OSSIMO (BS)'
  },
  {
    'value': 'G181',
    'viewValue': 'OSSONA (MI)'
  },
  {
    'value': 'G182',
    'viewValue': 'OSSUCCIO (CO)'
  },
  {
    'value': 'G183',
    'viewValue': 'OSTANA (CN)'
  },
  {
    'value': 'G184',
    'viewValue': 'OSTELLATO (FE)'
  },
  {
    'value': 'G185',
    'viewValue': 'OSTIANO (CR)'
  },
  {
    'value': 'G186',
    'viewValue': 'OSTIGLIA (MN)'
  },
  {
    'value': 'F401',
    'viewValue': 'OSTRA (AN)'
  },
  {
    'value': 'F581',
    'viewValue': 'OSTRA VETERE (AN)'
  },
  {
    'value': 'G187',
    'viewValue': 'OSTUNI (BR)'
  },
  {
    'value': 'G188',
    'viewValue': 'OTRANTO (LE)'
  },
  {
    'value': 'G189',
    'viewValue': 'OTRICOLI (TR)'
  },
  {
    'value': 'G191',
    'viewValue': 'OTTANA (NU)'
  },
  {
    'value': 'G192',
    'viewValue': 'OTTATI (SA)'
  },
  {
    'value': 'G190',
    'viewValue': 'OTTAVIANO (NA)'
  },
  {
    'value': 'G193',
    'viewValue': 'OTTIGLIO (AL)'
  },
  {
    'value': 'G194',
    'viewValue': 'OTTOBIANO (PV)'
  },
  {
    'value': 'G195',
    'viewValue': 'OTTONE (PC)'
  },
  {
    'value': 'G196',
    'viewValue': 'OULX (TO)'
  },
  {
    'value': 'G197',
    'viewValue': 'OVADA (AL)'
  },
  {
    'value': 'G198',
    'viewValue': 'OVARO (UD)'
  },
  {
    'value': 'G199',
    'viewValue': 'OVIGLIO (AL)'
  },
  {
    'value': 'G200',
    'viewValue': 'OVINDOLI (AQ)'
  },
  {
    'value': 'G201',
    'viewValue': 'OVODDA (NU)'
  },
  {
    'value': 'G012',
    'viewValue': 'OYACE (AO)'
  },
  {
    'value': 'G202',
    'viewValue': 'OZEGNA (TO)'
  },
  {
    'value': 'G203',
    'viewValue': 'OZIERI (SS)'
  },
  {
    'value': 'G205',
    'viewValue': "OZZANO DELL'EMILIA (BO)"
  },
  {
    'value': 'G204',
    'viewValue': 'OZZANO MONFERRATO (AL)'
  },
  {
    'value': 'G206',
    'viewValue': 'OZZERO (MI)'
  },
  {
    'value': 'G207',
    'viewValue': 'PABILLONIS (CA)'
  },
  {
    'value': 'G209',
    'viewValue': 'PACE DEL MELA (ME)'
  },
  {
    'value': 'G208',
    'viewValue': 'PACECO (TP)'
  },
  {
    'value': 'G210',
    'viewValue': 'PACENTRO (AQ)'
  },
  {
    'value': 'G211',
    'viewValue': 'PACHINO (SR)'
  },
  {
    'value': 'G212',
    'viewValue': 'PACIANO (PG)'
  },
  {
    'value': 'G213',
    'viewValue': 'PADENGHE SUL GARDA (BS)'
  },
  {
    'value': 'G214',
    'viewValue': 'PADERGNONE (TN)'
  },
  {
    'value': 'G215',
    'viewValue': 'PADERNA (AL)'
  },
  {
    'value': 'G218',
    'viewValue': "PADERNO D'ADDA (LC)"
  },
  {
    'value': 'G221',
    'viewValue': 'PADERNO DEL GRAPPA (TV)'
  },
  {
    'value': 'G220',
    'viewValue': 'PADERNO DUGNANO (MI)'
  },
  {
    'value': 'G217',
    'viewValue': 'PADERNO FRANCIACORTA (BS)'
  },
  {
    'value': 'G222',
    'viewValue': 'PADERNO PONCHIELLI (CR)'
  },
  {
    'value': 'G224',
    'viewValue': 'PADOVA (PD)'
  },
  {
    'value': 'G225',
    'viewValue': 'PADRIA (SS)'
  },
  {
    'value': 'M301',
    'viewValue': 'PADRU (SS)'
  },
  {
    'value': 'G226',
    'viewValue': 'PADULA (SA)'
  },
  {
    'value': 'G227',
    'viewValue': 'PADULI (BN)'
  },
  {
    'value': 'G228',
    'viewValue': 'PAESANA (CN)'
  },
  {
    'value': 'G229',
    'viewValue': 'PAESE (TV)'
  },
  {
    'value': 'G230',
    'viewValue': 'PAGANI (SA)'
  },
  {
    'value': 'G232',
    'viewValue': 'PAGANICO SABINO (RI)'
  },
  {
    'value': 'G233',
    'viewValue': 'PAGAZZANO (BG)'
  },
  {
    'value': 'G234',
    'viewValue': 'PAGLIARA (ME)'
  },
  {
    'value': 'G237',
    'viewValue': 'PAGLIETA (CH)'
  },
  {
    'value': 'G238',
    'viewValue': 'PAGNACCO (UD)'
  },
  {
    'value': 'G240',
    'viewValue': 'PAGNO (CN)'
  },
  {
    'value': 'G241',
    'viewValue': 'PAGNONA (LC)'
  },
  {
    'value': 'G242',
    'viewValue': 'PAGO DEL VALLO DI LAURO (AV)'
  },
  {
    'value': 'G243',
    'viewValue': 'PAGO VEIANO (BN)'
  },
  {
    'value': 'G247',
    'viewValue': 'PAISCO LOVENO (BS)'
  },
  {
    'value': 'G248',
    'viewValue': 'PAITONE (BS)'
  },
  {
    'value': 'G249',
    'viewValue': 'PALADINA (BG)'
  },
  {
    'value': 'G250',
    'viewValue': 'PALAGANO (MO)'
  },
  {
    'value': 'G251',
    'viewValue': 'PALAGIANELLO (TA)'
  },
  {
    'value': 'G252',
    'viewValue': 'PALAGIANO (TA)'
  },
  {
    'value': 'G253',
    'viewValue': 'PALAGONIA (CT)'
  },
  {
    'value': 'G254',
    'viewValue': 'PALAIA (PI)'
  },
  {
    'value': 'G255',
    'viewValue': 'PALANZANO (PR)'
  },
  {
    'value': 'G257',
    'viewValue': 'PALATA (CB)'
  },
  {
    'value': 'G258',
    'viewValue': 'PALAU (SS)'
  },
  {
    'value': 'G259',
    'viewValue': 'PALAZZAGO (BG)'
  },
  {
    'value': 'G263',
    'viewValue': 'PALAZZO ADRIANO (PA)'
  },
  {
    'value': 'G262',
    'viewValue': 'PALAZZO CANAVESE (TO)'
  },
  {
    'value': 'G260',
    'viewValue': 'PALAZZO PIGNANO (CR)'
  },
  {
    'value': 'G261',
    'viewValue': 'PALAZZO SAN GERVASIO (PZ)'
  },
  {
    'value': 'G267',
    'viewValue': 'PALAZZOLO ACREIDE (SR)'
  },
  {
    'value': 'G268',
    'viewValue': 'PALAZZOLO DELLO STELLA (UD)'
  },
  {
    'value': 'G264',
    'viewValue': "PALAZZOLO SULL'OGLIO (BS)"
  },
  {
    'value': 'G266',
    'viewValue': 'PALAZZOLO VERCELLESE (VC)'
  },
  {
    'value': 'G270',
    'viewValue': 'PALAZZUOLO SUL SENIO (FI)'
  },
  {
    'value': 'G271',
    'viewValue': 'PALENA (CH)'
  },
  {
    'value': 'G272',
    'viewValue': 'PALERMITI (CZ)'
  },
  {
    'value': 'G273',
    'viewValue': 'PALERMO (PA)'
  },
  {
    'value': 'G274',
    'viewValue': 'PALESTRINA (RM)'
  },
  {
    'value': 'G275',
    'viewValue': 'PALESTRO (PV)'
  },
  {
    'value': 'G276',
    'viewValue': 'PALIANO (FR)'
  },
  {
    'value': 'G277',
    'viewValue': 'PALIZZI (RC)'
  },
  {
    'value': 'G278',
    'viewValue': 'PALLAGORIO (KR)'
  },
  {
    'value': 'G280',
    'viewValue': 'PALLANZENO (VB)'
  },
  {
    'value': 'G281',
    'viewValue': 'PALLARE (SV)'
  },
  {
    'value': 'G283',
    'viewValue': 'PALMA CAMPANIA (NA)'
  },
  {
    'value': 'G282',
    'viewValue': 'PALMA DI MONTECHIARO (AG)'
  },
  {
    'value': 'G284',
    'viewValue': 'PALMANOVA (UD)'
  },
  {
    'value': 'G285',
    'viewValue': 'PALMARIGGI (LE)'
  },
  {
    'value': 'G286',
    'viewValue': 'PALMAS ARBOREA (OR)'
  },
  {
    'value': 'G288',
    'viewValue': 'PALMI (RC)'
  },
  {
    'value': 'G289',
    'viewValue': 'PALMIANO (AP)'
  },
  {
    'value': 'G290',
    'viewValue': 'PALMOLI (CH)'
  },
  {
    'value': 'G291',
    'viewValue': 'PALO DEL COLLE (BA)'
  },
  {
    'value': 'G293',
    'viewValue': 'PALOMBARA SABINA (RM)'
  },
  {
    'value': 'G294',
    'viewValue': 'PALOMBARO (CH)'
  },
  {
    'value': 'G292',
    'viewValue': 'PALOMONTE (SA)'
  },
  {
    'value': 'G295',
    'viewValue': 'PALOSCO (BG)'
  },
  {
    'value': 'G297',
    'viewValue': "PALU' (VR)"
  },
  {
    'value': 'G296',
    'viewValue': "PALU' DEL FERSINA (TN)"
  },
  {
    'value': 'G298',
    'viewValue': 'PALUDI (CS)'
  },
  {
    'value': 'G300',
    'viewValue': 'PALUZZA (UD)'
  },
  {
    'value': 'G302',
    'viewValue': 'PAMPARATO (CN)'
  },
  {
    'value': 'G303',
    'viewValue': 'PANCALIERI (TO)'
  },
  {
    'value': 'G304',
    'viewValue': 'PANCARANA (PV)'
  },
  {
    'value': 'G305',
    'viewValue': "PANCHIA' (TN)"
  },
  {
    'value': 'G306',
    'viewValue': 'PANDINO (CR)'
  },
  {
    'value': 'G307',
    'viewValue': 'PANETTIERI (CS)'
  },
  {
    'value': 'G308',
    'viewValue': 'PANICALE (PG)'
  },
  {
    'value': 'G311',
    'viewValue': 'PANNARANO (BN)'
  },
  {
    'value': 'G312',
    'viewValue': 'PANNI (FG)'
  },
  {
    'value': 'G315',
    'viewValue': 'PANTELLERIA (TP)'
  },
  {
    'value': 'G316',
    'viewValue': 'PANTIGLIATE (MI)'
  },
  {
    'value': 'G317',
    'viewValue': 'PAOLA (CS)'
  },
  {
    'value': 'G318',
    'viewValue': 'PAOLISI (BN)'
  },
  {
    'value': 'G320',
    'viewValue': 'PAPASIDERO (CS)'
  },
  {
    'value': 'G323',
    'viewValue': 'PAPOZZE (RO)'
  },
  {
    'value': 'G324',
    'viewValue': 'PARABIAGO (MI)'
  },
  {
    'value': 'G325',
    'viewValue': 'PARABITA (LE)'
  },
  {
    'value': 'G327',
    'viewValue': 'PARATICO (BS)'
  },
  {
    'value': 'G328',
    'viewValue': 'PARCINES .PARTSCHINS. (BZ)'
  },
  {
    'value': 'G329',
    'viewValue': "PARE' (CO)"
  },
  {
    'value': 'G330',
    'viewValue': 'PARELLA (TO)'
  },
  {
    'value': 'G331',
    'viewValue': 'PARENTI (CS)'
  },
  {
    'value': 'G333',
    'viewValue': 'PARETE (CE)'
  },
  {
    'value': 'G334',
    'viewValue': 'PARETO (AL)'
  },
  {
    'value': 'G335',
    'viewValue': 'PARGHELIA (VV)'
  },
  {
    'value': 'G336',
    'viewValue': 'PARLASCO (LC)'
  },
  {
    'value': 'G337',
    'viewValue': 'PARMA (PR)'
  },
  {
    'value': 'G338',
    'viewValue': 'PARODI LIGURE (AL)'
  },
  {
    'value': 'G339',
    'viewValue': 'PAROLDO (CN)'
  },
  {
    'value': 'G340',
    'viewValue': 'PAROLISE (AV)'
  },
  {
    'value': 'G342',
    'viewValue': 'PARONA (PV)'
  },
  {
    'value': 'G344',
    'viewValue': 'PARRANO (TR)'
  },
  {
    'value': 'G346',
    'viewValue': 'PARRE (BG)'
  },
  {
    'value': 'G347',
    'viewValue': 'PARTANNA (TP)'
  },
  {
    'value': 'G348',
    'viewValue': 'PARTINICO (PA)'
  },
  {
    'value': 'G349',
    'viewValue': 'PARUZZARO (NO)'
  },
  {
    'value': 'G350',
    'viewValue': 'PARZANICA (BG)'
  },
  {
    'value': 'G352',
    'viewValue': 'PASIAN DI PRATO (UD)'
  },
  {
    'value': 'G353',
    'viewValue': 'PASIANO DI PORDENONE (PN)'
  },
  {
    'value': 'G354',
    'viewValue': 'PASPARDO (BS)'
  },
  {
    'value': 'G358',
    'viewValue': 'PASSERANO MARMORITO (AT)'
  },
  {
    'value': 'G359',
    'viewValue': 'PASSIGNANO SUL TRASIMENO (PG)'
  },
  {
    'value': 'G361',
    'viewValue': 'PASSIRANO (BS)'
  },
  {
    'value': 'G362',
    'viewValue': 'PASTENA (FR)'
  },
  {
    'value': 'G364',
    'viewValue': 'PASTORANO (CE)'
  },
  {
    'value': 'G365',
    'viewValue': 'PASTRENGO (VR)'
  },
  {
    'value': 'G367',
    'viewValue': 'PASTURANA (AL)'
  },
  {
    'value': 'G368',
    'viewValue': 'PASTURO (LC)'
  },
  {
    'value': 'M269',
    'viewValue': 'PATERNO (PZ)'
  },
  {
    'value': 'G371',
    'viewValue': "PATERNO' (CT)"
  },
  {
    'value': 'G372',
    'viewValue': 'PATERNO CALABRO (CS)'
  },
  {
    'value': 'G370',
    'viewValue': 'PATERNOPOLI (AV)'
  },
  {
    'value': 'G374',
    'viewValue': 'PATRICA (FR)'
  },
  {
    'value': 'G376',
    'viewValue': 'PATTADA (SS)'
  },
  {
    'value': 'G377',
    'viewValue': 'PATTI (ME)'
  },
  {
    'value': 'G378',
    'viewValue': "PATU' (LE)"
  },
  {
    'value': 'G379',
    'viewValue': 'PAU (OR)'
  },
  {
    'value': 'G381',
    'viewValue': 'PAULARO (UD)'
  },
  {
    'value': 'G382',
    'viewValue': 'PAULI ARBAREI (CA)'
  },
  {
    'value': 'G384',
    'viewValue': 'PAULILATINO (OR)'
  },
  {
    'value': 'G385',
    'viewValue': 'PAULLO (MI)'
  },
  {
    'value': 'G386',
    'viewValue': 'PAUPISI (BN)'
  },
  {
    'value': 'G387',
    'viewValue': 'PAVAROLO (TO)'
  },
  {
    'value': 'G388',
    'viewValue': 'PAVIA (PV)'
  },
  {
    'value': 'G389',
    'viewValue': 'PAVIA DI UDINE (UD)'
  },
  {
    'value': 'G392',
    'viewValue': 'PAVONE CANAVESE (TO)'
  },
  {
    'value': 'G391',
    'viewValue': 'PAVONE DEL MELLA (BS)'
  },
  {
    'value': 'G393',
    'viewValue': 'PAVULLO NEL FRIGNANO (MO)'
  },
  {
    'value': 'G394',
    'viewValue': 'PAZZANO (RC)'
  },
  {
    'value': 'G395',
    'viewValue': 'PECCIOLI (PI)'
  },
  {
    'value': 'G396',
    'viewValue': 'PECCO (TO)'
  },
  {
    'value': 'G397',
    'viewValue': 'PECETTO DI VALENZA (AL)'
  },
  {
    'value': 'G398',
    'viewValue': 'PECETTO TORINESE (TO)'
  },
  {
    'value': 'G399',
    'viewValue': 'PECORARA (PC)'
  },
  {
    'value': 'G400',
    'viewValue': 'PEDACE (CS)'
  },
  {
    'value': 'G402',
    'viewValue': 'PEDARA (CT)'
  },
  {
    'value': 'G403',
    'viewValue': 'PEDASO (AP)'
  },
  {
    'value': 'G404',
    'viewValue': 'PEDAVENA (BL)'
  },
  {
    'value': 'G406',
    'viewValue': 'PEDEMONTE (VI)'
  },
  {
    'value': 'G408',
    'viewValue': 'PEDEROBBA (TV)'
  },
  {
    'value': 'G410',
    'viewValue': 'PEDESINA (SO)'
  },
  {
    'value': 'G411',
    'viewValue': 'PEDIVIGLIANO (CS)'
  },
  {
    'value': 'G412',
    'viewValue': 'PEDRENGO (BG)'
  },
  {
    'value': 'G415',
    'viewValue': 'PEGLIO (CO)'
  },
  {
    'value': 'G416',
    'viewValue': 'PEGLIO (PU)'
  },
  {
    'value': 'G417',
    'viewValue': 'PEGOGNAGA (MN)'
  },
  {
    'value': 'G418',
    'viewValue': 'PEIA (BG)'
  },
  {
    'value': 'G419',
    'viewValue': 'PEJO (TN)'
  },
  {
    'value': 'G420',
    'viewValue': 'PELAGO (FI)'
  },
  {
    'value': 'G421',
    'viewValue': 'PELLA (NO)'
  },
  {
    'value': 'G424',
    'viewValue': 'PELLEGRINO PARMENSE (PR)'
  },
  {
    'value': 'G426',
    'viewValue': 'PELLEZZANO (SA)'
  },
  {
    'value': 'G427',
    'viewValue': 'PELLIO INTELVI (CO)'
  },
  {
    'value': 'G428',
    'viewValue': 'PELLIZZANO (TN)'
  },
  {
    'value': 'G429',
    'viewValue': 'PELUGO (TN)'
  },
  {
    'value': 'G430',
    'viewValue': 'PENANGO (AT)'
  },
  {
    'value': 'G432',
    'viewValue': 'PENNA IN TEVERINA (TR)'
  },
  {
    'value': 'G436',
    'viewValue': 'PENNA SAN GIOVANNI (MC)'
  },
  {
    'value': 'G437',
    'viewValue': "PENNA SANT'ANDREA (TE)"
  },
  {
    'value': 'G433',
    'viewValue': 'PENNABILLI (PU)'
  },
  {
    'value': 'G434',
    'viewValue': 'PENNADOMO (CH)'
  },
  {
    'value': 'G435',
    'viewValue': 'PENNAPIEDIMONTE (CH)'
  },
  {
    'value': 'G438',
    'viewValue': 'PENNE (PE)'
  },
  {
    'value': 'G439',
    'viewValue': 'PENTONE (CZ)'
  },
  {
    'value': 'G441',
    'viewValue': 'PERANO (CH)'
  },
  {
    'value': 'G442',
    'viewValue': 'PERAROLO DI CADORE (BL)'
  },
  {
    'value': 'G443',
    'viewValue': 'PERCA .PERCHA. (BZ)'
  },
  {
    'value': 'G444',
    'viewValue': 'PERCILE (RM)'
  },
  {
    'value': 'G445',
    'viewValue': 'PERDASDEFOGU (NU)'
  },
  {
    'value': 'G446',
    'viewValue': 'PERDAXIUS (CA)'
  },
  {
    'value': 'G447',
    'viewValue': 'PERDIFUMO (SA)'
  },
  {
    'value': 'G448',
    'viewValue': 'PEREGO (LC)'
  },
  {
    'value': 'G449',
    'viewValue': 'PERETO (AQ)'
  },
  {
    'value': 'G450',
    'viewValue': 'PERFUGAS (SS)'
  },
  {
    'value': 'G451',
    'viewValue': 'PERGINE VALDARNO (AR)'
  },
  {
    'value': 'G452',
    'viewValue': 'PERGINE VALSUGANA (TN)'
  },
  {
    'value': 'G453',
    'viewValue': 'PERGOLA (PU)'
  },
  {
    'value': 'G454',
    'viewValue': 'PERINALDO (IM)'
  },
  {
    'value': 'G455',
    'viewValue': 'PERITO (SA)'
  },
  {
    'value': 'G456',
    'viewValue': 'PERLEDO (LC)'
  },
  {
    'value': 'G457',
    'viewValue': 'PERLETTO (CN)'
  },
  {
    'value': 'G458',
    'viewValue': 'PERLO (CN)'
  },
  {
    'value': 'G459',
    'viewValue': 'PERLOZ (AO)'
  },
  {
    'value': 'G461',
    'viewValue': 'PERNUMIA (PD)'
  },
  {
    'value': 'C013',
    'viewValue': 'PERO (MI)'
  },
  {
    'value': 'G463',
    'viewValue': 'PEROSA ARGENTINA (TO)'
  },
  {
    'value': 'G462',
    'viewValue': 'PEROSA CANAVESE (TO)'
  },
  {
    'value': 'G465',
    'viewValue': 'PERRERO (TO)'
  },
  {
    'value': 'G469',
    'viewValue': 'PERSICO DOSIMO (CR)'
  },
  {
    'value': 'G471',
    'viewValue': 'PERTENGO (VC)'
  },
  {
    'value': 'G474',
    'viewValue': 'PERTICA ALTA (BS)'
  },
  {
    'value': 'G475',
    'viewValue': 'PERTICA BASSA (BS)'
  },
  {
    'value': 'G476',
    'viewValue': 'PERTOSA (SA)'
  },
  {
    'value': 'G477',
    'viewValue': 'PERTUSIO (TO)'
  },
  {
    'value': 'G478',
    'viewValue': 'PERUGIA (PG)'
  },
  {
    'value': 'G479',
    'viewValue': 'PESARO (PU)'
  },
  {
    'value': 'G480',
    'viewValue': 'PESCAGLIA (LU)'
  },
  {
    'value': 'G481',
    'viewValue': 'PESCANTINA (VR)'
  },
  {
    'value': 'G482',
    'viewValue': 'PESCARA (PE)'
  },
  {
    'value': 'G483',
    'viewValue': 'PESCAROLO ED UNITI (CR)'
  },
  {
    'value': 'G484',
    'viewValue': 'PESCASSEROLI (AQ)'
  },
  {
    'value': 'G485',
    'viewValue': 'PESCATE (LC)'
  },
  {
    'value': 'G486',
    'viewValue': 'PESCHE (IS)'
  },
  {
    'value': 'G487',
    'viewValue': 'PESCHICI (FG)'
  },
  {
    'value': 'G488',
    'viewValue': 'PESCHIERA BORROMEO (MI)'
  },
  {
    'value': 'G489',
    'viewValue': 'PESCHIERA DEL GARDA (VR)'
  },
  {
    'value': 'G491',
    'viewValue': 'PESCIA (PT)'
  },
  {
    'value': 'G492',
    'viewValue': 'PESCINA (AQ)'
  },
  {
    'value': 'G494',
    'viewValue': 'PESCO SANNITA (BN)'
  },
  {
    'value': 'G493',
    'viewValue': 'PESCOCOSTANZO (AQ)'
  },
  {
    'value': 'G495',
    'viewValue': 'PESCOLANCIANO (IS)'
  },
  {
    'value': 'G496',
    'viewValue': 'PESCOPAGANO (PZ)'
  },
  {
    'value': 'G497',
    'viewValue': 'PESCOPENNATARO (IS)'
  },
  {
    'value': 'G498',
    'viewValue': 'PESCOROCCHIANO (RI)'
  },
  {
    'value': 'G499',
    'viewValue': 'PESCOSANSONESCO (PE)'
  },
  {
    'value': 'G500',
    'viewValue': 'PESCOSOLIDO (FR)'
  },
  {
    'value': 'G502',
    'viewValue': 'PESSANO CON BORNAGO (MI)'
  },
  {
    'value': 'G504',
    'viewValue': 'PESSINA CREMONESE (CR)'
  },
  {
    'value': 'G505',
    'viewValue': 'PESSINETTO (TO)'
  },
  {
    'value': 'G506',
    'viewValue': 'PETACCIATO (CB)'
  },
  {
    'value': 'G508',
    'viewValue': 'PETILIA POLICASTRO (KR)'
  },
  {
    'value': 'G509',
    'viewValue': 'PETINA (SA)'
  },
  {
    'value': 'G510',
    'viewValue': 'PETRALIA SOPRANA (PA)'
  },
  {
    'value': 'G511',
    'viewValue': 'PETRALIA SOTTANA (PA)'
  },
  {
    'value': 'G513',
    'viewValue': 'PETRELLA SALTO (RI)'
  },
  {
    'value': 'G512',
    'viewValue': 'PETRELLA TIFERNINA (CB)'
  },
  {
    'value': 'G514',
    'viewValue': 'PETRIANO (PU)'
  },
  {
    'value': 'G515',
    'viewValue': 'PETRIOLO (MC)'
  },
  {
    'value': 'G516',
    'viewValue': 'PETRITOLI (AP)'
  },
  {
    'value': 'G517',
    'viewValue': 'PETRIZZI (CZ)'
  },
  {
    'value': 'G518',
    'viewValue': "PETRONA' (CZ)"
  },
  {
    'value': 'M281',
    'viewValue': 'PETROSINO (TP)'
  },
  {
    'value': 'G519',
    'viewValue': 'PETRURO IRPINO (AV)'
  },
  {
    'value': 'G520',
    'viewValue': 'PETTENASCO (NO)'
  },
  {
    'value': 'G521',
    'viewValue': 'PETTINENGO (BI)'
  },
  {
    'value': 'G522',
    'viewValue': 'PETTINEO (ME)'
  },
  {
    'value': 'G523',
    'viewValue': 'PETTORANELLO DEL MOLISE (IS)'
  },
  {
    'value': 'G524',
    'viewValue': 'PETTORANO SUL GIZIO (AQ)'
  },
  {
    'value': 'G525',
    'viewValue': 'PETTORAZZA GRIMANI (RO)'
  },
  {
    'value': 'G526',
    'viewValue': 'PEVERAGNO (CN)'
  },
  {
    'value': 'G528',
    'viewValue': 'PEZZANA (VC)'
  },
  {
    'value': 'G529',
    'viewValue': 'PEZZAZE (BS)'
  },
  {
    'value': 'G532',
    'viewValue': 'PEZZOLO VALLE UZZONE (CN)'
  },
  {
    'value': 'G535',
    'viewValue': 'PIACENZA (PC)'
  },
  {
    'value': 'G534',
    'viewValue': "PIACENZA D'ADIGE (PD)"
  },
  {
    'value': 'G536',
    'viewValue': 'PIADENA (CR)'
  },
  {
    'value': 'G537',
    'viewValue': 'PIAGGE (PU)'
  },
  {
    'value': 'G538',
    'viewValue': 'PIAGGINE (SA)'
  },
  {
    'value': 'G546',
    'viewValue': 'PIAN CAMUNO (BS)'
  },
  {
    'value': 'G552',
    'viewValue': 'PIAN DI SCO (AR)'
  },
  {
    'value': 'G542',
    'viewValue': 'PIANA CRIXIA (SV)'
  },
  {
    'value': 'G543',
    'viewValue': 'PIANA DEGLI ALBANESI (PA)'
  },
  {
    'value': 'G541',
    'viewValue': 'PIANA DI MONTE VERNA (CE)'
  },
  {
    'value': 'G547',
    'viewValue': 'PIANCASTAGNAIO (SI)'
  },
  {
    'value': 'G549',
    'viewValue': 'PIANCOGNO (BS)'
  },
  {
    'value': 'G551',
    'viewValue': 'PIANDIMELETO (PU)'
  },
  {
    'value': 'G553',
    'viewValue': 'PIANE CRATI (CS)'
  },
  {
    'value': 'G555',
    'viewValue': 'PIANELLA (PE)'
  },
  {
    'value': 'G556',
    'viewValue': 'PIANELLO DEL LARIO (CO)'
  },
  {
    'value': 'G557',
    'viewValue': 'PIANELLO VAL TIDONE (PC)'
  },
  {
    'value': 'G558',
    'viewValue': 'PIANENGO (CR)'
  },
  {
    'value': 'G559',
    'viewValue': 'PIANEZZA (TO)'
  },
  {
    'value': 'G560',
    'viewValue': 'PIANEZZE (VI)'
  },
  {
    'value': 'G561',
    'viewValue': 'PIANFEI (CN)'
  },
  {
    'value': 'G564',
    'viewValue': 'PIANICO (BG)'
  },
  {
    'value': 'G565',
    'viewValue': 'PIANIGA (VE)'
  },
  {
    'value': 'G568',
    'viewValue': 'PIANO DI SORRENTO (NA)'
  },
  {
    'value': 'D546',
    'viewValue': 'PIANOPOLI (CZ)'
  },
  {
    'value': 'G570',
    'viewValue': 'PIANORO (BO)'
  },
  {
    'value': 'G571',
    'viewValue': 'PIANSANO (VT)'
  },
  {
    'value': 'G572',
    'viewValue': 'PIANTEDO (SO)'
  },
  {
    'value': 'G574',
    'viewValue': 'PIARIO (BG)'
  },
  {
    'value': 'G575',
    'viewValue': 'PIASCO (CN)'
  },
  {
    'value': 'G576',
    'viewValue': 'PIATEDA (SO)'
  },
  {
    'value': 'G577',
    'viewValue': 'PIATTO (BI)'
  },
  {
    'value': 'G582',
    'viewValue': 'PIAZZA AL SERCHIO (LU)'
  },
  {
    'value': 'G580',
    'viewValue': 'PIAZZA ARMERINA (EN)'
  },
  {
    'value': 'G579',
    'viewValue': 'PIAZZA BREMBANA (BG)'
  },
  {
    'value': 'G583',
    'viewValue': 'PIAZZATORRE (BG)'
  },
  {
    'value': 'G587',
    'viewValue': 'PIAZZOLA SUL BRENTA (PD)'
  },
  {
    'value': 'G588',
    'viewValue': 'PIAZZOLO (BG)'
  },
  {
    'value': 'G589',
    'viewValue': 'PICCIANO (PE)'
  },
  {
    'value': 'G590',
    'viewValue': 'PICERNO (PZ)'
  },
  {
    'value': 'G591',
    'viewValue': 'PICINISCO (FR)'
  },
  {
    'value': 'G592',
    'viewValue': 'PICO (FR)'
  },
  {
    'value': 'G593',
    'viewValue': 'PIEA (AT)'
  },
  {
    'value': 'G594',
    'viewValue': 'PIEDICAVALLO (BI)'
  },
  {
    'value': 'G597',
    'viewValue': 'PIEDIMONTE ETNEO (CT)'
  },
  {
    'value': 'G596',
    'viewValue': 'PIEDIMONTE MATESE (CE)'
  },
  {
    'value': 'G598',
    'viewValue': 'PIEDIMONTE SAN GERMANO (FR)'
  },
  {
    'value': 'G600',
    'viewValue': 'PIEDIMULERA (VB)'
  },
  {
    'value': 'G601',
    'viewValue': 'PIEGARO (PG)'
  },
  {
    'value': 'G602',
    'viewValue': 'PIENZA (SI)'
  },
  {
    'value': 'G603',
    'viewValue': 'PIERANICA (CR)'
  },
  {
    'value': 'G612',
    'viewValue': "PIETRA DE' GIORGI (PV)"
  },
  {
    'value': 'G605',
    'viewValue': 'PIETRA LIGURE (SV)'
  },
  {
    'value': 'G619',
    'viewValue': 'PIETRA MARAZZI (AL)'
  },
  {
    'value': 'G606',
    'viewValue': 'PIETRABBONDANTE (IS)'
  },
  {
    'value': 'G607',
    'viewValue': 'PIETRABRUNA (IM)'
  },
  {
    'value': 'G608',
    'viewValue': 'PIETRACAMELA (TE)'
  },
  {
    'value': 'G609',
    'viewValue': 'PIETRACATELLA (CB)'
  },
  {
    'value': 'G610',
    'viewValue': 'PIETRACUPA (CB)'
  },
  {
    'value': 'G611',
    'viewValue': 'PIETRADEFUSI (AV)'
  },
  {
    'value': 'G613',
    'viewValue': 'PIETRAFERRAZZANA (CH)'
  },
  {
    'value': 'G615',
    'viewValue': 'PIETRAFITTA (CS)'
  },
  {
    'value': 'G616',
    'viewValue': 'PIETRAGALLA (PZ)'
  },
  {
    'value': 'G618',
    'viewValue': 'PIETRALUNGA (PG)'
  },
  {
    'value': 'G620',
    'viewValue': 'PIETRAMELARA (CE)'
  },
  {
    'value': 'G604',
    'viewValue': 'PIETRAMONTECORVINO (FG)'
  },
  {
    'value': 'G621',
    'viewValue': 'PIETRANICO (PE)'
  },
  {
    'value': 'G622',
    'viewValue': 'PIETRAPAOLA (CS)'
  },
  {
    'value': 'G623',
    'viewValue': 'PIETRAPERTOSA (PZ)'
  },
  {
    'value': 'G624',
    'viewValue': 'PIETRAPERZIA (EN)'
  },
  {
    'value': 'G625',
    'viewValue': 'PIETRAPORZIO (CN)'
  },
  {
    'value': 'G626',
    'viewValue': 'PIETRAROJA (BN)'
  },
  {
    'value': 'G627',
    'viewValue': 'PIETRARUBBIA (PU)'
  },
  {
    'value': 'G628',
    'viewValue': 'PIETRASANTA (LU)'
  },
  {
    'value': 'G629',
    'viewValue': 'PIETRASTORNINA (AV)'
  },
  {
    'value': 'G630',
    'viewValue': 'PIETRAVAIRANO (CE)'
  },
  {
    'value': 'G631',
    'viewValue': 'PIETRELCINA (BN)'
  },
  {
    'value': 'G636',
    'viewValue': 'PIEVE A NIEVOLE (PT)'
  },
  {
    'value': 'G635',
    'viewValue': 'PIEVE ALBIGNOLA (PV)'
  },
  {
    'value': 'G638',
    'viewValue': "PIEVE D'ALPAGO (BL)"
  },
  {
    'value': 'G639',
    'viewValue': 'PIEVE DEL CAIRO (PV)'
  },
  {
    'value': 'G641',
    'viewValue': 'PIEVE DI BONO (TN)'
  },
  {
    'value': 'G642',
    'viewValue': 'PIEVE DI CADORE (BL)'
  },
  {
    'value': 'G643',
    'viewValue': 'PIEVE DI CENTO (BO)'
  },
  {
    'value': 'G633',
    'viewValue': 'PIEVE DI CORIANO (MN)'
  },
  {
    'value': 'G644',
    'viewValue': 'PIEVE DI LEDRO (TN)'
  },
  {
    'value': 'G645',
    'viewValue': 'PIEVE DI SOLIGO (TV)'
  },
  {
    'value': 'G632',
    'viewValue': 'PIEVE DI TECO (IM)'
  },
  {
    'value': 'G647',
    'viewValue': "PIEVE D'OLMI (CR)"
  },
  {
    'value': 'G634',
    'viewValue': 'PIEVE EMANUELE (MI)'
  },
  {
    'value': 'G096',
    'viewValue': 'PIEVE FISSIRAGA (LO)'
  },
  {
    'value': 'G648',
    'viewValue': 'PIEVE FOSCIANA (LU)'
  },
  {
    'value': 'G646',
    'viewValue': 'PIEVE LIGURE (GE)'
  },
  {
    'value': 'G650',
    'viewValue': 'PIEVE PORTO MORONE (PV)'
  },
  {
    'value': 'G651',
    'viewValue': 'PIEVE SAN GIACOMO (CR)'
  },
  {
    'value': 'G653',
    'viewValue': 'PIEVE SANTO STEFANO (AR)'
  },
  {
    'value': 'G656',
    'viewValue': 'PIEVE TESINO (TN)'
  },
  {
    'value': 'G657',
    'viewValue': 'PIEVE TORINA (MC)'
  },
  {
    'value': 'G658',
    'viewValue': 'PIEVE VERGONTE (VB)'
  },
  {
    'value': 'G637',
    'viewValue': 'PIEVEBOVIGLIANA (MC)'
  },
  {
    'value': 'G649',
    'viewValue': 'PIEVEPELAGO (MO)'
  },
  {
    'value': 'G659',
    'viewValue': 'PIGLIO (FR)'
  },
  {
    'value': 'G660',
    'viewValue': 'PIGNA (IM)'
  },
  {
    'value': 'G662',
    'viewValue': 'PIGNATARO INTERAMNA (FR)'
  },
  {
    'value': 'G661',
    'viewValue': 'PIGNATARO MAGGIORE (CE)'
  },
  {
    'value': 'G663',
    'viewValue': 'PIGNOLA (PZ)'
  },
  {
    'value': 'G664',
    'viewValue': 'PIGNONE (SP)'
  },
  {
    'value': 'G665',
    'viewValue': 'PIGRA (CO)'
  },
  {
    'value': 'G666',
    'viewValue': 'PILA (VC)'
  },
  {
    'value': 'G669',
    'viewValue': 'PIMENTEL (CA)'
  },
  {
    'value': 'G670',
    'viewValue': 'PIMONTE (NA)'
  },
  {
    'value': 'G671',
    'viewValue': 'PINAROLO PO (PV)'
  },
  {
    'value': 'G672',
    'viewValue': 'PINASCA (TO)'
  },
  {
    'value': 'G673',
    'viewValue': 'PINCARA (RO)'
  },
  {
    'value': 'G674',
    'viewValue': 'PINEROLO (TO)'
  },
  {
    'value': 'F831',
    'viewValue': 'PINETO (TE)'
  },
  {
    'value': 'G676',
    'viewValue': "PINO D'ASTI (AT)"
  },
  {
    'value': 'G677',
    'viewValue': 'PINO SULLA SPONDA DEL LAGO MAGGIORE (VA)'
  },
  {
    'value': 'G678',
    'viewValue': 'PINO TORINESE (TO)'
  },
  {
    'value': 'G680',
    'viewValue': 'PINZANO AL TAGLIAMENTO (PN)'
  },
  {
    'value': 'G681',
    'viewValue': 'PINZOLO (TN)'
  },
  {
    'value': 'G682',
    'viewValue': 'PIOBBICO (PU)'
  },
  {
    'value': 'G683',
    'viewValue': "PIOBESI D'ALBA (CN)"
  },
  {
    'value': 'G684',
    'viewValue': 'PIOBESI TORINESE (TO)'
  },
  {
    'value': 'G685',
    'viewValue': 'PIODE (VC)'
  },
  {
    'value': 'G686',
    'viewValue': 'PIOLTELLO (MI)'
  },
  {
    'value': 'G687',
    'viewValue': 'PIOMBINO (LI)'
  },
  {
    'value': 'G688',
    'viewValue': 'PIOMBINO DESE (PD)'
  },
  {
    'value': 'G690',
    'viewValue': 'PIORACO (MC)'
  },
  {
    'value': 'G691',
    'viewValue': 'PIOSSASCO (TO)'
  },
  {
    'value': 'G692',
    'viewValue': "PIOVA' MASSAIA (AT)"
  },
  {
    'value': 'G693',
    'viewValue': 'PIOVE DI SACCO (PD)'
  },
  {
    'value': 'G694',
    'viewValue': 'PIOVENE ROCCHETTE (VI)'
  },
  {
    'value': 'G695',
    'viewValue': 'PIOVERA (AL)'
  },
  {
    'value': 'G696',
    'viewValue': 'PIOZZANO (PC)'
  },
  {
    'value': 'G697',
    'viewValue': 'PIOZZO (CN)'
  },
  {
    'value': 'G699',
    'viewValue': 'PIRAINO (ME)'
  },
  {
    'value': 'G702',
    'viewValue': 'PISA (PI)'
  },
  {
    'value': 'G703',
    'viewValue': 'PISANO (NO)'
  },
  {
    'value': 'G705',
    'viewValue': 'PISCINA (TO)'
  },
  {
    'value': 'M291',
    'viewValue': 'PISCINAS (CA)'
  },
  {
    'value': 'G707',
    'viewValue': 'PISCIOTTA (SA)'
  },
  {
    'value': 'G710',
    'viewValue': 'PISOGNE (BS)'
  },
  {
    'value': 'G704',
    'viewValue': 'PISONIANO (RM)'
  },
  {
    'value': 'G712',
    'viewValue': 'PISTICCI (MT)'
  },
  {
    'value': 'G713',
    'viewValue': 'PISTOIA (PT)'
  },
  {
    'value': 'G715',
    'viewValue': 'PITEGLIO (PT)'
  },
  {
    'value': 'G716',
    'viewValue': 'PITIGLIANO (GR)'
  },
  {
    'value': 'G717',
    'viewValue': 'PIUBEGA (MN)'
  },
  {
    'value': 'G718',
    'viewValue': 'PIURO (SO)'
  },
  {
    'value': 'G719',
    'viewValue': 'PIVERONE (TO)'
  },
  {
    'value': 'G720',
    'viewValue': 'PIZZALE (PV)'
  },
  {
    'value': 'G721',
    'viewValue': 'PIZZIGHETTONE (CR)'
  },
  {
    'value': 'G722',
    'viewValue': 'PIZZO (VV)'
  },
  {
    'value': 'G724',
    'viewValue': 'PIZZOFERRATO (CH)'
  },
  {
    'value': 'G726',
    'viewValue': 'PIZZOLI (AQ)'
  },
  {
    'value': 'G727',
    'viewValue': 'PIZZONE (IS)'
  },
  {
    'value': 'G728',
    'viewValue': 'PIZZONI (VV)'
  },
  {
    'value': 'G729',
    'viewValue': 'PLACANICA (RC)'
  },
  {
    'value': 'G733',
    'viewValue': 'PLATACI (CS)'
  },
  {
    'value': 'G734',
    'viewValue': 'PLATANIA (CZ)'
  },
  {
    'value': 'G735',
    'viewValue': "PLATI' (RC)"
  },
  {
    'value': 'G299',
    'viewValue': 'PLAUS .PLAUS. (BZ)'
  },
  {
    'value': 'G737',
    'viewValue': 'PLESIO (CO)'
  },
  {
    'value': 'G740',
    'viewValue': 'PLOAGHE (SS)'
  },
  {
    'value': 'G741',
    'viewValue': 'PLODIO (SV)'
  },
  {
    'value': 'G742',
    'viewValue': 'POCAPAGLIA (CN)'
  },
  {
    'value': 'G743',
    'viewValue': 'POCENIA (UD)'
  },
  {
    'value': 'G746',
    'viewValue': 'PODENZANA (MS)'
  },
  {
    'value': 'G747',
    'viewValue': 'PODENZANO (PC)'
  },
  {
    'value': 'G749',
    'viewValue': 'POFI (FR)'
  },
  {
    'value': 'G751',
    'viewValue': 'POGGIARDO (LE)'
  },
  {
    'value': 'G752',
    'viewValue': 'POGGIBONSI (SI)'
  },
  {
    'value': 'G754',
    'viewValue': 'POGGIO A CAIANO (PO)'
  },
  {
    'value': 'G755',
    'viewValue': 'POGGIO BERNI (RN)'
  },
  {
    'value': 'G756',
    'viewValue': 'POGGIO BUSTONE (RI)'
  },
  {
    'value': 'G757',
    'viewValue': 'POGGIO CATINO (RI)'
  },
  {
    'value': 'G761',
    'viewValue': 'POGGIO IMPERIALE (FG)'
  },
  {
    'value': 'G763',
    'viewValue': 'POGGIO MIRTETO (RI)'
  },
  {
    'value': 'G764',
    'viewValue': 'POGGIO MOIANO (RI)'
  },
  {
    'value': 'G765',
    'viewValue': 'POGGIO NATIVO (RI)'
  },
  {
    'value': 'G766',
    'viewValue': 'POGGIO PICENZE (AQ)'
  },
  {
    'value': 'G768',
    'viewValue': 'POGGIO RENATICO (FE)'
  },
  {
    'value': 'G753',
    'viewValue': 'POGGIO RUSCO (MN)'
  },
  {
    'value': 'G770',
    'viewValue': 'POGGIO SAN LORENZO (RI)'
  },
  {
    'value': 'G771',
    'viewValue': 'POGGIO SAN MARCELLO (AN)'
  },
  {
    'value': 'D566',
    'viewValue': 'POGGIO SAN VICINO (MC)'
  },
  {
    'value': 'B317',
    'viewValue': 'POGGIO SANNITA (IS)'
  },
  {
    'value': 'G758',
    'viewValue': 'POGGIODOMO (PG)'
  },
  {
    'value': 'G760',
    'viewValue': 'POGGIOFIORITO (CH)'
  },
  {
    'value': 'G762',
    'viewValue': 'POGGIOMARINO (NA)'
  },
  {
    'value': 'G767',
    'viewValue': 'POGGIOREALE (TP)'
  },
  {
    'value': 'G769',
    'viewValue': 'POGGIORSINI (BA)'
  },
  {
    'value': 'G431',
    'viewValue': 'POGGIRIDENTI (SO)'
  },
  {
    'value': 'G772',
    'viewValue': 'POGLIANO MILANESE (MI)'
  },
  {
    'value': 'G773',
    'viewValue': 'POGNANA LARIO (CO)'
  },
  {
    'value': 'G774',
    'viewValue': 'POGNANO (BG)'
  },
  {
    'value': 'G775',
    'viewValue': 'POGNO (NO)'
  },
  {
    'value': 'G776',
    'viewValue': 'POIANA MAGGIORE (VI)'
  },
  {
    'value': 'G777',
    'viewValue': 'POIRINO (TO)'
  },
  {
    'value': 'G779',
    'viewValue': 'POLAVENO (BS)'
  },
  {
    'value': 'G780',
    'viewValue': 'POLCENIGO (PN)'
  },
  {
    'value': 'G778',
    'viewValue': 'POLA (PL)'
  },
  {
    'value': 'G782',
    'viewValue': 'POLESELLA (RO)'
  },
  {
    'value': 'G783',
    'viewValue': 'POLESINE PARMENSE (PR)'
  },
  {
    'value': 'G784',
    'viewValue': 'POLI (RM)'
  },
  {
    'value': 'G785',
    'viewValue': 'POLIA (VV)'
  },
  {
    'value': 'G786',
    'viewValue': 'POLICORO (MT)'
  },
  {
    'value': 'G787',
    'viewValue': 'POLIGNANO A MARE (BA)'
  },
  {
    'value': 'G789',
    'viewValue': 'POLINAGO (MO)'
  },
  {
    'value': 'G790',
    'viewValue': 'POLINO (TR)'
  },
  {
    'value': 'G791',
    'viewValue': 'POLISTENA (RC)'
  },
  {
    'value': 'G792',
    'viewValue': 'POLIZZI GENEROSA (PA)'
  },
  {
    'value': 'G793',
    'viewValue': 'POLLA (SA)'
  },
  {
    'value': 'G794',
    'viewValue': 'POLLEIN (AO)'
  },
  {
    'value': 'G795',
    'viewValue': 'POLLENA TROCCHIA (NA)'
  },
  {
    'value': 'F567',
    'viewValue': 'POLLENZA (MC)'
  },
  {
    'value': 'G796',
    'viewValue': 'POLLICA (SA)'
  },
  {
    'value': 'G797',
    'viewValue': 'POLLINA (PA)'
  },
  {
    'value': 'G798',
    'viewValue': 'POLLONE (BI)'
  },
  {
    'value': 'G799',
    'viewValue': 'POLLUTRI (CH)'
  },
  {
    'value': 'G800',
    'viewValue': 'POLONGHERA (CN)'
  },
  {
    'value': 'G801',
    'viewValue': 'POLPENAZZE DEL GARDA (BS)'
  },
  {
    'value': 'G802',
    'viewValue': 'POLVERARA (PD)'
  },
  {
    'value': 'G803',
    'viewValue': 'POLVERIGI (AN)'
  },
  {
    'value': 'G804',
    'viewValue': 'POMARANCE (PI)'
  },
  {
    'value': 'G805',
    'viewValue': 'POMARETTO (TO)'
  },
  {
    'value': 'G806',
    'viewValue': 'POMARICO (MT)'
  },
  {
    'value': 'G807',
    'viewValue': 'POMARO MONFERRATO (AL)'
  },
  {
    'value': 'G808',
    'viewValue': 'POMAROLO (TN)'
  },
  {
    'value': 'G809',
    'viewValue': 'POMBIA (NO)'
  },
  {
    'value': 'G811',
    'viewValue': 'POMEZIA (RM)'
  },
  {
    'value': 'G812',
    'viewValue': "POMIGLIANO D'ARCO (NA)"
  },
  {
    'value': 'G813',
    'viewValue': 'POMPEI (NA)'
  },
  {
    'value': 'G814',
    'viewValue': 'POMPEIANA (IM)'
  },
  {
    'value': 'G815',
    'viewValue': 'POMPIANO (BS)'
  },
  {
    'value': 'G816',
    'viewValue': 'POMPONESCO (MN)'
  },
  {
    'value': 'G817',
    'viewValue': 'POMPU (OR)'
  },
  {
    'value': 'G818',
    'viewValue': 'PONCARALE (BS)'
  },
  {
    'value': 'G820',
    'viewValue': 'PONDERANO (BI)'
  },
  {
    'value': 'G821',
    'viewValue': 'PONNA (CO)'
  },
  {
    'value': 'G822',
    'viewValue': 'PONSACCO (PI)'
  },
  {
    'value': 'G823',
    'viewValue': 'PONSO (PD)'
  },
  {
    'value': 'G826',
    'viewValue': 'PONT CANAVESE (TO)'
  },
  {
    'value': 'G825',
    'viewValue': 'PONTASSIEVE (FI)'
  },
  {
    'value': 'G545',
    'viewValue': 'PONTBOSET (AO)'
  },
  {
    'value': 'G827',
    'viewValue': 'PONTE (BN)'
  },
  {
    'value': 'G833',
    'viewValue': 'PONTE BUGGIANESE (PT)'
  },
  {
    'value': 'G842',
    'viewValue': "PONTE DELL'OLIO (PC)"
  },
  {
    'value': 'G844',
    'viewValue': 'PONTE DI LEGNO (BS)'
  },
  {
    'value': 'G846',
    'viewValue': 'PONTE DI PIAVE (TV)'
  },
  {
    'value': 'G830',
    'viewValue': 'PONTE GARDENA .WAIDBRUCK. (BZ)'
  },
  {
    'value': 'G829',
    'viewValue': 'PONTE IN VALTELLINA (SO)'
  },
  {
    'value': 'G847',
    'viewValue': 'PONTE LAMBRO (CO)'
  },
  {
    'value': 'B662',
    'viewValue': 'PONTE NELLE ALPI (BL)'
  },
  {
    'value': 'G851',
    'viewValue': 'PONTE NIZZA (PV)'
  },
  {
    'value': 'F941',
    'viewValue': 'PONTE NOSSA (BG)'
  },
  {
    'value': 'G855',
    'viewValue': "PONTE SAN NICOLO' (PD)"
  },
  {
    'value': 'G856',
    'viewValue': 'PONTE SAN PIETRO (BG)'
  },
  {
    'value': 'G831',
    'viewValue': 'PONTEBBA (UD)'
  },
  {
    'value': 'G834',
    'viewValue': 'PONTECAGNANO FAIANO (SA)'
  },
  {
    'value': 'G836',
    'viewValue': 'PONTECCHIO POLESINE (RO)'
  },
  {
    'value': 'G837',
    'viewValue': 'PONTECHIANALE (CN)'
  },
  {
    'value': 'G838',
    'viewValue': 'PONTECORVO (FR)'
  },
  {
    'value': 'G839',
    'viewValue': 'PONTECURONE (AL)'
  },
  {
    'value': 'G840',
    'viewValue': 'PONTEDASSIO (IM)'
  },
  {
    'value': 'G843',
    'viewValue': 'PONTEDERA (PI)'
  },
  {
    'value': 'G848',
    'viewValue': 'PONTELANDOLFO (BN)'
  },
  {
    'value': 'G849',
    'viewValue': 'PONTELATONE (CE)'
  },
  {
    'value': 'G850',
    'viewValue': 'PONTELONGO (PD)'
  },
  {
    'value': 'G852',
    'viewValue': 'PONTENURE (PC)'
  },
  {
    'value': 'G853',
    'viewValue': 'PONTERANICA (BG)'
  },
  {
    'value': 'G858',
    'viewValue': 'PONTESTURA (AL)'
  },
  {
    'value': 'G859',
    'viewValue': 'PONTEVICO (BS)'
  },
  {
    'value': 'G860',
    'viewValue': 'PONTEY (AO)'
  },
  {
    'value': 'G861',
    'viewValue': 'PONTI (AL)'
  },
  {
    'value': 'G862',
    'viewValue': 'PONTI SUL MINCIO (MN)'
  },
  {
    'value': 'G864',
    'viewValue': 'PONTIDA (BG)'
  },
  {
    'value': 'G865',
    'viewValue': 'PONTINIA (LT)'
  },
  {
    'value': 'G866',
    'viewValue': 'PONTINVREA (SV)'
  },
  {
    'value': 'G867',
    'viewValue': 'PONTIROLO NUOVO (BG)'
  },
  {
    'value': 'G869',
    'viewValue': 'PONTOGLIO (BS)'
  },
  {
    'value': 'G870',
    'viewValue': 'PONTREMOLI (MS)'
  },
  {
    'value': 'G854',
    'viewValue': 'PONT-SAINT-MARTIN (AO)'
  },
  {
    'value': 'G871',
    'viewValue': 'PONZA (LT)'
  },
  {
    'value': 'G873',
    'viewValue': 'PONZANO DI FERMO (AP)'
  },
  {
    'value': 'G872',
    'viewValue': 'PONZANO MONFERRATO (AL)'
  },
  {
    'value': 'G874',
    'viewValue': 'PONZANO ROMANO (RM)'
  },
  {
    'value': 'G875',
    'viewValue': 'PONZANO VENETO (TV)'
  },
  {
    'value': 'G877',
    'viewValue': 'PONZONE (AL)'
  },
  {
    'value': 'G878',
    'viewValue': 'POPOLI (PE)'
  },
  {
    'value': 'G879',
    'viewValue': 'POPPI (AR)'
  },
  {
    'value': 'G881',
    'viewValue': 'PORANO (TR)'
  },
  {
    'value': 'G882',
    'viewValue': 'PORCARI (LU)'
  },
  {
    'value': 'G886',
    'viewValue': 'PORCIA (PN)'
  },
  {
    'value': 'G888',
    'viewValue': 'PORDENONE (PN)'
  },
  {
    'value': 'G889',
    'viewValue': 'PORLEZZA (CO)'
  },
  {
    'value': 'G890',
    'viewValue': 'PORNASSIO (IM)'
  },
  {
    'value': 'G891',
    'viewValue': 'PORPETTO (UD)'
  },
  {
    'value': 'A558',
    'viewValue': 'PORRETTA TERME (BO)'
  },
  {
    'value': 'G894',
    'viewValue': 'PORTACOMARO (AT)'
  },
  {
    'value': 'G895',
    'viewValue': 'PORTALBERA (PV)'
  },
  {
    'value': 'G900',
    'viewValue': 'PORTE (TO)'
  },
  {
    'value': 'G902',
    'viewValue': 'PORTICI (NA)'
  },
  {
    'value': 'G903',
    'viewValue': 'PORTICO DI CASERTA (CE)'
  },
  {
    'value': 'G904',
    'viewValue': 'PORTICO E SAN BENEDETTO (FC)'
  },
  {
    'value': 'G905',
    'viewValue': 'PORTIGLIOLA (RC)'
  },
  {
    'value': 'E680',
    'viewValue': 'PORTO AZZURRO (LI)'
  },
  {
    'value': 'G906',
    'viewValue': 'PORTO CERESIO (VA)'
  },
  {
    'value': 'M263',
    'viewValue': 'PORTO CESAREO (LE)'
  },
  {
    'value': 'F299',
    'viewValue': 'PORTO EMPEDOCLE (AG)'
  },
  {
    'value': 'G917',
    'viewValue': 'PORTO MANTOVANO (MN)'
  },
  {
    'value': 'G919',
    'viewValue': 'PORTO RECANATI (MC)'
  },
  {
    'value': 'G920',
    'viewValue': 'PORTO SAN GIORGIO (AP)'
  },
  {
    'value': 'G921',
    'viewValue': "PORTO SANT'ELPIDIO (AP)"
  },
  {
    'value': 'G923',
    'viewValue': 'PORTO TOLLE (RO)'
  },
  {
    'value': 'G924',
    'viewValue': 'PORTO TORRES (SS)'
  },
  {
    'value': 'G907',
    'viewValue': 'PORTO VALTRAVAGLIA (VA)'
  },
  {
    'value': 'G926',
    'viewValue': 'PORTO VIRO (RO)'
  },
  {
    'value': 'G909',
    'viewValue': "PORTOBUFFOLE' (TV)"
  },
  {
    'value': 'G910',
    'viewValue': 'PORTOCANNONE (CB)'
  },
  {
    'value': 'G912',
    'viewValue': 'PORTOFERRAIO (LI)'
  },
  {
    'value': 'G913',
    'viewValue': 'PORTOFINO (GE)'
  },
  {
    'value': 'G914',
    'viewValue': 'PORTOGRUARO (VE)'
  },
  {
    'value': 'G916',
    'viewValue': 'PORTOMAGGIORE (FE)'
  },
  {
    'value': 'M257',
    'viewValue': 'PORTOPALO DI CAPO PASSERO (SR)'
  },
  {
    'value': 'G922',
    'viewValue': 'PORTOSCUSO (CA)'
  },
  {
    'value': 'G925',
    'viewValue': 'PORTOVENERE (SP)'
  },
  {
    'value': 'G927',
    'viewValue': 'PORTULA (BI)'
  },
  {
    'value': 'G929',
    'viewValue': 'POSADA (NU)'
  },
  {
    'value': 'G931',
    'viewValue': 'POSINA (VI)'
  },
  {
    'value': 'G932',
    'viewValue': 'POSITANO (SA)'
  },
  {
    'value': 'G933',
    'viewValue': 'POSSAGNO (TV)'
  },
  {
    'value': 'G934',
    'viewValue': 'POSTA (RI)'
  },
  {
    'value': 'G935',
    'viewValue': 'POSTA FIBRENO (FR)'
  },
  {
    'value': 'G936',
    'viewValue': 'POSTAL .BURGSTALL. (BZ)'
  },
  {
    'value': 'G937',
    'viewValue': 'POSTALESIO (SO)'
  },
  {
    'value': 'G939',
    'viewValue': 'POSTIGLIONE (SA)'
  },
  {
    'value': 'G940',
    'viewValue': 'POSTUA (VC)'
  },
  {
    'value': 'G942',
    'viewValue': 'POTENZA (PZ)'
  },
  {
    'value': 'F632',
    'viewValue': 'POTENZA PICENA (MC)'
  },
  {
    'value': 'G943',
    'viewValue': 'POVE DEL GRAPPA (VI)'
  },
  {
    'value': 'G944',
    'viewValue': 'POVEGLIANO (TV)'
  },
  {
    'value': 'G945',
    'viewValue': 'POVEGLIANO VERONESE (VR)'
  },
  {
    'value': 'G947',
    'viewValue': 'POVIGLIO (RE)'
  },
  {
    'value': 'G949',
    'viewValue': 'POVOLETTO (UD)'
  },
  {
    'value': 'G950',
    'viewValue': 'POZZA DI FASSA (TN)'
  },
  {
    'value': 'G951',
    'viewValue': 'POZZAGLIA SABINA (RI)'
  },
  {
    'value': 'B914',
    'viewValue': 'POZZAGLIO ED UNITI (CR)'
  },
  {
    'value': 'G953',
    'viewValue': 'POZZALLO (RG)'
  },
  {
    'value': 'G954',
    'viewValue': 'POZZILLI (IS)'
  },
  {
    'value': 'G955',
    'viewValue': "POZZO D'ADDA (MI)"
  },
  {
    'value': 'G960',
    'viewValue': 'POZZOL GROPPO (AL)'
  },
  {
    'value': 'G959',
    'viewValue': 'POZZOLENGO (BS)'
  },
  {
    'value': 'G957',
    'viewValue': 'POZZOLEONE (VI)'
  },
  {
    'value': 'G961',
    'viewValue': 'POZZOLO FORMIGARO (AL)'
  },
  {
    'value': 'G962',
    'viewValue': 'POZZOMAGGIORE (SS)'
  },
  {
    'value': 'G963',
    'viewValue': 'POZZONOVO (PD)'
  },
  {
    'value': 'G964',
    'viewValue': 'POZZUOLI (NA)'
  },
  {
    'value': 'G966',
    'viewValue': 'POZZUOLO DEL FRIULI (UD)'
  },
  {
    'value': 'G965',
    'viewValue': 'POZZUOLO MARTESANA (MI)'
  },
  {
    'value': 'G968',
    'viewValue': 'PRADALUNGA (BG)'
  },
  {
    'value': 'G969',
    'viewValue': 'PRADAMANO (UD)'
  },
  {
    'value': 'G970',
    'viewValue': 'PRADLEVES (CN)'
  },
  {
    'value': 'G973',
    'viewValue': 'PRAGELATO (TO)'
  },
  {
    'value': 'G975',
    'viewValue': 'PRAIA A MARE (CS)'
  },
  {
    'value': 'G976',
    'viewValue': 'PRAIANO (SA)'
  },
  {
    'value': 'G977',
    'viewValue': 'PRALBOINO (BS)'
  },
  {
    'value': 'G978',
    'viewValue': 'PRALI (TO)'
  },
  {
    'value': 'G979',
    'viewValue': 'PRALORMO (TO)'
  },
  {
    'value': 'G980',
    'viewValue': 'PRALUNGO (BI)'
  },
  {
    'value': 'G981',
    'viewValue': 'PRAMAGGIORE (VE)'
  },
  {
    'value': 'G982',
    'viewValue': 'PRAMOLLO (TO)'
  },
  {
    'value': 'G985',
    'viewValue': 'PRAROLO (VC)'
  },
  {
    'value': 'G986',
    'viewValue': 'PRAROSTINO (TO)'
  },
  {
    'value': 'G987',
    'viewValue': 'PRASCO (AL)'
  },
  {
    'value': 'G988',
    'viewValue': 'PRASCORSANO (TO)'
  },
  {
    'value': 'G989',
    'viewValue': 'PRASO (TN)'
  },
  {
    'value': 'G993',
    'viewValue': 'PRATA CAMPORTACCIO (SO)'
  },
  {
    'value': 'G992',
    'viewValue': "PRATA D'ANSIDONIA (AQ)"
  },
  {
    'value': 'G994',
    'viewValue': 'PRATA DI PORDENONE (PN)'
  },
  {
    'value': 'G990',
    'viewValue': 'PRATA DI PRINCIPATO ULTRA (AV)'
  },
  {
    'value': 'G991',
    'viewValue': 'PRATA SANNITA (CE)'
  },
  {
    'value': 'G995',
    'viewValue': 'PRATELLA (CE)'
  },
  {
    'value': 'G997',
    'viewValue': 'PRATIGLIONE (TO)'
  },
  {
    'value': 'G999',
    'viewValue': 'PRATO (PO)'
  },
  {
    'value': 'H004',
    'viewValue': 'PRATO ALLO STELVIO .PRAD AM STILFSERJOCH. (BZ)'
  },
  {
    'value': 'H002',
    'viewValue': 'PRATO CARNICO (UD)'
  },
  {
    'value': 'H001',
    'viewValue': 'PRATO SESIA (NO)'
  },
  {
    'value': 'H007',
    'viewValue': 'PRATOLA PELIGNA (AQ)'
  },
  {
    'value': 'H006',
    'viewValue': 'PRATOLA SERRA (AV)'
  },
  {
    'value': 'H008',
    'viewValue': 'PRATOVECCHIO (AR)'
  },
  {
    'value': 'H010',
    'viewValue': 'PRAVISDOMINI (PN)'
  },
  {
    'value': 'G974',
    'viewValue': 'PRAY (BI)'
  },
  {
    'value': 'H011',
    'viewValue': 'PRAZZO (CN)'
  },
  {
    'value': 'H014',
    'viewValue': 'PRECENICCO (UD)'
  },
  {
    'value': 'H015',
    'viewValue': 'PRECI (PG)'
  },
  {
    'value': 'H017',
    'viewValue': 'PREDAPPIO (FC)'
  },
  {
    'value': 'H018',
    'viewValue': 'PREDAZZO (TN)'
  },
  {
    'value': 'H019',
    'viewValue': 'PREDOI .PRETTAU. (BZ)'
  },
  {
    'value': 'H020',
    'viewValue': 'PREDORE (BG)'
  },
  {
    'value': 'H021',
    'viewValue': 'PREDOSA (AL)'
  },
  {
    'value': 'H022',
    'viewValue': 'PREGANZIOL (TV)'
  },
  {
    'value': 'H026',
    'viewValue': 'PREGNANA MILANESE (MI)'
  },
  {
    'value': 'H027',
    'viewValue': "PRELA' (IM)"
  },
  {
    'value': 'H028',
    'viewValue': 'PREMANA (LC)'
  },
  {
    'value': 'H029',
    'viewValue': 'PREMARIACCO (UD)'
  },
  {
    'value': 'H030',
    'viewValue': 'PREMENO (VB)'
  },
  {
    'value': 'H033',
    'viewValue': 'PREMIA (VB)'
  },
  {
    'value': 'H034',
    'viewValue': 'PREMILCUORE (FC)'
  },
  {
    'value': 'H036',
    'viewValue': 'PREMOLO (BG)'
  },
  {
    'value': 'H037',
    'viewValue': 'PREMOSELLO-CHIOVENDA (VB)'
  },
  {
    'value': 'H038',
    'viewValue': 'PREONE (UD)'
  },
  {
    'value': 'H039',
    'viewValue': 'PREORE (TN)'
  },
  {
    'value': 'H040',
    'viewValue': 'PREPOTTO (UD)'
  },
  {
    'value': 'H042',
    'viewValue': "PRE'-SAINT-DIDIER (AO)"
  },
  {
    'value': 'H043',
    'viewValue': 'PRESEGLIE (BS)'
  },
  {
    'value': 'H045',
    'viewValue': 'PRESENZANO (CE)'
  },
  {
    'value': 'H046',
    'viewValue': 'PRESEZZO (BG)'
  },
  {
    'value': 'H047',
    'viewValue': 'PRESICCE (LE)'
  },
  {
    'value': 'H048',
    'viewValue': 'PRESSANA (VR)'
  },
  {
    'value': 'H050',
    'viewValue': 'PRESTINE (BS)'
  },
  {
    'value': 'H052',
    'viewValue': 'PRETORO (CH)'
  },
  {
    'value': 'H055',
    'viewValue': 'PREVALLE (BS)'
  },
  {
    'value': 'H056',
    'viewValue': 'PREZZA (AQ)'
  },
  {
    'value': 'H057',
    'viewValue': 'PREZZO (TN)'
  },
  {
    'value': 'H059',
    'viewValue': 'PRIERO (CN)'
  },
  {
    'value': 'H062',
    'viewValue': 'PRIGNANO CILENTO (SA)'
  },
  {
    'value': 'H061',
    'viewValue': 'PRIGNANO SULLA SECCHIA (MO)'
  },
  {
    'value': 'H063',
    'viewValue': 'PRIMALUNA (LC)'
  },
  {
    'value': 'H068',
    'viewValue': 'PRIOCCA (CN)'
  },
  {
    'value': 'H069',
    'viewValue': 'PRIOLA (CN)'
  },
  {
    'value': 'M279',
    'viewValue': 'PRIOLO GARGALLO (SR)'
  },
  {
    'value': 'G698',
    'viewValue': 'PRIVERNO (LT)'
  },
  {
    'value': 'H070',
    'viewValue': 'PRIZZI (PA)'
  },
  {
    'value': 'H071',
    'viewValue': 'PROCENO (VT)'
  },
  {
    'value': 'H072',
    'viewValue': 'PROCIDA (NA)'
  },
  {
    'value': 'H073',
    'viewValue': 'PROPATA (GE)'
  },
  {
    'value': 'H074',
    'viewValue': 'PROSERPIO (CO)'
  },
  {
    'value': 'H076',
    'viewValue': 'PROSSEDI (LT)'
  },
  {
    'value': 'H078',
    'viewValue': "PROVAGLIO D'ISEO (BS)"
  },
  {
    'value': 'H077',
    'viewValue': 'PROVAGLIO VAL SABBIA (BS)'
  },
  {
    'value': 'H081',
    'viewValue': 'PROVES .PROVEIS. (BZ)'
  },
  {
    'value': 'H083',
    'viewValue': 'PROVVIDENTI (CB)'
  },
  {
    'value': 'H085',
    'viewValue': 'PRUNETTO (CN)'
  },
  {
    'value': 'H086',
    'viewValue': 'PUEGNAGO SUL GARDA (BS)'
  },
  {
    'value': 'H087',
    'viewValue': 'PUGLIANELLO (BN)'
  },
  {
    'value': 'H088',
    'viewValue': 'PULA (CA)'
  },
  {
    'value': 'H089',
    'viewValue': 'PULFERO (UD)'
  },
  {
    'value': 'H090',
    'viewValue': 'PULSANO (TA)'
  },
  {
    'value': 'H091',
    'viewValue': 'PUMENENGO (BG)'
  },
  {
    'value': 'H092',
    'viewValue': "PUOS D'ALPAGO (BL)"
  },
  {
    'value': 'H094',
    'viewValue': 'PUSIANO (CO)'
  },
  {
    'value': 'H095',
    'viewValue': 'PUTIFIGARI (SS)'
  },
  {
    'value': 'H096',
    'viewValue': 'PUTIGNANO (BA)'
  },
  {
    'value': 'H097',
    'viewValue': 'QUADRELLE (AV)'
  },
  {
    'value': 'H098',
    'viewValue': 'QUADRI (CH)'
  },
  {
    'value': 'H100',
    'viewValue': 'QUAGLIUZZO (TO)'
  },
  {
    'value': 'H101',
    'viewValue': 'QUALIANO (NA)'
  },
  {
    'value': 'H102',
    'viewValue': 'QUARANTI (AT)'
  },
  {
    'value': 'H103',
    'viewValue': 'QUAREGNA (BI)'
  },
  {
    'value': 'H104',
    'viewValue': 'QUARGNENTO (AL)'
  },
  {
    'value': 'H106',
    'viewValue': 'QUARNA SOPRA (VB)'
  },
  {
    'value': 'H107',
    'viewValue': 'QUARNA SOTTO (VB)'
  },
  {
    'value': 'H108',
    'viewValue': 'QUARONA (VC)'
  },
  {
    'value': 'H109',
    'viewValue': 'QUARRATA (PT)'
  },
  {
    'value': 'H110',
    'viewValue': 'QUART (AO)'
  },
  {
    'value': 'H114',
    'viewValue': 'QUARTO (NA)'
  },
  {
    'value': 'H117',
    'viewValue': "QUARTO D'ALTINO (VE)"
  },
  {
    'value': 'H118',
    'viewValue': "QUARTU SANT'ELENA (CA)"
  },
  {
    'value': 'H119',
    'viewValue': 'QUARTUCCIU (CA)'
  },
  {
    'value': 'H120',
    'viewValue': 'QUASSOLO (TO)'
  },
  {
    'value': 'H121',
    'viewValue': 'QUATTORDIO (AL)'
  },
  {
    'value': 'H122',
    'viewValue': 'QUATTRO CASTELLA (RE)'
  },
  {
    'value': 'H124',
    'viewValue': 'QUERO (BL)'
  },
  {
    'value': 'H126',
    'viewValue': 'QUILIANO (SV)'
  },
  {
    'value': 'H127',
    'viewValue': 'QUINCINETTO (TO)'
  },
  {
    'value': 'H128',
    'viewValue': 'QUINDICI (AV)'
  },
  {
    'value': 'H129',
    'viewValue': 'QUINGENTOLE (MN)'
  },
  {
    'value': 'H130',
    'viewValue': 'QUINTANO (CR)'
  },
  {
    'value': 'H131',
    'viewValue': 'QUINTO DI TREVISO (TV)'
  },
  {
    'value': 'H132',
    'viewValue': 'QUINTO VERCELLESE (VC)'
  },
  {
    'value': 'H134',
    'viewValue': 'QUINTO VICENTINO (VI)'
  },
  {
    'value': 'H140',
    'viewValue': "QUINZANO D'OGLIO (BS)"
  },
  {
    'value': 'H143',
    'viewValue': 'QUISTELLO (MN)'
  },
  {
    'value': 'H145',
    'viewValue': 'QUITTENGO (BI)'
  },
  {
    'value': 'H146',
    'viewValue': 'RABBI (TN)'
  },
  {
    'value': 'H147',
    'viewValue': 'RACALE (LE)'
  },
  {
    'value': 'H148',
    'viewValue': 'RACALMUTO (AG)'
  },
  {
    'value': 'H150',
    'viewValue': 'RACCONIGI (CN)'
  },
  {
    'value': 'H151',
    'viewValue': 'RACCUJA (ME)'
  },
  {
    'value': 'H152',
    'viewValue': 'RACINES .RATSCHINGS. (BZ)'
  },
  {
    'value': 'H153',
    'viewValue': 'RADDA IN CHIANTI (SI)'
  },
  {
    'value': 'H154',
    'viewValue': 'RADDUSA (CT)'
  },
  {
    'value': 'H156',
    'viewValue': 'RADICOFANI (SI)'
  },
  {
    'value': 'H157',
    'viewValue': 'RADICONDOLI (SI)'
  },
  {
    'value': 'H159',
    'viewValue': 'RAFFADALI (AG)'
  },
  {
    'value': 'M287',
    'viewValue': 'RAGALNA (CT)'
  },
  {
    'value': 'H161',
    'viewValue': 'RAGOGNA (UD)'
  },
  {
    'value': 'H162',
    'viewValue': 'RAGOLI (TN)'
  },
  {
    'value': 'H163',
    'viewValue': 'RAGUSA (RG)'
  },
  {
    'value': 'H166',
    'viewValue': 'RAIANO (AQ)'
  },
  {
    'value': 'H168',
    'viewValue': 'RAMACCA (CT)'
  },
  {
    'value': 'G654',
    'viewValue': 'RAMISETO (RE)'
  },
  {
    'value': 'H171',
    'viewValue': 'RAMPONIO VERNA (CO)'
  },
  {
    'value': 'H173',
    'viewValue': 'RANCIO VALCUVIA (VA)'
  },
  {
    'value': 'H174',
    'viewValue': 'RANCO (VA)'
  },
  {
    'value': 'H175',
    'viewValue': 'RANDAZZO (CT)'
  },
  {
    'value': 'H176',
    'viewValue': 'RANICA (BG)'
  },
  {
    'value': 'H177',
    'viewValue': 'RANZANICO (BG)'
  },
  {
    'value': 'H180',
    'viewValue': 'RANZO (IM)'
  },
  {
    'value': 'H182',
    'viewValue': 'RAPAGNANO (AP)'
  },
  {
    'value': 'H183',
    'viewValue': 'RAPALLO (GE)'
  },
  {
    'value': 'H184',
    'viewValue': 'RAPINO (CH)'
  },
  {
    'value': 'H185',
    'viewValue': 'RAPOLANO TERME (SI)'
  },
  {
    'value': 'H186',
    'viewValue': 'RAPOLLA (PZ)'
  },
  {
    'value': 'H187',
    'viewValue': 'RAPONE (PZ)'
  },
  {
    'value': 'H188',
    'viewValue': 'RASSA (VC)'
  },
  {
    'value': 'H189',
    'viewValue': 'RASUN ANTERSELVA .RASEN ANTHOLZ. (BZ)'
  },
  {
    'value': 'H192',
    'viewValue': 'RASURA (SO)'
  },
  {
    'value': 'H194',
    'viewValue': 'RAVANUSA (AG)'
  },
  {
    'value': 'H195',
    'viewValue': 'RAVARINO (MO)'
  },
  {
    'value': 'H196',
    'viewValue': 'RAVASCLETTO (UD)'
  },
  {
    'value': 'H198',
    'viewValue': 'RAVELLO (SA)'
  },
  {
    'value': 'H199',
    'viewValue': 'RAVENNA (RA)'
  },
  {
    'value': 'H200',
    'viewValue': 'RAVEO (UD)'
  },
  {
    'value': 'H202',
    'viewValue': 'RAVISCANINA (CE)'
  },
  {
    'value': 'H203',
    'viewValue': 'RE (VB)'
  },
  {
    'value': 'H204',
    'viewValue': 'REA (PV)'
  },
  {
    'value': 'H205',
    'viewValue': 'REALMONTE (AG)'
  },
  {
    'value': 'H206',
    'viewValue': 'REANA DEL ROIALE (UD)'
  },
  {
    'value': 'H207',
    'viewValue': 'REANO (TO)'
  },
  {
    'value': 'H210',
    'viewValue': 'RECALE (CE)'
  },
  {
    'value': 'H211',
    'viewValue': 'RECANATI (MC)'
  },
  {
    'value': 'H212',
    'viewValue': 'RECCO (GE)'
  },
  {
    'value': 'H213',
    'viewValue': 'RECETTO (NO)'
  },
  {
    'value': 'H214',
    'viewValue': 'RECOARO TERME (VI)'
  },
  {
    'value': 'H216',
    'viewValue': 'REDAVALLE (PV)'
  },
  {
    'value': 'H218',
    'viewValue': 'REDONDESCO (MN)'
  },
  {
    'value': 'H219',
    'viewValue': 'REFRANCORE (AT)'
  },
  {
    'value': 'H220',
    'viewValue': 'REFRONTOLO (TV)'
  },
  {
    'value': 'H221',
    'viewValue': 'REGALBUTO (EN)'
  },
  {
    'value': 'H222',
    'viewValue': 'REGGELLO (FI)'
  },
  {
    'value': 'H224',
    'viewValue': 'REGGIO DI CALABRIA (RC)'
  },
  {
    'value': 'H223',
    'viewValue': "REGGIO NELL'EMILIA (RE)"
  },
  {
    'value': 'H225',
    'viewValue': 'REGGIOLO (RE)'
  },
  {
    'value': 'H227',
    'viewValue': 'REINO (BN)'
  },
  {
    'value': 'H228',
    'viewValue': 'REITANO (ME)'
  },
  {
    'value': 'H229',
    'viewValue': 'REMANZACCO (UD)'
  },
  {
    'value': 'H230',
    'viewValue': 'REMEDELLO (BS)'
  },
  {
    'value': 'H233',
    'viewValue': 'RENATE (MI)'
  },
  {
    'value': 'H234',
    'viewValue': 'RENATE VEDUGGIO (MI)'
  },
  {
    'value': 'H235',
    'viewValue': 'RENDE (CS)'
  },
  {
    'value': 'H236',
    'viewValue': 'RENON .RITTEN. (BZ)'
  },
  {
    'value': 'H238',
    'viewValue': 'RESANA (TV)'
  },
  {
    'value': 'H240',
    'viewValue': 'RESCALDINA (MI)'
  },
  {
    'value': 'H242',
    'viewValue': 'RESIA (UD)'
  },
  {
    'value': 'H244',
    'viewValue': 'RESIUTTA (UD)'
  },
  {
    'value': 'H245',
    'viewValue': 'RESUTTANO (CL)'
  },
  {
    'value': 'H246',
    'viewValue': 'RETORBIDO (PV)'
  },
  {
    'value': 'H247',
    'viewValue': 'REVELLO (CN)'
  },
  {
    'value': 'H248',
    'viewValue': 'REVERE (MN)'
  },
  {
    'value': 'H250',
    'viewValue': "REVIGLIASCO D'ASTI (AT)"
  },
  {
    'value': 'H253',
    'viewValue': 'REVINE LAGO (TV)'
  },
  {
    'value': 'H254',
    'viewValue': "REVO' (TN)"
  },
  {
    'value': 'H255',
    'viewValue': 'REZZAGO (CO)'
  },
  {
    'value': 'H256',
    'viewValue': 'REZZATO (BS)'
  },
  {
    'value': 'H257',
    'viewValue': 'REZZO (IM)'
  },
  {
    'value': 'H258',
    'viewValue': 'REZZOAGLIO (GE)'
  },
  {
    'value': 'H262',
    'viewValue': 'RHEMES-NOTRE-DAME (AO)'
  },
  {
    'value': 'H263',
    'viewValue': 'RHEMES-SAINT-GEORGES (AO)'
  },
  {
    'value': 'H264',
    'viewValue': 'RHO (MI)'
  },
  {
    'value': 'H265',
    'viewValue': 'RIACE (RC)'
  },
  {
    'value': 'H266',
    'viewValue': 'RIALTO (SV)'
  },
  {
    'value': 'H267',
    'viewValue': 'RIANO (RM)'
  },
  {
    'value': 'H268',
    'viewValue': 'RIARDO (CE)'
  },
  {
    'value': 'H269',
    'viewValue': 'RIBERA (AG)'
  },
  {
    'value': 'H270',
    'viewValue': 'RIBORDONE (TO)'
  },
  {
    'value': 'H271',
    'viewValue': 'RICADI (VV)'
  },
  {
    'value': 'H272',
    'viewValue': 'RICALDONE (AL)'
  },
  {
    'value': 'H273',
    'viewValue': 'RICCIA (CB)'
  },
  {
    'value': 'H274',
    'viewValue': 'RICCIONE (RN)'
  },
  {
    'value': 'H275',
    'viewValue': "RICCO' DEL GOLFO DI SPEZIA (SP)"
  },
  {
    'value': 'H276',
    'viewValue': 'RICENGO (CR)'
  },
  {
    'value': 'H277',
    'viewValue': 'RICIGLIANO (SA)'
  },
  {
    'value': 'H280',
    'viewValue': 'RIESE PIO X (TV)'
  },
  {
    'value': 'H281',
    'viewValue': 'RIESI (CL)'
  },
  {
    'value': 'H282',
    'viewValue': 'RIETI (RI)'
  },
  {
    'value': 'H284',
    'viewValue': 'RIFIANO .RIFFIAN. (BZ)'
  },
  {
    'value': 'H285',
    'viewValue': 'RIFREDDO (CN)'
  },
  {
    'value': 'H288',
    'viewValue': 'RIGNANO FLAMINIO (RM)'
  },
  {
    'value': 'H287',
    'viewValue': 'RIGNANO GARGANICO (FG)'
  },
  {
    'value': 'H286',
    'viewValue': "RIGNANO SULL'ARNO (FI)"
  },
  {
    'value': 'H289',
    'viewValue': 'RIGOLATO (UD)'
  },
  {
    'value': 'H291',
    'viewValue': 'RIMA SAN GIUSEPPE (VC)'
  },
  {
    'value': 'H292',
    'viewValue': 'RIMASCO (VC)'
  },
  {
    'value': 'H293',
    'viewValue': 'RIMELLA (VC)'
  },
  {
    'value': 'H294',
    'viewValue': 'RIMINI (RN)'
  },
  {
    'value': 'M391',
    'viewValue': 'RIO (LI)'
  },
  {
    'value': 'H299',
    'viewValue': 'RIO DI PUSTERIA (BZ)'
  },
  {
    'value': 'H305',
    'viewValue': 'RIO MARINA (LI)'
  },
  {
    'value': 'H297',
    'viewValue': "RIO NELL'ELBA (LI)"
  },
  {
    'value': 'H298',
    'viewValue': 'RIO SALICETO (RE)'
  },
  {
    'value': 'H300',
    'viewValue': 'RIOFREDDO (RM)'
  },
  {
    'value': 'H301',
    'viewValue': 'RIOLA SARDO (OR)'
  },
  {
    'value': 'H302',
    'viewValue': 'RIOLO TERME (RA)'
  },
  {
    'value': 'H303',
    'viewValue': 'RIOLUNATO (MO)'
  },
  {
    'value': 'H304',
    'viewValue': 'RIOMAGGIORE (SP)'
  },
  {
    'value': 'H307',
    'viewValue': 'RIONERO IN VULTURE (PZ)'
  },
  {
    'value': 'H308',
    'viewValue': 'RIONERO SANNITICO (IS)'
  },
  {
    'value': 'H320',
    'viewValue': 'RIPA TEATINA (CH)'
  },
  {
    'value': 'H311',
    'viewValue': 'RIPABOTTONI (CB)'
  },
  {
    'value': 'H312',
    'viewValue': 'RIPACANDIDA (PZ)'
  },
  {
    'value': 'H313',
    'viewValue': 'RIPALIMOSANI (CB)'
  },
  {
    'value': 'H314',
    'viewValue': 'RIPALTA ARPINA (CR)'
  },
  {
    'value': 'H315',
    'viewValue': 'RIPALTA CREMASCA (CR)'
  },
  {
    'value': 'H316',
    'viewValue': 'RIPALTA GUERINA (CR)'
  },
  {
    'value': 'H319',
    'viewValue': 'RIPARBELLA (PI)'
  },
  {
    'value': 'H321',
    'viewValue': 'RIPATRANSONE (AP)'
  },
  {
    'value': 'H322',
    'viewValue': 'RIPE (AN)'
  },
  {
    'value': 'H323',
    'viewValue': 'RIPE SAN GINESIO (MC)'
  },
  {
    'value': 'H324',
    'viewValue': 'RIPI (FR)'
  },
  {
    'value': 'H325',
    'viewValue': 'RIPOSTO (CT)'
  },
  {
    'value': 'H326',
    'viewValue': 'RITTANA (CN)'
  },
  {
    'value': 'H330',
    'viewValue': 'RIVA DEL GARDA (TN)'
  },
  {
    'value': 'H331',
    'viewValue': 'RIVA DI SOLTO (BG)'
  },
  {
    'value': 'H328',
    'viewValue': 'RIVA LIGURE (IM)'
  },
  {
    'value': 'H337',
    'viewValue': 'RIVA PRESSO CHIERI (TO)'
  },
  {
    'value': 'H329',
    'viewValue': 'RIVA VALDOBBIA (VC)'
  },
  {
    'value': 'H333',
    'viewValue': 'RIVALBA (TO)'
  },
  {
    'value': 'H334',
    'viewValue': 'RIVALTA BORMIDA (AL)'
  },
  {
    'value': 'H335',
    'viewValue': 'RIVALTA DI TORINO (TO)'
  },
  {
    'value': 'H327',
    'viewValue': 'RIVAMONTE AGORDINO (BL)'
  },
  {
    'value': 'H336',
    'viewValue': 'RIVANAZZANO (PV)'
  },
  {
    'value': 'H338',
    'viewValue': 'RIVARA (TO)'
  },
  {
    'value': 'H340',
    'viewValue': 'RIVAROLO CANAVESE (TO)'
  },
  {
    'value': 'H341',
    'viewValue': 'RIVAROLO DEL RE ED UNITI (CR)'
  },
  {
    'value': 'H342',
    'viewValue': 'RIVAROLO MANTOVANO (MN)'
  },
  {
    'value': 'H343',
    'viewValue': 'RIVARONE (AL)'
  },
  {
    'value': 'H344',
    'viewValue': 'RIVAROSSA (TO)'
  },
  {
    'value': 'H346',
    'viewValue': 'RIVE (VC)'
  },
  {
    'value': 'H347',
    'viewValue': "RIVE D'ARCANO (UD)"
  },
  {
    'value': 'H348',
    'viewValue': 'RIVELLO (PZ)'
  },
  {
    'value': 'H350',
    'viewValue': 'RIVERGARO (PC)'
  },
  {
    'value': 'H352',
    'viewValue': 'RIVIGNANO (UD)'
  },
  {
    'value': 'H353',
    'viewValue': 'RIVISONDOLI (AQ)'
  },
  {
    'value': 'H354',
    'viewValue': 'RIVODUTRI (RI)'
  },
  {
    'value': 'H355',
    'viewValue': 'RIVOLI (TO)'
  },
  {
    'value': 'H356',
    'viewValue': 'RIVOLI VERONESE (VR)'
  },
  {
    'value': 'H357',
    'viewValue': "RIVOLTA D'ADDA (CR)"
  },
  {
    'value': 'H359',
    'viewValue': 'RIZZICONI (RC)'
  },
  {
    'value': 'H360',
    'viewValue': 'RO (FE)'
  },
  {
    'value': 'H361',
    'viewValue': 'ROANA (VI)'
  },
  {
    'value': 'H362',
    'viewValue': 'ROASCHIA (CN)'
  },
  {
    'value': 'H363',
    'viewValue': 'ROASCIO (CN)'
  },
  {
    'value': 'H365',
    'viewValue': 'ROASIO (VC)'
  },
  {
    'value': 'H366',
    'viewValue': 'ROATTO (AT)'
  },
  {
    'value': 'H367',
    'viewValue': 'ROBASSOMERO (TO)'
  },
  {
    'value': 'G223',
    'viewValue': 'ROBBIATE (LC)'
  },
  {
    'value': 'H369',
    'viewValue': 'ROBBIO (PV)'
  },
  {
    'value': 'H371',
    'viewValue': 'ROBECCHETTO CON INDUNO (MI)'
  },
  {
    'value': 'H372',
    'viewValue': "ROBECCO D'OGLIO (CR)"
  },
  {
    'value': 'H375',
    'viewValue': 'ROBECCO PAVESE (PV)'
  },
  {
    'value': 'H373',
    'viewValue': 'ROBECCO SUL NAVIGLIO (MI)'
  },
  {
    'value': 'H376',
    'viewValue': 'ROBELLA (AT)'
  },
  {
    'value': 'H377',
    'viewValue': 'ROBILANTE (CN)'
  },
  {
    'value': 'H378',
    'viewValue': 'ROBURENT (CN)'
  },
  {
    'value': 'H386',
    'viewValue': 'ROCCA CANAVESE (TO)'
  },
  {
    'value': 'H387',
    'viewValue': 'ROCCA CANTERANO (RM)'
  },
  {
    'value': 'H391',
    'viewValue': "ROCCA CIGLIE' (CN)"
  },
  {
    'value': 'H392',
    'viewValue': "ROCCA D'ARAZZO (AT)"
  },
  {
    'value': 'H395',
    'viewValue': "ROCCA DE' BALDI (CN)"
  },
  {
    'value': 'H396',
    'viewValue': "ROCCA DE' GIORGI (PV)"
  },
  {
    'value': 'H398',
    'viewValue': "ROCCA D'EVANDRO (CE)"
  },
  {
    'value': 'H399',
    'viewValue': 'ROCCA DI BOTTE (AQ)'
  },
  {
    'value': 'H400',
    'viewValue': 'ROCCA DI CAMBIO (AQ)'
  },
  {
    'value': 'H401',
    'viewValue': 'ROCCA DI CAVE (RM)'
  },
  {
    'value': 'H402',
    'viewValue': 'ROCCA DI MEZZO (AQ)'
  },
  {
    'value': 'H403',
    'viewValue': 'ROCCA DI NETO (KR)'
  },
  {
    'value': 'H404',
    'viewValue': 'ROCCA DI PAPA (RM)'
  },
  {
    'value': 'H414',
    'viewValue': 'ROCCA GRIMALDA (AL)'
  },
  {
    'value': 'H416',
    'viewValue': 'ROCCA IMPERIALE (CS)'
  },
  {
    'value': 'H421',
    'viewValue': 'ROCCA MASSIMA (LT)'
  },
  {
    'value': 'H429',
    'viewValue': 'ROCCA PIA (AQ)'
  },
  {
    'value': 'H379',
    'viewValue': 'ROCCA PIETORE (BL)'
  },
  {
    'value': 'H432',
    'viewValue': 'ROCCA PRIORA (RM)'
  },
  {
    'value': 'H437',
    'viewValue': 'ROCCA SAN CASCIANO (FC)'
  },
  {
    'value': 'H438',
    'viewValue': 'ROCCA SAN FELICE (AV)'
  },
  {
    'value': 'H439',
    'viewValue': 'ROCCA SAN GIOVANNI (CH)'
  },
  {
    'value': 'H440',
    'viewValue': 'ROCCA SANTA MARIA (TE)'
  },
  {
    'value': 'H441',
    'viewValue': 'ROCCA SANTO STEFANO (RM)'
  },
  {
    'value': 'H446',
    'viewValue': 'ROCCA SINIBALDA (RI)'
  },
  {
    'value': 'H450',
    'viewValue': 'ROCCA SUSELLA (PV)'
  },
  {
    'value': 'H382',
    'viewValue': 'ROCCABASCERANA (AV)'
  },
  {
    'value': 'H383',
    'viewValue': 'ROCCABERNARDA (KR)'
  },
  {
    'value': 'H384',
    'viewValue': 'ROCCABIANCA (PR)'
  },
  {
    'value': 'H385',
    'viewValue': 'ROCCABRUNA (CN)'
  },
  {
    'value': 'H389',
    'viewValue': 'ROCCACASALE (AQ)'
  },
  {
    'value': 'H393',
    'viewValue': 'ROCCADARCE (FR)'
  },
  {
    'value': 'H394',
    'viewValue': 'ROCCADASPIDE (SA)'
  },
  {
    'value': 'H405',
    'viewValue': 'ROCCAFIORITA (ME)'
  },
  {
    'value': 'H390',
    'viewValue': 'ROCCAFLUVIONE (AP)'
  },
  {
    'value': 'H408',
    'viewValue': 'ROCCAFORTE DEL GRECO (RC)'
  },
  {
    'value': 'H406',
    'viewValue': 'ROCCAFORTE LIGURE (AL)'
  },
  {
    'value': 'H407',
    'viewValue': "ROCCAFORTE MONDOVI' (CN)"
  },
  {
    'value': 'H409',
    'viewValue': 'ROCCAFORZATA (TA)'
  },
  {
    'value': 'H410',
    'viewValue': 'ROCCAFRANCA (BS)'
  },
  {
    'value': 'H411',
    'viewValue': 'ROCCAGIOVINE (RM)'
  },
  {
    'value': 'H412',
    'viewValue': 'ROCCAGLORIOSA (SA)'
  },
  {
    'value': 'H413',
    'viewValue': 'ROCCAGORGA (LT)'
  },
  {
    'value': 'H417',
    'viewValue': 'ROCCALBEGNA (GR)'
  },
  {
    'value': 'H418',
    'viewValue': 'ROCCALUMERA (ME)'
  },
  {
    'value': 'H420',
    'viewValue': 'ROCCAMANDOLFI (IS)'
  },
  {
    'value': 'H422',
    'viewValue': 'ROCCAMENA (PA)'
  },
  {
    'value': 'H423',
    'viewValue': 'ROCCAMONFINA (CE)'
  },
  {
    'value': 'H424',
    'viewValue': 'ROCCAMONTEPIANO (CH)'
  },
  {
    'value': 'H425',
    'viewValue': 'ROCCAMORICE (PE)'
  },
  {
    'value': 'H426',
    'viewValue': 'ROCCANOVA (PZ)'
  },
  {
    'value': 'H427',
    'viewValue': 'ROCCANTICA (RI)'
  },
  {
    'value': 'H428',
    'viewValue': 'ROCCAPALUMBA (PA)'
  },
  {
    'value': 'H431',
    'viewValue': 'ROCCAPIEMONTE (SA)'
  },
  {
    'value': 'H433',
    'viewValue': 'ROCCARAINOLA (NA)'
  },
  {
    'value': 'H434',
    'viewValue': 'ROCCARASO (AQ)'
  },
  {
    'value': 'H436',
    'viewValue': 'ROCCAROMANA (CE)'
  },
  {
    'value': 'H442',
    'viewValue': 'ROCCASCALEGNA (CH)'
  },
  {
    'value': 'H443',
    'viewValue': 'ROCCASECCA (FR)'
  },
  {
    'value': 'H444',
    'viewValue': 'ROCCASECCA DEI VOLSCI (LT)'
  },
  {
    'value': 'H445',
    'viewValue': 'ROCCASICURA (IS)'
  },
  {
    'value': 'H447',
    'viewValue': 'ROCCASPARVERA (CN)'
  },
  {
    'value': 'H448',
    'viewValue': 'ROCCASPINALVETI (CH)'
  },
  {
    'value': 'H449',
    'viewValue': 'ROCCASTRADA (GR)'
  },
  {
    'value': 'H380',
    'viewValue': 'ROCCAVALDINA (ME)'
  },
  {
    'value': 'H451',
    'viewValue': 'ROCCAVERANO (AT)'
  },
  {
    'value': 'H452',
    'viewValue': 'ROCCAVIGNALE (SV)'
  },
  {
    'value': 'H453',
    'viewValue': 'ROCCAVIONE (CN)'
  },
  {
    'value': 'H454',
    'viewValue': 'ROCCAVIVARA (CB)'
  },
  {
    'value': 'H456',
    'viewValue': 'ROCCELLA IONICA (RC)'
  },
  {
    'value': 'H455',
    'viewValue': 'ROCCELLA VALDEMONE (ME)'
  },
  {
    'value': 'H458',
    'viewValue': 'ROCCHETTA A VOLTURNO (IS)'
  },
  {
    'value': 'H462',
    'viewValue': 'ROCCHETTA BELBO (CN)'
  },
  {
    'value': 'H461',
    'viewValue': 'ROCCHETTA DI VARA (SP)'
  },
  {
    'value': 'H459',
    'viewValue': 'ROCCHETTA E CROCE (CE)'
  },
  {
    'value': 'H465',
    'viewValue': 'ROCCHETTA LIGURE (AL)'
  },
  {
    'value': 'H460',
    'viewValue': 'ROCCHETTA NERVINA (IM)'
  },
  {
    'value': 'H466',
    'viewValue': 'ROCCHETTA PALAFEA (AT)'
  },
  {
    'value': 'H467',
    'viewValue': "ROCCHETTA SANT'ANTONIO (FG)"
  },
  {
    'value': 'H468',
    'viewValue': 'ROCCHETTA TANARO (AT)'
  },
  {
    'value': 'H470',
    'viewValue': 'RODANO (MI)'
  },
  {
    'value': 'H472',
    'viewValue': 'RODDI (CN)'
  },
  {
    'value': 'H473',
    'viewValue': 'RODDINO (CN)'
  },
  {
    'value': 'H474',
    'viewValue': 'RODELLO (CN)'
  },
  {
    'value': 'H475',
    'viewValue': 'RODENGO .RODENECK. (BZ)'
  },
  {
    'value': 'H477',
    'viewValue': 'RODENGO-SAIANO (BS)'
  },
  {
    'value': 'H478',
    'viewValue': 'RODERO (CO)'
  },
  {
    'value': 'H480',
    'viewValue': 'RODI GARGANICO (FG)'
  },
  {
    'value': 'H479',
    'viewValue': "RODI' MILICI (ME)"
  },
  {
    'value': 'H481',
    'viewValue': 'RODIGO (MN)'
  },
  {
    'value': 'H484',
    'viewValue': "ROE' VOLCIANO (BS)"
  },
  {
    'value': 'H485',
    'viewValue': 'ROFRANO (SA)'
  },
  {
    'value': 'H486',
    'viewValue': 'ROGENO (LC)'
  },
  {
    'value': 'H488',
    'viewValue': 'ROGGIANO GRAVINA (CS)'
  },
  {
    'value': 'H489',
    'viewValue': 'ROGHUDI (RC)'
  },
  {
    'value': 'H490',
    'viewValue': 'ROGLIANO (CS)'
  },
  {
    'value': 'H491',
    'viewValue': 'ROGNANO (PV)'
  },
  {
    'value': 'H492',
    'viewValue': 'ROGNO (BG)'
  },
  {
    'value': 'H493',
    'viewValue': 'ROGOLO (SO)'
  },
  {
    'value': 'H494',
    'viewValue': 'ROIATE (RM)'
  },
  {
    'value': 'H495',
    'viewValue': 'ROIO DEL SANGRO (CH)'
  },
  {
    'value': 'H497',
    'viewValue': 'ROISAN (AO)'
  },
  {
    'value': 'H498',
    'viewValue': 'ROLETTO (TO)'
  },
  {
    'value': 'H500',
    'viewValue': 'ROLO (RE)'
  },
  {
    'value': 'H501',
    'viewValue': 'ROMA (RM)'
  },
  {
    'value': 'H503',
    'viewValue': 'ROMAGNANO AL MONTE (SA)'
  },
  {
    'value': 'H502',
    'viewValue': 'ROMAGNANO SESIA (NO)'
  },
  {
    'value': 'H505',
    'viewValue': 'ROMAGNESE (PV)'
  },
  {
    'value': 'H506',
    'viewValue': 'ROMALLO (TN)'
  },
  {
    'value': 'H507',
    'viewValue': 'ROMANA (SS)'
  },
  {
    'value': 'H508',
    'viewValue': 'ROMANENGO (CR)'
  },
  {
    'value': 'H511',
    'viewValue': 'ROMANO CANAVESE (TO)'
  },
  {
    'value': 'H512',
    'viewValue': "ROMANO D'EZZELINO (VI)"
  },
  {
    'value': 'H509',
    'viewValue': 'ROMANO DI LOMBARDIA (BG)'
  },
  {
    'value': 'H514',
    'viewValue': "ROMANS D'ISONZO (GO)"
  },
  {
    'value': 'H516',
    'viewValue': 'ROMBIOLO (VV)'
  },
  {
    'value': 'H517',
    'viewValue': 'ROMENO (TN)'
  },
  {
    'value': 'H518',
    'viewValue': 'ROMENTINO (NO)'
  },
  {
    'value': 'H519',
    'viewValue': 'ROMETTA (ME)'
  },
  {
    'value': 'H521',
    'viewValue': 'RONAGO (CO)'
  },
  {
    'value': 'H522',
    'viewValue': "RONCA' (VR)"
  },
  {
    'value': 'H523',
    'viewValue': 'RONCADE (TV)'
  },
  {
    'value': 'H525',
    'viewValue': 'RONCADELLE (BS)'
  },
  {
    'value': 'H527',
    'viewValue': 'RONCARO (PV)'
  },
  {
    'value': 'H528',
    'viewValue': 'RONCEGNO (TN)'
  },
  {
    'value': 'H529',
    'viewValue': 'RONCELLO (MI)'
  },
  {
    'value': 'H531',
    'viewValue': 'RONCHI DEI LEGIONARI (GO)'
  },
  {
    'value': 'H532',
    'viewValue': 'RONCHI VALSUGANA (TN)'
  },
  {
    'value': 'H533',
    'viewValue': 'RONCHIS (UD)'
  },
  {
    'value': 'H534',
    'viewValue': 'RONCIGLIONE (VT)'
  },
  {
    'value': 'H540',
    'viewValue': "RONCO ALL'ADIGE (VR)"
  },
  {
    'value': 'H538',
    'viewValue': 'RONCO BIELLESE (BI)'
  },
  {
    'value': 'H537',
    'viewValue': 'RONCO BRIANTINO (MI)'
  },
  {
    'value': 'H539',
    'viewValue': 'RONCO CANAVESE (TO)'
  },
  {
    'value': 'H536',
    'viewValue': 'RONCO SCRIVIA (GE)'
  },
  {
    'value': 'H535',
    'viewValue': 'RONCOBELLO (BG)'
  },
  {
    'value': 'H541',
    'viewValue': 'RONCOFERRARO (MN)'
  },
  {
    'value': 'H542',
    'viewValue': 'RONCOFREDDO (FC)'
  },
  {
    'value': 'H544',
    'viewValue': 'RONCOLA (BG)'
  },
  {
    'value': 'H545',
    'viewValue': 'RONCONE (TN)'
  },
  {
    'value': 'H546',
    'viewValue': 'RONDANINA (GE)'
  },
  {
    'value': 'H547',
    'viewValue': 'RONDISSONE (TO)'
  },
  {
    'value': 'H549',
    'viewValue': 'RONSECCO (VC)'
  },
  {
    'value': 'M303',
    'viewValue': 'RONZO-CHIENIS (TN)'
  },
  {
    'value': 'H552',
    'viewValue': 'RONZONE (TN)'
  },
  {
    'value': 'H553',
    'viewValue': 'ROPPOLO (BI)'
  },
  {
    'value': 'H554',
    'viewValue': "RORA' (TO)"
  },
  {
    'value': 'H556',
    'viewValue': "ROSA' (VI)"
  },
  {
    'value': 'H558',
    'viewValue': 'ROSARNO (RC)'
  },
  {
    'value': 'H559',
    'viewValue': 'ROSASCO (PV)'
  },
  {
    'value': 'H560',
    'viewValue': 'ROSATE (MI)'
  },
  {
    'value': 'H561',
    'viewValue': 'ROSAZZA (BI)'
  },
  {
    'value': 'H562',
    'viewValue': 'ROSCIANO (PE)'
  },
  {
    'value': 'H564',
    'viewValue': 'ROSCIGNO (SA)'
  },
  {
    'value': 'H565',
    'viewValue': 'ROSE (CS)'
  },
  {
    'value': 'H566',
    'viewValue': 'ROSELLO (CH)'
  },
  {
    'value': 'H572',
    'viewValue': 'ROSETO CAPO SPULICO (CS)'
  },
  {
    'value': 'F585',
    'viewValue': 'ROSETO DEGLI ABRUZZI (TE)'
  },
  {
    'value': 'H568',
    'viewValue': 'ROSETO VALFORTORE (FG)'
  },
  {
    'value': 'H570',
    'viewValue': 'ROSIGNANO MARITTIMO (LI)'
  },
  {
    'value': 'H569',
    'viewValue': 'ROSIGNANO MONFERRATO (AL)'
  },
  {
    'value': 'H573',
    'viewValue': 'ROSOLINA (RO)'
  },
  {
    'value': 'H574',
    'viewValue': 'ROSOLINI (SR)'
  },
  {
    'value': 'H575',
    'viewValue': 'ROSORA (AN)'
  },
  {
    'value': 'H577',
    'viewValue': 'ROSSA (VC)'
  },
  {
    'value': 'H578',
    'viewValue': 'ROSSANA (CN)'
  },
  {
    'value': 'H579',
    'viewValue': 'ROSSANO (CS)'
  },
  {
    'value': 'H580',
    'viewValue': 'ROSSANO VENETO (VI)'
  },
  {
    'value': 'H581',
    'viewValue': 'ROSSIGLIONE (GE)'
  },
  {
    'value': 'H583',
    'viewValue': 'ROSTA (TO)'
  },
  {
    'value': 'H584',
    'viewValue': "ROTA D'IMAGNA (BG)"
  },
  {
    'value': 'H585',
    'viewValue': 'ROTA GRECA (CS)'
  },
  {
    'value': 'H588',
    'viewValue': 'ROTELLA (AP)'
  },
  {
    'value': 'H589',
    'viewValue': 'ROTELLO (CB)'
  },
  {
    'value': 'H590',
    'viewValue': 'ROTONDA (PZ)'
  },
  {
    'value': 'H591',
    'viewValue': 'ROTONDELLA (MT)'
  },
  {
    'value': 'H592',
    'viewValue': 'ROTONDI (AV)'
  },
  {
    'value': 'H593',
    'viewValue': 'ROTTOFRENO (PC)'
  },
  {
    'value': 'H594',
    'viewValue': 'ROTZO (VI)'
  },
  {
    'value': 'H555',
    'viewValue': 'ROURE (TO)'
  },
  {
    'value': 'H596',
    'viewValue': 'ROVAGNATE (LC)'
  },
  {
    'value': 'H364',
    'viewValue': 'ROVASENDA (VC)'
  },
  {
    'value': 'H598',
    'viewValue': 'ROVATO (BS)'
  },
  {
    'value': 'H599',
    'viewValue': 'ROVEGNO (GE)'
  },
  {
    'value': 'H601',
    'viewValue': 'ROVELLASCA (CO)'
  },
  {
    'value': 'H602',
    'viewValue': 'ROVELLO PORRO (CO)'
  },
  {
    'value': 'H604',
    'viewValue': 'ROVERBELLA (MN)'
  },
  {
    'value': 'H606',
    'viewValue': 'ROVERCHIARA (VR)'
  },
  {
    'value': 'H607',
    'viewValue': "ROVERE' DELLA LUNA (TN)"
  },
  {
    'value': 'H608',
    'viewValue': "ROVERE' VERONESE (VR)"
  },
  {
    'value': 'H610',
    'viewValue': "ROVEREDO DI GUA' (VR)"
  },
  {
    'value': 'H609',
    'viewValue': 'ROVEREDO IN PIANO (PN)'
  },
  {
    'value': 'H612',
    'viewValue': 'ROVERETO (TN)'
  },
  {
    'value': 'H614',
    'viewValue': 'ROVESCALA (PV)'
  },
  {
    'value': 'H615',
    'viewValue': 'ROVETTA (BG)'
  },
  {
    'value': 'H618',
    'viewValue': 'ROVIANO (RM)'
  },
  {
    'value': 'H620',
    'viewValue': 'ROVIGO (RO)'
  },
  {
    'value': 'H621',
    'viewValue': 'ROVITO (CS)'
  },
  {
    'value': 'H622',
    'viewValue': 'ROVOLON (PD)'
  },
  {
    'value': 'H623',
    'viewValue': 'ROZZANO (MI)'
  },
  {
    'value': 'H625',
    'viewValue': 'RUBANO (PD)'
  },
  {
    'value': 'H627',
    'viewValue': 'RUBIANA (TO)'
  },
  {
    'value': 'H628',
    'viewValue': 'RUBIERA (RE)'
  },
  {
    'value': 'H629',
    'viewValue': 'RUDA (UD)'
  },
  {
    'value': 'H630',
    'viewValue': 'RUDIANO (BS)'
  },
  {
    'value': 'H631',
    'viewValue': 'RUEGLIO (TO)'
  },
  {
    'value': 'H632',
    'viewValue': 'RUFFANO (LE)'
  },
  {
    'value': 'H633',
    'viewValue': 'RUFFIA (CN)'
  },
  {
    'value': 'H634',
    'viewValue': "RUFFRE' (TN)"
  },
  {
    'value': 'H635',
    'viewValue': 'RUFINA (FI)'
  },
  {
    'value': 'F271',
    'viewValue': 'RUINAS (OR)'
  },
  {
    'value': 'H637',
    'viewValue': 'RUINO (PV)'
  },
  {
    'value': 'H639',
    'viewValue': 'RUMO (TN)'
  },
  {
    'value': 'H641',
    'viewValue': 'RUOTI (PZ)'
  },
  {
    'value': 'H642',
    'viewValue': 'RUSSI (RA)'
  },
  {
    'value': 'H643',
    'viewValue': 'RUTIGLIANO (BA)'
  },
  {
    'value': 'H644',
    'viewValue': 'RUTINO (SA)'
  },
  {
    'value': 'H165',
    'viewValue': 'RUVIANO (CE)'
  },
  {
    'value': 'H646',
    'viewValue': 'RUVO DEL MONTE (PZ)'
  },
  {
    'value': 'H645',
    'viewValue': 'RUVO DI PUGLIA (BA)'
  },
  {
    'value': 'H647',
    'viewValue': 'SABAUDIA (LT)'
  },
  {
    'value': 'H648',
    'viewValue': 'SABBIA (VC)'
  },
  {
    'value': 'H650',
    'viewValue': 'SABBIO CHIESE (BS)'
  },
  {
    'value': 'H652',
    'viewValue': 'SABBIONETA (MN)'
  },
  {
    'value': 'H654',
    'viewValue': 'SACCO (SA)'
  },
  {
    'value': 'H655',
    'viewValue': 'SACCOLONGO (PD)'
  },
  {
    'value': 'H657',
    'viewValue': 'SACILE (PN)'
  },
  {
    'value': 'H658',
    'viewValue': 'SACROFANO (RM)'
  },
  {
    'value': 'H659',
    'viewValue': 'SADALI (NU)'
  },
  {
    'value': 'H661',
    'viewValue': 'SAGAMA (NU)'
  },
  {
    'value': 'H662',
    'viewValue': 'SAGLIANO MICCA (BI)'
  },
  {
    'value': 'H665',
    'viewValue': 'SAGRADO (GO)'
  },
  {
    'value': 'H666',
    'viewValue': 'SAGRON MIS (TN)'
  },
  {
    'value': 'H669',
    'viewValue': 'SAINT-CHRISTOPHE (AO)'
  },
  {
    'value': 'H670',
    'viewValue': 'SAINT-DENIS (AO)'
  },
  {
    'value': 'H671',
    'viewValue': 'SAINT-MARCEL (AO)'
  },
  {
    'value': 'H672',
    'viewValue': 'SAINT-NICOLAS (AO)'
  },
  {
    'value': 'H673',
    'viewValue': 'SAINT-OYEN (AO)'
  },
  {
    'value': 'H674',
    'viewValue': 'SAINT-PIERRE (AO)'
  },
  {
    'value': 'H675',
    'viewValue': 'SAINT-RHEMY-EN-BOSSES (AO)'
  },
  {
    'value': 'H676',
    'viewValue': 'SAINT-VINCENT (AO)'
  },
  {
    'value': 'H682',
    'viewValue': 'SALA BAGANZA (PR)'
  },
  {
    'value': 'H681',
    'viewValue': 'SALA BIELLESE (BI)'
  },
  {
    'value': 'H678',
    'viewValue': 'SALA BOLOGNESE (BO)'
  },
  {
    'value': 'H679',
    'viewValue': 'SALA COMACINA (CO)'
  },
  {
    'value': 'H683',
    'viewValue': 'SALA CONSILINA (SA)'
  },
  {
    'value': 'H677',
    'viewValue': 'SALA MONFERRATO (AL)'
  },
  {
    'value': 'H687',
    'viewValue': 'SALANDRA (MT)'
  },
  {
    'value': 'H688',
    'viewValue': 'SALAPARUTA (TP)'
  },
  {
    'value': 'H689',
    'viewValue': 'SALARA (RO)'
  },
  {
    'value': 'H690',
    'viewValue': 'SALASCO (VC)'
  },
  {
    'value': 'H691',
    'viewValue': 'SALASSA (TO)'
  },
  {
    'value': 'H684',
    'viewValue': 'SALBERTRAND (TO)'
  },
  {
    'value': 'F810',
    'viewValue': 'SALCEDO (VI)'
  },
  {
    'value': 'H693',
    'viewValue': 'SALCITO (CB)'
  },
  {
    'value': 'H694',
    'viewValue': 'SALE (AL)'
  },
  {
    'value': 'H695',
    'viewValue': 'SALE DELLE LANGHE (CN)'
  },
  {
    'value': 'H699',
    'viewValue': 'SALE MARASINO (BS)'
  },
  {
    'value': 'H704',
    'viewValue': 'SALE SAN GIOVANNI (CN)'
  },
  {
    'value': 'H700',
    'viewValue': 'SALEMI (TP)'
  },
  {
    'value': 'H686',
    'viewValue': 'SALENTO (SA)'
  },
  {
    'value': 'H702',
    'viewValue': 'SALERANO CANAVESE (TO)'
  },
  {
    'value': 'H701',
    'viewValue': 'SALERANO SUL LAMBRO (LO)'
  },
  {
    'value': 'H703',
    'viewValue': 'SALERNO (SA)'
  },
  {
    'value': 'H705',
    'viewValue': 'SALETTO (PD)'
  },
  {
    'value': 'H706',
    'viewValue': 'SALGAREDA (TV)'
  },
  {
    'value': 'H707',
    'viewValue': 'SALI VERCELLESE (VC)'
  },
  {
    'value': 'H708',
    'viewValue': 'SALICE SALENTINO (LE)'
  },
  {
    'value': 'H710',
    'viewValue': 'SALICETO (CN)'
  },
  {
    'value': 'H713',
    'viewValue': 'SALISANO (RI)'
  },
  {
    'value': 'H714',
    'viewValue': 'SALIZZOLE (VR)'
  },
  {
    'value': 'H715',
    'viewValue': 'SALLE (PE)'
  },
  {
    'value': 'H716',
    'viewValue': 'SALMOUR (CN)'
  },
  {
    'value': 'H717',
    'viewValue': "SALO' (BS)"
  },
  {
    'value': 'H719',
    'viewValue': 'SALORNO .SALURN. (BZ)'
  },
  {
    'value': 'H720',
    'viewValue': 'SALSOMAGGIORE TERME (PR)'
  },
  {
    'value': 'H721',
    'viewValue': 'SALTARA (PU)'
  },
  {
    'value': 'H723',
    'viewValue': 'SALTRIO (VA)'
  },
  {
    'value': 'H724',
    'viewValue': 'SALUDECIO (RN)'
  },
  {
    'value': 'H725',
    'viewValue': 'SALUGGIA (VC)'
  },
  {
    'value': 'H726',
    'viewValue': 'SALUSSOLA (BI)'
  },
  {
    'value': 'H727',
    'viewValue': 'SALUZZO (CN)'
  },
  {
    'value': 'H729',
    'viewValue': 'SALVE (LE)'
  },
  {
    'value': 'H731',
    'viewValue': 'SALVIROLA (CR)'
  },
  {
    'value': 'H732',
    'viewValue': 'SALVITELLE (SA)'
  },
  {
    'value': 'H734',
    'viewValue': 'SALZA DI PINEROLO (TO)'
  },
  {
    'value': 'H733',
    'viewValue': 'SALZA IRPINA (AV)'
  },
  {
    'value': 'H735',
    'viewValue': 'SALZANO (VE)'
  },
  {
    'value': 'H736',
    'viewValue': 'SAMARATE (VA)'
  },
  {
    'value': 'H738',
    'viewValue': 'SAMASSI (CA)'
  },
  {
    'value': 'H739',
    'viewValue': 'SAMATZAI (CA)'
  },
  {
    'value': 'M208',
    'viewValue': 'SAMBIASE (CZ)'
  },
  {
    'value': 'H743',
    'viewValue': 'SAMBUCA DI SICILIA (AG)'
  },
  {
    'value': 'H744',
    'viewValue': 'SAMBUCA PISTOIESE (PT)'
  },
  {
    'value': 'H745',
    'viewValue': 'SAMBUCI (RM)'
  },
  {
    'value': 'H746',
    'viewValue': 'SAMBUCO (CN)'
  },
  {
    'value': 'H749',
    'viewValue': 'SAMMICHELE DI BARI (BA)'
  },
  {
    'value': 'H013',
    'viewValue': 'SAMO (RC)'
  },
  {
    'value': 'H752',
    'viewValue': 'SAMOLACO (SO)'
  },
  {
    'value': 'H753',
    'viewValue': 'SAMONE (TO)'
  },
  {
    'value': 'H754',
    'viewValue': 'SAMONE (TN)'
  },
  {
    'value': 'H755',
    'viewValue': 'SAMPEYRE (CN)'
  },
  {
    'value': 'H756',
    'viewValue': 'SAMUGHEO (OR)'
  },
  {
    'value': 'H763',
    'viewValue': 'SAN BARTOLOMEO AL MARE (IM)'
  },
  {
    'value': 'H764',
    'viewValue': 'SAN BARTOLOMEO IN GALDO (BN)'
  },
  {
    'value': 'H760',
    'viewValue': 'SAN BARTOLOMEO VAL CAVARGNA (CO)'
  },
  {
    'value': 'H765',
    'viewValue': 'SAN BASILE (CS)'
  },
  {
    'value': 'H766',
    'viewValue': 'SAN BASILIO (CA)'
  },
  {
    'value': 'H767',
    'viewValue': 'SAN BASSANO (CR)'
  },
  {
    'value': 'H768',
    'viewValue': 'SAN BELLINO (RO)'
  },
  {
    'value': 'H770',
    'viewValue': 'SAN BENEDETTO BELBO (CN)'
  },
  {
    'value': 'H772',
    'viewValue': 'SAN BENEDETTO DEI MARSI (AQ)'
  },
  {
    'value': 'H769',
    'viewValue': 'SAN BENEDETTO DEL TRONTO (AP)'
  },
  {
    'value': 'H773',
    'viewValue': 'SAN BENEDETTO IN PERILLIS (AQ)'
  },
  {
    'value': 'H771',
    'viewValue': 'SAN BENEDETTO PO (MN)'
  },
  {
    'value': 'H774',
    'viewValue': 'SAN BENEDETTO ULLANO (CS)'
  },
  {
    'value': 'G566',
    'viewValue': 'SAN BENEDETTO VAL DI SAMBRO (BO)'
  },
  {
    'value': 'H775',
    'viewValue': 'SAN BENIGNO CANAVESE (TO)'
  },
  {
    'value': 'H777',
    'viewValue': 'SAN BERNARDINO VERBANO (VB)'
  },
  {
    'value': 'H780',
    'viewValue': 'SAN BIAGIO DELLA CIMA (IM)'
  },
  {
    'value': 'H781',
    'viewValue': 'SAN BIAGIO DI CALLALTA (TV)'
  },
  {
    'value': 'H778',
    'viewValue': 'SAN BIAGIO PLATANI (AG)'
  },
  {
    'value': 'H779',
    'viewValue': 'SAN BIAGIO SARACINISCO (FR)'
  },
  {
    'value': 'H782',
    'viewValue': 'SAN BIASE (CB)'
  },
  {
    'value': 'H783',
    'viewValue': 'SAN BONIFACIO (VR)'
  },
  {
    'value': 'H784',
    'viewValue': 'SAN BUONO (CH)'
  },
  {
    'value': 'H785',
    'viewValue': 'SAN CALOGERO (VV)'
  },
  {
    'value': 'H786',
    'viewValue': 'SAN CANDIDO .INNICHEN. (BZ)'
  },
  {
    'value': 'H787',
    'viewValue': "SAN CANZIAN D'ISONZO (GO)"
  },
  {
    'value': 'H789',
    'viewValue': 'SAN CARLO CANAVESE (TO)'
  },
  {
    'value': 'H790',
    'viewValue': 'SAN CASCIANO DEI BAGNI (SI)'
  },
  {
    'value': 'H791',
    'viewValue': 'SAN CASCIANO IN VAL DI PESA (FI)'
  },
  {
    'value': 'M264',
    'viewValue': 'SAN CASSIANO (LE)'
  },
  {
    'value': 'H792',
    'viewValue': 'SAN CATALDO (CL)'
  },
  {
    'value': 'M295',
    'viewValue': 'SAN CESAREO (RM)'
  },
  {
    'value': 'H793',
    'viewValue': 'SAN CESARIO DI LECCE (LE)'
  },
  {
    'value': 'H794',
    'viewValue': 'SAN CESARIO SUL PANARO (MO)'
  },
  {
    'value': 'H795',
    'viewValue': 'SAN CHIRICO NUOVO (PZ)'
  },
  {
    'value': 'H796',
    'viewValue': 'SAN CHIRICO RAPARO (PZ)'
  },
  {
    'value': 'H797',
    'viewValue': 'SAN CIPIRELLO (PA)'
  },
  {
    'value': 'H798',
    'viewValue': "SAN CIPRIANO D'AVERSA (CE)"
  },
  {
    'value': 'H800',
    'viewValue': 'SAN CIPRIANO PICENTINO (SA)'
  },
  {
    'value': 'H799',
    'viewValue': 'SAN CIPRIANO PO (PV)'
  },
  {
    'value': 'H801',
    'viewValue': 'SAN CLEMENTE (RN)'
  },
  {
    'value': 'H803',
    'viewValue': 'SAN COLOMBANO AL LAMBRO (MI)'
  },
  {
    'value': 'H804',
    'viewValue': 'SAN COLOMBANO BELMONTE (TO)'
  },
  {
    'value': 'H802',
    'viewValue': 'SAN COLOMBANO CERTENOLI (GE)'
  },
  {
    'value': 'H805',
    'viewValue': 'SAN CONO (CT)'
  },
  {
    'value': 'H806',
    'viewValue': 'SAN COSMO ALBANESE (CS)'
  },
  {
    'value': 'H808',
    'viewValue': 'SAN COSTANTINO ALBANESE (PZ)'
  },
  {
    'value': 'H807',
    'viewValue': 'SAN COSTANTINO CALABRO (VV)'
  },
  {
    'value': 'H809',
    'viewValue': 'SAN COSTANZO (PU)'
  },
  {
    'value': 'H810',
    'viewValue': 'SAN CRISTOFORO (AL)'
  },
  {
    'value': 'H814',
    'viewValue': 'SAN DAMIANO AL COLLE (PV)'
  },
  {
    'value': 'H811',
    'viewValue': "SAN DAMIANO D'ASTI (AT)"
  },
  {
    'value': 'H812',
    'viewValue': 'SAN DAMIANO MACRA (CN)'
  },
  {
    'value': 'H816',
    'viewValue': 'SAN DANIELE DEL FRIULI (UD)'
  },
  {
    'value': 'H815',
    'viewValue': 'SAN DANIELE PO (CR)'
  },
  {
    'value': 'H818',
    'viewValue': 'SAN DEMETRIO CORONE (CS)'
  },
  {
    'value': 'H819',
    'viewValue': "SAN DEMETRIO NE' VESTINI (AQ)"
  },
  {
    'value': 'H820',
    'viewValue': 'SAN DIDERO (TO)'
  },
  {
    'value': 'H823',
    'viewValue': "SAN DONA' DI PIAVE (VE)"
  },
  {
    'value': 'H822',
    'viewValue': 'SAN DONACI (BR)'
  },
  {
    'value': 'H826',
    'viewValue': 'SAN DONATO DI LECCE (LE)'
  },
  {
    'value': 'H825',
    'viewValue': 'SAN DONATO DI NINEA (CS)'
  },
  {
    'value': 'H827',
    'viewValue': 'SAN DONATO MILANESE (MI)'
  },
  {
    'value': 'H824',
    'viewValue': 'SAN DONATO VAL DI COMINO (FR)'
  },
  {
    'value': 'D324',
    'viewValue': 'SAN DORLIGO DELLA VALLE (TS)'
  },
  {
    'value': 'H830',
    'viewValue': 'SAN FEDELE INTELVI (CO)'
  },
  {
    'value': 'H831',
    'viewValue': 'SAN FELE (PZ)'
  },
  {
    'value': 'H834',
    'viewValue': 'SAN FELICE A CANCELLO (CE)'
  },
  {
    'value': 'H836',
    'viewValue': 'SAN FELICE CIRCEO (LT)'
  },
  {
    'value': 'H838',
    'viewValue': 'SAN FELICE DEL BENACO (BS)'
  },
  {
    'value': 'H833',
    'viewValue': 'SAN FELICE DEL MOLISE (CB)'
  },
  {
    'value': 'H835',
    'viewValue': 'SAN FELICE SUL PANARO (MO)'
  },
  {
    'value': 'M277',
    'viewValue': 'SAN FERDINANDO (RC)'
  },
  {
    'value': 'H839',
    'viewValue': 'SAN FERDINANDO DI PUGLIA (FG)'
  },
  {
    'value': 'H840',
    'viewValue': 'SAN FERMO DELLA BATTAGLIA (CO)'
  },
  {
    'value': 'H841',
    'viewValue': 'SAN FILI (CS)'
  },
  {
    'value': 'H842',
    'viewValue': 'SAN FILIPPO DEL MELA (ME)'
  },
  {
    'value': 'H843',
    'viewValue': 'SAN FIOR (TV)'
  },
  {
    'value': 'H844',
    'viewValue': 'SAN FIORANO (LO)'
  },
  {
    'value': 'H845',
    'viewValue': 'SAN FLORIANO DEL COLLIO (GO)'
  },
  {
    'value': 'H846',
    'viewValue': 'SAN FLORO (CZ)'
  },
  {
    'value': 'H847',
    'viewValue': 'SAN FRANCESCO AL CAMPO (TO)'
  },
  {
    'value': 'H850',
    'viewValue': 'SAN FRATELLO (ME)'
  },
  {
    'value': 'H856',
    'viewValue': 'SAN GAVINO MONREALE (CA)'
  },
  {
    'value': 'H857',
    'viewValue': 'SAN GEMINI (TR)'
  },
  {
    'value': 'H858',
    'viewValue': 'SAN GENESIO ATESINO .JENESIEN. (BZ)'
  },
  {
    'value': 'H859',
    'viewValue': 'SAN GENESIO ED UNITI (PV)'
  },
  {
    'value': 'H860',
    'viewValue': 'SAN GENNARO VESUVIANO (NA)'
  },
  {
    'value': 'H862',
    'viewValue': 'SAN GERMANO CHISONE (TO)'
  },
  {
    'value': 'H863',
    'viewValue': 'SAN GERMANO DEI BERICI (VI)'
  },
  {
    'value': 'H861',
    'viewValue': 'SAN GERMANO VERCELLESE (VC)'
  },
  {
    'value': 'H865',
    'viewValue': 'SAN GERVASIO BRESCIANO (BS)'
  },
  {
    'value': 'H867',
    'viewValue': 'SAN GIACOMO DEGLI SCHIAVONI (CB)'
  },
  {
    'value': 'H870',
    'viewValue': 'SAN GIACOMO DELLE SEGNATE (MN)'
  },
  {
    'value': 'H868',
    'viewValue': 'SAN GIACOMO FILIPPO (SO)'
  },
  {
    'value': 'B952',
    'viewValue': 'SAN GIACOMO VERCELLESE (VC)'
  },
  {
    'value': 'H873',
    'viewValue': 'SAN GILLIO (TO)'
  },
  {
    'value': 'H875',
    'viewValue': 'SAN GIMIGNANO (SI)'
  },
  {
    'value': 'H876',
    'viewValue': 'SAN GINESIO (MC)'
  },
  {
    'value': 'H892',
    'viewValue': 'SAN GIORGIO A CREMANO (NA)'
  },
  {
    'value': 'H880',
    'viewValue': 'SAN GIORGIO A LIRI (FR)'
  },
  {
    'value': 'H881',
    'viewValue': 'SAN GIORGIO ALBANESE (CS)'
  },
  {
    'value': 'H890',
    'viewValue': 'SAN GIORGIO CANAVESE (TO)'
  },
  {
    'value': 'H894',
    'viewValue': 'SAN GIORGIO DEL SANNIO (BN)'
  },
  {
    'value': 'H891',
    'viewValue': 'SAN GIORGIO DELLA RICHINVELDA (PN)'
  },
  {
    'value': 'H893',
    'viewValue': 'SAN GIORGIO DELLE PERTICHE (PD)'
  },
  {
    'value': 'H885',
    'viewValue': 'SAN GIORGIO DI LOMELLINA (PV)'
  },
  {
    'value': 'H883',
    'viewValue': 'SAN GIORGIO DI MANTOVA (MN)'
  },
  {
    'value': 'H895',
    'viewValue': 'SAN GIORGIO DI NOGARO (UD)'
  },
  {
    'value': 'H886',
    'viewValue': 'SAN GIORGIO DI PESARO (PU)'
  },
  {
    'value': 'H896',
    'viewValue': 'SAN GIORGIO DI PIANO (BO)'
  },
  {
    'value': 'H897',
    'viewValue': 'SAN GIORGIO IN BOSCO (PD)'
  },
  {
    'value': 'H882',
    'viewValue': 'SAN GIORGIO IONICO (TA)'
  },
  {
    'value': 'H898',
    'viewValue': 'SAN GIORGIO LA MOLARA (BN)'
  },
  {
    'value': 'H888',
    'viewValue': 'SAN GIORGIO LUCANO (MT)'
  },
  {
    'value': 'H878',
    'viewValue': 'SAN GIORGIO MONFERRATO (AL)'
  },
  {
    'value': 'H889',
    'viewValue': 'SAN GIORGIO MORGETO (RC)'
  },
  {
    'value': 'H887',
    'viewValue': 'SAN GIORGIO PIACENTINO (PC)'
  },
  {
    'value': 'H899',
    'viewValue': 'SAN GIORGIO SCARAMPI (AT)'
  },
  {
    'value': 'H884',
    'viewValue': 'SAN GIORGIO SU LEGNANO (MI)'
  },
  {
    'value': 'H900',
    'viewValue': 'SAN GIORIO DI SUSA (TO)'
  },
  {
    'value': 'H907',
    'viewValue': 'SAN GIOVANNI A PIRO (SA)'
  },
  {
    'value': 'H906',
    'viewValue': 'SAN GIOVANNI AL NATISONE (UD)'
  },
  {
    'value': 'H910',
    'viewValue': 'SAN GIOVANNI BIANCO (BG)'
  },
  {
    'value': 'H911',
    'viewValue': "SAN GIOVANNI D'ASSO (SI)"
  },
  {
    'value': 'H912',
    'viewValue': 'SAN GIOVANNI DEL DOSSO (MN)'
  },
  {
    'value': 'H903',
    'viewValue': 'SAN GIOVANNI DI GERACE (RC)'
  },
  {
    'value': 'H914',
    'viewValue': 'SAN GIOVANNI GEMINI (AG)'
  },
  {
    'value': 'H916',
    'viewValue': 'SAN GIOVANNI ILARIONE (VR)'
  },
  {
    'value': 'H918',
    'viewValue': 'SAN GIOVANNI IN CROCE (CR)'
  },
  {
    'value': 'H919',
    'viewValue': 'SAN GIOVANNI IN FIORE (CS)'
  },
  {
    'value': 'H920',
    'viewValue': 'SAN GIOVANNI IN GALDO (CB)'
  },
  {
    'value': 'H921',
    'viewValue': 'SAN GIOVANNI IN MARIGNANO (RN)'
  },
  {
    'value': 'G467',
    'viewValue': 'SAN GIOVANNI IN PERSICETO (BO)'
  },
  {
    'value': 'H917',
    'viewValue': 'SAN GIOVANNI INCARICO (FR)'
  },
  {
    'value': 'H922',
    'viewValue': 'SAN GIOVANNI LA PUNTA (CT)'
  },
  {
    'value': 'H923',
    'viewValue': 'SAN GIOVANNI LIPIONI (CH)'
  },
  {
    'value': 'H924',
    'viewValue': 'SAN GIOVANNI LUPATOTO (VR)'
  },
  {
    'value': 'H926',
    'viewValue': 'SAN GIOVANNI ROTONDO (FG)'
  },
  {
    'value': 'G287',
    'viewValue': 'SAN GIOVANNI SUERGIU (CA)'
  },
  {
    'value': 'D690',
    'viewValue': 'SAN GIOVANNI TEATINO (CH)'
  },
  {
    'value': 'H901',
    'viewValue': 'SAN GIOVANNI VALDARNO (AR)'
  },
  {
    'value': 'H928',
    'viewValue': 'SAN GIULIANO DEL SANNIO (CB)'
  },
  {
    'value': 'H929',
    'viewValue': 'SAN GIULIANO DI PUGLIA (CB)'
  },
  {
    'value': 'H930',
    'viewValue': 'SAN GIULIANO MILANESE (MI)'
  },
  {
    'value': 'A562',
    'viewValue': 'SAN GIULIANO TERME (PI)'
  },
  {
    'value': 'H933',
    'viewValue': 'SAN GIUSEPPE JATO (PA)'
  },
  {
    'value': 'H931',
    'viewValue': 'SAN GIUSEPPE VESUVIANO (NA)'
  },
  {
    'value': 'H935',
    'viewValue': 'SAN GIUSTINO (PG)'
  },
  {
    'value': 'H936',
    'viewValue': 'SAN GIUSTO CANAVESE (TO)'
  },
  {
    'value': 'H937',
    'viewValue': 'SAN GODENZO (FI)'
  },
  {
    'value': 'H942',
    'viewValue': 'SAN GREGORIO DA SASSOLA (RM)'
  },
  {
    'value': 'H940',
    'viewValue': 'SAN GREGORIO DI CATANIA (CT)'
  },
  {
    'value': 'H941',
    'viewValue': "SAN GREGORIO D'IPPONA (VV)"
  },
  {
    'value': 'H943',
    'viewValue': 'SAN GREGORIO MAGNO (SA)'
  },
  {
    'value': 'H939',
    'viewValue': 'SAN GREGORIO MATESE (CE)'
  },
  {
    'value': 'H938',
    'viewValue': 'SAN GREGORIO NELLE ALPI (BL)'
  },
  {
    'value': 'H945',
    'viewValue': 'SAN LAZZARO DI SAVENA (BO)'
  },
  {
    'value': 'H949',
    'viewValue': 'SAN LEO (PU)'
  },
  {
    'value': 'H951',
    'viewValue': 'SAN LEONARDO (UD)'
  },
  {
    'value': 'H952',
    'viewValue': 'SAN LEONARDO IN PASSIRIA .ST LEONHARD IN PAS. (BZ)'
  },
  {
    'value': 'H953',
    'viewValue': 'SAN LEUCIO DEL SANNIO (BN)'
  },
  {
    'value': 'H955',
    'viewValue': 'SAN LORENZELLO (BN)'
  },
  {
    'value': 'H959',
    'viewValue': 'SAN LORENZO (RC)'
  },
  {
    'value': 'H957',
    'viewValue': 'SAN LORENZO AL MARE (IM)'
  },
  {
    'value': 'H961',
    'viewValue': 'SAN LORENZO BELLIZZI (CS)'
  },
  {
    'value': 'H962',
    'viewValue': 'SAN LORENZO DEL VALLO (CS)'
  },
  {
    'value': 'H956',
    'viewValue': 'SAN LORENZO DI SEBATO .ST LORENZEN. (BZ)'
  },
  {
    'value': 'H966',
    'viewValue': 'SAN LORENZO IN BANALE (TN)'
  },
  {
    'value': 'H958',
    'viewValue': 'SAN LORENZO IN CAMPO (PU)'
  },
  {
    'value': 'H964',
    'viewValue': 'SAN LORENZO ISONTINO (GO)'
  },
  {
    'value': 'H967',
    'viewValue': 'SAN LORENZO MAGGIORE (BN)'
  },
  {
    'value': 'H969',
    'viewValue': 'SAN LORENZO NUOVO (VT)'
  },
  {
    'value': 'H970',
    'viewValue': 'SAN LUCA (RC)'
  },
  {
    'value': 'H971',
    'viewValue': 'SAN LUCIDO (CS)'
  },
  {
    'value': 'H973',
    'viewValue': 'SAN LUPO (BN)'
  },
  {
    'value': 'H976',
    'viewValue': "SAN MANGO D'AQUINO (CZ)"
  },
  {
    'value': 'H977',
    'viewValue': 'SAN MANGO PIEMONTE (SA)'
  },
  {
    'value': 'H975',
    'viewValue': 'SAN MANGO SUL CALORE (AV)'
  },
  {
    'value': 'H978',
    'viewValue': 'SAN MARCELLINO (CE)'
  },
  {
    'value': 'H979',
    'viewValue': 'SAN MARCELLO (AN)'
  },
  {
    'value': 'H980',
    'viewValue': 'SAN MARCELLO PISTOIESE (PT)'
  },
  {
    'value': 'H981',
    'viewValue': 'SAN MARCO ARGENTANO (CS)'
  },
  {
    'value': 'H982',
    'viewValue': "SAN MARCO D'ALUNZIO (ME)"
  },
  {
    'value': 'H984',
    'viewValue': 'SAN MARCO DEI CAVOTI (BN)'
  },
  {
    'value': 'F043',
    'viewValue': 'SAN MARCO EVANGELISTA (CE)'
  },
  {
    'value': 'H985',
    'viewValue': 'SAN MARCO IN LAMIS (FG)'
  },
  {
    'value': 'H986',
    'viewValue': 'SAN MARCO LA CATOLA (FG)'
  },
  {
    'value': 'H999',
    'viewValue': 'SAN MARTINO AL TAGLIAMENTO (PN)'
  },
  {
    'value': 'H987',
    'viewValue': 'SAN MARTINO ALFIERI (AT)'
  },
  {
    'value': 'I003',
    'viewValue': 'SAN MARTINO BUON ALBERGO (VR)'
  },
  {
    'value': 'H997',
    'viewValue': 'SAN MARTINO CANAVESE (TO)'
  },
  {
    'value': 'H994',
    'viewValue': "SAN MARTINO D'AGRI (PZ)"
  },
  {
    'value': 'I005',
    'viewValue': "SAN MARTINO DALL'ARGINE (MN)"
  },
  {
    'value': 'I007',
    'viewValue': 'SAN MARTINO DEL LAGO (CR)'
  },
  {
    'value': 'H992',
    'viewValue': 'SAN MARTINO DI FINITA (CS)'
  },
  {
    'value': 'I008',
    'viewValue': 'SAN MARTINO DI LUPARI (PD)'
  },
  {
    'value': 'H996',
    'viewValue': 'SAN MARTINO DI VENEZZE (RO)'
  },
  {
    'value': 'H988',
    'viewValue': 'SAN MARTINO IN BADIA .ST MARTIN IN THURN. (BZ)'
  },
  {
    'value': 'H989',
    'viewValue': 'SAN MARTINO IN PASSIRIA .ST MARTIN IN PASSEI. (BZ)'
  },
  {
    'value': 'H990',
    'viewValue': 'SAN MARTINO IN PENSILIS (CB)'
  },
  {
    'value': 'I011',
    'viewValue': 'SAN MARTINO IN RIO (RE)'
  },
  {
    'value': 'I012',
    'viewValue': 'SAN MARTINO IN STRADA (LO)'
  },
  {
    'value': 'I002',
    'viewValue': 'SAN MARTINO SANNITA (BN)'
  },
  {
    'value': 'I014',
    'viewValue': 'SAN MARTINO SICCOMARIO (PV)'
  },
  {
    'value': 'H991',
    'viewValue': 'SAN MARTINO SULLA MARRUCINA (CH)'
  },
  {
    'value': 'I016',
    'viewValue': 'SAN MARTINO VALLE CAUDINA (AV)'
  },
  {
    'value': 'I018',
    'viewValue': 'SAN MARZANO DI SAN GIUSEPPE (TA)'
  },
  {
    'value': 'I017',
    'viewValue': 'SAN MARZANO OLIVETO (AT)'
  },
  {
    'value': 'I019',
    'viewValue': 'SAN MARZANO SUL SARNO (SA)'
  },
  {
    'value': 'I023',
    'viewValue': 'SAN MASSIMO (CB)'
  },
  {
    'value': 'I024',
    'viewValue': 'SAN MAURIZIO CANAVESE (TO)'
  },
  {
    'value': 'I025',
    'viewValue': "SAN MAURIZIO D'OPAGLIO (NO)"
  },
  {
    'value': 'I028',
    'viewValue': 'SAN MAURO CASTELVERDE (PA)'
  },
  {
    'value': 'I031',
    'viewValue': 'SAN MAURO CILENTO (SA)'
  },
  {
    'value': 'H712',
    'viewValue': 'SAN MAURO DI SALINE (VR)'
  },
  {
    'value': 'I029',
    'viewValue': 'SAN MAURO FORTE (MT)'
  },
  {
    'value': 'I032',
    'viewValue': 'SAN MAURO LA BRUCA (SA)'
  },
  {
    'value': 'I026',
    'viewValue': 'SAN MAURO MARCHESATO (KR)'
  },
  {
    'value': 'I027',
    'viewValue': 'SAN MAURO PASCOLI (FC)'
  },
  {
    'value': 'I030',
    'viewValue': 'SAN MAURO TORINESE (TO)'
  },
  {
    'value': 'I040',
    'viewValue': 'SAN MICHELE AL TAGLIAMENTO (VE)'
  },
  {
    'value': 'I042',
    'viewValue': "SAN MICHELE ALL'ADIGE (TN)"
  },
  {
    'value': 'I035',
    'viewValue': 'SAN MICHELE DI GANZARIA (CT)'
  },
  {
    'value': 'I034',
    'viewValue': 'SAN MICHELE DI SERINO (AV)'
  },
  {
    'value': 'I037',
    'viewValue': "SAN MICHELE MONDOVI' (CN)"
  },
  {
    'value': 'I045',
    'viewValue': 'SAN MICHELE SALENTINO (BR)'
  },
  {
    'value': 'I046',
    'viewValue': 'SAN MINIATO (PI)'
  },
  {
    'value': 'I047',
    'viewValue': 'SAN NAZARIO (VI)'
  },
  {
    'value': 'I049',
    'viewValue': 'SAN NAZZARO (BN)'
  },
  {
    'value': 'I052',
    'viewValue': 'SAN NAZZARO SESIA (NO)'
  },
  {
    'value': 'I051',
    'viewValue': 'SAN NAZZARO VAL CAVARGNA (CO)'
  },
  {
    'value': 'I060',
    'viewValue': 'SAN NICOLA ARCELLA (CS)'
  },
  {
    'value': 'I061',
    'viewValue': 'SAN NICOLA BARONIA (AV)'
  },
  {
    'value': 'I058',
    'viewValue': 'SAN NICOLA DA CRISSA (VV)'
  },
  {
    'value': 'I057',
    'viewValue': "SAN NICOLA DELL'ALTO (KR)"
  },
  {
    'value': 'I056',
    'viewValue': 'SAN NICOLA LA STRADA (CE)'
  },
  {
    'value': 'I062',
    'viewValue': 'SAN NICOLA MANFREDI (BN)'
  },
  {
    'value': 'A368',
    'viewValue': "SAN NICOLO' D'ARCIDANO (OR)"
  },
  {
    'value': 'I063',
    'viewValue': "SAN NICOLO' DI COMELICO (BL)"
  },
  {
    'value': 'G383',
    'viewValue': "SAN NICOLO' GERREI (CA)"
  },
  {
    'value': 'I065',
    'viewValue': 'SAN PANCRAZIO .ST PANKRAZ. (BZ)'
  },
  {
    'value': 'I066',
    'viewValue': 'SAN PANCRAZIO SALENTINO (BR)'
  },
  {
    'value': 'G407',
    'viewValue': 'SAN PAOLO (BS)'
  },
  {
    'value': 'B906',
    'viewValue': 'SAN PAOLO ALBANESE (PZ)'
  },
  {
    'value': 'I073',
    'viewValue': 'SAN PAOLO BEL SITO (NA)'
  },
  {
    'value': 'I074',
    'viewValue': 'SAN PAOLO CERVO (BI)'
  },
  {
    'value': 'B310',
    'viewValue': "SAN PAOLO D'ARGON (BG)"
  },
  {
    'value': 'I072',
    'viewValue': 'SAN PAOLO DI CIVITATE (FG)'
  },
  {
    'value': 'I071',
    'viewValue': 'SAN PAOLO DI JESI (AN)'
  },
  {
    'value': 'I076',
    'viewValue': 'SAN PAOLO SOLBRITO (AT)'
  },
  {
    'value': 'I079',
    'viewValue': 'SAN PELLEGRINO TERME (BG)'
  },
  {
    'value': 'I082',
    'viewValue': "SAN PIER D'ISONZO (GO)"
  },
  {
    'value': 'I084',
    'viewValue': 'SAN PIER NICETO (ME)'
  },
  {
    'value': 'I085',
    'viewValue': 'SAN PIERO A SIEVE (FI)'
  },
  {
    'value': 'I086',
    'viewValue': 'SAN PIERO PATTI (ME)'
  },
  {
    'value': 'I093',
    'viewValue': 'SAN PIETRO A MAIDA (CZ)'
  },
  {
    'value': 'I092',
    'viewValue': 'SAN PIETRO AL NATISONE (UD)'
  },
  {
    'value': 'I089',
    'viewValue': 'SAN PIETRO AL TANAGRO (SA)'
  },
  {
    'value': 'I095',
    'viewValue': 'SAN PIETRO APOSTOLO (CZ)'
  },
  {
    'value': 'I096',
    'viewValue': 'SAN PIETRO AVELLANA (IS)'
  },
  {
    'value': 'I098',
    'viewValue': 'SAN PIETRO CLARENZA (CT)'
  },
  {
    'value': 'I088',
    'viewValue': 'SAN PIETRO DI CADORE (BL)'
  },
  {
    'value': 'I102',
    'viewValue': "SAN PIETRO DI CARIDA' (RC)"
  },
  {
    'value': 'I103',
    'viewValue': 'SAN PIETRO DI FELETTO (TV)'
  },
  {
    'value': 'I105',
    'viewValue': 'SAN PIETRO DI MORUBIO (VR)'
  },
  {
    'value': 'I108',
    'viewValue': 'SAN PIETRO IN AMANTEA (CS)'
  },
  {
    'value': 'I109',
    'viewValue': 'SAN PIETRO IN CARIANO (VR)'
  },
  {
    'value': 'I110',
    'viewValue': 'SAN PIETRO IN CASALE (BO)'
  },
  {
    'value': 'G788',
    'viewValue': 'SAN PIETRO IN CERRO (PC)'
  },
  {
    'value': 'I107',
    'viewValue': 'SAN PIETRO IN GU (PD)'
  },
  {
    'value': 'I114',
    'viewValue': 'SAN PIETRO IN GUARANO (CS)'
  },
  {
    'value': 'I115',
    'viewValue': 'SAN PIETRO IN LAMA (LE)'
  },
  {
    'value': 'I113',
    'viewValue': 'SAN PIETRO INFINE (CE)'
  },
  {
    'value': 'I116',
    'viewValue': 'SAN PIETRO MOSEZZO (NO)'
  },
  {
    'value': 'I117',
    'viewValue': 'SAN PIETRO MUSSOLINO (VI)'
  },
  {
    'value': 'I090',
    'viewValue': 'SAN PIETRO VAL LEMINA (TO)'
  },
  {
    'value': 'I119',
    'viewValue': 'SAN PIETRO VERNOTICO (BR)'
  },
  {
    'value': 'I120',
    'viewValue': 'SAN PIETRO VIMINARIO (PD)'
  },
  {
    'value': 'I121',
    'viewValue': 'SAN PIO DELLE CAMERE (AQ)'
  },
  {
    'value': 'I125',
    'viewValue': 'SAN POLO DEI CAVALIERI (RM)'
  },
  {
    'value': 'I123',
    'viewValue': "SAN POLO D'ENZA (RE)"
  },
  {
    'value': 'I124',
    'viewValue': 'SAN POLO DI PIAVE (TV)'
  },
  {
    'value': 'I122',
    'viewValue': 'SAN POLO MATESE (CB)'
  },
  {
    'value': 'I126',
    'viewValue': 'SAN PONSO (TO)'
  },
  {
    'value': 'I128',
    'viewValue': 'SAN POSSIDONIO (MO)'
  },
  {
    'value': 'I130',
    'viewValue': 'SAN POTITO SANNITICO (CE)'
  },
  {
    'value': 'I129',
    'viewValue': 'SAN POTITO ULTRA (AV)'
  },
  {
    'value': 'I131',
    'viewValue': 'SAN PRISCO (CE)'
  },
  {
    'value': 'I132',
    'viewValue': 'SAN PROCOPIO (RC)'
  },
  {
    'value': 'I133',
    'viewValue': 'SAN PROSPERO (MO)'
  },
  {
    'value': 'I135',
    'viewValue': "SAN QUIRICO D'ORCIA (SI)"
  },
  {
    'value': 'I136',
    'viewValue': 'SAN QUIRINO (PN)'
  },
  {
    'value': 'I137',
    'viewValue': 'SAN RAFFAELE CIMENA (TO)'
  },
  {
    'value': 'I138',
    'viewValue': 'SAN REMO (IM)'
  },
  {
    'value': 'I139',
    'viewValue': 'SAN ROBERTO (RC)'
  },
  {
    'value': 'I140',
    'viewValue': 'SAN ROCCO AL PORTO (LO)'
  },
  {
    'value': 'I142',
    'viewValue': 'SAN ROMANO IN GARFAGNANA (LU)'
  },
  {
    'value': 'I143',
    'viewValue': 'SAN RUFO (SA)'
  },
  {
    'value': 'I147',
    'viewValue': 'SAN SALVATORE DI FITALIA (ME)'
  },
  {
    'value': 'I144',
    'viewValue': 'SAN SALVATORE MONFERRATO (AL)'
  },
  {
    'value': 'I145',
    'viewValue': 'SAN SALVATORE TELESINO (BN)'
  },
  {
    'value': 'I148',
    'viewValue': 'SAN SALVO (CH)'
  },
  {
    'value': 'I151',
    'viewValue': 'SAN SEBASTIANO AL VESUVIO (NA)'
  },
  {
    'value': 'I150',
    'viewValue': 'SAN SEBASTIANO CURONE (AL)'
  },
  {
    'value': 'I152',
    'viewValue': 'SAN SEBASTIANO DA PO (TO)'
  },
  {
    'value': 'I154',
    'viewValue': 'SAN SECONDO DI PINEROLO (TO)'
  },
  {
    'value': 'I153',
    'viewValue': 'SAN SECONDO PARMENSE (PR)'
  },
  {
    'value': 'I157',
    'viewValue': 'SAN SEVERINO LUCANO (PZ)'
  },
  {
    'value': 'I156',
    'viewValue': 'SAN SEVERINO MARCHE (MC)'
  },
  {
    'value': 'I158',
    'viewValue': 'SAN SEVERO (FG)'
  },
  {
    'value': 'I162',
    'viewValue': 'SAN SIRO (CO)'
  },
  {
    'value': 'I163',
    'viewValue': 'SAN SOSSIO BARONIA (AV)'
  },
  {
    'value': 'I164',
    'viewValue': 'SAN SOSTENE (CZ)'
  },
  {
    'value': 'I165',
    'viewValue': 'SAN SOSTI (CS)'
  },
  {
    'value': 'I166',
    'viewValue': 'SAN SPERATE (CA)'
  },
  {
    'value': 'I261',
    'viewValue': 'SAN TAMMARO (CE)'
  },
  {
    'value': 'I328',
    'viewValue': 'SAN TEODORO (ME)'
  },
  {
    'value': 'I329',
    'viewValue': 'SAN TEODORO (NU)'
  },
  {
    'value': 'I347',
    'viewValue': 'SAN TOMASO AGORDINO (BL)'
  },
  {
    'value': 'I376',
    'viewValue': 'SAN VALENTINO IN ABRUZZO CITERIORE (PE)'
  },
  {
    'value': 'I377',
    'viewValue': 'SAN VALENTINO TORIO (SA)'
  },
  {
    'value': 'I381',
    'viewValue': 'SAN VENANZO (TR)'
  },
  {
    'value': 'I382',
    'viewValue': 'SAN VENDEMIANO (TV)'
  },
  {
    'value': 'I384',
    'viewValue': 'SAN VERO MILIS (OR)'
  },
  {
    'value': 'I390',
    'viewValue': 'SAN VINCENZO (LI)'
  },
  {
    'value': 'I388',
    'viewValue': 'SAN VINCENZO LA COSTA (CS)'
  },
  {
    'value': 'I389',
    'viewValue': 'SAN VINCENZO VALLE ROVETO (AQ)'
  },
  {
    'value': 'I391',
    'viewValue': 'SAN VITALIANO (NA)'
  },
  {
    'value': 'I402',
    'viewValue': 'SAN VITO (CA)'
  },
  {
    'value': 'I403',
    'viewValue': 'SAN VITO AL TAGLIAMENTO (PN)'
  },
  {
    'value': 'I404',
    'viewValue': 'SAN VITO AL TORRE (UD)'
  },
  {
    'value': 'I394',
    'viewValue': 'SAN VITO CHIETINO (CH)'
  },
  {
    'value': 'I396',
    'viewValue': 'SAN VITO DEI NORMANNI (BR)'
  },
  {
    'value': 'I392',
    'viewValue': 'SAN VITO DI CADORE (BL)'
  },
  {
    'value': 'I405',
    'viewValue': 'SAN VITO DI FAGAGNA (UD)'
  },
  {
    'value': 'I401',
    'viewValue': 'SAN VITO DI LEGUZZANO (VI)'
  },
  {
    'value': 'I407',
    'viewValue': 'SAN VITO LO CAPO (TP)'
  },
  {
    'value': 'I400',
    'viewValue': 'SAN VITO ROMANO (RM)'
  },
  {
    'value': 'I393',
    'viewValue': 'SAN VITO SULLO IONIO (CZ)'
  },
  {
    'value': 'I408',
    'viewValue': 'SAN VITTORE DEL LAZIO (FR)'
  },
  {
    'value': 'I409',
    'viewValue': 'SAN VITTORE OLONA (MI)'
  },
  {
    'value': 'I414',
    'viewValue': 'SAN ZENO DI MONTAGNA (VR)'
  },
  {
    'value': 'I412',
    'viewValue': 'SAN ZENO NAVIGLIO (BS)'
  },
  {
    'value': 'I415',
    'viewValue': 'SAN ZENONE AL LAMBRO (MI)'
  },
  {
    'value': 'I416',
    'viewValue': 'SAN ZENONE AL PO (PV)'
  },
  {
    'value': 'I417',
    'viewValue': 'SAN ZENONE DEGLI EZZELINI (TV)'
  },
  {
    'value': 'H757',
    'viewValue': 'SANARICA (LE)'
  },
  {
    'value': 'H821',
    'viewValue': 'SANDIGLIANO (BI)'
  },
  {
    'value': 'H829',
    'viewValue': 'SANDRIGO (VI)'
  },
  {
    'value': 'H851',
    'viewValue': "SANFRE' (CN)"
  },
  {
    'value': 'H852',
    'viewValue': 'SANFRONT (CN)'
  },
  {
    'value': 'H855',
    'viewValue': 'SANGANO (TO)'
  },
  {
    'value': 'H872',
    'viewValue': 'SANGIANO (VA)'
  },
  {
    'value': 'H877',
    'viewValue': 'SANGINETO (CS)'
  },
  {
    'value': 'H944',
    'viewValue': 'SANGUINETTO (VR)'
  },
  {
    'value': 'H974',
    'viewValue': 'SANLURI (CA)'
  },
  {
    'value': 'I048',
    'viewValue': "SANNAZZARO DE' BURGONDI (PV)"
  },
  {
    'value': 'I053',
    'viewValue': 'SANNICANDRO DI BARI (BA)'
  },
  {
    'value': 'I054',
    'viewValue': 'SANNICANDRO GARGANICO (FG)'
  },
  {
    'value': 'I059',
    'viewValue': 'SANNICOLA (LE)'
  },
  {
    'value': 'I155',
    'viewValue': 'SANSEPOLCRO (AR)'
  },
  {
    'value': 'I168',
    'viewValue': 'SANTA BRIGIDA (BG)'
  },
  {
    'value': 'I171',
    'viewValue': 'SANTA CATERINA ALBANESE (CS)'
  },
  {
    'value': 'I170',
    'viewValue': 'SANTA CATERINA DELLO IONIO (CZ)'
  },
  {
    'value': 'I169',
    'viewValue': 'SANTA CATERINA VILLARMOSA (CL)'
  },
  {
    'value': 'I172',
    'viewValue': 'SANTA CESAREA TERME (LE)'
  },
  {
    'value': 'I176',
    'viewValue': "SANTA CRISTINA D'ASPROMONTE (RC)"
  },
  {
    'value': 'I175',
    'viewValue': 'SANTA CRISTINA E BISSONE (PV)'
  },
  {
    'value': 'I174',
    'viewValue': 'SANTA CRISTINA GELA (PA)'
  },
  {
    'value': 'I173',
    'viewValue': 'SANTA CRISTINA VALGARDENA .ST CHRISTINA IN G. (BZ)'
  },
  {
    'value': 'I178',
    'viewValue': 'SANTA CROCE CAMERINA (RG)'
  },
  {
    'value': 'I179',
    'viewValue': 'SANTA CROCE DEL SANNIO (BN)'
  },
  {
    'value': 'I181',
    'viewValue': 'SANTA CROCE DI MAGLIANO (CB)'
  },
  {
    'value': 'I177',
    'viewValue': "SANTA CROCE SULL'ARNO (PI)"
  },
  {
    'value': 'I183',
    'viewValue': 'SANTA DOMENICA TALAO (CS)'
  },
  {
    'value': 'I184',
    'viewValue': 'SANTA DOMENICA VITTORIA (ME)'
  },
  {
    'value': 'I185',
    'viewValue': 'SANTA ELISABETTA (AG)'
  },
  {
    'value': 'I187',
    'viewValue': 'SANTA FIORA (GR)'
  },
  {
    'value': 'I188',
    'viewValue': 'SANTA FLAVIA (PA)'
  },
  {
    'value': 'I203',
    'viewValue': 'SANTA GIULETTA (PV)'
  },
  {
    'value': 'I205',
    'viewValue': 'SANTA GIUSTA (OR)'
  },
  {
    'value': 'I206',
    'viewValue': 'SANTA GIUSTINA (BL)'
  },
  {
    'value': 'I207',
    'viewValue': 'SANTA GIUSTINA IN COLLE (PD)'
  },
  {
    'value': 'I217',
    'viewValue': 'SANTA LUCE (PI)'
  },
  {
    'value': 'I220',
    'viewValue': 'SANTA LUCIA DEL MELA (ME)'
  },
  {
    'value': 'I221',
    'viewValue': 'SANTA LUCIA DI PIAVE (TV)'
  },
  {
    'value': 'I219',
    'viewValue': 'SANTA LUCIA DI SERINO (AV)'
  },
  {
    'value': 'I226',
    'viewValue': "SANTA MARGHERITA D'ADIGE (PD)"
  },
  {
    'value': 'I224',
    'viewValue': 'SANTA MARGHERITA DI BELICE (AG)'
  },
  {
    'value': 'I230',
    'viewValue': 'SANTA MARGHERITA DI STAFFORA (PV)'
  },
  {
    'value': 'I225',
    'viewValue': 'SANTA MARGHERITA LIGURE (GE)'
  },
  {
    'value': 'I232',
    'viewValue': 'SANTA MARIA A MONTE (PI)'
  },
  {
    'value': 'I233',
    'viewValue': 'SANTA MARIA A VICO (CE)'
  },
  {
    'value': 'I234',
    'viewValue': 'SANTA MARIA CAPUA VETERE (CE)'
  },
  {
    'value': 'M284',
    'viewValue': 'SANTA MARIA COGHINAS (SS)'
  },
  {
    'value': 'C717',
    'viewValue': 'SANTA MARIA DEL CEDRO (CS)'
  },
  {
    'value': 'I238',
    'viewValue': 'SANTA MARIA DEL MOLISE (IS)'
  },
  {
    'value': 'I237',
    'viewValue': 'SANTA MARIA DELLA VERSA (PV)'
  },
  {
    'value': 'I240',
    'viewValue': 'SANTA MARIA DI LICODIA (CT)'
  },
  {
    'value': 'I242',
    'viewValue': 'SANTA MARIA DI SALA (VE)'
  },
  {
    'value': 'I243',
    'viewValue': "SANTA MARIA HOE' (LC)"
  },
  {
    'value': 'I244',
    'viewValue': 'SANTA MARIA IMBARO (CH)'
  },
  {
    'value': 'M273',
    'viewValue': "SANTA MARIA LA CARITA' (NA)"
  },
  {
    'value': 'I247',
    'viewValue': 'SANTA MARIA LA FOSSA (CE)'
  },
  {
    'value': 'I248',
    'viewValue': 'SANTA MARIA LA LONGA (UD)'
  },
  {
    'value': 'I249',
    'viewValue': 'SANTA MARIA MAGGIORE (VB)'
  },
  {
    'value': 'I251',
    'viewValue': 'SANTA MARIA NUOVA (AN)'
  },
  {
    'value': 'I253',
    'viewValue': 'SANTA MARINA (SA)'
  },
  {
    'value': 'I254',
    'viewValue': 'SANTA MARINA SALINA (ME)'
  },
  {
    'value': 'I255',
    'viewValue': 'SANTA MARINELLA (RM)'
  },
  {
    'value': 'I291',
    'viewValue': 'SANTA NINFA (TP)'
  },
  {
    'value': 'I301',
    'viewValue': 'SANTA PAOLINA (AV)'
  },
  {
    'value': 'I308',
    'viewValue': 'SANTA SEVERINA (KR)'
  },
  {
    'value': 'I310',
    'viewValue': 'SANTA SOFIA (FC)'
  },
  {
    'value': 'I309',
    'viewValue': "SANTA SOFIA D'EPIRO (CS)"
  },
  {
    'value': 'I311',
    'viewValue': 'SANTA TERESA DI RIVA (ME)'
  },
  {
    'value': 'I312',
    'viewValue': 'SANTA TERESA GALLURA (SS)'
  },
  {
    'value': 'I314',
    'viewValue': 'SANTA VENERINA (CT)'
  },
  {
    'value': 'I316',
    'viewValue': "SANTA VITTORIA D'ALBA (CN)"
  },
  {
    'value': 'I315',
    'viewValue': 'SANTA VITTORIA IN MATENANO (AP)'
  },
  {
    'value': 'I182',
    'viewValue': 'SANTADI (CA)'
  },
  {
    'value': 'I189',
    'viewValue': "SANT'AGAPITO (IS)"
  },
  {
    'value': 'I191',
    'viewValue': "SANT'AGATA BOLOGNESE (BO)"
  },
  {
    'value': 'I197',
    'viewValue': "SANT'AGATA DE' GOTI (BN)"
  },
  {
    'value': 'I198',
    'viewValue': "SANT'AGATA DEL BIANCO (RC)"
  },
  {
    'value': 'I192',
    'viewValue': "SANT'AGATA DI ESARO (CS)"
  },
  {
    'value': 'I199',
    'viewValue': "SANT'AGATA DI MILITELLO (ME)"
  },
  {
    'value': 'I193',
    'viewValue': "SANT'AGATA DI PUGLIA (FG)"
  },
  {
    'value': 'I201',
    'viewValue': "SANT'AGATA FELTRIA (PU)"
  },
  {
    'value': 'I190',
    'viewValue': "SANT'AGATA FOSSILI (AL)"
  },
  {
    'value': 'I202',
    'viewValue': "SANT'AGATA LI BATTIATI (CT)"
  },
  {
    'value': 'I196',
    'viewValue': "SANT'AGATA SUL SANTERNO (RA)"
  },
  {
    'value': 'I208',
    'viewValue': "SANT'AGNELLO (NA)"
  },
  {
    'value': 'I209',
    'viewValue': "SANT'AGOSTINO (FE)"
  },
  {
    'value': 'I210',
    'viewValue': "SANT'ALBANO STURA (CN)"
  },
  {
    'value': 'I213',
    'viewValue': "SANT'ALESSIO CON VIALONE (PV)"
  },
  {
    'value': 'I214',
    'viewValue': "SANT'ALESSIO IN ASPROMONTE (RC)"
  },
  {
    'value': 'I215',
    'viewValue': "SANT'ALESSIO SICULO (ME)"
  },
  {
    'value': 'I216',
    'viewValue': "SANT'ALFIO (CT)"
  },
  {
    'value': 'I258',
    'viewValue': "SANT'AMBROGIO DI TORINO (TO)"
  },
  {
    'value': 'I259',
    'viewValue': "SANT'AMBROGIO DI VALPOLICELLA (VR)"
  },
  {
    'value': 'I256',
    'viewValue': "SANT'AMBROGIO SUL GARIGLIANO (FR)"
  },
  {
    'value': 'I262',
    'viewValue': "SANT'ANASTASIA (NA)"
  },
  {
    'value': 'I263',
    'viewValue': "SANT'ANATOLIA DI NARCO (PG)"
  },
  {
    'value': 'I266',
    'viewValue': "SANT'ANDREA APOSTOLO DELLO IONIO (CZ)"
  },
  {
    'value': 'I265',
    'viewValue': "SANT'ANDREA DEL GARIGLIANO (FR)"
  },
  {
    'value': 'I264',
    'viewValue': "SANT'ANDREA DI CONZA (AV)"
  },
  {
    'value': 'I271',
    'viewValue': "SANT'ANDREA FRIUS (CA)"
  },
  {
    'value': 'I277',
    'viewValue': "SANT'ANGELO A CUPOLO (BN)"
  },
  {
    'value': 'I278',
    'viewValue': "SANT'ANGELO A FASANELLA (SA)"
  },
  {
    'value': 'I280',
    'viewValue': "SANT'ANGELO A SCALA (AV)"
  },
  {
    'value': 'I279',
    'viewValue': "SANT'ANGELO ALL'ESCA (AV)"
  },
  {
    'value': 'I273',
    'viewValue': "SANT'ANGELO D'ALIFE (CE)"
  },
  {
    'value': 'I281',
    'viewValue': "SANT'ANGELO DEI LOMBARDI (AV)"
  },
  {
    'value': 'I282',
    'viewValue': "SANT'ANGELO DEL PESCO (IS)"
  },
  {
    'value': 'I283',
    'viewValue': "SANT'ANGELO DI BROLO (ME)"
  },
  {
    'value': 'I275',
    'viewValue': "SANT'ANGELO DI PIOVE DI SACCO (PD)"
  },
  {
    'value': 'I285',
    'viewValue': "SANT'ANGELO IN LIZZOLA (PU)"
  },
  {
    'value': 'I286',
    'viewValue': "SANT'ANGELO IN PONTANO (MC)"
  },
  {
    'value': 'I287',
    'viewValue': "SANT'ANGELO IN VADO (PU)"
  },
  {
    'value': 'I288',
    'viewValue': "SANT'ANGELO LE FRATTE (PZ)"
  },
  {
    'value': 'I289',
    'viewValue': "SANT'ANGELO LIMOSANO (CB)"
  },
  {
    'value': 'I274',
    'viewValue': "SANT'ANGELO LODIGIANO (LO)"
  },
  {
    'value': 'I276',
    'viewValue': "SANT'ANGELO LOMELLINA (PV)"
  },
  {
    'value': 'I290',
    'viewValue': "SANT'ANGELO MUXARO (AG)"
  },
  {
    'value': 'I284',
    'viewValue': "SANT'ANGELO ROMANO (RM)"
  },
  {
    'value': 'M209',
    'viewValue': "SANT'ANNA ARRESI (CA)"
  },
  {
    'value': 'I292',
    'viewValue': "SANT'ANNA D'ALFAEDO (VR)"
  },
  {
    'value': 'I293',
    'viewValue': "SANT'ANTIMO (NA)"
  },
  {
    'value': 'I294',
    'viewValue': "SANT'ANTIOCO (CA)"
  },
  {
    'value': 'I296',
    'viewValue': "SANT'ANTONINO DI SUSA (TO)"
  },
  {
    'value': 'I300',
    'viewValue': "SANT'ANTONIO ABATE (NA)"
  },
  {
    'value': 'M276',
    'viewValue': "SANT'ANTONIO DI GALLURA (SS)"
  },
  {
    'value': 'I302',
    'viewValue': "SANT'APOLLINARE (FR)"
  },
  {
    'value': 'I305',
    'viewValue': "SANT'ARCANGELO (PZ)"
  },
  {
    'value': 'I304',
    'viewValue': 'SANTARCANGELO DI ROMAGNA (RN)'
  },
  {
    'value': 'F557',
    'viewValue': "SANT'ARCANGELO TRIMONTE (BN)"
  },
  {
    'value': 'I306',
    'viewValue': "SANT'ARPINO (CE)"
  },
  {
    'value': 'I307',
    'viewValue': "SANT'ARSENIO (SA)"
  },
  {
    'value': 'I326',
    'viewValue': 'SANTE MARIE (AQ)'
  },
  {
    'value': 'I318',
    'viewValue': "SANT'EGIDIO ALLA VIBRATA (TE)"
  },
  {
    'value': 'I317',
    'viewValue': "SANT'EGIDIO DEL MONTE ALBINO (SA)"
  },
  {
    'value': 'I319',
    'viewValue': "SANT'ELENA (PD)"
  },
  {
    'value': 'B466',
    'viewValue': "SANT'ELENA SANNITA (IS)"
  },
  {
    'value': 'I320',
    'viewValue': "SANT'ELIA A PIANISI (CB)"
  },
  {
    'value': 'I321',
    'viewValue': "SANT'ELIA FIUMERAPIDO (FR)"
  },
  {
    'value': 'I324',
    'viewValue': "SANT'ELPIDIO A MARE (AP)"
  },
  {
    'value': 'I327',
    'viewValue': 'SANTENA (TO)'
  },
  {
    'value': 'I330',
    'viewValue': 'SANTERAMO IN COLLE (BA)'
  },
  {
    'value': 'I332',
    'viewValue': "SANT'EUFEMIA A MAIELLA (PE)"
  },
  {
    'value': 'I333',
    'viewValue': "SANT'EUFEMIA D'ASPROMONTE (RC)"
  },
  {
    'value': 'I335',
    'viewValue': "SANT'EUSANIO DEL SANGRO (CH)"
  },
  {
    'value': 'I336',
    'viewValue': "SANT'EUSANIO FORCONESE (AQ)"
  },
  {
    'value': 'I337',
    'viewValue': "SANTHIA' (VC)"
  },
  {
    'value': 'I339',
    'viewValue': 'SANTI COSMA E DAMIANO (LT)'
  },
  {
    'value': 'I341',
    'viewValue': "SANT'ILARIO DELLO IONIO (RC)"
  },
  {
    'value': 'I342',
    'viewValue': "SANT'ILARIO D'ENZA (RE)"
  },
  {
    'value': 'I344',
    'viewValue': "SANT'IPPOLITO (PU)"
  },
  {
    'value': 'I365',
    'viewValue': 'SANTO STEFANO AL MARE (IM)'
  },
  {
    'value': 'I367',
    'viewValue': 'SANTO STEFANO BELBO (CN)'
  },
  {
    'value': 'I368',
    'viewValue': "SANTO STEFANO D'AVETO (GE)"
  },
  {
    'value': 'I357',
    'viewValue': 'SANTO STEFANO DEL SOLE (AV)'
  },
  {
    'value': 'C919',
    'viewValue': 'SANTO STEFANO DI CADORE (BL)'
  },
  {
    'value': 'I370',
    'viewValue': 'SANTO STEFANO DI CAMASTRA (ME)'
  },
  {
    'value': 'I363',
    'viewValue': 'SANTO STEFANO DI MAGRA (SP)'
  },
  {
    'value': 'I359',
    'viewValue': 'SANTO STEFANO DI ROGLIANO (CS)'
  },
  {
    'value': 'I360',
    'viewValue': 'SANTO STEFANO DI SESSANIO (AQ)'
  },
  {
    'value': 'I371',
    'viewValue': 'SANTO STEFANO IN ASPROMONTE (RC)'
  },
  {
    'value': 'I362',
    'viewValue': 'SANTO STEFANO LODIGIANO (LO)'
  },
  {
    'value': 'I356',
    'viewValue': 'SANTO STEFANO QUISQUINA (AG)'
  },
  {
    'value': 'I372',
    'viewValue': 'SANTO STEFANO ROERO (CN)'
  },
  {
    'value': 'I361',
    'viewValue': 'SANTO STEFANO TICINO (MI)'
  },
  {
    'value': 'I373',
    'viewValue': 'SANTO STINO DI LIVENZA (VE)'
  },
  {
    'value': 'I346',
    'viewValue': "SANT'OLCESE (GE)"
  },
  {
    'value': 'I260',
    'viewValue': 'SANTOMENNA (SA)'
  },
  {
    'value': 'I348',
    'viewValue': "SANT'OMERO (TE)"
  },
  {
    'value': 'I349',
    'viewValue': "SANT'OMOBONO IMAGNA (BG)"
  },
  {
    'value': 'I350',
    'viewValue': "SANT'ONOFRIO (VV)"
  },
  {
    'value': 'I351',
    'viewValue': 'SANTOPADRE (FR)'
  },
  {
    'value': 'I352',
    'viewValue': "SANT'ORESTE (RM)"
  },
  {
    'value': 'I353',
    'viewValue': 'SANTORSO (VI)'
  },
  {
    'value': 'I354',
    'viewValue': "SANT'ORSOLA TERME (TN)"
  },
  {
    'value': 'I374',
    'viewValue': 'SANTU LUSSURGIU (OR)'
  },
  {
    'value': 'I375',
    'viewValue': "SANT'URBANO (PD)"
  },
  {
    'value': 'I410',
    'viewValue': 'SANZA (SA)'
  },
  {
    'value': 'I411',
    'viewValue': 'SANZENO (TN)'
  },
  {
    'value': 'I418',
    'viewValue': 'SAONARA (PD)'
  },
  {
    'value': 'I420',
    'viewValue': 'SAPONARA (ME)'
  },
  {
    'value': 'I421',
    'viewValue': 'SAPPADA (BL)'
  },
  {
    'value': 'I422',
    'viewValue': 'SAPRI (SA)'
  },
  {
    'value': 'I423',
    'viewValue': 'SARACENA (CS)'
  },
  {
    'value': 'I424',
    'viewValue': 'SARACINESCO (RM)'
  },
  {
    'value': 'I425',
    'viewValue': 'SARCEDO (VI)'
  },
  {
    'value': 'I426',
    'viewValue': 'SARCONI (PZ)'
  },
  {
    'value': 'I428',
    'viewValue': 'SARDARA (CA)'
  },
  {
    'value': 'I429',
    'viewValue': 'SARDIGLIANO (AL)'
  },
  {
    'value': 'I430',
    'viewValue': 'SAREGO (VI)'
  },
  {
    'value': 'I431',
    'viewValue': 'SARENTINO .SARNTAL. (BZ)'
  },
  {
    'value': 'I432',
    'viewValue': 'SAREZZANO (AL)'
  },
  {
    'value': 'I433',
    'viewValue': 'SAREZZO (BS)'
  },
  {
    'value': 'I434',
    'viewValue': 'SARMATO (PC)'
  },
  {
    'value': 'I435',
    'viewValue': 'SARMEDE (TV)'
  },
  {
    'value': 'I436',
    'viewValue': 'SARNANO (MC)'
  },
  {
    'value': 'I437',
    'viewValue': 'SARNICO (BG)'
  },
  {
    'value': 'I438',
    'viewValue': 'SARNO (SA)'
  },
  {
    'value': 'I439',
    'viewValue': 'SARNONICO (TN)'
  },
  {
    'value': 'I441',
    'viewValue': 'SARONNO (VA)'
  },
  {
    'value': 'I442',
    'viewValue': 'SARRE (AO)'
  },
  {
    'value': 'I443',
    'viewValue': 'SARROCH (CA)'
  },
  {
    'value': 'I444',
    'viewValue': 'SARSINA (FC)'
  },
  {
    'value': 'I445',
    'viewValue': 'SARTEANO (SI)'
  },
  {
    'value': 'I447',
    'viewValue': 'SARTIRANA LOMELLINA (PV)'
  },
  {
    'value': 'I448',
    'viewValue': 'SARULE (NU)'
  },
  {
    'value': 'I449',
    'viewValue': 'SARZANA (SP)'
  },
  {
    'value': 'I451',
    'viewValue': 'SASSANO (SA)'
  },
  {
    'value': 'I452',
    'viewValue': 'SASSARI (SS)'
  },
  {
    'value': 'I453',
    'viewValue': 'SASSELLO (SV)'
  },
  {
    'value': 'I454',
    'viewValue': 'SASSETTA (LI)'
  },
  {
    'value': 'I455',
    'viewValue': 'SASSINORO (BN)'
  },
  {
    'value': 'I457',
    'viewValue': 'SASSO DI CASTALDA (PZ)'
  },
  {
    'value': 'G972',
    'viewValue': 'SASSO MARCONI (BO)'
  },
  {
    'value': 'I459',
    'viewValue': 'SASSOCORVARO (PU)'
  },
  {
    'value': 'I460',
    'viewValue': 'SASSOFELTRIO (PU)'
  },
  {
    'value': 'I461',
    'viewValue': 'SASSOFERRATO (AN)'
  },
  {
    'value': 'I462',
    'viewValue': 'SASSUOLO (MO)'
  },
  {
    'value': 'I463',
    'viewValue': 'SATRIANO (CZ)'
  },
  {
    'value': 'G614',
    'viewValue': 'SATRIANO DI LUCANIA (PZ)'
  },
  {
    'value': 'I464',
    'viewValue': 'SAURIS (UD)'
  },
  {
    'value': 'I465',
    'viewValue': 'SAUZE DI CESANA (TO)'
  },
  {
    'value': 'I466',
    'viewValue': "SAUZE D'OULX (TO)"
  },
  {
    'value': 'I467',
    'viewValue': 'SAVA (TA)'
  },
  {
    'value': 'I468',
    'viewValue': 'SAVELLI (KR)'
  },
  {
    'value': 'I469',
    'viewValue': 'SAVIANO (NA)'
  },
  {
    'value': 'I470',
    'viewValue': 'SAVIGLIANO (CN)'
  },
  {
    'value': 'I471',
    'viewValue': 'SAVIGNANO IRPINO (AV)'
  },
  {
    'value': 'I473',
    'viewValue': 'SAVIGNANO SUL PANARO (MO)'
  },
  {
    'value': 'I472',
    'viewValue': 'SAVIGNANO SUL RUBICONE (FC)'
  },
  {
    'value': 'I474',
    'viewValue': 'SAVIGNO (BO)'
  },
  {
    'value': 'I475',
    'viewValue': 'SAVIGNONE (GE)'
  },
  {
    'value': 'I476',
    'viewValue': "SAVIORE DELL'ADAMELLO (BS)"
  },
  {
    'value': 'I477',
    'viewValue': 'SAVOCA (ME)'
  },
  {
    'value': 'I478',
    'viewValue': 'SAVOGNA (UD)'
  },
  {
    'value': 'I479',
    'viewValue': "SAVOGNA D'ISONZO (GO)"
  },
  {
    'value': 'H730',
    'viewValue': 'SAVOIA DI LUCANIA (PZ)'
  },
  {
    'value': 'I480',
    'viewValue': 'SAVONA (SV)'
  },
  {
    'value': 'I482',
    'viewValue': 'SCAFA (PE)'
  },
  {
    'value': 'I483',
    'viewValue': 'SCAFATI (SA)'
  },
  {
    'value': 'I484',
    'viewValue': 'SCAGNELLO (CN)'
  },
  {
    'value': 'I486',
    'viewValue': 'SCALA (SA)'
  },
  {
    'value': 'I485',
    'viewValue': 'SCALA COELI (CS)'
  },
  {
    'value': 'I487',
    'viewValue': 'SCALDASOLE (PV)'
  },
  {
    'value': 'I489',
    'viewValue': 'SCALEA (CS)'
  },
  {
    'value': 'I490',
    'viewValue': 'SCALENGHE (TO)'
  },
  {
    'value': 'I492',
    'viewValue': 'SCALETTA ZANCLEA (ME)'
  },
  {
    'value': 'I493',
    'viewValue': 'SCAMPITELLA (AV)'
  },
  {
    'value': 'I494',
    'viewValue': 'SCANDALE (KR)'
  },
  {
    'value': 'I495',
    'viewValue': 'SCANDELUZZA (AT)'
  },
  {
    'value': 'I496',
    'viewValue': 'SCANDIANO (RE)'
  },
  {
    'value': 'B962',
    'viewValue': 'SCANDICCI (FI)'
  },
  {
    'value': 'I497',
    'viewValue': 'SCANDOLARA RAVARA (CR)'
  },
  {
    'value': 'I498',
    'viewValue': "SCANDOLARA RIPA D'OGLIO (CR)"
  },
  {
    'value': 'I499',
    'viewValue': 'SCANDRIGLIA (RI)'
  },
  {
    'value': 'I501',
    'viewValue': 'SCANNO (AQ)'
  },
  {
    'value': 'I503',
    'viewValue': 'SCANO DI MONTIFERRO (OR)'
  },
  {
    'value': 'I504',
    'viewValue': 'SCANSANO (GR)'
  },
  {
    'value': 'M256',
    'viewValue': 'SCANZANO JONICO (MT)'
  },
  {
    'value': 'I506',
    'viewValue': 'SCANZOROSCIATE (BG)'
  },
  {
    'value': 'I507',
    'viewValue': 'SCAPOLI (IS)'
  },
  {
    'value': 'I510',
    'viewValue': 'SCARLINO (GR)'
  },
  {
    'value': 'I511',
    'viewValue': 'SCARMAGNO (TO)'
  },
  {
    'value': 'I512',
    'viewValue': 'SCARNAFIGI (CN)'
  },
  {
    'value': 'I514',
    'viewValue': 'SCARPERIA (FI)'
  },
  {
    'value': 'I519',
    'viewValue': 'SCENA .SCHONNA. (BZ)'
  },
  {
    'value': 'I520',
    'viewValue': 'SCERNI (CH)'
  },
  {
    'value': 'I522',
    'viewValue': 'SCHEGGIA E PASCELUPO (PG)'
  },
  {
    'value': 'I523',
    'viewValue': 'SCHEGGINO (PG)'
  },
  {
    'value': 'I526',
    'viewValue': 'SCHIAVI DI ABRUZZO (CH)'
  },
  {
    'value': 'I527',
    'viewValue': 'SCHIAVON (VI)'
  },
  {
    'value': 'I529',
    'viewValue': 'SCHIGNANO (CO)'
  },
  {
    'value': 'I530',
    'viewValue': 'SCHILPARIO (BG)'
  },
  {
    'value': 'I531',
    'viewValue': 'SCHIO (VI)'
  },
  {
    'value': 'I532',
    'viewValue': 'SCHIVENOGLIA (MN)'
  },
  {
    'value': 'I533',
    'viewValue': 'SCIACCA (AG)'
  },
  {
    'value': 'I534',
    'viewValue': 'SCIARA (PA)'
  },
  {
    'value': 'I535',
    'viewValue': 'SCICLI (RG)'
  },
  {
    'value': 'I536',
    'viewValue': 'SCIDO (RC)'
  },
  {
    'value': 'D290',
    'viewValue': 'SCIGLIANO (CS)'
  },
  {
    'value': 'I537',
    'viewValue': 'SCILLA (RC)'
  },
  {
    'value': 'I538',
    'viewValue': 'SCILLATO (PA)'
  },
  {
    'value': 'I539',
    'viewValue': 'SCIOLZE (TO)'
  },
  {
    'value': 'I540',
    'viewValue': 'SCISCIANO (NA)'
  },
  {
    'value': 'I541',
    'viewValue': 'SCLAFANI BAGNI (PA)'
  },
  {
    'value': 'I543',
    'viewValue': 'SCONTRONE (AQ)'
  },
  {
    'value': 'I544',
    'viewValue': 'SCOPA (VC)'
  },
  {
    'value': 'I545',
    'viewValue': 'SCOPELLO (VC)'
  },
  {
    'value': 'I546',
    'viewValue': 'SCOPPITO (AQ)'
  },
  {
    'value': 'I548',
    'viewValue': 'SCORDIA (CT)'
  },
  {
    'value': 'I549',
    'viewValue': 'SCORRANO (LE)'
  },
  {
    'value': 'I551',
    'viewValue': "SCORZE' (VE)"
  },
  {
    'value': 'I553',
    'viewValue': 'SCURCOLA MARSICANA (AQ)'
  },
  {
    'value': 'I554',
    'viewValue': 'SCURELLE (TN)'
  },
  {
    'value': 'I555',
    'viewValue': 'SCURZOLENGO (AT)'
  },
  {
    'value': 'I556',
    'viewValue': 'SEBORGA (IM)'
  },
  {
    'value': 'I558',
    'viewValue': 'SECINARO (AQ)'
  },
  {
    'value': 'I559',
    'viewValue': "SECLI' (LE)"
  },
  {
    'value': 'I561',
    'viewValue': 'SECUGNAGO (LO)'
  },
  {
    'value': 'I562',
    'viewValue': 'SEDEGLIANO (UD)'
  },
  {
    'value': 'I563',
    'viewValue': 'SEDICO (BL)'
  },
  {
    'value': 'I564',
    'viewValue': 'SEDILO (OR)'
  },
  {
    'value': 'I565',
    'viewValue': 'SEDINI (SS)'
  },
  {
    'value': 'I566',
    'viewValue': 'SEDRIANO (MI)'
  },
  {
    'value': 'I567',
    'viewValue': 'SEDRINA (BG)'
  },
  {
    'value': 'I569',
    'viewValue': 'SEFRO (MC)'
  },
  {
    'value': 'I570',
    'viewValue': 'SEGARIU (CA)'
  },
  {
    'value': 'I571',
    'viewValue': 'SEGGIANO (GR)'
  },
  {
    'value': 'I573',
    'viewValue': 'SEGNI (RM)'
  },
  {
    'value': 'I576',
    'viewValue': 'SEGONZANO (TN)'
  },
  {
    'value': 'I577',
    'viewValue': 'SEGRATE (MI)'
  },
  {
    'value': 'I578',
    'viewValue': 'SEGUSINO (TV)'
  },
  {
    'value': 'I580',
    'viewValue': 'SELARGIUS (CA)'
  },
  {
    'value': 'I581',
    'viewValue': 'SELCI (RI)'
  },
  {
    'value': 'I582',
    'viewValue': 'SELEGAS (CA)'
  },
  {
    'value': 'I585',
    'viewValue': 'SELLANO (PG)'
  },
  {
    'value': 'I588',
    'viewValue': 'SELLERO (BS)'
  },
  {
    'value': 'I589',
    'viewValue': 'SELLIA (CZ)'
  },
  {
    'value': 'I590',
    'viewValue': 'SELLIA MARINA (CZ)'
  },
  {
    'value': 'I593',
    'viewValue': 'SELVA DEI MOLINI .MUHLWALD. (BZ)'
  },
  {
    'value': 'I592',
    'viewValue': 'SELVA DI CADORE (BL)'
  },
  {
    'value': 'I594',
    'viewValue': 'SELVA DI PROGNO (VR)'
  },
  {
    'value': 'I591',
    'viewValue': 'SELVA DI VAL GARDENA .WOLKENSTEIN IN GROEDEN. (BZ)'
  },
  {
    'value': 'I595',
    'viewValue': 'SELVAZZANO DENTRO (PD)'
  },
  {
    'value': 'I596',
    'viewValue': 'SELVE MARCONE (BI)'
  },
  {
    'value': 'I597',
    'viewValue': 'SELVINO (BG)'
  },
  {
    'value': 'I598',
    'viewValue': 'SEMESTENE (SS)'
  },
  {
    'value': 'I599',
    'viewValue': 'SEMIANA (PV)'
  },
  {
    'value': 'I600',
    'viewValue': 'SEMINARA (RC)'
  },
  {
    'value': 'I601',
    'viewValue': 'SEMPRONIANO (GR)'
  },
  {
    'value': 'I602',
    'viewValue': 'SENAGO (MI)'
  },
  {
    'value': 'I604',
    'viewValue': 'SENALES .SCHNALS. (BZ)'
  },
  {
    'value': 'I603',
    'viewValue': 'SENALE-SAN FELICE .UNSERE LIEBE FRAU IM WALD. (BZ)'
  },
  {
    'value': 'I605',
    'viewValue': 'SENEGHE (OR)'
  },
  {
    'value': 'I606',
    'viewValue': 'SENERCHIA (AV)'
  },
  {
    'value': 'I607',
    'viewValue': 'SENIGA (BS)'
  },
  {
    'value': 'I608',
    'viewValue': 'SENIGALLIA (AN)'
  },
  {
    'value': 'I609',
    'viewValue': 'SENIS (OR)'
  },
  {
    'value': 'I610',
    'viewValue': 'SENISE (PZ)'
  },
  {
    'value': 'I611',
    'viewValue': 'SENNA COMASCO (CO)'
  },
  {
    'value': 'I612',
    'viewValue': 'SENNA LODIGIANA (LO)'
  },
  {
    'value': 'I613',
    'viewValue': 'SENNARIOLO (OR)'
  },
  {
    'value': 'I614',
    'viewValue': 'SENNORI (SS)'
  },
  {
    'value': 'I615',
    'viewValue': "SENORBI' (CA)"
  },
  {
    'value': 'I618',
    'viewValue': 'SEPINO (CB)'
  },
  {
    'value': 'I619',
    'viewValue': 'SEPPIANA (VB)'
  },
  {
    'value': 'I621',
    'viewValue': 'SEQUALS (PN)'
  },
  {
    'value': 'I622',
    'viewValue': 'SERAVEZZA (LU)'
  },
  {
    'value': 'I624',
    'viewValue': 'SERDIANA (CA)'
  },
  {
    'value': 'I625',
    'viewValue': 'SEREGNO (MI)'
  },
  {
    'value': 'I626',
    'viewValue': 'SEREN DEL GRAPPA (BL)'
  },
  {
    'value': 'I627',
    'viewValue': 'SERGNANO (CR)'
  },
  {
    'value': 'I628',
    'viewValue': 'SERIATE (BG)'
  },
  {
    'value': 'I629',
    'viewValue': 'SERINA (BG)'
  },
  {
    'value': 'I630',
    'viewValue': 'SERINO (AV)'
  },
  {
    'value': 'I631',
    'viewValue': 'SERLE (BS)'
  },
  {
    'value': 'I632',
    'viewValue': 'SERMIDE (MN)'
  },
  {
    'value': 'I634',
    'viewValue': 'SERMONETA (LT)'
  },
  {
    'value': 'I635',
    'viewValue': 'SERNAGLIA DELLA BATTAGLIA (TV)'
  },
  {
    'value': 'I636',
    'viewValue': 'SERNIO (SO)'
  },
  {
    'value': 'I637',
    'viewValue': 'SEROLE (AT)'
  },
  {
    'value': 'I642',
    'viewValue': "SERRA D'AIELLO (CS)"
  },
  {
    'value': 'I643',
    'viewValue': "SERRA DE' CONTI (AN)"
  },
  {
    'value': 'I650',
    'viewValue': 'SERRA PEDACE (CS)'
  },
  {
    'value': 'I640',
    'viewValue': "SERRA RICCO' (GE)"
  },
  {
    'value': 'I639',
    'viewValue': 'SERRA SAN BRUNO (VV)'
  },
  {
    'value': 'I653',
    'viewValue': 'SERRA SAN QUIRICO (AN)'
  },
  {
    'value': 'I654',
    'viewValue': "SERRA SANT'ABBONDIO (PU)"
  },
  {
    'value': 'I641',
    'viewValue': 'SERRACAPRIOLA (FG)'
  },
  {
    'value': 'I644',
    'viewValue': 'SERRADIFALCO (CL)'
  },
  {
    'value': 'I646',
    'viewValue': "SERRALUNGA D'ALBA (CN)"
  },
  {
    'value': 'I645',
    'viewValue': 'SERRALUNGA DI CREA (AL)'
  },
  {
    'value': 'I647',
    'viewValue': 'SERRAMANNA (CA)'
  },
  {
    'value': 'F357',
    'viewValue': 'SERRAMAZZONI (MO)'
  },
  {
    'value': 'I648',
    'viewValue': 'SERRAMEZZANA (SA)'
  },
  {
    'value': 'I649',
    'viewValue': 'SERRAMONACESCA (PE)'
  },
  {
    'value': 'I651',
    'viewValue': 'SERRAPETRONA (MC)'
  },
  {
    'value': 'I652',
    'viewValue': 'SERRARA FONTANA (NA)'
  },
  {
    'value': 'I655',
    'viewValue': 'SERRASTRETTA (CZ)'
  },
  {
    'value': 'I656',
    'viewValue': 'SERRATA (RC)'
  },
  {
    'value': 'I662',
    'viewValue': 'SERRAVALLE A PO (MN)'
  },
  {
    'value': 'I661',
    'viewValue': 'SERRAVALLE DI CHIENTI (MC)'
  },
  {
    'value': 'I659',
    'viewValue': 'SERRAVALLE LANGHE (CN)'
  },
  {
    'value': 'I660',
    'viewValue': 'SERRAVALLE PISTOIESE (PT)'
  },
  {
    'value': 'I657',
    'viewValue': 'SERRAVALLE SCRIVIA (AL)'
  },
  {
    'value': 'I663',
    'viewValue': 'SERRAVALLE SESIA (VC)'
  },
  {
    'value': 'I666',
    'viewValue': 'SERRE (SA)'
  },
  {
    'value': 'I667',
    'viewValue': 'SERRENTI (CA)'
  },
  {
    'value': 'I668',
    'viewValue': 'SERRI (NU)'
  },
  {
    'value': 'I669',
    'viewValue': 'SERRONE (FR)'
  },
  {
    'value': 'I670',
    'viewValue': 'SERRUNGARINA (PU)'
  },
  {
    'value': 'I671',
    'viewValue': 'SERSALE (CZ)'
  },
  {
    'value': 'C070',
    'viewValue': 'SERVIGLIANO (AP)'
  },
  {
    'value': 'I676',
    'viewValue': 'SESSA AURUNCA (CE)'
  },
  {
    'value': 'I677',
    'viewValue': 'SESSA CILENTO (SA)'
  },
  {
    'value': 'I678',
    'viewValue': 'SESSAME (AT)'
  },
  {
    'value': 'I679',
    'viewValue': 'SESSANO DEL MOLISE (IS)'
  },
  {
    'value': 'E070',
    'viewValue': 'SESTA GODANO (SP)'
  },
  {
    'value': 'I681',
    'viewValue': 'SESTINO (AR)'
  },
  {
    'value': 'I687',
    'viewValue': 'SESTO .SEXTEN. (BZ)'
  },
  {
    'value': 'I686',
    'viewValue': 'SESTO AL REGHENA (PN)'
  },
  {
    'value': 'I688',
    'viewValue': 'SESTO CALENDE (VA)'
  },
  {
    'value': 'I682',
    'viewValue': 'SESTO CAMPANO (IS)'
  },
  {
    'value': 'I683',
    'viewValue': 'SESTO ED UNITI (CR)'
  },
  {
    'value': 'I684',
    'viewValue': 'SESTO FIORENTINO (FI)'
  },
  {
    'value': 'I690',
    'viewValue': 'SESTO SAN GIOVANNI (MI)'
  },
  {
    'value': 'I689',
    'viewValue': 'SESTOLA (MO)'
  },
  {
    'value': 'I693',
    'viewValue': 'SESTRI LEVANTE (GE)'
  },
  {
    'value': 'I692',
    'viewValue': 'SESTRIERE (TO)'
  },
  {
    'value': 'I695',
    'viewValue': 'SESTU (CA)'
  },
  {
    'value': 'I696',
    'viewValue': 'SETTALA (MI)'
  },
  {
    'value': 'I697',
    'viewValue': 'SETTEFRATI (FR)'
  },
  {
    'value': 'I698',
    'viewValue': 'SETTIME (AT)'
  },
  {
    'value': 'I700',
    'viewValue': 'SETTIMO MILANESE (MI)'
  },
  {
    'value': 'I701',
    'viewValue': 'SETTIMO ROTTARO (TO)'
  },
  {
    'value': 'I699',
    'viewValue': 'SETTIMO SAN PIETRO (CA)'
  },
  {
    'value': 'I703',
    'viewValue': 'SETTIMO TORINESE (TO)'
  },
  {
    'value': 'I702',
    'viewValue': 'SETTIMO VITTONE (TO)'
  },
  {
    'value': 'I704',
    'viewValue': 'SETTINGIANO (CZ)'
  },
  {
    'value': 'I705',
    'viewValue': 'SETZU (CA)'
  },
  {
    'value': 'I706',
    'viewValue': 'SEUI (NU)'
  },
  {
    'value': 'I707',
    'viewValue': 'SEULO (NU)'
  },
  {
    'value': 'I709',
    'viewValue': 'SEVESO (MI)'
  },
  {
    'value': 'I711',
    'viewValue': 'SEZZADIO (AL)'
  },
  {
    'value': 'I712',
    'viewValue': 'SEZZE (LT)'
  },
  {
    'value': 'I714',
    'viewValue': 'SFRUZ (TN)'
  },
  {
    'value': 'I715',
    'viewValue': 'SGONICO (TS)'
  },
  {
    'value': 'I716',
    'viewValue': 'SGURGOLA (FR)'
  },
  {
    'value': 'I717',
    'viewValue': 'SIAMAGGIORE (OR)'
  },
  {
    'value': 'I718',
    'viewValue': 'SIAMANNA (OR)'
  },
  {
    'value': 'I720',
    'viewValue': 'SIANO (SA)'
  },
  {
    'value': 'I721',
    'viewValue': 'SIAPICCIA (OR)'
  },
  {
    'value': 'M253',
    'viewValue': 'SICIGNANO DEGLI ALBURNI (SA)'
  },
  {
    'value': 'I723',
    'viewValue': 'SICULIANA (AG)'
  },
  {
    'value': 'I724',
    'viewValue': 'SIDDI (CA)'
  },
  {
    'value': 'I725',
    'viewValue': 'SIDERNO (RC)'
  },
  {
    'value': 'I726',
    'viewValue': 'SIENA (SI)'
  },
  {
    'value': 'I727',
    'viewValue': 'SIGILLO (PG)'
  },
  {
    'value': 'I728',
    'viewValue': 'SIGNA (FI)'
  },
  {
    'value': 'I729',
    'viewValue': 'SILANDRO .SCHLANDERS. (BZ)'
  },
  {
    'value': 'I730',
    'viewValue': 'SILANUS (NU)'
  },
  {
    'value': 'F116',
    'viewValue': 'SILEA (TV)'
  },
  {
    'value': 'I732',
    'viewValue': 'SILIGO (SS)'
  },
  {
    'value': 'I734',
    'viewValue': 'SILIQUA (CA)'
  },
  {
    'value': 'I735',
    'viewValue': 'SILIUS (CA)'
  },
  {
    'value': 'I737',
    'viewValue': 'SILLANO (LU)'
  },
  {
    'value': 'I736',
    'viewValue': 'SILLAVENGO (NO)'
  },
  {
    'value': 'I738',
    'viewValue': "SILVANO D'ORBA (AL)"
  },
  {
    'value': 'I739',
    'viewValue': 'SILVANO PIETRA (PV)'
  },
  {
    'value': 'I741',
    'viewValue': 'SILVI (TE)'
  },
  {
    'value': 'I742',
    'viewValue': 'SIMALA (OR)'
  },
  {
    'value': 'I743',
    'viewValue': 'SIMAXIS (OR)'
  },
  {
    'value': 'I744',
    'viewValue': 'SIMBARIO (VV)'
  },
  {
    'value': 'I745',
    'viewValue': 'SIMERI CRICHI (CZ)'
  },
  {
    'value': 'I747',
    'viewValue': 'SINAGRA (ME)'
  },
  {
    'value': 'A468',
    'viewValue': 'SINALUNGA (SI)'
  },
  {
    'value': 'I748',
    'viewValue': 'SINDIA (NU)'
  },
  {
    'value': 'I749',
    'viewValue': 'SINI (OR)'
  },
  {
    'value': 'I750',
    'viewValue': 'SINIO (CN)'
  },
  {
    'value': 'I751',
    'viewValue': 'SINISCOLA (NU)'
  },
  {
    'value': 'I752',
    'viewValue': 'SINNAI (CA)'
  },
  {
    'value': 'I753',
    'viewValue': 'SINOPOLI (RC)'
  },
  {
    'value': 'I754',
    'viewValue': 'SIRACUSA (SR)'
  },
  {
    'value': 'I756',
    'viewValue': 'SIRIGNANO (AV)'
  },
  {
    'value': 'I757',
    'viewValue': 'SIRIS (OR)'
  },
  {
    'value': 'I633',
    'viewValue': 'SIRMIONE (BS)'
  },
  {
    'value': 'I758',
    'viewValue': 'SIROLO (AN)'
  },
  {
    'value': 'I759',
    'viewValue': 'SIRONE (LC)'
  },
  {
    'value': 'I760',
    'viewValue': 'SIROR (TN)'
  },
  {
    'value': 'I761',
    'viewValue': 'SIRTORI (LC)'
  },
  {
    'value': 'I763',
    'viewValue': 'SISSA (PR)'
  },
  {
    'value': 'I765',
    'viewValue': 'SIURGUS DONIGALA (CA)'
  },
  {
    'value': 'E265',
    'viewValue': 'SIZIANO (PV)'
  },
  {
    'value': 'I767',
    'viewValue': 'SIZZANO (NO)'
  },
  {
    'value': 'I771',
    'viewValue': 'SLUDERNO .SCHLUDERNS. (BZ)'
  },
  {
    'value': 'I772',
    'viewValue': 'SMARANO (TN)'
  },
  {
    'value': 'I774',
    'viewValue': 'SMERILLO (AP)'
  },
  {
    'value': 'I775',
    'viewValue': 'SOAVE (VR)'
  },
  {
    'value': 'I777',
    'viewValue': 'SOCCHIEVE (UD)'
  },
  {
    'value': 'I778',
    'viewValue': "SODDI' (OR)"
  },
  {
    'value': 'I779',
    'viewValue': 'SOGLIANO AL RUBICONE (FC)'
  },
  {
    'value': 'I780',
    'viewValue': 'SOGLIANO CAVOUR (LE)'
  },
  {
    'value': 'I781',
    'viewValue': 'SOGLIO (AT)'
  },
  {
    'value': 'I782',
    'viewValue': 'SOIANO DEL LAGO (BS)'
  },
  {
    'value': 'I783',
    'viewValue': 'SOLAGNA (VI)'
  },
  {
    'value': 'I785',
    'viewValue': 'SOLARINO (SR)'
  },
  {
    'value': 'I786',
    'viewValue': 'SOLARO (MI)'
  },
  {
    'value': 'I787',
    'viewValue': 'SOLAROLO (RA)'
  },
  {
    'value': 'I790',
    'viewValue': 'SOLAROLO RAINERIO (CR)'
  },
  {
    'value': 'I791',
    'viewValue': 'SOLARUSSA (OR)'
  },
  {
    'value': 'I792',
    'viewValue': 'SOLBIATE (CO)'
  },
  {
    'value': 'I793',
    'viewValue': 'SOLBIATE ARNO (VA)'
  },
  {
    'value': 'I794',
    'viewValue': 'SOLBIATE OLONA (VA)'
  },
  {
    'value': 'I796',
    'viewValue': 'SOLDANO (IM)'
  },
  {
    'value': 'I797',
    'viewValue': 'SOLEMINIS (CA)'
  },
  {
    'value': 'I798',
    'viewValue': 'SOLERO (AL)'
  },
  {
    'value': 'I799',
    'viewValue': 'SOLESINO (PD)'
  },
  {
    'value': 'I800',
    'viewValue': 'SOLETO (LE)'
  },
  {
    'value': 'I801',
    'viewValue': 'SOLFERINO (MN)'
  },
  {
    'value': 'I802',
    'viewValue': 'SOLIERA (MO)'
  },
  {
    'value': 'I803',
    'viewValue': 'SOLIGNANO (PR)'
  },
  {
    'value': 'I805',
    'viewValue': 'SOLOFRA (AV)'
  },
  {
    'value': 'I808',
    'viewValue': 'SOLONGHELLO (AL)'
  },
  {
    'value': 'I809',
    'viewValue': 'SOLOPACA (BN)'
  },
  {
    'value': 'I812',
    'viewValue': 'SOLTO COLLINA (BG)'
  },
  {
    'value': 'I813',
    'viewValue': 'SOLZA (BG)'
  },
  {
    'value': 'I815',
    'viewValue': 'SOMAGLIA (LO)'
  },
  {
    'value': 'I817',
    'viewValue': 'SOMANO (CN)'
  },
  {
    'value': 'I819',
    'viewValue': 'SOMMA LOMBARDO (VA)'
  },
  {
    'value': 'I820',
    'viewValue': 'SOMMA VESUVIANA (NA)'
  },
  {
    'value': 'I821',
    'viewValue': 'SOMMACAMPAGNA (VR)'
  },
  {
    'value': 'I822',
    'viewValue': 'SOMMARIVA DEL BOSCO (CN)'
  },
  {
    'value': 'I823',
    'viewValue': 'SOMMARIVA PERNO (CN)'
  },
  {
    'value': 'I824',
    'viewValue': 'SOMMATINO (CL)'
  },
  {
    'value': 'I825',
    'viewValue': 'SOMMO (PV)'
  },
  {
    'value': 'I826',
    'viewValue': 'SONA (VR)'
  },
  {
    'value': 'I827',
    'viewValue': 'SONCINO (CR)'
  },
  {
    'value': 'I828',
    'viewValue': 'SONDALO (SO)'
  },
  {
    'value': 'I829',
    'viewValue': 'SONDRIO (SO)'
  },
  {
    'value': 'I830',
    'viewValue': 'SONGAVAZZO (BG)'
  },
  {
    'value': 'I831',
    'viewValue': 'SONICO (BS)'
  },
  {
    'value': 'I832',
    'viewValue': 'SONNINO (LT)'
  },
  {
    'value': 'I835',
    'viewValue': 'SOPRANA (BI)'
  },
  {
    'value': 'I838',
    'viewValue': 'SORA (FR)'
  },
  {
    'value': 'I839',
    'viewValue': 'SORAGA (TN)'
  },
  {
    'value': 'I840',
    'viewValue': 'SORAGNA (PR)'
  },
  {
    'value': 'I841',
    'viewValue': 'SORANO (GR)'
  },
  {
    'value': 'I844',
    'viewValue': 'SORBO SAN BASILE (CZ)'
  },
  {
    'value': 'I843',
    'viewValue': 'SORBO SERPICO (AV)'
  },
  {
    'value': 'I845',
    'viewValue': 'SORBOLO (PR)'
  },
  {
    'value': 'I847',
    'viewValue': 'SORDEVOLO (BI)'
  },
  {
    'value': 'I848',
    'viewValue': 'SORDIO (LO)'
  },
  {
    'value': 'I849',
    'viewValue': 'SORESINA (CR)'
  },
  {
    'value': 'I850',
    'viewValue': "SORGA' (VR)"
  },
  {
    'value': 'I851',
    'viewValue': 'SORGONO (NU)'
  },
  {
    'value': 'I852',
    'viewValue': 'SORI (GE)'
  },
  {
    'value': 'I853',
    'viewValue': 'SORIANELLO (VV)'
  },
  {
    'value': 'I854',
    'viewValue': 'SORIANO CALABRO (VV)'
  },
  {
    'value': 'I855',
    'viewValue': 'SORIANO NEL CIMINO (VT)'
  },
  {
    'value': 'I856',
    'viewValue': 'SORICO (CO)'
  },
  {
    'value': 'I857',
    'viewValue': 'SORISO (NO)'
  },
  {
    'value': 'I858',
    'viewValue': 'SORISOLE (BG)'
  },
  {
    'value': 'I860',
    'viewValue': 'SORMANO (CO)'
  },
  {
    'value': 'I861',
    'viewValue': 'SORRADILE (OR)'
  },
  {
    'value': 'I862',
    'viewValue': 'SORRENTO (NA)'
  },
  {
    'value': 'I863',
    'viewValue': 'SORSO (SS)'
  },
  {
    'value': 'I864',
    'viewValue': 'SORTINO (SR)'
  },
  {
    'value': 'I865',
    'viewValue': 'SOSPIRO (CR)'
  },
  {
    'value': 'I866',
    'viewValue': 'SOSPIROLO (BL)'
  },
  {
    'value': 'I867',
    'viewValue': 'SOSSANO (VI)'
  },
  {
    'value': 'I868',
    'viewValue': 'SOSTEGNO (BI)'
  },
  {
    'value': 'I869',
    'viewValue': 'SOTTO IL MONTE GIOVANNI XXIII (BG)'
  },
  {
    'value': 'I871',
    'viewValue': 'SOVER (TN)'
  },
  {
    'value': 'I872',
    'viewValue': 'SOVERATO (CZ)'
  },
  {
    'value': 'I873',
    'viewValue': 'SOVERE (BG)'
  },
  {
    'value': 'I874',
    'viewValue': 'SOVERIA MANNELLI (CZ)'
  },
  {
    'value': 'I875',
    'viewValue': 'SOVERIA SIMERI (CZ)'
  },
  {
    'value': 'I876',
    'viewValue': 'SOVERZENE (BL)'
  },
  {
    'value': 'I877',
    'viewValue': 'SOVICILLE (SI)'
  },
  {
    'value': 'I878',
    'viewValue': 'SOVICO (MI)'
  },
  {
    'value': 'I879',
    'viewValue': 'SOVIZZO (VI)'
  },
  {
    'value': 'I673',
    'viewValue': 'SOVRAMONTE (BL)'
  },
  {
    'value': 'I880',
    'viewValue': 'SOZZAGO (NO)'
  },
  {
    'value': 'I881',
    'viewValue': 'SPADAFORA (ME)'
  },
  {
    'value': 'I884',
    'viewValue': 'SPADOLA (VV)'
  },
  {
    'value': 'I885',
    'viewValue': 'SPARANISE (CE)'
  },
  {
    'value': 'I886',
    'viewValue': 'SPARONE (TO)'
  },
  {
    'value': 'I887',
    'viewValue': 'SPECCHIA (LE)'
  },
  {
    'value': 'I888',
    'viewValue': 'SPELLO (PG)'
  },
  {
    'value': 'I889',
    'viewValue': 'SPERA (TN)'
  },
  {
    'value': 'I891',
    'viewValue': 'SPERLINGA (EN)'
  },
  {
    'value': 'I892',
    'viewValue': 'SPERLONGA (LT)'
  },
  {
    'value': 'I893',
    'viewValue': 'SPERONE (AV)'
  },
  {
    'value': 'I894',
    'viewValue': 'SPESSA (PV)'
  },
  {
    'value': 'I895',
    'viewValue': 'SPEZZANO ALBANESE (CS)'
  },
  {
    'value': 'I896',
    'viewValue': 'SPEZZANO DELLA SILA (CS)'
  },
  {
    'value': 'I898',
    'viewValue': 'SPEZZANO PICCOLO (CS)'
  },
  {
    'value': 'I899',
    'viewValue': 'SPIAZZO (TN)'
  },
  {
    'value': 'I901',
    'viewValue': 'SPIGNO MONFERRATO (AL)'
  },
  {
    'value': 'I902',
    'viewValue': 'SPIGNO SATURNIA (LT)'
  },
  {
    'value': 'I903',
    'viewValue': 'SPILAMBERTO (MO)'
  },
  {
    'value': 'I904',
    'viewValue': 'SPILIMBERGO (PN)'
  },
  {
    'value': 'I905',
    'viewValue': 'SPILINGA (VV)'
  },
  {
    'value': 'I906',
    'viewValue': 'SPINADESCO (CR)'
  },
  {
    'value': 'I907',
    'viewValue': 'SPINAZZOLA (BA)'
  },
  {
    'value': 'I908',
    'viewValue': 'SPINEA (VE)'
  },
  {
    'value': 'I909',
    'viewValue': 'SPINEDA (CR)'
  },
  {
    'value': 'I910',
    'viewValue': 'SPINETE (CB)'
  },
  {
    'value': 'I911',
    'viewValue': 'SPINETO SCRIVIA (AL)'
  },
  {
    'value': 'I912',
    'viewValue': 'SPINETOLI (AP)'
  },
  {
    'value': 'I914',
    'viewValue': "SPINO D'ADDA (CR)"
  },
  {
    'value': 'I916',
    'viewValue': 'SPINONE AL LAGO (BG)'
  },
  {
    'value': 'I917',
    'viewValue': 'SPINOSO (PZ)'
  },
  {
    'value': 'I919',
    'viewValue': 'SPIRANO (BG)'
  },
  {
    'value': 'I921',
    'viewValue': 'SPOLETO (PG)'
  },
  {
    'value': 'I922',
    'viewValue': 'SPOLTORE (PE)'
  },
  {
    'value': 'I923',
    'viewValue': 'SPONGANO (LE)'
  },
  {
    'value': 'I924',
    'viewValue': 'SPORMAGGIORE (TN)'
  },
  {
    'value': 'I925',
    'viewValue': 'SPORMINORE (TN)'
  },
  {
    'value': 'I926',
    'viewValue': 'SPOTORNO (SV)'
  },
  {
    'value': 'I927',
    'viewValue': 'SPRESIANO (TV)'
  },
  {
    'value': 'I928',
    'viewValue': 'SPRIANA (SO)'
  },
  {
    'value': 'I929',
    'viewValue': 'SQUILLACE (CZ)'
  },
  {
    'value': 'I930',
    'viewValue': 'SQUINZANO (LE)'
  },
  {
    'value': 'I932',
    'viewValue': 'STAFFOLO (AN)'
  },
  {
    'value': 'I935',
    'viewValue': 'STAGNO LOMBARDO (CR)'
  },
  {
    'value': 'I936',
    'viewValue': 'STAITI (RC)'
  },
  {
    'value': 'I937',
    'viewValue': 'STALETTI (CZ)'
  },
  {
    'value': 'I938',
    'viewValue': 'STANGHELLA (PD)'
  },
  {
    'value': 'I939',
    'viewValue': 'STARANZANO (GO)'
  },
  {
    'value': 'M298',
    'viewValue': 'STATTE (TA)'
  },
  {
    'value': 'I941',
    'viewValue': 'STAZZANO (AL)'
  },
  {
    'value': 'I942',
    'viewValue': 'STAZZEMA (LU)'
  },
  {
    'value': 'I943',
    'viewValue': 'STAZZONA (CO)'
  },
  {
    'value': 'I945',
    'viewValue': 'STEFANACONI (VV)'
  },
  {
    'value': 'I946',
    'viewValue': 'STELLA (SV)'
  },
  {
    'value': 'G887',
    'viewValue': 'STELLA CILENTO (SA)'
  },
  {
    'value': 'I947',
    'viewValue': 'STELLANELLO (SV)'
  },
  {
    'value': 'I948',
    'viewValue': 'STELVIO .STILFS. (BZ)'
  },
  {
    'value': 'I949',
    'viewValue': 'STENICO (TN)'
  },
  {
    'value': 'I950',
    'viewValue': 'STERNATIA (LE)'
  },
  {
    'value': 'I951',
    'viewValue': 'STEZZANO (BG)'
  },
  {
    'value': 'I952',
    'viewValue': 'STIA (AR)'
  },
  {
    'value': 'I953',
    'viewValue': 'STIENTA (RO)'
  },
  {
    'value': 'I954',
    'viewValue': 'STIGLIANO (MT)'
  },
  {
    'value': 'I955',
    'viewValue': 'STIGNANO (RC)'
  },
  {
    'value': 'I956',
    'viewValue': 'STILO (RC)'
  },
  {
    'value': 'I959',
    'viewValue': 'STIMIGLIANO (RI)'
  },
  {
    'value': 'M290',
    'viewValue': 'STINTINO (SS)'
  },
  {
    'value': 'I960',
    'viewValue': 'STIO (SA)'
  },
  {
    'value': 'I962',
    'viewValue': 'STORNARA (FG)'
  },
  {
    'value': 'I963',
    'viewValue': 'STORNARELLA (FG)'
  },
  {
    'value': 'I964',
    'viewValue': 'STORO (TN)'
  },
  {
    'value': 'I965',
    'viewValue': 'STRA (VE)'
  },
  {
    'value': 'I968',
    'viewValue': 'STRADELLA (PV)'
  },
  {
    'value': 'I969',
    'viewValue': 'STRAMBINELLO (TO)'
  },
  {
    'value': 'I970',
    'viewValue': 'STRAMBINO (TO)'
  },
  {
    'value': 'I973',
    'viewValue': 'STRANGOLAGALLI (FR)'
  },
  {
    'value': 'I974',
    'viewValue': 'STREGNA (UD)'
  },
  {
    'value': 'I975',
    'viewValue': 'STREMBO (TN)'
  },
  {
    'value': 'I976',
    'viewValue': 'STRESA (VB)'
  },
  {
    'value': 'I977',
    'viewValue': 'STREVI (AL)'
  },
  {
    'value': 'I978',
    'viewValue': 'STRIANO (NA)'
  },
  {
    'value': 'I979',
    'viewValue': 'STRIGNO (TN)'
  },
  {
    'value': 'I980',
    'viewValue': 'STRONA (BI)'
  },
  {
    'value': 'I981',
    'viewValue': 'STRONCONE (TR)'
  },
  {
    'value': 'I982',
    'viewValue': 'STRONGOLI (KR)'
  },
  {
    'value': 'I984',
    'viewValue': 'STROPPIANA (VC)'
  },
  {
    'value': 'I985',
    'viewValue': 'STROPPO (CN)'
  },
  {
    'value': 'I986',
    'viewValue': 'STROZZA (BG)'
  },
  {
    'value': 'I990',
    'viewValue': 'STURNO (AV)'
  },
  {
    'value': 'B014',
    'viewValue': 'SUARDI (PV)'
  },
  {
    'value': 'I991',
    'viewValue': 'SUBBIANO (AR)'
  },
  {
    'value': 'I992',
    'viewValue': 'SUBIACO (RM)'
  },
  {
    'value': 'I993',
    'viewValue': 'SUCCIVO (CE)'
  },
  {
    'value': 'I994',
    'viewValue': 'SUEGLIO (LC)'
  },
  {
    'value': 'I995',
    'viewValue': 'SUELLI (CA)'
  },
  {
    'value': 'I996',
    'viewValue': 'SUELLO (LC)'
  },
  {
    'value': 'I997',
    'viewValue': 'SUISIO (BG)'
  },
  {
    'value': 'I998',
    'viewValue': 'SULBIATE (MI)'
  },
  {
    'value': 'I804',
    'viewValue': 'SULMONA (AQ)'
  },
  {
    'value': 'L002',
    'viewValue': 'SULZANO (BS)'
  },
  {
    'value': 'L003',
    'viewValue': 'SUMIRAGO (VA)'
  },
  {
    'value': 'L004',
    'viewValue': 'SUMMONTE (AV)'
  },
  {
    'value': 'L006',
    'viewValue': 'SUNI (NU)'
  },
  {
    'value': 'L007',
    'viewValue': 'SUNO (NO)'
  },
  {
    'value': 'L008',
    'viewValue': 'SUPERSANO (LE)'
  },
  {
    'value': 'L009',
    'viewValue': 'SUPINO (FR)'
  },
  {
    'value': 'L010',
    'viewValue': 'SURANO (LE)'
  },
  {
    'value': 'L011',
    'viewValue': 'SURBO (LE)'
  },
  {
    'value': 'L013',
    'viewValue': 'SUSA (TO)'
  },
  {
    'value': 'L014',
    'viewValue': 'SUSEGANA (TV)'
  },
  {
    'value': 'L015',
    'viewValue': 'SUSTINENTE (MN)'
  },
  {
    'value': 'L016',
    'viewValue': 'SUTERA (CL)'
  },
  {
    'value': 'L017',
    'viewValue': 'SUTRI (VT)'
  },
  {
    'value': 'L018',
    'viewValue': 'SUTRIO (UD)'
  },
  {
    'value': 'L019',
    'viewValue': 'SUVERETO (LI)'
  },
  {
    'value': 'L020',
    'viewValue': 'SUZZARA (MN)'
  },
  {
    'value': 'L022',
    'viewValue': 'TACENO (LC)'
  },
  {
    'value': 'L023',
    'viewValue': 'TADASUNI (OR)'
  },
  {
    'value': 'L024',
    'viewValue': 'TAGGIA (IM)'
  },
  {
    'value': 'L025',
    'viewValue': 'TAGLIACOZZO (AQ)'
  },
  {
    'value': 'L026',
    'viewValue': 'TAGLIO DI PO (RO)'
  },
  {
    'value': 'L027',
    'viewValue': 'TAGLIOLO MONFERRATO (AL)'
  },
  {
    'value': 'L030',
    'viewValue': 'TAIBON AGORDINO (BL)'
  },
  {
    'value': 'L032',
    'viewValue': 'TAINO (VA)'
  },
  {
    'value': 'L033',
    'viewValue': 'TAIO (TN)'
  },
  {
    'value': 'G736',
    'viewValue': 'TAIPANA (UD)'
  },
  {
    'value': 'L034',
    'viewValue': 'TALAMELLO (PU)'
  },
  {
    'value': 'L035',
    'viewValue': 'TALAMONA (SO)'
  },
  {
    'value': 'L036',
    'viewValue': 'TALANA (NU)'
  },
  {
    'value': 'L037',
    'viewValue': 'TALEGGIO (BG)'
  },
  {
    'value': 'L038',
    'viewValue': 'TALLA (AR)'
  },
  {
    'value': 'L039',
    'viewValue': 'TALMASSONS (UD)'
  },
  {
    'value': 'L040',
    'viewValue': 'TAMBRE (BL)'
  },
  {
    'value': 'L042',
    'viewValue': 'TAORMINA (ME)'
  },
  {
    'value': 'L044',
    'viewValue': 'TAPOGLIANO (UD)'
  },
  {
    'value': 'L046',
    'viewValue': 'TARANO (RI)'
  },
  {
    'value': 'L047',
    'viewValue': 'TARANTA PELIGNA (CH)'
  },
  {
    'value': 'L048',
    'viewValue': 'TARANTASCA (CN)'
  },
  {
    'value': 'L049',
    'viewValue': 'TARANTO (TA)'
  },
  {
    'value': 'L050',
    'viewValue': 'TARCENTO (UD)'
  },
  {
    'value': 'D024',
    'viewValue': 'TARQUINIA (VT)'
  },
  {
    'value': 'L055',
    'viewValue': 'TARSIA (CS)'
  },
  {
    'value': 'L056',
    'viewValue': 'TARTANO (SO)'
  },
  {
    'value': 'L057',
    'viewValue': 'TARVISIO (UD)'
  },
  {
    'value': 'L058',
    'viewValue': 'TARZO (TV)'
  },
  {
    'value': 'L059',
    'viewValue': 'TASSAROLO (AL)'
  },
  {
    'value': 'L060',
    'viewValue': 'TASSULLO (TN)'
  },
  {
    'value': 'L061',
    'viewValue': 'TAURANO (AV)'
  },
  {
    'value': 'L062',
    'viewValue': 'TAURASI (AV)'
  },
  {
    'value': 'L063',
    'viewValue': 'TAURIANOVA (RC)'
  },
  {
    'value': 'L064',
    'viewValue': 'TAURISANO (LE)'
  },
  {
    'value': 'L065',
    'viewValue': 'TAVAGNACCO (UD)'
  },
  {
    'value': 'L066',
    'viewValue': 'TAVAGNASCO (TO)'
  },
  {
    'value': 'L067',
    'viewValue': 'TAVARNELLE VAL DI PESA (FI)'
  },
  {
    'value': 'F260',
    'viewValue': 'TAVAZZANO CON VILLAVESCO (LO)'
  },
  {
    'value': 'L069',
    'viewValue': 'TAVENNA (CB)'
  },
  {
    'value': 'L070',
    'viewValue': 'TAVERNA (CZ)'
  },
  {
    'value': 'L071',
    'viewValue': 'TAVERNERIO (CO)'
  },
  {
    'value': 'L073',
    'viewValue': 'TAVERNOLA BERGAMASCA (BG)'
  },
  {
    'value': 'C698',
    'viewValue': 'TAVERNOLE SUL MELLA (BS)'
  },
  {
    'value': 'L074',
    'viewValue': 'TAVIANO (LE)'
  },
  {
    'value': 'L075',
    'viewValue': 'TAVIGLIANO (BI)'
  },
  {
    'value': 'L078',
    'viewValue': 'TAVOLETO (PU)'
  },
  {
    'value': 'L081',
    'viewValue': 'TAVULLIA (PU)'
  },
  {
    'value': 'L082',
    'viewValue': 'TEANA (PZ)'
  },
  {
    'value': 'L083',
    'viewValue': 'TEANO (CE)'
  },
  {
    'value': 'D292',
    'viewValue': 'TEGGIANO (SA)'
  },
  {
    'value': 'L084',
    'viewValue': 'TEGLIO (SO)'
  },
  {
    'value': 'L085',
    'viewValue': 'TEGLIO VENETO (VE)'
  },
  {
    'value': 'L086',
    'viewValue': 'TELESE TERME (BN)'
  },
  {
    'value': 'L087',
    'viewValue': 'TELGATE (BG)'
  },
  {
    'value': 'L088',
    'viewValue': 'TELTI (SS)'
  },
  {
    'value': 'L089',
    'viewValue': 'TELVE (TN)'
  },
  {
    'value': 'L090',
    'viewValue': 'TELVE DI SOPRA (TN)'
  },
  {
    'value': 'L093',
    'viewValue': 'TEMPIO PAUSANIA (SS)'
  },
  {
    'value': 'L094',
    'viewValue': "TEMU' (BS)"
  },
  {
    'value': 'L096',
    'viewValue': 'TENNA (TN)'
  },
  {
    'value': 'L097',
    'viewValue': 'TENNO (TN)'
  },
  {
    'value': 'L100',
    'viewValue': 'TEOLO (PD)'
  },
  {
    'value': 'L101',
    'viewValue': 'TEOR (UD)'
  },
  {
    'value': 'L102',
    'viewValue': 'TEORA (AV)'
  },
  {
    'value': 'L103',
    'viewValue': 'TERAMO (TE)'
  },
  {
    'value': 'L104',
    'viewValue': 'TERDOBBIATE (NO)'
  },
  {
    'value': 'L105',
    'viewValue': 'TERELLE (FR)'
  },
  {
    'value': 'L106',
    'viewValue': 'TERENTO .TERENTEN. (BZ)'
  },
  {
    'value': 'E548',
    'viewValue': 'TERENZO (PR)'
  },
  {
    'value': 'M282',
    'viewValue': 'TERGU (SS)'
  },
  {
    'value': 'L107',
    'viewValue': 'TERLAGO (TN)'
  },
  {
    'value': 'L108',
    'viewValue': 'TERLANO .TERLAN. (BZ)'
  },
  {
    'value': 'L109',
    'viewValue': 'TERLIZZI (BA)'
  },
  {
    'value': 'M210',
    'viewValue': 'TERME VIGLIATORE (ME)'
  },
  {
    'value': 'L111',
    'viewValue': 'TERMENO SULLA STRADA DEL VINO .TRAMIN AN DER. (BZ)'
  },
  {
    'value': 'L112',
    'viewValue': 'TERMINI IMERESE (PA)'
  },
  {
    'value': 'L113',
    'viewValue': 'TERMOLI (CB)'
  },
  {
    'value': 'L115',
    'viewValue': 'TERNATE (VA)'
  },
  {
    'value': 'L116',
    'viewValue': 'TERNENGO (BI)'
  },
  {
    'value': 'L117',
    'viewValue': 'TERNI (TR)'
  },
  {
    'value': 'L118',
    'viewValue': "TERNO D'ISOLA (BG)"
  },
  {
    'value': 'L120',
    'viewValue': 'TERRACINA (LT)'
  },
  {
    'value': 'L121',
    'viewValue': 'TERRAGNOLO (TN)'
  },
  {
    'value': 'L122',
    'viewValue': 'TERRALBA (OR)'
  },
  {
    'value': 'L124',
    'viewValue': 'TERRANOVA DA SIBARI (CS)'
  },
  {
    'value': 'L125',
    'viewValue': 'TERRANOVA DEI PASSERINI (LO)'
  },
  {
    'value': 'L126',
    'viewValue': 'TERRANOVA DI POLLINO (PZ)'
  },
  {
    'value': 'L127',
    'viewValue': 'TERRANOVA SAPPO MINULIO (RC)'
  },
  {
    'value': 'L123',
    'viewValue': 'TERRANUOVA BRACCIOLINI (AR)'
  },
  {
    'value': 'L131',
    'viewValue': 'TERRASINI (PA)'
  },
  {
    'value': 'L132',
    'viewValue': 'TERRASSA PADOVANA (PD)'
  },
  {
    'value': 'L134',
    'viewValue': 'TERRAVECCHIA (CS)'
  },
  {
    'value': 'L136',
    'viewValue': 'TERRAZZO (VR)'
  },
  {
    'value': 'L137',
    'viewValue': 'TERRES (TN)'
  },
  {
    'value': 'L138',
    'viewValue': 'TERRICCIOLA (PI)'
  },
  {
    'value': 'L139',
    'viewValue': 'TERRUGGIA (AL)'
  },
  {
    'value': 'L140',
    'viewValue': 'TERTENIA (NU)'
  },
  {
    'value': 'L142',
    'viewValue': 'TERZIGNO (NA)'
  },
  {
    'value': 'L143',
    'viewValue': 'TERZO (AL)'
  },
  {
    'value': 'L144',
    'viewValue': 'TERZO DI AQUILEIA (UD)'
  },
  {
    'value': 'L145',
    'viewValue': 'TERZOLAS (TN)'
  },
  {
    'value': 'L146',
    'viewValue': 'TERZORIO (IM)'
  },
  {
    'value': 'L147',
    'viewValue': 'TESERO (TN)'
  },
  {
    'value': 'L149',
    'viewValue': 'TESIMO .TISENS. (BZ)'
  },
  {
    'value': 'L150',
    'viewValue': 'TESSENNANO (VT)'
  },
  {
    'value': 'L152',
    'viewValue': 'TESTICO (SV)'
  },
  {
    'value': 'L153',
    'viewValue': 'TETI (NU)'
  },
  {
    'value': 'L154',
    'viewValue': 'TEULADA (CA)'
  },
  {
    'value': 'L155',
    'viewValue': 'TEVEROLA (CE)'
  },
  {
    'value': 'L156',
    'viewValue': 'TEZZE SUL BRENTA (VI)'
  },
  {
    'value': 'L157',
    'viewValue': 'THIENE (VI)'
  },
  {
    'value': 'L158',
    'viewValue': 'THIESI (SS)'
  },
  {
    'value': 'L160',
    'viewValue': 'TIANA (NU)'
  },
  {
    'value': 'L162',
    'viewValue': 'TIARNO DI SOPRA (TN)'
  },
  {
    'value': 'L163',
    'viewValue': 'TIARNO DI SOTTO (TN)'
  },
  {
    'value': 'L164',
    'viewValue': 'TICENGO (CR)'
  },
  {
    'value': 'L165',
    'viewValue': 'TICINETO (AL)'
  },
  {
    'value': 'L166',
    'viewValue': 'TIGGIANO (LE)'
  },
  {
    'value': 'L167',
    'viewValue': 'TIGLIETO (GE)'
  },
  {
    'value': 'L168',
    'viewValue': 'TIGLIOLE (AT)'
  },
  {
    'value': 'L169',
    'viewValue': 'TIGNALE (BS)'
  },
  {
    'value': 'L172',
    'viewValue': 'TINNURA (NU)'
  },
  {
    'value': 'L173',
    'viewValue': 'TIONE DEGLI ABRUZZI (AQ)'
  },
  {
    'value': 'L174',
    'viewValue': 'TIONE DI TRENTO (TN)'
  },
  {
    'value': 'L175',
    'viewValue': 'TIRANO (SO)'
  },
  {
    'value': 'L176',
    'viewValue': 'TIRES .TIERS. (BZ)'
  },
  {
    'value': 'L177',
    'viewValue': 'TIRIOLO (CZ)'
  },
  {
    'value': 'L178',
    'viewValue': 'TIROLO .TIROL. (BZ)'
  },
  {
    'value': 'L180',
    'viewValue': 'TISSI (SS)'
  },
  {
    'value': 'L181',
    'viewValue': 'TITO (PZ)'
  },
  {
    'value': 'L182',
    'viewValue': 'TIVOLI (RM)'
  },
  {
    'value': 'L183',
    'viewValue': 'TIZZANO VAL PARMA (PR)'
  },
  {
    'value': 'L184',
    'viewValue': 'TOANO (RE)'
  },
  {
    'value': 'L185',
    'viewValue': 'TOCCO CAUDIO (BN)'
  },
  {
    'value': 'L186',
    'viewValue': 'TOCCO DA CASAURIA (PE)'
  },
  {
    'value': 'L187',
    'viewValue': 'TOCENO (VB)'
  },
  {
    'value': 'L188',
    'viewValue': 'TODI (PG)'
  },
  {
    'value': 'L189',
    'viewValue': 'TOFFIA (RI)'
  },
  {
    'value': 'L190',
    'viewValue': 'TOIRANO (SV)'
  },
  {
    'value': 'L191',
    'viewValue': 'TOLENTINO (MC)'
  },
  {
    'value': 'L192',
    'viewValue': 'TOLFA (RM)'
  },
  {
    'value': 'L193',
    'viewValue': 'TOLLEGNO (BI)'
  },
  {
    'value': 'L194',
    'viewValue': 'TOLLO (CH)'
  },
  {
    'value': 'L195',
    'viewValue': 'TOLMEZZO (UD)'
  },
  {
    'value': 'L196',
    'viewValue': 'TOLMINO (GO)'
  },
  {
    'value': 'L197',
    'viewValue': 'TOLVE (PZ)'
  },
  {
    'value': 'L199',
    'viewValue': 'TOMBOLO (PD)'
  },
  {
    'value': 'L200',
    'viewValue': 'TON (TN)'
  },
  {
    'value': 'L201',
    'viewValue': 'TONADICO (TN)'
  },
  {
    'value': 'L202',
    'viewValue': 'TONARA (NU)'
  },
  {
    'value': 'L203',
    'viewValue': 'TONCO (AT)'
  },
  {
    'value': 'L204',
    'viewValue': 'TONENGO (AT)'
  },
  {
    'value': 'D717',
    'viewValue': 'TONEZZA DEL CIMONE (VI)'
  },
  {
    'value': 'L205',
    'viewValue': 'TORA E PICCILLI (CE)'
  },
  {
    'value': 'L206',
    'viewValue': 'TORANO CASTELLO (CS)'
  },
  {
    'value': 'L207',
    'viewValue': 'TORANO NUOVO (TE)'
  },
  {
    'value': 'L210',
    'viewValue': 'TORBOLE CASAGLIA (BS)'
  },
  {
    'value': 'L211',
    'viewValue': 'TORCEGNO (TN)'
  },
  {
    'value': 'L212',
    'viewValue': 'TORCHIARA (SA)'
  },
  {
    'value': 'L213',
    'viewValue': 'TORCHIAROLO (BR)'
  },
  {
    'value': 'L214',
    'viewValue': 'TORELLA DEI LOMBARDI (AV)'
  },
  {
    'value': 'L215',
    'viewValue': 'TORELLA DEL SANNIO (CB)'
  },
  {
    'value': 'L216',
    'viewValue': 'TORGIANO (PG)'
  },
  {
    'value': 'L217',
    'viewValue': 'TORGNON (AO)'
  },
  {
    'value': 'L219',
    'viewValue': 'TORINO (TO)'
  },
  {
    'value': 'L218',
    'viewValue': 'TORINO DI SANGRO (CH)'
  },
  {
    'value': 'L220',
    'viewValue': 'TORITTO (BA)'
  },
  {
    'value': 'L221',
    'viewValue': 'TORLINO VIMERCATI (CR)'
  },
  {
    'value': 'L223',
    'viewValue': 'TORNACO (NO)'
  },
  {
    'value': 'L224',
    'viewValue': 'TORNARECCIO (CH)'
  },
  {
    'value': 'L225',
    'viewValue': 'TORNATA (CR)'
  },
  {
    'value': 'L227',
    'viewValue': 'TORNIMPARTE (AQ)'
  },
  {
    'value': 'L228',
    'viewValue': 'TORNO (CO)'
  },
  {
    'value': 'L229',
    'viewValue': 'TORNOLO (PR)'
  },
  {
    'value': 'L230',
    'viewValue': 'TORO (CB)'
  },
  {
    'value': 'L231',
    'viewValue': "TORPE' (NU)"
  },
  {
    'value': 'L233',
    'viewValue': 'TORRACA (SA)'
  },
  {
    'value': 'L235',
    'viewValue': 'TORRALBA (SS)'
  },
  {
    'value': 'L237',
    'viewValue': 'TORRAZZA COSTE (PV)'
  },
  {
    'value': 'L238',
    'viewValue': 'TORRAZZA PIEMONTE (TO)'
  },
  {
    'value': 'L239',
    'viewValue': 'TORRAZZO (BI)'
  },
  {
    'value': 'L245',
    'viewValue': 'TORRE ANNUNZIATA (NA)'
  },
  {
    'value': 'L250',
    'viewValue': 'TORRE BERETTI E CASTELLARO (PV)'
  },
  {
    'value': 'L251',
    'viewValue': 'TORRE BOLDONE (BG)'
  },
  {
    'value': 'L252',
    'viewValue': 'TORRE BORMIDA (CN)'
  },
  {
    'value': 'L243',
    'viewValue': 'TORRE CAJETANI (FR)'
  },
  {
    'value': 'L247',
    'viewValue': 'TORRE CANAVESE (TO)'
  },
  {
    'value': 'L256',
    'viewValue': "TORRE D'ARESE (PV)"
  },
  {
    'value': 'L257',
    'viewValue': "TORRE DE' BUSI (LC)"
  },
  {
    'value': 'L262',
    'viewValue': "TORRE DE' NEGRI (PV)"
  },
  {
    'value': 'L263',
    'viewValue': "TORRE DE' PASSERI (PE)"
  },
  {
    'value': 'L258',
    'viewValue': "TORRE DE' PICENARDI (CR)"
  },
  {
    'value': 'L265',
    'viewValue': "TORRE DE' ROVERI (BG)"
  },
  {
    'value': 'L259',
    'viewValue': 'TORRE DEL GRECO (NA)'
  },
  {
    'value': 'L267',
    'viewValue': 'TORRE DI MOSTO (VE)'
  },
  {
    'value': 'L240',
    'viewValue': 'TORRE DI RUGGIERO (CZ)'
  },
  {
    'value': 'L244',
    'viewValue': 'TORRE DI SANTA MARIA (SO)'
  },
  {
    'value': 'L269',
    'viewValue': "TORRE D'ISOLA (PV)"
  },
  {
    'value': 'L272',
    'viewValue': 'TORRE LE NOCELLE (AV)'
  },
  {
    'value': 'L241',
    'viewValue': "TORRE MONDOVI' (CN)"
  },
  {
    'value': 'L274',
    'viewValue': 'TORRE ORSAIA (SA)'
  },
  {
    'value': 'L276',
    'viewValue': 'TORRE PALLAVICINA (BG)'
  },
  {
    'value': 'L277',
    'viewValue': 'TORRE PELLICE (TO)'
  },
  {
    'value': 'L278',
    'viewValue': 'TORRE SAN GIORGIO (CN)'
  },
  {
    'value': 'L279',
    'viewValue': 'TORRE SAN PATRIZIO (AP)'
  },
  {
    'value': 'L280',
    'viewValue': 'TORRE SANTA SUSANNA (BR)'
  },
  {
    'value': 'L246',
    'viewValue': 'TORREANO (UD)'
  },
  {
    'value': 'L248',
    'viewValue': 'TORREBELVICINO (VI)'
  },
  {
    'value': 'L253',
    'viewValue': 'TORREBRUNA (CH)'
  },
  {
    'value': 'L254',
    'viewValue': 'TORRECUSO (BN)'
  },
  {
    'value': 'L270',
    'viewValue': 'TORREGLIA (PD)'
  },
  {
    'value': 'L271',
    'viewValue': 'TORREGROTTA (ME)'
  },
  {
    'value': 'L273',
    'viewValue': 'TORREMAGGIORE (FG)'
  },
  {
    'value': 'M286',
    'viewValue': 'TORRENOVA (ME)'
  },
  {
    'value': 'L281',
    'viewValue': 'TORRESINA (CN)'
  },
  {
    'value': 'L282',
    'viewValue': 'TORRETTA (PA)'
  },
  {
    'value': 'L285',
    'viewValue': 'TORREVECCHIA PIA (PV)'
  },
  {
    'value': 'L284',
    'viewValue': 'TORREVECCHIA TEATINA (CH)'
  },
  {
    'value': 'L287',
    'viewValue': 'TORRI DEL BENACO (VR)'
  },
  {
    'value': 'L297',
    'viewValue': 'TORRI DI QUARTESOLO (VI)'
  },
  {
    'value': 'L286',
    'viewValue': 'TORRI IN SABINA (RI)'
  },
  {
    'value': 'I550',
    'viewValue': 'TORRIANA (RN)'
  },
  {
    'value': 'L290',
    'viewValue': 'TORRICE (FR)'
  },
  {
    'value': 'L294',
    'viewValue': 'TORRICELLA (TA)'
  },
  {
    'value': 'L296',
    'viewValue': 'TORRICELLA DEL PIZZO (CR)'
  },
  {
    'value': 'L293',
    'viewValue': 'TORRICELLA IN SABINA (RI)'
  },
  {
    'value': 'L291',
    'viewValue': 'TORRICELLA PELIGNA (CH)'
  },
  {
    'value': 'L295',
    'viewValue': 'TORRICELLA SICURA (TE)'
  },
  {
    'value': 'L292',
    'viewValue': 'TORRICELLA VERZATE (PV)'
  },
  {
    'value': 'L298',
    'viewValue': 'TORRIGLIA (GE)'
  },
  {
    'value': 'L299',
    'viewValue': 'TORRILE (PR)'
  },
  {
    'value': 'L301',
    'viewValue': 'TORRIONI (AV)'
  },
  {
    'value': 'L303',
    'viewValue': 'TORRITA DI SIENA (SI)'
  },
  {
    'value': 'L302',
    'viewValue': 'TORRITA TIBERINA (RM)'
  },
  {
    'value': 'A355',
    'viewValue': "TORTOLI' (NU)"
  },
  {
    'value': 'L304',
    'viewValue': 'TORTONA (AL)'
  },
  {
    'value': 'L305',
    'viewValue': 'TORTORA (CS)'
  },
  {
    'value': 'L306',
    'viewValue': 'TORTORELLA (SA)'
  },
  {
    'value': 'L307',
    'viewValue': 'TORTORETO (TE)'
  },
  {
    'value': 'L308',
    'viewValue': 'TORTORICI (ME)'
  },
  {
    'value': 'L309',
    'viewValue': 'TORVISCOSA (UD)'
  },
  {
    'value': 'L312',
    'viewValue': 'TOSCOLANO-MADERNO (BS)'
  },
  {
    'value': 'L314',
    'viewValue': 'TOSSICIA (TE)'
  },
  {
    'value': 'L316',
    'viewValue': "TOVO DI SANT'AGATA (SO)"
  },
  {
    'value': 'L315',
    'viewValue': 'TOVO SAN GIACOMO (SV)'
  },
  {
    'value': 'L317',
    'viewValue': 'TRABIA (PA)'
  },
  {
    'value': 'L319',
    'viewValue': 'TRADATE (VA)'
  },
  {
    'value': 'L321',
    'viewValue': 'TRAMATZA (OR)'
  },
  {
    'value': 'L322',
    'viewValue': 'TRAMBILENO (TN)'
  },
  {
    'value': 'L323',
    'viewValue': 'TRAMONTI (SA)'
  },
  {
    'value': 'L324',
    'viewValue': 'TRAMONTI DI SOPRA (PN)'
  },
  {
    'value': 'L325',
    'viewValue': 'TRAMONTI DI SOTTO (PN)'
  },
  {
    'value': 'L326',
    'viewValue': 'TRAMUTOLA (PZ)'
  },
  {
    'value': 'L327',
    'viewValue': 'TRANA (TO)'
  },
  {
    'value': 'L328',
    'viewValue': 'TRANI (BA)'
  },
  {
    'value': 'L329',
    'viewValue': 'TRANSACQUA (TN)'
  },
  {
    'value': 'L330',
    'viewValue': 'TRAONA (SO)'
  },
  {
    'value': 'L331',
    'viewValue': 'TRAPANI (TP)'
  },
  {
    'value': 'L332',
    'viewValue': 'TRAPPETO (PA)'
  },
  {
    'value': 'L333',
    'viewValue': 'TRAREGO VIGGIONA (VB)'
  },
  {
    'value': 'L334',
    'viewValue': 'TRASACCO (AQ)'
  },
  {
    'value': 'L335',
    'viewValue': 'TRASAGHIS (UD)'
  },
  {
    'value': 'L336',
    'viewValue': 'TRASQUERA (VB)'
  },
  {
    'value': 'L337',
    'viewValue': 'TRATALIAS (CA)'
  },
  {
    'value': 'L338',
    'viewValue': 'TRAUSELLA (TO)'
  },
  {
    'value': 'I236',
    'viewValue': "TRAVACO' SICCOMARIO (PV)"
  },
  {
    'value': 'L339',
    'viewValue': 'TRAVAGLIATO (BS)'
  },
  {
    'value': 'L342',
    'viewValue': 'TRAVEDONA-MONATE (VA)'
  },
  {
    'value': 'L345',
    'viewValue': 'TRAVERSELLA (TO)'
  },
  {
    'value': 'L346',
    'viewValue': 'TRAVERSETOLO (PR)'
  },
  {
    'value': 'L340',
    'viewValue': 'TRAVES (TO)'
  },
  {
    'value': 'L347',
    'viewValue': 'TRAVESIO (PN)'
  },
  {
    'value': 'L348',
    'viewValue': 'TRAVO (PC)'
  },
  {
    'value': 'L349',
    'viewValue': 'TREBASELEGHE (PD)'
  },
  {
    'value': 'L353',
    'viewValue': 'TREBISACCE (CS)'
  },
  {
    'value': 'L354',
    'viewValue': 'TRECASALI (PR)'
  },
  {
    'value': 'M280',
    'viewValue': 'TRECASE (NA)'
  },
  {
    'value': 'L355',
    'viewValue': 'TRECASTAGNI (CT)'
  },
  {
    'value': 'L356',
    'viewValue': 'TRECATE (NO)'
  },
  {
    'value': 'L357',
    'viewValue': 'TRECCHINA (PZ)'
  },
  {
    'value': 'L359',
    'viewValue': 'TRECENTA (RO)'
  },
  {
    'value': 'L361',
    'viewValue': 'TREDOZIO (FC)'
  },
  {
    'value': 'L363',
    'viewValue': 'TREGLIO (CH)'
  },
  {
    'value': 'L364',
    'viewValue': 'TREGNAGO (VR)'
  },
  {
    'value': 'L366',
    'viewValue': 'TREIA (MC)'
  },
  {
    'value': 'L367',
    'viewValue': 'TREISO (CN)'
  },
  {
    'value': 'L368',
    'viewValue': 'TREMENICO (LC)'
  },
  {
    'value': 'L369',
    'viewValue': 'TREMESTIERI ETNEO (CT)'
  },
  {
    'value': 'L371',
    'viewValue': 'TREMEZZO (CO)'
  },
  {
    'value': 'L372',
    'viewValue': 'TREMOSINE (BS)'
  },
  {
    'value': 'L375',
    'viewValue': 'TRENTA (CS)'
  },
  {
    'value': 'L377',
    'viewValue': 'TRENTINARA (SA)'
  },
  {
    'value': 'L378',
    'viewValue': 'TRENTO (TN)'
  },
  {
    'value': 'L379',
    'viewValue': 'TRENTOLA DUCENTA (CE)'
  },
  {
    'value': 'L380',
    'viewValue': 'TRENZANO (BS)'
  },
  {
    'value': 'L381',
    'viewValue': 'TREPPO CARNICO (UD)'
  },
  {
    'value': 'L382',
    'viewValue': 'TREPPO GRANDE (UD)'
  },
  {
    'value': 'L383',
    'viewValue': 'TREPUZZI (LE)'
  },
  {
    'value': 'L384',
    'viewValue': 'TREQUANDA (SI)'
  },
  {
    'value': 'L385',
    'viewValue': 'TRES (TN)'
  },
  {
    'value': 'L386',
    'viewValue': 'TRESANA (MS)'
  },
  {
    'value': 'L388',
    'viewValue': 'TRESCORE BALNEARIO (BG)'
  },
  {
    'value': 'L389',
    'viewValue': 'TRESCORE CREMASCO (CR)'
  },
  {
    'value': 'L390',
    'viewValue': 'TRESIGALLO (FE)'
  },
  {
    'value': 'L392',
    'viewValue': 'TRESIVIO (SO)'
  },
  {
    'value': 'L393',
    'viewValue': 'TRESNURAGHES (OR)'
  },
  {
    'value': 'L396',
    'viewValue': 'TREVENZUOLO (VR)'
  },
  {
    'value': 'L397',
    'viewValue': 'TREVI (PG)'
  },
  {
    'value': 'L398',
    'viewValue': 'TREVI NEL LAZIO (FR)'
  },
  {
    'value': 'L399',
    'viewValue': 'TREVICO (AV)'
  },
  {
    'value': 'L400',
    'viewValue': 'TREVIGLIO (BG)'
  },
  {
    'value': 'L402',
    'viewValue': 'TREVIGNANO (TV)'
  },
  {
    'value': 'L401',
    'viewValue': 'TREVIGNANO ROMANO (RM)'
  },
  {
    'value': 'L403',
    'viewValue': 'TREVILLE (AL)'
  },
  {
    'value': 'L404',
    'viewValue': 'TREVIOLO (BG)'
  },
  {
    'value': 'L407',
    'viewValue': 'TREVISO (TV)'
  },
  {
    'value': 'L406',
    'viewValue': 'TREVISO BRESCIANO (BS)'
  },
  {
    'value': 'L408',
    'viewValue': 'TREZZANO ROSA (MI)'
  },
  {
    'value': 'L409',
    'viewValue': 'TREZZANO SUL NAVIGLIO (MI)'
  },
  {
    'value': 'L411',
    'viewValue': "TREZZO SULL'ADDA (MI)"
  },
  {
    'value': 'L410',
    'viewValue': 'TREZZO TINELLA (CN)'
  },
  {
    'value': 'L413',
    'viewValue': 'TREZZONE (CO)'
  },
  {
    'value': 'L414',
    'viewValue': 'TRIBANO (PD)'
  },
  {
    'value': 'L415',
    'viewValue': 'TRIBIANO (MI)'
  },
  {
    'value': 'L416',
    'viewValue': 'TRIBOGNA (GE)'
  },
  {
    'value': 'L418',
    'viewValue': 'TRICARICO (MT)'
  },
  {
    'value': 'L419',
    'viewValue': 'TRICASE (LE)'
  },
  {
    'value': 'L420',
    'viewValue': 'TRICERRO (VC)'
  },
  {
    'value': 'L421',
    'viewValue': 'TRICESIMO (UD)'
  },
  {
    'value': 'L422',
    'viewValue': 'TRICHIANA (BL)'
  },
  {
    'value': 'L423',
    'viewValue': 'TRIEI (NU)'
  },
  {
    'value': 'L424',
    'viewValue': 'TRIESTE (TS)'
  },
  {
    'value': 'L425',
    'viewValue': 'TRIGGIANO (BA)'
  },
  {
    'value': 'L426',
    'viewValue': 'TRIGOLO (CR)'
  },
  {
    'value': 'L427',
    'viewValue': "TRINITA' (CN)"
  },
  {
    'value': 'L428',
    'viewValue': "TRINITA' D'AGULTU E VIGNOLA (SS)"
  },
  {
    'value': 'B915',
    'viewValue': 'TRINITAPOLI (FG)'
  },
  {
    'value': 'L429',
    'viewValue': 'TRINO VERCELLESE (VC)'
  },
  {
    'value': 'L430',
    'viewValue': 'TRIORA (IM)'
  },
  {
    'value': 'L431',
    'viewValue': 'TRIPI (ME)'
  },
  {
    'value': 'L432',
    'viewValue': 'TRISOBBIO (AL)'
  },
  {
    'value': 'L433',
    'viewValue': 'TRISSINO (VI)'
  },
  {
    'value': 'L434',
    'viewValue': 'TRIUGGIO (MI)'
  },
  {
    'value': 'L435',
    'viewValue': 'TRIVENTO (CB)'
  },
  {
    'value': 'L436',
    'viewValue': 'TRIVERO (BI)'
  },
  {
    'value': 'L437',
    'viewValue': 'TRIVIGLIANO (FR)'
  },
  {
    'value': 'L438',
    'viewValue': 'TRIVIGNANO UDINESE (UD)'
  },
  {
    'value': 'L439',
    'viewValue': 'TRIVIGNO (PZ)'
  },
  {
    'value': 'L440',
    'viewValue': 'TRIVOLZIO (PV)'
  },
  {
    'value': 'L444',
    'viewValue': 'TRODENA .TRUDEN. (BZ)'
  },
  {
    'value': 'L445',
    'viewValue': 'TROFARELLO (TO)'
  },
  {
    'value': 'L447',
    'viewValue': 'TROIA (FG)'
  },
  {
    'value': 'L448',
    'viewValue': 'TROINA (EN)'
  },
  {
    'value': 'L449',
    'viewValue': 'TROMELLO (PV)'
  },
  {
    'value': 'L450',
    'viewValue': 'TRONTANO (VB)'
  },
  {
    'value': 'A705',
    'viewValue': 'TRONZANO LAGO MAGGIORE (VA)'
  },
  {
    'value': 'L451',
    'viewValue': 'TRONZANO VERCELLESE (VC)'
  },
  {
    'value': 'L452',
    'viewValue': 'TROPEA (VV)'
  },
  {
    'value': 'L453',
    'viewValue': 'TROVO (PV)'
  },
  {
    'value': 'L454',
    'viewValue': 'TRUCCAZZANO (MI)'
  },
  {
    'value': 'L455',
    'viewValue': 'TUBRE .TAUFERS IN MUNSTERTHAL. (BZ)'
  },
  {
    'value': 'L457',
    'viewValue': 'TUENNO (TN)'
  },
  {
    'value': 'L458',
    'viewValue': 'TUFARA (CB)'
  },
  {
    'value': 'L459',
    'viewValue': 'TUFILLO (CH)'
  },
  {
    'value': 'L460',
    'viewValue': 'TUFINO (NA)'
  },
  {
    'value': 'L461',
    'viewValue': 'TUFO (AV)'
  },
  {
    'value': 'L462',
    'viewValue': 'TUGLIE (LE)'
  },
  {
    'value': 'L463',
    'viewValue': 'TUILI (CA)'
  },
  {
    'value': 'L464',
    'viewValue': 'TULA (SS)'
  },
  {
    'value': 'L466',
    'viewValue': 'TUORO SUL TRASIMENO (PG)'
  },
  {
    'value': 'G507',
    'viewValue': 'TURANIA (RI)'
  },
  {
    'value': 'L469',
    'viewValue': 'TURANO LODIGIANO (LO)'
  },
  {
    'value': 'L470',
    'viewValue': 'TURATE (CO)'
  },
  {
    'value': 'L471',
    'viewValue': 'TURBIGO (MI)'
  },
  {
    'value': 'L472',
    'viewValue': 'TURI (BA)'
  },
  {
    'value': 'L473',
    'viewValue': 'TURRI (CA)'
  },
  {
    'value': 'L474',
    'viewValue': 'TURRIACO (GO)'
  },
  {
    'value': 'L475',
    'viewValue': 'TURRIVALIGNANI (PE)'
  },
  {
    'value': 'L477',
    'viewValue': 'TURSI (MT)'
  },
  {
    'value': 'L478',
    'viewValue': 'TUSA (ME)'
  },
  {
    'value': 'L310',
    'viewValue': 'TUSCANIA (VT)'
  },
  {
    'value': 'C789',
    'viewValue': 'UBIALE CLANEZZO (BG)'
  },
  {
    'value': 'L480',
    'viewValue': 'UBOLDO (VA)'
  },
  {
    'value': 'L482',
    'viewValue': 'UCRIA (ME)'
  },
  {
    'value': 'L483',
    'viewValue': 'UDINE (UD)'
  },
  {
    'value': 'L484',
    'viewValue': 'UGENTO (LE)'
  },
  {
    'value': 'L485',
    'viewValue': 'UGGIANO LA CHIESA (LE)'
  },
  {
    'value': 'L487',
    'viewValue': 'UGGIATE-TREVANO (CO)'
  },
  {
    'value': 'L488',
    'viewValue': "ULA' TIRSO (OR)"
  },
  {
    'value': 'L489',
    'viewValue': 'ULASSAI (NU)'
  },
  {
    'value': 'L490',
    'viewValue': 'ULTIMO .ULTEN. (BZ)'
  },
  {
    'value': 'D786',
    'viewValue': 'UMBERTIDE (PG)'
  },
  {
    'value': 'L492',
    'viewValue': 'UMBRIATICO (KR)'
  },
  {
    'value': 'L494',
    'viewValue': "URAGO D'OGLIO (BS)"
  },
  {
    'value': 'L496',
    'viewValue': 'URAS (OR)'
  },
  {
    'value': 'L497',
    'viewValue': 'URBANA (PD)'
  },
  {
    'value': 'L498',
    'viewValue': 'URBANIA (PU)'
  },
  {
    'value': 'L499',
    'viewValue': 'URBE (SV)'
  },
  {
    'value': 'L500',
    'viewValue': 'URBINO (PU)'
  },
  {
    'value': 'L501',
    'viewValue': 'URBISAGLIA (MC)'
  },
  {
    'value': 'L502',
    'viewValue': 'URGNANO (BG)'
  },
  {
    'value': 'L503',
    'viewValue': 'URI (SS)'
  },
  {
    'value': 'L505',
    'viewValue': 'URURI (CB)'
  },
  {
    'value': 'L506',
    'viewValue': 'URZULEI (NU)'
  },
  {
    'value': 'L507',
    'viewValue': 'USCIO (GE)'
  },
  {
    'value': 'L508',
    'viewValue': 'USELLUS (OR)'
  },
  {
    'value': 'L509',
    'viewValue': 'USINI (SS)'
  },
  {
    'value': 'L511',
    'viewValue': 'USMATE VELATE (MI)'
  },
  {
    'value': 'L512',
    'viewValue': 'USSANA (CA)'
  },
  {
    'value': 'L513',
    'viewValue': 'USSARAMANNA (CA)'
  },
  {
    'value': 'L514',
    'viewValue': 'USSASSAI (NU)'
  },
  {
    'value': 'L515',
    'viewValue': 'USSEAUX (TO)'
  },
  {
    'value': 'L516',
    'viewValue': 'USSEGLIO (TO)'
  },
  {
    'value': 'L517',
    'viewValue': 'USSITA (MC)'
  },
  {
    'value': 'L519',
    'viewValue': 'USTICA (PA)'
  },
  {
    'value': 'L521',
    'viewValue': 'UTA (CA)'
  },
  {
    'value': 'L522',
    'viewValue': 'UZZANO (PT)'
  },
  {
    'value': 'L524',
    'viewValue': 'VACCARIZZO ALBANESE (CS)'
  },
  {
    'value': 'L525',
    'viewValue': 'VACONE (RI)'
  },
  {
    'value': 'L526',
    'viewValue': 'VACRI (CH)'
  },
  {
    'value': 'L527',
    'viewValue': 'VADENA .PFATTEN. (BZ)'
  },
  {
    'value': 'L528',
    'viewValue': 'VADO LIGURE (SV)'
  },
  {
    'value': 'L533',
    'viewValue': 'VAGLI SOTTO (LU)'
  },
  {
    'value': 'L529',
    'viewValue': 'VAGLIA (FI)'
  },
  {
    'value': 'L532',
    'viewValue': 'VAGLIO BASILICATA (PZ)'
  },
  {
    'value': 'L531',
    'viewValue': 'VAGLIO SERRA (AT)'
  },
  {
    'value': 'L537',
    'viewValue': 'VAIANO (PO)'
  },
  {
    'value': 'L535',
    'viewValue': 'VAIANO CREMASCO (CR)'
  },
  {
    'value': 'L538',
    'viewValue': 'VAIE (TO)'
  },
  {
    'value': 'L539',
    'viewValue': 'VAILATE (CR)'
  },
  {
    'value': 'L540',
    'viewValue': 'VAIRANO PATENORA (CE)'
  },
  {
    'value': 'M265',
    'viewValue': 'VAJONT (PN)'
  },
  {
    'value': 'L555',
    'viewValue': 'VAL DELLA TORRE (TO)'
  },
  {
    'value': 'M405',
    'viewValue': 'VAL DI CHY (TO)'
  },
  {
    'value': 'L562',
    'viewValue': 'VAL DI NIZZA (PV)'
  },
  {
    'value': 'L564',
    'viewValue': 'VAL DI VIZZE .PFITSCH. (BZ)'
  },
  {
    'value': 'L638',
    'viewValue': 'VAL MASINO (SO)'
  },
  {
    'value': 'H259',
    'viewValue': 'VAL REZZO (CO)'
  },
  {
    'value': 'L544',
    'viewValue': 'VALBONDIONE (BG)'
  },
  {
    'value': 'L545',
    'viewValue': 'VALBREMBO (BG)'
  },
  {
    'value': 'L546',
    'viewValue': 'VALBREVENNA (GE)'
  },
  {
    'value': 'L547',
    'viewValue': 'VALBRONA (CO)'
  },
  {
    'value': 'L550',
    'viewValue': 'VALDA (TN)'
  },
  {
    'value': 'L551',
    'viewValue': 'VALDAGNO (VI)'
  },
  {
    'value': 'L552',
    'viewValue': 'VALDAORA .OLANG. (BZ)'
  },
  {
    'value': 'L554',
    'viewValue': 'VALDASTICO (VI)'
  },
  {
    'value': 'L556',
    'viewValue': 'VALDENGO (BI)'
  },
  {
    'value': 'G319',
    'viewValue': 'VALDERICE (TP)'
  },
  {
    'value': 'L557',
    'viewValue': 'VALDIDENTRO (SO)'
  },
  {
    'value': 'L558',
    'viewValue': 'VALDIERI (CN)'
  },
  {
    'value': 'L561',
    'viewValue': 'VALDINA (ME)'
  },
  {
    'value': 'L563',
    'viewValue': 'VALDISOTTO (SO)'
  },
  {
    'value': 'L565',
    'viewValue': 'VALDOBBIADENE (TV)'
  },
  {
    'value': 'L566',
    'viewValue': 'VALDUGGIA (VC)'
  },
  {
    'value': 'L568',
    'viewValue': 'VALEGGIO (PV)'
  },
  {
    'value': 'L567',
    'viewValue': 'VALEGGIO SUL MINCIO (VR)'
  },
  {
    'value': 'L569',
    'viewValue': 'VALENTANO (VT)'
  },
  {
    'value': 'L570',
    'viewValue': 'VALENZA (AL)'
  },
  {
    'value': 'L571',
    'viewValue': 'VALENZANO (BA)'
  },
  {
    'value': 'L572',
    'viewValue': 'VALERA FRATTA (LO)'
  },
  {
    'value': 'L573',
    'viewValue': 'VALFABBRICA (PG)'
  },
  {
    'value': 'L574',
    'viewValue': 'VALFENERA (AT)'
  },
  {
    'value': 'L575',
    'viewValue': 'VALFLORIANA (TN)'
  },
  {
    'value': 'L576',
    'viewValue': 'VALFURVA (SO)'
  },
  {
    'value': 'L577',
    'viewValue': 'VALGANNA (VA)'
  },
  {
    'value': 'L578',
    'viewValue': 'VALGIOIE (TO)'
  },
  {
    'value': 'L579',
    'viewValue': 'VALGOGLIO (BG)'
  },
  {
    'value': 'L580',
    'viewValue': 'VALGRANA (CN)'
  },
  {
    'value': 'L581',
    'viewValue': 'VALGREGHENTINO (LC)'
  },
  {
    'value': 'L582',
    'viewValue': 'VALGRISENCHE (AO)'
  },
  {
    'value': 'L583',
    'viewValue': 'VALGUARNERA CAROPEPE (EN)'
  },
  {
    'value': 'L584',
    'viewValue': 'VALLADA AGORDINA (BL)'
  },
  {
    'value': 'L586',
    'viewValue': 'VALLANZENGO (BI)'
  },
  {
    'value': 'L588',
    'viewValue': 'VALLARSA (TN)'
  },
  {
    'value': 'L589',
    'viewValue': 'VALLATA (AV)'
  },
  {
    'value': 'L594',
    'viewValue': 'VALLE AGRICOLA (CE)'
  },
  {
    'value': 'L595',
    'viewValue': 'VALLE AURINA .AHRNTAL. (BZ)'
  },
  {
    'value': 'L597',
    'viewValue': 'VALLE CASTELLANA (TE)'
  },
  {
    'value': 'G540',
    'viewValue': "VALLE DELL'ANGELO (SA)"
  },
  {
    'value': 'L590',
    'viewValue': 'VALLE DI CADORE (BL)'
  },
  {
    'value': 'L601',
    'viewValue': 'VALLE DI CASIES .GSIES. (BZ)'
  },
  {
    'value': 'L591',
    'viewValue': 'VALLE DI MADDALONI (CE)'
  },
  {
    'value': 'L593',
    'viewValue': 'VALLE LOMELLINA (PV)'
  },
  {
    'value': 'L606',
    'viewValue': 'VALLE MOSSO (BI)'
  },
  {
    'value': 'L617',
    'viewValue': 'VALLE SALIMBENE (PV)'
  },
  {
    'value': 'L620',
    'viewValue': 'VALLE SAN NICOLAO (BI)'
  },
  {
    'value': 'L596',
    'viewValue': 'VALLEBONA (IM)'
  },
  {
    'value': 'L598',
    'viewValue': 'VALLECORSA (FR)'
  },
  {
    'value': 'L599',
    'viewValue': 'VALLECROSIA (IM)'
  },
  {
    'value': 'L603',
    'viewValue': 'VALLEDOLMO (PA)'
  },
  {
    'value': 'L604',
    'viewValue': 'VALLEDORIA (SS)'
  },
  {
    'value': 'I322',
    'viewValue': 'VALLEFIORITA (CZ)'
  },
  {
    'value': 'L607',
    'viewValue': 'VALLELONGA (VV)'
  },
  {
    'value': 'L609',
    'viewValue': 'VALLELUNGA PRATAMENO (CL)'
  },
  {
    'value': 'L605',
    'viewValue': 'VALLEMAIO (FR)'
  },
  {
    'value': 'L611',
    'viewValue': 'VALLEPIETRA (RM)'
  },
  {
    'value': 'L612',
    'viewValue': 'VALLERANO (VT)'
  },
  {
    'value': 'L613',
    'viewValue': 'VALLERMOSA (CA)'
  },
  {
    'value': 'L614',
    'viewValue': 'VALLEROTONDA (FR)'
  },
  {
    'value': 'L616',
    'viewValue': 'VALLESACCARDA (AV)'
  },
  {
    'value': 'L623',
    'viewValue': 'VALLEVE (BG)'
  },
  {
    'value': 'L624',
    'viewValue': 'VALLI DEL PASUBIO (VI)'
  },
  {
    'value': 'L625',
    'viewValue': 'VALLINFREDA (RM)'
  },
  {
    'value': 'L626',
    'viewValue': 'VALLIO TERME (BS)'
  },
  {
    'value': 'L628',
    'viewValue': 'VALLO DELLA LUCANIA (SA)'
  },
  {
    'value': 'L627',
    'viewValue': 'VALLO DI NERA (PG)'
  },
  {
    'value': 'L629',
    'viewValue': 'VALLO TORINESE (TO)'
  },
  {
    'value': 'L631',
    'viewValue': 'VALLORIATE (CN)'
  },
  {
    'value': 'L633',
    'viewValue': 'VALMACCA (AL)'
  },
  {
    'value': 'L634',
    'viewValue': 'VALMADRERA (LC)'
  },
  {
    'value': 'L636',
    'viewValue': 'VALMALA (CN)'
  },
  {
    'value': 'L639',
    'viewValue': 'VALMONTONE (RM)'
  },
  {
    'value': 'L640',
    'viewValue': 'VALMOREA (CO)'
  },
  {
    'value': 'L641',
    'viewValue': 'VALMOZZOLA (PR)'
  },
  {
    'value': 'L642',
    'viewValue': 'VALNEGRA (BG)'
  },
  {
    'value': 'L643',
    'viewValue': 'VALPELLINE (AO)'
  },
  {
    'value': 'L644',
    'viewValue': 'VALPERGA (TO)'
  },
  {
    'value': 'B510',
    'viewValue': 'VALPRATO SOANA (TO)'
  },
  {
    'value': 'M320',
    'viewValue': 'VALSAMOGGIA (BO)'
  },
  {
    'value': 'L647',
    'viewValue': 'VALSAVARENCHE (AO)'
  },
  {
    'value': 'L649',
    'viewValue': 'VALSECCA (BG)'
  },
  {
    'value': 'D513',
    'viewValue': 'VALSINNI (MT)'
  },
  {
    'value': 'C936',
    'viewValue': 'VALSOLDA (CO)'
  },
  {
    'value': 'L650',
    'viewValue': 'VALSTAGNA (VI)'
  },
  {
    'value': 'L651',
    'viewValue': 'VALSTRONA (VB)'
  },
  {
    'value': 'L653',
    'viewValue': 'VALTOPINA (PG)'
  },
  {
    'value': 'L655',
    'viewValue': 'VALTORTA (BG)'
  },
  {
    'value': 'L654',
    'viewValue': 'VALTOURNENCHE (AO)'
  },
  {
    'value': 'L656',
    'viewValue': 'VALVA (SA)'
  },
  {
    'value': 'L657',
    'viewValue': 'VALVASONE (PN)'
  },
  {
    'value': 'L658',
    'viewValue': 'VALVERDE (CT)'
  },
  {
    'value': 'L659',
    'viewValue': 'VALVERDE (PV)'
  },
  {
    'value': 'L468',
    'viewValue': 'VALVESTINO (BS)'
  },
  {
    'value': 'L660',
    'viewValue': 'VANDOIES .VINTL. (BZ)'
  },
  {
    'value': 'L664',
    'viewValue': 'VANZAGHELLO (MI)'
  },
  {
    'value': 'L665',
    'viewValue': 'VANZAGO (MI)'
  },
  {
    'value': 'L666',
    'viewValue': 'VANZONE CON SAN CARLO (VB)'
  },
  {
    'value': 'L667',
    'viewValue': "VAPRIO D'ADDA (MI)"
  },
  {
    'value': 'L668',
    'viewValue': "VAPRIO D'AGOGNA (NO)"
  },
  {
    'value': 'L669',
    'viewValue': 'VARALLO (VC)'
  },
  {
    'value': 'L670',
    'viewValue': 'VARALLO POMBIA (NO)'
  },
  {
    'value': 'L671',
    'viewValue': 'VARANO BORGHI (VA)'
  },
  {
    'value': 'L672',
    'viewValue': "VARANO DE' MELEGARI (PR)"
  },
  {
    'value': 'L673',
    'viewValue': 'VARAPODIO (RC)'
  },
  {
    'value': 'L675',
    'viewValue': 'VARAZZE (SV)'
  },
  {
    'value': 'L676',
    'viewValue': 'VARCO SABINO (RI)'
  },
  {
    'value': 'L677',
    'viewValue': 'VAREDO (MI)'
  },
  {
    'value': 'L678',
    'viewValue': 'VARENA (TN)'
  },
  {
    'value': 'L680',
    'viewValue': 'VARENNA (LC)'
  },
  {
    'value': 'L682',
    'viewValue': 'VARESE (VA)'
  },
  {
    'value': 'L681',
    'viewValue': 'VARESE LIGURE (SP)'
  },
  {
    'value': 'L685',
    'viewValue': 'VARISELLA (TO)'
  },
  {
    'value': 'L686',
    'viewValue': 'VARMO (UD)'
  },
  {
    'value': 'L687',
    'viewValue': 'VARNA .VAHRN. (BZ)'
  },
  {
    'value': 'L689',
    'viewValue': 'VARSI (PR)'
  },
  {
    'value': 'L690',
    'viewValue': 'VARZI (PV)'
  },
  {
    'value': 'L691',
    'viewValue': 'VARZO (VB)'
  },
  {
    'value': 'L692',
    'viewValue': 'VAS (BL)'
  },
  {
    'value': 'A701',
    'viewValue': 'VASANELLO (VT)'
  },
  {
    'value': 'L693',
    'viewValue': 'VASIA (IM)'
  },
  {
    'value': 'E372',
    'viewValue': 'VASTO (CH)'
  },
  {
    'value': 'L696',
    'viewValue': 'VASTOGIRARDI (IS)'
  },
  {
    'value': 'L697',
    'viewValue': 'VATTARO (TN)'
  },
  {
    'value': 'L698',
    'viewValue': 'VAUDA CANAVESE (TO)'
  },
  {
    'value': 'L699',
    'viewValue': 'VAZZANO (VV)'
  },
  {
    'value': 'L700',
    'viewValue': 'VAZZOLA (TV)'
  },
  {
    'value': 'L702',
    'viewValue': 'VECCHIANO (PI)'
  },
  {
    'value': 'L704',
    'viewValue': 'VEDANO AL LAMBRO (MI)'
  },
  {
    'value': 'L703',
    'viewValue': 'VEDANO OLONA (VA)'
  },
  {
    'value': 'L705',
    'viewValue': 'VEDDASCA (VA)'
  },
  {
    'value': 'L706',
    'viewValue': 'VEDELAGO (TV)'
  },
  {
    'value': 'L707',
    'viewValue': 'VEDESETA (BG)'
  },
  {
    'value': 'L709',
    'viewValue': 'VEDUGGIO CON COLZANO (MI)'
  },
  {
    'value': 'L710',
    'viewValue': 'VEGGIANO (PD)'
  },
  {
    'value': 'L711',
    'viewValue': 'VEGLIE (LE)'
  },
  {
    'value': 'L712',
    'viewValue': 'VEGLIO (BI)'
  },
  {
    'value': 'L713',
    'viewValue': 'VEJANO (VT)'
  },
  {
    'value': 'L715',
    'viewValue': 'VELESO (CO)'
  },
  {
    'value': 'L716',
    'viewValue': 'VELEZZO LOMELLINA (PV)'
  },
  {
    'value': 'L719',
    'viewValue': 'VELLETRI (RM)'
  },
  {
    'value': 'L720',
    'viewValue': 'VELLEZZO BELLINI (PV)'
  },
  {
    'value': 'L723',
    'viewValue': "VELO D'ASTICO (VI)"
  },
  {
    'value': 'L722',
    'viewValue': 'VELO VERONESE (VR)'
  },
  {
    'value': 'L724',
    'viewValue': 'VELTURNO .FELDTHURNS. (BZ)'
  },
  {
    'value': 'L725',
    'viewValue': 'VENAFRO (IS)'
  },
  {
    'value': 'L727',
    'viewValue': 'VENARIA REALE (TO)'
  },
  {
    'value': 'L728',
    'viewValue': 'VENAROTTA (AP)'
  },
  {
    'value': 'L729',
    'viewValue': 'VENASCA (CN)'
  },
  {
    'value': 'L726',
    'viewValue': 'VENAUS (TO)'
  },
  {
    'value': 'L730',
    'viewValue': 'VENDONE (SV)'
  },
  {
    'value': 'L731',
    'viewValue': 'VENDROGNO (LC)'
  },
  {
    'value': 'L733',
    'viewValue': 'VENEGONO INFERIORE (VA)'
  },
  {
    'value': 'L734',
    'viewValue': 'VENEGONO SUPERIORE (VA)'
  },
  {
    'value': 'L735',
    'viewValue': 'VENETICO (ME)'
  },
  {
    'value': 'L736',
    'viewValue': 'VENEZIA (VE)'
  },
  {
    'value': 'L737',
    'viewValue': 'VENIANO (CO)'
  },
  {
    'value': 'L738',
    'viewValue': 'VENOSA (PZ)'
  },
  {
    'value': 'L739',
    'viewValue': 'VENTICANO (AV)'
  },
  {
    'value': 'L741',
    'viewValue': 'VENTIMIGLIA (IM)'
  },
  {
    'value': 'L740',
    'viewValue': 'VENTIMIGLIA DI SICILIA (PA)'
  },
  {
    'value': 'L742',
    'viewValue': 'VENTOTENE (LT)'
  },
  {
    'value': 'L743',
    'viewValue': 'VENZONE (UD)'
  },
  {
    'value': 'L745',
    'viewValue': 'VERANO .VORAN. (BZ)'
  },
  {
    'value': 'L744',
    'viewValue': 'VERANO BRIANZA (MI)'
  },
  {
    'value': 'L746',
    'viewValue': 'VERBANIA (VB)'
  },
  {
    'value': 'L747',
    'viewValue': 'VERBICARO (CS)'
  },
  {
    'value': 'L748',
    'viewValue': 'VERCANA (CO)'
  },
  {
    'value': 'L749',
    'viewValue': 'VERCEIA (SO)'
  },
  {
    'value': 'L750',
    'viewValue': 'VERCELLI (VC)'
  },
  {
    'value': 'L751',
    'viewValue': 'VERCURAGO (LC)'
  },
  {
    'value': 'L752',
    'viewValue': 'VERDELLINO (BG)'
  },
  {
    'value': 'L753',
    'viewValue': 'VERDELLO (BG)'
  },
  {
    'value': 'L755',
    'viewValue': 'VERDERIO INFERIORE (LC)'
  },
  {
    'value': 'L756',
    'viewValue': 'VERDERIO SUPERIORE (LC)'
  },
  {
    'value': 'L758',
    'viewValue': 'VERDUNO (CN)'
  },
  {
    'value': 'L762',
    'viewValue': 'VERGATO (BO)'
  },
  {
    'value': 'L763',
    'viewValue': 'VERGEMOLI (LU)'
  },
  {
    'value': 'L764',
    'viewValue': 'VERGHERETO (FC)'
  },
  {
    'value': 'L765',
    'viewValue': 'VERGIATE (VA)'
  },
  {
    'value': 'L768',
    'viewValue': 'VERMEZZO (MI)'
  },
  {
    'value': 'L769',
    'viewValue': 'VERMIGLIO (TN)'
  },
  {
    'value': 'L771',
    'viewValue': 'VERNANTE (CN)'
  },
  {
    'value': 'L772',
    'viewValue': 'VERNASCA (PC)'
  },
  {
    'value': 'L773',
    'viewValue': 'VERNATE (MI)'
  },
  {
    'value': 'L774',
    'viewValue': 'VERNAZZA (SP)'
  },
  {
    'value': 'L775',
    'viewValue': 'VERNIO (PO)'
  },
  {
    'value': 'L776',
    'viewValue': 'VERNOLE (LE)'
  },
  {
    'value': 'L777',
    'viewValue': 'VEROLANUOVA (BS)'
  },
  {
    'value': 'L778',
    'viewValue': 'VEROLAVECCHIA (BS)'
  },
  {
    'value': 'L779',
    'viewValue': 'VEROLENGO (TO)'
  },
  {
    'value': 'L780',
    'viewValue': 'VEROLI (FR)'
  },
  {
    'value': 'L781',
    'viewValue': 'VERONA (VR)'
  },
  {
    'value': 'D193',
    'viewValue': 'VERONELLA (VR)'
  },
  {
    'value': 'L783',
    'viewValue': 'VERRAYES (AO)'
  },
  {
    'value': 'C282',
    'viewValue': 'VERRES (AO)'
  },
  {
    'value': 'L784',
    'viewValue': 'VERRETTO (PV)'
  },
  {
    'value': 'L785',
    'viewValue': 'VERRONE (BI)'
  },
  {
    'value': 'L788',
    'viewValue': 'VERRUA PO (PV)'
  },
  {
    'value': 'L787',
    'viewValue': 'VERRUA SAVOIA (TO)'
  },
  {
    'value': 'L792',
    'viewValue': 'VERTEMATE CON MINOPRIO (CO)'
  },
  {
    'value': 'L795',
    'viewValue': 'VERTOVA (BG)'
  },
  {
    'value': 'L797',
    'viewValue': 'VERUCCHIO (RN)'
  },
  {
    'value': 'L798',
    'viewValue': 'VERUNO (NO)'
  },
  {
    'value': 'L799',
    'viewValue': 'VERVIO (SO)'
  },
  {
    'value': 'L800',
    'viewValue': "VERVO' (TN)"
  },
  {
    'value': 'L801',
    'viewValue': 'VERZEGNIS (UD)'
  },
  {
    'value': 'L802',
    'viewValue': 'VERZINO (KR)'
  },
  {
    'value': 'L804',
    'viewValue': 'VERZUOLO (CN)'
  },
  {
    'value': 'L805',
    'viewValue': 'VESCOVANA (PD)'
  },
  {
    'value': 'L806',
    'viewValue': 'VESCOVATO (CR)'
  },
  {
    'value': 'L807',
    'viewValue': 'VESIME (AT)'
  },
  {
    'value': 'L808',
    'viewValue': 'VESPOLATE (NO)'
  },
  {
    'value': 'L809',
    'viewValue': 'VESSALICO (IM)'
  },
  {
    'value': 'L810',
    'viewValue': 'VESTENANOVA (VR)'
  },
  {
    'value': 'L811',
    'viewValue': "VESTIGNE' (TO)"
  },
  {
    'value': 'L812',
    'viewValue': 'VESTONE (BS)'
  },
  {
    'value': 'L813',
    'viewValue': 'VESTRENO (LC)'
  },
  {
    'value': 'L814',
    'viewValue': 'VETRALLA (VT)'
  },
  {
    'value': 'L815',
    'viewValue': 'VETTO (RE)'
  },
  {
    'value': 'L817',
    'viewValue': "VEZZA D'ALBA (CN)"
  },
  {
    'value': 'L816',
    'viewValue': "VEZZA D'OGLIO (BS)"
  },
  {
    'value': 'L821',
    'viewValue': 'VEZZANO (TN)'
  },
  {
    'value': 'L819',
    'viewValue': 'VEZZANO LIGURE (SP)'
  },
  {
    'value': 'L820',
    'viewValue': 'VEZZANO SUL CROSTOLO (RE)'
  },
  {
    'value': 'L823',
    'viewValue': 'VEZZI PORTIO (SV)'
  },
  {
    'value': 'L826',
    'viewValue': 'VIADANA (MN)'
  },
  {
    'value': 'L827',
    'viewValue': 'VIADANICA (BG)'
  },
  {
    'value': 'L828',
    'viewValue': 'VIAGRANDE (CT)'
  },
  {
    'value': 'L829',
    'viewValue': "VIALE D'ASTI (AT)"
  },
  {
    'value': 'L830',
    'viewValue': "VIALFRE' (TO)"
  },
  {
    'value': 'L831',
    'viewValue': 'VIANO (RE)'
  },
  {
    'value': 'L833',
    'viewValue': 'VIAREGGIO (LU)'
  },
  {
    'value': 'L834',
    'viewValue': 'VIARIGI (AT)'
  },
  {
    'value': 'F537',
    'viewValue': 'VIBO VALENTIA (VV)'
  },
  {
    'value': 'L835',
    'viewValue': 'VIBONATI (SA)'
  },
  {
    'value': 'L836',
    'viewValue': 'VICALVI (FR)'
  },
  {
    'value': 'L837',
    'viewValue': 'VICARI (PA)'
  },
  {
    'value': 'L838',
    'viewValue': 'VICCHIO (FI)'
  },
  {
    'value': 'L840',
    'viewValue': 'VICENZA (VI)'
  },
  {
    'value': 'L548',
    'viewValue': 'VICO CANAVESE (TO)'
  },
  {
    'value': 'L842',
    'viewValue': 'VICO DEL GARGANO (FG)'
  },
  {
    'value': 'L845',
    'viewValue': 'VICO EQUENSE (NA)'
  },
  {
    'value': 'L843',
    'viewValue': 'VICO NEL LAZIO (FR)'
  },
  {
    'value': 'L841',
    'viewValue': 'VICOFORTE (CN)'
  },
  {
    'value': 'L846',
    'viewValue': 'VICOLI (PE)'
  },
  {
    'value': 'L847',
    'viewValue': 'VICOLUNGO (NO)'
  },
  {
    'value': 'L850',
    'viewValue': 'VICOPISANO (PI)'
  },
  {
    'value': 'L851',
    'viewValue': 'VICOVARO (RM)'
  },
  {
    'value': 'M259',
    'viewValue': 'VIDDALBA (SS)'
  },
  {
    'value': 'L854',
    'viewValue': 'VIDIGULFO (PV)'
  },
  {
    'value': 'L856',
    'viewValue': 'VIDOR (TV)'
  },
  {
    'value': 'L857',
    'viewValue': 'VIDRACCO (TO)'
  },
  {
    'value': 'L858',
    'viewValue': 'VIESTE (FG)'
  },
  {
    'value': 'L859',
    'viewValue': 'VIETRI DI POTENZA (PZ)'
  },
  {
    'value': 'L860',
    'viewValue': 'VIETRI SUL MARE (SA)'
  },
  {
    'value': 'L864',
    'viewValue': 'VIGANELLA (VB)'
  },
  {
    'value': 'L866',
    'viewValue': "VIGANO' (LC)"
  },
  {
    'value': 'L870',
    'viewValue': "VIGATTO' (PR)"
  },
  {
    'value': 'L865',
    'viewValue': 'VIGANO SAN MARTINO (BG)'
  },
  {
    'value': 'L868',
    'viewValue': 'VIGARANO MAINARDA (FE)'
  },
  {
    'value': 'L869',
    'viewValue': 'VIGASIO (VR)'
  },
  {
    'value': 'L872',
    'viewValue': 'VIGEVANO (PV)'
  },
  {
    'value': 'L873',
    'viewValue': 'VIGGIANELLO (PZ)'
  },
  {
    'value': 'L874',
    'viewValue': 'VIGGIANO (PZ)'
  },
  {
    'value': 'L876',
    'viewValue': "VIGGIU' (VA)"
  },
  {
    'value': 'L878',
    'viewValue': "VIGHIZZOLO D'ESTE (PD)"
  },
  {
    'value': 'L880',
    'viewValue': 'VIGLIANO BIELLESE (BI)'
  },
  {
    'value': 'L879',
    'viewValue': "VIGLIANO D'ASTI (AT)"
  },
  {
    'value': 'L881',
    'viewValue': 'VIGNALE MONFERRATO (AL)'
  },
  {
    'value': 'L882',
    'viewValue': 'VIGNANELLO (VT)'
  },
  {
    'value': 'L883',
    'viewValue': 'VIGNATE (MI)'
  },
  {
    'value': 'L885',
    'viewValue': 'VIGNOLA (MO)'
  },
  {
    'value': 'L886',
    'viewValue': 'VIGNOLA FALESINA (TN)'
  },
  {
    'value': 'L887',
    'viewValue': 'VIGNOLE BORBERA (AL)'
  },
  {
    'value': 'L888',
    'viewValue': 'VIGNOLO (CN)'
  },
  {
    'value': 'L889',
    'viewValue': 'VIGNONE (VB)'
  },
  {
    'value': 'L890',
    'viewValue': 'VIGO DI CADORE (BL)'
  },
  {
    'value': 'L893',
    'viewValue': 'VIGO DI FASSA (TN)'
  },
  {
    'value': 'L903',
    'viewValue': 'VIGO RENDENA (TN)'
  },
  {
    'value': 'L892',
    'viewValue': 'VIGODARZERE (PD)'
  },
  {
    'value': 'L894',
    'viewValue': 'VIGOLO (BG)'
  },
  {
    'value': 'L896',
    'viewValue': 'VIGOLO VATTARO (TN)'
  },
  {
    'value': 'L897',
    'viewValue': 'VIGOLZONE (PC)'
  },
  {
    'value': 'L898',
    'viewValue': 'VIGONE (TO)'
  },
  {
    'value': 'L899',
    'viewValue': 'VIGONOVO (VE)'
  },
  {
    'value': 'L900',
    'viewValue': 'VIGONZA (PD)'
  },
  {
    'value': 'L904',
    'viewValue': 'VIGUZZOLO (AL)'
  },
  {
    'value': 'L910',
    'viewValue': 'VILLA AGNEDO (TN)'
  },
  {
    'value': 'L912',
    'viewValue': 'VILLA BARTOLOMEA (VR)'
  },
  {
    'value': 'L913',
    'viewValue': 'VILLA BASILICA (LU)'
  },
  {
    'value': 'L917',
    'viewValue': 'VILLA BISCOSSI (PV)'
  },
  {
    'value': 'L919',
    'viewValue': 'VILLA CARCINA (BS)'
  },
  {
    'value': 'L920',
    'viewValue': 'VILLA CASTELLI (BR)'
  },
  {
    'value': 'L922',
    'viewValue': 'VILLA CELIERA (PE)'
  },
  {
    'value': 'L926',
    'viewValue': 'VILLA COLLEMANDINA (LU)'
  },
  {
    'value': 'L928',
    'viewValue': 'VILLA CORTESE (MI)'
  },
  {
    'value': 'L929',
    'viewValue': "VILLA D'ADDA (BG)"
  },
  {
    'value': 'A215',
    'viewValue': "VILLA D'ALME' (BG)"
  },
  {
    'value': 'L933',
    'viewValue': 'VILLA DEL BOSCO (BI)'
  },
  {
    'value': 'L934',
    'viewValue': 'VILLA DEL CONTE (PD)'
  },
  {
    'value': 'D801',
    'viewValue': 'VILLA DI BRIANO (CE)'
  },
  {
    'value': 'L907',
    'viewValue': 'VILLA DI CHIAVENNA (SO)'
  },
  {
    'value': 'L936',
    'viewValue': 'VILLA DI SERIO (BG)'
  },
  {
    'value': 'L908',
    'viewValue': 'VILLA DI TIRANO (SO)'
  },
  {
    'value': 'L938',
    'viewValue': "VILLA D'OGNA (BG)"
  },
  {
    'value': 'L937',
    'viewValue': 'VILLA ESTENSE (PD)'
  },
  {
    'value': 'L943',
    'viewValue': 'VILLA FARALDI (IM)'
  },
  {
    'value': 'L956',
    'viewValue': 'VILLA GUARDIA (CO)'
  },
  {
    'value': 'L957',
    'viewValue': 'VILLA LAGARINA (TN)'
  },
  {
    'value': 'A081',
    'viewValue': 'VILLA LATINA (FR)'
  },
  {
    'value': 'L844',
    'viewValue': 'VILLA LITERNO (CE)'
  },
  {
    'value': 'L969',
    'viewValue': 'VILLA MINOZZO (RE)'
  },
  {
    'value': 'F804',
    'viewValue': 'VILLA POMA (MN)'
  },
  {
    'value': 'M006',
    'viewValue': 'VILLA RENDENA (TN)'
  },
  {
    'value': 'M018',
    'viewValue': 'VILLA SAN GIOVANNI (RC)'
  },
  {
    'value': 'H913',
    'viewValue': 'VILLA SAN GIOVANNI IN TUSCIA (VT)'
  },
  {
    'value': 'I118',
    'viewValue': 'VILLA SAN PIETRO (CA)'
  },
  {
    'value': 'M019',
    'viewValue': 'VILLA SAN SECONDO (AT)'
  },
  {
    'value': 'L905',
    'viewValue': 'VILLA SANTA LUCIA (FR)'
  },
  {
    'value': 'M021',
    'viewValue': 'VILLA SANTA LUCIA DEGLI ABRUZZI (AQ)'
  },
  {
    'value': 'M022',
    'viewValue': 'VILLA SANTA MARIA (CH)'
  },
  {
    'value': 'M023',
    'viewValue': "VILLA SANT'ANGELO (AQ)"
  },
  {
    'value': 'I298',
    'viewValue': "VILLA SANT'ANTONIO (OR)"
  },
  {
    'value': 'L909',
    'viewValue': 'VILLA SANTINA (UD)'
  },
  {
    'value': 'I364',
    'viewValue': 'VILLA SANTO STEFANO (FR)'
  },
  {
    'value': 'A609',
    'viewValue': 'VILLA VERDE (OR)'
  },
  {
    'value': 'M034',
    'viewValue': 'VILLA VICENTINA (UD)'
  },
  {
    'value': 'L915',
    'viewValue': 'VILLABASSA .NIEDERDORF. (BZ)'
  },
  {
    'value': 'L916',
    'viewValue': 'VILLABATE (PA)'
  },
  {
    'value': 'L923',
    'viewValue': 'VILLACHIARA (BS)'
  },
  {
    'value': 'L924',
    'viewValue': 'VILLACIDRO (CA)'
  },
  {
    'value': 'L931',
    'viewValue': 'VILLADEATI (AL)'
  },
  {
    'value': 'L939',
    'viewValue': 'VILLADOSE (RO)'
  },
  {
    'value': 'L906',
    'viewValue': 'VILLADOSSOLA (VB)'
  },
  {
    'value': 'L942',
    'viewValue': 'VILLAFALLETTO (CN)'
  },
  {
    'value': 'L945',
    'viewValue': "VILLAFRANCA D'ASTI (AT)"
  },
  {
    'value': 'L949',
    'viewValue': 'VILLAFRANCA DI VERONA (VR)'
  },
  {
    'value': 'L946',
    'viewValue': 'VILLAFRANCA IN LUNIGIANA (MS)'
  },
  {
    'value': 'L947',
    'viewValue': 'VILLAFRANCA PADOVANA (PD)'
  },
  {
    'value': 'L948',
    'viewValue': 'VILLAFRANCA PIEMONTE (TO)'
  },
  {
    'value': 'L944',
    'viewValue': 'VILLAFRANCA SICULA (AG)'
  },
  {
    'value': 'L950',
    'viewValue': 'VILLAFRANCA TIRRENA (ME)'
  },
  {
    'value': 'L951',
    'viewValue': 'VILLAFRATI (PA)'
  },
  {
    'value': 'L952',
    'viewValue': 'VILLAGA (VI)'
  },
  {
    'value': 'L953',
    'viewValue': 'VILLAGRANDE STRISAILI (NU)'
  },
  {
    'value': 'L958',
    'viewValue': 'VILLALAGO (AQ)'
  },
  {
    'value': 'L959',
    'viewValue': 'VILLALBA (CL)'
  },
  {
    'value': 'L961',
    'viewValue': 'VILLALFONSINA (CH)'
  },
  {
    'value': 'L963',
    'viewValue': 'VILLALVERNIA (AL)'
  },
  {
    'value': 'L964',
    'viewValue': 'VILLAMAGNA (CH)'
  },
  {
    'value': 'L965',
    'viewValue': 'VILLAMAINA (AV)'
  },
  {
    'value': 'L966',
    'viewValue': 'VILLAMAR (CA)'
  },
  {
    'value': 'L967',
    'viewValue': 'VILLAMARZANA (RO)'
  },
  {
    'value': 'L968',
    'viewValue': 'VILLAMASSARGIA (CA)'
  },
  {
    'value': 'L970',
    'viewValue': 'VILLAMIROGLIO (AL)'
  },
  {
    'value': 'L971',
    'viewValue': 'VILLANDRO .VILLANDERS. (BZ)'
  },
  {
    'value': 'L978',
    'viewValue': 'VILLANOVA BIELLESE (BI)'
  },
  {
    'value': 'L982',
    'viewValue': 'VILLANOVA CANAVESE (TO)'
  },
  {
    'value': 'L975',
    'viewValue': "VILLANOVA D'ALBENGA (SV)"
  },
  {
    'value': 'L983',
    'viewValue': "VILLANOVA D'ARDENGHI (PV)"
  },
  {
    'value': 'L984',
    'viewValue': "VILLANOVA D'ASTI (AT)"
  },
  {
    'value': 'L973',
    'viewValue': 'VILLANOVA DEL BATTISTA (AV)'
  },
  {
    'value': 'L985',
    'viewValue': 'VILLANOVA DEL GHEBBO (RO)'
  },
  {
    'value': 'L977',
    'viewValue': 'VILLANOVA DEL SILLARO (LO)'
  },
  {
    'value': 'L979',
    'viewValue': 'VILLANOVA DI CAMPOSAMPIERO (PD)'
  },
  {
    'value': 'L988',
    'viewValue': 'VILLANOVA MARCHESANA (RO)'
  },
  {
    'value': 'L974',
    'viewValue': "VILLANOVA MONDOVI' (CN)"
  },
  {
    'value': 'L972',
    'viewValue': 'VILLANOVA MONFERRATO (AL)'
  },
  {
    'value': 'L989',
    'viewValue': 'VILLANOVA MONTELEONE (SS)'
  },
  {
    'value': 'L990',
    'viewValue': 'VILLANOVA SOLARO (CN)'
  },
  {
    'value': 'L980',
    'viewValue': "VILLANOVA SULL'ARDA (PC)"
  },
  {
    'value': 'L991',
    'viewValue': 'VILLANOVA TRUSCHEDU (OR)'
  },
  {
    'value': 'L986',
    'viewValue': 'VILLANOVAFORRU (CA)'
  },
  {
    'value': 'L987',
    'viewValue': 'VILLANOVAFRANCA (CA)'
  },
  {
    'value': 'L992',
    'viewValue': 'VILLANOVATULO (NU)'
  },
  {
    'value': 'L994',
    'viewValue': 'VILLANTERIO (PV)'
  },
  {
    'value': 'L995',
    'viewValue': 'VILLANUOVA SUL CLISI (BS)'
  },
  {
    'value': 'M278',
    'viewValue': 'VILLAPERUCCIO (CA)'
  },
  {
    'value': 'B903',
    'viewValue': 'VILLAPIANA (CS)'
  },
  {
    'value': 'L998',
    'viewValue': 'VILLAPUTZU (CA)'
  },
  {
    'value': 'L999',
    'viewValue': 'VILLAR DORA (TO)'
  },
  {
    'value': 'M007',
    'viewValue': 'VILLAR FOCCHIARDO (TO)'
  },
  {
    'value': 'M013',
    'viewValue': 'VILLAR PELLICE (TO)'
  },
  {
    'value': 'M014',
    'viewValue': 'VILLAR PEROSA (TO)'
  },
  {
    'value': 'M015',
    'viewValue': 'VILLAR SAN COSTANZO (CN)'
  },
  {
    'value': 'M002',
    'viewValue': 'VILLARBASSE (TO)'
  },
  {
    'value': 'M003',
    'viewValue': 'VILLARBOIT (VC)'
  },
  {
    'value': 'M004',
    'viewValue': 'VILLAREGGIA (TO)'
  },
  {
    'value': 'G309',
    'viewValue': 'VILLARICCA (NA)'
  },
  {
    'value': 'M009',
    'viewValue': 'VILLAROMAGNANO (AL)'
  },
  {
    'value': 'M011',
    'viewValue': 'VILLAROSA (EN)'
  },
  {
    'value': 'M016',
    'viewValue': 'VILLASALTO (CA)'
  },
  {
    'value': 'M017',
    'viewValue': 'VILLASANTA (MI)'
  },
  {
    'value': 'B738',
    'viewValue': 'VILLASIMIUS (CA)'
  },
  {
    'value': 'M025',
    'viewValue': 'VILLASOR (CA)'
  },
  {
    'value': 'M026',
    'viewValue': 'VILLASPECIOSA (CA)'
  },
  {
    'value': 'M027',
    'viewValue': 'VILLASTELLONE (TO)'
  },
  {
    'value': 'M028',
    'viewValue': 'VILLATA (VC)'
  },
  {
    'value': 'M030',
    'viewValue': 'VILLAURBANA (OR)'
  },
  {
    'value': 'M031',
    'viewValue': 'VILLAVALLELONGA (AQ)'
  },
  {
    'value': 'M032',
    'viewValue': 'VILLAVERLA (VI)'
  },
  {
    'value': 'L981',
    'viewValue': 'VILLENEUVE (AO)'
  },
  {
    'value': 'M043',
    'viewValue': 'VILLESSE (GO)'
  },
  {
    'value': 'M041',
    'viewValue': 'VILLETTA BARREA (AQ)'
  },
  {
    'value': 'M042',
    'viewValue': 'VILLETTE (VB)'
  },
  {
    'value': 'M044',
    'viewValue': 'VILLIMPENTA (MN)'
  },
  {
    'value': 'M045',
    'viewValue': 'VILLONGO (BG)'
  },
  {
    'value': 'M048',
    'viewValue': 'VILLORBA (TV)'
  },
  {
    'value': 'M050',
    'viewValue': 'VILMINORE DI SCALVE (BG)'
  },
  {
    'value': 'M052',
    'viewValue': 'VIMERCATE (MI)'
  },
  {
    'value': 'M053',
    'viewValue': 'VIMODRONE (MI)'
  },
  {
    'value': 'M055',
    'viewValue': 'VINADIO (CN)'
  },
  {
    'value': 'M057',
    'viewValue': 'VINCHIATURO (CB)'
  },
  {
    'value': 'M058',
    'viewValue': 'VINCHIO (AT)'
  },
  {
    'value': 'M059',
    'viewValue': 'VINCI (FI)'
  },
  {
    'value': 'M060',
    'viewValue': 'VINOVO (TO)'
  },
  {
    'value': 'M062',
    'viewValue': 'VINZAGLIO (NO)'
  },
  {
    'value': 'M063',
    'viewValue': 'VIOLA (CN)'
  },
  {
    'value': 'M065',
    'viewValue': 'VIONE (BS)'
  },
  {
    'value': 'M067',
    'viewValue': 'VIPITENO .STERZING. (BZ)'
  },
  {
    'value': 'H123',
    'viewValue': 'VIRGILIO (MN)'
  },
  {
    'value': 'M069',
    'viewValue': 'VIRLE PIEMONTE (TO)'
  },
  {
    'value': 'M070',
    'viewValue': 'VISANO (BS)'
  },
  {
    'value': 'M071',
    'viewValue': 'VISCHE (TO)'
  },
  {
    'value': 'M072',
    'viewValue': 'VISCIANO (NA)'
  },
  {
    'value': 'M073',
    'viewValue': 'VISCO (UD)'
  },
  {
    'value': 'M077',
    'viewValue': 'VISONE (AL)'
  },
  {
    'value': 'M078',
    'viewValue': 'VISSO (MC)'
  },
  {
    'value': 'M079',
    'viewValue': 'VISTARINO (PV)'
  },
  {
    'value': 'M080',
    'viewValue': 'VISTRORIO (TO)'
  },
  {
    'value': 'M081',
    'viewValue': 'VITA (TP)'
  },
  {
    'value': 'M082',
    'viewValue': 'VITERBO (VT)'
  },
  {
    'value': 'M083',
    'viewValue': 'VITICUSO (FR)'
  },
  {
    'value': 'M085',
    'viewValue': "VITO D'ASIO (PN)"
  },
  {
    'value': 'M086',
    'viewValue': 'VITORCHIANO (VT)'
  },
  {
    'value': 'M088',
    'viewValue': 'VITTORIA (RG)'
  },
  {
    'value': 'M089',
    'viewValue': 'VITTORIO VENETO (TV)'
  },
  {
    'value': 'M090',
    'viewValue': 'VITTORITO (AQ)'
  },
  {
    'value': 'M091',
    'viewValue': 'VITTUONE (MI)'
  },
  {
    'value': 'M093',
    'viewValue': 'VITULANO (BN)'
  },
  {
    'value': 'M092',
    'viewValue': 'VITULAZIO (CE)'
  },
  {
    'value': 'M094',
    'viewValue': "VIU' (TO)"
  },
  {
    'value': 'M096',
    'viewValue': 'VIVARO (PN)'
  },
  {
    'value': 'M095',
    'viewValue': 'VIVARO ROMANO (RM)'
  },
  {
    'value': 'M098',
    'viewValue': 'VIVERONE (BI)'
  },
  {
    'value': 'M100',
    'viewValue': 'VIZZINI (CT)'
  },
  {
    'value': 'M101',
    'viewValue': 'VIZZOLA TICINO (VA)'
  },
  {
    'value': 'M102',
    'viewValue': 'VIZZOLO PREDABISSI (MI)'
  },
  {
    'value': 'M103',
    'viewValue': 'VO (PD)'
  },
  {
    'value': 'M104',
    'viewValue': 'VOBARNO (BS)'
  },
  {
    'value': 'M105',
    'viewValue': 'VOBBIA (GE)'
  },
  {
    'value': 'M106',
    'viewValue': 'VOCCA (VC)'
  },
  {
    'value': 'M108',
    'viewValue': 'VODO DI CADORE (BL)'
  },
  {
    'value': 'M109',
    'viewValue': 'VOGHERA (PV)'
  },
  {
    'value': 'M110',
    'viewValue': 'VOGHIERA (FE)'
  },
  {
    'value': 'M111',
    'viewValue': 'VOGOGNA (VB)'
  },
  {
    'value': 'M113',
    'viewValue': 'VOLANO (TN)'
  },
  {
    'value': 'M115',
    'viewValue': 'VOLLA (NA)'
  },
  {
    'value': 'M116',
    'viewValue': 'VOLONGO (CR)'
  },
  {
    'value': 'M118',
    'viewValue': 'VOLPAGO DEL MONTELLO (TV)'
  },
  {
    'value': 'M119',
    'viewValue': 'VOLPARA (PV)'
  },
  {
    'value': 'M120',
    'viewValue': 'VOLPEDO (AL)'
  },
  {
    'value': 'M121',
    'viewValue': 'VOLPEGLINO (AL)'
  },
  {
    'value': 'M122',
    'viewValue': 'VOLPIANO (TO)'
  },
  {
    'value': 'M125',
    'viewValue': 'VOLTA MANTOVANA (MN)'
  },
  {
    'value': 'M123',
    'viewValue': 'VOLTAGGIO (AL)'
  },
  {
    'value': 'M124',
    'viewValue': 'VOLTAGO AGORDINO (BL)'
  },
  {
    'value': 'M126',
    'viewValue': 'VOLTERRA (PI)'
  },
  {
    'value': 'M127',
    'viewValue': 'VOLTIDO (CR)'
  },
  {
    'value': 'M131',
    'viewValue': 'VOLTURARA APPULA (FG)'
  },
  {
    'value': 'M130',
    'viewValue': 'VOLTURARA IRPINA (AV)'
  },
  {
    'value': 'M132',
    'viewValue': 'VOLTURINO (FG)'
  },
  {
    'value': 'M133',
    'viewValue': 'VOLVERA (TO)'
  },
  {
    'value': 'M136',
    'viewValue': 'VOTTIGNASCO (CN)'
  },
  {
    'value': 'M138',
    'viewValue': 'ZACCANOPOLI (VV)'
  },
  {
    'value': 'M139',
    'viewValue': 'ZAFFERANA ETNEA (CT)'
  },
  {
    'value': 'M140',
    'viewValue': 'ZAGARISE (CZ)'
  },
  {
    'value': 'M141',
    'viewValue': 'ZAGAROLO (RM)'
  },
  {
    'value': 'M142',
    'viewValue': 'ZAMBANA (TN)'
  },
  {
    'value': 'M143',
    'viewValue': 'ZAMBRONE (VV)'
  },
  {
    'value': 'M144',
    'viewValue': 'ZANDOBBIO (BG)'
  },
  {
    'value': 'M145',
    'viewValue': "ZANE' (VI)"
  },
  {
    'value': 'M147',
    'viewValue': 'ZANICA (BG)'
  },
  {
    'value': 'M267',
    'viewValue': 'ZAPPONETA (FG)'
  },
  {
    'value': 'M150',
    'viewValue': 'ZAVATTARELLO (PV)'
  },
  {
    'value': 'M152',
    'viewValue': 'ZECCONE (PV)'
  },
  {
    'value': 'M153',
    'viewValue': 'ZEDDIANI (OR)'
  },
  {
    'value': 'M156',
    'viewValue': 'ZELBIO (CO)'
  },
  {
    'value': 'M158',
    'viewValue': 'ZELO BUON PERSICO (LO)'
  },
  {
    'value': 'M160',
    'viewValue': 'ZELO SURRIGONE (MI)'
  },
  {
    'value': 'M161',
    'viewValue': 'ZEME (PV)'
  },
  {
    'value': 'M162',
    'viewValue': 'ZENEVREDO (PV)'
  },
  {
    'value': 'M163',
    'viewValue': 'ZENSON DI PIAVE (TV)'
  },
  {
    'value': 'M165',
    'viewValue': 'ZERBA (PC)'
  },
  {
    'value': 'M166',
    'viewValue': 'ZERBO (PV)'
  },
  {
    'value': 'M167',
    'viewValue': "ZERBOLO' (PV)"
  },
  {
    'value': 'M168',
    'viewValue': 'ZERFALIU (OR)'
  },
  {
    'value': 'M169',
    'viewValue': 'ZERI (MS)'
  },
  {
    'value': 'M170',
    'viewValue': 'ZERMEGHEDO (VI)'
  },
  {
    'value': 'M171',
    'viewValue': 'ZERO BRANCO (TV)'
  },
  {
    'value': 'M172',
    'viewValue': 'ZEVIO (VR)'
  },
  {
    'value': 'M173',
    'viewValue': 'ZIANO DI FIEMME (TN)'
  },
  {
    'value': 'L848',
    'viewValue': 'ZIANO PIACENTINO (PC)'
  },
  {
    'value': 'M174',
    'viewValue': 'ZIBELLO (PR)'
  },
  {
    'value': 'M176',
    'viewValue': 'ZIBIDO SAN GIACOMO (MI)'
  },
  {
    'value': 'M177',
    'viewValue': 'ZIGNAGO (SP)'
  },
  {
    'value': 'M178',
    'viewValue': 'ZIMELLA (VR)'
  },
  {
    'value': 'M179',
    'viewValue': 'ZIMONE (BI)'
  },
  {
    'value': 'M180',
    'viewValue': 'ZINASCO (PV)'
  },
  {
    'value': 'M182',
    'viewValue': 'ZOAGLI (GE)'
  },
  {
    'value': 'M183',
    'viewValue': 'ZOCCA (MO)'
  },
  {
    'value': 'M184',
    'viewValue': 'ZOGNO (BG)'
  },
  {
    'value': 'M185',
    'viewValue': 'ZOLA PREDOSA (BO)'
  },
  {
    'value': 'I345',
    'viewValue': 'ZOLDO ALTO (BL)'
  },
  {
    'value': 'M187',
    'viewValue': 'ZOLLINO (LE)'
  },
  {
    'value': 'M188',
    'viewValue': 'ZONE (BS)'
  },
  {
    'value': 'M189',
    'viewValue': "ZOPPE' DI CADORE (BL)"
  },
  {
    'value': 'M190',
    'viewValue': 'ZOPPOLA (PN)'
  },
  {
    'value': 'M194',
    'viewValue': 'ZOVENCEDO (VI)'
  },
  {
    'value': 'M196',
    'viewValue': 'ZUBIENA (BI)'
  },
  {
    'value': 'M197',
    'viewValue': 'ZUCCARELLO (SV)'
  },
  {
    'value': 'M198',
    'viewValue': 'ZUCLO (TN)'
  },
  {
    'value': 'M199',
    'viewValue': 'ZUGLIANO (VI)'
  },
  {
    'value': 'M200',
    'viewValue': 'ZUGLIO (UD)'
  },
  {
    'value': 'M201',
    'viewValue': 'ZUMAGLIA (BI)'
  },
  {
    'value': 'M202',
    'viewValue': 'ZUMPANO (CS)'
  },
  {
    'value': 'M203',
    'viewValue': 'ZUNGOLI (AV)'
  },
  {
    'value': 'M204',
    'viewValue': 'ZUNGRI (VV)'
  }
];

export const codiciCatastaliStranieri = [
  { 'viewValue': 'ALBANIA', 'value': 'Z100' },
  { 'viewValue': 'ANDORRA', 'value': 'Z101' },
  { 'viewValue': 'AUSTRIA', 'value': 'Z102' },
  { 'viewValue': 'BELGIO', 'value': 'Z103' },
  { 'viewValue': 'BULGARIA', 'value': 'Z104' },
  { 'viewValue': 'CECOSLOVACCHIA', 'value': 'Z105' },
  { 'viewValue': 'STATO CITTA DEL VATICANO', 'value': 'Z106' },
  { 'viewValue': 'DANIMARCA', 'value': 'Z107' },
  { 'viewValue': 'ISOLE FAER OER', 'value': 'Z108' },
  { 'viewValue': 'FINLANDIA', 'value': 'Z109' },
  { 'viewValue': 'FRANCIA', 'value': 'Z110' },
  { 'viewValue': 'GERMANIA REPUBBLICA DEMOCRATICA', 'value': 'Z111' },
  { 'viewValue': 'GERMANIA', 'value': 'Z112' },
  { 'viewValue': 'GIBILTERRA', 'value': 'Z113' },
  { 'viewValue': 'REGNO UNITO', 'value': 'Z114' },
  { 'viewValue': 'GRECIA', 'value': 'Z115' },
  { 'viewValue': 'IRLANDA', 'value': 'Z116' },
  { 'viewValue': 'ISLANDA', 'value': 'Z117' },
  { 'viewValue': 'IUGOSLAVIA', 'value': 'Z118' },
  { 'viewValue': 'LIECHTENSTEIN', 'value': 'Z119' },
  { 'viewValue': 'LUSSEMBURGO', 'value': 'Z120' },
  { 'viewValue': 'MALTA', 'value': 'Z121' },
  { 'viewValue': 'ISOLA DI MAN', 'value': 'Z122' },
  { 'viewValue': 'MONACO', 'value': 'Z123' },
  { 'viewValue': 'NORMANNE (ISOLE) O ISOLE DEL CANALE', 'value': 'Z124' },
  { 'viewValue': 'NORVEGIA', 'value': 'Z125' },
  { 'viewValue': 'PAESI BASSI', 'value': 'Z126' },
  { 'viewValue': 'POLONIA', 'value': 'Z127' },
  { 'viewValue': 'PORTOGALLO', 'value': 'Z128' },
  { 'viewValue': 'ROMANIA', 'value': 'Z129' },
  { 'viewValue': 'SAN MARINO', 'value': 'Z130' },
  { 'viewValue': 'SPAGNA', 'value': 'Z131' },
  { 'viewValue': 'SVEZIA', 'value': 'Z132' },
  { 'viewValue': 'SVIZZERA', 'value': 'Z133' },
  { 'viewValue': 'UNGHERIA', 'value': 'Z134' },
  { 'viewValue': 'UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE', 'value': 'Z135' },
  { 'viewValue': 'GEORGIA', 'value': 'Z136' },
  { 'viewValue': 'ARMENIA', 'value': 'Z137' },
  { 'viewValue': 'UCRAINA', 'value': 'Z138' },
  { 'viewValue': 'BIELORUSSIA', 'value': 'Z139' },
  { 'viewValue': 'MOLDOVA', 'value': 'Z140' },
  { 'viewValue': 'AZERBAIGIAN', 'value': 'Z141' },
  { 'viewValue': 'KIRGHIZISTAN', 'value': 'Z142' },
  { 'viewValue': 'UZBEKISTAN', 'value': 'Z143' },
  { 'viewValue': 'ESTONIA', 'value': 'Z144' },
  { 'viewValue': 'LETTONIA', 'value': 'Z145' },
  { 'viewValue': 'LITUANIA', 'value': 'Z146' },
  { 'viewValue': 'TAGIKISTAN', 'value': 'Z147' },
  { 'viewValue': 'MACEDONIA DEL NORD', 'value': 'Z148' },
  { 'viewValue': 'CROAZIA', 'value': 'Z149' },
  { 'viewValue': 'SLOVENIA', 'value': 'Z150' },
  { 'viewValue': 'TURKMENISTAN', 'value': 'Z151' },
  { 'viewValue': 'KAZAKHSTAN', 'value': 'Z152' },
  { 'viewValue': 'BOSNIA-ERZEGOVINA', 'value': 'Z153' },
  { 'viewValue': 'FEDERAZIONE RUSSA', 'value': 'Z154' },
  { 'viewValue': 'SLOVACCHIA', 'value': 'Z155' },
  { 'viewValue': 'REPUBBLICA CECA', 'value': 'Z156' },
  { 'viewValue': 'SERBIA E MONTENEGRO', 'value': 'Z157' },
  { 'viewValue': 'SERBIA', 'value': 'Z158' },
  { 'viewValue': 'MONTENEGRO', 'value': 'Z159' },
  { 'viewValue': 'KOSOVO', 'value': 'Z160' },
  { 'viewValue': 'ALGERIA', 'value': 'Z301' },
  { 'viewValue': 'ANGOLA', 'value': 'Z302' },
  { 'viewValue': 'BASUTOLAND', 'value': 'Z303' },
  { 'viewValue': 'BECIUANIA', 'value': 'Z304' },
  { 'viewValue': 'BENIN', 'value': 'Z314' },
  { 'viewValue': 'BOPHUTHATSWANA', 'value': 'Z364' },
  { 'viewValue': 'BOTSWANA', 'value': 'Z358' },
  { 'viewValue': 'BURKINA FASO', 'value': 'Z354' },
  { 'viewValue': 'BURUNDI', 'value': 'Z305' },
  { 'viewValue': 'CAMERUN', 'value': 'Z306' },
  { 'viewValue': 'CAPO VERDE', 'value': 'Z307' },
  { 'viewValue': 'CIAD', 'value': 'Z309' },
  { 'viewValue': 'CISKEI', 'value': 'Z367' },
  { 'viewValue': 'COMORE', 'value': 'Z310' },
  { 'viewValue': 'CONGO', 'value': 'Z311' },
  { 'viewValue': 'CONGO BELGA', 'value': 'Z370' },
  { 'viewValue': "COSTA D'AVORIO", 'value': 'Z313' },
  { 'viewValue': 'EGITTO', 'value': 'Z336' },
  { 'viewValue': 'ERITREA', 'value': 'Z368' },
  { 'viewValue': 'ESWATINI', 'value': 'Z349' },
  { 'viewValue': 'ETIOPIA', 'value': 'Z315' },
  { 'viewValue': 'GABON', 'value': 'Z316' },
  { 'viewValue': 'GAMBIA', 'value': 'Z317' },
  { 'viewValue': 'GHANA', 'value': 'Z318' },
  { 'viewValue': 'GIBUTI', 'value': 'Z361' },
  { 'viewValue': 'GUINEA', 'value': 'Z319' },
  { 'viewValue': 'GUINEA BISSAU', 'value': 'Z320' },
  { 'viewValue': 'GUINEA EQUATORIALE', 'value': 'Z321' },
  { 'viewValue': 'IFNI', 'value': 'Z323' },
  { 'viewValue': 'KENYA', 'value': 'Z322' },
  { 'viewValue': 'LESOTHO', 'value': 'Z359' },
  { 'viewValue': 'LIBERIA', 'value': 'Z325' },
  { 'viewValue': 'LIBIA', 'value': 'Z326' },
  { 'viewValue': 'MADAGASCAR', 'value': 'Z327' },
  { 'viewValue': 'MALAWI', 'value': 'Z328' },
  { 'viewValue': 'MALI', 'value': 'Z329' },
  { 'viewValue': 'MAROCCO', 'value': 'Z330' },
  { 'viewValue': 'MAURITANIA', 'value': 'Z331' },
  { 'viewValue': 'MAURITIUS', 'value': 'Z332' },
  { 'viewValue': 'MAYOTTE', 'value': 'Z360' },
  { 'viewValue': 'MOZAMBICO', 'value': 'Z333' },
  { 'viewValue': 'NAMIBIA', 'value': 'Z300' },
  { 'viewValue': 'NIGER', 'value': 'Z334' },
  { 'viewValue': 'NIGERIA', 'value': 'Z335' },
  { 'viewValue': 'NYASALAND', 'value': 'Z369' },
  { 'viewValue': 'REPUBBLICA CENTRAFRICANA', 'value': 'Z308' },
  { 'viewValue': 'REPUBBLICA DEMOCRATICA DEL CONGO', 'value': 'Z312' },
  { 'viewValue': 'RIUNIONE', 'value': 'Z324' },
  { 'viewValue': 'RUANDA', 'value': 'Z338' },
  { 'viewValue': 'SAHARA MERIDIONALE', 'value': 'Z362' },
  { 'viewValue': 'SAHARA SETTENTRIONALE', 'value': 'Z363' },
  { 'viewValue': 'SAHARA SPAGNOLO', 'value': 'Z339' },
  { 'viewValue': "SANT'ELENA", 'value': 'Z340' },
  { 'viewValue': "SAO TOME' E PRINCIPE", 'value': 'Z341' },
  { 'viewValue': 'SENEGAL', 'value': 'Z343' },
  { 'viewValue': 'SEYCHELLES', 'value': 'Z342' },
  { 'viewValue': 'SIERRA LEONE', 'value': 'Z344' },
  { 'viewValue': 'SOMALIA', 'value': 'Z345' },
  { 'viewValue': 'SUD AFRICA', 'value': 'Z347' },
  { 'viewValue': 'SUD SUDAN', 'value': 'Z907' },
  { 'viewValue': 'SUDAN', 'value': 'Z348' },
  { 'viewValue': 'TANGANICA', 'value': 'Z350' },
  { 'viewValue': 'TANZANIA', 'value': 'Z357' },
  { 'viewValue': 'TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA', 'value': 'Z346' },
  { 'viewValue': 'TOGO', 'value': 'Z351' },
  { 'viewValue': 'TRANSKEI', 'value': 'Z365' },
  { 'viewValue': 'TUNISIA', 'value': 'Z352' },
  { 'viewValue': 'UGANDA', 'value': 'Z353' },
  { 'viewValue': 'VENDA', 'value': 'Z366' },
  { 'viewValue': 'ZAMBIA', 'value': 'Z355' },
  { 'viewValue': 'ZANZIBAR', 'value': 'Z356' },
  { 'viewValue': 'ZIMBABWE', 'value': 'Z337' },
  { 'viewValue': 'AFGHANISTAN', 'value': 'Z200' },
  { 'viewValue': 'ARABIA MERIDIONALE FEDERAZIONE', 'value': 'Z201' },
  { 'viewValue': 'ARABIA MERIDIONALE PROTETTORATO', 'value': 'Z202' },
  { 'viewValue': 'ARABIA SAUDITA', 'value': 'Z203' },
  { 'viewValue': 'ARMENIA', 'value': 'Z252' },
  { 'viewValue': 'AZERBAIGIAN', 'value': 'Z253' },
  { 'viewValue': 'BAHREIN', 'value': 'Z204' },
  { 'viewValue': 'BANGLADESH', 'value': 'Z249' },
  { 'viewValue': 'BHUTAN', 'value': 'Z205' },
  { 'viewValue': 'BRUNEI', 'value': 'Z207' },
  { 'viewValue': 'CAMBOGIA', 'value': 'Z208' },
  { 'viewValue': 'CIPRO', 'value': 'Z211' },
  { 'viewValue': 'COCOS (ISOLE)', 'value': 'Z212' },
  { 'viewValue': 'EMIRATI ARABI UNITI', 'value': 'Z215' },
  { 'viewValue': 'FILIPPINE', 'value': 'Z216' },
  { 'viewValue': 'GAZA (TERRITORIO DI)', 'value': 'Z218' },
  { 'viewValue': 'GEORGIA', 'value': 'Z254' },
  { 'viewValue': 'GERUSALEMME', 'value': 'Z260' },
  { 'viewValue': 'GIAPPONE', 'value': 'Z219' },
  { 'viewValue': 'GIORDANIA', 'value': 'Z220' },
  { 'viewValue': 'HONG KONG', 'value': 'Z221' },
  { 'viewValue': 'INDIA', 'value': 'Z222' },
  { 'viewValue': 'INDONESIA', 'value': 'Z223' },
  { 'viewValue': 'IRAN', 'value': 'Z224' },
  { 'viewValue': 'IRAQ', 'value': 'Z225' },
  { 'viewValue': 'ISRAELE', 'value': 'Z226' },
  { 'viewValue': 'KAZAKHSTAN', 'value': 'Z255' },
  { 'viewValue': 'KIRGHIZISTAN', 'value': 'Z256' },
  { 'viewValue': 'KUWAIT', 'value': 'Z227' },
  { 'viewValue': 'LAOS', 'value': 'Z228' },
  { 'viewValue': 'LIBANO', 'value': 'Z229' },
  { 'viewValue': 'MACAO', 'value': 'Z231' },
  { 'viewValue': 'MALAYSIA', 'value': 'Z230' },
  { 'viewValue': 'MALDIVE', 'value': 'Z232' },
  { 'viewValue': 'MONGOLIA', 'value': 'Z233' },
  { 'viewValue': 'MYANMAR', 'value': 'Z206' },
  { 'viewValue': 'NEPAL', 'value': 'Z234' },
  { 'viewValue': 'OMAN', 'value': 'Z235' },
  { 'viewValue': 'PAKISTAN', 'value': 'Z236' },
  { 'viewValue': 'QATAR', 'value': 'Z237' },
  { 'viewValue': 'REPUBBLICA DI COREA', 'value': 'Z213' },
  { 'viewValue': 'REPUBBLICA POPOLARE CINESE', 'value': 'Z210' },
  { 'viewValue': 'REPUBBLICA POPOLARE DEMOCRATICA DI COREA', 'value': 'Z214' },
  { 'viewValue': 'RYUKYU (ISOLE)', 'value': 'Z238' },
  { 'viewValue': 'SIKKIM', 'value': 'Z239' },
  { 'viewValue': 'SINGAPORE', 'value': 'Z248' },
  { 'viewValue': 'SIRIA', 'value': 'Z240' },
  { 'viewValue': 'SRI LANKA', 'value': 'Z209' },
  { 'viewValue': 'TAGIKISTAN', 'value': 'Z257' },
  { 'viewValue': 'TAIWAN', 'value': 'Z217' },
  { 'viewValue': "TERRITORI DELL'AUTONOMIA PALESTINESE", 'value': 'Z161' },
  { 'viewValue': 'THAILANDIA', 'value': 'Z241' },
  { 'viewValue': 'TIMOR ORIENTALE', 'value': 'Z242' },
  { 'viewValue': 'TURCHIA', 'value': 'Z243' },
  { 'viewValue': 'TURKMENISTAN', 'value': 'Z258' },
  { 'viewValue': 'UZBEKISTAN', 'value': 'Z259' },
  { 'viewValue': 'VIETNAM', 'value': 'Z251' },
  { 'viewValue': 'VIETNAM DEL NORD', 'value': 'Z245' },
  { 'viewValue': 'VIETNAM DEL SUD', 'value': 'Z244' },
  { 'viewValue': 'YEMEN', 'value': 'Z246' },
  { 'viewValue': 'YEMEN REPUBBLICA DEMOCRATICA POPOLARE', 'value': 'Z250' },
  { 'viewValue': 'BERMUDA', 'value': 'Z400' },
  { 'viewValue': 'CANADA', 'value': 'Z401' },
  { 'viewValue': 'GROENLANDIA', 'value': 'Z402' },
  { 'viewValue': 'SAINT PIERRE E MIQUELON', 'value': 'Z403' },
  { 'viewValue': "STATI UNITI D'AMERICA", 'value': 'Z404' },
  { 'viewValue': 'ANGUILLA', 'value': 'Z529' },
  { 'viewValue': 'ANTIGUA E BARBUDA', 'value': 'Z532' },
  { 'viewValue': 'ANTILLE BRITANNICHE', 'value': 'Z500' },
  { 'viewValue': 'ANTILLE BRITANNICHE', 'value': 'Z523' },
  { 'viewValue': 'ANTILLE BRITANNICHE', 'value': 'Z521' },
  { 'viewValue': 'ANTILLE OLANDESI', 'value': 'Z501' },
  { 'viewValue': 'BAHAMAS', 'value': 'Z502' },
  { 'viewValue': 'BARBADOS', 'value': 'Z522' },
  { 'viewValue': 'BELIZE', 'value': 'Z512' },
  { 'viewValue': 'COSTA RICA', 'value': 'Z503' },
  { 'viewValue': 'CUBA', 'value': 'Z504' },
  { 'viewValue': 'DOMINICA', 'value': 'Z526' },
  { 'viewValue': 'EL SALVADOR', 'value': 'Z506' },
  { 'viewValue': 'GIAMAICA', 'value': 'Z507' },
  { 'viewValue': 'GRENADA', 'value': 'Z524' },
  { 'viewValue': 'GUADALUPA', 'value': 'Z508' },
  { 'viewValue': 'GUATEMALA', 'value': 'Z509' },
  { 'viewValue': 'HAITI', 'value': 'Z510' },
  { 'viewValue': 'HONDURAS', 'value': 'Z511' },
  { 'viewValue': 'ISOLE CAYMAN', 'value': 'Z530' },
  { 'viewValue': 'ISOLE TURKS E CAICOS', 'value': 'Z519' },
  { 'viewValue': 'ISOLE VERGINI BRITANNICHE', 'value': 'Z525' },
  { 'viewValue': 'MARTINICA', 'value': 'Z513' },
  { 'viewValue': 'MESSICO', 'value': 'Z514' },
  { 'viewValue': 'MONTSERRAT', 'value': 'Z531' },
  { 'viewValue': 'NICARAGUA', 'value': 'Z515' },
  { 'viewValue': 'PANAMA', 'value': 'Z516' },
  { 'viewValue': 'PANAMA ZONA DEL CANALE', 'value': 'Z517' },
  { 'viewValue': 'PUERTO RICO', 'value': 'Z518' },
  { 'viewValue': 'REPUBBLICA DOMINICANA', 'value': 'Z505' },
  { 'viewValue': 'SAINT KITTS E NEVIS', 'value': 'Z533' },
  { 'viewValue': 'SAINT LUCIA', 'value': 'Z527' },
  { 'viewValue': 'SAINT VINCENT E GRENADINE', 'value': 'Z528' },
  { 'viewValue': 'VERGINI AMERICANE (ISOLE)', 'value': 'Z520' },
  { 'viewValue': 'ARGENTINA', 'value': 'Z600' },
  { 'viewValue': 'BOLIVIA', 'value': 'Z601' },
  { 'viewValue': 'BRASILE', 'value': 'Z602' },
  { 'viewValue': 'CILE', 'value': 'Z603' },
  { 'viewValue': 'COLOMBIA', 'value': 'Z604' },
  { 'viewValue': 'ECUADOR', 'value': 'Z605' },
  { 'viewValue': 'GUYANA', 'value': 'Z606' },
  { 'viewValue': 'GUYANA FRANCESE', 'value': 'Z607' },
  { 'viewValue': 'MALVINE O FALKLAND (ISOLE)', 'value': 'Z609' },
  { 'viewValue': 'PARAGUAY', 'value': 'Z610' },
  { 'viewValue': "PERU'", 'value': 'Z611' },
  { 'viewValue': 'SURINAME', 'value': 'Z608' },
  { 'viewValue': 'TRINIDAD E TOBAGO', 'value': 'Z612' },
  { 'viewValue': 'URUGUAY', 'value': 'Z613' },
  { 'viewValue': 'VENEZUELA', 'value': 'Z614' },
  { 'viewValue': 'AUSTRALIA', 'value': 'Z700' },
  { 'viewValue': 'CAROLINE (ISOLE)=STATI FEDERATI DI MICRONESIA', 'value': 'Z701' },
  { 'viewValue': 'CHRISTMAS (ISOLA)', 'value': 'Z702' },
  { 'viewValue': 'FIGI', 'value': 'Z704' },
  { 'viewValue': 'GILBERT E ELLICE (ISOLE)', 'value': 'Z705' },
  { 'viewValue': 'GUAM (ISOLA)', 'value': 'Z706' },
  { 'viewValue': 'IRIAN OCCIDENTALE', 'value': 'Z707' },
  { 'viewValue': 'ISOLE CILENE (PASQUA E SALA Y GOMEZ)', 'value': 'Z721' },
  { 'viewValue': 'ISOLE COOK', 'value': 'Z703' },
  { 'viewValue': 'ISOLE MARSHALL', 'value': 'Z711' },
  { 'viewValue': 'ISOLE PITCAIRN', 'value': 'Z722' },
  { 'viewValue': 'ISOLE SALOMONE', 'value': 'Z724' },
  { 'viewValue': 'ISOLE WALLIS E FUTUNA', 'value': 'Z729' },
  { 'viewValue': 'KIRIBATI', 'value': 'Z731' },
  { 'viewValue': 'MACQUARIE (ISOLE)', 'value': 'Z708' },
  { 'viewValue': 'MARCUS (ISOLE)', 'value': 'Z709' },
  { 'viewValue': 'MARIANNE (ISOLE)', 'value': 'Z710' },
  { 'viewValue': 'MIDWAY (ISOLE)', 'value': 'Z712' },
  { 'viewValue': 'NAURU', 'value': 'Z713' },
  { 'viewValue': 'NIUE', 'value': 'Z714' },
  { 'viewValue': 'NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)', 'value': 'Z715' },
  { 'viewValue': 'NUOVA CALEDONIA', 'value': 'Z716' },
  { 'viewValue': 'NUOVA GUINEA', 'value': 'Z718' },
  { 'viewValue': 'NUOVA ZELANDA', 'value': 'Z719' },
  { 'viewValue': 'NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)', 'value': 'Z717' },
  { 'viewValue': 'PALAU', 'value': 'Z734' },
  { 'viewValue': 'PAPUA NUOVA GUINEA', 'value': 'Z730' },
  { 'viewValue': 'PAPUASIA', 'value': 'Z720' },
  { 'viewValue': 'POLINESIA FRANCESE (ISOLE)', 'value': 'Z723' },
  { 'viewValue': 'SAMOA', 'value': 'Z726' },
  { 'viewValue': 'SAMOA AMERICANE (ISOLE)', 'value': 'Z725' },
  { 'viewValue': 'STATI FEDERATI DI MICRONESIA', 'value': 'Z735' },
  { 'viewValue': "TOKELAU O ISOLE DELL'UNIONE", 'value': 'Z727' },
  { 'viewValue': 'TONGA', 'value': 'Z728' },
  { 'viewValue': 'TUVALU', 'value': 'Z732' },
  { 'viewValue': 'VANUATU', 'value': 'Z733' }
];

export const tavola_carattere_di_controllo_valore_caratteri_pari = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
  O: 14,
  P: 15,
  Q: 16,
  R: 17,
  S: 18,
  T: 19,
  U: 20,
  V: 21,
  W: 22,
  X: 23,
  Y: 24,
  Z: 25
};

export const tavola_carattere_di_controllo = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
