import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateFormatBe, ProfileRoles, ProfileType } from '@constants/constants';
import { UserService } from '@core/services/user.service';
import { environment } from '@env/environment';
import { IGenericHttpResponse } from '@modules/login/login.interface';
import { SelectInputType } from '@modules/wizard-sales/store/sales.state';
import { map, Observable, tap } from 'rxjs';
import { Training, TrainingList } from '../store/training.state';
import { Operator } from './../store/training.state';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  public newCourse: any;
  public GET_TRAININGLIST_URL: string = '/training/list';
  public GET_AGENT_FROM_AGENCY_URL: string = '/operator/list?cod_agen=';
  public SEND_EDIT__COURSE_URL: string = '/training';
  public DELETE__COURSE_URL: string = '/training/?uuid=';
  public CHECK_PASSED_URL: string = '/training/check-passed';
  public GET_OPERATOR_URL: string = '/operator/preview?agency=';

  constructor(private http: HttpClient, private userService: UserService) {}

  getTrainingList(filter: any): Observable<TrainingList> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_TRAININGLIST_URL}`, { params: filter }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        data: res.data as Partial<Training>[],
        totalCount: res.totalCount || 100
      })),
      map(res => ({
        data: res.data.map(res => {
          return {
            agency: res.agency,
            channel: res.channel,
            courseName: res.courseName,
            endDate: res.endDate ? new Date(res.endDate!).toISOString() : '',
            id: res.id,
            profile: res.profile,
            required: res.required,
            paFunctionality: res.paFunctionality,
            slides: res.slides,
            startDate: res.startDate ? new Date(res.startDate!).toISOString() : '',
            survey: res.survey,
            uuid: res.uuid,
            gotSurvey: res.gotSurvey,
            description: res.description,
            allAgencies: res.allAgencies
          };
        }),
        totalCount: res.totalCount
      }))
    );
  }

  getOperator(agency: string): Observable<{ data: SelectInputType[] }> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_OPERATOR_URL + `${agency}`}`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        data: res.data as Partial<Operator>[]
      })),
      map(res => ({
        data: res.data.map(res => {
          return {
            value: res.operatorCode,
            viewValue: res.name + ' ' + res.surname + ' ' + res.operatorCode
          };
        }) as SelectInputType[]
      }))
    );
  }

  getAgentFromAgency(agencyId: string) {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_AGENT_FROM_AGENCY_URL + `${agencyId}&active=true&blacklist_active=false`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        data: res.data as Partial<Operator>[]
      })),
      map(res => ({
        data: res.data.map((res: any) => {
          return {
            value: res.codOperatore,
            viewValue: res.name + ' ' + res.surname + ' ' + res.codOperatore
          };
        }) as SelectInputType[]
      }))
    );
  }

  sendNewCourse(course: Partial<Training>): Observable<Partial<Training>> {
    delete course.gotSurvey;
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.SEND_EDIT__COURSE_URL}`, course).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Partial<Training>),
      map(res => ({
        agency: res.agency,
        channel: res.channel,
        courseName: res.courseName,
        endDate: res.endDate ? format(new Date(res.endDate!), DateFormatBe) : '',
        id: res.id,
        profile: res.profile,
        required: res.required,
        paFunctionality: res.paFunctionality,
        slides: res.slides,
        startDate: res.startDate ? format(new Date(res.startDate!), DateFormatBe) : '',
        gotSurvey: res.survey?.length! > 0,
        survey: res.survey,
        uuid: res.uuid,
        description: res.description,
        allAgencies: res.allAgencies
      }))
    );
  }

  checkPassedCourse(course: Partial<Training>): Observable<Partial<Training>> {
    delete course.gotSurvey;
    if (!course.survey?.length) delete course.survey;
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.CHECK_PASSED_URL}`, course).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Partial<Training>),
      map(res => ({
        agency: res.agency,
        channel: res.channel,
        courseName: res.courseName,
        endDate: res.endDate ? format(new Date(res.endDate), DateFormatBe) : '',
        id: res.id,
        profile: res.profile,
        required: res.required,
        paFunctionality: res.paFunctionality,
        slides: res.slides,
        startDate: res.startDate ? format(new Date(res.startDate), DateFormatBe) : '',
        gotSurvey: res.survey?.length! > 0,
        survey: res.survey,
        uuid: res.uuid,
        description: res.description,
        allAgencies: res.allAgencies,
        score: res.score,
        passed: res.passed
      }))
    );
  }

  editCourse(course: Partial<Training>): Observable<Partial<Training>> {
    return this.http.put<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.SEND_EDIT__COURSE_URL}`, course).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Partial<Training>)
    );
  }

  deleteCourse(uuid: string): Observable<IGenericHttpResponse> {
    return this.http.delete<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.DELETE__COURSE_URL}${uuid}`);
  }
}
