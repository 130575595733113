import { StipulationMethodValue } from '@constants/constants';
import { Vas } from '@modules/catalog/catalog.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesState, SurveyRc } from './sales.state';

export const SalesStoreKey = 'SalesStoreKey';
export const SalesSelector = createFeatureSelector<SalesState>(SalesStoreKey);
export const SalesStepperRoutesSelector = createSelector(SalesSelector, state => state.stepperRoutes);
export const SalesPrevenditaTypeSelector = createSelector(SalesSelector, state => state.prevenditaType);
export const SalesInitialCheckSelector = createSelector(SalesSelector, state => state.initialCheck);
export const SalesEsitoSelector = createSelector(SalesSelector, state => state.esito);
export const SalesEsitoDualSelector = createSelector(SalesSelector, state => state.esitoDual);
export const SalesEsitoTrueSelector = createSelector(SalesSelector, state => state.esitoDone);
export const SalesEsitoVasSelectedSelector = createSelector(SalesSelector, state => state.selectedVas);
export const SalesContrattualizzazioneSelector = createSelector(SalesSelector, state => state.contrattualizzazione);
export const SalesCommoditySelector = createSelector(SalesSelector, state => state.commodity);
export const SalesVasSelector = createSelector(SalesSelector, state => state.vas);
export const SalesRaccoltaDatiSelector = createSelector(SalesSelector, state => state.raccoltaDati);
export const SalesRcaSummarySelector = createSelector(SalesSelector, state => state.rcaSummary);
export const SalesRaccoltaCambioOffertaSelector = createSelector(SalesSelector, state => state.cambioOffertaData);
export const SalesRiepilogoSelector = createSelector(SalesSelector, state => state.riepilogo);
export const SalesInvioSelector = createSelector(SalesSelector, state => state.invio);
export const SalesUUIDSelector = createSelector(SalesSelector, state => state.uuid);
export const SalesDocsRequiredSelector = createSelector(SalesSelector, state => state.docsRequired);
export const SalesOver85Selector = createSelector(SalesSelector, state => state.over85);
export const SalesSportelloSelector = createSelector(SalesSelector, state => state.sportello);
export const SummaryRaccoltaDatiSelector = createSelector(SalesSelector, state => {
  return {
    randomCode: state.randomCode,
    idQuoteRepricing: state.idQuoteRepricing,
    vas: state.vas,
    commodity: state.commodity,
    contrattualizzazione: state.contrattualizzazione,
    initialCheck: state.initialCheck,
    prevenditaType: state.prevenditaType,
    selectedVas: state.selectedVas,
    raccoltaDati: state.raccoltaDati,
    riepilogo: state.riepilogo,
    rcaSummary: state.rcaSummary,
    opLuminea: state.opLuminea,
    surveyRc: state.surveyRc,
    rcaCoverageSelected: state.rcaCoverageSelected,
    esito: state.esito,
    vulnerabilityModule: state.vulnerabilityModule,
    ongoingPracticeState: state.ongoingPracticeState,
    connettivitaSev: state.connettivitaSev
  };
});
export const SalesRiepilogoAllSelector = createSelector(SalesSelector, state => {
  return { vas: state.vas, commodity: state.commodity, contrattualizzazione: state.contrattualizzazione, initialCheck: state.initialCheck, raccoltaDati: state.raccoltaDati, selectedVas: state.selectedVas, riepilogo: state.riepilogo };
});
export const SalesLeavingSelector = createSelector(SalesSelector, state => state.leaving);
export const randomCodeSelector = createSelector(SalesSelector, state => state.randomCode);
export const SalesVasListBundleSelector = createSelector(SalesSelector, state => {
  let list = state.commodity?.commodityOffer?.value?.vasList?.map((el: Partial<Vas>) => ({ value: el, viewValue: el.vasName }));
  let stipulationMethod = state.contrattualizzazione?.stipulationMethod?.value;
  if (stipulationMethod !== StipulationMethodValue.IREN_DIGITAL_SIGNATURE && stipulationMethod !== StipulationMethodValue.PRECOMPILED_PLICO && stipulationMethod !== StipulationMethodValue.EXT_PARTNER_SIGNATURE) {
    return list?.filter(el => el.value?.vasType !== 'MGA') || [];
  }
  return list;
});
export const SalesLastIndex = createSelector(SalesSelector, state => state.lastIndex);
export const SalesVasInBundleIsLuminea = createSelector(SalesSelector, state => state.vas?.vasOffer?.value?.vasType === 'MGA' || state.vas?.vasOffer?.value?.vasType === 'RCA');
export const SalesPrewinBack = createSelector(SalesSelector, state => state.prewinBack);
export const ChangeOfferGoBackSelector = createSelector(SalesSelector, state => state.changeOfferGoBack);
export const RcaSummarySelector = createSelector(SalesSelector, state => state.rcaSummary);
export const RcaCoveragesSelector = createSelector(SalesSelector, state => state.rcaCoverage);
export const RcaCoveragesSelectedSelector = createSelector(SalesSelector, state => state.rcaCoverageSelected);
export const RcaCoveragesFromPackSelectedSelector = createSelector(RcaCoveragesSelectedSelector, state => state.quote?.coverages);
export const SurveyRcSelector = createSelector(SalesSelector, state => state.surveyRc);
export const BrokenBPSelector = createSelector(SalesSelector, state => state.brokenBP);
export const ConnettivtaSevSelector = createSelector(SalesSelector, state => state.connettivitaSev);
export const CheckIndirizzoInstallazioneConnettivitaSelector = createSelector(SalesSelector, state => state.isIndirizzoInstallazioneConnettivita);
