import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clearEmptyKeyFromObject } from '@constants/constants';
import { environment } from '@env/environment';
import { IGenericHttpResponse } from '@modules/login/login.interface';
import { Store } from '@ngrx/store';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import * as State from '../store/sales.state';
import { CheckBillingProfileRequest, CheckBillingProfileResponse, CheckPodPdrResponse, GetPdrResponse, GetPodResponse } from './sales-common.interfaces';
import { TrustpilotScoreAgency } from '@modules/trustpilot/store/trustpilot.state';

@Injectable({
  providedIn: 'root'
})
export class SalesApiService {
  private GET_SALES_INITIAL_CHECK_URLV3: string = '/v3/precheck/full';
  private GET_SALES_VAS_INITIAL_CHECK_URL: string = '/customer-check';
  private GET_POD_URL: string = '/registry/ele-dl';
  private UPLOAD_PRECOMPILED_PLICO: string = '/sales/precompiled/signed';
  private UPLOAD_VERBAL_ORDER: string = '/postsales/upload-verbalOrdering-doc';
  private GET_MP3_CONTR: string = '/multi-verbal-order';
  private POST_MP3_CONTR: string = '/upload/multi-verbal-order';
  private ARCHIVE_DOCUMENT: string = '/archive-document';
  private GET_PDR_URL: string = '/registry/gas-dl';
  private BILLING_CONTRACT_URL: string = '/billing-contract';
  private SAVE_DRAFT_PRACTIVE_URL: string = '/sale-state';
  private CHECK_CONNETIVITY_URL: string = '/check-offer';
  private CHECK_CF_WHITELIST_URL: string = '/ageLead/ageCheck';
  private CHECK_MARKETABILITY_URL: string = '/nds/vendibilita-vas';
  private OUTGOING_SELLERS_URL: string = '/venditori-uscenti';
  private SPORTELLO_STORES_URL: string = '/sportello';
  private RANDOM_CODE: string = '/random-code';
  private SWITCHIN_DUAL: string = '/sales/switchin/dual';
  private SWITCHIN_MONO: string = '/sales/switchin/mono';
  private LUMINEA_VAS: string = '/sales/vas_luminea';
  private ATTIVAZIONE_SUBENTRO: string = '/sales/attivazione-subentro';
  private ATTIVAZIONE_SUBENTRO_DUAL: string = '/sales/attivazione-subentro/dual';
  private CAMBIO_OFFERTA: string = '/sales/change/offer';
  private VOLTURA: string = '/sales/voltura';
  private VOLTURA_SWITCHING: string = '/sales/voltura-switching';
  private UPLOAD_DOCUMENT: string = '/sales/upload-document';
  private VAS_STANDALONE: string = '/nds/sales/standalone';
  private FORNITURE_ADDRESS: string = '/forniture/indirizzo';
  private CHECK_DISCOUNT_VAS: string = '/check/discount';

  constructor(private http: HttpClient, private store: Store) {}

  getSalesInitialCheck(body: Partial<State.SalesInitialCheck>, newCustomer: boolean): Observable<any> {
    return this.http.post<any>(environment.PA_PRIVATE_BASE_URL + this.GET_SALES_INITIAL_CHECK_URLV3, body).pipe(
      tap(response => {
        if (response.status.codice !== '000' && response.status.codice !== '205' && response.status.codice !== '409' && response.status.codice !== '408' && response.status.codice !== '406' && response.status.codice !== '407') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => ({
        'contestability': res.contestability,
        'contestabilityStatus': res.contestabilityStatus,
        'correlationId': res.correlationId,
        'creditCheck': res.creditCheck,
        'customerCheck': res.customerCheck,
        'eligibilities': res.eligibilities,
        'pointStatus': res.pointStatus,
        'saleabilityGas':
          res.pointStatus === 'Attivo con noi'
            ? {
                codice: '200',
                esito: 1
              }
            : res.saleabilityGas
            ? res.saleabilityGas
            : newCustomer
            ? {
                codice: '200',
                esito: 1
              }
            : {
                codice: '202',
                esito: 2
              },
        'status': res.status
      }))
    );
  }
  getSalesVasInitialCheck(body: Partial<State.SalesInitialCheck>): Observable<any> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_SALES_VAS_INITIAL_CHECK_URL, { params: clearEmptyKeyFromObject(body) }).pipe(
      tap(response => {
        if (response.status.codice !== '000' && response.status.codice !== '205' && response.status.codice !== '202') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res)
    );
  }
  getPod(body: any): Observable<Partial<GetPodResponse>> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_POD_URL, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as Partial<GetPodResponse>)
    );
  }
  uploadPrecompiledPlico(body: UploadPrecompiledPlicoRequest, typeValue?: string): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.UPLOAD_PRECOMPILED_PLICO, body, { params: { type: typeValue || '' } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.status.codice === '000')
    );
  }
  uploadVerbalOrder(body: UploadPrecompiledPlicoRequest): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.UPLOAD_VERBAL_ORDER, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.status.codice === '000')
    );
  }

  getMp3Contr(randomCode: string): Observable<string> {
    return this.http.get<IGenericHttpResponse<string>>(environment.PA_PUBLIC_BASE_URL + this.GET_MP3_CONTR, { params: { randomCode: randomCode } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data)
    );
  }

  uploadMp3Contr(body: UploadPrecompiledPlicoRequest): Observable<string> {
    return this.http.get<IGenericHttpResponse<string>>(environment.PA_PUBLIC_BASE_URL + this.GET_MP3_CONTR, { params: { randomCode: body.randomCode } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data),
      switchMap(res => {
        const finalBody = {
          'documento': body,
          'oldFieldName': res,
          'randomCode': body.randomCode
        };
        return this.http.post<IGenericHttpResponse<boolean>>(environment.PA_PUBLIC_BASE_URL + this.POST_MP3_CONTR, finalBody).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res.status.codice === '000')
        );
      }),
      switchMap(() => {
        return this.http.get<IGenericHttpResponse<string>>(environment.PA_PUBLIC_BASE_URL + this.GET_MP3_CONTR, { params: { randomCode: body.randomCode } }).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res.data)
        );
      })
    );
  }

  archiveDocument(body: UploadDocumentRequest): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.ARCHIVE_DOCUMENT, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.status.codice === '000')
    );
  }
  getPdr(body: any): Observable<Partial<GetPdrResponse>> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_PDR_URL, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res as Partial<GetPdrResponse>)
    );
  }
  checkBillingProfile(body: Partial<CheckBillingProfileRequest>): Observable<Partial<CheckBillingProfileResponse>> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.BILLING_CONTRACT_URL, { params: body }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as Partial<CheckBillingProfileResponse>)
    );
  }
  saveDraftPractice(body: any): Observable<any> {
    if (body.mock) delete body.mock;
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.SAVE_DRAFT_PRACTIVE_URL, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res as any)
    );
  }

  saveFinalSalesState(body: any, type: string): Observable<any> {
    if (body.mock) delete body.mock;
    switch (type) {
      case 'lumineaVas':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.LUMINEA_VAS, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'switchinDual':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.SWITCHIN_DUAL, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'switchinMono':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.SWITCHIN_MONO, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'attivazioneSubentro':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.ATTIVAZIONE_SUBENTRO, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'attivazioneSubentroDual':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.ATTIVAZIONE_SUBENTRO_DUAL, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'voltura':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.VOLTURA, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'volturaDual': //DA CAMBIARE API
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.VOLTURA, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'volturaSwitch':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.VOLTURA_SWITCHING, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'volturaSwitchDual': //DA CAMBIARE API
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.VOLTURA_SWITCHING, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'cambioOfferta':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.CAMBIO_OFFERTA, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'cambioOffertaDual': //DA CAMBIARE API
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.CAMBIO_OFFERTA, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      case 'vasStandalone':
        return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.VAS_STANDALONE, body).pipe(
          tap(response => {
            if (response.status.codice !== '000') {
              const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
              //this.errorService.setError(errorMessage)
              throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
            }
          }),
          map(res => res as any)
        );
      default:
        return of(null);
    }
  }

  checkConnetivity(body: any): Observable<any> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PUBLIC_BASE_URL + this.CHECK_CONNETIVITY_URL, { params: clearEmptyKeyFromObject(body) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res as any)
    );
  }
  checkMarketability(body: any): Observable<any> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PUBLIC_BASE_URL + this.CHECK_MARKETABILITY_URL, clearEmptyKeyFromObject(body)).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res as any)
    );
  }

  checkCfWhitelist(cf: string) {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.CHECK_CF_WHITELIST_URL, { params: { codiceFiscale: cf } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.status.codice === '000')
    );
  }

  getVenditoriUscenti(): Observable<OutGoingSeller[]> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.OUTGOING_SELLERS_URL).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data.venditoreUscente as OutGoingSeller[]),
      map(res =>
        res.map(el => ({
          codiceUnivoco: el.codiceUnivoco,
          company: el.company,
          id: el.id,
          nome: el.nome,
          startDate: el.startDate
        }))
      )
    );
  }
  getSportelli(userCompany: string): Observable<Partial<SportelliResponse>[]> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.SPORTELLO_STORES_URL).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as SportelliResponse[]),
      map(res =>
        res
          .filter(el => !el.isDeleted && (el.companyAppartenenza === userCompany || !userCompany))
          .map(el => ({ name: el.name, agencySfid: el.agencySfid, sfid: el.sfid, uploadFiles: el.uploadFiles, scoreAgency: el.scoreAgency } as Partial<SportelliResponse>))
          .sort((a, b) => a.name!.localeCompare(b.name!))
      )
    );
  }
  getRandomCode(): Observable<string> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.RANDOM_CODE).pipe(
      tap(response => {
        if (response?.status?.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as string)
    );
  }

  getFea(): Observable<any> {
    return of({
      feaType: 'otp',
      documentType: "CARTA D'IDENTITA",
      expiredDocDate: '12/02/2015'
    });
  }

  uploadDocument(body: any): Observable<any> {
    if (body.mock) delete body.mock;
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.UPLOAD_DOCUMENT, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as string)
    );
  }

  getRcaInfoByPlate(body: any): Observable<RcaInfoByPlate> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.UPLOAD_DOCUMENT, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => ({ value: res.data, isFast: !!res.data } as RcaInfoByPlate))
    );
  }

  //https://iren-pa-web-uatd.herokuapp.com/api/proxy/private/forniture/indirizzo?podPdr=IT001E93499817

  //https://iren-pa-web-dev.herokuapp.com/api/proxy/private

  checkPodPdr(body: { podPdr: string }) {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.FORNITURE_ADDRESS, { params: clearEmptyKeyFromObject(body) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as CheckPodPdrResponse)
    );
  }

  getCheckCodiceScontoVas(body: Partial<CodiceScontoVasRequest>) {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.CHECK_DISCOUNT_VAS, { params: clearEmptyKeyFromObject(body) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as Partial<CodiceScontoVasResponse>[])
    );
  }

  ageCheck(cf: string) {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + '/ageLead/ageCheck', { params: { codiceFiscale: cf } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data)
    );
  }
}

export interface OutGoingSeller {
  codiceUnivoco: string;
  company: string;
  id: string;
  nome: string;
  startDate: string;
}

export interface CodiceScontoVasResponse {
  scontoCanone: boolean;
  Id: string;
  Prodotto_Zuora__c: string;
  Codice_voucher_sconto_formula__c: string;
  Valore_Sconto__c: number;
  Codice_promozione__c: string;
  Durata_Sconto__c: number;
}

export interface CodiceScontoVasRequest {
  productType: string;
  tipoCommodity: string;
  codiceVoucher: string;
  prodottoZuora: string;
  isValid: boolean;
}

export interface SportelliResponse {
  createdDate: string;
  isDeleted: boolean;
  name: string;
  systemModStamp: string;
  sfid: string;
  id: number;
  hcLastop: string;
  hcErr: string;
  calendarId: number;
  citta: string;
  prenotaTicket: string;
  statoSportello: string;
  prenotaAppuntamento: string;
  orari: string;
  telefono: number;
  companyAppartenenza: string;
  categoria: string;
  cap: number;
  paginaPrenotaAppuntamento: string;
  notePubbliche: string;
  regione: string;
  indirizzo: string;
  provincia: string;
  poi: string;
  visibileIrenYou: boolean;
  tipologiaSportello: string;
  esposizioneProdottiIrenGo: string;
  commodityGestite: string;
  ecoSportelli: boolean;
  esposizioneProdottiIrenPlus: string;
  geolocationLatitude: number;
  geolocationLongitude: number;
  googleCard: string;
  prioritaRegione: number;
  agencySfid: string;
  uploadFiles: { id: number; tipoFile: string }[];
  scoreAgency: Partial<TrustpilotScoreAgency>[];
}
export interface RcaInfoByPlate<T = any> {
  value: T;
  isFast: boolean;
}

export interface UploadPrecompiledPlicoRequest {
  base64: string;
  nomeFile: string;
  randomCode: string;
  tipoDoc: string;
  tipoFile: string;
  acceptanceDate?: string;
}

export interface UploadDocumentRequest {
  base64: string;
  quoteId: string;
  acceptanceDate?: string;
  documentName: string;
  documentType: string;
}

export interface SalesFileRequest {
  nomeFile: string;
  tipoFile: string;
  base64: string;
  randomCode: string;
  key?: string;
}
