import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalCtaTypes, ModalStatus, ModalTitles, notificationType, openDialog, ProfileType } from '@constants/constants';
import { NotificationService } from '@core/services/notification.service';
import { UserService } from '@core/services/user.service';
import { UtilityService } from '@core/services/utility.service';
import { EmptyStoreState, IStoreState } from '@core/store/store.state';
import { environment } from '@env/environment';
import { LoginService } from '@modules/login/services/login.service';
import { TrainingService } from '@modules/training/services/training.service';
import { TrainingLoadedListAction } from '@modules/training/store/training.actions';
import { ExitFunnelComponent } from '@modules/wizard-sales/common-components/exit-funnel/exit-funnel.component';
import { SalesService } from '@modules/wizard-sales/services/sales.service';
import { SalesLeavingAction } from '@modules/wizard-sales/store/sales.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OtpInputModalComponent } from '@shared/otp-input/otp-input-modal/otp-input-modal.component';
import { GetAllAgencyAction, SetAllAgencyAction, SetMandatoryTrainingFlagAction, SetListaComuni, getListaComuni, ResetPaStoreKey } from '../store.action';
import { HomepageService } from '@modules/homepage/services/homepage.service';
import { retry, take, tap } from 'rxjs/operators';
import {
  AuthGetAuthAction,
  AuthGetOtpAction,
  AuthLogoutAction,
  AuthLogoutFunnelAction,
  AuthSetAuthFirstAction,
  AuthSetProfileAction,
  SetProfileAction,
  NotificationsGetDailyTaskListAction,
  NotificationsGetListAction,
  NotificationsSetListAction,
  NotificationsUpdateVisualizedAction
} from '../user/user.actions';
import { AuthSelector } from './user.selectors';
import { addHours, isAfter, parseISO } from 'date-fns';
import { KickOutModalComponent } from '@shared/kickout-modal/kickout-modal.component';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private loginService: LoginService,
    private store: Store<IStoreState>,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private salesService: SalesService,
    private trainingService: TrainingService,
    private homepageService: HomepageService
  ) {}

  AuthLogoutFunnelAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthLogoutFunnelAction),
        tap(() => {
          let dialogRef = this.dialog.open(ExitFunnelComponent, {
            width: '300px',
            disableClose: true,
            data: { logout: true }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.salesService.IsExitFromFunnel = true;
              this.store.dispatch(SalesLeavingAction({ leaving: true }));
              this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '2' }));
            }
          });
        })
      ),
    { dispatch: false }
  );

  AuthLogoutAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthLogoutAction),
        tap(action => {
          this.store
            .select(AuthSelector)
            .pipe(take(1))
            .subscribe({
              next: auth => {
                if (auth?.expirationDate && !action.noLogout) {
                  const expirationDate = parseISO(auth.expirationDate);
                  const now = new Date();
                  const oneHourLater = addHours(expirationDate, 3); //3 ore per allineare expDate a ora locale
                  const afterOneHour = isAfter(now, oneHourLater);

                  if (auth?.idToken && !afterOneHour) {
                    this.loginService.logout().subscribe({
                      next: () => {
                        this.store.dispatch(ResetPaStoreKey({ emptyStoreState: EmptyStoreState }));
                        if (action.goToLogin) {
                          this.router.navigateByUrl('/login');
                        }
                      },
                      error: () => {
                        this.store.dispatch(ResetPaStoreKey({ emptyStoreState: EmptyStoreState }));
                        if (action.goToLogin) {
                          this.router.navigateByUrl('/login');
                        }
                      }
                    });
                  } else {
                    this.store.dispatch(ResetPaStoreKey({ emptyStoreState: EmptyStoreState }));
                    if (action.goToLogin) {
                      this.router.navigateByUrl('/login');
                    }
                  }
                } else {
                  this.store.dispatch(ResetPaStoreKey({ emptyStoreState: EmptyStoreState }));
                  if (action.goToLogin) {
                    this.router.navigateByUrl('/login');
                  }
                }
              }
            });
        })
      ),
    { dispatch: false }
  );

  AuthGetAuthAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthGetAuthAction),
        tap(res => {
          this.loginService.login(res.credentials).subscribe({
            next: auth => {
              const expiresAt = environment.expiredSession * 60;
              localStorage.setItem('remainingTime', expiresAt + '');
              localStorage.setItem('expiredModalTime', (environment.expiredSessionModal * 60).toString());
              if (auth.firstAccess) {
                this.store.dispatch(AuthSetAuthFirstAction({ auth: auth }));
                this.router.navigate(['/otp', 'input']);
              } else if (!auth.mfa) {
                this.store.dispatch(AuthSetAuthFirstAction({ auth: auth }));
                this.router.navigate(['/choice-mfa']);
              } else {
                this.store.dispatch(AuthSetAuthFirstAction({ auth: auth }));
                this.dialog
                  .open(OtpInputModalComponent, {
                    disableClose: true,
                    data: { firstAccess: auth.firstAccess, type: '', mfa: auth.mfa }
                  })
                  .afterClosed()
                  .subscribe(res => {
                    if (!res) {
                      this.utilityService.loading.next(false);
                      this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '3' }));
                    } else if (res.valid) {
                      this.store.dispatch(AuthGetOtpAction({ otp: res.val, firstAccess: auth.firstAccess!, auth: auth!, mfa: auth.mfa! }));
                    }
                  });
              }
            },
            error: e => {
              this.utilityService.loading.next(false);
              let message;
              switch (e.code) {
                case '415':
                  message = e.error?.message;
                  break;
                case '402':
                  message = e.error?.message;
                  break;
                case '401':
                  message = 'Credenziali invalide';
                  break;
                default:
                  message = 'Si è verificato un errore in fase di login';
                  break;
              }
              openDialog({
                dialog: this.dialog,
                errorCode: e.code,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.LOGIN,
                message: message,
                noCopy: true
              })
                .afterClosed()
                .subscribe(() => this.router.navigate(['/my-area']));
            }
          });
        })
      ),
    { dispatch: false }
  );

  AuthGetOtpAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthGetOtpAction),
        tap(action => {
          this.loginService.otp({ otp: action.otp, transactionId: action.auth.transactionId || '' }).subscribe({
            next: res => {
              //SE LA CHIAMATA VA IN 200 E NON C'è MFA RIMANDAMIAMO ALLA PAGINA DI CONFERMA REGISTRAZIONE OTP,
              if (res.otp && action.auth.googleQrCode) {
                this.dialog.closeAll();
                this.router.navigate(['/otp', 'confirmed', !!action.firstAccess]);
              }
              //SE LA CHIAMATA VA IN 200 E NON è PRIMO ACCESSO SI RIMANDA A HOMEPAGE
              else if (res.otp && action.mfa) {
                this.dialog.closeAll();
                this.store.dispatch(AuthSetProfileAction());
              } else {
                this.utilityService.loading.next(false);
                openDialog({
                  dialog: this.dialog,
                  errorCode: '',
                  status: ModalStatus.ERROR,
                  ctaType: ModalCtaTypes.CLOSE,
                  title: ModalTitles.LOGIN,
                  message: 'OTP NON VALIDO'
                })
                  .afterClosed()
                  .subscribe(() => this.router.navigate(['/my-area']));
                this.store.dispatch(AuthLogoutAction({ goToLogin: false, number: '4' }));
              }
            },
            error: () => {
              // SE LA CHIAMATA FALLISCE SI AVVISA L'UTENTE E SI RIMANDA ALLA LOGIN
              this.utilityService.loading.next(false);
              openDialog({
                dialog: this.dialog,
                errorCode: '',
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.LOGIN,
                message: 'OTP NON VALIDO'
              })
                .afterClosed()
                .subscribe(() => this.router.navigate(['/my-area']));
              this.store.dispatch(AuthLogoutAction({ goToLogin: false, number: '5' }));
            }
          });
        })
      ),
    { dispatch: false }
  );
  AuthSetProfileAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthSetProfileAction),
        tap(() => {
          this.loginService.profile().subscribe({
            next: res => {
              this.userService.setUserProfile(res);
              this.store.dispatch(SetProfileAction({ profile: res }));
              if (this.userService.getUserProfile()?.agency?.sfid) {
                this.trainingService
                  .getTrainingList({ pageNumber: 0, pageSize: 10, onlyUnpassed: true, paFunctionality: true, operatorId: this.userService.getUserProfile()?.operatorId, agencyId: this.userService.getUserProfile()?.agency?.sfid, profile: this.userService.getUserProfile().profile?.name })
                  .subscribe({
                    next: list => {
                      this.store.dispatch(TrainingLoadedListAction({ trainingList: list.data, totalCount: list.totalCount }));
                      if (list.data.length > 0) {
                        openDialog({
                          dialog: this.dialog,
                          message: 'Devi completare il corso obbligatorio prima di accedere al portale',
                          ctaType: ModalCtaTypes.CLOSE,
                          title: 'Attenzione!'
                        });
                        this.store.dispatch(SetMandatoryTrainingFlagAction({ hasMandatoryTraining: true }));
                        this.router.navigate(['/training-required']);
                      }
                    },
                    error: e => {
                      openDialog({
                        dialog: this.dialog,
                        errorCode: e.code,
                        status: ModalStatus.ERROR,
                        ctaType: ModalCtaTypes.CLOSE,
                        title: ModalTitles.HOMEPAGE
                      });
                    },
                    complete: () => {
                      if (res.profile?.type !== ProfileType.EDITOR) this.store.dispatch(GetAllAgencyAction());
                    }
                  });
              } else {
                if (res.profile?.type !== ProfileType.EDITOR) this.store.dispatch(GetAllAgencyAction());
              }
            },
            error: e => {
              openDialog({
                dialog: this.dialog,
                errorCode: e.code,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.LOGIN,
                message: e.error?.message
              });
              this.utilityService.loading.next(false);
              this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '6' }));
            }
          });
        })
      ),
    { dispatch: false }
  );

  NotificationsUpdateVisualizedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsUpdateVisualizedAction),
        tap(res => {
          this.notificationService.updateVisualizedNotification(res?.notification?.id!).subscribe({
            next: () => {
              const route = notificationType.find(type => type.key === res.notification?.notification?.type)?.route;
              if (route) {
                if (res.notification.notification?.redirectObject) {
                  let option = { queryParams: { filter: res.notification.notification?.redirectObject } };
                  this.router.navigate([route], option);
                } else {
                  this.router.navigate([route]);
                }
              }
            },
            error: e => {
              openDialog({
                dialog: this.dialog,
                errorCode: e.code,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.LOGIN,
                message: e.error?.message
              });
            }
          });
        })
      ),
    { dispatch: false }
  );

  NotificationsGetListAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsGetListAction),
      switchMap(action => {
        if (action.notificationType === 'DAILY_TASK') {
          return this.notificationService.getNotificationList(action.operatorId, action.unread, action.notificationType).pipe(
            map(task => NotificationsGetDailyTaskListAction({ dailyTask: task })),
            catchError(e => {
              openDialog({
                dialog: this.dialog,
                errorCode: e.code,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.HOMEPAGE
              });
              return of(NotificationsGetDailyTaskListAction({ dailyTask: [] }));
            })
          );
        } else {
          return this.notificationService.getNotificationList(action.operatorId, action.unread, action.notificationType).pipe(
            map(notifications => NotificationsSetListAction({ notifications })),
            catchError(e => {
              openDialog({
                dialog: this.dialog,
                errorCode: e.code,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.HOMEPAGE
              });
              return of(NotificationsSetListAction({ notifications: [] }));
            })
          );
        }
      })
    )
  );

  GetAllAgencyAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GetAllAgencyAction),
        switchMap(() => {
          const filter: any = {};
          const userProfile = this.userService.getUserProfile();

          // Recupera lo sfid dal profilo
          const userSfid = userProfile.agency?.sfid;

          // Se lo sfid non è presente nel profilo, interrompi il flusso
          if (!userSfid && userProfile.profile?.type === ProfileType.EXTERNAL_SALES) {
            this.showErrorDialog();
            return EMPTY;
          }

          // Controlla se il profilo utente è tra quelli consentiti
          if (userProfile.profile?.type === ProfileType.EXTERNAL_SALES) {
            filter.agency = userProfile.agency?.sfid;
          }

          // Effettua chiamata per recupero agenzie
          return this.utilityService.getAllAgency(filter).pipe(
            retry(3), // Prova 3 volte a recuperare i dati
            map(res => {
              const agency = res.length > 0 ? res.find(el => el.sfid === userProfile.agency?.sfid) : undefined;

              // Controllo sulla presenza dell'agenzia e del tipo di profilo
              if (!agency && userProfile.profile?.type === ProfileType.EXTERNAL_SALES) {
                this.showErrorDialog();
                return EMPTY;
              }

              // Dispatch delle azioni per settare il profilo e agenzia sullo store
              this.store.dispatch(SetProfileAction({ profile: { ...userProfile, agency: agency } }));
              this.store.dispatch(SetAllAgencyAction({ agencyList: res }));

              // Gestione del routing in base al tipo di profilo
              const profileType = userProfile.profile?.type;
              if (profileType === ProfileType.BOE) {
                this.router.navigate(['/my-area/homepage-boe']);
              } else if (profileType === ProfileType.EDITOR) {
                this.router.navigate(['/my-area/editor']);
              } else {
                this.router.navigate(['/my-area']);
              }

              return EMPTY;
            }),
            catchError(err => {
              this.showErrorDialog();
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: false }
  );

  private showErrorDialog() {
    this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '99' }));

    let dialogRef = this.dialog.open(KickOutModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.utilityService.loading.next(false);
    });
  }

  getListaComuni = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getListaComuni),
        tap(() =>
          this.homepageService.getCodiceCatastaleComuni().subscribe({
            next: res => {
              this.store.dispatch(SetListaComuni({ listaComuni: res }));
            },
            error: e => {
              openDialog({
                dialog: this.dialog,
                errorCode: e.code,
                status: ModalStatus.ERROR,
                ctaType: ModalCtaTypes.CLOSE,
                title: ModalTitles.LOGIN,
                message: e.error?.message
              });
            }
          })
        )
      ),
    { dispatch: false }
  );
}
