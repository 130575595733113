import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clearEmptyKeyFromObject, ModalCtaTypes, ModalStatus, ModalTitles, openDialog } from '@constants/constants';
import { environment } from '@env/environment';
import { IGenericHttpResponse } from '@modules/login/login.interface';
import { map, Observable, of, tap } from 'rxjs';
import { UploadDoc } from '../upload-doc.interface';
import { UserService } from '@services/user.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PostSalesService {
  public GET_CONTRACT_URL: string = '/contract';
  public GET_REGISTRY_URL: string = '/anagrafica';
  public SEND_VARIATION_REGISTRY_COMMERCIAL_URL: string = '/postsales/variazione-anagrafica';
  public SDD: string = '/postsales/sdd-module';
  public GET_COMPATIBILITY_SFDC_URL: string = '/process-compatibility';
  public GET_CAMPAIGN_CARING_URL: string = '/check-user-campaign';
  public CHANGE_PAYMENT_METHOD_URL: string = '/postsales/';
  public GET_PLICO_VAS_URL: string = '/plico-crm';
  private isExitFromFunnel: boolean = false;
  constructor(private http: HttpClient, private userService: UserService, private dialog: MatDialog) {}

  get IsExitFromPostSales() {
    return this.isExitFromFunnel;
  }
  set IsExitFromPostSales(bool: boolean) {
    this.isExitFromFunnel = bool;
  }

  getContract(filter: Partial<ContractRequest>, noActive?: boolean) {
    if (!noActive) filter.onlyActive = true;
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_CONTRACT_URL}`, { params: clearEmptyKeyFromObject(filter) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as ContractResponse)
    );
  }

  getMock(cf: string) {
    /*     return of([
      {
        status: 'non ANNULLATO',
        randomCode: 'AX123',
        podPdr: '214321312312',
        commodity: 'commodity',
        dataCreazioneRandom: '07/06/2014',
        dataSottoscrizione: '07/06/2014'
      }
    ]) */
    return of([
      {
        status: 'no ANNULLATO',
        randomCode: 'AX123',
        podPdr: '214321312312',
        commodity: 'commodity',
        dataCreazioneRandom: '07/06/2014',
        dataSottoscrizione: '07/06/2014'
      },
      {
        status: 'no ANNULLATO',
        randomCode: 'AB456',
        podPdr: '214321312312',
        commodity: 'commodity',
        dataCreazioneRandom: '07/06/2014',
        dataSottoscrizione: '07/06/2014'
      }
    ]);
  }

  getRegistry(filter: Partial<RegistryRequest>, nofilter?: boolean, noError?: boolean): Observable<Registry> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_REGISTRY_URL}`, { params: clearEmptyKeyFromObject(filter) }).pipe(
      tap(response => {
        if (noError && response.status.codice !== '000' && response.status.codice !== '001') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
        } else if (!noError && response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
        }
      }),
      map(res => res.data.account as Partial<RegistryResponse>[]),
      map(res => {
        if (!nofilter) return (res.find(account => account.dbProvenienza === 'SFDC' && account.gruppoAutorizzazione === this.userService.getUserProfile().authorizationGroup && account.societa == null && account.sfOrg === 'ENERGY') as Partial<RegistryResponse>) || ({} as Partial<RegistryResponse>);
        else return (res.find(account => account.dbProvenienza === 'SFDC' && account.societa == null && account.sfOrg === 'ENERGY') as Partial<RegistryResponse>) || ({} as Partial<RegistryResponse>);
      }),
      tap(res => {
        if ((!res || !res.idAccount) && !noError) {
          openDialog({
            dialog: this.dialog,
            title: ModalTitles.POST_SALES,
            ctaType: ModalCtaTypes.CLOSE,
            status: ModalStatus.GENERIC,
            message: 'Il cliente ha contratti solo con altre Company'
          });
        }
      }),
      map(res => ({
        idAccount: res.idAccount || '',
        name: res.nome || '',
        surname: res.cognome || '',
        company: res.cognomeNome || '',
        cf: res.codiceFiscale || '',
        email: res.indirizzoEmail || '',
        birthDate: res.dataNascita || '',
        birthPlace: res.luogoNascita || '',
        mobilePhone: res.cellulare || '',
        phone: res.telefono || '',
        primaryContactKey: res.chiaveAlReferenteTitolare || '',
        address: res.indirizzo || '',
        buildingNumber: res.civico || '',
        city: res.citta || '',
        country: res.provincia || '',
        cap: res.cap || '',
        residenceAddress: res.pressoResidenza || '',
        legalNonResidentialAddress: res.sedeLegaleNonResidenziale || '',
        gruppoAutorizzazione: res.gruppoAutorizzazione || '',
        residenceStreet: res.stradaDiResidenza || '',
        cellulare: res.cellulare || '',
        consensoTrattamentoDati: res.consensoTrattamentoDati || false,
        consensoAllaProfilazione: res.consensoAllaProfilazione || false,
        consensoCessioneDatiaTerzi: res.consensoCessioneDatiaTerzi || false,
        consensoTrattamentoDatiFiniCommerciali: res.consensoTrattamentoDatiFiniCommerciali || false,
        ragioneSociale: res.ragioneSociale || '',
        vulnerabile104: res.vulnerabile104 || '',
        vulnerabileBonus: res.vulnerabileBonus || '',
        vulnerabileSae: res.vulnerabileSae || '',
        vulnerabileEta: res.vulnerabileEta || '',
        vulnerabilitySaeEle: res.vulnerabilitySaeEle || '',
        vulnerabilityL104Ele: res.vulnerabilityL104Ele || '',
        vulnerabilityBonusEle: res.vulnerabilityBonusEle || '',
        vulnerabilityIsoleMinori: res.vulnerabilityIsoleMinori || '',
        vulnerabilityMacchinariSalvavita: res.vulnerabilityMacchinariSalvavita || '',
        vulnerabilityMacchinariSalvavitaAltri: res.vulnerabilityMacchinariSalvavitaAltri || '',
        primaryContact: res.tipoAccount !== 'RES' ? res.contacts?.find(el => el.idReferente_f === res.chiaveAlReferenteTitolare)! : null
      }))
    );
  }

  getCampaignCaring(accountId: string): Observable<Partial<CampaignCaringResponse>[]> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_CAMPAIGN_CARING_URL}`, { params: { account: accountId } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
        }
      }),
      map(res => res.data as Partial<CampaignCaringResponse>[]),
      map(res =>
        res.map(el => ({
          id: el.id,
          campaignId: el.campaignId,
          companyOrAccount: el.companyOrAccount,
          contactAccountId: el.contactAccountId
        }))
      )
    );
  }

  sddModule(body: Partial<Sdd>) {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.SDD}`, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'Post sales- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      })
    );
  }

  sendVariationRegistry(body: Partial<VariationRegistry>): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.SEND_VARIATION_REGISTRY_COMMERCIAL_URL}`, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.status.codice === '000')
    );
  }

  sendVariationCommercial(body: Partial<RegistryVariation>): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.SEND_VARIATION_REGISTRY_COMMERCIAL_URL}`, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.status.codice === '000')
    );
  }

  getCompatibilitySFDC(filter: Partial<CheckSDFCRequest>): Observable<Partial<IGenericHttpResponse>> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PUBLIC_BASE_URL + this.GET_COMPATIBILITY_SFDC_URL}`, { params: clearEmptyKeyFromObject(filter) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
        }
      }),
      map(res => ({
        status: {
          codice: res.status?.codice,
          esito: res.status?.esito,
          descrizione: res.status?.descrizione
        },
        data: {
          code: res.data?.codice || '',
          result: res.data?.esito || '',
          compatibilityResult: {
            resultDetail: res.data?.esitoCompatibilita?.dettaglioesito || '',
            result: res.data?.esitoCompatibilita?.esitocompatibilita || ''
          },
          reason: res.data?.motivazione || ''
        }
      }))
    );
  }

  getContractUploadDoc(cf?: string): Observable<UploadDoc> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL}/getPost-salesUploadDoc/?cf=${cf}`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as UploadDoc),
      map(res => ({
        id: res.id,
        idWO: res.idWO,
        idCase: res.idCase,
        alias: res.alias,
        tipoProcesso: res.tipoProcesso,
        tipoCommodity: res.tipoCommodity,
        statoCaseItem: res.statoCaseItem,
        dataCreazione: res.dataCreazione,
        numeroCliente: res.numeroCliente,
        irenContractNumber: res.irenContractNumber,
        dataChiusura: res.dataChiusura,
        dataAccettazPreventivo: res.dataAccettazPreventivo,
        dataAppuntamento: res.dataAppuntamento,
        fasciaAppuntamento: res.fasciaAppuntamento,
        statoAppuntamento: res.statoAppuntamento,
        caricaDocContratto: res.caricaDocContratto,
        identityDoc: res.identityDoc,
        propertyOccupationTitleDoc: res.propertyOccupationTitleDoc,
        buildingPermit: res.buildingPermit
      }))
    );
  }

  changePaymentMethod(body?: Partial<PaymentMethodRequest>): Observable<IGenericHttpResponse> {
    let flow = body?.variazioniMOP?.tipo_lavorazione === 'Attivazione SDD' ? 'attivazione-mop' : 'variazione-mop';
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.CHANGE_PAYMENT_METHOD_URL}${flow}`, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
        }
      })
    );
  }

  getLinkPlicoVas(idQuote: string) {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_PLICO_VAS_URL}`, { params: { quoteId: idQuote } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'POST SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw new Error(errorMessage);
        }
      })
    );
  }
}

export interface ContractRequest {
  podpdr: string;
  piva: string;
  maxnumcommodity: string;
  maxmesicessazione: string;
  i_massive: string;
  contractnumber: string;
  cf: string;
  accountid: string;
  onlyActive: boolean;
}

export interface CampaignCaringResponse {
  id: string;
  campaignId: string;
  companyOrAccount: string;
  contactAccountId: string;
}

export interface ContractResponse {
  contractPadre: ContractPadre[];
  uuid: string;
}

export interface ContractPadre {
  alias: string;
  bicSwift: string;
  bonusIrenPay: string;
  bpAccountSAP: string;
  capFatturazione: string;
  capFornitura: string;
  categoriaUso: string;
  cig: string;
  civicoFatturazione: string;
  civicoFornitura: string;
  cognomeSottoscrittore: string;
  classePrelievo: string;
  clienteResidente: boolean;
  codDestinatario: string;
  codiceAssenzaDatiCatastali: string;
  codiceComuneCatastale: string;
  codiceFiscaleCliente: string;
  codiceFiscaleIntestatario: string;
  cognomeIntestatario: string;
  comuneFatturazione: string;
  comuneFornitura: string;
  consumoAnnuoH2o: string;
  consumoAnnuoStimato: number;
  contactRiferimento: string;
  contractAccountSAP: string;
  contrattoSAP: string;
  cup: string;
  dataAttivazione: string;
  dataCessazione: string;
  dataInizioFormaContrattuale: string;
  dbProvenienza: string;
  emailRecapitoBollettaWeb: string;
  estensioneParticella: string;
  fatturazCongiunta: boolean;
  flagFatturazioneCongiunta: boolean;
  foglio: string;
  gruppoAutorizzazione: string;
  iban: string;
  idAccount: string;
  idBillingProfile: string;
  idContratto: string;
  idFornitura: string;
  idMandato: string;
  idPayment: string;
  idProdotto: string;
  idQuote: string;
  idQuoteLine: string;
  idServicePoint: string;
  idSubscription: string;
  indPecFatturaz: string;
  indirizzoDiFatturazione: string;
  indirizzoDiFornitura: string;
  internoFatturazione: string;
  internoFornitura: string;
  irenContractNumber: string;
  istatFatturazione: string;
  listaContractFiglio: ContractFiglio[];
  livelloDiTensione: string;
  metodoDiPagamento: string;
  nazioneFatturazione: string;
  nazioneFornitura: string;
  nomeBanca: string;
  nomeContract: string;
  nomeIntestatario: string;
  numUtenzeAltriUsi: string;
  numUtenzeDomNONRes: string;
  numUtenzeDomNonRes: string;
  numUtenzeDomRes: string;
  numUtenzeEntiAUPubblici: string;
  numUtenzeEntiAuPubblici: string;
  numeroMandato: string;
  numeroTotaleResidenti: string;
  numeroUnitServite: string;
  nomeSottoscrittore: string;
  origineMercatoRientro: string;
  particella: string;
  partitaIvaCliente: string;
  pianoFatturazione: string;
  pianoFornitura: string;
  pod_PDR: string;
  potenzaContrattuale: number;
  potenzaDisponibile: number;
  potenzaInFranchigia: number;
  productCode: string;
  profiloPrelievo: string;
  progFatturazUnica: string;
  provinciaFatturazione: string;
  provinciaFornitura: string;
  ragSocIntestatario: string;
  ranking: string;
  refCommercialeKAM: string;
  regime: string;
  riferimAmministrativo: string;
  riferimentoAmministrativo: string;
  scalaFatturazione: string;
  scalaFornitura: string;
  sezioneUrbana: string;
  sistemaProvenienza: string;
  societa: string;
  splitPayment: string;
  statoFormaContrattuale: string;
  statoServizio: string;
  stradaFornitura: string;
  subalterno: string;
  tensioneDisponibile: number;
  tipoMercato: string;
  target: string;
  tipoProdotto: string;
  tipologiaBolletta: string;
  tipologiaContract: string;
  tipologiaFornitura: string;
  tipologiaParticella: string;
  tipologiaPdf: string;
  tipologiaTariffa: string;
  tipologiaUnitaImmobiliare: string;
  tipologiaUsoH2o: string;
  titoloOccupazImmobile: string;
  usoFornitura: string;
  viaFatturazione: string;
  indirizzoDiInstallazioneNds: string;
}

export interface ContractFiglio {
  dataAttivazioneFiglio: string;
  dataCessazioneFiglio: string;
  id: string;
  idSubscriptionFiglio: string;
  parentIdFiglio: string;
  statoServizioFiglio: string;
  tipoProdottoFiglio: string;
  tipologiaServizioFiglio: string;
  tipologiaSubscriptionFiglio: string;
}

export interface RegistryRequest {
  piva: string;
  cf: string;
  accountid: string;
  personafisicagiuridica: string;
  gruppoautorizzazione?: string;
}

export interface Sdd {
  accountBuildingNumberName: string;
  accountCityName: string;
  accountEmail: string;
  accountFiscalCode: string;
  accountFullName: string;
  accountLocalityName: string;
  accountMobilePhone: string;
  accountPhone: string;
  accountPostalCodeName: string;
  accountRouteName: string;
  bankName: string;
  commodityEle: boolean;
  commodityGas: boolean;
  contractEle: string;
  contractGas: string;
  holderBirthDay: string;
  holderBirthMonth: string;
  holderBirthPlace: string;
  holderBirthYear: string;
  holderBuildingNumberName: string;
  holderCityName: string;
  holderEmail: string;
  holderFiscalCode: string;
  holderFullName: string;
  holderLocalityName: string;
  holderMobilePhone: string;
  holderPhone: string;
  holderPostalCodeName: string;
  holderRouteName: string;
  iban: string;
  pdr: string;
  pod: string;
  swift: string;
  punto_fornitura: string;
  cf_intestatario_sdd: string;
  iban_sdd: string;
  codice_bic_swift_sdd: string;
  presso_la_banca_sdd: string;
  nome_sottoscrittore: string;
  cognome_sottoscrittore: string;
  codice_fiscale_sottoscrittore: string;
  metodo_stipula: string;
}

export interface RegistryResponse {
  idAccount: string;
  nome: string;
  cognome: string;
  ragioneSociale: string;
  cognomeNome: string;
  codiceFiscale: string;
  partitaIva: string;
  bpSap: string;
  sedeLegaleNonResidenziale: string;
  tipoAccount: string;
  tipologiaCliente: string;
  statoCliente: string;
  indirizzoEmail: string;
  indirizzoEmailPec: string;
  cellulare: string;
  telefono: string;
  fax: string;
  chiaveAlReferenteTitolare: string;
  toponomasticaViaCivico: string;
  indirizzo: string;
  civico: string;
  citta: string;
  provincia: string;
  cap: string;
  descrizioneFormaGiuridica: string;
  personaFisicaGiuridica: string;
  consensoTrattamentoDati: boolean;
  consensoTrattamentoDatiFiniCommerciali: boolean;
  consensoAllaProfilazione: boolean;
  consensoCessioneDatiaTerzi: boolean;
  consensoPubblicita: boolean;
  dipendenteIren: string;
  dbProvenienza: string;
  istatResidenza: string;
  pressoResidenza: string;
  scalaResidenza: string;
  nazioneResidenza: string;
  gruppoAutorizzazione: string;
  mercatoRientro: string;
  contacts: Contact[];
  toponimoResidenza: string;
  societa: string;
  dataNascita: string;
  pianoResidenza: string;
  internoResidenza: string;
  sesso: string;
  sfOrg: string;
  partitaIvaGruppoIva: string;
  gruppoIva: string;
  codiceAteco: string;
  soggettoNonRilevanteIva: boolean;
  luogoNascita: string;
  dataInizioGruppoIva: string;
  dataFineGruppoIva: string;
  dataAggConsensoCessioneTerzi: string;
  dataAggConsensoProfilazione: string;
  dataAggConsensoTrattComm: string;
  stradaDiResidenza: string;
  masterAccount: string;
  vulnerabile104: string;
  vulnerabileBonus: string;
  vulnerabileSae: string;
  vulnerabileEta: string;
  vulnerabilitySaeEle: string;
  vulnerabilityL104Ele: string;
  vulnerabilityBonusEle: string;
  vulnerabilityIsoleMinori: string;
  vulnerabilityMacchinariSalvavita: string;
  vulnerabilityMacchinariSalvavitaAltri: string;
}

export interface Registry {
  idAccount: string;
  primaryContactKey: string;
  consensoTrattamentoDati: boolean;
  consensoTrattamentoDatiFiniCommerciali: boolean;
  consensoAllaProfilazione: boolean;
  consensoCessioneDatiaTerzi: boolean;
  name: string;
  surname: string;
  cf: string;
  email: string;
  birthDate: string;
  birthPlace: string;
  cellulare: string;
  mobilePhone: string;
  phone: string;
  address: string;
  buildingNumber: string;
  city: string;
  company: string;
  country: string;
  cap: string;
  residenceAddress: string;
  residenceStreet: string;
  legalNonResidentialAddress: string;
  ragioneSociale: string;
  primaryContact: Partial<Contact> | null;
  gruppoAutorizzazione: string;
}

export interface VariationRegistry {
  variazioneAnagrafica: Partial<RegistryVariation>;
}
export interface RegistryVariation {
  nome_strada_residenza: string;
  sportello: string;
  civico_residenza: string;
  cons_profilazione: string | null;
  cons_cess_dati_terzi: string | null;
  cons_tratt_dati_comm: string | null;
  canale_cont_pref: string;
  canale: string;
  cellulare_contact: string;
  tracking_codice_fiscale: string;
  tracking_titolare: string;
  tracking_partita_iva: string;
  telefono_account: string;
  comune_residenza: string;
  prov_residenza: string;
  cap_residenza: string;
  istat_residenza: string;
  nazione_residenza: string;
  email_account: string;
  email_contact: string;
  cellulare_account: string;
  account_id: string;
  channel: string;
  nome_strada_fatturazione: string;
  civico_fatturazione: string;
  comune_fatturazione: string;
  prov_fatturazione: string;
  cap_fatturazione: string;
  istat_fatturazione: string;
  nazione_fatturazione: string;
  email_bolletta_web: string;
  codice_destinatario: string;
  indirizzo_pec_fatturazione: string;
  punto_fornitura: string;
  cf_contact: string;
  modalita_fatturazione: string;
  consent_booster: string;
  random_code: string;
  tipo_lavorazione: string;
}

export interface Contact {
  id: number;
  nome_f: string;
  cognome_f: string;
  ragioneSociale_f: string;
  codiceFiscale_f: string;
  idReferente_f: string;
  indirizzoEmail_f: string;
  cellulare_f: string;
  telefono_f: string;
  altroTelefono2_f: string;
  altroTelefono3_f: string;
  fax_f: string;
  nome_strada_residenza: string;
  canaleContattoPreferito_f: string;
  dataCreazione_f: string;
  luogoNascita_f: string;
  sesso_f: string;
  consensoProfilo_f: boolean;
  consensoTerzi_f: boolean;
  consensoCommerciali_f: boolean;
  comune_residenza: string;
  tipoDocumento_f: string;
  civico_residenza: string;
  numeroDocumento_f: string;
  dataRilascioDocumento_f: string;
  dataScadenzaDocumento_f: string;
  enteRilascioDocumento_f: string;
  dataNascita_f: string;
  telefono: string;
  ruolo_f: string;
  emailClickIren_f: string;
  qualifica_f: string;
  piva_f: string;
}

export enum ProcessType {
  VMDP = 'Variazione Metodo di pagamento',
  VAEC = 'Variazioni Anagrafiche e commerciali'
}

export interface CheckSDFCRequest {
  punto: string;
  tipoprocesso: string;
  gruppoautorizzazione: string;
  cf: string;
  piva: string;
}

export interface PaymentMethodRequest {
  variazioniMOP: Partial<VariazioniMOP>;
  contractModuleFields: Partial<contractModule>;
  sddModule: Partial<Sdd>;
  sportello: string;
}

export interface VariazioniMOP {
  canale: string;
  canale_provenienza_web: string;
  case_id: string;
  cf_intestatario_sdd: string;
  channel: string;
  codice_agenzia: string;
  codice_bic_swift_sdd: string;
  codice_fiscale_sottoscrittore: string;
  cognome_sottoscrittore: string;
  company_appartenenza: string;
  conto_corrente_ita_sdd: string;
  data_stipula: string;
  descrizione_errore: string;
  document_id: string;
  esito: string;
  tracking_telefono_intestatario: string;
  tracking_cellulare_intestatario: string;
  esito_bpm: string;
  gruppo_lavoro_competenza: string;
  iban_sdd: string;
  intestatario_sdd: string;
  message_bpm: string;
  metodo_pagamento: string;
  nome_sottoscrittore: string;
  nomefile_vocal_order_contr: string;
  tracking_codice_fiscale: string;
  numero_record: string;
  operatore_acquisitore: string;
  order_id: string;
  presso_la_banca_sdd: string;
  punto_fornitura: string;
  sportello: string;
  tipo_lavorazione: string;
  work_order_id: string;
  holderBirthDay: string;
  holderBirthMonth: string;
  holderBirthPlace: string;
  holderBirthYear: string;
  holderBuildingNumberName: string;
  holderCityName: string;
  holderEmail: string;
  holderFiscalCode: string;
  holderFullName: string;
  holderLocalityName: string;
  holderMobilePhone: string;
  holderPhone: string;
  holderPostalCodeName: string;
  holderRouteName: string;
  random_code: string;
}

export interface contractModule {
  commodityEle: boolean;
  commodityGas: boolean;
  contractEle: string; // numeroContrattoELE
  pod: string;
  contractGas: string; //numeroContrattoGAS
  pdr: string;
  accountFullName: string; // Nome + Cognome account
  accountRouteName: string; // Via Residenza Account (con toponimo)
  accountBuildingNumberName: string; //Civico residenza
  accountPostalCodeName: string; // CAP residenza
  accountCityName: string; // residenza
  accountLocalityName: string; // provincia
  accountFiscalCode: string; // cf account
  accountPhone: string; // telefono
  accountMobilePhone: string; // cellulare account
  accountEmail: string; // email account
  holderFullName: string; // holder == sottoscrittore
  holderBirthPlace: string; // holder == sottoscrittore
  holderBirthDay: string; //holder == sottoscrittore
  holderBirthMonth: string; // holder == sottoscrittore
  holderBirthYear: string; //holder == sottoscrittore
  holderRouteName: string; // holder == sottoscrittore
  holderBuildingNumberName: string; // holder == sottoscrittore
  holderPostalCodeName: string; //holder == sottoscrittore
  holderCityName: string; // holder == sottoscrittore
  holderLocalityName: string; //holder == sottoscrittore
  holderFiscalCode: string; // holder == sottoscrittore
  holderPhone: string; // holder == sottoscrittore
  holderMobilePhone: string; //holder == sottoscrittore
  holderEmail: string; // holder == sottoscrittore
  iban: string; // iban
  swift: string; // codice bic swift
  bankName: string; // nome bancacommodityEle: boolean,
}
